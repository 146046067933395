import { IDevelopmentState } from '../DevelopmentSlice';
import { IDiagram } from '../types';
import { findCurrentObject } from './findCurrentObject';

export const findCurrentDiagram = (state: IDevelopmentState) => {
  return findCurrentObject<IDiagram>(
    state.openObjects as IDiagram[],
    state.currentObject.key
  );
};

export const findCurrentDiagramByKey = (
  state: IDevelopmentState,
  key: string
) => {
  return findCurrentObject<IDiagram>(state.openObjects as IDiagram[], key);
};
