import { useEffect, useRef } from 'react';

// eslint-disable-next-line require-jsdoc
export function usePrevious<T>(value: T): T {
  const ref: any = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
