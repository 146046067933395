/* eslint-disable require-jsdoc */
import { apiBase } from '@modules/apiBase';
import { ISchema } from '@modules/development/types/schema';

const COMPLEX_TYPE_ENDPOINT = 'diagrams/schemas';

class Schema {
  async getSchemas() {
    return await apiBase.get<ISchema[]>(COMPLEX_TYPE_ENDPOINT);
  }
  async getSchema(schemaId: string) {
    return await apiBase.get<ISchema>(`${COMPLEX_TYPE_ENDPOINT}/${schemaId}`);
  }
  async addSchema(body: ISchema) {
    return await apiBase.post(COMPLEX_TYPE_ENDPOINT, body);
  }

  async deleteSchema(id: string) {
    return await apiBase.delete(`${COMPLEX_TYPE_ENDPOINT}/${id}`);
  }
}

export const schemaApi = new Schema();
