import React, { Dispatch, FC, Key, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { IToolbarItem, useSearch } from '@shared';
import { DataSourcesToolbar } from './DataSourcesToolbar';
import { DataSourcesTop } from './DataSourcesTop';
import { DataSourcesTable } from './DataSourcesTable';
import { DataSource } from '../interfaces';
import { DataSourcesInfo } from './DataSourcesInfo';
import { Sort } from '@modules/administration';

interface IDataSourcesWrapper {
  actions: IToolbarItem[];
  search: string;
  activeItem: string | null;
  columns: ColumnsType<DataSource>;
  data: DataSource[];
  rowKeys: Key[];
  selectedRowKeys: Key[];
  loading: boolean;
  expandedRowKeys: React.Key[] | undefined;
  setSearch: (seacrh: string) => void;
  setActiveItem: Dispatch<SetStateAction<string | null>>;
  setSelectedRowKeys: (keys: Key[]) => void;
  setSort: (sort: Sort) => void;
  onRowEditClick: (keys: Key[]) => void;
}

export const DataSourcesWrapper: FC<IDataSourcesWrapper> = ({
  actions,
  search,
  activeItem,
  columns,
  data,
  rowKeys,
  selectedRowKeys,
  loading,
  expandedRowKeys,
  setSearch,
  setActiveItem,
  setSelectedRowKeys,
  setSort,
  onRowEditClick,
}) => {
  const { t } = useTranslation();

  const [renderSearchInput, getRefProps] = useSearch({ search, setSearch });

  return (
    <div className="data-sources">
      <DataSourcesTop
        title={t('administrationTab.solutionLists.sidePanel.dataSources')}
      />

      <DataSourcesToolbar
        actions={actions}
        search={renderSearchInput()}
        rowKeys={rowKeys}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />

      <div className="data-sources__content" {...getRefProps()}>
        <div className="data-sources__table">
          <DataSourcesTable
            columns={columns}
            data={data}
            selectedRowKeys={selectedRowKeys}
            activeItem={activeItem}
            loading={loading}
            expandedRowKeys={expandedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            setActiveItem={setActiveItem}
            setSort={setSort}
            onRowEditClick={onRowEditClick}
          />
        </div>

        <DataSourcesInfo
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </div>
    </div>
  );
};
