import { IDataType, IFunction, IRuleType } from './types/interface';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@modules/store';
import { referencesApi } from './api/referencesApi';
import { tryCatchDecorator } from '@modules/development/utils';

const REFERENCES_FEATURE_KEY = 'references';

export interface IReferencesState {
  dataTypes: IDataType[];
  functions: IFunction[];
  ruleTypes: IRuleType[];
}

const initialState: IReferencesState = {
  dataTypes: [],
  functions: [],
  ruleTypes: [],
};

export const getDataTypes = createAsyncThunk(
  `${REFERENCES_FEATURE_KEY}/getDataTypes`,
  async (_, { dispatch }) => {
    // @ts-ignore
    return await tryCatchDecorator(dispatch, async () => {
      const response = await referencesApi.getDataTypes();
      return response;
    });
  }
);

export const getFunctions = createAsyncThunk(
  `${REFERENCES_FEATURE_KEY}/getFunctions`,
  async (_, { dispatch }) => {
    // @ts-ignore
    return await tryCatchDecorator(dispatch, async () => {
      const response = await referencesApi.getFunctions();
      return response;
    });
  }
);

export const getRuleType = createAsyncThunk(
  `${REFERENCES_FEATURE_KEY}/getRuleType`,
  async (_, { dispatch }) => {
    // @ts-ignore
    return await tryCatchDecorator(dispatch, async () => {
      const response = await referencesApi.getRuleType();
      return response;
    });
  }
);

export const referencesSlice = createSlice({
  name: REFERENCES_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDataTypes.fulfilled, (state, action) => {
      if (action.payload) state.dataTypes = action.payload;
    });

    builder.addCase(getFunctions.fulfilled, (state, action) => {
      if (action.payload) state.functions = action.payload;
    });

    builder.addCase(getRuleType.fulfilled, (state, action) => {
      if (action.payload) state.ruleTypes = action.payload;
    });
  },
});

export const referencesActions = referencesSlice.actions;

export const selectDataTypes = (state: RootState) => state.references.dataTypes;
export const selectFunctions = (state: RootState) => state.references.functions;
export const selectRuleTypes = (state: RootState) => state.references.ruleTypes;

export default referencesSlice.reducer;
