import React, { FC, memo, useEffect, useState } from 'react';
import { notification as antdNotification } from 'antd';
import './notificationProvider.scss';
import { Icon } from '@modules/icon';
import { useTranslationPath, useTranslation } from '@modules/languageProvider';
import { NotificationMessage } from './components';
import { useNotificationAction, useNotificationSelector } from './hooks';

antdNotification.config({
  top: 96,
  closeIcon: <Icon name="icon-close_second" size={12} />,
});

export const NotificationProvider: FC = memo(({ children }) => {
  const { deleteNotification, clearNotifications } = useNotificationAction();
  const { notifications, showClearButton } = useNotificationSelector();
  const { t } = useTranslation();
  const translate = useTranslationPath('notification');
  const setItems = useState(notifications)[1];

  useEffect(() => {
    setItems((current) => {
      Object.keys(notifications).forEach((key) => {
        if (!current[key]) {
          antdNotification[notifications[key].type]({
            key: key,
            message: (
              <NotificationMessage
                text={
                  notifications[key].message ||
                  t(
                    `${notifications[key].translate}`,
                    notifications[key].translateOptions
                  )
                }
              />
            ),
            duration: null,
            onClose: () => deleteNotification(key),
          });
        }
      });

      Object.keys(current).forEach((key) => {
        if (!notifications[key]) {
          antdNotification.close(key);
        }
      });

      return notifications;
    });
  }, [notifications]);

  return (
    <React.Fragment>
      {showClearButton ? (
        <button
          className="notification-clear-button"
          onClick={() => clearNotifications()}
          children={translate('clearAll')}
        />
      ) : null}
      {children}
    </React.Fragment>
  );
});
