import {
  IDataVariables,
  IDataVariablesBase,
  IDiagramNode,
} from '@modules/development';

export type Expression = {
  serviceExpression: string;
  serviceExpressionTypeId: string;
};

export interface IExtServiceNodeVariable extends IDataVariablesBase {
  serviceVariableId: string;
  expression: Expression;
}

export interface IExtServiceOutputVariable extends IDataVariables {
  serviceVariableId: string;
  nodeVariable: string;
}

export interface IExtServiceProperties {
  serviceId: string;
  versionId: string;
  nodeVariablesMapping: IExtServiceNodeVariable[];
  outputVariablesMapping: IExtServiceOutputVariable[];
}

export type ExtServiceNode = IDiagramNode<IExtServiceProperties>;

export enum ModalTypes {
  inputVarValue = 'inputVarValue',
  outputVarValue = 'outputVarValue',
  confirmSave = 'confirmSave',
  confirm = 'confirm',
  chooseVersion = 'chooseVersion',
}

export type OpenedModal =
  | {
      type: ModalTypes.inputVarValue;
      title: string;
      typeId: string;
      key: string;
      expression: string;
    }
  | {
      type: ModalTypes.outputVarValue;
      title: string;
      typeId: string;
      key: string;
      isArray: boolean;
      value: string;
    }
  | {
      type: ModalTypes.confirmSave;
      contentText: string;
    }
  | {
      type: ModalTypes.confirm;
      contentText: string;
      kind?: 'error';
      onClose: () => void;
    }
  | {
      type: ModalTypes.chooseVersion;
    }
  | null;

export type InvalidData = {
  inputs: { [key: string]: string };
  inputVariablesMapping: { [key: string]: { [key: string]: string } };
  outputVariablesMapping: { [key: string]: { [key: string]: string } };
};
