import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '@modules/store';
import { administrationActions } from '../AdministrationSlice';
import * as administrationThunkActions from '../actions';

export const useAdministrationAction = () => {
  const dispatch = useAppDispatch();

  return bindActionCreators(
    { ...administrationActions, ...administrationThunkActions },
    dispatch
  );
};
