import { FC, Key, useEffect, useState } from 'react';
import { Button, Portal, SimpleTable, useSearch } from '@shared';
import { Icon } from '@modules/icon';
import { useTranslationPath } from '@modules/languageProvider';
import { SchemasModal } from './SchemasModal';
import {
  onChangeCustomDataTypesSearch,
  removeOpenModal,
} from '@modules/development/DevelopmentSlice';
import { getSchemas } from '@modules/schema';
import { getSchemas as refreshSchemas } from '@modules/schema/schemaSlice';
import './SchemasTable.scss';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { selectRoles, rolesList } from '@modules/services';
import { ColumnsType } from 'antd/es/table';
import { selectSchemas } from '../../reducers';

type UpdateType = {
  typeId: string;
  versionId: string;
};

type DataSourceRowType = UpdateType & {
  key: string;
  typeName: string;
  user: string;
};

interface ISchemasTable {
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
  addNewRequested?: boolean;
  hideSelectAll?: boolean;
  onCancelAdd?: () => void;
  onTypeUpdated?: () => void;
  search?: string;
}

export const SchemasTable: FC<ISchemasTable> = ({
  selectedRowKeys,
  setSelectedRowKeys,
  addNewRequested,
  hideSelectAll,
  onCancelAdd,
  onTypeUpdated,
  search,
}) => {
  const dispatch = useAppDispatch();
  const translate = useTranslationPath('development.complexTypes');
  const translateActions = useTranslationPath('actions');

  const data = useAppSelector((state) => state.schema.schemas);

  const roles = useAppSelector(selectRoles);

  const [schemaId, setSchemaId] = useState<string | null>(null);

  const onSearch = (search: string) => {
    dispatch(onChangeCustomDataTypesSearch({ search }));
  };

  const [renderSearchInput, getRefProps] = useSearch({
    search: String(search),
    setSearch: onSearch,
  });

  useEffect(() => {
    dispatch(getSchemas());
    return () => onSearch('');
  }, []);

  const customTypes = data.map((ct) => ({ ...ct, key: ct.id }));

  const typeColumns: ColumnsType<DataSourceRowType> = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Имя',
    },
    {
      dataIndex: 'url',
      key: 'url',
      title: 'url',
    },
  ];

  return (
    <div {...getRefProps()}>
      <Portal id="toolbarSearch">{renderSearchInput()}</Portal>
      <SimpleTable
        className="schemas__table"
        columnsData={typeColumns}
        dataSource={customTypes}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        hideSelectAll={hideSelectAll || true}
        onDoubleClick={(data: any) => {
          roles.includes(rolesList.COMPLEX_TYPE_READ) && setSchemaId(data.id);
        }}
      />
      {(addNewRequested || Boolean(schemaId)) && (
        <SchemasModal
          modalProps={{
            title: translate('editType'),
            type: 'editCustomDataType',
          }}
          schemaId={schemaId}
          onClose={() => {
            dispatch(removeOpenModal({}));
            onCancelAdd && onCancelAdd();
            setSchemaId(null);
            // TODO: fix this
            setTimeout(() => dispatch(refreshSchemas()), 1000);
          }}
          editFlg={!!addNewRequested}
          closeOnSave
          onChangesSaved={onTypeUpdated}
        />
      )}
    </div>
  );
};
