import { Key } from 'react';
import { IDataVariables, IDiagramNode } from '@modules/development';
import { useAggregateReadingModalValidate } from './hooks';

type InputVariable = {
  diagramElement: string;
  aggregateElement: string;
};

export type OutputVariable = IDataVariables & {
  aggregate: { aggregateId: string; versionId: string };
};

export interface IAggregateReadingProperties {
  groupingElements: InputVariable[];
  outputVariablesMapping: OutputVariable[];
}

export type AggregateReadingNode = IDiagramNode<IAggregateReadingProperties>;

export enum ModalTypes {
  chooseAggregate = 'chooseAggregate',
  addElement = 'addElement',
  chooseGroupingElements = 'chooseGroupingElements',
  confirmSave = 'confirmSave',
}

export enum PropertyTypes {
  diagramElement = 'diagramElement',
  diagramVariable = 'diagramVariable',
}

export type ModalData =
  | {
      type: ModalTypes.chooseAggregate;
      aggregateId: string;
      key: Key;
    }
  | {
      type: ModalTypes.addElement;
      variableName: string;
      allowedTypes?: { typeId: string; isArray: boolean }[];
      property: PropertyTypes;
      key: Key;
    }
  | {
      type: ModalTypes.chooseGroupingElements;
      selectedNames: string[];
      key: Key;
    }
  | {
      type: ModalTypes.confirmSave;
      contentText: string;
    }
  | null;

export type InvalidData = {
  inputs: { [key: string]: string };
  aggregateMapping: { [key: string]: { [key: string]: string } };
};

export type Validation = ReturnType<typeof useAggregateReadingModalValidate>;
