import { IDataVariables, IDiagramNode } from '../../../../../types';
import { ConfirmSaveModalProps } from '../ConfirmInvalidBlockSaveModal/ConfirmInvalidBlockSaveModal';

type SubdiagramVariable = IDataVariables & {
  nodeVariable: string;
};

export interface ISubdiagramProperties {
  subdiagramId: string;
  versionId: string;
  inputVariablesMapping: SubdiagramVariable[];
  outputVariablesMapping: SubdiagramVariable[];
}

export type SubdiagramNode = IDiagramNode<ISubdiagramProperties>;

type TableAttributeData = {
  variable: string;
  isArray: boolean;
};

export type TableValueData = {
  value: string;
  name: string;
  key: string;
  typeId: string;
  isArray: boolean;
  variablePath?: string;
};

export type TableData = {
  attribute: TableAttributeData;
  type?: string;
  value: TableValueData;
};

export enum ModalTypes {
  chooseDiagram = 'choose-diagram',
  addElement = 'add-element',
  confirmSave = 'confirmSave',
  chooseVersion = 'chooseVersion',
}

export type OpenedModal =
  | {
      type: ModalTypes.chooseDiagram;
    }
  | {
      type: ModalTypes.addElement;
      nodeVariable: string;
      allowedType: { typeId: string; isArray: boolean };
      isInput: boolean;
      key: string;
    }
  | ConfirmSaveModalProps
  | {
      type: ModalTypes.chooseVersion;
    }
  | null;

export type InvalidData = {
  inputs: { [key: string]: string };
  inputVariablesMapping: { [key: string]: { [key: string]: string } };
  outputVariablesMapping: { [key: string]: { [key: string]: string } };
};
