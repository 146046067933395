import { FC, useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import 'moment/locale/ru';
import './DataPicker.scss';
// import { IScriptsTableOptions } from "src/modules/Scripts/types/interface";

const { RangePicker } = DatePicker;

interface DatePickerFilterProps {
  // statusFilter: IScriptsTableOptions;
  statusFilter: any;
  confirm: () => void;
  setSelectedKeys: (selectedKeys: any) => void;
  clearFilters?: () => void;
  dataIndexAfter: string;
  dataIndexBefore: string;
  handleFilter: any;
  disabled: boolean;
}

const DatePickerFilter: FC<DatePickerFilterProps> = ({
  statusFilter,
  confirm,
  // setSelectedKeys,
  clearFilters,
  dataIndexAfter,
  dataIndexBefore,
  handleFilter,
  disabled,
}) => {
  // const [hackValue, setHackValue] = useState<any>();
  const [value, setValue] = useState<any>(null);
  // const [open, setOpen] = useState<boolean>(true);

  const onFilter = (val1: any, val2: any) => {
    if (val1 && val2) {
      handleFilter(
        [dataIndexAfter, dataIndexBefore],
        [val1?.format('YYYY-MM-DD'), val2?.format('YYYY-MM-DD')]
      );
    }
    confirm();
  };

  const onRangePickerChange = (val: any) => {
    setValue(val);
    if (val) {
      onFilter(val[0], val[1]);
    }
  };

  useEffect(() => {
    if (value !== null && statusFilter.changeDt === null && clearFilters) {
      setValue([null, null]);
    }
  }, [statusFilter]);

  // useEffect(() => {
  //   if (
  //     value !== null &&
  //     Object.keys(statusFilter).indexOf(dataIndexAfter) === -1 &&
  //     clearFilters
  //   ) {
  //     setValue([null, null]);
  //   }
  // }, [Object.keys(statusFilter).indexOf(dataIndexAfter) === -1]);

  return (
    <RangePicker
      disabled={disabled}
      value={value}
      onChange={(val) => onRangePickerChange(val)}
    />
  );
};

export const getColumnDateFilterProps = (
  selectFilter: any,
  dataIndexAfter: string,
  dataIndexBefore: string,
  handleFilter: any,
  disabled: boolean
) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <DatePickerFilter
          statusFilter={selectFilter}
          confirm={confirm}
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearFilters}
          dataIndexAfter={dataIndexAfter}
          dataIndexBefore={dataIndexBefore}
          handleFilter={handleFilter}
          disabled={disabled}
        />
      </div>
    ),

    onFilter: () => true,
  };
};
