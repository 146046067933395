import React, { useState } from 'react';
import { DatePicker } from 'antd';
import {
  Accordion,
  InputSearch,
  RadioGroup,
  Select,
  CheckoxListBase,
  Card,
} from '@shared';
import { useTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import './LogFilter.scss';

const { RangePicker } = DatePicker;

const TECH_MONITORING_TRANSLATION_PATH =
  'administrationTab.technicalMonitoring';
const getTranslationPath = (item: string) => {
  return `${TECH_MONITORING_TRANSLATION_PATH}.${item}`;
};

interface ILogFilter {}
const LogFilter: React.FC<ILogFilter> = () => {
  const { t } = useTranslation();
  const [typeRadio, setTypeRadio] = useState('allTypes');
  const [recentUpdates, setRecentUpdates] = useState('lastDay');
  const [messageType, setMessageType] = useState<CheckboxValueType[]>();
  const [messageSource, setMessageSource] = useState<CheckboxValueType[]>();

  const typeMessage = [
    { label: 'FATAL', value: 'FATAL' },
    { label: 'ERROR', value: 'ERROR' },
    { label: 'WARN', value: 'WARN' },
    { label: 'INFO', value: 'INFO' },
    { label: 'DEBUG', value: 'DEBUG' },
    { label: 'TRACE', value: 'TRACE' },
    { label: 'NONE', value: 'NONE' },
  ];
  const sourceMessage = [
    { label: 'audit', value: 'audit' },
    { label: 'authorization', value: 'authorization' },
    { label: 'authentication', value: 'authentication' },
    { label: 'test_load', value: 'test_load' },
    { label: 'test_exec', value: 'test_exec' },
    { label: 'TRACE', value: 'TRACE' },
    { label: 'runtime_exec', value: 'runtime_exec' },
  ];
  const selectTimeOptions = [
    { value: 'lastDay', label: t(getTranslationPath('lastDay')) },
    { value: 'lastTwoDays', label: t(getTranslationPath('lastTwoDays')) },
    { value: 'lastThreeDays', label: t(getTranslationPath('lastThreeDays')) },
    { value: 'lastWeek', label: t(getTranslationPath('lastWeek')) },
    { value: 'lastMonth', label: t(getTranslationPath('lastMonth')) },
  ];
  const radioGroupOptions = [
    { value: 'allTypes', label: t(getTranslationPath('allTypes')) },
    { value: 'recentUpdates', label: t(getTranslationPath('recentUpdates')) },
    { value: 'timeRange', label: t(getTranslationPath('timeRange')) },
  ];
  return (
    <div className="log-filter">
      <Card title={t(getTranslationPath('logFilter'))} icon="message">
        <Accordion title={t(getTranslationPath('messageText'))}>
          <InputSearch />
        </Accordion>
        <div className="log-filter__type">
          <RadioGroup
            value={typeRadio}
            onChange={(e) => setTypeRadio(e.target.value)}
            options={radioGroupOptions}
            isVerticalDirection
          />
        </div>
        <Accordion
          title={t(getTranslationPath('recentUpdates'))}
          className="log-filter__accordion"
        >
          <Select
            options={selectTimeOptions}
            className="select-time"
            disabled={typeRadio === 'timeRange'}
            value={recentUpdates}
            onChange={(e) => setRecentUpdates(e)}
          />
        </Accordion>
        <Accordion
          title={t(getTranslationPath('calendar'))}
          className="log-filter__accordion"
        >
          <RangePicker
            size="large"
            disabled={typeRadio === 'recentUpdates'}
            placeholder={[
              t(getTranslationPath('startDate')),
              t(getTranslationPath('endDate')),
            ]}
          />
        </Accordion>
        <Accordion
          title={t(getTranslationPath('messageType'))}
          className="log-filter__accordion"
        >
          <CheckoxListBase
            data={typeMessage}
            value={messageType}
            onChange={(value) => {
              setMessageType(value);
            }}
          />
        </Accordion>
        <Accordion
          title={t(getTranslationPath('messageSources'))}
          className="log-filter__accordion"
        >
          <CheckoxListBase
            data={sourceMessage}
            value={messageSource}
            onChange={(value) => {
              setMessageSource(value);
            }}
          />
        </Accordion>
      </Card>
    </div>
  );
};

export { LogFilter };
