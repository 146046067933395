import { Key } from 'react';
import { apiBase, IResponse } from '@modules/apiBase';
import {
  IDiagramBase,
  IDiagram,
  IDiagramNode,
  IDiagramNodeCreate,
  IDiagramNodeUpdate,
  IDiagramLinkCreate,
  IDiagramSave,
  InOutParameter,
  DiagramCreate,
  DiagramVersions,
  DiagramCreateUserVersion,
  DiagramUpdateUserVersion,
  PossibleNodes,
} from '../types';

const DIAGRAM_ENDPOINT = 'diagrams';
const VALIDATE_ENDPOINT = 'validate';
const DIAGRAM_HELPER_ENDPOINT = 'diagram-helper';
const NODE_ENDPOINT = `${DIAGRAM_ENDPOINT}/nodes`;
const LINK_ENDPOINT = `${DIAGRAM_ENDPOINT}/links`;

export const diagramApi = {
  getDiagram: async (id: Key) => {
    return await apiBase.get<IDiagram>(`${DIAGRAM_ENDPOINT}/${id}`);
  },
  getDiagrams: async () => {
    return await apiBase.get<IDiagramBase[]>(DIAGRAM_ENDPOINT);
  },
  deleteDiagram: async (versionId: Key) => {
    return await apiBase.delete<IResponse>(`${DIAGRAM_ENDPOINT}/${versionId}`);
  },
  createDiagram: async (body: DiagramCreate) => {
    return await apiBase.post<IResponse>(DIAGRAM_ENDPOINT, body);
  },
  createDiagramTemplate: async () => {
    return await apiBase.post<IDiagramBase>(
      `${DIAGRAM_ENDPOINT}/createTemplate`
    );
  },
  createDiagramTemplateFromLatest: async (diagramId: string) => {
    return await apiBase.post<IDiagram>(
      `${DIAGRAM_ENDPOINT}/${diagramId}/createTemplateFromLatest`
    );
  },
  deleteDiagramTemplate: async (diagramId: string) => {
    return await apiBase.delete<IResponse>(
      `${DIAGRAM_ENDPOINT}/${diagramId}/removeTemplate`
    );
  },
  createAsNew: async (body: DiagramCreate) => {
    return await apiBase.post<IResponse>(
      `${DIAGRAM_ENDPOINT}/createAsNew`,
      body
    );
  },
  saveDiagram: async (body: DiagramCreate) => {
    return await apiBase.post<IResponse>(DIAGRAM_ENDPOINT, body);
  },
  saveAsDiagram: async (body: DiagramCreate) => {
    return await apiBase.post<IResponse>(
      `${DIAGRAM_ENDPOINT}/createAsNew`,
      body
    );
  },
  editDiagram: async (id: string, body: IDiagramSave) => {
    return await apiBase.put<IResponse>(`${DIAGRAM_ENDPOINT}/${id}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
  validateDiagram: async (body: any) => {
    return await apiBase.post<any>(`${DIAGRAM_ENDPOINT}/validate`, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
  updateDiagramParameters: async (
    diagramId: string,
    parameters: InOutParameter[]
  ) => {
    return await apiBase.put<IResponse>(
      `${DIAGRAM_ENDPOINT}/${diagramId}/parameters`,
      parameters
    );
  },
  deployDiagram: async (id: Key) => {
    return await apiBase.post<any>(`${DIAGRAM_ENDPOINT}/${id}/deploy`);
  },
  setDiagramReadyToDeploy: async (id: Key) => {
    return await apiBase.put<any>(`${DIAGRAM_ENDPOINT}/${id}/submit`);
  },
  renameDiagram: async (data: { id: Key; title: string }) => {
    const newData = await apiBase.put<any>(
      `${DIAGRAM_ENDPOINT}/${data.id}/rename`,
      { diagramName: data.title }
    );
    newData.data.title = data.title;
    return newData;
  },
  validateCalculateExpression: async (expression: string) => {
    return await apiBase.post<{
      idTypeExpression: string;
      statusValidate: boolean;
    }>(`${VALIDATE_ENDPOINT}/calculate/expression`, expression, {
      headers: { 'Content-Type': 'text/plain' },
    });
  },
  parseExcel: async (file: any) => {
    return await apiBase.post(
      `${DIAGRAM_HELPER_ENDPOINT}/upload-scorecard-file`,
      file
    );
  },
  getNodeById: async <T>(nodeId: string) => {
    return await apiBase.get<IDiagramNode<T>>(`${NODE_ENDPOINT}/${nodeId}`);
  },
  updateNodeById: async <T>(nodeId: string, data: IDiagramNodeUpdate<T>) => {
    return await apiBase.put<IResponse>(`${NODE_ENDPOINT}/${nodeId}`, data);
  },
  deleteNodeById: async (nodeId: string) => {
    return await apiBase.delete<IResponse>(`${NODE_ENDPOINT}/${nodeId}`);
  },
  createNode: async <T>(data: IDiagramNodeCreate<T>) => {
    return await apiBase.post<IResponse>(NODE_ENDPOINT, data);
  },
  getPossibleNodesById: async (nodeId: string, versionId: string) => {
    return await apiBase.get<PossibleNodes>(
      `${NODE_ENDPOINT}/${nodeId}/possible?diagramId=${versionId}`
    );
  },
  getPrevNodesById: async (nodeId: string, versionId: string) => {
    return await apiBase.get<PossibleNodes>(
      `${NODE_ENDPOINT}/${nodeId}/prevNodes?diagramVersionId=${versionId}`
    );
  },
  createLink: async (data: IDiagramLinkCreate) => {
    return await apiBase.post<IResponse>(LINK_ENDPOINT, data);
  },
  deleteLink: async (linkId: string) => {
    return await apiBase.delete<IResponse>(`${LINK_ENDPOINT}/${linkId}`);
  },
  getDiagramVersions: async (diagramId: string) => {
    return await apiBase.get<DiagramVersions>(
      `${DIAGRAM_ENDPOINT}/${diagramId}/versions`
    );
  },
  createDiagramUserVersion: async (body: DiagramCreateUserVersion) => {
    return apiBase.post<IResponse>(
      `${DIAGRAM_ENDPOINT}/create/userVersion`,
      body
    );
  },
  updateDiagramUserVersion: async (
    versionId: string,
    body: DiagramUpdateUserVersion
  ) => {
    return apiBase.put<IResponse>(
      `${DIAGRAM_ENDPOINT}/${versionId}/updateUserVersion`,
      body
    );
  },
};
