/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key } from 'react';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
  Dispatch,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { XYPosition } from 'react-flow-renderer';
import {
  CUSTOMDATATYPES_OBJTYPE,
  ICustomDataTypes,
  ICurrentObject,
  IObjects,
  IOpenTab,
  IAggregates,
  IAggregate,
  ICustomCode,
  IDiagram,
  IGroupDiagrams,
  IDiagramObjects,
  ITestUpdate,
  IObjectModal,
  IDiagramNode,
  DiagramNodeTypes,
  CopiedNodes,
  IDiagramNodeBase,
  ICreatedNewNode,
  IDiagramLinkBase,
  IDiagramSave,
  ITest,
  ParameterTypes,
  DiagramCreate,
  IDiagramBase,
  ScriptValidate,
} from './types';
import diagramsReducers from './modules/Diagrams/reducers';
import diagramReducers from './modules/Diagrams/components/Diagram/reducers';
import generalReducers from './modules/Diagrams/components/GeneralInformation/reducers';
import diagramDataReducers from './modules/Diagrams/components/DiagramData/reducers';
import diagramEditorReducers from './modules/Diagrams/components/WorkingAreaDiagram/reducers';
import customCodeListReducers from './modules/CustomCodesList/reducers';
import customCodeReducers from './modules/CustomCodesList/components/CustomCode/reducers';
import customDataTypesReducesr from './modules/CustomDataTypes/reducers';
import diagramTestingReducers from './modules/Diagrams/components/Testing/reducers';
import diagramTestingReportReducers from './modules/Diagrams/components/TestingReportModal/reducers';
import aggregatesReducers from './modules/AggregatesList/reducers';
import aggregateReducers from './modules/AggregatesList/components/Aggregate/reducers';
import schemaReducers from './modules/Schemas/reducers';
import { AppThunk, IAction, RootState } from '@modules/store';
import {
  findCurrentDiagram,
  findCurrentDiagramByKey,
  tryCatchDecorator,
} from './utils';
import {
  diagramApi,
  customCodeApi,
  aggregatesApi,
  testApi,
  schemasApi,
} from './api';
import { DEVELOPMENT_FESTURE_KEY } from './constants';
import { showNotification } from '@modules/notification';
import {
  Branch,
  IBranchProperties,
} from './modules/Diagrams/components/DiagramBlocks/BranchModal/interfaces';
import { getNodeHandles } from './modules/Diagrams/components/DiagramBlocks/common/utils/getNodeHandles';
import { IResponse } from '@modules/apiBase';
import { userStorage } from '@modules/userStorage';
import { checkVariables } from './utils/checkVariables';
import { filterEqualByKey } from '@modules/utils';
import { filterByValue } from '@modules/shared';
import { ISchema } from './types/schema';
import { ISchemas, SCHEMA_OBJTYPE } from './types/schemas';

interface IDevelopmentEntity {
  id: number;
}

export interface IDevelopmentState {
  diagrams: IDiagramObjects;
  customCode: IObjects<ICustomCode>;
  aggregates: IAggregates;
  openTabs: IOpenTab[];
  currentObject: ICurrentObject;
  openObjects: (IDiagram | ICustomCode | IAggregate)[];
  modal: IObjectModal;
  isLoading: boolean; // мб стоит перенести это на уровень всего приложения?
  error?: string;
  groupDiagrams: IGroupDiagrams[];
  customDataTypes: ICustomDataTypes;
  schemas: ISchemas;
  mappingRows: any;
}

export const DevelopmentAdapter = createEntityAdapter<IDevelopmentEntity>();

// Diagrams
export const getDiagrams = (): AppThunk => async (dispatch) => {
  return await tryCatchDecorator(dispatch, async () => {
    const response = await diagramApi.getDiagrams();

    const diagrams = response.map((d) => ({
      key: d.diagramId,
      diagramName: d.diagramName,
      createDt: d.createDt,
      changeDt: d.changeDt,
      lastChangeByUser: d.lastChangeByUser,
      location: '/Streams/',
      state: 'IN_DEVELOPMENT',
      version: d.versionId,
      type: 'diagram',
      draft: false,
      isSaved: true,
      isReadonly: false,
    }));

    dispatch(diagramsSeeds({ diagrams }));
  });
};

export const createDiagram =
  (): AppThunk<Promise<IDiagramBase | undefined>> => async (dispatch) => {
    return await tryCatchDecorator(dispatch, async () => {
      const diagram = await diagramApi.createDiagramTemplate();
      // TODO: с пустым объектов параметров не работает сохранение
      if (diagram && !diagram.inOutParameters) {
        diagram.inOutParameters = [];
      }

      dispatch(
        setCurrentObject({
          key: diagram.versionId,
          title: diagram.diagramName,
          objectInfo: diagram,
          version: diagram.versionId,
          readonly: false,
        })
      );

      return {
        ...diagram,
        key: diagram.versionId,
        location: '/Diagrams/',
        state: 'IN_DEVELOPMENT',
        version: diagram.versionId,
        type: 'diagram',
        draft: true,
        isSaved: true,
        isReadonly: false,
        historyChanges: [],
      };
    });
  };

export const saveDiagram =
  (key: Key, saveData: DiagramCreate): AppThunk<Promise<string | undefined>> =>
  async (dispatch) => {
    return await tryCatchDecorator(dispatch, async () => {
      const responseParams = await dispatch(updateParameters());
      if (responseParams) {
        const response = await diagramApi.saveDiagram(saveData);
        if (response.uuid) {
          dispatch(changeSaveStatusObject({ saveStatus: true }));
          dispatch(updateOpenTab({ key, tab: { text: saveData.diagramName } }));
          dispatch(setCurrentObject({ title: saveData.diagramName }));
          return response.uuid;
        }
      }
    });
  };

export const saveAsDiagram =
  (saveData: DiagramCreate): AppThunk<Promise<string | undefined>> =>
  async (dispatch) => {
    return await tryCatchDecorator(dispatch, async () => {
      const response = await diagramApi.saveAsDiagram(saveData);
      if (response.uuid) return response.uuid;
    });
  };

export const openDiagram =
  (versionId: string): AppThunk<Promise<IDiagram | undefined>> =>
  async (dispatch, getState) => {
    return await tryCatchDecorator(dispatch, async () => {
      const development = getState().development;
      const key = findCurrentDiagramByKey(development, versionId)?.key;
      let diagram: IDiagram;

      if (key) {
        diagram = await diagramApi.getDiagram(key);
      } else {
        diagram = await diagramApi.createDiagramTemplateFromLatest(versionId);
      }

      // TODO: с пустым объектов параметров не работает сохранение
      if (diagram && !diagram.inOutParameters) {
        diagram.inOutParameters = [];
      }

      dispatch(
        setCurrentObject({
          key: versionId,
          title: diagram.diagramName,
          objectInfo: diagram,
          version: diagram.versionId,
          readonly: false,
        })
      );

      return {
        ...diagram,
        key: versionId,
        location: '/Diagrams/',
        state: 'IN_DEVELOPMENT',
        version: diagram.versionId,
        type: 'diagram',
        draft: false,
        isSaved: true,
        isReadonly: false,
        historyChanges: [],
      };
    });
  };

export const openDiagramToView =
  (
    versionId: string,
    versionName?: string
  ): AppThunk<Promise<IDiagram | undefined>> =>
  async (dispatch) => {
    return await tryCatchDecorator(dispatch, async () => {
      const diagram = await diagramApi.getDiagram(versionId);
      const version = versionName ? `${versionName} ` : '';

      dispatch(
        setCurrentObject({
          key: versionId,
          title: version + diagram.diagramName,
          objectInfo: diagram,
          version: diagram.versionId,
          readonly: true,
        })
      );

      return {
        ...diagram,
        diagramName: diagram.diagramName,
        key: versionId,
        location: '/Diagrams/',
        state: 'IN_DEVELOPMENT',
        version: diagram.versionId,
        versionName,
        type: 'diagram-view',
        draft: false,
        isSaved: true,
        isReadonly: true,
        historyChanges: [],
      };
    });
  };

export const closeDiagram =
  (versionId: string): AppThunk =>
  async (dispatch) => {
    return await tryCatchDecorator(dispatch, async () => {
      await diagramApi.deleteDiagramTemplate(versionId);
    });
  };

export const saveDiagramJson =
  (versionId: string): AppThunk =>
  async (dispatch) =>
    await tryCatchDecorator(dispatch, async () => {
      const diagram = await diagramApi.getDiagram(versionId);
      const encodedUri = encodeURIComponent(JSON.stringify(diagram));
      const fullUri = 'data:text/json;charset=utf-8,' + encodedUri;
      const timeNow = dayjs().format('LTS L');
      const link = document.createElement('a');
      link.setAttribute('href', fullUri);
      link.setAttribute('download', `${diagram.diagramName} ${timeNow}.json`);
      document.body.appendChild(link);
      link.click();
    });

export const getDiagram = createAsyncThunk(
  'developmentPage/getDiagram',
  async (id: React.Key) => {
    const response = await diagramApi.getDiagram(id);
    return { ...response, historyChanges: [] };
  }
);

export const deleteDiagram =
  (versionId: Key): AppThunk<Promise<void>> =>
  async (dispatch) =>
    await tryCatchDecorator(dispatch, async () => {
      await diagramApi.deleteDiagram(versionId);
      dispatch(removeDiagram({ key: versionId }));
    });

export const editDiagram = createAsyncThunk(
  'developmentPage/editDiagram',
  async (payload: { id: string; body: IDiagramSave }) => {
    const { id, body } = payload;
    const response = await diagramApi.editDiagram(id, body);
    return { response, body };
  }
);

export const updateParameters =
  (): AppThunk<Promise<IResponse | undefined>> =>
  async (dispatch, getState) => {
    return await tryCatchDecorator(dispatch, async () => {
      const currentDiagram = findCurrentDiagram(getState().development);
      const inputParameters = selectDiagramInputParameters(getState());
      const lastSavedParameters = userStorage.get('lastSavedParameters');

      if (currentDiagram && currentDiagram.inOutParameters) {
        if (currentDiagram.isReadonly) return;

        if (
          JSON.stringify(lastSavedParameters) ===
          JSON.stringify(currentDiagram.inOutParameters)
        ) {
          return true;
        }

        const errorMessages = checkVariables(currentDiagram.inOutParameters);
        if (errorMessages.length) {
          return dispatch(
            showNotification({
              type: 'error',
              translate: 'development.diagram.diagramData.errorSave',
              translateOptions: { error: errorMessages[0] },
            })
          );
        }

        const response = await diagramApi.updateDiagramParameters(
          currentDiagram.version,
          currentDiagram.inOutParameters
        );

        userStorage.set('lastSavedParameters', currentDiagram.inOutParameters);
        return response;
      }
    });
  };

export const validateDiagram = createAsyncThunk(
  'developmentPage/validateDiagram',
  async (body: any) => {
    const response = await diagramApi.validateDiagram(body);
    return response;
  }
);

export const deployDiagram = createAsyncThunk(
  'developmentPage/deployDiagram',
  async (id: React.Key) => {
    const response = await diagramApi.deployDiagram(id);
    return response;
  }
);

export const setDiagramReadyToDeploy =
  (id: React.Key): AppThunk =>
  async (dispatch) => {
    await diagramApi
      .setDiagramReadyToDeploy(id)
      .then(() => {
        dispatch(
          showNotification({
            type: 'info',
            translate: 'deployDiagram.successSendOnDeploy',
            translateOptions: {
              id: id,
            },
          })
        );
      })
      .catch(() => {
        dispatch(
          showNotification({
            type: 'error',
            translate: 'deployDiagram.errorSendOnDeploy',
            translateOptions: {
              id: id,
            },
          })
        );
      });
  };

export const setDiagramsReadyToDeploy =
  (ids: React.Key[]): AppThunk =>
  async (dispatch) => {
    ids.map((id) => {
      dispatch(setDiagramReadyToDeploy(id));
    });
  };

export const renameDiagram = createAsyncThunk(
  'developmentPage/renameDiagram',
  async (data: { id: React.Key; title: string }) => {
    const response = await diagramApi.renameDiagram(data);
    return response;
  }
);

// Diagrams testing
type CreateTestProps = { diagramId: string; locale: 'ru' | 'en' };
export const createTest =
  ({ diagramId, locale }: CreateTestProps): AppThunk<Promise<string>> =>
  async (dispatch) => {
    const { testId } = await testApi.createTest(diagramId, locale);
    dispatch(getTestById(testId));
    return testId;
  };

export const getTestsByDiagramId =
  (diagramId: string): AppThunk =>
  async (dispatch) => {
    const data = await testApi.getTestsByDiagramId(diagramId);
    dispatch(setDiagramTests({ data }));
    return data;
  };

export const changeTestById =
  (data: ITestUpdate): AppThunk =>
  async (dispatch) => {
    const { testId, ...testBody } = data;
    const response = await testApi.changeTestById(testId, testBody);
    response.uuid && dispatch(getTestById(response.uuid));
  };

export const uploadTestFile =
  (testId: string, formData: FormData): AppThunk =>
  async (dispatch) => {
    await testApi.uploadTestFile(testId, formData);
    await dispatch(getTestById(testId));
  };

export const getTestById =
  (testId: string): AppThunk<Promise<ITest>> =>
  async (dispatch) => {
    const testBody = await testApi.getTestById(testId);
    dispatch(updateDiagramTest({ testBody }));
    return testBody;
  };

export const watchAndUpdateTestById =
  (testId: string): AppThunk =>
  async (dispatch: Dispatch) => {
    const fetchTest = async () => {
      const response = await testApi.getTestById(testId);
      if (response.status === 'IN_PROGRESS') setTimeout(fetchTest, 3000);
      else dispatch(updateDiagramTest({ testBody: response }));
    };
    fetchTest();
  };

export const deleteTestById = createAsyncThunk(
  'developmentPage/deleteTestById',
  async (testId: string) => {
    const response = await testApi.deleteTestById(testId);
    return response;
  }
);

export const startTests =
  (diagramId: string, testIds: any[], callback?: () => void): AppThunk =>
  async (dispatch) => {
    const func = async () => await testApi.startTests(diagramId, testIds);
    return await tryCatchDecorator(dispatch, func, undefined, callback);
  };

export const getTestingReport = createAsyncThunk(
  'developmentPage/getTestingReport',
  async (testId: string) => {
    const testCases = await testApi.getTestResultsById(testId);

    const response = await Promise.all(
      testCases.map(({ caseId }) => testApi.getTestCaseById(caseId))
    ).catch(() => null);

    return { testId, data: response };
  }
);

export const getTestResultsById = createAsyncThunk(
  'developmentPage/getTestResultsById',
  async (testId: string) => {
    const response = await testApi.getTestResultsById(testId);
    return { testId, data: response };
  }
);

export const getTestCaseById = createAsyncThunk(
  'developmentPage/getTestCaseById',
  async ({ testId, caseId }: { testId: string; caseId: string }) => {
    const response = await testApi.getTestCaseById(caseId);
    return { testId, caseId, data: response };
  }
);

// Diagrams node/link
export const copyNodes =
  (
    offset: number,
    copiedNodes?: CopiedNodes,
    fromHistory?: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    const ids: { [key: string]: string } = {};
    const { diagramId, nodes: CopiedNodes } =
      copiedNodes || getState().development.diagrams.copiedNodes;
    const diagram = findCurrentDiagramByKey(getState().development, diagramId);
    const currentDiagram = findCurrentDiagram(getState().development);
    const startNodesLength = selectDiagramStartNodesLength(getState());
    const lastPosition = getState().development.diagrams.lastPosition;

    if (currentDiagram && diagram && diagram.nodes) {
      dispatch(setIsLoading({ isLoading: true }));

      try {
        const newNodes: { nodeId: string; node: IDiagramNodeBase }[] = [];

        await Promise.all(
          CopiedNodes.map(async (node) => {
            const newNode: IDiagramNodeBase = {
              nodeTypeId: node.nodeTypeId,
              nodeName: node.nodeName,
              nodeDescription: node.nodeDescription,
              properties: node.properties,
              metaInfo: {
                ...node.metaInfo,
                position: {
                  x:
                    node.metaInfo.position.x -
                    CopiedNodes[0].metaInfo.position.x +
                    lastPosition.x +
                    20 +
                    offset,
                  y:
                    node.metaInfo.position.y -
                    CopiedNodes[0].metaInfo.position.y +
                    lastPosition.y +
                    20 +
                    offset,
                },
              },
            };

            const r = await diagramApi.createNode({
              ...newNode,
              diagramId: currentDiagram.key,
              diagramVersionId: currentDiagram.version,
            });
            if (r.uuid) {
              ids[node.nodeId] = r.uuid;
              newNodes.push({ nodeId: r.uuid, node: newNode });
              return r.uuid;
            }
          })
        );

        dispatch(addDiagramNodes({ nodes: newNodes }));

        if (diagram.links) {
          Object.values(diagram.links).forEach((link) => {
            Object.entries(ids).forEach(([oldIdA, newIdA]) => {
              if (link.prevNodeId === oldIdA) {
                Object.entries(ids).forEach(([oldIdB, newIdB]) => {
                  if (link.nextNodeId === oldIdB) {
                    dispatch(
                      addLink({
                        prevNodeId: newIdA,
                        nextNodeId: newIdB,
                        metaInfo: link.metaInfo,
                      })
                    );
                  }
                });
              }
            });
          });
        }

        return ids;
      } catch (e: any) {
        e?.message &&
          dispatch(showNotification({ type: 'error', message: e?.message }));
      } finally {
        dispatch(setIsLoading({ isLoading: false }));
        fromHistory && dispatch(removeHistoryElement({ ids: ids }));
        !fromHistory && dispatch(nodeAddHistory(Object.values(ids)));
      }
    }
  };

export const addNodes =
  (
    nodes: IDiagramNodeBase[],
    fromHistory?: boolean
  ): AppThunk<Promise<{ [key: string]: string } | undefined>> =>
  async (dispatch, getState) => {
    const currentDiagram = findCurrentDiagram(getState().development);
    if (currentDiagram) {
      const diagramId = currentDiagram.key;
      const diagramVersionId = currentDiagram.versionId;
      const ids: { [key: string]: string } = {};
      const startNodesLength = selectDiagramStartNodesLength(getState());
      dispatch(setIsLoading({ isLoading: true }));

      try {
        const newNodes: { nodeId: string; node: IDiagramNodeBase<any> }[] = [];

        await Promise.all(
          nodes.map(async (n) => {
            const { uuid } = await diagramApi.createNode({
              ...n,
              diagramId,
              diagramVersionId,
            });
            if (uuid) {
              // @ts-ignore
              ids[n.nodeId] = uuid;
              newNodes.push({ nodeId: uuid, node: n });
            }
          })
        );

        dispatch(addDiagramNodes({ nodes: newNodes }));
        !fromHistory && dispatch(nodeAddHistory(newNodes.map((n) => n.nodeId)));
        return ids;
        // return newNodes.map((n) => n.nodeId);
      } catch (e: any) {
        e?.message &&
          dispatch(showNotification({ type: 'error', message: e?.message }));
      } finally {
        dispatch(setIsLoading({ isLoading: false }));
      }
    }
  };

export const addNode =
  (node: IDiagramNodeBase): AppThunk<Promise<string | undefined>> =>
  async (dispatch, getState) => {
    const currentDiagram = findCurrentDiagram(getState().development);

    if (currentDiagram) {
      dispatch(setIsLoading({ isLoading: true }));
      const diagramId = currentDiagram.key;
      const diagramVersionId = currentDiagram.version;

      try {
        const { uuid } = await diagramApi.createNode({
          ...node,
          diagramId,
          diagramVersionId,
        });
        uuid && dispatch(addDiagramNodes({ nodes: [{ nodeId: uuid, node }] }));
        uuid && dispatch(nodeAddHistory([uuid]));
        return uuid;
      } catch (e: any) {
        e?.message &&
          dispatch(showNotification({ type: 'error', message: e?.message }));
      } finally {
        dispatch(setIsLoading({ isLoading: false }));
      }
    }
  };

export const updateNodes =
  (nodes: IDiagramNode[]): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const currentDiagram = findCurrentDiagram(getState().development);

    if (currentDiagram) {
      dispatch(setIsLoading({ isLoading: true }));
      const diagramId = currentDiagram.diagramId;
      const diagramVersionId = currentDiagram.versionId;

      try {
        const updatedNodes: { id: string; data: IDiagramNode }[] = [];

        await Promise.all(
          nodes.map(async (node) => {
            const { uuid } = await diagramApi.updateNodeById(node.nodeId, {
              ...node,
              diagramVersionId,
              diagramId,
            });

            uuid && updatedNodes.push({ id: uuid, data: node });
          })
        );

        updatedNodes.length &&
          dispatch(updateDiagramNodes({ nodes: updatedNodes }));
      } catch (e: any) {
        e?.message &&
          dispatch(showNotification({ type: 'error', message: e?.message }));
      } finally {
        dispatch(setIsLoading({ isLoading: false }));
      }
    }
  };

export const historyBack = (): AppThunk => (dispatch, getState) => {
  const currentDiagram = findCurrentDiagram(getState().development);
  const history = currentDiagram?.historyChanges;
  const historyAction = history && history[history.length - 1];

  if (historyAction) {
    switch (historyAction.type) {
      case 'update':
        dispatch(updateNodes(historyAction.nodes));
        dispatch(removeHistoryElement({}));
        break;
      case 'delete':
        dispatch(deleteNodes(historyAction.nodes, true));
        dispatch(removeHistoryElement({}));
        break;
      case 'add':
        dispatch(addNodes(historyAction.nodes, true)).then((ids) => {
          dispatch(removeHistoryElement({ ids: ids }));
        });
        break;
      case 'deleteLink':
        historyAction.link &&
          typeof historyAction.link === 'string' &&
          dispatch(deleteLink(historyAction.link, true));
        break;
      case 'addLink':
        historyAction.link &&
          typeof historyAction.link != 'string' &&
          dispatch(addLink(historyAction.link, true));
    }
  }
};

export const updateBranchNode =
  (
    node: IDiagramNode<IBranchProperties>,
    newNodes?: ICreatedNewNode[]
  ): AppThunk =>
  async (dispatch, getState) => {
    const updatedNode = node;
    const currentDiagram = findCurrentDiagram(getState().development);

    if (currentDiagram && currentDiagram.nodes) {
      const newCreatedNodes: { [key: string]: IDiagramNodeBase } = {};
      const newCreatedLinks: IDiagramLinkBase[] = [];
      const { nodes } = currentDiagram;
      const { links } = currentDiagram;
      const { x: branchX, y: branchY } = updatedNode.metaInfo.position;

      const setPositionNewBlock = (index: number) => {
        if (index === 0) {
          return { x: branchX + 420, y: branchY };
        } else return { x: branchX + 420, y: branchY + index * 210 };
      };

      if (newNodes?.length) {
        newNodes.forEach((nd, index) => {
          newCreatedNodes[nd.code] = {
            nodeName: nd.nodeName,
            nodeTypeId: nd.nodeType,
            nodeDescription: '',
            properties: null,
            metaInfo: { position: setPositionNewBlock(index) },
          };
        });
      }

      const getLink = (prevId: string, nexId: string) => {
        return Boolean(
          links &&
            Object.values(links).find(
              (l) => l.prevNodeId === prevId && l.nextNodeId === nexId
            )
        );
      };

      const addNodeAndLink = async (id: string) => {
        if (nodes[id]) {
          if (!getLink(updatedNode.nodeId, nodes[id].nodeId)) {
            const link = getNodeHandles(
              updatedNode.metaInfo.position,
              nodes[id].metaInfo.position
            );

            newCreatedLinks.push({
              prevNodeId: updatedNode.nodeId,
              nextNodeId: nodes[id].nodeId,
              metaInfo: link,
            });
          }
        } else {
          const link = getNodeHandles(updatedNode.metaInfo.position);
          const nodeId = await dispatch(addNode(newCreatedNodes[id]));

          if (nodeId) {
            newCreatedLinks.push({
              prevNodeId: updatedNode.nodeId,
              nextNodeId: nodeId,
              metaInfo: link,
            });

            return nodeId;
          }
        }
      };

      if (updatedNode.properties.branches.length) {
        await Promise.all(
          updatedNode.properties.branches.map(async (branch: Branch, index) => {
            if (branch.path) {
              const nodeId = await addNodeAndLink(branch.path);
              if (nodeId) updatedNode.properties.branches[index].path = nodeId;
            }
          })
        );
      }

      if (updatedNode.properties.defaultPath) {
        const nodeId = await addNodeAndLink(updatedNode.properties.defaultPath);
        if (nodeId) updatedNode.properties.defaultPath = nodeId;
      }

      await dispatch(updateNodes([updatedNode]));
      await Promise.all(newCreatedLinks.map((link) => dispatch(addLink(link))));
    }
  };

export const deleteNodes =
  (nodeIds: string[], fromHistory?: boolean): AppThunk =>
  async (dispatch, getState) => {
    return tryCatchDecorator(
      dispatch,
      async () => {
        const deletedNodesIds: string[] = [];
        const currentDiagram = findCurrentDiagram(getState().development);

        await Promise.all(
          nodeIds.map(async (nodeId) => {
            const { uuid } = await diagramApi.deleteNodeById(nodeId);
            uuid && deletedNodesIds.push(uuid);
          })
        );

        if (deletedNodesIds.length) {
          dispatch(deleteDiagramNodes({ ids: deletedNodesIds, fromHistory }));
        }
      },
      undefined,
      () => dispatch(setSelectedNodes({ selectedNodes: [] }))
    );
  };

export const addLink =
  (
    link: IDiagramLinkBase,
    fromHistory?: boolean
  ): AppThunk<Promise<string | undefined>> =>
  async (dispatch, getState) => {
    const currentDiagram = findCurrentDiagram(getState().development);

    if (currentDiagram && currentDiagram.nodes) {
      const diagramId = currentDiagram.key;
      const diagramVersionId = currentDiagram.version;
      const prevNode = currentDiagram.nodes[link.prevNodeId];
      const nextNode = currentDiagram.nodes[link.nextNodeId];

      if (link.prevNodeId !== link.nextNodeId) {
        dispatch(setIsLoading({ isLoading: true }));
        try {
          const { uuid } = await diagramApi.createLink({
            ...link,
            diagramId,
            diagramVersionId,
          });

          if (uuid) {
            dispatch(addDiagramLink({ linkId: uuid, ...link }));
            !fromHistory && dispatch(nodeAddLinkHistory(uuid));
            fromHistory && dispatch(removeHistoryElement({}));
          }

          return uuid;
        } finally {
          dispatch(setIsLoading({ isLoading: false }));
        }
      } else {
        dispatch(
          showNotification({
            type: 'error',
            translate:
              'development.diagram.diagram.errors.nodeReferencesItself',
          })
        );
      }
    }
  };

export const deleteLink =
  (
    linkId: string,
    fromHistory?: boolean
  ): AppThunk<Promise<string | undefined>> =>
  async (dispatch, getState) => {
    return await tryCatchDecorator(dispatch, async () => {
      const { uuid: deletedLinkId } = await diagramApi.deleteLink(linkId);
      const currentDiagram = findCurrentDiagram(getState().development);
      const deletedLink = currentDiagram?.links?.[linkId];

      if (currentDiagram && currentDiagram.nodes && deletedLink) {
        const prevNode = currentDiagram.nodes[deletedLink.prevNodeId];
      }

      if (deletedLinkId) {
        if (!fromHistory && currentDiagram?.links) {
          dispatch(nodeDeleteLinkHistory(currentDiagram?.links[linkId]));
        }
        fromHistory && dispatch(removeHistoryElement({}));
        dispatch(deleteDiagramLink({ id: deletedLinkId }));
      }

      return deletedLinkId;
    });
  };

export const moveLink =
  (linkId: string, link: IDiagramLinkBase): AppThunk =>
  async (dispatch) => {
    await dispatch(deleteLink(linkId));
    await dispatch(addLink(link));
  };

// Custom Code
export const getCustomCodes = createAsyncThunk(
  'development/getCustomCodes',
  async (_, { dispatch }) => {
    // @ts-ignore
    return await tryCatchDecorator(dispatch, async () => {
      const response = await customCodeApi.getCustomCodes();
      return response;
    });
  }
);

export const getCustomCode = createAsyncThunk(
  'developmentPage/getCustomCode',
  async ({ id, versionName }: { id: React.Key; versionName?: string }) => {
    const response = await customCodeApi.getCustomCode(id);
    if (versionName) response.versionName = versionName;
    return response;
  }
);

export const createCustomCode = createAsyncThunk(
  'developmentPage/createCustomCode',
  async (body: any) => {
    const response = await customCodeApi.createCustomCode(body);
    return response;
  }
);

export const editCustomCode = createAsyncThunk(
  'developmentPage/editCustomCode',
  async (payload: any) => {
    const response = await customCodeApi.editCustomCode(payload);
    return response;
  }
);

export const deleteCustomCode =
  (versionId: Key): AppThunk<Promise<void>> =>
  async (dispatch) =>
    await tryCatchDecorator(dispatch, async () => {
      await customCodeApi.deleteCustomCode(versionId);
      dispatch(removeCustomCode({ key: versionId }));
    });

export const validateCustomCode =
  (body: ScriptValidate): AppThunk =>
  async (dispatch) =>
    await tryCatchDecorator(dispatch, async () => {
      const response = await customCodeApi.validateCustomCode(body);
      dispatch(showNotification({ type: 'info', message: response.message }));
    });

export const deployCustomCode = createAsyncThunk(
  'developmentPage/deployCustomCode',
  async (id: React.Key) => {
    const response = await customCodeApi.deployCustomCode(id);
    return response;
  }
);

// Aggregates
export const getAggregates = createAsyncThunk(
  'developmentPage/getAggregates',
  async (_, { dispatch }) => {
    // @ts-ignore
    return await tryCatchDecorator(dispatch, async () => {
      const response = await aggregatesApi.getAggregates();
      return response;
    });
  }
);

export const getAggregate = createAsyncThunk(
  'developmentPage/getAggregate',
  async (id: React.Key) => {
    const response = await aggregatesApi.getAggregate(id);
    return response;
  }
);

export const createAggregate = createAsyncThunk(
  'developmentPage/createAggregate',
  async (body: any) => {
    const response = await aggregatesApi.createAggregate(body);
    return response;
  }
);

export const editAggregate = createAsyncThunk(
  'developmentPage/editAggregate',
  async (payload: any) => {
    const response = await aggregatesApi.editAggregate(payload);
    return response;
  }
);

export const deleteAggregate = createAsyncThunk(
  'developmentPage/removeAggregate',
  async (id: React.Key) => {
    const response = await aggregatesApi.deleteAggregate(id);
    return response;
  }
);

// export const getRelations = createAsyncThunk(
//   'developmentPage/getRelations',
//   async (id: React.Key) => {
//     const response = await aggregatesApi.getRelations(id);
//     return response;
//   }
// );

export const getGroupingElements = createAsyncThunk(
  'developmentPage/getGroupingElements',
  async () => {
    const response = await aggregatesApi.getGroupingElements();
    return response;
  }
);

export const parseExcel = createAsyncThunk(
  'developmentPage/parseExcel',
  async (file: any) => {
    const response = await diagramApi.parseExcel(file);
    return response;
  }
);

export const initialState: IDevelopmentState = {
  isLoading: false,
  diagrams: {
    selectedRowTable: [],
    search: '',
    sort: {},
    data: [],
    copiedNodes: { diagramId: '', nodes: [] },
    selectedNodes: [],
    lastPosition: { x: 0, y: 0 },
  },
  customCode: {
    selectedRowTable: [],
    search: '',
    sort: {},
    data: [],
  },
  aggregates: {
    selectedRowTable: [],
    search: '',
    showError: false,
    data: [],
    groupingElements: [],
  },
  currentObject: {
    key: '',
    title: '',
    readonly: false,
  },
  openObjects: [],
  openTabs: [],
  modal: {
    openModals: [],
  },
  groupDiagrams: [],
  customDataTypes: { key: CUSTOMDATATYPES_OBJTYPE, selectedRowTable: [] },
  mappingRows: [],
  schemas: { key: SCHEMA_OBJTYPE, selectedRowTable: [] },
};

export const developmentSlice = createSlice({
  name: DEVELOPMENT_FESTURE_KEY,
  initialState,
  reducers: {
    ...diagramsReducers,
    ...diagramReducers,
    ...generalReducers,
    ...diagramDataReducers,
    ...diagramTestingReducers,
    ...diagramTestingReportReducers,
    ...diagramEditorReducers,
    ...customCodeListReducers,
    ...customCodeReducers,
    ...schemaReducers,
    ...customDataTypesReducesr,
    ...aggregatesReducers,
    ...aggregateReducers,
    customCodeUpdate(state, action) {
      const { customCodeKey, title } = action.payload;
      const existingCustomCode = state.customCode.data.find(
        (code) => code.key === customCodeKey
      );
      if (existingCustomCode) {
        existingCustomCode.title = title;
      }
    },
    addOpenTab(state, action) {
      const { tab } = action.payload;
      console.log(tab);
      if (tab) state.openTabs = [...state.openTabs, tab];
    },
    removeOpenTab(state, action) {
      const { key } = action.payload;
      state.openTabs = state.openTabs.filter((item) => item.key !== key);
    },
    updateOpenTab(state, action) {
      const { key, tab } = action.payload;

      state.openTabs = state.openTabs.map((openTab) => {
        if (openTab.key === key) {
          return { ...openTab, ...tab };
        }
        return openTab;
      });
    },
    addOpenObject(state, action: IAction<{ object: IDiagram | IAggregate }>) {
      const { object } = action.payload;
      state.openObjects = [
        ...state.openObjects,
        {
          ...object,
          isSaved: true,
          isReadonly: state.currentObject.readonly,
          historyChanges: [],
        },
      ];
    },
    addOpenCustomCodeObject(state, action) {
      const { key } = action.payload;
      const object = [...state.diagrams.data, ...state.customCode.data].find(
        (item) => item.key === key
      );
      if (object)
        state.openObjects = [
          ...state.openObjects,
          {
            ...object,
            isSaved: true,
            isReadonly: state.currentObject.readonly,
          },
        ];
    },
    removeOpenObject(state, action) {
      const { key } = action.payload;
      state.openObjects = state.openObjects.filter((item) => item.key !== key);
    },
    addOpenModal(state, action) {
      const { modalInfo } = action.payload;
      state.modal.openModals = [...state.modal.openModals, modalInfo];
    },
    removeOpenModal(state, action) {
      state.modal.openModals = state.modal.openModals.slice(0, -1);
    },
    addGroupDiagrams(state, action) {
      const newArray = [...state.groupDiagrams];
      newArray.push(action.payload);
      state.groupDiagrams = newArray;
    },
    clearMappingRows(state) {
      state.mappingRows = [];
    },
    changeSaveStatusObject(state, action) {
      const currentDiagram = findCurrentDiagram(state);
      if (currentDiagram) currentDiagram.isSaved = action.payload.saveStatus;
    },
    setIsLoading(state, action: IAction<{ isLoading: boolean }>) {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
    setLastPosition(state, action: IAction<XYPosition>) {
      state.diagrams.lastPosition = action.payload;
    },
    setCurrentObject(state, action: IAction<Partial<ICurrentObject>>) {
      state.currentObject = { ...state.currentObject, ...action.payload };
    },
  },

  extraReducers: (builder) => {
    // получение конкретной диаграммы
    builder.addCase(getDiagram.fulfilled, (state, action) => {
      state.currentObject = {
        ...state.currentObject,
        key: action.payload?.diagramId || '',
        title: action.payload?.diagramName || '',
        objectInfo: action.payload,
      };
    });
    // editDiagram
    builder.addCase(editDiagram.fulfilled, (state, action) => {
      if (!action.payload.response.uuid) return;
      state.currentObject = {
        ...state.currentObject,
        key: action.payload.response.uuid,
        title: action.payload.body.diagramName,
        objectInfo: action.payload.body,
      };
    });
    // Удаление теста
    builder.addCase(deleteTestById.fulfilled, (state, action) => {
      const { uuid } = action.payload;
      const currentDiagram = findCurrentDiagram(state);
      if (currentDiagram && currentDiagram.testing) {
        currentDiagram.testing = {
          ...currentDiagram.testing,
          data: currentDiagram.testing.data?.filter(
            (test) => test.testId !== uuid
          ),
        };
      }
    });
    // установка тест-кейсов для отчета
    builder.addCase(getTestingReport.fulfilled, (state, action) => {
      const { testId, data } = action.payload;
      const currentDiagram = findCurrentDiagram(state);
      if (currentDiagram && currentDiagram.testing && data) {
        currentDiagram.testing = {
          ...currentDiagram.testing,
          testReports: {
            ...currentDiagram.testing.testReports,
            [testId]: data,
          },
        };
      }
    });
    // установка результатов теста по id
    builder.addCase(getTestResultsById.pending, (state) => {
      const diagram = findCurrentDiagram(state);
      if (diagram && diagram.testing) {
        diagram.testing = { ...diagram.testing, loading: true };
      }
    });
    builder.addCase(getTestResultsById.fulfilled, (state, action) => {
      const { testId, data } = action.payload;
      const sortedData = data.sort((a, b) => {
        const i = +a.caseId.split('-')[a.caseId.split('-').length - 1];
        const j = +b.caseId.split('-')[b.caseId.split('-').length - 1];
        return i - j;
      });
      const diagram = findCurrentDiagram(state);
      if (diagram && diagram.testing && sortedData) {
        diagram.testing = {
          ...diagram.testing,
          testReports: { ...diagram.testing.testReports, [testId]: sortedData },
          loading: false,
        };
      }
    });
    builder.addCase(getTestResultsById.rejected, (state) => {
      const diagram = findCurrentDiagram(state);
      if (diagram && diagram.testing) {
        diagram.testing = { ...diagram.testing, loading: false };
      }
    });
    // установка установка тест кейса
    builder.addCase(getTestCaseById.pending, (state, action) => {
      const diagram = findCurrentDiagram(state);
      if (diagram && diagram.testing) {
        diagram.testing = { ...diagram.testing, loading: true };
      }
    });
    builder.addCase(getTestCaseById.fulfilled, (state, action) => {
      const { testId, caseId, data } = action.payload;
      const diagram = findCurrentDiagram(state);
      if (diagram && diagram.testing && diagram.testing?.testReports && data) {
        diagram.testing = {
          ...diagram.testing,
          testReports: {
            ...diagram.testing.testReports,
            [testId]: diagram.testing.testReports[testId]?.map((item) => {
              if (item.caseId === caseId) {
                return { ...item, ...data };
              }
              return item;
            }),
          },
          loading: false,
        };
      }
    });
    builder.addCase(getTestCaseById.rejected, (state) => {
      const diagram = findCurrentDiagram(state);
      if (diagram && diagram.testing) {
        diagram.testing = { ...diagram.testing, loading: false };
      }
    });
    // переименование диаграммы
    builder.addCase(renameDiagram.fulfilled, (state, action) => {
      const { uuid, title } = action.payload;
      const existingDiagram = state.diagrams.data.find(
        (diagram: { key: string }) => diagram.key === uuid
      );
      if (existingDiagram) {
        existingDiagram.diagramName = title;
      }
    });
    // создание custom-code
    builder.addCase(createCustomCode.fulfilled, (state, action) => {
      state.currentObject.key = action.payload.uuid;
    });
    // получение агрегата
    builder.addCase(getAggregate.fulfilled, (state, action) => {
      state.currentObject = {
        ...state.currentObject,
        key: action.payload?.versionId || '',
        title: action.payload?.aggregateName || '',
        objectInfo: action.payload,
      };
    });
    // создание агрегата
    builder.addCase(createAggregate.fulfilled, (state, action) => {
      state.currentObject.key = action.payload.uuid;
    });
    // получение связей агрегата
    // builder.addCase(getRelations.fulfilled, (state, action) => {
    //   state.aggregates.data = state.aggregates.data.map((aggregate) => {
    //     if (aggregate.aggregateId === action.payload?.[0]?.aggregateId) {
    //       return {
    //         ...aggregate,
    //         diagramCalcId: action.payload?.[0]?.diagramCalcId,
    //         diagramCalcName: action.payload?.[0]?.diagramCalcName,
    //         diagramReadId: action.payload?.[0]?.diagramReadId,
    //         diagramReadName: action.payload?.[0]?.diagramReadName,
    //       };
    //     }
    //     return aggregate;
    //   });
    // });
    // получение группирующих элементов
    builder.addCase(getGroupingElements.fulfilled, (state, action) => {
      state.aggregates.groupingElements = action.payload;
    });
    // парсинг excel для скоркарты
    builder.addCase(parseExcel.fulfilled, (state, action) => {
      state.mappingRows = action.payload;
    });
  },
});

export const {
  setCurrentObject,

  // diagram
  setDiagramKey,
  setDiagramTitle,
  setReadonly,
  onSelectedDiagramsRow,
  onChangeDiagramsSearch,
  onChangeDiagramsSort,
  setIsLoading,
  setLastPosition,

  // general information
  changeTitle,
  changeCode,
  changeDescription,

  // diagram data
  updateDiagramData,
  addDiagramDataRow,
  deleteDiagramDataRow,
  setSelectedRowKeysDiagramData,
  updateDiagramErrorResponseFlag,

  // custom code list
  customCodeSeeds,
  customCodeUpdate,
  addCustomCode,
  removeCustomCode,
  onSelectedCustomCodeRow,
  onChangeCustomCodeSearch,
  onChangeCustomCodeSort,

  // custom code
  setCustomCodeKey,
  setCustomCodeTitle,
  setCurrentCustomCodeTitle,
  setCustomCodeReadonly,
  addOpenCustomCodeObject,
  setCustomCodeScriptText,
  addCustomCodeInputRow,
  deleteCustomCodeInputRows,
  setCustomCodeInputName,
  setCustomCodeInputIsArray,
  setCustomCodeInputType,
  addCustomCodeOutputRow,
  deleteCustomCodeOutputRows,
  setCustomCodeOutputName,
  setCustomCodeOutputIsArray,
  setCustomCodeOutputType,

  // diagram editor
  removeHistoryElement,
  addDiagramGroup,
  removeDiagramGroup,
  nodeUpdateHistory,
  nodeAddHistory,
  nodeAddLinkHistory,
  nodeDeleteLinkHistory,

  // diagrams
  diagramsSeeds,
  diagramsUpdate,
  removeDiagram,
  addOpenTab,
  updateOpenTab,
  removeOpenTab,
  addOpenObject,
  removeOpenObject,
  addOpenModal,
  removeOpenModal,
  setCopyNodes,
  setSelectedNodes,
  changeSaveStatusObject,

  // calcScoreCard
  clearMappingRows,

  // diagram node/link
  addDiagramNodes,
  updateDiagramNodes,
  deleteDiagramNodes,
  addDiagramLink,
  deleteDiagramLink,

  // group diagrams
  addGroupDiagrams,

  // diagram testing
  setDiagramTests,
  updateDiagramTest,
  addDiagramTest,
  setSelectedRowKeysDiagramTesting,
  resetDiagramTestReport,

  // schemas
  addSchema,
  cancelAddSchema,
  onSelectedSchemaRows,

  // custom data types
  addCustomDataType,
  onSelectedCustomDataTypesRows,
  onChangeCustomDataTypesSearch,
  cancelAdd,

  // aggregates
  addAgregate,
  removeAgregate,
  aggregatesSeeds,
  onSelectedAggregatesRow,
  onChangeAggregatesSearch,

  // aggregate
  setAggregateName,
  setAggregateDescription,
  setAggregateVariableType,
  setAggregateFunction,
  setAggregateGroupingElement,
  setShowError,
} = developmentSlice.actions;

export const selectDiagrams = (state: RootState) => state.development.diagrams;
export const selectCopyNodes = (state: RootState) =>
  state.development.diagrams.copiedNodes;
export const selectSelectedNodes = (state: RootState) =>
  state.development.diagrams.selectedNodes;
export const getSelectedDiagramsRow = (state: RootState) =>
  state.development.diagrams.selectedRowTable;
export const getSearchDiagramsTable = (state: RootState) =>
  state.development.diagrams.search;
export const selectDiagramSort = (state: RootState) =>
  state.development.diagrams.sort;
export const getDiagramsDataKeys = (state: RootState) =>
  state.development.diagrams.data.map((d) => d.key);
export const getDiagramsDataVersions = (state: RootState) =>
  state.development.diagrams.data.map((d) => d.version);

export const selectCustomCodes = (state: RootState) =>
  state.development.customCode;
export const selectCustomCode = (state: RootState) =>
  state.development.customCode;
export const getSelectedCustomCodeRow = (state: RootState) =>
  state.development.customCode.selectedRowTable;
export const getSearchCustomCodeTable = (state: RootState) =>
  state.development.customCode.search;
export const selectCustomCodeSort = (state: RootState) =>
  state.development.customCode.sort;
export const getCustomCodeDataKeys = (state: RootState) =>
  state.development.customCode.data.map((d) => d.key);
export const selecCustomCodeFilteredData = createSelector(
  selectCustomCodes,
  (customCode) => {
    if (customCode.search && customCode.search.length) {
      const byTitle = customCode.data.filter((entry) =>
        entry.title.toLowerCase().includes(customCode.search.toLowerCase())
      );

      const byCode = customCode.data.filter((entry) =>
        entry.code.toLowerCase().includes(customCode.search.toLowerCase())
      );

      return filterEqualByKey(byTitle.concat(byCode), 'code');
    }

    return customCode.data;
  }
);
export const selecCustomCodeFilteredDataKeys = createSelector(
  selecCustomCodeFilteredData,
  (data) => data.map((d) => d.key)
);
export const selectOpenTabs = (state: RootState) => state.development?.openTabs;

export const selectAggregates = (state: RootState) =>
  state.development.aggregates;
export const selectAggregatesData = (state: RootState) =>
  state.development.aggregates.data;
export const selectAggregatesSelectedRows = (state: RootState) =>
  state.development.aggregates.selectedRowTable;
export const selectAggregatesKeys = (state: RootState) =>
  state.development.aggregates.data.map((aggr) => aggr.key);
export const selectAggregatesSearch = (state: RootState) =>
  state.development.aggregates.search;
export const selectAggregatesFilteredData = createSelector(
  selectAggregatesData,
  selectAggregatesSearch,
  (data, search) => filterByValue<IAggregate>(data, 'aggregateName', search)
);
export const selectAggregatesFilteredDataKeys = createSelector(
  selectAggregatesFilteredData,
  (data) => data.map((d) => d.key)
);
export const selectGroupingElements = (state: RootState) =>
  state.development.aggregates.groupingElements;
export const selectError = (state: RootState) =>
  state.development.aggregates.showError;
export const diagram = (state: RootState) => {
  return state.development.currentObject;
};

export const getSelectedRowKeysDiagramData = (state: RootState) => {
  const currentObject = state.development.openObjects.find(
    (item) => item.key === state.development.currentObject.key
  );
  return currentObject?.meta?.diagramDataSelectedRowKeys || [];
};

export const groupDiagrams = (state: RootState) =>
  state.development.groupDiagrams;

export const currentObjectKey = (state: RootState) =>
  state.development.currentObject.key;
export const currentObjectReadonly = (state: RootState) =>
  state.development.currentObject.readonly;

export const selectCurrentObjectReadonly = (state: RootState) => {
  const currentObject = state.development.openObjects.find(
    (item) => item.key === state.development.currentObject.key
  );
  return currentObject?.isReadonly;
};

export const openObjects = (state: RootState) => state.development.openObjects;

export const selectCurrentObject = (state: RootState) => {
  const currentObject = state.development.openObjects.find(
    (item) => item.key === state.development.currentObject.key
  );
  return currentObject || {};
};

export const selectCurrentDiagram = createSelector(
  selectCurrentObject,
  (diagram) => diagram as IDiagram
);

export const selectCurrentDiagramHistory = createSelector(
  selectCurrentDiagram,
  (diagram) => diagram?.historyChanges
);

export const selectDiagramParameters = createSelector(
  selectCurrentDiagram,
  (diagram) => {
    return diagram?.inOutParameters || [];
  }
);

export const selectDiagramInputParameters = createSelector(
  selectDiagramParameters,
  (inOutParameters) => {
    return inOutParameters.filter((p) =>
      [ParameterTypes.in, ParameterTypes.inOut].includes(p.parameterType)
    );
  }
);

export const selectDiagramOutputParameters = createSelector(
  selectDiagramParameters,
  (inOutParameters) => {
    return inOutParameters.filter((p) =>
      [ParameterTypes.out, ParameterTypes.inOut].includes(p.parameterType)
    );
  }
);

export const selectDiagramNodes = createSelector(
  selectCurrentObject,
  (currObj) => {
    return (currObj as IDiagram)?.nodes;
  }
);

export const selectDiagramLinks = createSelector(
  selectCurrentObject,
  (currObj) => {
    return (currObj as IDiagram)?.links;
  }
);

export const selectDiagramStartNodesLength = createSelector(
  selectDiagramNodes,
  (nodes) => {
    return nodes && Object.values(nodes)?.length;
  }
);

export const selectDiagramTests = createSelector(
  selectCurrentObject,
  (currObj) => {
    return (currObj as IDiagram)?.testing?.data || [];
  }
);

export const selectDiagramTestsLoading = createSelector(
  selectCurrentObject,
  (currObj) => {
    return (currObj as IDiagram)?.testing.loading;
  }
);

export const selectDiagramTestsSelectedRowKeys = createSelector(
  selectCurrentObject,
  (currObj) => {
    return (currObj as IDiagram)?.testing?.selectedRowKeys || [];
  }
);

export const selectDiagramTestReports = createSelector(
  selectCurrentObject,
  (currObj) => (currObj as IDiagram)?.testing?.testReports || {}
);

export const selectDiagramTestReportByTestId =
  (testId: string) => (state: RootState) => {
    const testReports = selectDiagramTestReports(state);
    return testReports?.[testId];
  };

export const openModals = (state: RootState) =>
  state.development.modal?.openModals;

export const selectIsSavedObject = (state: RootState) => {
  return state.development.openObjects.find(
    (item) => item.key === state.development.currentObject.key
  )?.isSaved;
};
export const selectMappingRows = (state: RootState) =>
  state.development.mappingRows;

export const selectDevelopmentIsLoading = (state: RootState) =>
  state.development.isLoading;

export default developmentSlice.reducer;
