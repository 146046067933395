import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import './TabSliderPanel.scss';
import { Icon } from '@modules/icon';
import { ITabPanelItem } from '@shared';

export interface ITabSliderPanel {
  items: ITabPanelItem[];
  level: number;
  type?: string;
  active: string;
  disabledKeys?: string[];
  className?: string;
  onClick: (
    e: any,
    key: string,
    type: string,
    path?: string,
    state?: any
  ) => void;
  onClickClose?: (key: string, type: string, version: string) => void;
  textAlignCenter?: boolean;
}

const TabSliderPanel: React.FC<ITabSliderPanel> = (props): any => {
  const { items, level, active, className, onClick, onClickClose } = props;
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [isShowButtons, setIsShowButtons] = useState(false);

  const onWheel = (event: any) => {
    if (event.deltaY > 0) {
      onScrollRight();
    } else {
      onScrollLeft();
    }
  };

  const onScrollLeft = () => {
    if (scrollRef.current !== null) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft - 169,
        behavior: 'smooth',
      });
    }
  };

  const onScrollRight = () => {
    if (scrollRef.current !== null) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft + 169,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (scrollRef.current !== null) {
      if (scrollRef.current.scrollWidth > scrollRef.current.offsetWidth) {
        return setIsShowButtons(true);
      }

      setIsShowButtons(false);
    }
  }, [items]);

  return (
    <div
      className={classnames(className, !items.length && `${className}--empty`)}
    >
      <div
        className={classnames(
          'tab-slider-panel',
          !items.length && 'tab-slider-panel--empty'
        )}
        onWheel={onWheel}
      >
        {isShowButtons && (
          <button
            className="tab-slider-panel__button tab-slider-panel__button--prev"
            onClick={onScrollLeft}
          >
            <Icon name="icon-arrow_left" size={16} />
          </button>
        )}

        {Boolean(items.length) && (
          <div className="tab-slider-panel__scroll" ref={scrollRef}>
            {items.map((data: any) => (
              <Tooltip
                title={data.translation ? t(data.translation) : data.text}
                key={`tab-slider-item-${data.key}`}
                placement="bottom"
              >
                <div
                  className={classnames(
                    'tab-slider-panel__item',
                    data.key === active ? 'active' : '',
                    `level-${level}`
                  )}
                  data-index={data.key}
                  onClick={(e) =>
                    onClick(e, data.key, data.type, data.path, data.state)
                  }
                >
                  <p
                    className="tab-slider-panel__item-title"
                    style={{
                      paddingRight: data.padding ? `${data.padding}px` : '',
                    }}
                  >
                    <span>
                      {data.translation ? t(data.translation) : data.text}
                    </span>
                  </p>

                  <Icon
                    className="tab-slider-panel__item-close"
                    name="icon-close"
                    size={16}
                    onClick={
                      onClickClose
                        ? (event) => {
                            event.stopPropagation();
                            onClickClose(data.key, data.type, data.version);
                          }
                        : undefined
                    }
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        )}

        {isShowButtons && (
          <button
            className="tab-slider-panel__button tab-slider-panel__button--next"
            onClick={onScrollRight}
          >
            <Icon name="icon-arrow_right" size={16} />
          </button>
        )}
      </div>
    </div>
  );
};

export { TabSliderPanel };
