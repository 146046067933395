import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  id: string;
  children: ReactNode;
}

export const Portal: React.FC<IProps> = ({ children, id }: IProps) => {
  const portalDiv = document.getElementById(id) as HTMLElement;

  return portalDiv ? ReactDOM.createPortal(children, portalDiv) : null;
};
