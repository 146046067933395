import { AppDispatch } from '@modules/store';
import { ICustomCode, IDiagram } from '../types';
import {
  createCustomCode,
  editCustomCode,
  saveDiagram,
} from '../DevelopmentSlice';

export const onSaveClick = async (
  data: IDiagram | ICustomCode,
  dispatch: AppDispatch
) => {
  console.log(data.type);
  switch (data.type) {
    case 'diagram':
      const {
        key,
        diagramName,
        diagramId,
        versionId,
        diagramDescription,
        errorResponseFlag,
      } = data as IDiagram;

      await dispatch(
        saveDiagram(key, {
          diagramName,
          diagramId,
          versionId,
          diagramDescription,
          errorResponseFlag,
        })
      );
      break;

    case 'customCode':
      const { draft, scriptJson, title, variables } = data as ICustomCode;

      const body = {
        scriptJson: JSON.stringify(scriptJson),
        scriptName: title,
        variables,
      };

      if (draft) dispatch(createCustomCode(body));
      else dispatch(editCustomCode({ id: data.versionId, body }));
      break;
  }
};
