import React, { VFC } from 'react';
import { Checkbox } from 'antd';
import './createEnvironmentModal.scss';
import {
  AccordionNew as Accordion,
  Button,
  FieldGroup,
  IModalProps,
  Input,
  InputPassword,
  Modal,
  ModalBox,
  Select,
  SimpleTable,
  useAdministrationModalVisible,
} from '@shared';
import { useTranslation } from '@modules/languageProvider';
import { getTranslation as getTranslationUtils } from '../../utils';
import { ColumnsType } from 'antd/lib/table';

interface ICreateEnvironmentModal {
  modalProps: IModalProps;
  onClose: () => void;
}

const getTranslation = (value: string) =>
  getTranslationUtils(`modals.createEnvironment.${value}`);

export const CreateEnvironmentModal: VFC<ICreateEnvironmentModal> = ({
  modalProps,
  onClose,
}) => {
  const { t } = useTranslation();
  const isVisible = useAdministrationModalVisible(modalProps.type);

  const columns: ColumnsType = [
    {
      title: t(getTranslation('parameterName')),
      dataIndex: 'parameterName',
      width: '30%',
      render: (value) => <Input value={value} />,
    },
    {
      title: t(getTranslation('parameterValue')),
      dataIndex: 'parameterValue',
      width: '30%',
      render: (value, row: any) => (
        <Input
          type={row.parameterConfidential ? 'password' : 'text'}
          value={value}
        />
      ),
    },
    {
      title: t(getTranslation('parameterConfidential')),
      dataIndex: 'parameterConfidential',
      width: '40%',
      align: 'center',
      render: (value) => <Checkbox checked={value} />,
    },
  ];

  return (
    <Modal
      isOn={isVisible}
      modalProps={{
        title: modalProps?.title || t(getTranslation('title')),
        icon: modalProps?.icon || 'icon-link',
        type: modalProps.type,
        width: modalProps?.width || '840px',
        footer: (
          <div className="btn-group-bottom">
            <Button
              className="btn-left"
              kind="normal"
              modifiers={['hover-box-shadow']}
              children={t(getTranslation('connectionTest'))}
            />

            <Button
              kind="normal"
              modifiers={['hover-box-shadow']}
              children={t('actions.saveAs')}
            />

            <Button
              kind="normal"
              modifiers={['hover-box-shadow']}
              children={t('actions.save')}
            />

            <Button
              kind="normal"
              modifiers={['second', 'hover-box-shadow']}
              children={t('actions.cancel')}
            />
          </div>
        ),
      }}
      page="administration"
      onClose={onClose}
    >
      <div className="create-environment-modal">
        <FieldGroup
          className="create-environment-modal__main-settings"
          data={[
            {
              key: 'name',
              label: t(getTranslation('name')),
              field: <Input />,
              required: true,
            },
            {
              key: 'default',
              label: t(getTranslation('default')),
              field: <Checkbox />,
            },
            {
              key: 'integrationUrl',
              label: t(getTranslation('integrationUrl')),
              field: <Input />,
              required: true,
            },
            {
              key: 'kafkaUrl',
              label: t(getTranslation('kafkaUrl')),
              field: <Input />,
              required: true,
            },
          ]}
          labelWidth="264px"
        />

        <Accordion
          accordionKey="additional-settings"
          title={t(getTranslation('additionalSettings'))}
          actions={[
            { icon: 'icon-add', tooltip: t('actions.add') },
            { isDivider: true },
            { icon: 'icon-delete', tooltip: t('actions.delete') },
          ]}
          defaultOpen
        >
          <div className="create-environment-modal__additional-settings">
            <SimpleTable
              columnsData={columns}
              dataSource={[
                {
                  parameterName: 'DEV',
                  parameterValue: 'DEV',
                  parameterConfidential: false,
                },
              ]}
              selectedRowKeys={[]}
              setSelectedRowKeys={() => {}}
              hideSelectAll
            />
          </div>
        </Accordion>

        <FieldGroup
          className="create-environment-modal__streaming-platform"
          data={[
            {
              key: 'streamingPlatformUrl',
              label: t(getTranslation('streamingPlatformUrl')),
              field: <Input />,
              required: true,
            },
          ]}
          labelWidth="192px"
        />

        <Accordion
          accordionKey="authentication"
          title={t(getTranslation('authentication'))}
          defaultOpen
        >
          <div className="create-environment-modal__authentication">
            <FieldGroup
              className="create-environment-modal__authentication-field-group"
              data={[
                {
                  key: 'userId',
                  label: t(getTranslation('userId')),
                  field: <Input />,
                  required: true,
                },
                {
                  key: 'password',
                  label: t(getTranslation('password')),
                  field: <InputPassword />,
                },
              ]}
              labelWidth="124px"
            />
          </div>
        </Accordion>

        <ModalBox
          className="create-environment-modal__cache-parameters"
          title={t(getTranslation('cacheParameters'))}
          showHeader
        >
          <FieldGroup
            data={[
              {
                key: 'dBMSType',
                label: t(getTranslation('dBMSType')),
                field: <Select />,
                required: true,
              },
              {
                key: 'server',
                label: t(getTranslation('server')),
                field: <Input />,
                required: true,
              },
              {
                key: 'port',
                label: t(getTranslation('port')),
                field: <Input />,
                required: true,
              },
              {
                key: 'schemeName',
                label: t(getTranslation('schemeName')),
                field: <Input />,
                required: true,
              },
              {
                key: 'userId',
                label: t(getTranslation('userId')),
                field: <Input />,
                required: true,
              },
              {
                key: 'password',
                label: t(getTranslation('password')),
                field: <InputPassword />,
                required: true,
              },
            ]}
            labelWidth="124px"
          />
        </ModalBox>
      </div>
    </Modal>
  );
};
