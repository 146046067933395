import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IComplexTypeExt,
  IComplexTypeAttributeExt,
  IComplexTypeMap,
  IComplexTypeWithAttributes,
  INewComplexTypeWithAttributes,
  IComplexTypeAttributeBase,
  IComplexTypeAttribute,
} from './types/interface';
import { complexTypeApi } from './api/complexTypeApi';
import { IAction, RootState } from '@modules/store';
import { tryCatchDecorator } from '@modules/development/utils';

const COMPLEXTYPE_FEATURE_KEY = 'complexType';

export interface IComplexTypeState {
  complexTypes: IComplexTypeExt[];
  lastLoadedMap?: IComplexTypeMap;
  complexTypesAttributes?: IComplexTypeAttributeExt[];
}

const initialState: IComplexTypeState = {
  complexTypes: [],
};

export const getComplexTypes = createAsyncThunk(
  `${COMPLEXTYPE_FEATURE_KEY}/getComplexTypes`,
  async (_, { dispatch }) => {
    // @ts-ignore
    return await tryCatchDecorator(dispatch, async () => {
      const response = await complexTypeApi.getComplexTypes();
      return response;
    });
  }
);

export const getComplexTypeAttributes = createAsyncThunk(
  `${COMPLEXTYPE_FEATURE_KEY}/getComplexTypeAttributes`,
  async (typeId: string) => {
    return await complexTypeApi.getComplexTypeAttributes(typeId);
  }
);

export const getComplexTypeMap = createAsyncThunk(
  `${COMPLEXTYPE_FEATURE_KEY}/getComplexTypeMap`,
  async (typeId: string) => {
    return await complexTypeApi.getComplexTypeMap(typeId);
  }
);

export const addComplexTypeAttributes = createAsyncThunk(
  `${COMPLEXTYPE_FEATURE_KEY}/addComplexTypeAttributes`,
  async ({
    parentTypeId,
    data,
  }: {
    parentTypeId: string;
    data: IComplexTypeAttributeBase;
  }) => {
    return await complexTypeApi.addComplexTypeAttribute(parentTypeId, data);
  }
);

export const deleteComplexType = createAsyncThunk(
  `${COMPLEXTYPE_FEATURE_KEY}/deleteComplexType`,
  async (typeId: string) => {
    return await complexTypeApi.deleteComplexType(typeId);
  }
);

export const addComplexType = createAsyncThunk(
  `${COMPLEXTYPE_FEATURE_KEY}/addComplexType`,
  async (data: INewComplexTypeWithAttributes) => {
    return await complexTypeApi.addComplexType(data);
  }
);

export const manageChangedComplexType = createAsyncThunk(
  `${COMPLEXTYPE_FEATURE_KEY}/manageChangedComplexType`,
  async (data: {
    typeId: string;
    typeName: string;
    displayName: string;
    description: string;
    newAttributes: IComplexTypeAttributeBase[];
    changedAttributes: IComplexTypeAttribute[];
    deletedAttributes: string[];
  }) => {
    const { typeId } = data;

    return await Promise.all([
      complexTypeApi.updateComplexType(typeId, {
        typeName: data.typeName,
        displayName: data.displayName,
        description: data.description,
      }),
      ...data.deletedAttributes.map((attrId) => {
        return complexTypeApi.deleteComplexTypeAttributes(typeId, attrId);
      }),
      ...data.changedAttributes.map((attrId) => {
        return complexTypeApi.updateComplexTypeAttribute(typeId, attrId);
      }),
      ...data.newAttributes.map((attrId) => {
        return complexTypeApi.addComplexTypeAttribute(typeId, attrId);
      }),
    ]);
  }
);

export const complexTypeSlice = createSlice({
  name: COMPLEXTYPE_FEATURE_KEY,
  initialState,
  reducers: {
    setComplexTypeAttributes(state, action) {
      const { attributes } = action.payload;
      state.complexTypesAttributes = attributes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getComplexTypes.fulfilled,
      (state, action: IAction<IComplexTypeExt[]>) => {
        if (action.payload) state.complexTypes = action.payload;
      }
    );

    builder.addCase(getComplexTypeAttributes.fulfilled, (state, action) => {
      state.complexTypesAttributes = (state.complexTypesAttributes || [])
        .filter(
          (attr) =>
            !action.payload.some((a) => a.attributeId === attr.attributeId)
        )
        .concat(action.payload);
    });

    builder.addCase(
      getComplexTypeMap.fulfilled,
      (state, action: IAction<IComplexTypeMap>) => {
        state.lastLoadedMap = {
          ...state.lastLoadedMap,
          ...action.payload,
        };
      }
    );
  },
});

export const complexTypeActions = {
  ...complexTypeSlice.actions,
};

export const getComplexTypesMap = (typeIds: string[]) => (dispatch: any) => {
  typeIds
    .filter((typeId, index, arr) => arr.indexOf(typeId) === index)
    .forEach((typeId) => dispatch(getComplexTypeMap(typeId)));
};

export const deleteComplexTypes = (typeIds: string[]) => (dispatch: any) => {
  typeIds.forEach((typeId) => dispatch(deleteComplexType(typeId)));
};

export const selectComplexTypes = (state: RootState) =>
  state.complexType.complexTypes;

export const selectComplexTypesIds = (state: RootState) =>
  state.complexType.complexTypes.map((ct) => ct.typeId);

export const selectComplexTypesVersionsIds = (state: RootState) =>
  state.complexType.complexTypes.map((ct) => ct.versionId);

export const selectLastLoadedComplexTypeMap = (state: RootState) =>
  state.complexType?.lastLoadedMap;

export const selectComplexTypesWithAttributes: (
  ...args: any
) => IComplexTypeWithAttributes[] = (state: RootState) => {
  return state.complexType?.complexTypes?.map((ct) => {
    return {
      ...ct,
      attributes: state.complexType?.complexTypesAttributes?.filter((a) => {
        return a.parentId === ct.versionId;
      }),
    } as IComplexTypeWithAttributes;
  });
};

export default complexTypeSlice.reducer;
