import React, { FC, useEffect, useMemo, useState } from 'react';
import './ConnectDataSourceModal.scss';
import {
  Button,
  ConfirmModal,
  IConfirmModalProps,
  Modal,
  useAdministrationModalVisible,
} from '@shared';
import { selectRoles, rolesList } from '@modules/services';
import { useAppSelector } from '@modules/store';
import { useTranslationPath } from '@modules/languageProvider';
import { dataProviderApi, IDataProviderUpdate } from '@modules/dataProvider';
import { DataSource } from '../DataSources/interfaces';
import { useConnectDataSourceValidation } from './hooks';
import type { IConnectDataSourceModalProps, Message } from './interfaces';
import { ConnectDataSourceMessage } from './parts/ConnectDataSourceMessage';
import { ConnectDataSourceGrid } from './parts/ConnectDataSourceGrid';

type OpenedModal =
  | (IConfirmModalProps & {
      type: 'confirm';
      onOk?: () => void;
      onCancel?: () => void;
    })
  | null;

interface IConnectDataSourceModal {
  modalProps: IConnectDataSourceModalProps;
  closeOnSave?: boolean;
  onSave: (values: IDataProviderUpdate, sourceId?: string) => void;
  onClose: () => void;
  data?: DataSource[];
}

const initialValues: IDataProviderUpdate = {
  sourceName: '',
  description: undefined,
  connectionType: '',
  sourceType: '',
  username: '',
  password: '',
  serverName: '',
  port: '',
  additionalProperties: '',
};

export const ConnectDataSourceModal: FC<IConnectDataSourceModal> = ({
  modalProps,
  closeOnSave,
  onSave,
  onClose,
  data,
}) => {
  const translate = useTranslationPath(
    'administrationTab.dataSources.connectDataSourceModal'
  );
  const isVisible = useAdministrationModalVisible(modalProps.type);
  const [values, setValues] = useState<IDataProviderUpdate>(
    modalProps.data || initialValues
  );
  const [message, setMessage] = useState<Message>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [openedModal, setOpenedModal] = useState<OpenedModal>(null);
  const [notUniqueSourceNameMessage, setNotUniqueSourceNameMessage] =
    useState<Message>(null);
  const roles = useAppSelector(selectRoles);
  const validation = useConnectDataSourceValidation(
    values,
    showErrors,
    data,
    modalProps.sourceId
  );

  const onValuesChange = (key: string, value: string) => {
    setValues((currentValues) => ({ ...currentValues, [key]: value }));
  };

  const onSaveClick = async () => {
    setShowErrors(true);
    if (!validation.isValid) return false;
    const isValidConnect = await onTestConnection();

    if (!isValidConnect) {
      return setOpenedModal({
        type: 'confirm',
        width: '650px',
        contentText: translate('confirmSaveText'),
        closeButtonText: translate('cancelButtonText'),
        okButtonText: translate('save'),
        kind: 'warning',
        onOk: () => {
          onSave(values, modalProps.sourceId);
          closeOnSave && onClose();
        },
        onCancel: () => onClose(),
      });
    }

    onSave(values, modalProps.sourceId);
    closeOnSave && onClose();
  };

  const onTestConnection = async () => {
    setShowErrors(true);
    if (!validation.isValid) return false;

    try {
      const response = await dataProviderApi.testConnection({
        sourceType: values.sourceType,
        connectionType: values.connectionType,
        port: values.port,
        username: values.username,
        password: values.password,
        additionalProperties: values.additionalProperties,
        serverName: values.serverName,
      });

      response && response.result === 'SUCCESS'
        ? setMessage({
            text: translate('successTestConnection'),
            status: 'success',
          })
        : setMessage({
            text: translate('errorTestConnection'),
            status: 'error',
          });

      return response.result === 'SUCCESS';
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (!validation.isValid && showErrors) {
      !validation.errorOnlyByUnique &&
        setMessage({ text: translate('errorValues'), status: 'error' });

      validation.errorOnlyByUnique && setMessage(null);

      validation.notUnique &&
        setNotUniqueSourceNameMessage({
          text: translate('notUnique'),
          status: 'error',
        });

      !validation.notUnique && setNotUniqueSourceNameMessage(null);
    } else {
      setMessage(null);
      setNotUniqueSourceNameMessage(null);
    }
  }, [
    showErrors,
    validation.isValid,
    validation.notUnique,
    validation.errorOnlyByUnique,
  ]);

  const saveButtonDisabled = useMemo(() => {
    if (modalProps.sourceId) {
      return !roles.includes(rolesList.DATA_PROVIDER_UPDATE);
    } else {
      return !roles.includes(rolesList.DATA_PROVIDER_CREATE);
    }
  }, [roles, modalProps.sourceId]);

  return (
    <React.Fragment>
      <Modal
        isOn={isVisible}
        modalProps={{
          title: modalProps?.title || translate('title'),
          icon: modalProps?.icon || 'icon-link',
          type: modalProps.type,
          width: modalProps?.width || '580px',
          helpKey: 'connect-data-source',
          footer: (
            <div className="btn-group-bottom">
              <Button
                className="btn-left"
                kind="normal"
                modifiers={['hover-box-shadow']}
                onClick={onTestConnection}
                children={translate('connectionTest')}
                disabled={
                  !roles.includes(rolesList.DATA_PROVIDER_TEST_CONNECTION)
                }
              />

              <Button
                kind="normal"
                modifiers={['hover-box-shadow']}
                onClick={onSaveClick}
                children={translate('save')}
                disabled={saveButtonDisabled}
              />

              <Button
                kind="normal"
                modifiers={['second', 'hover-box-shadow']}
                onClick={onClose}
                children={translate('cancel')}
              />
            </div>
          ),
        }}
        page="administration"
        onClose={onClose}
      >
        <div className="connect-data-source-modal">
          <ConnectDataSourceMessage message={message} />
          <ConnectDataSourceMessage message={notUniqueSourceNameMessage} />
          <ConnectDataSourceGrid
            translate={translate}
            values={values}
            errors={validation?.errors}
            onValuesChange={onValuesChange}
          />
        </div>
      </Modal>

      {openedModal?.type === 'confirm' && (
        <ConfirmModal
          modalProps={openedModal}
          onClose={() => setOpenedModal(null)}
          onOk={openedModal?.onOk}
          onCancel={openedModal?.onCancel}
        />
      )}
    </React.Fragment>
  );
};
