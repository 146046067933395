import React, { Key, useEffect, useMemo, useState, VFC } from 'react';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import './StreamsListImportStreamsModal.scss';
import {
  Modal,
  ModalBox,
  ModalFooter,
  SimpleTable,
  Tooltip,
  useModalVisible,
} from '@shared';
import { useTranslation } from '@modules/languageProvider';
import { Icon } from '@modules/icon';
import { exportApi, IModalProps } from '@modules/development';
import { tryCatchDecorator } from '@modules/development/utils';
import { useDispatch } from 'react-redux';
import { successImport } from '@modules/development/modules/streams/components/streamsList/StreamsList';

type RecordType = {
  key: Key;
  title?: string;
  children?: RecordType[];
  selectable?: boolean;
  objectName?: string;
  objectType?: string;
  objectVersionName?: string;
  objectVersionType?: string;
  isExists?: boolean;
  isLatestExistsWithDifferentId?: string;
};

const getTranslate = (value: string) => `modals.confirmImportObjects.${value}`;

interface IConfirmImportObjectsModal {
  modalProps: IModalProps;
  onClose: () => void;
  setSuccessModalProps: React.Dispatch<IModalProps | null>;
}

export const StreamsListImportStreamsModal: VFC<IConfirmImportObjectsModal> = ({
  modalProps,
  onClose,
  setSuccessModalProps,
}) => {
  const { t } = useTranslation();
  const isVisible = useModalVisible(modalProps.type);
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const columns: ColumnsType<RecordType> = [
    {
      title: t(getTranslate('objectName')),
      dataIndex: 'objectName',
      sorter: (a, b) => (a.objectName || '').localeCompare(b.objectName || ''),
      render: (value, record) => (
        <span className={classNames(!record.objectType && 'gray')}>
          {!record.objectType ? record.title : value}
        </span>
      ),
    },
    {
      title: t(getTranslate('objectType')),
      dataIndex: 'objectType',
      sorter: (a, b) => (a.objectType || '').localeCompare(b.objectType || ''),
    },
    {
      title: t(getTranslate('versionName')),
      dataIndex: 'objectVersionName',
      sorter: (a, b) =>
        (a.objectVersionName || '').localeCompare(b.objectVersionName || ''),
      render: (val, row) => {
        if (row.children) return;
        if (row.objectType === 'DEPLOY' || row.objectType === 'DATA_PROVIDER') {
          return '-';
        } else {
          return val;
        }
      },
    },
    {
      title: t(getTranslate('versionType')),
      dataIndex: 'objectVersionType',
      sorter: (a, b) =>
        (a.objectVersionType || '').localeCompare(b.objectVersionType || ''),
      render: (val, row) => {
        if (row.children) return;
        if (row.objectType === 'DEPLOY' || row.objectType === 'DATA_PROVIDER') {
          return '-';
        } else {
          return val;
        }
      },
    },
    {
      title: t(getTranslate('importContour')),
      dataIndex: 'isExists',
      align: 'center',
      sorter: (a, b) => (a.isExists === b.isExists ? 0 : a.isExists ? -1 : 1),
      render: (value) =>
        value && <Icon name="icon-check" size={20} color="#919699" />,
    },
    {
      dataIndex: 'isLatestExistsWithDifferentId',
      render: (value) =>
        value && (
          <Tooltip
            title={t(getTranslate('alert'))}
            placement="bottomRight"
            white
          >
            <span>
              <Icon
                name="icon-alert"
                size={16}
                color="rgba(245, 34, 45, 0.85)"
              />
            </span>
          </Tooltip>
        ),
    },
  ];

  const data = useMemo(() => {
    const data = Object.entries(modalProps?.data?.objectsInfo).map(
      (item: any, index) => {
        return {
          key: index,
          title: t(getTranslate(item[0])),
          selectable: false,
          children: item[1].map((i: any) => ({
            ...i,
            key: i.objectVersionId || i.objectId,
            selectable: false,
          })),
        };
      }
    );
    return data;
  }, [modalProps]);

  useEffect(() => {
    const childrens = data.map((item) => item.children);
    const keys = childrens.flat().map((c) => c.key);
    setSelectedRowKeys(keys);
  }, [data]);

  const importFile = async () => {
    const data = Object.entries(modalProps?.data?.objectsInfo).map(
      (item: any) => {
        return {
          [item[0]]: item[1].map((val: any) => {
            if (selectedRowKeys.includes(val.objectVersionId || val.objectId)) {
              return {
                ...val,
                isSelected: true,
              };
            } else {
              return {
                ...val,
                isSelected: false,
              };
            }
          }),
        };
      }
    );
    const dataSend: any = {};
    data.forEach((item) => {
      const entries: any = Object.entries(item);
      dataSend[entries[0][0]] = entries[0][1];
    });
    const finalData = {
      fileName: modalProps.data.fileName,
      objectsInfo: dataSend,
    };
    await tryCatchDecorator(dispatch, async () => {
      return await exportApi.import(finalData).then((res: any) => {
        setSuccessModalProps({
          type: successImport,
          title: t('modals.successImport.title'),
          data: res,
        });
        onClose();
      });
    });
  };

  return (
    <Modal
      isOn={isVisible}
      modalProps={{
        type: modalProps.type,
        title: t(getTranslate('title')),
        icon: 'icon-dots',
        width: '1346px',
        footer: (
          <ModalFooter
            saveText={t('actions.import')}
            onSave={importFile}
            onClose={onClose}
            saveDisabled={!selectedRowKeys.length}
          />
        ),
      }}
      onClose={onClose}
    >
      <div className="confirm-import-objects-modal">
        <div className="confirm-import-objects-modal__description">
          <span>{t(getTranslate('description1'))}</span>
          <span>{t(getTranslate('description2'))}</span>
          <span>{t(getTranslate('description3'))}</span>
        </div>

        <ModalBox className="confirm-import-objects-modal__table">
          <SimpleTable
            columnsData={columns}
            dataSource={data}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </ModalBox>
      </div>
    </Modal>
  );
};
