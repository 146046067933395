import { IDataVariables, IDiagramNode } from '@modules/development';

export interface ICustomCodeVariable extends IDataVariables {
  nodeVariable: string;
}

export interface ICustomCodeProperties {
  customCodeId: string;
  versionId: string;
  inputVariablesMapping: ICustomCodeVariable[];
  outputVariablesMapping: ICustomCodeVariable[];
}

export type CustomCodeData = IDiagramNode<ICustomCodeProperties>;

export enum ModalTypes {
  addElement = 'addElement',
  confirmSave = 'confirmSave',
  chooseVersion = 'chooseVersion',
}

export type OpenedModal =
  | {
      type: ModalTypes.addElement;
      title: string;
      index: number;
      to: 'input' | 'output';
      variableName: string;
      typeId: string;
      isArray: boolean;
    }
  | {
      type: ModalTypes.confirmSave;
      contentText: string;
    }
  | {
      type: ModalTypes.chooseVersion;
      customCodeId: string;
    };
