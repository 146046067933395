import { Key, Dispatch, SetStateAction, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DataTypesSelect, Input } from '@shared';
import {
  ServiceAdd,
  ServiceUpdate,
  selectRoles,
  rolesList,
} from '@modules/services';
import { Errors, Header, Variable } from './interfaces';
import { complexTypeApi, IComplexTypeAttributeExt } from '@modules/complexType';
import { ServiceData } from '../ExternalServiceAccess/interfaces';
import { IDataType } from '@modules/references';
import { useAppSelector } from '@modules/store';
import { useDispatch } from 'react-redux';
import { tryCatchDecorator } from '@modules/development/utils';

export const useExtServiceInputVariablesTable = (
  dataTypes: IDataType[],
  translate: (attrName: any, options?: any) => any,
  setInputVariables: Dispatch<SetStateAction<Variable[]>>,
  errors?: any
) => {
  const [inputVariablesRowKeys, setInputVariablesRowKeys] = useState<Key[]>([]);
  const roles = useAppSelector(selectRoles);
  const dispatch = useDispatch();

  const fetchAttributes = async (
    typeId: string,
    key: Key,
    name: string
  ): Promise<Variable[]> => {
    const response: IComplexTypeAttributeExt[] = await tryCatchDecorator(
      dispatch,
      async () => {
        return await complexTypeApi.getComplexTypeAttributes(typeId);
      }
    );

    return await Promise.all(
      response.map(async (item) => {
        let data: Variable = {
          key: `${key}-${uuidv4()}`,
          variableId: item.attributeId,
          variableName: `${name}.${item.attributeName}`,
          isArray: item.arrayFlag,
          isComplex: item.complexFlag,
          variableTypeId: undefined,
          sourcePath: '',
          isChildren: true,
        };

        if (item.complexFlag && item.complexTypeVersionId) {
          const children = await fetchAttributes(
            item.complexTypeVersionId,
            key,
            `${name}.${item.attributeName}`
          );

          data = { ...data, children };
        }

        return data;
      })
    );
  };

  const onInputVariablesChange = (
    key: Key,
    property: keyof Variable,
    value: string | boolean | undefined
  ) => {
    setInputVariables((current) =>
      current.map((variable) => {
        if (key === variable.key) {
          return { ...variable, [property]: value };
        }
        return {
          ...variable,
          children: variable.children?.map((child) => {
            if (key === child.key) {
              return { ...child, [property]: value };
            }
            return child;
          }),
        };
      })
    );
  };

  const onInputVariablesTypeIdChange = async (
    key: Key,
    name: string,
    isComplex?: boolean,
    variableTypeId?: string
  ) => {
    let children: Variable[];

    if (
      isComplex &&
      typeof variableTypeId === 'string' &&
      roles.includes(rolesList.COMPLEX_TYPE_ATTRIBUTE_READ_ALL)
    ) {
      children = await fetchAttributes(variableTypeId, key, name);
    }

    setInputVariables((current) =>
      current.map((variable) => {
        if (key === variable.key) {
          return {
            ...variable,
            isComplex,
            variableTypeId,
            children,
          };
        }

        return {
          ...variable,
          children: variable.children?.map((child) => {
            if (key === child.key) {
              return {
                ...child,
                isComplex,
                variableTypeId,
                children,
              };
            }
            return child;
          }),
        };
      })
    );
  };

  const onInputVariablesAdd = () => {
    setInputVariables((current) => [
      ...current,
      { key: uuidv4(), variableName: '', sourcePath: '/', isArray: false },
    ]);
  };

  const onInputVariablesDelete = (keys: Key[]) => {
    setInputVariables((current) =>
      current.filter(({ key }) => !keys.includes(key))
    );
  };

  const inputVariablesActions = [
    {
      icon: 'icon-add',
      tooltip: translate('add'),
      onClick: () => onInputVariablesAdd(),
      disabled: !roles.includes(rolesList.EXT_SERVICE_UPDATE),
    },
    { isDivider: true },
    {
      icon: 'icon-delete',
      tooltip: translate('delete'),
      onClick: () => onInputVariablesDelete(inputVariablesRowKeys),
      disabled: !roles.includes(rolesList.EXT_SERVICE_UPDATE),
    },
  ];

  const inputVariablesColumns: ColumnsType<Variable> = [
    {
      dataIndex: 'collapse',
      className: 'ant-table-collapse',
    },
    {
      dataIndex: 'variableName',
      title: translate('variableName'),
      width: '50%',
      // @ts-ignore
      onCell: (_, index) => ({
        invalid: errors?.[`${index}`]?.variableName,
      }),
      render: (value, { key, isChildren }) => (
        <Input
          value={value}
          readOnly={isChildren}
          onChange={({ target: { value } }) => {
            onInputVariablesChange(key, 'variableName', value);
          }}
          disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
        />
      ),
    },
    {
      dataIndex: 'isArray',
      title: translate('isArray'),
      align: 'center',
      render: (value, { key, isChildren }) => (
        <Checkbox
          checked={value}
          onChange={({ target: { checked } }) => {
            !isChildren && onInputVariablesChange(key, 'isArray', checked);
          }}
          disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
        />
      ),
    },
    {
      dataIndex: 'variableTypeId',
      title: translate('variableTypeId'),
      width: '50%',
      // @ts-ignore
      onCell: (_, index) => ({
        invalid: errors?.[`${index}`]?.primitiveTypeId,
      }),
      render: (value, { key, variableName, isChildren }) => {
        return (
          !isChildren && (
            <DataTypesSelect
              value={value}
              onChange={(dataType) => {
                onInputVariablesTypeIdChange(
                  key,
                  variableName,
                  dataType?.complexFlag,
                  dataType?.typeId
                );
              }}
              disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
            />
          )
        );
      },
    },
  ];

  return {
    inputVariablesRowKeys,
    setInputVariablesRowKeys,
    inputVariablesActions,
    inputVariablesColumns,
  } as const;
};

export const useExtServiceOutputVariablesTable = (
  dataTypes: IDataType[],
  translate: (attrName: any, options?: any) => any,
  setOutputVariables: Dispatch<SetStateAction<Variable[]>>,
  errors?: any
) => {
  const [outputVariablesRowKeys, setOutputVariablesRowKeys] = useState<Key[]>(
    []
  );
  const roles = useAppSelector(selectRoles);
  const dispatch = useDispatch();

  const fetchAttributes = async (
    typeId: string,
    key: Key,
    name: string
  ): Promise<Variable[]> => {
    const response: IComplexTypeAttributeExt[] = await tryCatchDecorator(
      dispatch,
      async () => {
        return await complexTypeApi.getComplexTypeAttributes(typeId);
      }
    );

    return await Promise.all(
      response.map(async (item) => {
        let data: Variable = {
          key: `${key}-${uuidv4()}`,
          variableId: item.attributeId,
          variableName: `${name}.${item.attributeName}`,
          isArray: item.arrayFlag,
          isComplex: item.complexFlag,
          variableTypeId: undefined,
          sourcePath: '',
          isChildren: true,
        };

        if (item.complexFlag && item.complexTypeVersionId) {
          const children = await fetchAttributes(
            item.complexTypeVersionId,
            key,
            `${name}.${item.attributeName}`
          );

          data = { ...data, children };
        }

        return data;
      })
    );
  };

  const onOutputVariablesChange = (
    key: Key,
    property: keyof Variable,
    value: string | boolean | undefined
  ) => {
    setOutputVariables((current) =>
      current.map((variable) => {
        if (key === variable.key) {
          return { ...variable, [property]: value };
        }
        return {
          ...variable,
          children: variable.children?.map((child) => {
            if (key === child.key) {
              return { ...child, [property]: value };
            }
            return child;
          }),
        };
      })
    );
  };

  const onOutputVariablesTypeIdChange = async (
    key: Key,
    name: string,
    isComplex?: boolean,
    variableTypeId?: string
  ) => {
    let children: Variable[];

    if (
      isComplex &&
      typeof variableTypeId === 'string' &&
      roles.includes(rolesList.COMPLEX_TYPE_ATTRIBUTE_READ_ALL)
    ) {
      children = await fetchAttributes(variableTypeId, key, name);
    }

    setOutputVariables((current) =>
      current.map((variable) => {
        if (key === variable.key) {
          return {
            ...variable,
            isComplex,
            variableTypeId,
            children,
          };
        }

        return {
          ...variable,
          children: variable.children?.map((child) => {
            if (key === child.key) {
              return {
                ...child,
                isComplex,
                variableTypeId,
                children,
              };
            }
            return child;
          }),
        };
      })
    );
  };

  const onOutputVariablesAdd = () => {
    setOutputVariables((current) => [
      ...current,
      { key: uuidv4(), variableName: '', isArray: false, sourcePath: '' },
    ]);
  };

  const onOutputVariablesDelete = (keys: Key[]) => {
    setOutputVariables((current) =>
      current.filter(({ key }) => !keys.includes(key))
    );
  };

  const outputVariablesActions = [
    {
      icon: 'icon-add',
      tooltip: translate('add'),
      onClick: () => onOutputVariablesAdd(),
      disabled: !roles.includes(rolesList.EXT_SERVICE_UPDATE),
    },
    { isDivider: true },
    {
      icon: 'icon-delete',
      tooltip: translate('delete'),
      onClick: () => onOutputVariablesDelete(outputVariablesRowKeys),
      disabled: !roles.includes(rolesList.EXT_SERVICE_UPDATE),
    },
  ];

  const outputVariablesColumns: ColumnsType<Variable> = [
    {
      dataIndex: 'collapse',
      className: 'ant-table-collapse',
    },
    {
      dataIndex: 'variableName',
      title: translate('variableName'),
      width: '33.3333%',
      // @ts-ignore
      onCell: (_, index) => ({
        invalid: errors?.[`${index}`]?.variableName,
      }),
      render: (value, { key, isChildren }) => (
        <Input
          value={value}
          readOnly={isChildren}
          onChange={({ target: { value } }) => {
            onOutputVariablesChange(key, 'variableName', value);
          }}
          disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
        />
      ),
    },
    {
      dataIndex: 'isArray',
      title: translate('isArray'),
      align: 'center',
      render: (value, { key, isChildren }) => (
        <Checkbox
          checked={value}
          onChange={({ target: { checked } }) => {
            !isChildren && onOutputVariablesChange(key, 'isArray', checked);
          }}
          disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
        />
      ),
    },
    {
      dataIndex: 'variableTypeId',
      title: translate('variableTypeId'),
      width: '33.3333%',
      // @ts-ignore
      onCell: (_, index) => ({
        invalid: errors?.[`${index}`]?.primitiveTypeId,
      }),
      render: (value, { key, variableName, isChildren }) => {
        return (
          !isChildren && (
            <DataTypesSelect
              value={value}
              onChange={(dataType) => {
                onOutputVariablesTypeIdChange(
                  key,
                  variableName,
                  dataType?.complexFlag,
                  dataType?.typeId
                );
              }}
              disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
            />
          )
        );
      },
    },
    {
      dataIndex: 'sourcePath',
      title: translate('sourcePath'),
      width: '33.3333%',
      // @ts-ignore
      onCell: (_, index) => ({
        invalid: errors?.[`${index}`]?.sourcePath,
      }),
      render: (value, { key }) => (
        <Input
          value={value}
          onChange={({ target: { value } }) => {
            onOutputVariablesChange(key, 'sourcePath', value);
          }}
        />
      ),
    },
  ];

  return {
    outputVariablesRowKeys,
    setOutputVariablesRowKeys,
    outputVariablesActions,
    outputVariablesColumns,
  } as const;
};

export const useExtServiceHeadersTable = (
  translate: (attrName: any, options?: any) => any,
  setHeaders: Dispatch<SetStateAction<Header[]>>
) => {
  const [headersRowKeys, setHeadersRowKeys] = useState<Key[]>([]);
  const roles = useAppSelector(selectRoles);

  const onHeadersChange = (
    key: Key,
    property: keyof Header,
    value: string | undefined
  ) => {
    setHeaders((current) =>
      current.map((header) => {
        if (key === header.key) {
          return { ...header, [property]: value };
        }
        return header;
      })
    );
  };

  const onHeadersAdd = () => {
    setHeaders((current) => [...current, { key: uuidv4() }]);
  };

  const onHeadersDelete = (keys: Key[]) => {
    setHeaders((current) => current.filter(({ key }) => !keys.includes(key)));
  };

  const headersActions = [
    {
      icon: 'icon-add',
      tooltip: translate('add'),
      onClick: () => onHeadersAdd(),
      disabled: !roles.includes(rolesList.EXT_SERVICE_UPDATE),
    },
    { isDivider: true },
    {
      icon: 'icon-delete',
      tooltip: translate('delete'),
      onClick: () => onHeadersDelete(headersRowKeys),
      disabled: !roles.includes(rolesList.EXT_SERVICE_UPDATE),
    },
  ];

  const headersColumns: ColumnsType<Header> = [
    {
      dataIndex: 'headerName',
      title: translate('headerName'),
      width: '50%',
      render: (value, { key }) => (
        <Input
          value={value}
          onChange={({ target: { value } }) => {
            onHeadersChange(key, 'headerName', value);
          }}
          disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
        />
      ),
    },
    {
      dataIndex: 'headerValue',
      title: translate('headerValue'),
      width: '50%',
      render: (value, { key }) => (
        <Input
          value={value}
          onChange={({ target: { value } }) => {
            onHeadersChange(key, 'headerValue', value);
          }}
          disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
        />
      ),
    },
  ];

  return {
    headersRowKeys,
    setHeadersRowKeys,
    headersActions,
    headersColumns,
  } as const;
};

export const useExternalServiceCallSettingsValidation = (
  showErrors: boolean,
  translate: (attrName: any, options?: any) => any,
  data: ServiceData[],
  values?: ServiceAdd | ServiceUpdate,
  key?: Key
) => {
  const validation = useMemo(() => {
    const errors: Errors = {};
    const inputVarsErrors: any = {};
    const outputVarsErrors: any = {};
    const inputVariables = values?.variables?.filter(
      (v) => v.parameterType === 'IN'
    );
    const outputVariables = values?.variables?.filter(
      (v) => v.parameterType === 'OUT'
    );

    inputVariables?.forEach((v, i) => {
      !v.variableName &&
        (inputVarsErrors[i] = {
          ...inputVarsErrors[i],
          variableName: translate('errorEmptyValue'),
        });
      !v.primitiveTypeId &&
        v.primitiveTypeId !== 0 &&
        !v.complexTypeVersionId &&
        (inputVarsErrors[i] = {
          ...inputVarsErrors[i],
          primitiveTypeId: translate('errorEmptyValue'),
        });
      inputVariables.find(
        (item, index) => index !== i && item.variableName === v.variableName
      ) &&
        (inputVarsErrors[i] = {
          ...inputVarsErrors[i],
          variableName: translate('notUnique'),
        });
    });

    outputVariables?.forEach((v, i) => {
      !v.variableName &&
        (outputVarsErrors[i] = {
          ...outputVarsErrors[i],
          variableName: translate('errorEmptyValue'),
        });
      !v.primitiveTypeId &&
        v.primitiveTypeId !== 0 &&
        !v.complexTypeVersionId &&
        (outputVarsErrors[i] = {
          ...outputVarsErrors[i],
          primitiveTypeId: translate('errorEmptyValue'),
        });
      !v.sourcePath &&
        (outputVarsErrors[i] = {
          ...outputVarsErrors[i],
          sourcePath: translate('errorEmptyValue'),
        });
      outputVariables.find(
        (item, index) => index !== i && item.variableName === v.variableName
      ) &&
        (outputVarsErrors[i] = {
          ...outputVarsErrors[i],
          variableName: translate('notUnique'),
        });
    });

    const notUnique = key
      ? data?.find(
          (item) => key !== item.key && item.serviceName === values?.serviceName
        )
      : data?.find((item) => item.serviceName === values?.serviceName);

    notUnique && (errors.serviceName = '');
    !values?.serviceName && (errors.serviceName = '');
    !values?.host && (errors.host = '');
    !values?.endpoint && (errors.endpoint = '');
    !values?.fileFormat && (errors.fileFormat = '');
    !values?.secondAttemptsCnt && (errors.secondAttemptsCnt = '');
    !values?.interval && (errors.interval = '');
    !values?.port && (errors.port = '');
    values?.batchFlag &&
      !values?.transactionsPerSecond &&
      (errors.transactionsPerSecond = '');
    (values?.serviceType === 'HTTP' || values?.serviceType === 'HTTPS') &&
      !values.protocol &&
      (errors.protocol = '');
    values?.protocol === 'SOAP' && !values.method && (errors.method = '');

    return {
      isValid:
        !Object.values(errors).length &&
        !Object.values(inputVarsErrors).length &&
        !Object.values(outputVarsErrors).length,
      errors: showErrors ? errors : undefined,
      inputVarsErrors: showErrors ? inputVarsErrors : undefined,
      outputVarsErrors: showErrors ? outputVarsErrors : undefined,
      notUnique: notUnique,
      errorOnlyByUnique:
        Object.values(errors).length === 1 &&
        notUnique &&
        errors.serviceName === '',
    };
  }, [values, showErrors]);

  return validation;
};
