import { useTranslationPath } from '@modules/languageProvider';
import { FC } from 'react';
import './VersionChangesInfo.scss';

interface IVarsionChangesProps {
  lastChangeDt?: string;
  lastChangeUser?: string;
  currentVersionTitle: string;
  currentVersion?: string;
  createDt?: string;
  createUser?: string;
}

export const VersionChangesInfo: FC<IVarsionChangesProps> = ({
  lastChangeDt,
  lastChangeUser,
  currentVersionTitle,
  currentVersion,
  createUser,
  createDt,
}) => {
  const translate = useTranslationPath(
    'development.diagram.generalInformation'
  );
  const tStream = useTranslationPath('development.streams.generalInformation');

  return (
    <div className="information-changes">
      <div className="information-changes__block">
        <div className="information-changes__title bottom-border">
          {translate('lastChanges')}
        </div>
        <div className="information-changes__title">{tStream('date')}</div>
        <div className="information-changes__date">{lastChangeDt}</div>
        <div className="information-changes__title">{tStream('user')}</div>
        <div className="information-changes__author">{lastChangeUser}</div>
      </div>

      <div className="information-changes__block">
        <div className="information-changes__title bottom-border">
          {currentVersionTitle}
        </div>
        <div className="information-changes__title">
          {tStream('versionName')}
        </div>
        <div className="information-changes__date">v.2022.09.19_10:00:15</div>
        <div className="information-changes__title">{tStream('versionId')}</div>
        <div className="information-changes__author">{currentVersion}</div>
      </div>

      <div className="information-changes__block">
        <div className="information-changes__title bottom-border">
          {translate('create')}
        </div>
        <div className="information-changes__title">{tStream('date')}</div>
        <div className="information-changes__date">{createDt}</div>
        <div className="information-changes__title">{tStream('user')}</div>
        <div className="information-changes__author">{createUser}</div>
      </div>
    </div>
  );
};
