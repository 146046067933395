import { userStorage } from '@modules/userStorage';
import React, { FC, createContext, useEffect, useState } from 'react';
import darkTheme from './themes/dark.json';
import defaultTheme from './themes/default.json';

type Themes = 'default' | 'dark';

export const ThemeContext = createContext<{
  theme: Themes;
  setTheme: React.Dispatch<React.SetStateAction<Themes>>;
}>({
  theme: 'default',
  setTheme: () => {},
});

export const ThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState<Themes>('default');
  const value = { theme, setTheme };

  const onThemeChange = (theme: Themes) => {
    const root = document.getElementsByTagName('html')[0];
    root.setAttribute('theme', theme);
    root.setAttribute('class', theme);
    // @ts-ignore
    window.less.modifyVars(theme === 'default' ? defaultTheme : darkTheme);
  };

  useEffect(() => {
    const theme = userStorage.get<Themes>('theme');

    if (theme) {
      onThemeChange(theme);
      setTheme(theme);
    }
  }, []);

  useEffect(() => {
    onThemeChange(theme);
    userStorage.set('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};
