export const rolesList = {
  /**
   * Атомарные роли
   **/

  /* Диаграммы */
  DIAGRAM_CREATE: 'API_DECISION_DIAGRAM_CREATE', // Кнопка добавления диаграммы
  DIAGRAM_CREATE_TEMPLATE: 'API_DECISION_DIAGRAM_CREATE_TEMPLATE', // Сохранение диаграммы при создании новой диаграммы
  DIAGRAM_CREATE_USER_VERSION: 'API_DECISION_DIAGRAM_CREATE_USER_VERSION',
  DIAGRAM_CREATE_AS_NEW: 'API_DECISION_DIAGRAM_CREATE_AS_NEW', // Кнопка "Сохранить как..."
  DIAGRAM_CREATE_TEMPLATE_FROM_LATEST:
    'API_DECISION_DIAGRAM_CREATE_TEMPLATE_FROM_LATEST', // Открытие диаграммы на редактирование
  DIAGRAM_DELETE: 'API_DECISION_DIAGRAM_DELETE', // Кнопка удаления выбранных диаграмм
  DIAGRAM_READ_VERSIONS: 'API_DECISION_DIAGRAM_READ_VERSIONS', // Просмотр списка версий диаграммы
  DIAGRAM_READ: 'API_DECISION_DIAGRAM_READ', // Открытие диаграммы по двойному клику левой кнопки мыши
  DIAGRAM_UPDATE: 'API_DECISION_DIAGRAM_UPDATE', // Кнопка "Сохранить", Редактирование названия диаграммы, Редактирование описания диаграммы
  DIAGRAM_UPDATE_USER_VERSION: 'API_DECISION_DIAGRAM_UPDATE_USER_VERSION', // Обновление пользовательской версии диаграммы
  DIAGRAM_READ_ALL: 'API_DECISION_DIAGRAM_READ_ALL', // Получение списка диаграмм, Кнопка изменения иерархии списка, Функция сортировки записей по столбцам, Поиск по списку, Раздел "Разработка", Подраздел "Диаграммы"
  DIAGRAM_MARK_TO_DEPLOY: 'API_DECISION_DIAGRAM_MARK_TO_DEPLOY', // Кнопка "Отправить на развертывание"
  DIAGRAM_DEPLOY: 'API_DECISION_DIAGRAM_DEPLOY', // Кнопка "Развернуть диаграмму"
  DIAGRAM_UNDEPLOY: 'API_DECISION_DIAGRAM_UNDEPLOY', // Кнопка "Отменить развертывание"
  DIAGRAM_VALIDATE: 'API_DECISION_DIAGRAM_VALIDATE', // Кнопка "Валидация"
  DIAGRAM_RENAME: 'API_DECISION_DIAGRAM_RENAME', // Изменение только имени диаграммы через интерфейс отображения общего списка диаграмм
  DIAGRAM_PARAMETERS_UPDATE: 'API_DECISION_PARAMETERS_UPDATE', // Обновление списка переменных диаграммы
  DIAGRAM_UPLOAD_SCORECARD_FILE:
    'API_DECISION_DIAGRAM_HELPER_UPLOAD_SCORECARD_FILE',
  /* Пользовательские типы данных */
  COMPLEX_TYPE_READ_VERSIONS: 'API_DECISION_COMPLEX_TYPE_READ_VERSIONS', // Получение списка версий комплексного типа
  COMPLEX_TYPE_READ_ALL: 'API_DECISION_COMPLEX_TYPE_READ_ALL', // Получение списка комплексных типов, Кнопка изменения иерархии списка, Функция сортировки записей по столбцам, Поиск по списку, Раздел "Разработка", Подраздел "Пользовательские типы данных"
  COMPLEX_TYPE_CREATE: 'API_DECISION_COMPLEX_TYPE_CREATE', // Кнопка добавления комплексного типа, Кнопка сохранения комплексного типа, API первичного сохранения
  COMPLEX_TYPE_UPDATE: 'API_DECISION_COMPLEX_TYPE_UPDATE', // Кнопка сохранения комплексного типа, API сохранения существующего комплексного типа
  COMPLEX_TYPE_DELETE: 'API_DECISION_COMPLEX_TYPE_DELETE', // Кнопка удаления выбранных комплексных типов
  COMPLEX_TYPE_READ: 'API_DECISION_COMPLEX_TYPE_READ', // Открытие окна редактирования комплексного типа по двойному клику
  COMPLEX_TYPE_READ_MAP: 'API_DECISION_COMPLEX_TYPE_READ_MAP', // Получение ассоциативного списка комплексных типов связанных с определенным typeId.Строится иерархия от typeID, который указан в запросе
  COMPLEX_TYPE_ATTRIBUTE_CREATE: 'API_DECISION_COMPLEX_TYPE_ATTRIBUTE_CREATE', // Добавление атрибута к комплексному типу
  COMPLEX_TYPE_ATTRIBUTE_DELETE: 'API_DECISION_COMPLEX_TYPE_ATTRIBUTE_DELETE', // Удаление атрибута комплексного типа
  COMPLEX_TYPE_ATTRIBUTE_READ_ALL:
    'API_DECISION_COMPLEX_TYPE_ATTRIBUTE_READ_ALL', // Получение списка атрибутов, принадлежащих комплексному типу
  COMPLEX_TYPE_ATTRIBUTE_UPDATE: 'API_DECISION_COMPLEX_TYPE_ATTRIBUTE_UPDATE', // Обновление атрибута
  /* Источники данных */
  DATA_PROVIDER_READ_ALL: 'API_DECISION_DATA_PROVIDER_READ_ALL', // "Вкладка ""Администрирование"" / Раздел ""Источники данных"" Блок чтения данных, блок сохранения данных", Функция сортировки записей по столбцам, Вкладка "Администрирование", Раздел "Источники данных"
  DATA_PROVIDER_READ: 'API_DECISION_DATA_PROVIDER_READ', // Открытие окна редактирования источника данных по двойному клику
  DATA_PROVIDER_READ_TABLES: 'API_DECISION_DATA_PROVIDER_READ_TABLES', // "Кнопка ""плюс"" около названия источника данных Блок чтения данных, блок сохранения данных / Кнопка выбора таблицы / Кнопка ""плюс"" около названия источника данных"
  DATA_PROVIDER_READ_COLUMNS: 'API_DECISION_DATA_PROVIDER_READ_COLUMNS', // "Блок чтения данных / Данные БД / Кнопка ""плюс"" около названия таблицы, Блок сохранения данных / Условия отбора записей / Кнопка ""Добавить атрибут"", Блок сохранения данных / Обновляемые атрибуты / Кнопка ""Добавить атрибут"", Вкладка ""Администрирование"" / Источники данных / Двойной клик по названию таблицы"
  DATA_PROVIDER_CREATE: 'API_DECISION_DATA_PROVIDER_CREATE', // Кнопка "Добавить источник данных", Окно редактирования данных / Кнопка "Сохранить" (при первичном сохранении)
  DATA_PROVIDER_UPDATE: 'API_DECISION_DATA_PROVIDER_UPDATE', // Окно редактирования данных / Кнопка "Сохранить" (при сохранении существующего источника)
  DATA_PROVIDER_DELETE: 'API_DECISION_DATA_PROVIDER_DELETE', // Кнопка "Удалить выбранные источники данных"
  DATA_PROVIDER_TEST_CONNECTION: 'API_DECISION_DATA_PROVIDER_TEST_CONNECTION', // Тестирование подключения к БД (при создании источника данных)
  /* INTEGRATION */
  INTEGRATION_READ_TOPICS: 'API_DECISION_INTEGRATION_READ_TOPICS',
  INTEGRATION_UPDATE_TOPICS: 'API_DECISION_INTEGRATION_UPDATE_TOPICS', // Роль для апдейта топиков в момент деплоя диаграммы
  INTEGRATION_PROCESS_EVENT: 'API_DECISION_INTEGRATION_PROCESS_EVENT', // Роль для формирования запроса на обработку события в интеграционный модуля
  /* VALIDATION */
  VALIDATION_CHECK_EXPRESSION: 'API_DECISION_VALIDATION_CHECK_EXPRESSION',
  /* Внешние сервисы */
  EXT_SERVICE_READ_ALL: 'API_DECISION_EXT_SERVICE_READ_ALL', // Получение списка внешних сервисов, Функция сортировки записей по столбцам, Поиск по списку, Вкладка "Администрирование", Раздел "Доступ к внешним сервисам"
  EXT_SERVICE_READ: 'API_DECISION_EXT_SERVICE_READ', // Открытие окна редактирования внешнего сервиса по двойному клику
  EXT_SERVICE_READ_VERSIONS: 'API_DECISION_EXT_SERVICE_READ_VERSIONS',
  EXT_SERVICE_READ_VARIABLES: 'API_DECISION_EXT_SERVICE_READ_VARIABLES', // "Окно редактирования внешнего сервиса / Отображение при раскрытии одного из выпадающих списков ""Входные переменные"" и ""Выходные переменные"", Блок вызова внешнего сервиса / Отображение списка входных и выходных переменных после выбора внешнего сервиса "
  EXT_SERVICE_CREATE: 'API_DECISION_EXT_SERVICE_CREATE', // Кнопка "Сохранить" и "Сохранить как". Первичное сохранение внешнего сервиса, Кнопка "Добавить внешний сервис"
  EXT_SERVICE_CREATE_USER_VERSION:
    'API_DECISION_EXT_SERVICE_CREATE_USER_VERSION',
  EXT_SERVICE_UPDATE: 'API_DECISION_EXT_SERVICE_UPDATE', // Кнопка "Сохранить". Сохранение созданного ранее внешнего сервиса.
  EXT_SERVICE_UPDATE_USER_VERSION:
    'API_DECISION_EXT_SERVICE_UPDATE_USER_VERSION', // Обновление пользовательской версии внешнего сервиса
  EXT_SERVICE_DELETE: 'API_DECISION_EXT_SERVICE_DELETE', // Кнопка удаления выбранных сервисов.
  /* DICTIONARY */
  DICTIONARY_READ_ALL_DATA_TYPES: 'API_DECISION_DICTIONARY_READ_ALL_DATA_TYPES', // Выбор типов данных из выпадающего списка при задании переменных в блоках диаграммы, в окнах редактирования объектов и др.
  DICTIONARY_READ_ALL_FUNCTIONS: 'API_DECISION_DICTIONARY_READ_ALL_FUNCTIONS', // Выбор доступных функции в Expression Editor
  DICTIONARY_READ_ALL_RULES: 'API_DECISION_DICTIONARY_READ_ALL_RULES', // Выбор доступных правил в выпадающем списке блока правил
  DICTIONARY_READ_ALL_NODE_TYPES: 'API_DECISION_DICTIONARY_READ_ALL_NODE_TYPES', // Выбор доступных типов узлов при задании узла ветки в блоке ветвления
  /* Кастомные коды */
  SCRIPT_READ_VERSIONS: 'API_DECISION_SCRIPT_READ_VERSIONS', // Получение списка версий
  SCRIPT_READ_ALL: 'API_DECISION_SCRIPT_READ_ALL', // Получение списка скриптов, Раздел "Разработка", Подраздел "Кастомные коды"
  SCRIPT_READ: 'API_DECISION_SCRIPT_READ', // Открытие окна редактирования скрипта по двойному клику
  SCRIPT_READ_VARIABLES: 'API_DECISION_SCRIPT_READ_VARIABLES',
  SCRIPT_CREATE_USER_VERSION: 'API_DECISION_SCRIPT_CREATE_USER_VERSION',
  SCRIPT_CREATE: 'API_DECISION_SCRIPT_CREATE', // Кнопка "Сохранить" и "Сохранить как". Первичное сохранение внешнего сервиса, Кнопка "Добавить скрипт"
  SCRIPT_UPDATE: 'API_DECISION_SCRIPT_UPDATE', // Кнопка "Сохранить". Сохранение созданного ранее скрипта, Корректировка параметров скрипта
  SCRIPT_UPDATE_USER_VERSION: 'API_DECISION_SCRIPT_UPDATE_USER_VERSION', // Обновление пользовательской версии скрипта
  SCRIPT_DELETE: 'API_DECISION_SCRIPT_DELETE', // Кнопка удаления выбранных скриптов
  SCRIPT_VALIDATE: 'API_DECISION_SCRIPT_VALIDATE',
  /* STREAMING */
  STREAMING_READ_AVRO_SCHEMA: 'API_DECISION_STREAMING_READ_AVRO_SCHEMA',
  STREAMING_READ_GROOVY_OUT_AVRO_SCHEMA:
    'API_DECISION_STREAMING_READ_GROOVY_OUT_AVRO_SCHEMA',
  STREAMING_READ_SCRIPT: 'API_DECISION_STREAMING_READ_SCRIPT',
  STREAMING_READ_TECH_SCRIPT: 'API_DECISION_STREAMING_READ_TECH_SCRIPT',
  /* Тесты */
  TEST_READ_ALL: 'API_DECISION_TEST_READ_ALL', // Получение списка тест-кейсов с описаниями
  TEST_READ: 'API_DECISION_TEST_READ', // Получение данных по конкретному тестовому набору
  TEST_READ_RESULTS: 'API_DECISION_TEST_READ_RESULTS', // Запрос и получение результатов тестирования
  TEST_CREATE: 'API_DECISION_TEST_CREATE', // Кнопка "Добавить тестовый набор"
  TEST_UPDATE: 'API_DECISION_TEST_UPDATE', // Кнопка "Сохранить тестовый набор"
  TEST_DELETE: 'API_DECISION_TEST_DELETE', // Удаление выбранных тестовых наборов
  TEST_READ_STATUS: 'API_DECISION_TEST_READ_STATUS', // Получение статуса процесса тестирования
  TEST_START: 'API_DECISION_TEST_START', // Запуск тестирования
  TEST_CASE_READ: 'API_DECISION_TEST_CASE_READ', // Получение тест-кейса по уникальному идентификатору
  TEST_FILE_CREATE: 'API_DECISION_TEST_FILE_CREATE', // Генерация файла тест-кейса диаграммы
  TEST_FILE_UPLOAD: 'API_DECISION_TEST_FILE_UPLOAD', // Загрузить файл с данными тестирования
  TEST_FILE_DOWNLOAD: 'API_DECISION_TEST_FILE_DOWNLOAD', // Генерация ранее загруженного файла тест-кейса диграммы (со значениями)
  /* Агрегаты */
  AGGREGATE_READ: 'API_DECISION_AGGREGATE_READ', // Открытие окна редактирования агрегата по двойному клику
  AGGREGATE_CREATE: 'API_DECISION_AGGREGATE_CREATE', // Кнопка "Сохранить" и "Сохранить как". Первичное сохранение агрегата, Кнопка "Добавить агрегат"
  AGGREGATE_UPDATE: 'API_DECISION_AGGREGATE_UPDATE', // Корректировка параметров агрегата
  AGGREGATE_DELETE: 'API_DECISION_AGGREGATE_DELETE', // Кнопка удаления выбранных агрегатов
  AGGREGATE_READ_RELATIONS: 'API_DECISION_AGGREGATE_READ_RELATIONS', // Полчение списка зависимых объектов
  AGGREGATE_READ_ALL_BY_GROUPING_ELEMENT:
    'API_DECISION_AGGREGATE_READ_ALL_BY_GROUPING_ELEMENT', // Получение списка всех доступных агрегатов по группирующему элементу
  AGGREGATE_READ_GROUPING_ELEMENTS:
    'API_DECISION_AGGREGATE_READ_GROUPING_ELEMENTS', // Получение списка группирующих элементов агрегатов
  AGGREGATE_READ_VERSIONS: 'API_DECISION_AGGREGATE_READ_VERSIONS',
  /* Узлы */
  NODE_CREATE: 'API_DECISION_NODE_CREATE', // Создание записи по узлу
  NODE_READ: 'API_DECISION_NODE_READ', // Получение информации по узлу
  NODE_UPDATE: 'API_DECISION_NODE_UPDATE', // Изменение записи по узлу
  NODE_DELETE: 'API_DECISION_NODE_DELETE', // Удаление записи по узлу
  NODE_GET_POSSIBLE: 'API_DECISION_NODE_POSSIBLE', // Получение списка возможных узлов
  /* Ребра */
  LINK_CREATE: 'API_DECISION_LINK_CREATE', // Создание записи по ребру
  LINK_DELETE: 'API_DECISION_LINK_DELETE', // Удаление записи по ребру
  /* ENVIRONMENT */
  ENVIRONMENT_READ_ALL: 'API_DECISION_ENVIRONMENT_READ_ALL', // Получение списка сред развертывания
  ENVIRONMENT_CREATE: 'API_DECISION_ENVIRONMENT_CREATE', // Создание среды развертывания
  ENVIRONMENT_UPDATE: 'API_DECISION_ENVIRONMENT_UPDATE', // Обновление среды развертывания
  ENVIRONMENT_DELETE: 'API_DECISION_ENVIRONMENT_DELETE', // Удаление среды развертывания
  /* ENVIRONMENT */
  LOCKING_READ_ALL: 'API_DECISION_ENVIRONMENT_READ_ALL', // Получение списка заблокированных объектов
  LOCKING_CREATE: 'API_DECISION_LOCKING_CREATE', // Создание блокировки
  LOCKING_DELETE_BY_TYPE_AND_ID: 'API_DECISION_LOCKING_DELETE_BY_TYPE_AN_ID', // Удаление блокировки по типу объекта и идентификатору

  /**
   * Итоговые композитные роли
   **/
  READER: 'COMPLETE_DECISION_READER',
  EDITOR: 'COMPLETE_DECISION_EDITOR',
  ADMINISTRATOR: 'COMPLETE_DECISION_ADMINISTRATOR',
  TESTER: 'COMPLETE_DECISION_TESTER',
  APPROVER: 'COMPLETE_DECISION_APPROVER',
  DEPLOYER: 'COMPLETE_DECISION_DEPLOYER',
  INTEGRATOR: 'COMPLETE_DECISION_INTEGRATOR',
};
