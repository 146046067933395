import { RootState, useAppSelector } from '@modules/store';
import { createSelector } from '@reduxjs/toolkit';

export const useEnvironmentsSelector = () => {
  const selectData = (state: RootState) => state.environments.data;

  const data = useAppSelector(selectData);

  const selectedRowKeys = useAppSelector(
    (state: RootState) => state.environments.selectedRowKeys
  );

  const rowKeys = useAppSelector(
    createSelector(selectData, (data) => data.map((i) => i.key))
  );

  return { data, selectedRowKeys, rowKeys };
};
