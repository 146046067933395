import React, { useMemo, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IConfirmModalProps, Modal } from '@modules/shared';
import { SolutionListsNew } from '@modules/development/components/solutionListsNew/SolutionListsNew';
import { StreamTable } from '@modules/development/modules/streams/components/streamTable';
import {
  createDiagram,
  deleteDiagram,
  getDiagrams,
  getSelectedDiagramsRow,
  onSelectedDiagramsRow,
  removeOpenObject,
  removeOpenTab,
  selectDiagrams,
  selectOpenTabs,
  setDiagramsReadyToDeploy,
} from '@modules/development/DevelopmentSlice';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { rolesList, selectRoles } from '@modules/services';
import { STREAMS_CORE_PATH } from '../../routing/streamsConstants';
import { useHistory } from 'react-router-dom';
import { IDataTableList, IModalProps } from '@modules/development';
import { filterEqualByKey } from '@modules/utils';
import { StreamsListParamsExportModal } from './components/streamsListParamsExportModal';
import { StreamsListExportStreamsModal } from './components/streamsListExportStreamsModal';
import { StreamsListImportStreamsModal } from './components/streamsListImportStreamsModal';
import { StreamsListSuccessModal } from './components/streamsListSuccessModal';
import { tryCatchDecorator } from '@modules/development/utils';
import { exportApi } from '@modules/development';

export const EXPORT_STREAMS_MODAL = 'exportStreamsModal';
export const IMPORT_STREAMS_MODAL = 'importStreamsModal';
export const successExport = 'successExport';
export const successImport = 'successImport';

export const StreamsList: VFC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const diagrams = useAppSelector(selectDiagrams);
  const roles = useAppSelector(selectRoles);
  const selectedRow = useAppSelector(getSelectedDiagramsRow);
  const openTabs = useAppSelector(selectOpenTabs);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState<
    IConfirmModalProps | IModalProps | null
  >(null);
  const [exportModal, setExportModal] = useState(false);
  const [successModalProps, setSuccessModalProps] =
    useState<IModalProps | null>(null);

  const onAddClick = async () => {
    await tryCatchDecorator(dispatch, async () => {
      const diagram = await dispatch(createDiagram());
      diagram &&
        history.push(`${STREAMS_CORE_PATH}/${diagram.versionId}`, {
          diagram,
        });
    });
  };

  const onDeleteClick = async () => {
    if (!modalProps) {
      const contentText = t('development.solutionLists.confirmDeleteText', {
        name: t('development.solutionLists.sidePanel.diagrams').toLowerCase(),
      });

      return setModalProps({
        type: 'confirmDelete',
        width: '440px',
        okButtonText: t('modals.yes'),
        closeButtonText: t('modals.no'),
        contentText: contentText,
        kind: 'info',
      });
    }

    selectedRow.forEach(async (key) => {
      const isOpen = openTabs.find((item) => item.key === key);

      if (isOpen) {
        // если объект открыт, то удалить его из списка открытых табов
        // и из списка открытых объектов
        dispatch(removeOpenTab({ key }));
        dispatch(removeOpenObject({ key }));
      }

      // в зависимости от открытой вкладки удалить кастомный код или диаграмму
      dispatch(deleteDiagram(key));
      dispatch(onSelectedDiagramsRow({ keys: [] }));
    });

    // TODO этот ужас надо исправить
    setTimeout(() => dispatch(getDiagrams()), 200);
  };

  const onRefreshClick = () => {
    dispatch(getDiagrams());
  };

  const onSelectionViewClick = () => {};

  const openedDiagrams = useMemo(() => {
    if (openTabs) {
      return openTabs
        .filter((tab) => tab.type === 'diagram')
        .filter((item) => selectedRow.includes(item.key))
        .map((diagram) => {
          return <div>{diagram.text}</div>;
        });
    } else {
      return [];
    }
  }, [openTabs, selectedRow]);

  const onSendDeployClick = async () => {
    if (openedDiagrams.length) {
      setIsModalOpen(true);
    } else {
      dispatch(setDiagramsReadyToDeploy(selectedRow));
    }
  };

  const exportStreams = () => {
    setExportModal(true);
  };

  const readFile = (inputChangeEvent: any) => {
    const file = (inputChangeEvent.target as HTMLInputElement)?.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      tryCatchDecorator(dispatch, async () => {
        return await exportApi.uploadImportFile(formData);
      }).then((res) => {
        setModalProps({ type: IMPORT_STREAMS_MODAL, data: res });
      });
    }
  };

  const uploadJson = () => {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', '.json');
    fileInput.onchange = readFile;
    fileInput.click();
  };

  const toolbarData = [
    {
      isDivider: true,
    },
    {
      icon: 'icon-add',
      tooltip: t('actions.add'),
      onClick: onAddClick,
      disabled: !roles.includes(rolesList.DIAGRAM_CREATE),
    },
    {
      icon: 'icon-delete',
      tooltip: t('actions.delete'),
      onClick: onDeleteClick,
      disabled: !roles.includes(rolesList.DIAGRAM_DELETE),
    },
    {
      icon: 'icon-refresh',
      tooltip: t('actions.refresh'),
      onClick: onRefreshClick,
    },
    {
      icon: 'icon-selection_view',
      onClick: onSelectionViewClick,
    },
    {
      icon: 'icon-deploy',
      tooltip: t('actions.sendOnDeploy'),
      onClick: onSendDeployClick,
      disabled: !selectedRow.length,
    },
    {
      icon: 'icon-download_file',
      tooltip: t('actions.exportStream'),
      disabled: !selectedRow.length,
      onClick: exportStreams,
    },
    {
      icon: 'icon-upload_to_file',
      tooltip: t('actions.importStream'),
      onClick: uploadJson,
    },
  ];

  const modalFooter = () => {
    return (
      <Button
        kind="normal"
        onClick={() => setIsModalOpen(false)}
        style={{ display: 'inline' }}
      >
        Ok
      </Button>
    );
  };

  const modalBody = () => {
    return (
      <div className="modal__child">
        {t('deployDiagram.modals.modalText')}
        {openedDiagrams}
      </div>
    );
  };

  const data = useMemo<IDataTableList[]>(() => {
    let tempData: IDataTableList[] = diagrams.data.map((d) => ({
      key: d.version,
      title: d.diagramName,
      code: d.key,
      location: d.location,
      state: d.state,
      dateOfChange: d.changeDt || d.createDt,
      user: d.lastChangeByUser,
      version: d.version,
    }));

    if (diagrams.search) {
      const byTitle = tempData.filter((entry) =>
        entry.title.toLowerCase().includes(diagrams.search.toLowerCase())
      );

      const byCode = tempData.filter((entry) =>
        entry.code.toLowerCase().includes(diagrams.search.toLowerCase())
      );

      tempData = filterEqualByKey(byTitle.concat(byCode), 'code');
    }

    return tempData;
  }, [diagrams]);

  return (
    <>
      <SolutionListsNew
        title={t('development.streams.streamsList.title')}
        rowKeys={data.map((i) => i.key)}
        selectedRow={selectedRow}
        onSelectedRowChange={onSelectedDiagramsRow}
        toolbarData={toolbarData}
        modalProps={modalProps}
        onDeleteClick={onDeleteClick}
        setModalProps={setModalProps}
        children={<StreamTable data={data} />}
      />
      {exportModal && (
        <StreamsListParamsExportModal
          data={data}
          onClose={() => setExportModal(false)}
          selectedRows={selectedRow}
          setModalProps={setModalProps}
        />
      )}
      {modalProps?.type === EXPORT_STREAMS_MODAL && (
        <StreamsListExportStreamsModal
          modalProps={modalProps}
          onClose={() => setModalProps(null)}
          setSuccessModalProps={setSuccessModalProps}
        />
      )}
      {modalProps?.type === IMPORT_STREAMS_MODAL && (
        <StreamsListImportStreamsModal
          modalProps={modalProps}
          onClose={() => setModalProps(null)}
          setSuccessModalProps={setSuccessModalProps}
        />
      )}
      {(successModalProps?.type === successExport ||
        successModalProps?.type === successImport) && (
        <StreamsListSuccessModal
          setModalProps={setModalProps}
          setSuccessModalProps={setSuccessModalProps}
          successModalProps={successModalProps}
        />
      )}
      <Modal
        isOn={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalProps={{
          title: t('deployDiagram.modals.hasOpenedDiagrams'),
          icon: 'icon-alert-blue',
          footer: modalFooter(),
        }}
      >
        {modalBody()}
      </Modal>
    </>
  );
};
