import { ColumnType } from 'antd/lib/table';
import { useMemo } from 'react';
import * as React from 'react';
import { RenderedCell } from 'rc-table/lib/interface';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@modules/store';
import { openModals } from '@modules/development/DevelopmentSlice';

export type ConfigureTableSetting<T> = {
  [key in keyof Partial<T>]: ColumnType<T>;
};

interface IUseColumnSetup<T> {
  rawColumns: ColumnType<T> & any[];
  configureSettings?: ConfigureTableSetting<T>;
  render?: (
    value: any,
    record: T,
    index: number
  ) => React.ReactNode | RenderedCell<T>;
  translate?: (value: string) => string;
}

export const useColumnSetup = <T>({
  rawColumns,
  configureSettings = {} as ConfigureTableSetting<T>,
  render = (value) => value,
  translate,
}: IUseColumnSetup<T>) => {
  const { t } = useTranslation();
  const currentModal = useAppSelector(openModals);
  if (currentModal[0] === 'postgres-cdc') {
    rawColumns[0].title =
      'development.streams.streams.modals.kafka.properties.name';
    rawColumns[1].title =
      'development.streams.streams.modals.kafka.properties.value';
  }

  return useMemo(
    () =>
      rawColumns.map((column) => ({
        ...column,
        ...(configureSettings[column.dataIndex as keyof T]
          ? configureSettings[column.dataIndex as keyof T]
          : { render }),
        ...(column?.title
          ? {
              title: translate
                ? translate(column?.title as string)
                : t(column.title as string),
            }
          : {}),
        ...(column.showSorterTooltip
          ? {
              showSorterTooltip: {
                title: translate // @ts-ignore
                  ? translate(column.showSorterTooltip.title)
                  : // @ts-ignore
                    column.showSorterTooltip.title,
              },
            }
          : {}),
      })),
    [rawColumns, configureSettings]
  );
};
