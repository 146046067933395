import { apiBase } from '@modules/apiBase';

export const deployDiagramApi = {
  getDeployDiagramList: async () => {
    return await apiBase.get<any>('deploy');
  },

  getEnvironments: async () => {
    return await apiBase.get<any>('environments');
  },

  // environmentId в балайке отсутствует
  deployDiagram: async (
    versionId: React.Key,
    environmentId: React.Key,
    settings: any
  ) => {
    return await apiBase.post<any>(`/diagrams/${versionId}/deploy`, settings);
  },

  undeployDiagram: async (versionId: React.Key) => {
    return await apiBase.post<any>(`/diagrams/${versionId}/undeploy`);
  },
};
