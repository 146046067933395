import { useTranslationPath } from '@modules/languageProvider';
import { useMemo, useState } from 'react';
import { CalculateNode, ICalculateVariable } from './interfaces';

// @ts-ignore
const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

type InvalidData = {
  inputs: { [key: string]: string };
  calculate: { [key: string]: { [key: string]: string } };
};

export const useCalcVariablesModalValidation = (
  calcVariablesData: CalculateNode | undefined,
  initialValidate = false
) => {
  const translate = useTranslationPath(
    'development.diagram.diagram.modals.calcVariables'
  );
  const [validate, setValidate] = useState(initialValidate);
  const calculate = calcVariablesData?.properties?.calculate;

  const checkInvalidType = (v: ICalculateVariable) => {
    return v.typeId !== v.expression.calculateExpressionTypeId;
  };

  const checkEmptyCells = (v: ICalculateVariable) => {
    return !(
      v.variableName &&
      v.typeId &&
      v.expression.calculateExpressionValue
    );
  };

  const checkVariableRepeat = (v: ICalculateVariable, i: number) => {
    return calculate?.some(
      (cv, ci) => cv.variableName === v.variableName && ci !== i
    );
  };

  const invalidData = useMemo(() => {
    const result: InvalidData = {
      inputs: {},
      calculate: {},
    };

    if (!validate) return result;

    if (!calcVariablesData?.nodeName) {
      result.inputs.nodeName = translate('emptyValue');
    }

    calculate?.forEach((v, i) => {
      if (!v.variableName) {
        result.calculate[i] = {
          ...result.calculate[i],
          variableName: translate('emptyValue'),
        };
      }

      if (!v.expression.calculateExpressionValue) {
        result.calculate[i] = {
          ...result.calculate[i],
          calculateExpression: translate('emptyValue'),
        };
      }

      if (!HIDE_FEATURES) {
        if (checkInvalidType(v)) {
          result.calculate[i] = {
            ...result.calculate[i],
            calculateExpression: translate('errorType'),
          };
        }
      }

      if (checkVariableRepeat(v, i)) {
        result.calculate[i] = {
          ...result.calculate[i],
          variableName: translate('repeatValue'),
        };
      }
    });

    return result;
  }, [calcVariablesData, validate, calculate, translate]);

  const getErrors = () => {
    !validate && setValidate(true);
    const errors = [];

    if (!calcVariablesData?.nodeName) {
      errors.push(translate('errorBlockName'));
    }

    if (calculate?.some(checkEmptyCells)) {
      errors.push(translate('errorEmptyCells'));
    }

    if (!HIDE_FEATURES) {
      if (calculate?.some(checkInvalidType)) {
        errors.push(translate('errorType'));
      }
    }
    if (calculate?.some(checkVariableRepeat)) {
      errors.push(translate('errorRepeat'));
    }

    return errors;
  };

  return [invalidData, getErrors] as const;
};
