import React, { VFC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './Loader.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

export const Loader: VFC = () => {
  return (
    <div className="loader">
      <Spin indicator={antIcon} />
    </div>
  );
};
