import { IDiagramNode, IDataVariables } from '../../../../../types';
import { ConfirmSaveModalProps } from '../ConfirmInvalidBlockSaveModal/ConfirmInvalidBlockSaveModal';

export type RuleVariable = IDataVariables;

export type Rule = {
  applyRule: boolean;
  ruleName: string;
  ruleCode: string;
  ruleTypeId: string;
  ruleDescription: string;
  ruleExpression: string;
};

export interface IRulesetProperties {
  ruleVariable: RuleVariable;
  rules: Rule[];
}

export type RulesetNode = IDiagramNode<IRulesetProperties>;

export enum ModalTypes {
  expressionEditor = 'expressionEditor',
  addElement = 'addElement',
}

export type ModalProps =
  | {
      type: ModalTypes.expressionEditor;
      title: string;
      index: number;
      ruleExpression: string;
    }
  | {
      type: ModalTypes.addElement;
      title: string;
      variableName: string;
    }
  | ConfirmSaveModalProps
  | null;
