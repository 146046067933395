import { FC, Key, useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  InputSearch,
  SimpleTable,
  ConfirmModal,
  Loader,
  useSearch,
} from '@shared';
import {
  CustomCodeVersions,
  DiagramVersions,
  IModalProps,
} from '@modules/development';
import { useTranslationPath } from '@modules/languageProvider';
import { ServiceVersions } from '@modules/services';

type Versions = ServiceVersions | CustomCodeVersions | DiagramVersions;

type TableData = {
  key: string;
  versionName: string;
  versionId: string;
  versionType: string;
  changeDt: string;
  lastChangeByUser: string;
  versionFlag?: boolean;
};

interface IChooseVersionModal {
  modalProps: IModalProps;
  isDiagramVersion?: boolean;
  selectedKeys?: string[];
  onSave: (versionId: string) => void;
  onClose: () => void;
  getVersions: () => Promise<Versions>;
}

export const ChooseVersionModal: FC<IChooseVersionModal> = ({
  modalProps,
  isDiagramVersion,
  selectedKeys,
  onSave,
  onClose,
  getVersions,
}) => {
  const translate = useTranslationPath('modals.chooseVersionModal');

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>(
    selectedKeys || []
  );
  const [search, setSearch] = useState<string>('');
  const [versions, setVersions] = useState<Versions>([]);
  const [loading, setLoading] = useState(false);

  const [, , getInputProps, filterSearch] = useSearch({ search, setSearch });

  const sorterCellString = (a: any, b: any, c: any) => {
    const safeA = a?.[c]?.toLowerCase() || 'Z';
    const safeB = b?.[c]?.toLowerCase() || 'Z';
    return safeA.localeCompare(safeB);
  };

  const fetchVersions = async () => {
    setLoading(true);
    const versions = await getVersions();
    setLoading(false);
    setVersions(versions || []);
  };

  const onSaveClick = () => {
    const versionId = selectedRowKeys?.[0] && String(selectedRowKeys[0]);
    if (versionId) onSave(versionId);
  };

  const columns: ColumnsType<TableData> = [
    {
      key: 'versionName',
      dataIndex: 'versionName',
      title: translate('objectVersion'),
      ellipsis: true,
      width: '15%',
      sorter: (a: any, b: any) => sorterCellString(a, b, 'versionName'),
    },
    {
      key: 'versionId',
      dataIndex: 'versionId',
      title: translate('versionID'),
      ellipsis: true,
      width: '15%',
      sorter: (a: any, b: any) => sorterCellString(a, b, 'versionId'),
    },
    {
      key: 'versionType',
      dataIndex: 'versionType',
      title: translate('versionType'),
      ellipsis: true,
      width: '15%',
      sorter: (a: any, b: any) => sorterCellString(a, b, 'versionType'),
    },
    {
      key: 'changeDt',
      dataIndex: 'changeDt',
      title: translate('lastChangeDt'),
      ellipsis: true,
      width: '30%',
      sorter: (a: any, b: any) => sorterCellString(a, b, 'changeDt'),
    },
    {
      key: 'lastChangeByUser',
      dataIndex: 'lastChangeByUser',
      title: translate('lastChangeUser'),
      ellipsis: true,
      width: '30%',
      sorter: (a: any, b: any) => sorterCellString(a, b, 'lastChangeByUser'),
    },
  ];

  const diagramColumns: ColumnsType<TableData> = [
    {
      key: 'versionFlag',
      dataIndex: 'versionFlag',
      title: translate('versionStorageFlag'),
      width: '15%',
      render: (value: boolean) => <Checkbox checked={value} />,
    },
  ];

  const innerColumns = isDiagramVersion
    ? [...columns, ...diagramColumns]
    : columns;

  const data: TableData[] = filterSearch<TableData>(
    versions
      .map((v) => ({
        key: v.versionId,
        versionName: v.versionName || '',
        versionId: v.versionId,
        versionType: v.versionType,
        changeDt: v.changeDt,
        lastChangeByUser: v.lastChangeByUser,
        versionFlag: !!v.rootObjectVersionId,
      }))
      .filter((v) => v.versionType !== 'TEMP' && v.versionType !== 'DEPLOYED'),
    'versionName'
  );

  useEffect(() => {
    fetchVersions();
  }, []);

  return (
    <ConfirmModal
      modalProps={{
        title: translate('title'),
        type: modalProps?.type,
        width: '1295px',
        icon: 'icon-dots',
        closeButtonText: translate('cancel'),
        okButtonText: translate('choose'),
        okButtonDisabled: !selectedRowKeys?.length,
      }}
      onClose={onClose}
      onOk={onSaveClick}
    >
      {loading && <Loader />}
      <div className="choose-custom-code__search">
        <InputSearch {...getInputProps()} bordered />
      </div>
      <SimpleTable
        columnsData={innerColumns}
        dataSource={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        isSingleChoice
        hideSelectAll
      />
    </ConfirmModal>
  );
};
