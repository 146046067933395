import { apiBase, IResponse } from '@modules/apiBase';
import {
  IDataProviderExt,
  IDataProviderTables,
  IDataProviderAttr,
  IDataProviderUpdate,
  IDataProviderBase,
} from '../types/interface';

const DATA_PROVIDER_ENDPOINT = 'dataprovider';

export const dataProviderApi = {
  getDataProviderById: async (sourceId: string) => {
    return await apiBase.get<IDataProviderExt>(
      `${DATA_PROVIDER_ENDPOINT}/${sourceId}`
    );
  },

  updateDataProviderById: async (
    sourceId: string,
    requestBody: IDataProviderUpdate
  ) => {
    return await apiBase.put<IResponse>(
      `${DATA_PROVIDER_ENDPOINT}/${sourceId}`,
      requestBody
    );
  },

  deleteDataProviderById: async (sourceId: string) => {
    return await apiBase.delete<IResponse>(
      `${DATA_PROVIDER_ENDPOINT}/${sourceId}`
    );
  },

  getDataProvider: async () => {
    return await apiBase.get<IDataProviderExt[]>(`${DATA_PROVIDER_ENDPOINT}`);
  },

  addDataProvider: async (body: IDataProviderUpdate) => {
    return await apiBase.post<IResponse>(`${DATA_PROVIDER_ENDPOINT}`, body);
  },

  getDataProviderTables: async (sourceId: string) => {
    return await apiBase.get<IDataProviderTables[]>(
      `${DATA_PROVIDER_ENDPOINT}/${sourceId}/tables`
    );
  },

  getDataProviderAttr: async (sourceId: string, tableName: string) => {
    return await apiBase.get<IDataProviderAttr[]>(
      `${DATA_PROVIDER_ENDPOINT}/${sourceId}/tables/${tableName}`
    );
  },

  testConnection: async (body: IDataProviderBase) => {
    return await apiBase.post<{
      result: 'SUCCESS' | 'FAILED';
      description?: string;
    }>(`${DATA_PROVIDER_ENDPOINT}/testConnection`, body);
  },
};
