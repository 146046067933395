import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import classnames from 'classnames';
import { Tooltip } from '..';
import './Select.scss';
import SelectAngleIcon from '@modules/icon/assets/icon-select_angle.svg';
import { useTranslation } from 'react-i18next';

export interface ISelect<T = string>
  extends Omit<AntdSelectProps<T>, 'options'> {
  required?: boolean;
  label?: string;
  options?: { value: string; label?: string; translation?: string }[];
  error?: string;
  onChange?: (value: T) => void;
}

export const Select = <T extends string>({
  className,
  required,
  label,
  size,
  error,
  options,
  ...props
}: ISelect<T>) => {
  const { t } = useTranslation();

  const translatedOptions = options?.map((o) => ({
    value: o.value,
    label: o.translation ? t(o.translation) : o.label ? o.label : o.value,
  }));

  return (
    <div
      className={classnames(
        className,
        'select-ext__container',
        typeof error === 'string' && 'select-ext__container--error'
      )}
    >
      <div className="select-ext__top">
        {label && <span className="select-ext__label">{label}</span>}
        {required && <span className="select-ext__required">*</span>}
      </div>
      <Tooltip error={!!error} title={error}>
        <AntdSelect
          className="select-ext"
          size={size || 'large'}
          suffixIcon={<SelectAngleIcon className="select-ext__icon" />}
          options={translatedOptions}
          {...props}
        />
      </Tooltip>
    </div>
  );
};
