import React, { Key, useEffect, useMemo, useState, VFC } from 'react';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import './StreamsListExportStreamsModal.scss';
import {
  Modal,
  ModalBox,
  ModalFooter,
  SimpleTable,
  useModalVisible,
} from '@shared';
import { exportApi, IModalProps } from '@modules/development';
import { useTranslation } from '@modules/languageProvider';
import { tryCatchDecorator } from '@modules/development/utils';
import { useDispatch } from 'react-redux';
import fileDownload from 'js-file-download';
import dayjs from 'dayjs';
import { useAppSelector } from '@modules/store';
import { selectUserName } from '@modules/services';
import { successExport } from '@modules/development/modules/streams/components/streamsList/StreamsList';

type RecordType = {
  key: Key;
  title?: string;
  children?: RecordType[];
  selectable?: boolean;
  objectName?: string;
  objectType?: string;
  objectVersionName?: string;
  objectVersionType?: string;
  rootObjectName?: string;
  objectVersionId: string;
};

interface IExportsObject {
  key: Key;
  title: string;
  selectable: boolean;
  children: ChildrenType[];
}
[];

type ChildrenType = {
  key: Key;
  objectId: string;
  objectName: string;
  objectType: string;
  objectVersionId?: string;
  objectVersionName: string;
  objectVersionType: string;
  rootObjects: { [key: string]: string };
};

const getTranslate = (value: string) => `modals.confirmExportObjects.${value}`;

interface IExportModal {
  modalProps: IModalProps;
  onClose: () => void;
  setSuccessModalProps: React.Dispatch<IModalProps | null>;
}

export const StreamsListExportStreamsModal: VFC<IExportModal> = ({
  modalProps,
  onClose,
  setSuccessModalProps,
}) => {
  const { t } = useTranslation();
  const isVisible = useModalVisible(modalProps.type);
  const userName = useAppSelector(selectUserName);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const dispatch = useDispatch();
  const columns: ColumnsType<RecordType> = [
    {
      title: t(getTranslate('objectName')),
      dataIndex: 'objectName',
      sorter: (a, b) => (a.objectName || '').localeCompare(b.objectName || ''),
      render: (value, record) => (
        <span className={classNames(!record.objectType && 'gray')}>
          {!record.objectType ? record.title : value}
        </span>
      ),
    },
    {
      title: t(getTranslate('objectType')),
      dataIndex: 'objectType',
      sorter: (a, b) => (a.objectType || '').localeCompare(b.objectType || ''),
    },
    {
      title: t(getTranslate('versionName')),
      dataIndex: 'objectVersionName',
      sorter: (a, b) =>
        (a.objectVersionName || '').localeCompare(b.objectVersionName || ''),
      render: (val, row) => {
        if (row.children) return;
        if (row.objectType === 'DEPLOY' || row.objectType === 'DATA_PROVIDER') {
          return '-';
        } else {
          return val;
        }
      },
    },
    {
      title: t(getTranslate('versionType')),
      dataIndex: 'objectVersionType',
      sorter: (a, b) =>
        (a.objectVersionType || '').localeCompare(b.objectVersionType || ''),
      render: (val, row) => {
        if (row.children) return;
        if (row.objectType === 'DEPLOY' || row.objectType === 'DATA_PROVIDER') {
          return '-';
        } else {
          return val;
        }
      },
    },
    {
      title: t(getTranslate('rootObjectName')),
      dataIndex: 'rootObjects',
      sorter: (a, b) =>
        (a.rootObjectName || '').localeCompare(b.rootObjectName || ''),
      render: (value, row) => {
        if (!value) return;
        if (value[row.objectVersionId]) {
          delete value[row.objectVersionId];
        }
        const values = Object.values(value);
        return values.map((v) => {
          return (
            <>
              {v}
              <br></br>
            </>
          );
        });
      },
    },
  ];

  const data: IExportsObject[] = useMemo(() => {
    const data = Object.entries(modalProps.data).map((item: any, index) => {
      return {
        key: index,
        title: t(getTranslate(item[0])),
        selectable: false,
        children: item[1].map((i: ChildrenType) => ({
          ...i,
          key: i.objectVersionId || i.objectId,
          selectable: false,
        })),
      };
    });
    return data;
  }, [modalProps]);

  const selectAll = () => {
    const childrens = data.map((item) => item.children);
    const keys = childrens.flat().map((c) => c.key);
    setSelectedRowKeys(keys);
  };

  useEffect(() => {
    selectAll();
  }, [data]);

  const onSelectKeys = (keys: Key[]) => {
    const deselectedKey = selectedRowKeys.find((k) => !keys.includes(k));
    const selectedKey = keys.find((k) => !selectedRowKeys.includes(k));
    const activeKey = selectedKey ? selectedKey : deselectedKey;
    const childrens = data.map((item) => item.children).flat();
    const activeObject = childrens.find((c) => c.objectVersionId === activeKey);
    console.log(activeObject);

    // const deploysKeys = data
    //   .filter((d) => d.title === 'deploys')
    //   .map((item) => item.children)
    //   .flat()
    //   .map((i) => i.key);
    // const addedKeys: Key[] = [];
    // const deletedKeys: Key[] = [];
    // if (
    //   activeObject?.objectType === 'DIAGRAM' &&
    //   activeObject.objectVersionType === 'LATEST'
    // ) {
    // }
    const childKeys = childrens
      .filter((child) => child.rootObjects[activeKey as React.Key])
      .map((c) => (c.objectVersionId as Key) || (c.objectId as Key));
    console.log(childrens);
    console.log(childKeys);
    if (deselectedKey) {
      setSelectedRowKeys(keys.filter((key) => !childKeys.includes(key)));
    }
    if (selectedKey) {
      setSelectedRowKeys(keys.concat(...childKeys));
    }
  };

  const exportFile = async () => {
    const data = Object.entries(modalProps.data).map((item: any) => {
      return {
        [item[0]]: item[1].map((val: any) => {
          if (selectedRowKeys.includes(val.objectVersionId || val.objectId)) {
            return {
              ...val,
              isSelected: true,
            };
          } else {
            return {
              ...val,
              isSelected: false,
            };
          }
        }),
      };
    });
    const dataSend: any = {};
    data.forEach((item) => {
      const entries: any = Object.entries(item);
      dataSend[entries[0][0]] = entries[0][1];
    });

    const date = dayjs().format('DD-MM-YYYY');
    const fileName = `${userName.replace(' ', '_')}_${date}_${
      modalProps.type === 'deployExportModal' ? 'deploy' : 'diagram'
    }.json`;
    console.log(fileName);
    await tryCatchDecorator(dispatch, async () => {
      return await exportApi.export(dataSend).then((res: any) => {
        tryCatchDecorator(dispatch, async () => {
          return await exportApi.downloadFile(res.fileName);
        })
          .then((file) => {
            return fileDownload(JSON.stringify(file), fileName);
          })
          .then(() => {
            console.log('HI');
            setSuccessModalProps({
              type: successExport,
              title: t('modals.successExport.title'),
              data: res,
            });
          })
          .finally(() => {
            onClose();
          });
      });
    });
  };

  return (
    <Modal
      isOn={isVisible}
      modalProps={{
        type: modalProps.type,
        title: t(getTranslate('title')),
        icon: 'icon-dots',
        width: '1346px',
        footer: (
          <ModalFooter
            saveText={t('actions.export')}
            onSave={exportFile}
            onClose={onClose}
            saveDisabled={!selectedRowKeys.length}
          />
        ),
      }}
      onClose={onClose}
    >
      <div className="confirm-export-objects-modal">
        <div className="confirm-export-objects-modal__description">
          <span>{t(getTranslate('description1'))}</span>
          <span>{t(getTranslate('description2'))}</span>
        </div>

        <ModalBox className="confirm-export-objects-modal__table">
          <SimpleTable
            columnsData={columns}
            dataSource={data}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={(keys) => {
              setSelectedRowKeys(keys);
              onSelectKeys(keys);
            }}
          />
        </ModalBox>
      </div>
    </Modal>
  );
};
