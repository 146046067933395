import React, { useMemo } from 'react';
import { useDataSourceHeaders } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/hooks';
import { useTranslationPath } from '@modules/languageProvider';
import { tranlationPath } from '@modules/administration/components/ExternalServiceCallSettingsModal/data';
import { useConnectionsSelector } from '@modules/connections/hooks';
import { DataSourcePanel } from '@modules/connections/components/connectionsModalDataSource/components';
import { Table } from 'antd';

export const ExternalServiceHeaders: React.FC = () => {
  const translate = useTranslationPath(tranlationPath);
  const { oneConnection = {} } = useConnectionsSelector();
  const { properties = {} } = oneConnection ?? ({} as any);
  const { headers = [] } = properties;

  const { columns, toolbar, selectedHeaders, setSelectedHeaders, tableData } =
    useDataSourceHeaders(headers);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: false,
      onChange: (selectedRowKeys: React.Key[]) => {
        setSelectedHeaders(selectedRowKeys);
      },
      selectedRowKeys: selectedHeaders,
    }),
    [selectedHeaders]
  );

  return (
    <DataSourcePanel
      title={translate('headers')}
      panel={{ key: 'external-service-accordion-2' }}
      toolbar={{ data: toolbar }}
    >
      <Table
        columns={columns}
        dataSource={tableData}
        rowSelection={rowSelection}
        className="connections__table"
      />
    </DataSourcePanel>
  );
};
