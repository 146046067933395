import React, { Key, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { useTranslation } from 'react-i18next';
import './Aggregate.scss';

import {
  addOpenObject,
  addOpenTab,
  editAggregate,
  getAggregate,
  openObjects,
  selectAggregates,
  selectCurrentObject,
  selectOpenTabs,
  createAggregate,
  updateOpenTab,
  removeOpenObject,
  diagram,
  selectCurrentObjectReadonly,
  setShowError,
  selectIsSavedObject,
  changeSaveStatusObject,
} from '@modules/development/DevelopmentSlice';
import { CloseObjectButton, IAggregate } from '@modules/development';
import { ConfirmModal, Modal, SaveAsModal, TabPanel, Toolbar } from '@shared';
import { Icon } from '@modules/icon';
import { getDataTypes } from '@modules/references';
import { ConfirmSaveModalProps } from '../../../Diagrams/components/DiagramBlocks/ConfirmInvalidBlockSaveModal/ConfirmInvalidBlockSaveModal';
import { AggregateNavigation } from './AggregateNavigation';
import { selectRoles, rolesList } from '@modules/services';
import { useAppSelector } from '@modules/store';
import { useUserGuide } from '@modules/userGuide';
import { AGGREGATES_CORE_PATH } from '../../routing/aggregatesConstants';
import { tabs } from './data';

interface IAgregateProps {}

export const Aggregate: React.FC<IAgregateProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<any>();
  const dispatch = useDispatch();
  const aggregates = useSelector(selectAggregates);
  const currentAggregate = useSelector(diagram);
  const currentAggregateReadonly = useSelector(selectCurrentObjectReadonly);
  const aggregate = useSelector(selectCurrentObject) as IAggregate;
  const openTabs = useSelector(selectOpenTabs);
  const openAllObjects = useSelector(openObjects);
  const isSaved = useSelector(selectIsSavedObject);
  const [activeThirdPanel, setActiveThirdPanel] = useState('');
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState<
    ConfirmSaveModalProps | false
  >(false);
  const [versionName, setVersionName] = useState<string>('');
  const roles = useAppSelector(selectRoles);

  const [getLink] = useUserGuide();

  const aggregateKey = history.location.pathname.split('/')[3];

  const createAggregateRequest = async (body: any) => {
    const response = dispatch(createAggregate(body));
    const data: any = await response;

    history.push(
      `${AGGREGATES_CORE_PATH}/${data.payload.uuid}?u=${aggregateKey}`
    );
  };

  const getAggregateRequest = async (key: Key) => {
    // const relations = await dispatch<any>(getRelations(key));
    const response = await dispatch(getAggregate(key));

    const data: any = await response;
    const parsedUrl = queryString.parse(location.search.slice(1));

    if (location.state?.versionName) {
      setVersionName(`${location.state?.versionName} `);
    } else {
      setVersionName('');
    }

    // если диаграмма на беке не найдена, то вроверяются черновики
    let responseAggregate: IAggregate;
    if (!data.payload) {
      responseAggregate = [
        ...aggregates.data,
        ...(openAllObjects as IAggregate[]),
      ].filter((item) => item.key == key)[0];
      // если черновик не найден, то редирект на not found
      if (!responseAggregate) return history.push('/not-found');
    } else {
      const newData = [data.payload].flat()[0];
      const aggregateJson = JSON.parse(newData.aggregateJson);
      responseAggregate = {
        ...newData,
        key: newData.versionId,
        draft: false,
        aggregateJson,
      };
    }

    // если в табах нет этого объекта, то он добавляется в табы и в список открытых объектов
    if (!openTabs.find((item) => item.key === responseAggregate?.key)) {
      if (parsedUrl.u) {
        dispatch(
          updateOpenTab({
            key: parsedUrl.u,
            tab: {
              key: responseAggregate.versionId,
              text: responseAggregate.aggregateName,
              type: 'aggregate',
              icon: 'icon-aggregate',
            },
          })
        );

        history.push(`${AGGREGATES_CORE_PATH}/${responseAggregate.key}`);
      } else {
        dispatch(
          addOpenTab({
            tab: {
              key: responseAggregate.versionId,
              text: responseAggregate.aggregateName,
              type: 'aggregate',
              icon: 'icon-aggregate',
            },
          })
        );
      }

      dispatch(addOpenObject({ object: responseAggregate }));
    } else {
      dispatch(changeSaveStatusObject({ saveStatus: true }));
      dispatch(
        updateOpenTab({
          key: responseAggregate.key,
          tab: { text: responseAggregate.aggregateName },
        })
      );
    }
  };

  const checkIsValid = (needCheckRelations = true) => {
    dispatch(setShowError(true));

    if (
      !aggregate ||
      !aggregate.aggregateName ||
      !aggregate.aggregateJson.aggregateVariableType ||
      !aggregate.aggregateJson.aggregateFunction ||
      !aggregate.aggregateJson.groupingElement
    ) {
      return alert('Не заполнены обязательные поля');
    }
    if (functionError) {
      return alert(
        'Для данной агрегирующей функции тип поля должен быть цифровой'
      );
    }
    if (needCheckRelations && aggregate.aggregateRelations?.length) {
      const diagrams = aggregate.aggregateRelations
        .map((r) => r.diagramName)
        .filter(Boolean)
        .join(', ');

      return setShowConfirmModal({
        type: 'confirmSave',
        contentText: `Агрегат используется в следующих диаграммах ${diagrams}. Сохранить изменения?`,
      });
    }
    return true;
  };

  const onSaveClick = (needCheck = true) => {
    if (needCheck && !checkIsValid()) return;

    const body = {
      aggregateName: aggregate?.aggregateName,
      aggregateDescription: aggregate?.aggregateDescription || '',
      aggregateJson: JSON.stringify({
        ...aggregate?.aggregateJson,
        aggregateDescription:
          aggregate?.aggregateJson.aggregateDescription || '',
      }),
    };

    if (aggregate?.draft) {
      createAggregateRequest(body);
      dispatch(removeOpenObject({ key: aggregate.key }));
    } else {
      if (!roles.includes(rolesList.AGGREGATE_UPDATE)) {
        return;
      } else {
        dispatch(editAggregate({ id: aggregate.key, body }));
        setTimeout(() => getAggregateRequest(aggregate.key), 10);
      }
    }
  };

  const onSaveAsClick = () => {
    if (!checkIsValid(false)) return;

    setShowSaveAsModal(true);
  };

  const onClickSaveModal = (title: string) => {
    const checkTitle = aggregates.data.filter(
      (item) => item.aggregateName === title
    );

    if (checkTitle[0]) return console.log('название уже занято');

    const body = {
      aggregateName: title,
      aggregateDescription: aggregate.aggregateDescription,
      aggregateJson: JSON.stringify({
        ...aggregate.aggregateJson,
        aggregateName: title,
      }),
    };

    createAggregateRequest(body);
    dispatch(removeOpenObject({ key: aggregate.key }));
    setShowSaveAsModal(false);
  };

  const aggregateToolbar: { [key: string]: any }[] = [
    {
      icon: 'icon-save',
      tooltip: t('actions.save'),
      disabled:
        currentAggregateReadonly ||
        isSaved ||
        !roles.includes(rolesList.AGGREGATE_CREATE),
      onClick: currentAggregateReadonly ? () => {} : onSaveClick,
    },
    {
      icon: 'icon-save_as',
      tooltip: t('actions.saveAs'),
      onClick: onSaveAsClick,
      disabled: !roles.includes(rolesList.AGGREGATE_CREATE),
    },
  ];

  const functionError = useMemo(() => {
    const aggregateVariableType =
      aggregate?.aggregateJson?.aggregateVariableType;
    const aggregateFunction = aggregate?.aggregateJson?.aggregateFunction;

    if (!aggregateFunction) return 'Поле является обязательным';
    if (
      [
        'AggAverage',
        'AggMax',
        'AggMin',
        'AggMode',
        'AggStd',
        'AggSum',
      ].includes(aggregateFunction) &&
      !['0', '1'].includes(aggregateVariableType)
    ) {
      return 'Для данной агрегирующей функции тип поля должен быть цифровой';
    }

    return undefined;
  }, [
    aggregate?.aggregateJson?.aggregateVariableType,
    aggregate?.aggregateJson?.aggregateFunction,
  ]);

  useEffect(() => {
    roles.includes(rolesList.DICTIONARY_READ_ALL_DATA_TYPES) &&
      dispatch(getDataTypes());
    return () => {
      dispatch(setShowError(false));
    };
  }, []);

  useEffect(() => {
    if (aggregateKey) getAggregateRequest(aggregateKey);
  }, [history.location.pathname.split('/')[3]]);

  useEffect(() => {
    const text = [
      versionName,
      currentAggregate.title,
      currentAggregateReadonly && t('development.aggregates.common.readOnly'),
    ]
      .filter(Boolean)
      .join(' ');

    const obj = { key: currentAggregate.key, tab: { text } };
    dispatch(updateOpenTab(obj));
  }, [versionName, currentAggregate, currentAggregateReadonly]);

  useEffect(() => {
    tabs.forEach((data) => {
      if (data.path === '/' + history.location.pathname.split('/')[4]) {
        setActiveThirdPanel(data.key);
      }
    });
  }, [history.location.pathname.split('/')[4]]);

  return (
    <>
      <div className="aggregate">
        <div className="aggregate__top">
          <h1 className="aggregate__title">
            <Icon className="aggregate__title-icon" name="icon-aggregate" />
            <span>
              {versionName + currentAggregate.title}
              {!!currentAggregateReadonly &&
                ` ${t('development.aggregates.common.readOnly')}`}
            </span>
          </h1>
          <div className="aggregate__actions">
            <div className="aggregate__actions-button">
              {getLink('aggregates', <Icon name="icon-help" />)}
            </div>
            <CloseObjectButton
              objectKey={aggregateKey}
              objectType="aggregates"
            />
          </div>
        </div>
        <div className="aggregate__row">
          <TabPanel
            items={tabs.filter((tab) =>
              currentAggregateReadonly ? tab.key !== '2' : true
            )}
            level={3}
            active={activeThirdPanel}
            textAlignCenter
            onClick={(_, key) => {
              history.push(
                `/development/aggregates/${aggregateKey}${tabs[+key].path}`
              );
            }}
          />
        </div>
        <div className="aggregate__row">
          <Toolbar data={aggregateToolbar} />
        </div>
        <AggregateNavigation />
      </div>

      <Modal
        isOn={showSaveAsModal}
        modalProps={{
          title: t('modals.createNewObject'),
          icon: 'icon-expression_editor',
          width: '510px',
        }}
        onClose={() => setShowSaveAsModal(false)}
      >
        <SaveAsModal
          onSave={onClickSaveModal}
          onClose={() => setShowSaveAsModal(false)}
        />
      </Modal>

      {showConfirmModal && (
        <ConfirmModal
          modalProps={showConfirmModal}
          onOk={() => onSaveClick(false)}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};
