import React, { Dispatch, FC, SetStateAction } from 'react';
import classNames from 'classnames';
import { InputSearch, Button, SimpleTable, useSearch } from '@shared';
import { Icon } from '@modules/icon';
import { useTranslation } from 'react-i18next';
import { useDataSourcesInfoTable } from '../hooks';

interface IDataSourcesInfo {
  activeItem: string | null;
  setActiveItem: Dispatch<SetStateAction<string | null>>;
}

export const DataSourcesInfo: FC<IDataSourcesInfo> = ({
  activeItem,
  setActiveItem,
}) => {
  const { t } = useTranslation();
  const [sourceId, tableName] = activeItem?.split(':') || [];

  const [columns, data, isLoading, fetchAttributes, search, setSearch] =
    useDataSourcesInfoTable({
      sourceId,
      tableName,
    });

  const [, getRefProps, getInputProps] = useSearch({ search, setSearch });

  return (
    <div
      className={classNames(
        'data-sources-info',
        activeItem && tableName && 'data-sources-info--active'
      )}
      {...getRefProps()}
    >
      <div className="data-sources-info__top">
        <span className="data-sources-info__title">{tableName}</span>
        <button
          className="data-sources-info__close"
          onClick={() => setActiveItem(null)}
        >
          <Icon name="icon-close" />
        </button>
      </div>
      <div className="data-sources-info__content">
        <div className="data-sources-info__actions">
          <InputSearch size="large" iconColor="5e6366" {...getInputProps()} />

          <Button kind="square-s-b" tooltip={t('actions.refresh')}>
            <Icon name="icon-refresh" onClick={fetchAttributes} />
          </Button>
        </div>

        <div className="data-sources-info__divider"></div>

        <SimpleTable
          className="data-sources-info__table"
          columnsData={columns}
          dataSource={data}
          loading={isLoading}
        />
      </div>
    </div>
  );
};
