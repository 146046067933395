import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { getComplexTypeMap, getComplexTypes } from '@modules/complexType';

export const addComplexTypeAttributes = (
  store: MiddlewareAPI<Dispatch<AnyAction>, any>,
  action: { meta: { arg: { parentTypeId: string } } }
) => {
  // @ts-ignore
  store.dispatch(getComplexTypeMap(action.meta.arg.parentTypeId));
};

export const refreshComplexTypes = (
  store: MiddlewareAPI<Dispatch<AnyAction>, any>
) => {
  // @ts-ignore
  store.dispatch(getComplexTypes());
};
