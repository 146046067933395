import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  InputSearch,
  IToolbarItem,
  Modal,
  RadioGroup,
  SimpleTable,
  Toolbar,
} from '@modules/shared';
import { ColumnsType } from 'antd/lib/table';
import './Lockout.scss';
import { DateFilters } from './parts';

export const Lockout = () => {
  const { t } = useTranslation();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [unlockMode, setUnlockMode] = useState<number>(1);

  const columns: ColumnsType<any> = [
    {
      title: t('lockout.name'),
      dataIndex: 'objectName',
      key: 'objectName',
      width: '25%',
      sorter: (a, b) => a.objectName.localeCompare(b.objectName),
    },
    {
      title: t('lockout.code'),
      dataIndex: 'objectCode',
      key: 'objectCode',
      width: '15%',
      sorter: (a, b) => a.objectCode.localeCompare(b.objectCode),
    },
    {
      title: t('lockout.type'),
      dataIndex: 'objectType',
      key: 'objectType',
      width: '15%',
      sorter: (a, b) => a.objectType.localeCompare(b.objectType),
    },
    {
      title: t('lockout.userName'),
      dataIndex: 'userName',
      key: 'userName',
      width: '20%',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: t('lockout.lockTime'),
      dataIndex: 'lockDt',
      key: 'lockDt',
      width: '25%',
      sorter: (a, b) => a.lockDt.localeCompare(b.lockDt),
    },
  ];

  const data = [
    {
      title: 'Проверка данных заявки',
      objectName: '1',
      objectCode: '123',
      objectType: 'diagram',
      userName: 'User_1',
      lockDt: '2020-10-09 09:10:05',
    },
    {
      title: 'Проверка данных заявки',
      objectName: '1',
      objectCode: '312',
      objectType: 'diagram',
      userName: 'User_1',
      lockDt: '2020-10-09 09:10:05',
    },
    {
      title: 'Проверка данных заявки',
      objectName: '1',
      objectCode: 'qwe',
      objectType: 'diagram',
      userName: 'User_1',
      lockDt: '2020-10-09 09:10:05',
    },
    {
      title: 'Проверка данных заявки',
      objectName: '1',
      objectCode: '223',
      objectType: 'diagram',
      userName: 'User_1',
      lockDt: '2020-10-09 09:10:05',
    },
    {
      title: 'Проверка данных заявки',
      objectName: '1',
      objectCode: '442',
      objectType: 'diagram',
      userName: 'User_1',
      lockDt: '2020-10-09 09:10:05',
    },
  ].map((item, index) => ({ ...item, key: index + 1 }));

  const toolbarData: IToolbarItem[] = [
    {
      icon: 'icon-lock',
      tooltip: t('actions.unlock'),
      onClick: () => setIsModalOpen(true),
    },
  ];

  const options = [
    {
      value: 1,
      label: t('lockout.modals.instantly'),
    },
    {
      value: 2,
      label: t('lockout.modals.withNotify'),
    },
  ];

  const modalBody = () => {
    return (
      <div className="unlock-body">
        <RadioGroup
          options={options}
          value={unlockMode}
          onChange={(e) => setUnlockMode(e.target.value)}
          isVerticalDirection
        />
      </div>
    );
  };

  const modalFooter = () => {
    return (
      <>
        <Button
          kind="normal"
          onClick={() => setIsModalOpen(false)}
          style={{ display: 'inline' }}
        >
          Ok
        </Button>
        <Button
          kind="default"
          onClick={() => setIsModalOpen(false)}
          style={{ display: 'inline', marginLeft: '16px' }}
        >
          {t('layout.cancel')}
        </Button>
      </>
    );
  };

  return (
    <>
      <div className="lockout">
        <div className="lockout__top">{t('lockout.title')}</div>
        <div className="lockout__row">
          <Toolbar
            data={toolbarData}
            isShowCheckboxAll
            CheckboxAllIndeterminate={
              selectedRowKeys.length !== data.length &&
              selectedRowKeys.length !== 0
            }
            CheckboxAllChecked={selectedRowKeys.length === data.length}
            onCheckboxAllChange={() => {
              setSelectedRowKeys(
                selectedRowKeys.length === data.length
                  ? []
                  : data.map((item) => item.key)
              );
            }}
            search={<InputSearch width={200} />}
          />
        </div>
        <div className="lockout__content">
          <div className="lockout__left">
            <SimpleTable
              columnsData={columns}
              dataSource={data}
              hideSelectAll
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          </div>
          <div className="lockout__right">
            <DateFilters />
          </div>
        </div>
      </div>

      <Modal
        isOn={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalProps={{
          title: t('lockout.modals.unlockModeTitle'),
          icon: 'icon-dots',
          footer: modalFooter(),
        }}
      >
        {modalBody()}
      </Modal>
    </>
  );
};
