import React, { FC } from 'react';
import { MainLayout } from './components/mainLayout';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { SidePanel } from './components/sidePanel';

export const Layout: FC = ({ children }) => {
  return (
    <MainLayout>
      {{
        header: <Header />,
        footer: <Footer />,
        sidePanel: <SidePanel />,
        mainContent: children,
      }}
    </MainLayout>
  );
};
