import React from 'react';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import './Button.scss';

// Todo переделать на enum
export type ButtonKind =
  | 'default'
  | 'normal'
  | 'border'
  | 'transparent'
  | 'active'
  | 'icon'
  | 'square'
  | 'square-s'
  | 'square-ss'
  | 'square-s-b';
export type ButtonModify =
  | 'default-border'
  | 'second'
  | 'danger'
  | 'min-width'
  | 'box-shadow'
  | 'transparent'
  | 'usual-text-style'
  | 'hover-box-shadow'
  | 'hover-default';

interface IButton {
  width?: number;
  disabled?: boolean;
  kind: ButtonKind;
  className?: string;
  style?: React.CSSProperties;
  modifiers?: ButtonModify[];
  tooltip?: string;
  onClick?: (() => void) | ((e: any) => void);
}

const Button: React.FC<IButton> = (props) => {
  const {
    width,
    disabled,
    kind,
    className: currentClassName,
    style: propsStyle,
    children,
    modifiers,
    tooltip,
    onClick,
    ...rest
  } = props;

  if (tooltip) return <ButtonWidthTooltip tooltip={tooltip} {...props} />;

  const css = propsStyle || {};
  const cssClass = kind ? kind : 'normal';
  if (width) css.width = `${width}px`;

  const className = classnames(
    currentClassName,
    `button_${cssClass}`,
    modifiers && modifiers.map((m) => `button--${m}`).join(' ')
  );

  return (
    <button
      className={className}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      style={css}
      {...rest}
    >
      {children}
    </button>
  );
};

const ButtonWidthTooltip: React.FC<IButton> = ({ tooltip, ...restProps }) => {
  return (
    <Tooltip
      placement="bottom"
      title={tooltip}
      children={<Button {...restProps} />}
    />
  );
};

// TODO нужно избавиться от этого ибо компонент кнопки был расширен
const NewButton: React.FC<IButton> = (props) => {
  const {
    width,
    onClick,
    disabled,
    kind,
    className: currentClassName,
    style: propsStyle,
    children,
    ...rest
  } = props;
  const css = propsStyle || {};
  const cssClass = kind ? kind : 'normal';

  if (width) css.width = `${width}px`;

  const className = classnames(`new-button_${cssClass}`, currentClassName);
  return (
    <button
      className={className}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      style={css}
      {...rest}
    >
      {children}
    </button>
  );
};

export { Button, NewButton };
