import { FC, useEffect, useState } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { useTranslationPath } from '@modules/languageProvider';
import { Input } from '../Input';
import './IntervalValue.scss';

export type RangeValue = {
  from?: string | number;
  to?: string | number;
  includeFrom: boolean;
  includeTo: boolean;
};

interface IIntervalValue {
  value: RangeValue;
  readOnly?: boolean;
  disabled?: boolean;
  onChange: (value: RangeValue) => void;
  onBlur?: (value: RangeValue) => void;
}

export const NewIntervalValue: FC<IIntervalValue> = ({
  value,
  readOnly,
  disabled,
  onChange,
  onBlur,
}) => {
  const translate = useTranslationPath(
    'development.diagram.diagram.common.intervalValue'
  );

  const [innerValue, setInnerValue] = useState<RangeValue>({
    includeFrom: false,
    includeTo: false,
  });

  const handleOnBlur = (value: RangeValue) => {
    onBlur && onBlur(value);
  };

  useEffect(() => {
    setInnerValue({
      from: value.from,
      to: value.to,
      includeFrom: value.includeFrom,
      includeTo: value.includeTo,
    });
  }, [value]);

  return (
    <div className="interval-value">
      <Tooltip placement="top" title={translate('including')}>
        <Checkbox
          checked={innerValue.includeFrom}
          disabled={disabled}
          onChange={(e) => {
            if (!readOnly) {
              handleOnBlur({ ...innerValue, includeFrom: e.target.checked });
            }
          }}
        />
      </Tooltip>

      <Tooltip title={translate('from')} placement="top">
        <Input
          className="interval-value__input-from"
          value={innerValue.from ?? ''}
          readOnly={readOnly}
          disabled={disabled}
          onChange={(e) => onChange({ ...innerValue, from: e.target.value })}
          onBlur={(e) => handleOnBlur({ ...innerValue, from: e.target.value })}
        />
      </Tooltip>

      <Tooltip title={translate('to')} placement="top">
        <Input
          className="interval-value__input-to"
          value={innerValue.to ?? ''}
          readOnly={readOnly}
          disabled={disabled}
          onChange={(e) => onChange({ ...innerValue, to: e.target.value })}
          onBlur={(e) => handleOnBlur({ ...innerValue, to: e.target.value })}
        />
      </Tooltip>

      <Tooltip placement="top" title={translate('including')}>
        <Checkbox
          checked={innerValue.includeTo}
          disabled={disabled}
          onChange={(e) => {
            if (!readOnly) {
              handleOnBlur({ ...innerValue, includeTo: e.target.checked });
            }
          }}
        />
      </Tooltip>
    </div>
  );
};
