import React, { forwardRef, Ref } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import classnames from 'classnames';
import { TextAreaRef } from 'antd/lib/input/TextArea';

interface ITextArea extends TextAreaProps {
  label?: string;
  ref?: Ref<TextAreaRef>;
  bottomContent?: React.ReactNode;
}

export const TextArea: React.FC<ITextArea> = forwardRef(
  (
    { required, className, size, label, bottomContent, ...restProps },
    ref?: Ref<TextAreaRef>
  ) => {
    return (
      <div
        className={classnames(
          className,
          'input-ext__container',
          'textarea-ext'
        )}
      >
        <div className="input-ext__top">
          {label && <span className="input-ext__label">{label}</span>}
          {required && <span className="input-ext__required">*</span>}
        </div>
        <Input.TextArea
          className="input-ext"
          size={size || 'large'}
          required={required}
          ref={ref}
          {...restProps}
        />
        {bottomContent}
      </div>
    );
  }
);
