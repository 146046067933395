/* eslint-disable @typescript-eslint/no-unused-vars */
import { Key } from 'react';
import { IDataTableList, ICustomCode } from '@modules/development';
import { IAction } from '@modules/store';
import { findCurrentObject } from '../../../../utils';
import { IDevelopmentState } from '../../../../DevelopmentSlice';
import { findCurrentCustomCode } from '../../utils';

const reducers = {
  customCodeSeeds(state: IDevelopmentState, action: IAction) {
    const { customCodes } = action.payload;
    state.customCode.data = customCodes;
  },

  customCodeUpdate(
    state: IDevelopmentState,
    action: IAction<{ customCodeKey: string; title: string }>
  ) {
    const { customCodeKey, title } = action.payload;
    const existingCustomCode = state.customCode.data.find(
      (code: IDataTableList) => code.key === customCodeKey
    );
    if (existingCustomCode) {
      existingCustomCode.title = title;
    }
  },

  addCustomCode(state: IDevelopmentState, action: IAction) {
    const { key } = action.payload;

    // TODO устанавливается на сервере?
    const date = new Date();
    const options: any = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timezone: 'UTC',
    };
    const newCustomCode = {
      key: key,
      title: 'New Custom Code',
      draft: true,
      isSaved: false,
      isReadonly: false,
      code: key,
      location: '/CustomCode/',
      state: 'В разработке',
      dateOfChange: `${date.toLocaleString(
        'ru',
        options
      )} ${date.toLocaleTimeString()}`, // '01.05.2021 13:41:56'
      user: 'User_1',
      createUser: 'User_1',
      version: '',
      description: '',
      type: 'customCode',
      variables: [],
      versionId: '',
      scriptId: '',
      scriptJson: {
        name: 'New Custom Code',
        scriptText: '',
        // inputVariables: [],
        // outputVariables: [],
      },
    };
    state.openObjects.push(newCustomCode);
    state.openTabs.push({
      key: key,
      version: key,
      text: 'New Custom Code',
      type: 'customCode',
      icon: 'icon-custom_code',
    });
  },

  removeCustomCode(state: IDevelopmentState, action: IAction<{ key: Key }>) {
    const { key } = action.payload;
    state.customCode.data = state.customCode.data.filter(
      (item: IDataTableList) => item.key !== key
    );
  },

  setCustomCodeKey(state: IDevelopmentState, action: IAction) {
    const { key } = action.payload;
    if (key.length !== 0) {
      state.currentObject.key = key;
    }
  },

  setCustomCodeReadonly(state: IDevelopmentState, action: IAction) {
    // TODO устанавливается подгружая параметр из url
    const { readonly } = action.payload;
    state.currentObject.readonly = readonly;
  },

  setCurrentCustomCodeTitle(state: IDevelopmentState, action: IAction) {
    const { title } = action.payload;
    state.currentObject.title = title;
  },

  setCustomCodeTitle(state: IDevelopmentState, action: IAction) {
    const { title } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.title = title;
      currentCustomCode.scriptJson.name = title;
      currentCustomCode.isSaved = false;
    }
  },

  setCustomCodeScriptText(state: IDevelopmentState, action: IAction) {
    const { scriptText } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.scriptJson.scriptText = scriptText;
      currentCustomCode.isSaved = false;
    }
  },

  addCustomCodeInputRow(state: IDevelopmentState, action: IAction) {
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.variables = [
        ...(currentCustomCode.variables || []),
        {
          variableName: '',
          variableType: 'IN',
          arrayFlag: false,
        },
      ];
      currentCustomCode.isSaved = false;
    }
  },

  deleteCustomCodeInputRows(state: IDevelopmentState, action: IAction) {
    const { keys } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.variables = [
        ...currentCustomCode.variables
          .filter((v) => v.variableType === 'IN')
          .filter((_, index) => !keys.includes(`input-${index}`)),
        ...currentCustomCode.variables.filter((v) => v.variableType === 'OUT'),
      ];
      currentCustomCode.isSaved = false;
    }
  },

  setCustomCodeInputName(state: IDevelopmentState, action: IAction) {
    const { key, name } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.variables = [
        ...currentCustomCode.variables
          .filter((v) => v.variableType === 'IN')
          .map((item, index) => {
            if (key !== `input-${index}`) return item;
            return { ...item, variableName: name };
          }),
        ...currentCustomCode.variables.filter((v) => v.variableType === 'OUT'),
      ];
      currentCustomCode.isSaved = false;
    }
  },

  setCustomCodeInputIsArray(state: IDevelopmentState, action: IAction) {
    const { key, isArray } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.variables = [
        ...currentCustomCode.variables
          .filter((v) => v.variableType === 'IN')
          .map((item, index) => {
            if (key !== `input-${index}`) return item;
            return { ...item, arrayFlag: isArray };
          }),
        ...currentCustomCode.variables.filter((v) => v.variableType === 'OUT'),
      ];
      currentCustomCode.isSaved = false;
    }
  },

  setCustomCodeInputType(state: IDevelopmentState, action: IAction) {
    const { key, typeId, isComplex } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      let isChanged = false;

      currentCustomCode.variables = [
        ...currentCustomCode.variables
          .filter((v) => v.variableType === 'IN')
          .map((item, index) => {
            if (key !== `input-${index}`) return item;

            if (
              typeId === item.complexTypeVersionId ||
              typeId === item.primitiveTypeId
            ) {
              return item;
            }
            isChanged = true;

            if (isComplex) {
              delete item.primitiveTypeId;
              return {
                ...item,
                complexTypeVersionId: typeId,
              };
            } else {
              delete item.complexTypeVersionId;
              return {
                ...item,
                primitiveTypeId: typeId,
              };
            }
          }),
        ...currentCustomCode.variables.filter((v) => v.variableType === 'OUT'),
      ];
      isChanged && (currentCustomCode.isSaved = false);
    }
  },

  addCustomCodeOutputRow(state: IDevelopmentState, action: IAction) {
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.variables = [
        ...(currentCustomCode.variables || []),
        {
          variableName: '',
          variableType: 'OUT',
          arrayFlag: false,
        },
      ];
      currentCustomCode.isSaved = false;
    }
  },

  deleteCustomCodeOutputRows(state: IDevelopmentState, action: IAction) {
    const { keys } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.variables = [
        ...currentCustomCode.variables
          .filter((v) => v.variableType === 'OUT')
          .filter((_, index) => !keys.includes(`output-${index}`)),
        ...currentCustomCode.variables.filter((v) => v.variableType === 'IN'),
      ];
      currentCustomCode.isSaved = false;
    }
  },

  setCustomCodeOutputName(state: IDevelopmentState, action: IAction) {
    const { key, name } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.variables = [
        ...currentCustomCode.variables
          .filter((v) => v.variableType === 'OUT')
          .map((item, index) => {
            if (key !== `output-${index}`) return item;
            return { ...item, variableName: name };
          }),
        ...currentCustomCode.variables.filter((v) => v.variableType === 'IN'),
      ];
      currentCustomCode.isSaved = false;
    }
  },
  setCustomCodeOutputIsArray(state: IDevelopmentState, action: IAction) {
    const { key, isArray } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      currentCustomCode.variables = [
        ...currentCustomCode.variables
          .filter((v) => v.variableType === 'OUT')
          .map((item, index) => {
            if (key !== `output-${index}`) return item;
            return { ...item, arrayFlag: isArray };
          }),
        ...currentCustomCode.variables.filter((v) => v.variableType === 'IN'),
      ];
      currentCustomCode.isSaved = false;
    }
  },

  setCustomCodeOutputType(state: IDevelopmentState, action: IAction) {
    const { key, typeId, isComplex } = action.payload;
    const currentCustomCode = findCurrentCustomCode(state);

    if (currentCustomCode) {
      let isChanged = false;

      currentCustomCode.variables = [
        ...currentCustomCode.variables
          .filter((v) => v.variableType === 'OUT')
          .map((item, index) => {
            if (key !== `output-${index}`) return item;

            if (
              typeId === item.complexTypeVersionId ||
              typeId === item.primitiveTypeId
            ) {
              return item;
            }
            isChanged = true;

            if (isComplex) {
              delete item.primitiveTypeId;
              return {
                ...item,
                complexTypeVersionId: typeId,
              };
            } else {
              delete item.complexTypeVersionId;
              return {
                ...item,
                primitiveTypeId: typeId,
              };
            }
          }),
        ...currentCustomCode.variables.filter((v) => v.variableType === 'IN'),
      ];
      isChanged && (currentCustomCode.isSaved = false);
    }
  },
};

export default reducers;
