import { filterByValue, InputSearch } from '@shared';
import { InputRef } from 'antd';
import { useCallback, useRef } from 'react';

type UseSearchProps = {
  search: string;
  setSearch: (value: string) => void;
};

export const useSearch = ({ search, setSearch }: UseSearchProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<InputRef>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.ctrlKey) {
      if (event.key === 'f' || event.key === 'а') {
        event.preventDefault();
        inputRef.current && inputRef.current.focus();
      }
    }
  };

  const getInputProps = () => ({
    ref: inputRef,
    value: search,
    onChange: (event: any) => setSearch(event.target.value),
  });

  const getRefProps = () => ({
    ref: divRef,
    tabIndex: 0,
    style: { outline: 'none', minHeight: '100%' },
    onKeyDown: handleKeyDown,
  });

  const filterSearch = <T extends { [key: string]: any }>(
    data: T[],
    field: keyof T
  ) => filterByValue<T>(data, field, search);

  const renderSearchInput = useCallback(() => {
    return <InputSearch {...getInputProps()} background="#fff" bordered />;
  }, [getInputProps]);

  return [renderSearchInput, getRefProps, getInputProps, filterSearch] as const;
};
