import React, { VFC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { selectRoles } from '@modules/services';
import { useAppSelector } from '@modules/store';
import { STREAMS_ROUTES } from './streamsRoutes';
export const NOT_FOUND_PATH = '/not-found';

export const StreamsRouting: VFC = () => {
  const roles = useAppSelector(selectRoles);

  return (
    <Switch>
      {STREAMS_ROUTES.map(({ role, ...route }) =>
        role ? (
          roles.includes(role) && (
            <Route key={route.path.toString()} {...route} />
          )
        ) : (
          <Route key={route.path.toString()} {...route} />
        )
      )}
      <Route path="*" render={() => <Redirect to={NOT_FOUND_PATH} />} />
    </Switch>
  );
};
