import { IDiagram } from '../../../../types';
import { findCurrentObject } from '../../../../utils';

const reducers = {
  changeTitle(state: any, action: any) {
    const { title } = action.payload;
    const currentObject = findCurrentObject<IDiagram>(
      state.openObjects,
      state.currentObject.key
    );

    if (currentObject) currentObject.diagramName = title || undefined;
  },
  changeCode(state: any, action: any) {
    const { code } = action.payload;
    const currentObject = findCurrentObject<IDiagram>(
      state.openObjects,
      state.currentObject.key
    );
    if (currentObject) currentObject.diagramId = code;
  },
  changeDescription(state: any, action: any) {
    const { description } = action.payload;
    const currentObject = findCurrentObject<IDiagram>(
      state.openObjects,
      state.currentObject.key
    );
    if (currentObject) currentObject.diagramDescription = description;
  },
};

export default reducers;
