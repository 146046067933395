import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addOpenTab,
  cancelAdd,
  onSelectedCustomDataTypesRows,
  selectOpenTabs,
  updateOpenTab,
} from '@modules/development/DevelopmentSlice';
import {
  selectAddNewRequested,
  selectCustomDataTypesSearch,
  selectCustomDataTypesSelectedRows,
} from './reducers';
import { ComplexTypes } from './components/ComplexTypes/ComplexTypes';
import { CUSTOMDATATYPES_OBJTYPE } from '@modules/development';

export const CustomDataTypes: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openedTabs = useSelector(selectOpenTabs);
  const addNewRequested = useSelector(selectAddNewRequested);
  const selectedRowKeys = useSelector(selectCustomDataTypesSelectedRows);
  const search = useSelector(selectCustomDataTypesSearch);

  useEffect(() => {
    const key = CUSTOMDATATYPES_OBJTYPE;
    const tab = {
      key,
      text: t('development.solutionLists.sidePanel.customDataTypes'),
      type: CUSTOMDATATYPES_OBJTYPE,
    };
    dispatch(
      openedTabs.every((ot) => ot.key !== key)
        ? addOpenTab({ tab })
        : updateOpenTab({ key, tab })
    );
  }, [t]);

  return (
    <>
      <ComplexTypes
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={(keys) => {
          dispatch(onSelectedCustomDataTypesRows({ keys }));
        }}
        addNewRequested={addNewRequested}
        onCancelAdd={() => dispatch(cancelAdd())}
        search={search}
      />
    </>
  );
};
