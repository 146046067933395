import React, { forwardRef, Ref, VFC } from 'react';
import { Input } from 'antd';
import { TextAreaProps, TextAreaRef } from 'antd/lib/input/TextArea';
import classNames from 'classnames';
import './textAreaWithButton.scss';
import { Button } from '../Button';
import { Icon } from '@modules/icon';

export interface ITextAreaWithButton extends TextAreaProps {
  label?: string;
  ref?: Ref<TextAreaRef>;
}

export const TextAreaWithButton: VFC<ITextAreaWithButton> = forwardRef(
  (
    { className, label, required, size, disabled, onClick, ...restProps },
    ref?: Ref<TextAreaRef>
  ) => {
    return (
      <div className={classNames(className, 'textarea-with-button-container')}>
        {label && (
          <div className="textarea-with-button-container__label">{label}</div>
        )}
        <Input.TextArea
          {...restProps}
          className="textarea-with-button"
          size={size || 'large'}
          required={required}
          disabled={disabled}
          ref={ref}
        />
        {onClick && (
          <Button
            className="textarea-with-button-container__button"
            kind="square-ss"
            modifiers={['transparent']}
            disabled={disabled}
            onClick={onClick}
            children={<Icon name="icon-edit" />}
          />
        )}
        {required && (
          <span className="textarea-with-button-container__required">*</span>
        )}
      </div>
    );
  }
);
