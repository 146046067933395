import React, { Key, useEffect, useState, VFC } from 'react';
import './ExternalServiceAccess.scss';
import {
  SimpleTable,
  SaveAsModal,
  Modal,
  ConfirmModal,
  useSearch,
} from '@shared';
import {
  deleteServicesByIds,
  fetchServices,
  selectServicesError,
  selectServices,
  servicesActions,
  selectServicesLoading,
  addServiceAndFetch,
  updateServiceAndFetch,
  selectRoles,
  ServiceAdd,
  ServiceUpdate,
  rolesList,
} from '@modules/services';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { ExternalServiceCallSettingsModal } from '../ExternalServiceCallSettingsModal';
import { useExtServAccessTable } from './parts/useExtServAccessTable';
import { OpenedModal } from './interfaces';
import { ExtServAccessToolbar } from './parts/ExtServAccessToolbar';
import { useTranslationPath } from '@modules/languageProvider';

const translatePath = 'administrationTab.externalServiceAccess';

export const ExternalServiceAccess: VFC = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslationPath(translatePath);

  const services = useAppSelector(selectServices);
  const error = useAppSelector(selectServicesError);
  const loading = useAppSelector(selectServicesLoading);
  const roles = useAppSelector(selectRoles);

  const [search, setSearch] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [openedModal, setOpenedModal] = useState<OpenedModal>(null);
  const [savedBody, setSavedBody] = useState<any>(null);

  const onModalClose = () => {
    setOpenedModal(null);
  };

  const onOpen = (versionId: Key, serviceId: string) => {
    setOpenedModal({ type: 'service-settings', versionId, serviceId });
  };

  const onDelete = (servicesIds: Key[]) => {
    if (servicesIds.length) {
      setOpenedModal({
        type: 'confirm',
        contentText: translate('confirmDeleteServiceText'),
        kind: 'info',
        onOk: () => {
          dispatch(deleteServicesByIds(servicesIds.map((id) => String(id))));
          onModalClose();
        },
      });
    }
    setSelectedRowKeys([]);
  };

  const onRefresh = () => {
    dispatch(fetchServices());
    setSelectedRowKeys([]);
  };

  const onServiceSave = (body: ServiceAdd | ServiceUpdate, versionId?: Key) => {
    setSavedBody({ body, versionId });
    if (versionId) {
      dispatch(updateServiceAndFetch(String(versionId), body as ServiceUpdate));
    } else {
      dispatch(addServiceAndFetch(body));
    }
  };

  const { columns, data } = useExtServAccessTable(
    services,
    search,
    translate,
    onOpen,
    onDelete
  );

  const [, getRefProps, getInputProps] = useSearch({ search, setSearch });

  useEffect(() => {
    return setSavedBody(null);
  }, []);

  return (
    <>
      <div className="external-service-access">
        <div className="external-service-access__top">{translate('title')}</div>
        <div className="external-service-access__row">
          <ExtServAccessToolbar
            data={data}
            keys={selectedRowKeys}
            setKeys={setSelectedRowKeys}
            translate={translate}
            getInputProps={getInputProps}
            onAdd={() => setOpenedModal({ type: 'service-settings' })}
            onDelete={() => onDelete(selectedRowKeys)}
            onRefresh={() => onRefresh()}
          />
        </div>
        <div className="external-service-access__content" {...getRefProps()}>
          <SimpleTable
            columnsData={columns}
            dataSource={data}
            loading={loading}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            onDoubleClick={({ key, serviceId }) =>
              roles.includes(rolesList.EXT_SERVICE_READ) &&
              onOpen(key, serviceId)
            }
            hideSelectAll
          />
        </div>
      </div>

      {openedModal?.type === 'service-settings' && (
        <ExternalServiceCallSettingsModal
          modalProps={openedModal}
          onSave={onServiceSave}
          onSaveAs={(body) => {
            setOpenedModal({ type: 'save-as', body });
          }}
          onClose={onModalClose}
          onSaveClose
          data={data}
          body={savedBody?.body}
          setSavedBody={setSavedBody}
        />
      )}

      {openedModal?.type === 'save-as' && (
        <Modal
          isOn={Boolean(openedModal)}
          modalProps={{
            title: translate('saveAsTitle'),
            icon: 'icon-expression_editor',
            width: '510px',
          }}
          onClose={onModalClose}
          page="administration"
        >
          <SaveAsModal
            onSave={(serviceName) => {
              onServiceSave({ ...openedModal.body, serviceName });
              onModalClose();
            }}
            onClose={onModalClose}
          />
        </Modal>
      )}

      {openedModal?.type === 'confirm' && (
        <ConfirmModal
          modalProps={openedModal}
          onOk={openedModal?.onOk}
          onClose={onModalClose}
        />
      )}

      {error && (
        <ConfirmModal
          modalProps={{
            type: 'error',
            title: translate('errorTitle'),
            contentText: error,
            kind: 'error',
            okButtonText: translate('cancelButtonText'),
          }}
          onOk={() => {
            setOpenedModal({
              type: 'service-settings',
              versionId: savedBody?.versionId,
            });
          }}
          onClose={() => dispatch(servicesActions.setError(undefined))}
        />
      )}
    </>
  );
};
