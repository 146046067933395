import React, { useCallback, VFC } from 'react';
import { Errors, Service } from '../interfaces';
import { mainClass } from '../data';
import { ExtServiceGeneralItem } from './ExtServiceGeneralItem';
import { rolesList, selectRoles } from '@modules/services';
import { useAppSelector } from '@modules/store';

type ExtServiceGeneralSettingsProps = {
  service: Service;
  translate: (attrName: any, options?: any) => any;
  onServiceChange: (key: keyof Service, value: string) => void;
  errors?: Errors;
};

export const ExtServiceGeneralSettings: VFC<ExtServiceGeneralSettingsProps> = ({
  service,
  translate,
  onServiceChange,
  errors,
}) => {
  const arrayIntoSelectOptions = useCallback(
    (array: string[]) =>
      array.map((item) => ({
        label: translate(item),
        value: item,
      })),
    [translate]
  );
  const roles = useAppSelector(selectRoles);

  return (
    <div className={`${mainClass}-general-settings`}>
      <ExtServiceGeneralItem
        label="host"
        size="large"
        value={service.host}
        error={errors?.host}
        onChange={onServiceChange}
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="fileFormat"
        value={service.fileFormat}
        error={errors?.fileFormat}
        options={arrayIntoSelectOptions(['JSON', 'XML'])}
        onChange={onServiceChange}
        disabled={
          service.protocol === 'SOAP' ||
          !roles.includes(rolesList.EXT_SERVICE_UPDATE)
        }
      />

      <ExtServiceGeneralItem
        label="serviceType"
        value={service.serviceType}
        error={errors?.serviceType}
        options={arrayIntoSelectOptions(['HTTP', 'HTTPS'])}
        onChange={onServiceChange}
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="batchFlag"
        value={service.batchFlag}
        options={arrayIntoSelectOptions(['BATCH', 'SINGLE'])}
        onChange={onServiceChange}
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="endpoint"
        size="large"
        value={service.endpoint}
        error={errors?.endpoint}
        onChange={onServiceChange}
        addonBefore="/"
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="port"
        size="small"
        value={service.port}
        error={errors?.port}
        onChange={onServiceChange}
        number
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="syncType"
        value={service.syncType}
        error={errors?.syncType}
        options={arrayIntoSelectOptions(['SYNC', 'ASYNC'])}
        onChange={onServiceChange}
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="timeout"
        size="small"
        value={service.timeout}
        error={errors?.timeout}
        onChange={onServiceChange}
        number
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="secondAttemptsCnt"
        size="small"
        value={service.secondAttemptsCnt}
        error={errors?.secondAttemptsCnt}
        onChange={onServiceChange}
        number
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="protocol"
        value={service.protocol}
        error={errors?.protocol}
        options={arrayIntoSelectOptions(['REST', 'SOAP'])}
        onChange={onServiceChange}
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="transactionsPerSecond"
        value={service.transactionsPerSecond}
        error={errors?.transactionsPerSecond}
        onChange={onServiceChange}
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
        number
      />

      <ExtServiceGeneralItem
        label="method"
        size="large"
        value={service.method}
        error={errors?.method}
        options={arrayIntoSelectOptions(['GET', 'POST', 'PUT'])}
        onChange={onServiceChange}
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />

      <ExtServiceGeneralItem
        label="interval"
        size="small"
        value={service.interval}
        error={errors?.interval}
        onChange={onServiceChange}
        number
        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
      />
    </div>
  );
};
