import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { mockSourceData } from '@modules/connections/mockData';
import { IConnectionSourceTableData } from '@modules/connections/types/connectionsTypes';

export const useConnectionsDataSources = ({
  sourceId,
  tableName,
}: {
  sourceId: string;
  tableName: string;
}) => {
  const { t } = useTranslation();

  const [attributes, setAttributes] = useState<IConnectionSourceTableData[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const fetchAttributes = () => {
    setIsLoading(true);
    return new Promise((resolve) =>
      setTimeout(() => {
        console.log('fetch');
        resolve(mockSourceData);
      }, 1000)
    ).finally(() => setIsLoading(false));
  };

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        dataIndex: 'attributeName',
        key: 'attributeName',
        title: t('administrationTab.dataSources.attributeName'),
        width: '70%',
        sorter: (a, b) => a.attributeName.localeCompare(b.attributeName),
      },
      {
        dataIndex: 'type',
        key: 'type',
        title: t('administrationTab.dataSources.type'),
        width: '30%',
        sorter: (a, b) => a.type.localeCompare(b.type),
      },
    ];
  }, [t]);

  const data = useMemo(() => {
    return attributes
      .map((item) => ({
        attributeName: item.columnName,
        type: item.dataType,
      }))
      .filter(
        (item) =>
          !search ||
          item.attributeName.toLowerCase().includes(search.toLowerCase())
      );
  }, [t, attributes, search]);

  useEffect(() => {
    sourceId &&
      tableName &&
      fetchAttributes().then((data) => setAttributes(data as any));

    return () => {
      setAttributes([]);
    };
  }, [sourceId, tableName]);

  return {
    columns,
    data,
    isLoading,
    fetchAttributes,
    search,
    setSearch,
  } as const;
};
