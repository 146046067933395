import { FC } from 'react';
import { Button } from '@shared';
import { Popover } from 'antd';
import './ValidationInfo.scss';

interface IValidationInfo {
  errorMessages?: string[];
  show?: boolean;
}

export const ValidationInfo: FC<IValidationInfo> = ({
  show,
  errorMessages,
}) => {
  if (!show) return null;
  return (
    <Popover
      content={
        <>
          {errorMessages?.map((msg) => (
            <p className="invalid-data__msg" key={msg}>
              * {msg}
            </p>
          ))}
        </>
      }
    >
      <Button kind="normal" className="invalid-data__btn" width={200}>
        Невалидные данные
      </Button>
    </Popover>
  );
};
