import { DiagramNodeTypes } from '../../../../types';

type NodeListItem = {
  title: string;
  translation: string;
  icon: string;
  type: string;
  nodeType: DiagramNodeTypes;
};

// @ts-ignore
const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

// TODO список требуется в нескольких разных модулях,
// возможно стоит вынести куда-то
const nodeList: NodeListItem[] = [
  // ICustomNode[]
  // {
  //   title: 'Начало',
  //   translation: 'development.diagram.diagram.elements.start',
  //   icon: 'icon-start',
  //   type: 'start',
  //   nodeType: DiagramNodeTypes.Start,
  // },
  // {
  //   title: 'Завершение',
  //   translation: 'development.diagram.diagram.elements.finish',
  //   icon: 'icon-finish',
  //   type: 'finish',
  //   nodeType: DiagramNodeTypes.Finish,
  // },
  // {
  //   title: 'Чтение данных',
  //   translation: 'development.diagram.diagram.elements.readingData',
  //   icon: 'icon-reading_data',
  //   type: 'reading_data',
  //   nodeType: DiagramNodeTypes.Read,
  // },
  // {
  //   title: 'Сохранение данных',
  //   translation: 'development.diagram.diagram.elements.saveData',
  //   icon: 'icon-save_data',
  //   type: 'save_data',
  //   nodeType: DiagramNodeTypes.Write,
  // },
  // {
  //   title: 'Расчет переменных',
  //   translation: 'development.diagram.diagram.elements.calculationVariables',
  //   icon: 'icon-calculation_variables',
  //   type: 'calculation_variables',
  //   nodeType: DiagramNodeTypes.Calculate,
  // },
  // {
  //   title: 'Вызов внешнего сервиса',
  //   translation: 'development.diagram.diagram.elements.callingExternalService',
  //   icon: 'icon-calling_external_service',
  //   type: 'calling_external_service',
  //   nodeType: DiagramNodeTypes.ExtService,
  // },
  // {
  //   title: 'Кастомный код',
  //   translation: 'development.diagram.diagram.elements.customCode',
  //   icon: 'icon-custom_code',
  //   type: 'custom_code',
  //   nodeType: DiagramNodeTypes.CustomCode,
  // },
  // {
  //   title: 'Расчет скоркарты',
  //   translation: 'development.diagram.diagram.elements.calculationScorecard',
  //   icon: 'icon-calculation_scorecard',
  //   type: 'calculation_scorecard',
  //   nodeType: DiagramNodeTypes.Scorecard,
  // },
  // {
  //   title: 'Поддиаграмма',
  //   translation: 'development.diagram.diagram.elements.subdiagram',
  //   icon: 'icon-subdiagram',
  //   type: 'subdiagram',
  //   nodeType: DiagramNodeTypes.Subdiagram,
  // },
  // {
  //   title: 'Ветвление',
  //   translation: 'development.diagram.diagram.elements.branching',
  //   icon: 'icon-branching',
  //   type: 'branching',
  //   nodeType: DiagramNodeTypes.Branch,
  // },
  // {
  //   title: 'Таблица принятия решения',
  //   translation: 'development.diagram.diagram.elements.dicisionMakingTable',
  //   icon: 'icon-decision_making_table',
  //   type: 'decision_making_table',
  //   nodeType: DiagramNodeTypes.DecisionTable,
  // },
  // {
  //   title: 'Набор правил',
  //   translation: 'development.diagram.diagram.elements.rulset',
  //   icon: 'icon-ruleset',
  //   type: 'ruleset',
  //   nodeType: DiagramNodeTypes.Ruleset,
  // },
  // {
  //   title: 'Чтение агрегата',
  //   translation: 'development.diagram.diagram.elements.readingAggregate',
  //   icon: 'icon-aggregate_read',
  //   type: 'reading-aggregate',
  //   nodeType: DiagramNodeTypes.AggregateReading,
  // },
  // {
  //   title: 'Расчет агрегата',
  //   translation: 'development.diagram.diagram.elements.calculationAggregate',
  //   icon: 'icon-aggregate_calc',
  //   type: 'calculation-aggregate',
  //   nodeType: DiagramNodeTypes.AggregateCompute,
  // },
  // {
  //   title: 'Коммуникация',
  //   translation: 'development.diagram.diagram.elements.communication',
  //   icon: 'icon-communication',
  //   type: 'communication',
  //   nodeType: DiagramNodeTypes.Communication,
  // },
];

if (HIDE_FEATURES) delete nodeList[14];

export { nodeList };
