import React, { useState, VFC } from 'react';
import './communicationFieldSettingsModal.scss';
import {
  IModalProps,
  Input,
  InputWithButton,
  Modal,
  ModalBox,
  ModalFooter,
  Select,
  SimpleTable,
  useModalVisible,
} from '@shared';
import { Checkbox } from 'antd';

interface ICommunicationFieldSettingsModal {
  modalProps: IModalProps;
  onClose: () => void;
}

export const CommunicationFieldSettingsModal: VFC<ICommunicationFieldSettingsModal> =
  ({ modalProps, onClose }) => {
    const isVisible = useModalVisible(modalProps.type);

    const [selectedSource, setSelectedSource] = useState('user');

    return (
      <Modal
        isOn={isVisible}
        modalProps={{
          type: modalProps.type,
          title: 'Дополнительные настройки полей',
          icon: 'icon-dots',
          width: '476px',
          footer: <ModalFooter onSave={() => {}} onClose={onClose} />,
        }}
        onClose={onClose}
      >
        <div className="communication-field-settings-modal">
          <div className="communication-field-settings-modal__source">
            <Select
              label="Источник данных"
              options={[
                { value: 'user', label: 'Пользователь' },
                { value: 'list', label: 'Динамический список' },
                { value: 'flag', label: 'Флаг' },
              ]}
              value={selectedSource}
              onChange={setSelectedSource}
            />
          </div>

          {selectedSource === 'user' && (
            <ModalBox
              className="communication-field-settings-modal__user"
              title="Ограничение"
              showHeader
            >
              <SimpleTable
                columnsData={[
                  {
                    title: 'Минимальное значение',
                    dataIndex: 'min',
                    width: '50%',
                    render: (value: any) => (
                      <Input
                        placeholder="<Введите значение>"
                        size="small"
                        defaultValue={value}
                        bordered={false}
                      />
                    ),
                  },
                  {
                    title: 'Максимальное значение',
                    dataIndex: 'max',
                    width: '50%',
                    render: (value: any) => (
                      <Input
                        placeholder="<Введите значение>"
                        size="small"
                        defaultValue={value}
                        bordered={false}
                      />
                    ),
                  },
                ]}
                dataSource={[{ min: '', max: '' }]}
              />
            </ModalBox>
          )}

          {selectedSource === 'list' && (
            <div className="communication-field-settings-modal__list">
              <InputWithButton label="Схема" />
              <InputWithButton label="Таблица" />
              <Select label="Поле" />
            </div>
          )}

          {selectedSource === 'flag' && (
            <div className="communication-field-settings-modal__flag">
              <Checkbox.Group
                options={[
                  { label: 'Да', value: 'true' },
                  { label: 'Нет', value: 'false' },
                ]}
              />
            </div>
          )}
        </div>
      </Modal>
    );
  };
