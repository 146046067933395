import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@modules/store';
import { servicesApi } from './api/servicesApi';
import { IServiceShort, ServiceAdd, ServiceUpdate } from './types/interface';
import { tryCatchDecorator } from '@modules/development/utils';

const SERVICES_FEATURE_KEY = 'services';

export interface IsServicesState {
  services: IServiceShort[];
  error?: string;
  loading: boolean;
  roles: string[];
  userName: string;
}

const initialState: IsServicesState = {
  services: [],
  error: undefined,
  loading: false,
  roles: [],
  userName: '',
};

export const updateServiceById = createAsyncThunk(
  `${SERVICES_FEATURE_KEY}/updateServiceById`,
  async ({ serviceId, body }: { serviceId: string; body: ServiceUpdate }) => {
    const response = await servicesApi.updateServiceById(serviceId, body);
    return response;
  }
);

export const updateServiceAndFetch =
  (serviceId: string, body: ServiceUpdate) => async (dispatch: any) => {
    dispatch(servicesActions.setLoading(true));
    await dispatch(updateServiceById({ serviceId, body }));
    dispatch(fetchServices());
  };

export const deleteServicesByIds = createAsyncThunk(
  `${SERVICES_FEATURE_KEY}/deleteServicesByIds`,
  async (servicesIds: string[]) => {
    await Promise.all(
      servicesIds.map((serviceId) => servicesApi.deleteServiceById(serviceId))
    );
    return servicesIds;
  }
);

export const getServices = createAsyncThunk(
  `${SERVICES_FEATURE_KEY}/getServices`,
  async (_, { dispatch }) => {
    // @ts-ignore
    return await tryCatchDecorator(dispatch, async () => {
      const response = await servicesApi.getServices();
      return response;
    });
  }
);

export const fetchServices = () => async (dispatch: any) => {
  dispatch(servicesActions.setLoading(true));
  await dispatch(getServices());
  dispatch(servicesActions.setLoading(false));
};

export const addService = createAsyncThunk(
  `${SERVICES_FEATURE_KEY}/addService`,
  async (body: ServiceAdd) => {
    const response = await servicesApi.addService(body);
    return response;
  }
);

export const addServiceAndFetch =
  (body: ServiceAdd) => async (dispatch: any) => {
    dispatch(servicesActions.setLoading(true));
    await dispatch(addService(body));
    dispatch(fetchServices());
  };

export const servicesSlice = createSlice({
  name: SERVICES_FEATURE_KEY,
  initialState,
  reducers: {
    setError(state, { payload }) {
      state.error = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setRoles(state, { payload }) {
      state.roles = payload;
    },
    setUserName(state, { payload }) {
      state.userName = payload;
    },
  },
  extraReducers: (builder) => {
    // updateServiceById
    builder.addCase(updateServiceById.rejected, (state, action) => {
      state.error = action.error.message;
    });
    // deleteServicesByIds
    builder.addCase(deleteServicesByIds.fulfilled, (state, { payload }) => {
      state.services = state.services.filter(
        ({ versionId }) => !payload.includes(versionId)
      );
    });
    builder.addCase(deleteServicesByIds.rejected, (state, action) => {
      state.error = action.error.message;
    });
    // getServices
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.services = action.payload;
    });
    builder.addCase(getServices.rejected, (state, action) => {
      state.error = action.error.message;
    });
    // addService
    builder.addCase(addService.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export const servicesActions = servicesSlice.actions;

export const selectServices = (state: RootState) => state.services.services;
export const selectServicesError = (state: RootState) => state.services.error;
export const selectServicesLoading = (state: RootState) =>
  state.services.loading;
export const selectRoles = (state: RootState) => state.services.roles;
export const selectUserName = (state: RootState) => state.services.userName;

export default servicesSlice.reducer;
