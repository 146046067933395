import React, { useEffect, useMemo, createRef, useState } from 'react';
import './ConnectionsMoreWrap.scss';
import { Icon } from '@modules/icon';
import { Tooltip } from '@shared';

interface IConnectionsMoreTooltip {
  text: string;
  initialHeight: number;
}

const moreIconSize = 27;

export const ConnectionsMoreWrap: React.FC<IConnectionsMoreTooltip> = ({
  text,
  initialHeight,
}) => {
  const refText = createRef<HTMLSpanElement>();
  const [elementHeight, setElementHeight] = useState(0);
  const [elementWidth, setElementWidth] = useState(0);
  const isLineBreak = elementHeight > initialHeight;

  const cutText = (textToCut: string): string => {
    const separateText = textToCut.split(', ');
    separateText.splice(separateText.length - 1, 1);
    const result = separateText.join(', ');
    const span = document.createElement('span');

    span.append(document.createTextNode(result));
    span.style.display = 'inline-block';
    document.body.append(span);
    const tempElementWidth = span.offsetWidth;
    span.remove();

    if (tempElementWidth > elementWidth - moreIconSize) {
      return cutText(result);
    }

    return result;
  };

  const preparedText = useMemo(() => {
    if (isLineBreak) {
      return cutText(text);
    }

    return text;
  }, [isLineBreak, text]);

  useEffect(() => {
    if (refText.current && refText.current.offsetHeight > initialHeight) {
      setElementHeight(refText.current.offsetHeight);
      setElementWidth(refText.current.offsetWidth);
    }
  }, [refText]);

  return (
    <span ref={refText}>
      {preparedText}
      {isLineBreak && (
        <Tooltip
          title={text}
          placement="bottomRight"
          overlayStyle={{ maxWidth: `${elementWidth}px` }}
          trigger="click"
          white
        >
          <Icon
            name="icon-more-volume"
            size={moreIconSize}
            className="connection-cell__more"
          />
        </Tooltip>
      )}
    </span>
  );
};
