import { ICustomCodeProperties } from '../components/DiagramBlocks/CustomCodeModal/interfaces';
import { IStartModalProperties } from '../components/DiagramBlocks/StartModal/interfaces';
import { IFinishProperties } from '../components/DiagramBlocks/finishModal/interfaces';
import { IReadProperties } from '../components/DiagramBlocks/readModal/interfaces';
import { ICalculateProperties } from '../components/DiagramBlocks/CalcVariablesModal/interfaces';
import { IRulesetProperties } from '../components/DiagramBlocks/RulesetModal/interfaces';
import {
  BranchTypes,
  IBranchProperties,
} from '../components/DiagramBlocks/BranchModal/interfaces';
import {
  ISaveDataProperies,
  QueryTypes,
} from '../components/DiagramBlocks/SaveDataModal/interfaces';
import { ISubdiagramProperties } from '../components/DiagramBlocks/SubDiagramModal/interfaces';
import { IExtServiceProperties } from '../components/DiagramBlocks/ExtServiceModal/interfaces';
import { IScorecardProperties } from '../components/DiagramBlocks/CalcScorecardModal/interfaces';
import { IAggregateReadingProperties } from '../components/DiagramBlocks/aggregateReadingModal/interfaces';
import {
  IAggregateComputeProperties,
  RetentionTimeUnits,
  RetentionTypes,
} from '../components/DiagramBlocks/aggregateComputeModal/interfaces';
import { ICommunicationProperties } from '../components/DiagramBlocks/communicationModal/types/interface';

export const CUSTOM_CODE_PROPERTIES_TEMPLATE: ICustomCodeProperties = {
  customCodeId: '',
  versionId: '',
  inputVariablesMapping: [],
  outputVariablesMapping: [],
};

export const START_PROPERTIES_TEMPLATE: IStartModalProperties = {
  mappingVariables: [],
};

export const FINISH_PROPERTIES_TEMPLATE: IFinishProperties = {
  mappingVariables: [],
};

export const READ_PROPERTIES_TEMPLATE: IReadProperties = {
  dataProviderUuid: '',
  query: '',
  allowMultiResultResponse: false,
  outputVariablesMapping: [],
  selectedTableNames: [],
};

export const SAVE_DATA_PROPERTIES_TEMPLATE: ISaveDataProperies = {
  dataProviderUuid: '',
  tableName: '',
  queryType: QueryTypes.insert,
  inputVariablesUpdateMapping: [],
  inputVariablesConditionMapping: [],
};

export const CALCULATE_PROPERTIES_TEMPLATE: ICalculateProperties = {
  calculate: [],
};

export const RULESET_PROPERTIES_TEMPLATE: IRulesetProperties = {
  ruleVariable: {
    variableName: '',
    isComplex: true,
    typeId: '4d16435a-7bdb-4b0b-96cb-3d40ccac3260',
    isArray: true,
  },
  rules: [
    {
      applyRule: true,
      ruleName: '',
      ruleCode: '',
      ruleTypeId: '',
      ruleDescription: '',
      ruleExpression: '',
    },
  ],
};

export const EXT_SERVICE_PROPERTIES_TEMPLATE: IExtServiceProperties = {
  serviceId: '',
  versionId: '',
  nodeVariablesMapping: [],
  outputVariablesMapping: [],
};

export const SCORECARD_PROPERTIES_TEMPLATE: IScorecardProperties = {
  outputVariable: {
    variableName: '',
    isComplex: false,
    typeId: '',
    isArray: false,
    defaultValue: '',
  },
  inputVariablesMapping: [],
};

export const BRANCH_PROPERTIES_TEMPLATE: IBranchProperties = {
  branchingType: BranchTypes.BranchByElement,
  defaultPath: '',
  condition: '',
  branches: [],
};

export const AGGREGATE_COMPUTE_PROPERTIES_TEMPLATE: IAggregateComputeProperties =
  {
    groupingElements: [],
    retentionType: RetentionTypes.system,
    retentionTimeValue: 0,
    retentionTimeUnit: RetentionTimeUnits.seconds,
    outputVariablesMapping: [],
  };

export const AGGREGATE_READING_PROPERTIES_TEMPLATE: IAggregateReadingProperties =
  {
    groupingElements: [{ diagramElement: '', aggregateElement: '' }],
    outputVariablesMapping: [],
  };

export const SUBDIAGRAM_PROPERTIES_TEMPLATE: ISubdiagramProperties = {
  subdiagramId: '',
  versionId: '',
  inputVariablesMapping: [],
  outputVariablesMapping: [],
};

export const COMMUNICATION_PROPERTIES_TEMPLATE: ICommunicationProperties = {
  communicationFields: [],
  nodeVariablesMapping: [
    {
      nodeVariable: 'EMAIL',
      variableName: '',
      isArray: false,
      isComplex: false,
      typeId: '',
    },
  ],
  outputVariablesMapping: [
    {
      nodeVariable: 'CODE',
      variableName: '',
      isArray: false,
      isComplex: false,
      typeId: '',
    },
    {
      nodeVariable: 'SMS',
      variableName: '',
      isArray: false,
      isComplex: false,
      typeId: '',
    },
  ],
};
