import React, { useState, Key } from 'react';
import { Modal, Button, IConfirmModalProps } from '@shared';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { StreamsDataTable } from '@modules/development/modules/streams/types/streamsTypes';
import { tryCatchDecorator } from '@modules/development/utils';
import { useAppDispatch } from '@modules/store';
import {
  IRequestExportSettings,
  exportApi,
  IModalProps,
} from '@modules/development';
import { EXPORT_STREAMS_MODAL } from '@modules/development/modules/streams/components/streamsList/StreamsList';

interface ParamsExportModal {
  onClose: () => void;
  selectedRows: Key[];
  data: StreamsDataTable[];
  setModalProps: React.Dispatch<
    React.SetStateAction<IModalProps | IConfirmModalProps | null>
  >;
}

interface IExportSettings {
  isIncludeAllVersions: boolean;
  isIncludeDependencies: boolean;
}

export const StreamsListParamsExportModal: React.FC<ParamsExportModal> = ({
  onClose,
  selectedRows,
  data,
  setModalProps,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [exportSettings, setExportSettings] = useState<IExportSettings>({
    isIncludeAllVersions: false,
    isIncludeDependencies: false,
  });

  const onSetExportSettings = (checked: boolean, type: string) => {
    setExportSettings({ ...exportSettings, [type]: checked });
  };

  const sendExportSettings = () => {
    const objectIds = selectedRows.map(
      (id) => data.find((d) => d.version === id)?.code
    );

    const settings: IRequestExportSettings = {
      objectsType: 'DIAGRAM',
      objectIds,
      isIncludeAllVersions: exportSettings.isIncludeAllVersions,
      isIncludeDependencies: true,
    };

    tryCatchDecorator(dispatch, async () => {
      return await exportApi.generateExportObjects(settings);
    }).then((res) => {
      setModalProps({ type: EXPORT_STREAMS_MODAL, data: res });
      onClose();
    });
  };

  return (
    <Modal
      isOn={true}
      onClose={onClose}
      modalProps={{
        title: t('modals.exportSettings.title'),
        icon: 'icon-dots',
        footer: (
          <>
            <Button
              kind="normal"
              onClick={sendExportSettings}
              style={{ display: 'inline' }}
            >
              {t('modals.exportSettings.buttonNext')}
            </Button>
            <Button
              kind="default"
              onClick={onClose}
              style={{ display: 'inline', marginLeft: '16px' }}
            >
              {t('actions.cancel')}
            </Button>
          </>
        ),
      }}
    >
      <div className="modal__child">
        <Checkbox
          onChange={(e) =>
            onSetExportSettings(e.target.checked, 'isIncludeAllVersions')
          }
        >
          {t('modals.exportSettings.allVersions')}
        </Checkbox>
        <Checkbox
          onChange={(e) =>
            onSetExportSettings(e.target.checked, 'isIncludeDependencies')
          }
          style={{ marginLeft: '0px', marginTop: '16px' }}
        >
          {t('modals.exportSettings.relatedObjects')}
        </Checkbox>
      </div>
    </Modal>
  );
};
