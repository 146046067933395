import { createSelector } from '@reduxjs/toolkit';
import { RootState, useAppSelector } from '@modules/store';

export const useAdministrationSelector = () => {
  const dataProvider = useAppSelector(
    createSelector(
      (state: RootState) => state.administration.dataProvider,
      (dataProvider) => dataProvider
    )
  );

  const administrationOpenModals = useAppSelector(
    createSelector(
      (state: RootState) => state.administration.modal.openModals,
      (openModals) => openModals
    )
  );

  return { dataProvider, administrationOpenModals };
};
