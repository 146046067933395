import React, {
  FC,
  Key,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox } from 'antd';
import { IModalProps } from '@modules/development';
import {
  Input,
  Modal,
  ModalBox,
  SimpleTable,
  useModalVisible,
  TabPanel,
  DataTypesSelect,
  ModalFooter,
} from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import { getDataTypes, selectDataTypes } from '@modules/references';
import {
  complexTypeActions,
  addComplexType,
  getComplexTypeAttributes,
  manageChangedComplexType,
  selectComplexTypesWithAttributes,
  IComplexTypeAttribute,
} from '@modules/complexType';
import './ComplexTypes.scss';
import { useAppSelector } from '@modules/store';
import { selectRoles, rolesList } from '@modules/services';
import CustomTypeVersions from './CustomTypeVersions';
import { tabs } from './data';

interface IEditCustomDataTypeModal {
  modalProps: IModalProps;
  complexTypeId?: {
    typeId: string;
    versionId: string;
  } | null;
  onClose: () => void;
  onChangesSaved?: () => void;
  closeOnSave?: boolean;
}

interface IAttributeRow extends Partial<IComplexTypeAttribute> {
  key: Key;
}

const regExp = new RegExp('^[a-zA-Z][a-zA-Z0-9_\\.]*$');

export const EditComplexTypeModal: FC<IEditCustomDataTypeModal> = ({
  modalProps,
  complexTypeId,
  onClose,
  onChangesSaved,
  closeOnSave,
}) => {
  const dispatch = useDispatch();
  const isEditModalVisible = useModalVisible(
    modalProps?.type || 'editComplexType'
  );
  const translate = useTranslationPath('development.complexTypes');
  const translateActions = useTranslationPath('actions');
  const refDataTypes = useSelector(selectDataTypes);
  const complexTypesWithAttributes = useSelector(
    selectComplexTypesWithAttributes
  );
  const [selectedAttrRowKeys, setSelectedAttrRowKeys] = useState<Key[]>([]);
  const [attributes, setAttributes] = useState<IAttributeRow[]>();
  const [typeName, setTypeName] = useState<string>('');
  const roles = useAppSelector(selectRoles);
  const [activeThirdPanel, setActiveThirdPanel] = useState('0');
  const [errors, setErrors] = useState<Key[]>([]);
  const currComplexTypeWithAttributes = useMemo(() => {
    return complexTypesWithAttributes?.find(
      (ct) => ct.versionId === complexTypeId?.versionId
    );
  }, [complexTypesWithAttributes]);

  useEffect(() => {
    roles.includes(rolesList.DICTIONARY_READ_ALL_DATA_TYPES) &&
      dispatch(getDataTypes());
    if (
      complexTypeId &&
      roles.includes(rolesList.COMPLEX_TYPE_ATTRIBUTE_READ_ALL)
    ) {
      dispatch(getComplexTypeAttributes(complexTypeId.versionId));
    }
    return () => {
      dispatch(complexTypeActions.setComplexTypeAttributes({ attributes: [] }));
    };
  }, [complexTypeId]);

  useEffect(() => {
    setAttributes(
      currComplexTypeWithAttributes?.attributes?.map((a) => {
        return {
          attributeId: a.attributeId,
          attributeName: a.attributeName,
          complexFlag: a.complexFlag,
          arrayFlag: a.arrayFlag,
          primitiveTypeId: a.primitiveTypeId,
          complexTypeVersionId: a.complexTypeVersionId,
          description: a.description,
          key: a.attributeId,
        } as IAttributeRow;
      })
    );
    setTypeName(currComplexTypeWithAttributes?.displayName || '');
  }, [currComplexTypeWithAttributes]);

  useEffect(() => {
    const err: React.Key[] = [];
    attributes?.forEach((a: IAttributeRow, _: number, arr: IAttributeRow[]) => {
      if (
        !a.attributeName ||
        !a.attributeName.match(regExp) ||
        arr.some(
          (b) => a.key !== b.key && a.attributeName === b.attributeName
        ) ||
        (a.complexFlag && !a.complexTypeVersionId) ||
        (!a.complexFlag && !a.primitiveTypeId)
      ) {
        err.push(a.key);
      }
    });

    if (err.length) {
      setErrors(err);
    } else {
      setErrors([]);
    }
  }, [attributes]);

  const attrColumns = useMemo(() => {
    return [
      {
        key: 'attributeName',
        dataIndex: 'attributeName',
        title: translate('attrName'),
        render: (value: string, rowData: IAttributeRow) => {
          const isNotValid = errors.includes(rowData.key);
          const errorText = isNotValid
            ? 'Наименование должно быть уникальным, состоять из [A-Za-z0-9_.] и начинаться с буквы'
            : undefined;
          return (
            <Input
              value={value}
              error={errorText}
              disabled={
                !roles.includes(rolesList.COMPLEX_TYPE_ATTRIBUTE_UPDATE)
              }
              onChange={(e) => {
                setAttributes(
                  (attributes || []).map((attr) => {
                    if (attr.key !== rowData.key) return attr;
                    return {
                      ...attr,
                      attributeName: e.target.value,
                    };
                  })
                );
              }}
            />
          );
        },
      },
      {
        key: 'typeId',
        dataIndex: 'typeId',
        title: translate('attrType'),
        render: (_: any, rowData: IAttributeRow) => {
          const currTypeId = rowData.complexFlag
            ? rowData.complexTypeVersionId
            : rowData.primitiveTypeId;

          return (
            <DataTypesSelect
              value={currTypeId || ''}
              onChange={(dataType) => {
                dataType &&
                  setAttributes(
                    (attributes || []).map((attr) => {
                      if (attr.key !== rowData.key) return attr;
                      return {
                        ...attr,
                        complexTypeVersionId: dataType.complexFlag
                          ? dataType.typeId
                          : null,
                        primitiveTypeId: !dataType.complexFlag
                          ? dataType.typeId
                          : null,
                        complexFlag: dataType.complexFlag,
                      };
                    })
                  );
              }}
            />
          );
        },
      },
      {
        key: 'array',
        dataIndex: 'array',
        title: translate('array'),
        render: (value: any, rowData: IAttributeRow) => {
          return (
            <div className="custom-type-edit__checkbox-cell">
              <Checkbox
                checked={rowData.arrayFlag}
                disabled={
                  !roles.includes(rolesList.COMPLEX_TYPE_ATTRIBUTE_UPDATE)
                }
                onChange={(e) => {
                  setAttributes(
                    (attributes || []).map((attr) => {
                      if (attr.key !== rowData.key) return attr;
                      return {
                        ...attr,
                        arrayFlag: e.target.checked,
                      };
                    })
                  );
                }}
              />
            </div>
          );
        },
      },
    ];
  }, [translate, refDataTypes, attributes, errors]);

  const actions = useMemo(
    () => [
      {
        icon: 'icon-add',
        tooltip: translateActions('add'),
        disabled: !roles.includes(rolesList.COMPLEX_TYPE_ATTRIBUTE_CREATE),
        onClick: () => {
          setAttributes([
            ...(attributes || []),
            {
              key: 'new-' + uuidv4(),
            },
          ]);
        },
      },
      {
        icon: 'icon-delete',
        tooltip: translateActions('delete'),
        disabled: !roles.includes(rolesList.COMPLEX_TYPE_ATTRIBUTE_DELETE),
        onClick: () => {
          setAttributes(
            (attributes || []).filter(
              (a: { key: Key }) => !selectedAttrRowKeys.includes(a.key)
            )
          );
        },
      },
    ],
    [translateActions, selectedAttrRowKeys, attributes]
  );

  const saveData = useCallback(() => {
    const typeData = {
      typeName,
      description: currComplexTypeWithAttributes?.description || '',
      displayName: typeName,
    };
    const attributesData = (attributes || []).map((attr) => ({
      attributeId: attr.attributeId,
      attributeName: attr.attributeName || '',
      complexFlag: Boolean(attr.complexFlag),
      arrayFlag: Boolean(attr.arrayFlag),
      primitiveTypeId: attr.primitiveTypeId || null,
      complexTypeVersionId: attr.complexTypeVersionId || null,
      description: attr.description || '',
    }));
    dispatch(
      !complexTypeId
        ? addComplexType({
            ...typeData,
            attributes: [...attributesData],
          })
        : manageChangedComplexType({
            typeId: complexTypeId.versionId,
            ...typeData,
            newAttributes: attributesData.filter((attr) => !attr.attributeId),
            changedAttributes: attributesData.filter((attr) => {
              if (!attr.attributeId) return false;
              const currAttr = currComplexTypeWithAttributes?.attributes.find(
                (a) => a.attributeId === attr.attributeId
              );
              return (
                currAttr &&
                Object.entries(attr).some(
                  // @ts-ignore
                  ([key]) => attr[key] !== currAttr[key]
                )
              );
            }) as IComplexTypeAttribute[],
            deletedAttributes:
              currComplexTypeWithAttributes?.attributes
                .filter(
                  (attr) =>
                    !attributesData.some(
                      (a) => a.attributeId === attr.attributeId
                    )
                )
                .map((attr) => attr.attributeId) || [],
          })
    );
    onChangesSaved && onChangesSaved();
    closeOnSave && onClose();
  }, [attributes, typeName]);

  const saveButtonDisabled = useMemo(() => {
    const attrNotValid = (a: IAttributeRow, _: number, arr: IAttributeRow[]) =>
      !a.attributeName ||
      !a.attributeName.match(regExp) ||
      arr.some((b) => a.key !== b.key && a.attributeName === b.attributeName) ||
      (a.complexFlag && !a.complexTypeVersionId) ||
      (!a.complexFlag && !a.primitiveTypeId);

    if (complexTypeId) {
      return (
        Boolean(!typeName || !attributes || attributes.some(attrNotValid)) ||
        !roles.includes(rolesList.COMPLEX_TYPE_UPDATE)
      );
    } else {
      return (
        Boolean(!typeName || !attributes || attributes.some(attrNotValid)) ||
        !roles.includes(rolesList.COMPLEX_TYPE_CREATE)
      );
    }
  }, [typeName, attributes, roles, complexTypeId]);

  return (
    <Modal
      modalProps={{
        title: modalProps?.title || translate('editType'),
        type: modalProps?.type || 'editComplexType',
        width: 65,
        helpKey: 'custom-data-types',
        footer: (
          <ModalFooter
            saveDisabled={saveButtonDisabled}
            onSave={saveData}
            onClose={onClose}
          />
        ),
      }}
      isOn={isEditModalVisible}
      onClose={onClose}
    >
      <div className="custom-type-tabs-wrapper">
        <TabPanel
          items={tabs}
          level={3}
          active={activeThirdPanel}
          onClick={(_, key) => {
            setActiveThirdPanel(key);
          }}
        />
      </div>
      {activeThirdPanel === '0' && (
        <section className="custom-type-edit">
          <Input
            label={translate('typeName')}
            className="custom-type-edit__name"
            value={typeName}
            onChange={(e) => setTypeName(e.target.value)}
            disabled={!roles.includes(rolesList.COMPLEX_TYPE_UPDATE)}
          />
          <ModalBox
            className="custom-type-edit__table-wrapper"
            title={translate('typeAttr')}
            actions={actions}
            showHeader
          >
            <SimpleTable
              dataSource={attributes}
              columnsData={attrColumns}
              selectedRowKeys={selectedAttrRowKeys}
              setSelectedRowKeys={setSelectedAttrRowKeys}
            />
          </ModalBox>
        </section>
      )}
      {activeThirdPanel === '1' && (
        <CustomTypeVersions
          complexTypeId={complexTypeId?.typeId}
          setActiveTab={setActiveThirdPanel}
        />
      )}
    </Modal>
  );
};
