import React, { useState } from 'react';
import { ConnectionsTableData } from '@modules/connections/types/connectionsTypes';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useConnectionsActions,
  useConnectionsSelector,
} from '@modules/connections/hooks';
import {
  ApproveModal,
  ApproveModalModalProps,
} from '@modules/connections/components';
const { Item } = Menu;

type TActionTypes = 'open' | 'openJustSee' | 'rename' | 'delete';

interface IContextMenuFill {
  action: TActionTypes;
  text: string;
  divider?: boolean;
  key: number;
}

const menuFill: IContextMenuFill[] = [
  {
    action: 'open',
    text: 'connections.contextMenu.open',
    key: 1,
  },
  {
    action: 'openJustSee',
    text: 'connections.contextMenu.openJustSee',
    key: 2,
  },
  {
    action: 'rename',
    text: 'connections.contextMenu.rename',
    key: 3,
  },
  {
    action: 'delete',
    text: 'connections.contextMenu.delete',
    divider: true,
    key: 4,
  },
];

interface IConnectionContextMenu {
  data: ConnectionsTableData;
}

export const ConnectionsContextMenu: React.FC<IConnectionContextMenu> = ({
  data,
  children,
}) => {
  const { t } = useTranslation();
  const { tableData } = useConnectionsSelector();
  const {
    setEditRow,
    setConnectionActiveId,
    setVConnectionsDataSourceModal,
    setDisabledConnectionsDataSourceModal,
    setConnectionsTable,
  } = useConnectionsActions();
  const [openedModal, setOpenedModal] = useState<ApproveModalModalProps | null>(
    null
  );

  const onContextMenuItemClick = (
    { connectionId }: ConnectionsTableData,
    action: TActionTypes
  ) => {
    switch (action) {
      case 'rename': {
        setEditRow(connectionId);
        break;
      }
      case 'open': {
        setConnectionActiveId(connectionId);
        setVConnectionsDataSourceModal(true);
        break;
      }
      case 'openJustSee': {
        setConnectionActiveId(connectionId);
        setDisabledConnectionsDataSourceModal(true);
        setVConnectionsDataSourceModal(true);
        break;
      }
      case 'delete': {
        setOpenedModal({
          type: 'deleteConnections',
          yesClick: () => {
            const updatedTableData = tableData.filter(
              (connection) => connection.connectionId !== connectionId
            );
            setConnectionsTable(updatedTableData);
            setOpenedModal(null);
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const menu = (data: ConnectionsTableData) => (
    <Menu>
      {menuFill.map(({ text, divider, action, key }) =>
        !divider ? (
          <Item key={key} onClick={() => onContextMenuItemClick(data, action)}>
            {t(text)}
          </Item>
        ) : (
          <div key={key}>
            <Menu.Divider />
            <Item onClick={() => onContextMenuItemClick(data, action)} danger>
              {t(text)}
            </Item>
          </div>
        )
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu(data)} trigger={['contextMenu']}>
        <span> {children} </span>
      </Dropdown>
      {openedModal?.type === 'deleteConnections' && (
        <ApproveModal
          modalProps={openedModal}
          onClose={() => setOpenedModal(null)}
          modalText={t('connections.deleteModalText')}
        />
      )}
    </>
  );
};
