import { ColumnsType } from 'antd/lib/table';
import { ConnectionsVariablesDTO } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';

export const rawColumnsInputVariables: ColumnsType<ConnectionsVariablesDTO> = [
  {
    title: '',
    dataIndex: 'collapse',
    className: 'ant-table-collapse',
  },
  {
    title: 'variableName',
    dataIndex: 'variableName',
    width: '50%',
  },
  {
    title: 'isArray',
    dataIndex: 'isArray',
    align: 'center',
  },
  {
    title: 'variableTypeId',
    dataIndex: 'variableTypeId',
    width: '50%',
  },
];
