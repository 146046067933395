import { Key } from 'react';
import {
  CustomCodeCreateUserVersion,
  CustomCodeUpdateUserVersion,
  ICustomCode,
  ICustomCodeVersion,
  ScriptValidate,
} from '@modules/development';
import { apiBase, IResponse } from '@modules/apiBase';

const CUSTOM_CODE_ENDPOINT = 'scripts';

export const customCodeApi = {
  getCustomCodes: async () => {
    return await apiBase.get(CUSTOM_CODE_ENDPOINT);
  },
  getCustomCode: async (id: Key) => {
    return await apiBase.get<ICustomCode>(`${CUSTOM_CODE_ENDPOINT}/${id}`);
  },
  createCustomCode: async (body: any) => {
    return await apiBase.post<any>(CUSTOM_CODE_ENDPOINT, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
  editCustomCode: async (payload: any) => {
    const { id, body } = payload;
    return await apiBase.put(`${CUSTOM_CODE_ENDPOINT}/${id}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
  deleteCustomCode: async (id: Key) => {
    return await apiBase.delete(`${CUSTOM_CODE_ENDPOINT}/${id}`);
  },
  validateCustomCode: async (body: ScriptValidate) => {
    return await apiBase.post<IResponse>(
      `${CUSTOM_CODE_ENDPOINT}/validate`,
      body
    );
  },
  deployCustomCode: async (id: Key) => {
    return await apiBase.get(`${CUSTOM_CODE_ENDPOINT}/deploy/${id}`);
  },
  createCustomCodeVersion: async (
    scriptId: string,
    body: CustomCodeCreateUserVersion
  ) => {
    return await apiBase.post<IResponse>(
      `${CUSTOM_CODE_ENDPOINT}/${scriptId}/create/userVersion`,
      body
    );
  },
  getCustomCodeVersions: async (scriptId: string) => {
    return await apiBase.get<ICustomCodeVersion[]>(
      `${CUSTOM_CODE_ENDPOINT}/${scriptId}/versions`
    );
  },
  editCustomCodeVersion: async (
    versionId: string,
    body: CustomCodeUpdateUserVersion
  ) => {
    return await apiBase.put<IResponse>(
      `${CUSTOM_CODE_ENDPOINT}/${versionId}/updateUserVersion`,
      body
    );
  },
};
