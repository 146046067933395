import {
  IServiceHeader,
  IServiceHeaderBase,
  IServiceVariable,
  IServiceVariableBase,
  ServiceAdd,
  ServiceUpdate,
} from '@modules/services';
import { Header, Service, Variable } from './interfaces';

const convertVariables = (
  variables: Variable[],
  isInput: boolean
): IServiceVariableBase[] | IServiceVariable[] => {
  return variables.map((variable) => {
    const newVariable: any = {
      variableId: variable.variableId,
      variableName: variable.variableName,
      primitiveTypeId: !variable.isComplex
        ? Number(variable.variableTypeId)
        : undefined,
      complexTypeVersionId: variable.isComplex
        ? variable.variableTypeId
        : undefined,
      parameterType: isInput ? 'IN' : 'OUT',
      arrayFlag: variable.isArray,
      sourcePath: variable.sourcePath,
    };

    for (const key in newVariable) {
      if (typeof newVariable[key] === 'undefined') {
        delete newVariable[key];
      }
    }

    if (!newVariable.variableId) {
      return newVariable as IServiceVariableBase;
    }

    return newVariable as IServiceVariable;
  });
};

const convertHeaders = (
  headers: Header[]
): IServiceHeaderBase[] | IServiceHeader[] => {
  return headers.map((header) => {
    const newHeader: any = {
      headerId: header.headerId,
      headerName: header.headerName,
      headerValue: header.headerValue,
    };

    for (const key in newHeader) {
      if (typeof newHeader[key] === 'undefined') {
        delete newHeader[key];
      }
    }

    if (!newHeader.headerId) {
      return newHeader as IServiceHeaderBase;
    }

    return newHeader as IServiceHeader;
  });
};

export const getBody = (
  service: Service,
  headers: Header[],
  inputVariables: Variable[],
  outputVariables: Variable[]
): ServiceAdd | ServiceUpdate => ({
  serviceName: service.serviceName,
  description: service.description,
  host: service.host,
  serviceType: service.serviceType,
  endpoint: service.endpoint,
  port: +service.port,
  syncType: service.syncType,
  protocol: service.protocol,
  method: service.method,
  fileFormat: service.fileFormat,
  batchFlag: service.batchFlag === 'BATCH' ? true : false,
  secondAttemptsCnt: +service.secondAttemptsCnt,
  transactionsPerSecond:
    service.transactionsPerSecond && +service.transactionsPerSecond,
  interval: +service.interval,
  timeout: service.timeout && +service.timeout,
  body: service.body,
  headers: convertHeaders(headers),
  variables: [
    ...convertVariables(inputVariables, true),
    ...convertVariables(outputVariables, false),
  ],
});
