import { Dispatch, FC, SetStateAction } from 'react';
import { InputAddData } from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import { BranchTypes, ModalTypes, OpenedModal } from './interfaces';
import './branchModal.scss';

export const BranchingAdditionalField: FC<{
  condition: string;
  branchingType: BranchTypes;
  error?: string;
  setOpenedModal: Dispatch<SetStateAction<OpenedModal>>;
  disabled?: boolean;
}> = ({ condition, branchingType, error, setOpenedModal, disabled }) => {
  const translate = useTranslationPath(
    'development.diagram.diagram.modals.branching'
  );
  switch (branchingType) {
    case BranchTypes.BranchByElement:
      return (
        <div className="form-field form-field_elem_name">
          <InputAddData
            label={translate('elemName')}
            value={condition}
            error={error}
            disabled={disabled}
            onClick={() =>
              setOpenedModal({
                type: ModalTypes.addElem,
                condition: condition?.split('.').reverse()[0],
              })
            }
          />
        </div>
      );
    case BranchTypes.BranchByCalculateCondition:
      return (
        <div className="form-field form-field_condition">
          <InputAddData
            label={translate('calcCondition')}
            value={condition.replace(/[\n\r]/g, ' ')}
            error={error}
            tooltip={condition}
            disabled={disabled}
            onClick={() =>
              setOpenedModal({
                type: ModalTypes.exprEditor,
                title: translate('inputBranchingCondition'),
                expression: condition,
              })
            }
          />
        </div>
      );
    default:
      return null;
  }
};
