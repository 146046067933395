import { TConnectionsFormSettings } from '@modules/connections/hooks';
import { ConnectionsDataSourceModalFields } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import {
  DBMS,
  MESSAGE_BROKERS,
  EXTERNAL_SERVICES,
} from '@modules/connections/constants';

export const rawCommonFormFields: TConnectionsFormSettings[] = [
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.sourceName,
    label: 'connections.sourceDataModal.formFields.sourceName',
    required: true,
  },
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.driver,
    label: 'connections.sourceDataModal.formFields.driver',
    required: true,
    props: {
      disabled: true,
    },
  },
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.sourceDescription,
    label: 'connections.sourceDataModal.formFields.sourceDescription',
  },
  {
    type: 'select',
    key: ConnectionsDataSourceModalFields.sourceType,
    label: 'connections.sourceDataModal.formFields.sourceType',
    required: true,
    props: {
      options: [
        {
          value: DBMS,
          translation: 'connections.types.DBMS',
        },
        {
          value: EXTERNAL_SERVICES,
          translation: 'connections.types.outputServices',
        },
        {
          value: MESSAGE_BROKERS,
          translation: 'connections.types.messageBrokers',
        },
      ],
    },
  },
];
