/* eslint-disable require-jsdoc */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { userStorage } from '@modules/userStorage';
import { IResponseError } from './types/interface';

// @ts-ignore
const API_URL = window._env_.API_URL;

class ApiBase {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({ baseURL: API_URL });

    this.init();
  }

  private init() {
    this.instance.interceptors.request.use((config) => {
      if (userStorage.get('token')) {
        config.headers.Authorization = `Bearer ${userStorage.get('token')}`;
      }
      return config;
    });
  }

  private async requestWrapper<T>(request: Promise<AxiosResponse<T>>) {
    try {
      const response = await request;
      if (response.status === 204) {
        throw response;
      } else {
        return response.data;
      }
    } catch (error) {
      if ((error as AxiosResponse).status === 204) {
        throw new Error('No content');
      } else {
        throw (error as IResponseError).response?.data;
      }
    }
  }

  get<T>(url: string, config?: AxiosRequestConfig | undefined) {
    return this.requestWrapper<T>(this.instance.get<T>(url, config));
  }

  post<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    return this.requestWrapper<T>(this.instance.post<T>(url, data, config));
  }

  put<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    return this.requestWrapper<T>(this.instance.put<T>(url, data, config));
  }

  delete<T>(url: string, config?: AxiosRequestConfig | undefined) {
    return this.requestWrapper<T>(this.instance.delete<T>(url, config));
  }
}

export const apiBase = new ApiBase();
