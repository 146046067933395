import { useKeycloak } from '@react-keycloak/web';
import { servicesActions } from '@modules/services';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useRoles = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  useEffect(() => {
    if (keycloak && keycloak.authenticated) {
      const userName = `${keycloak.tokenParsed?.given_name} ${keycloak.tokenParsed?.family_name}`;
      const roles =
        keycloak.resourceAccess &&
        keycloak.resourceAccess['decision.frontend']?.roles;
      roles && dispatch(servicesActions.setRoles(roles));
      userName && dispatch(servicesActions.setUserName(userName));
    }
  }, [keycloak?.authenticated]);
};
