/* eslint-disable require-jsdoc */
import { apiBase } from '@modules/apiBase';
import {
  ConnectionsResponse,
  TConnectionType,
} from '@modules/connections/types/connectionsTypes';
import {
  ConnectionRdbmsResponse,
  ConnectionsDataSourceTestDBFields,
  OneConnectionDTO,
} from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import { ConnectionEntityOptionsApi } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import {
  ALL_CONNECTIONS,
  DBMS,
  EXTERNAL_SERVICES,
  MESSAGE_BROKERS,
} from '../constants';

const CONNECTIONS_ENDPOINT = 'connections';

const connectionTyoeToPath = {
  [MESSAGE_BROKERS]: 'brokers',
  [EXTERNAL_SERVICES]: 'services',
  [DBMS]: 'dataprovider',
  [ALL_CONNECTIONS]: '',
  '': '',
};

class Connections {
  async getAllConnections(type: TConnectionType | '') {
    const path = connectionTyoeToPath[type];
    return await apiBase.get<ConnectionsResponse[]>(
      `${CONNECTIONS_ENDPOINT}${path ? `/${path}` : ''}`
    );
  }
  async getOneConnection(type: TConnectionType, id: string) {
    const path = connectionTyoeToPath[type];
    return await apiBase.get<ConnectionRdbmsResponse>(
      `${CONNECTIONS_ENDPOINT}/${path}/${id}`
    );
  }
  async createConnectionEntity(
    type: TConnectionType,
    data: Omit<OneConnectionDTO, 'connectionId'>
  ) {
    const path = connectionTyoeToPath[type];
    return await apiBase.post(`${CONNECTIONS_ENDPOINT}/${path}`, data);
  }
  async updateConnectionEntity(
    type: TConnectionType,
    id: string,
    data: OneConnectionDTO
  ) {
    const path = connectionTyoeToPath[type];
    return await apiBase.put(`${CONNECTIONS_ENDPOINT}/${path}/${id}`, data);
  }
  async deleteConnection(type: TConnectionType, id: string) {
    const path = connectionTyoeToPath[type];
    return await apiBase.delete(`${CONNECTIONS_ENDPOINT}/${path}/${id}`);
  }
  async testRdbConnection(data: ConnectionsDataSourceTestDBFields) {
    return await apiBase.post(
      `${CONNECTIONS_ENDPOINT}/dataprovider/testConnection`,
      data
    );
  }
}

export const connectionsApi = new Connections();
