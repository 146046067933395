export const mockVersionsData = [
  {
    serviceId: '1ead5d9b-40df-4b6a-b18b-e01d1b455b52',
    versionId: '1ead5d9b-40df-4b6a-b18b-e01d1b455b52',
    serviceName: 'СПАРК',
    uri: 'http://external-service:9099/CRE',
    createDt: '20-03-2022 10:31:27',
    changeDt: '09-08-2022 14:41:01',
    lastChangeByUser: '58b5bd5c-b349-49d3-87c3-186f1b7f6230',
    createByUser: '58b5bd5c-b349-49d3-87c3-186f1b7f6230',
    versionType: 'LATEST',
    versionName: 'v.2022-08-09_14:41:01',
    versionDescription: null,
    rootObjectVersionId: null,
  },
  {
    serviceId: '1ead5d9b-40df-4b6a-b18b-e01d1b455b52',
    versionId: '3f67c382-66e0-4923-98fc-d0f4dc7985df',
    serviceName: 'СПАРК',
    uri: 'http://external-service:9099/CRE',
    createDt: '27-09-2022 08:28:54',
    changeDt: '27-09-2022 08:28:54',
    lastChangeByUser: '235d49d4-99f7-45fb-9b3f-bd5265274027',
    createByUser: '58b5bd5c-b349-49d3-87c3-186f1b7f6230',
    versionType: 'USER_GLOBAL',
    versionName: 'TANYA_GLOBE123',
    versionDescription: null,
    rootObjectVersionId: 'cf1e312c-7296-4eb6-8a88-1895b5a5aece',
  },
  {
    serviceId: '1ead5d9b-40df-4b6a-b18b-e01d1b455b52',
    versionId: '1047def2-8ffe-408a-902e-3659b1fc5bfb',
    serviceName: 'СПАРК',
    uri: 'http://external-service:9099/CRE',
    createDt: '27-09-2022 14:26:22',
    changeDt: '27-09-2022 14:26:22',
    lastChangeByUser: '235d49d4-99f7-45fb-9b3f-bd5265274027',
    createByUser: '58b5bd5c-b349-49d3-87c3-186f1b7f6230',
    versionType: 'DEPLOYED',
    versionName: 'v.2022-09-27_14:26:21_deploy',
    versionDescription: null,
    rootObjectVersionId: '7a0c2d63-aafe-42ad-8c6f-710310691050',
  },
  {
    serviceId: '1ead5d9b-40df-4b6a-b18b-e01d1b455b52',
    versionId: '82761859-3801-41db-b566-2347fd96de08',
    serviceName: 'СПАРК',
    uri: 'http://external-service:9099/CRE',
    createDt: '27-09-2022 14:26:26',
    changeDt: '27-09-2022 14:26:26',
    lastChangeByUser: '235d49d4-99f7-45fb-9b3f-bd5265274027',
    createByUser: '58b5bd5c-b349-49d3-87c3-186f1b7f6230',
    versionType: 'DEPLOYED',
    versionName: 'v.2022-09-27_14:26:26_deploy',
    versionDescription: null,
    rootObjectVersionId: 'b45dfd7e-f184-43d6-8c29-df2369a35605',
  },
];
