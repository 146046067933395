import { TConnectionsFormSettings } from '@modules/connections/hooks';
import { ConnectionsDataSourceModalFields } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';

export const rawExternalServiceFields: TConnectionsFormSettings[] = [
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.host,
    label: 'connections.sourceDataModal.formFields.host',
    required: true,
  },
  {
    type: 'select',
    key: ConnectionsDataSourceModalFields.fileFormat,
    label: 'connections.sourceDataModal.formFields.fileFormat',
    props: {
      options: [
        {
          value: 'json',
          label: '.json',
        },
        {
          value: 'xml',
          label: '.xml',
        },
      ],
    },
  },
  {
    type: 'select',
    key: ConnectionsDataSourceModalFields.serviceType,
    label: 'connections.sourceDataModal.formFields.exchangeType',
    props: {
      value: 'HTTP',
      options: [
        {
          value: 'HTTP',
          label: 'http://',
        },
        {
          value: 'HTTPS',
          label: 'https://',
        },
      ],
    },
  },
  {
    type: 'select',
    key: ConnectionsDataSourceModalFields.batchFlag,
    label: 'connections.sourceDataModal.formFields.serviceType',
    props: {
      value: true,
      options: [
        {
          value: true,
          translation: 'connections.sourceDataModal.serviceType.batch',
        },
        {
          value: false,
          translation: 'connections.sourceDataModal.serviceType.single',
        },
      ],
    },
  },
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.endPoint,
    label: 'connections.sourceDataModal.formFields.endPoint',
    props: {
      addonBefore: '/',
    },
  },
  {
    type: 'input-number',
    key: ConnectionsDataSourceModalFields.port,
    label: 'connections.sourceDataModal.formFields.port',
    required: true,
    props: {
      type: 'number',
      value: '0',
    },
  },
  {
    type: 'select',
    key: ConnectionsDataSourceModalFields.syncType,
    label: 'connections.sourceDataModal.formFields.communicationType',
    props: {
      value: 'ASYNC',
      options: [
        {
          value: 'ASYNC',
          translation: 'connections.sourceDataModal.communicationType.async',
        },
        {
          value: 'SYNC',
          translation: 'connections.sourceDataModal.communicationType.sync',
        },
      ],
    },
  },
  {
    type: 'input-number',
    key: ConnectionsDataSourceModalFields.timeout,
    label: 'connections.sourceDataModal.formFields.maxTimeout',
    props: {
      type: 'number',
      value: '0',
    },
  },
  {
    type: 'input-number',
    key: ConnectionsDataSourceModalFields.secondAttemptsCnt,
    label: 'connections.sourceDataModal.formFields.amountTrySend',
    props: {
      type: 'number',
      value: '1',
    },
  },
  {
    type: 'select',
    key: ConnectionsDataSourceModalFields.protocol,
    label: 'connections.sourceDataModal.formFields.accessProtocol',
    props: {
      value: 'REST',
      options: [
        {
          value: 'REST',
          label: 'REST',
        },
        {
          value: 'SOAP',
          label: 'SOAP',
        },
      ],
    },
  },
  {
    type: 'input-number',
    key: ConnectionsDataSourceModalFields.transactionsPerSecond,
    label: 'connections.sourceDataModal.formFields.tps',
    required: true,
    props: {
      type: 'number',
      value: '0',
    },
  },
  {
    type: 'select',
    key: ConnectionsDataSourceModalFields.method,
    label: 'connections.sourceDataModal.formFields.restMethod',
    props: {
      options: [
        {
          value: 'GET',
          label: 'GET',
        },
        {
          value: 'POST',
          label: 'POST',
        },
        {
          value: 'PUT',
          label: 'PUT',
        },
      ],
    },
  },
  {
    type: 'input-number',
    key: ConnectionsDataSourceModalFields.interval,
    label: 'connections.sourceDataModal.formFields.intervalSending',
    required: true,
    props: {
      type: 'number',
      value: '0',
    },
  },
];
