import {
  ICustomCode,
  ICustomCodeVariable,
  IDataVariables,
} from '@modules/development';
import { CustomCodeData, ICustomCodeProperties } from './interfaces';

export const TRANSLATE_PATH = 'development.diagram.diagram.modals.customCode';

const parseScriptToVariable = (script: ICustomCodeVariable) => ({
  nodeVariable: script.variableName,
  isComplex: !!script.complexTypeVersionId,
  typeId: !!script.complexTypeVersionId
    ? script.complexTypeVersionId
    : script.primitiveTypeId || '',
  isArray: script.arrayFlag,
  variableName: '',
});

export const parseScriptVariables = (
  properties: ICustomCodeProperties,
  script: ICustomCode
) => {
  const parseVariable = (
    vA: ICustomCodeVariable,
    from: 'inputVariablesMapping' | 'outputVariablesMapping'
  ) => {
    const foundVariable = properties[from].find(
      (vB) =>
        vB.nodeVariable === vA.variableName &&
        vB.typeId ===
          (!!vA.complexTypeVersionId
            ? vA.complexTypeVersionId
            : vA.primitiveTypeId)
    );

    return foundVariable || parseScriptToVariable(vA);
  };

  return {
    ...properties,
    inputVariablesMapping: (script.variables || [])
      .filter((v) => v.variableType === 'IN')
      .map((vA) => parseVariable(vA, 'inputVariablesMapping')),
    outputVariablesMapping: (script.variables || [])
      .filter((v) => v.variableType === 'OUT')
      .map((vA) => parseVariable(vA, 'outputVariablesMapping')),
  };
};

export const setData = (customCodeData: CustomCodeData) => ({
  setNodeName: (value: string): CustomCodeData => ({
    ...customCodeData,
    nodeName: value,
  }),
  setScriptId: (scriptId: string): CustomCodeData => ({
    ...customCodeData,
    properties: {
      ...customCodeData.properties,
      customCodeId: scriptId,
      versionId: '',
      inputVariablesMapping: [],
      outputVariablesMapping: [],
    },
  }),
  setInputVariable: (rowIndex: number, v: IDataVariables): CustomCodeData => ({
    ...customCodeData,
    properties: {
      ...customCodeData.properties,
      inputVariablesMapping:
        customCodeData.properties.inputVariablesMapping.map((it, index) => {
          if (index !== rowIndex) return it;
          return { nodeVariable: it.nodeVariable, ...v };
        }),
    },
  }),
  setOutputVariable: (rowIndex: number, v: IDataVariables): CustomCodeData => ({
    ...customCodeData,
    properties: {
      ...customCodeData.properties,
      outputVariablesMapping:
        customCodeData.properties.outputVariablesMapping.map((it, index) => {
          if (index !== rowIndex) return it;
          return { nodeVariable: it.nodeVariable, ...v };
        }),
    },
  }),
});
