import { STREAMS } from '@modules/development/routing/developmentConstants';

export const STREAMS_CORE_PATH = `/development/${STREAMS}`;
export const STREAM_PATH = `${STREAMS_CORE_PATH}/:diagram`;
export const GENERAL_INFORMATION_PATH = `${STREAM_PATH}/general-information`;
export const SCHEMAS = 'schemas';
export const SCHEMAS_PATH = `${STREAM_PATH}/${SCHEMAS}`;
export const STREAM = 'streams';
export const WORKING_AREA_PATH = `${STREAM_PATH}/${STREAM}`;
export const TESTING_PATH = `${STREAM_PATH}/testing`;
export const VERSIONS_PATH = `${STREAM_PATH}/versions`;
