import { ITabPanelItem } from '@shared';
import { IDataTableList } from '@modules/development';

const tabs: ITabPanelItem[] = [
  {
    key: '0',
    text: 'Скрипт',
    translation: 'development.customCode.tabs.script',
    path: '/script',
  },
  {
    key: '1',
    text: 'Входные/Выходные данные скрипта',
    translation: 'development.customCode.tabs.inputOutputScriptData',
    path: '/input-output-data-script',
  },
  {
    key: '2',
    text: 'Диаграммы, в которых вызывается скрипт',
    translation: 'development.customCode.tabs.diagramsInWhichTheScriptIsCalled',
    path: '/diagrams-with-script',
  },
  {
    key: '3',
    text: 'Версии',
    translation: 'development.customCode.tabs.versions',
    path: '/versions',
  },
];

const data: IDataTableList[] = [
  {
    key: 'customeCode_55',
    title: 'Code001',
    code: 'CODE001',
    location: '/CustomCode/',
    state: 'Развернуто',
    dateOfChange: '30.04.2021 13:40:01',
    user: 'User_1',
    version: '1.0',
  },
  {
    key: 'customeCode_61',
    title: 'Code002',
    code: 'CODE002',
    location: '/CustomCode/',
    state: 'Развернуто',
    dateOfChange: '01.05.2021 13:41:56',
    user: 'User_1',
    version: '1.0',
  },
  {
    key: 'customeCode_42',
    title: 'Code003',
    code: 'CODE003',
    location: '/CustomCode/',
    state: 'Развернуто',
    dateOfChange: '06.05.2021 15:14:22',
    user: 'User_1',
    version: '1.2',
  },
];

export { tabs, data };
