import React from 'react';
import { Column } from '@ant-design/charts';
import './Chart.scss';

interface IChartData {
  type: string;
  value: number;
}

interface IChart {
  data: IChartData[];
}

const Chart: React.FC<IChart> = ({ data }) => {
  const config = {
    data,
    yField: 'value',
    xField: 'type',
    width: 47,
    label: {
      style: {
        fill: '#fff',
        fontSize: 12,
      },
    },
    color: '#3A36F5',
    meta: {
      value: { alias: 'Value' },
      type: { alias: 'Type' },
    },
  };

  return (
    <div className="chart">
      <Column {...config} />
    </div>
  );
};

export { Chart };
