import { Key } from 'react';
import { filterByValue } from '@shared';
import { IDevelopmentState } from '@modules/development/DevelopmentSlice';
import { IAction, RootState } from '@modules/store';
import {
  deleteComplexType,
  deleteComplexTypes,
  getComplexTypes,
  IComplexTypeExt,
  selectComplexTypes,
  selectComplexTypesVersionsIds,
} from '@modules/complexType';
import { createSelector } from '@reduxjs/toolkit';

const reducers = {
  onSelectedCustomDataTypesRows(
    state: IDevelopmentState,
    action: IAction<{ keys: Key[] }>
  ) {
    state.customDataTypes.selectedRowTable = action.payload.keys;
  },
  onChangeCustomDataTypesSearch(
    state: IDevelopmentState,
    action: IAction<{ search: string }>
  ) {
    state.customDataTypes.search = action.payload.search;
  },
  addCustomDataType(state: IDevelopmentState) {
    state.customDataTypes.addNewRequested = true;
  },
  cancelAdd(state: IDevelopmentState) {
    state.customDataTypes.addNewRequested = false;
  },
};

export const selectCustomDataTypesSelectedRows = (state: RootState) => {
  return state.development.customDataTypes.selectedRowTable;
};

export const selectCustomDataTypesSearch = (state: RootState) => {
  return state.development.customDataTypes.search || '';
};

export const selectAddNewRequested = (state: RootState) => {
  return state.development.customDataTypes.addNewRequested;
};

export const selectCustomDataTypesFilteredData = createSelector(
  selectComplexTypes,
  selectCustomDataTypesSearch,
  (complexTypes, search) =>
    filterByValue<IComplexTypeExt>(complexTypes, 'typeName', search)
);
export const selectCustomDataTypesFilteredDataKeys = createSelector(
  selectCustomDataTypesFilteredData,
  (complexTypes) => complexTypes.map((ct) => ct.versionId)
);

export const getCustomDataTypes = getComplexTypes;
export const deletCustomDataType = deleteComplexType;
export const deleteCustomDataTypes = deleteComplexTypes;
export const selectCustomDataTypesKeys = selectComplexTypesVersionsIds;

export default reducers;
