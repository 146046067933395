import React, { Key, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  removeOpenObject,
  removeOpenTab,
  selectCustomCode,
  selectOpenTabs,
  setReadonly,
  getSelectedCustomCodeRow,
  onSelectedCustomCodeRow,
  getCustomCodes,
  deleteCustomCode,
  editCustomCode,
  onChangeCustomCodeSearch,
  selectCustomCodeSort,
  onChangeCustomCodeSort,
  selecCustomCodeFilteredData,
} from '../../DevelopmentSlice';
import { IDataTableList } from '@modules/development';
import { Portal, useSearch } from '@shared';
import './CustomCodesList.scss';
import { DataGridList } from '@modules/development/components/DataGridList';
import { useAppDispatch, useAppSelector } from '@modules/store/hooks';
import { selectRoles, rolesList } from '@modules/services';
import { CUSTOM_CODES_CORE_PATH } from './routing/сustomCodesConstants';

interface ICustomCodesList {}

const CustomCodesList: React.FC<ICustomCodesList> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const customCode = useAppSelector(selectCustomCode);
  const openTabs = useAppSelector(selectOpenTabs);
  const sort = useAppSelector(selectCustomCodeSort);
  const roles = useAppSelector(selectRoles);
  const data = useAppSelector(selecCustomCodeFilteredData);

  const onClickOpen = (data: IDataTableList) => {
    dispatch(setReadonly({ readonly: false }));

    if (!roles.includes(rolesList.SCRIPT_READ)) {
      return;
    } else {
      history.push(`${CUSTOM_CODES_CORE_PATH}/${data.key}`);
    }
  };

  const onClickOpenViewingOnly = (data: IDataTableList) => {
    // TODO добавить ограничение действий на странице кастомного кода
    dispatch(setReadonly({ readonly: true }));
    history.push({
      pathname: `${CUSTOM_CODES_CORE_PATH}/${data.key}`,
      state: { readonly: true, id: data.key },
    });
  };

  const onClickDelete = async (data: IDataTableList) => {
    const isOpen = openTabs.find((item) => item.key === data.key);
    if (isOpen) {
      // если скрипт открыт, то удалить его из списка открытых табов
      // и из списка открытых объектов
      dispatch(removeOpenTab({ key: data.key }));
      dispatch(removeOpenObject({ key: data.key }));
    }
    await dispatch(deleteCustomCode(data.key));
    await dispatch(getCustomCodes());
  };

  const updateDiagram = async (row: IDataTableList) => {
    const selectedCustomCode = Object.values(customCode.data).find(
      (code) => code.code === row.code
    );

    const newCustomCode = {
      scriptJson: JSON.stringify(selectedCustomCode?.scriptJson),
      reusableNodeName: row.title,
    };

    await dispatch(editCustomCode({ id: row.code, body: newCustomCode }));
    dispatch(getCustomCodes());
  };

  const onSelectedRowKeys = (keys: Key[]) => {
    dispatch(onSelectedCustomCodeRow({ keys }));
  };

  const onSearch = (search: string) => {
    dispatch(onChangeCustomCodeSearch({ search }));
  };

  const onSortChange = (sort: any) => {
    dispatch(onChangeCustomCodeSort(sort));
  };

  useEffect(() => {
    dispatch(getCustomCodes());
    return () => onSearch('');
  }, []);

  const [renderSearchInput, getRefProps] = useSearch({
    search: customCode.search,
    setSearch: onSearch,
  });

  return (
    <div className="custom-code" {...getRefProps()}>
      <Portal id="toolbarSearch">{renderSearchInput()}</Portal>
      <DataGridList
        data={data}
        sort={sort}
        onSortChange={onSortChange}
        selectedRow={getSelectedCustomCodeRow}
        changeData={updateDiagram}
        onClickOpen={onClickOpen}
        onClickOpenViewingOnly={onClickOpenViewingOnly}
        onClickDelete={onClickDelete}
        onSelectedRowKeys={onSelectedRowKeys}
        onDoubleClick={onClickOpen}
      />
    </div>
  );
};

export { CustomCodesList };
