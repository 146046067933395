import React, { Dispatch, Key, SetStateAction, useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslationPath } from '@modules/languageProvider';
import { InputWithButton, ModalBox, SimpleTable } from '@shared';
import {
  ModalData,
  ModalTypes,
  PropertyTypes,
  AggregateReadingNode,
  Validation,
} from '../interfaces';
import { TRANSLATE_PATH } from '../services';

type TableData = {
  key: Key;
  groupingAggregateElement: string;
  groupingDiagramElement: string;
};

interface IGroupingElements {
  readAggregateData: AggregateReadingNode;
  validation: Validation;
  disabled?: boolean;
  setModalData: Dispatch<SetStateAction<ModalData>>;
}

export const GroupingElements: React.FC<IGroupingElements> = ({
  readAggregateData,
  validation,
  disabled,
  setModalData,
}) => {
  const translate = useTranslationPath(TRANSLATE_PATH);

  const GROUPING_ELEMENTS = useMemo(() => {
    if (!readAggregateData) return {};

    const COLUMNS: ColumnsType<TableData> = [
      {
        title: translate('elemTableAggr'),
        dataIndex: 'groupingAggregateElement',
        key: 'groupingAggregateElement',
        onCell: () =>
          ({
            invalid: validation.invalidData.inputs.groupingAggregateElement,
          } as React.HTMLAttributes<HTMLElement>),
        render: (value, { key }) => (
          <InputWithButton
            className="reading-aggregate__input-with-button"
            value={value}
            disabled={disabled}
            onClick={() => {
              setModalData({
                type: ModalTypes.chooseGroupingElements,
                selectedNames: [value],
                key,
              });
            }}
            bordered={false}
            readOnly
          />
        ),
      },
      {
        title: translate('elemDiagram'),
        dataIndex: 'groupingDiagramElement',
        key: 'groupingDiagramElement',
        onCell: () =>
          ({
            invalid: validation.invalidData.inputs.groupingDiagramElement,
          } as React.HTMLAttributes<HTMLElement>),
        render: (value, { key }) => (
          <InputWithButton
            className="reading-aggregate__input-with-button"
            value={value}
            disabled={disabled}
            onClick={() =>
              setModalData({
                type: ModalTypes.addElement,
                property: PropertyTypes.diagramElement,
                variableName: value,
                key,
              })
            }
            bordered={false}
            readOnly
          />
        ),
      },
    ];

    const DATA: TableData[] = readAggregateData.properties.groupingElements.map(
      (variable, index) => ({
        key: `grouping-element-${index}`,
        groupingAggregateElement: variable.aggregateElement,
        groupingDiagramElement: variable.diagramElement,
      })
    );

    return { COLUMNS, DATA };
  }, [translate, validation, readAggregateData, disabled]);

  return (
    <ModalBox
      className="reading-aggregate__grouping-elements"
      title={translate('mapGroupElem')}
      showHeader
    >
      <SimpleTable
        columnsData={GROUPING_ELEMENTS.COLUMNS}
        dataSource={GROUPING_ELEMENTS.DATA}
      />
    </ModalBox>
  );
};
