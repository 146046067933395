import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewButton } from '@shared/Button/Button';
import { Chart } from './Chart';
import { Accordion } from '@shared';
import { data0, data1, data2 } from './data';
import './Charts.scss';

const TECH_MONITORING_TRANSLATION_PATH =
  'administrationTab.technicalMonitoring';
const getTranslationPath = (item: string) => {
  return `${TECH_MONITORING_TRANSLATION_PATH}.${item}`;
};

interface ICharts {
  isButtonsShow?: boolean;
}
const Charts: React.FC<ICharts> = ({ isButtonsShow }) => {
  const { t } = useTranslation();
  const [actionChart, setActionChart] = useState(0);
  const [chartData, setChartData] = useState(data0);

  const btns = useMemo(
    () => (
      <div className="charts__btns">
        <NewButton
          kind={actionChart === 0 ? 'active' : 'normal'}
          className="chart-btn"
          onClick={(e) => {
            e.stopPropagation();
            setActionChart(0);
            setChartData(data0);
          }}
        >
          {t(getTranslationPath('byMessageType'))}
        </NewButton>
        <NewButton
          kind={actionChart === 1 ? 'active' : 'normal'}
          className="chart-btn"
          onClick={(e) => {
            e.stopPropagation();
            setActionChart(1);
            setChartData(data1);
          }}
        >
          {t(getTranslationPath('byMessageSource'))}
        </NewButton>
        <NewButton
          kind={actionChart === 2 ? 'active' : 'normal'}
          className="chart-btn"
          onClick={(e) => {
            e.stopPropagation();
            setActionChart(2);
            setChartData(data2);
          }}
        >
          {t(getTranslationPath('byTime'))}
        </NewButton>
      </div>
    ),
    [actionChart, chartData, t]
  );
  return (
    <div className="charts">
      <Accordion
        title={t(getTranslationPath('charts'))}
        icon="charts"
        additionalHeaderElement={isButtonsShow && btns}
      >
        <Chart data={chartData} />
      </Accordion>
    </div>
  );
};

export { Charts };
