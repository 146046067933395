import React, { Key, useMemo } from 'react';
import { Dropdown, Menu } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IServiceShort } from '@modules/services';
import { ServiceData } from '../interfaces';

const { Item, Divider } = Menu;

export const useExtServAccessTable = (
  services: IServiceShort[] = [],
  search: string,
  translate: (attrName: any, options?: any) => any,
  onOpen: (key: Key, serviceId: string) => void,
  onDelete: (key: Key[]) => void
) => {
  const menu = (key: Key, serviceId: string) => (
    <Menu className="dropdown-menu">
      <Item onClick={() => onOpen(key, serviceId)}>{translate('open')}</Item>
      {/* <Item>{translate('openReadOnly')}</Item>
      <Item>{translate('renameObject')}</Item> */}
      <Divider />
      <Item onClick={() => onDelete([key])} danger>
        {translate('delete')}
      </Item>
    </Menu>
  );

  const onRenderCell = (value: any, rowData: ServiceData) => {
    return (
      <Dropdown
        overlay={menu(rowData.key, rowData.serviceId)}
        trigger={[`contextMenu`]}
      >
        <div style={{ margin: -16, padding: 16 }}>{value}</div>
      </Dropdown>
    );
  };

  const parseDate = (date: string) => {
    return Date.parse(
      date?.replace(/(\d{2})\-(\d{2})\-(\d{4})/, '$3-$2-$1')
    ).toString();
  };

  const sorterDate = (a: ServiceData, b: ServiceData) =>
    parseDate(a.changeDt).localeCompare(parseDate(b.changeDt));

  const columns: ColumnsType<ServiceData> = [
    {
      title: translate('serviceName'),
      dataIndex: 'serviceName',
      key: 'serviceName',
      width: '30%',
      render: onRenderCell,
      sorter: (a, b) => a.serviceName.localeCompare(b.serviceName),
    },
    {
      title: translate('uri'),
      dataIndex: 'uri',
      key: 'uri',
      width: '20%',
      render: onRenderCell,
      sorter: (a, b) => a.uri.localeCompare(b.uri),
    },
    {
      title: translate('changeDt'),
      dataIndex: 'changeDt',
      key: 'changeDt',
      width: '20%',
      render: onRenderCell,
      sorter: sorterDate,
    },
    {
      title: translate('lastChangeByUser'),
      dataIndex: 'lastChangeByUser',
      key: 'lastChangeByUser',
      width: '30%',
      render: onRenderCell,
      sorter: (a, b) => a.lastChangeByUser.localeCompare(b.lastChangeByUser),
    },
  ];

  const data = useMemo<ServiceData[]>(() => {
    if (!services.length) return [];

    return services
      .map((service) => ({
        key: service.versionId,
        serviceId: service.serviceId,
        serviceName: service.serviceName,
        uri: service.uri || '',
        changeDt: service.changeDt,
        lastChangeByUser: service.lastChangeByUser,
      }))
      .filter(
        ({ serviceName }) =>
          !search || serviceName.toLowerCase().includes(search.toLowerCase())
      );
  }, [services, search]);

  return { columns, data } as const;
};
