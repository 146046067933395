import * as React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useRoles } from '@modules/services';

interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteParams) => {
  const { keycloak } = useKeycloak();
  useRoles();

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? <Component {...props} /> : keycloak.login()
      }
    />
  );
};
