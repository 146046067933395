import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CUSTOMDATATYPES_OBJTYPE, IDiagram as IDiagramData } from './types';
import { TabSliderPanel } from '@shared/TabSliderPanel/TabSliderPanel';
import { SolutionLists } from './components/SolutionLists/SolutionLists';
import { Aggregate } from './modules/AggregatesList/components/Aggregate';
import { CustomCode } from './modules/CustomCodesList/components/CustomCode/CustomCode';
import { onSaveClick } from './utils';
import {
  currentObjectKey,
  removeOpenObject,
  removeOpenTab,
  selectOpenTabs,
  openObjects,
  removeDiagram,
  removeCustomCode,
  closeDiagram,
} from './DevelopmentSlice';
import './development.scss';
import { useTranslation } from 'react-i18next';
import { ConfirmModal, IConfirmModalProps } from '@shared';
import {
  AGGREGATES_PATH,
  CUSTOM_CODES_PATH,
  CUSTOM_DATA_TYPES_PATH,
  DIAGRAMS_PATH,
  STREAMS_PATH,
  STREAMS,
  SCHEMAS_PATH,
} from './routing/developmentConstants';
import { CommunicationChannels } from '@modules/communicationChannels';
import { Streams } from '@modules/development/modules/streams';

interface IDevelopment {
  match: {
    path: string;
  };
}

export const Development: React.FC<IDevelopment> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const openTabs = useSelector(selectOpenTabs);
  const currentObjKey = useSelector(currentObjectKey);
  const openObjectsData = useSelector(openObjects);

  const [activeSecondPanel, setActiveSecondPanel] = useState('');
  const [modalProps, setModalProps] = useState<IConfirmModalProps | null>(null);

  const location = history.location.pathname.split('/');

  const currentObjectData = useMemo(() => {
    if (!modalProps) return undefined;
    const objectKey = modalProps?.meta?.objectKey;
    return openObjectsData.find(({ key }) => key === objectKey);
  }, [modalProps]);

  const onClickObject = (
    _: any,
    key: string,
    type: string,
    path?: string,
    state?: any
  ) => {
    let pathname = '';

    if (type === CUSTOMDATATYPES_OBJTYPE)
      return history.replace({ pathname: CUSTOM_DATA_TYPES_PATH });

    switch (type) {
      case 'aggregate':
        pathname = `${AGGREGATES_PATH}/${key}`;
        break;
      case 'diagram':
        pathname = `${STREAMS_PATH}/${key}`;
        break;
      case 'customCode':
        pathname = `${CUSTOM_CODES_PATH}/${key}`;
        break;
      case 'schema':
        pathname = `${SCHEMAS_PATH}`;
        break;
    }

    path && (pathname = `${pathname}/${path}`);
    history.push({ pathname, state });
  };

  const onClickCloseObject = async (
    key: string,
    type: string,
    version: string
  ) => {
    // если закрывается таб диаграммы которая в данный момент на странице
    // то редирект на список диаграмм
    const currentObjectData = openObjectsData.find((item) => item.key === key);

    if (currentObjectData && !currentObjectData?.isSaved) {
      return setModalProps({
        type: 'confirm-modal',
        contentText: t(
          `development.${
            type === 'aggregate' ? 'aggregates' : type
          }.confirmationClose`
        ),
        okButtonText: t('modals.yes'),
        cancelButtonText: t('modals.no'),
        cancelButtonModifiers: ['hover-box-shadow', 'second'],
        closeButtonText: t('modals.cancel'),
        kind: 'info',
        meta: { objectKey: key, version },
      });
    }

    onClickCancelConfirmModal(key, version);
  };

  const onClickOkConfirmModal = async () => {
    onSaveClick(currentObjectData as IDiagramData, dispatch);
    onClickCancelConfirmModal();
  };

  const onClickCancelConfirmModal = (objectKey?: string, version?: string) => {
    const key = objectKey ?? modalProps?.meta?.objectKey;
    const ver = version ?? modalProps?.meta?.version;

    if (currentObjKey === key) history.push(location.slice(0, 3).join('/'));
    const currentObjectData = openObjectsData.find((o) => o.key === key);

    if (currentObjectData?.type === 'diagram') {
      dispatch(closeDiagram(ver));
    }

    if (currentObjectData?.draft) {
      currentObjectData?.type === 'diagram'
        ? dispatch(removeDiagram({ key }))
        : dispatch(removeCustomCode({ key }));
    }

    dispatch(removeOpenTab({ key }));
    dispatch(removeOpenObject({ key }));
  };

  const onClickCloseConfirmModal = () => {
    setModalProps(null);
  };

  useEffect(() => {
    if (history.location.pathname === CUSTOM_DATA_TYPES_PATH)
      return setActiveSecondPanel(CUSTOMDATATYPES_OBJTYPE);
    if (location.length >= 4 && location[3]) setActiveSecondPanel(location[3]);
    else setActiveSecondPanel('');
  }, [history.location.pathname]);

  const getContent = () => {
    // Todo переосмыслить данную конструкцию
    if (location[2] === 'сommunication-сhannels') {
      return <CommunicationChannels />;
    } else if (location[2] === STREAMS) {
      return <Streams />;
    }

    if (
      activeSecondPanel.length === 0 ||
      activeSecondPanel === CUSTOMDATATYPES_OBJTYPE
    ) {
      return <SolutionLists />;
    }

    switch (location[2]) {
      case 'aggregates':
        return <Aggregate />;

      // case 'diagrams':
      //   return <Diagram />;

      case 'custom-code-list':
        return <CustomCode />;
    }
  };
  return (
    <>
      <div className="development-page">
        <TabSliderPanel
          className="development-page__tabs"
          items={openTabs}
          level={2}
          active={activeSecondPanel}
          onClick={onClickObject}
          onClickClose={onClickCloseObject}
        />
        {getContent()}
      </div>

      {modalProps && modalProps.type === 'confirm-modal' && (
        <ConfirmModal
          modalProps={modalProps}
          onOk={onClickOkConfirmModal}
          onCancel={onClickCancelConfirmModal}
          onClose={onClickCloseConfirmModal}
        />
      )}
    </>
  );
};
