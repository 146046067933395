/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IObjectModal } from '@modules/development';
import { IDataProviderObjects } from '@modules/dataProvider';
import { Sort } from './types/interface';
import {
  deleteDataProviderById,
  getDataProvider,
  getDataProviderById,
  getDataProviderTables,
} from './actions';
import { ADMINISTRATION_FEATURE_KEY } from './constants';
import {
  IDiagramForDeploy,
  IEnvironments,
  ISettingsValues,
} from './components/DeployDiagram/interfaces';
import { AppThunk, RootState } from '@modules/store';
import { tryCatchDecorator } from '@modules/development/utils';
import { deployDiagramApi } from './components/DeployDiagram/api/deployDiagramApi';

export interface IAdministrationState {
  dataProvider: IDataProviderObjects;
  modal: IObjectModal;
  deployDiagramList: IDiagramForDeploy[];
  environments: IEnvironments[];
}

export const getDeployDiagramList = (): AppThunk => async (dispatch) => {
  return await tryCatchDecorator(dispatch, async () => {
    const response = await deployDiagramApi.getDeployDiagramList();
    dispatch(administrationActions.setDeployDiagramList(response));
  });
};

export const getEnvironments = (): AppThunk => async (dispatch) => {
  return await tryCatchDecorator(dispatch, async () => {
    const env = await deployDiagramApi.getEnvironments();
    dispatch(administrationActions.setEnvironments(env));
  });
};

export const deployDiagrams =
  (environmentId: React.Key, settings: ISettingsValues, ids: any): AppThunk =>
  async (dispatch) => {
    return await tryCatchDecorator(dispatch, async () => {
      Promise.all(
        ids.map(async (versionId: any) => {
          await deployDiagramApi.deployDiagram(
            versionId,
            environmentId,
            settings
          );
        })
      );
    });
  };

export const undeployDiagrams =
  (ids: React.Key[]): AppThunk =>
  async (dispatch) => {
    return await tryCatchDecorator(dispatch, async () => {
      Promise.all(
        ids.map(async (versionId) => {
          await deployDiagramApi.undeployDiagram(versionId);
        })
      );
    });
  };

const initialState: IAdministrationState = {
  dataProvider: {
    data: [],
    selectedRowKeys: [],
    search: '',
    isLoading: false,
    sort: {},
  },
  modal: {
    openModals: [],
  },
  deployDiagramList: [],
  environments: [],
};

const administrationSlice = createSlice({
  name: ADMINISTRATION_FEATURE_KEY,
  initialState,
  reducers: {
    addAdministrationOpenModal(state, action) {
      const { modalInfo } = action.payload;
      state.modal.openModals = [...state.modal.openModals, modalInfo];
    },
    removeAdministrationOpenModal(state, action) {
      state.modal.openModals = state.modal.openModals.slice(0, -1);
    },
    setDataProviderIsLoading(state, action) {
      state.dataProvider.isLoading = action.payload;
    },
    setDataProviderSelectedRowKeys(state, { payload }: PayloadAction<Key[]>) {
      state.dataProvider.selectedRowKeys = payload;
    },
    setDataProviderSearch(state, { payload }: PayloadAction<string>) {
      state.dataProvider.search = payload;
    },
    setDataProviderSort(state, { payload }: PayloadAction<Sort>) {
      state.dataProvider.sort = payload;
    },
    setDeployDiagramList(state, action) {
      const diagrams = action.payload.map((i: any) => ({
        key: i.deployId,
        deployDt: i.deployDt,
        deployId: i.deployId,
        deployStatus: i.deployStatus,
        deployUser: i.deployUser,
        diagramId: i.diagram.diagramId,
        diagramName: i.diagram.diagramName,
        versionName: i.diagram.versionName || '',
        children: i.childDeploys?.map((child: any) => ({
          key: child.deployId,
          // deployDt: child.deployDt,
          // deployId: child.deployId,
          // deployStatus: child.deployStatus,
          // deployUser: child.deployUser,
          // diagramId: child.diagram.diagramId,
          diagramName: child.diagram.diagramName,
          versionName: child.diagram.versionName,
          // ...child,
        })),
        ...i,
      }));
      state.deployDiagramList = diagrams;
    },
    setEnvironments(state, action) {
      state.environments = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getDataProviderById
    builder.addCase(getDataProviderById.fulfilled, (state, { payload }) => {
      state.dataProvider.data = state.dataProvider.data.find(
        (item) => item.sourceId === payload.sourceId
      )
        ? state.dataProvider.data.map((item) =>
            item.sourceId === payload.sourceId ? { ...item, ...payload } : item
          )
        : [...state.dataProvider.data, payload];
    });

    // deleteDataProviderById
    builder.addCase(deleteDataProviderById.fulfilled, (state, action) => {
      state.dataProvider.data = state.dataProvider.data.filter(
        (item) => item.sourceId !== action.payload.uuid
      );
    });

    // getDataProvider
    builder.addCase(getDataProvider.fulfilled, (state, action) => {
      state.dataProvider.data = action.payload;
    });

    // getDataProviderTables
    builder.addCase(getDataProviderTables.fulfilled, (state, action) => {
      state.dataProvider.data = state.dataProvider.data.map((item) => {
        if (item.sourceId === action.payload.sourceId) {
          return { ...item, children: action.payload.tables };
        }
        return item;
      });
    });
  },
});

export const selectDeployDiargamList = (state: RootState) =>
  state.administration.deployDiagramList;

export const selectEnvironments = (state: RootState) =>
  state.administration.environments;

const { reducer, actions } = administrationSlice;
export const administrationActions = { ...actions };
export { reducer as administrationReducer };
