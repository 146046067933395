import { IDevelopmentState } from '../../../DevelopmentSlice';
import { ICustomCode } from '../../../types';
import { findCurrentObject } from '../../../utils';

export const findCurrentCustomCode = (state: IDevelopmentState) => {
  return findCurrentObject<ICustomCode>(
    state.openObjects as ICustomCode[],
    state.currentObject.key
  );
};
