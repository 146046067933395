import {
  GroupingElement,
  OutputVariable,
  RetentionTimeUnits,
  RetentionTypes,
  WatermarkTypes,
} from './interfaces';

export const GROUPING_ELEMENT_TEMPLATE: GroupingElement = {
  diagramElement: '',
  aggregateElement: '',
};

export const OUTPUT_VARIABLE_TEMPLATE: OutputVariable = {
  variableName: '',
  isComplex: false,
  typeId: '',
  isArray: false,
  variablePath: '',
  variableRootId: '',
  aggregate: {
    aggregateId: '',
    versionId: '',
    aggregateElementTypeId: '',
    diagramAggregateElement: '',
    isUsedInDiagram: false,
  },
};

export const retentionTypeOptions = [
  { value: RetentionTypes.system, label: 'systemTime' },
  { value: RetentionTypes.event, label: 'eventTime' },
];

export const retentionTimeUnitOptions = [
  { value: RetentionTimeUnits.milliseconds, label: 'ms' },
  { value: RetentionTimeUnits.seconds, label: 'sec' },
  { value: RetentionTimeUnits.minutes, label: 'min' },
  { value: RetentionTimeUnits.hours, label: 'hour' },
  { value: RetentionTimeUnits.days, label: 'day' },
];

export const watermarkTypeOptions = [
  { value: WatermarkTypes.permanentOrderly, label: 'permanentOrderly' },
  { value: WatermarkTypes.permanentDisorderly, label: 'permanentDisorderly' },
  { value: WatermarkTypes.impermanentOrderly, label: 'impermanentOrderly' },
  {
    value: WatermarkTypes.impermanentDisorderly,
    label: 'impermanentDisorderly',
  },
];
