import { FC, Ref, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Input, InputRef, Select, Switch } from 'antd';
import './AvroElementContainer.scss';
import { AccordionNew } from '@modules/shared';

export interface IAvroData {
  type?: string | IAvroData | string[];
  name?: string;
  namespace?: string;
  items?: IAvroData;
  fields?: IAvroData[];
}

interface IAvroElement {
  data: IAvroData;
  editFlg: boolean;
  onChange?: (e: IAvroData) => any;
}

const typeOptions = [
  { value: 'string', label: 'string' },
  { value: 'int', label: 'int' },
  { value: 'double', label: 'double' },
  { value: 'boolean', label: 'boolean' },
  { value: 'record', label: 'record' },
  { value: 'array', label: 'array' },
  { value: 'complex', label: 'complex' },
];

const primitiveTypes = ['string', 'int', 'boolean', 'double'];

const defaultElem = {
  name: '',
  type: 'string',
};

export const AvroElement: FC<IAvroElement> = ({ data, editFlg, onChange }) => {
  const [entireElem, setEntireElem] = useState(data);
  const [fieldsVisibleFlg, setFieldsVisibleFlg] = useState(true);
  const [itemsVisibleFlg, setItemsVisibleFlg] = useState(true);
  const [fieldsDisplay, setFieldsDisplay] = useState(<></>);
  const [itemsDisplay, setItemsDisplay] = useState(<></>);
  const [items, setItems] = useState<IAvroData>();

  const [fields, setFields] = useState<IAvroData[]>();
  const [typeSimple, setTypeSimple] = useState(data?.type);
  const [typeNullableFlg, setTypeNullableFlg] = useState<boolean | undefined>(
    undefined
  );
  const [typeComplex, setTypeComplex] = useState(
    undefined as IAvroData | undefined
  );
  const [typeComplexDisplay, setTypeComplexDisplay] = useState(<></>);
  const [name, setName] = useState(data?.name);
  const [namespace, setNamespace] = useState(data?.namespace);

  useEffect(() => {
    // setEntireElem(data);
  }, [data]);

  useEffect(() => {
    setName(data?.name ? data?.name : '');
  }, [data?.name]);

  useEffect(() => {
    setNamespace(data?.namespace ? data?.namespace : '');
  }, [data?.namespace]);

  useEffect(() => {
    if (!editFlg && data?.fields) {
      setFields(data?.fields);
    }
  }, [data?.fields]);

  // Сейчас исхожу из предположения, что единственный случай, когда у поля несколько типов - если один из них null
  useEffect(() => {
    if (Array.isArray(data?.type)) {
      setTypeSimple(data?.type[0]);
      if (data?.type[1] === 'null') {
        setTypeNullableFlg(true);
      }
    } else if (typeof data?.type === 'object') {
      setTypeSimple('complex');
      setTypeComplex(data?.type);
      setTypeNullableFlg(undefined);
    } else {
      setTypeSimple(data?.type);
      if (data.type === 'record' || data.type === 'array') {
        setTypeNullableFlg(undefined);
      } else {
        setTypeNullableFlg(false);
      }
    }
  }, []);

  const onTypeChange = (type: any) => {
    if (primitiveTypes.findIndex((e) => e === type) !== -1) {
      if (typeNullableFlg === undefined) {
        setTypeNullableFlg(false);
      }
    } else {
      setTypeNullableFlg(undefined);
    }
    setTypeSimple(type);
  };

  // Рисовалка сложного типа
  useEffect(() => {
    if (typeSimple !== 'complex') {
      setTypeComplexDisplay(<></>);
    } else {
      let newElem = typeComplex;
      if (!newElem) {
        newElem = defaultElem;
      }
      setTypeComplexDisplay(
        <div className="avro-inner">
          <AvroElement
            editFlg={editFlg}
            data={newElem}
            onChange={onCustomTypeChange}
          ></AvroElement>
        </div>
      );
    }
  }, [typeSimple, typeComplex]);

  // Рисовалка списка полей для типа record
  useEffect(() => {
    if (typeSimple === 'record') {
      const fieldsArray = fields ? fields : [];
      const result = (
        <div>
          <AccordionNew
            className="avro-accordion"
            accordionKey="1"
            title="Fields"
            defaultOpen
          >
            {fieldsVisibleFlg && (
              <div className="avro-inner">
                {fieldsArray.map((e, idx) => {
                  return (
                    <div className="avro-button-container" key={idx}>
                      {editFlg && (
                        <Button
                          key={idx + '_b'}
                          size="small"
                          className="avro-button"
                          onClick={() =>
                            setFields(() => {
                              if (fields) {
                                fields.splice(idx, 1);
                                return [...fields];
                              } else {
                                return undefined;
                              }
                            })
                          }
                        >
                          -
                        </Button>
                      )}
                      <AvroElement
                        key={idx + '_e'}
                        data={e}
                        editFlg={editFlg}
                        onChange={(e) => onFieldChange(e, idx)}
                      ></AvroElement>
                    </div>
                  );
                })}
                {editFlg && (
                  <Button size="small" onClick={addField}>
                    +
                  </Button>
                )}
              </div>
            )}
          </AccordionNew>
        </div>
      );

      setFieldsDisplay(result);
    } else {
      setFieldsDisplay(<></>);
    }
  }, [typeSimple, fields, fieldsVisibleFlg]);

  // Рисовалка списка элементов для типа array
  useEffect(() => {
    if (typeSimple === 'array') {
      const innerElem = data?.items
        ? data.items
        : { name: 'new', type: 'string' };
      const result = (
        <div>
          <AccordionNew
            className="avro-accordion"
            accordionKey="1"
            title="Items"
            defaultOpen
          >
            {itemsVisibleFlg && (
              <div className="avro-inner">
                <AvroElement
                  editFlg={editFlg}
                  data={innerElem}
                  onChange={onItemsChange}
                ></AvroElement>
              </div>
            )}
          </AccordionNew>
        </div>
      );
      setItemsDisplay(result);
    } else {
      setItemsDisplay(<></>);
    }
  }, [typeSimple, itemsVisibleFlg]);

  // onChange triggers
  useEffect(() => {
    setEntireElem({ ...entireElem, name: name });
  }, [name]);

  useEffect(() => {
    setEntireElem({ ...entireElem, namespace: namespace });
  }, [namespace]);
  /*
  useEffect(() => {
    setEntireElem({ ...entireElem, fields: fields });
  }, [fields]);
*/
  useEffect(() => {
    let newtype: any;

    if (typeSimple !== 'complex') {
      if (
        primitiveTypes.findIndex((e) => e === typeSimple) !== -1 &&
        typeNullableFlg
      ) {
        newtype = [typeSimple, 'null'];
      } else {
        newtype = typeSimple;
      }
    } else {
      newtype = typeComplex;
    }
    setEntireElem({ ...entireElem, type: newtype });
  }, [typeSimple, typeComplex, typeNullableFlg]);

  const onFieldChange = (val: IAvroData, idx: number) => {
    if (fields) {
      const newFields = [...fields];
      newFields[idx] = { ...val };
      setFields(newFields);
    }
  };
  useEffect(() => {
    setEntireElem({ ...entireElem, fields: fields });
  }, [fields]);

  const addField = () => {
    if (fields) {
      setFields([...fields, defaultElem]);
    } else {
      setFields([defaultElem]);
    }
  };

  const onCustomTypeChange = (val: IAvroData) => {
    setEntireElem({ ...entireElem, type: val });
  };

  const onItemsChange = (val: IAvroData) => {
    setItems(val);
  };

  useEffect(() => {
    setEntireElem({ ...entireElem, items: items });
  }, [items]);

  const cleanObject = (obj: IAvroData) => {
    const newObj = { ...obj };
    if (newObj.fields === undefined) delete newObj.fields;
    if (typeSimple !== 'record') delete newObj.fields;
    if (newObj.namespace === undefined || newObj.namespace === '')
      delete newObj.namespace;
    if (newObj.name === undefined || newObj.name === '') delete newObj.name;
    if (newObj.items === undefined) delete newObj.items;
    if (typeSimple !== 'array') delete newObj.items;
    if (typeSimple === 'array') {
      delete newObj.name;
      delete newObj.namespace;
    }

    return newObj;
  };

  useEffect(() => {
    editFlg && onChange && onChange(cleanObject(entireElem));
  }, [entireElem]);

  const typeDisplay = (
    <div className="avro-button-container">
      <span className="ant-input-group-addon avro-select-before">Type</span>
      <Select
        disabled={!editFlg}
        className="avro-type-selector"
        options={typeOptions}
        defaultValue={data?.type}
        value={typeSimple}
        onChange={onTypeChange}
      ></Select>
      {typeNullableFlg !== undefined && (
        <div className="avro-switch-container">
          <Switch
            disabled={!editFlg}
            checked={typeNullableFlg}
            checkedChildren="Null"
            unCheckedChildren="Not Null"
            onChange={(e) => setTypeNullableFlg(e)}
          ></Switch>
        </div>
      )}
    </div>
  );

  // Namespace было решено не отображать
  return (
    <div className="avro-element-container">
      {typeSimple !== 'array' && (
        <div>
          <Input
            disabled={!editFlg}
            addonBefore="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {false && (editFlg || namespace) && (
            <Input
              disabled={!editFlg}
              addonBefore="Namespace"
              value={namespace}
              onChange={(e) => setNamespace(e.target.value)}
            />
          )}
        </div>
      )}
      {typeDisplay}
      {typeComplexDisplay}
      {fieldsDisplay}
      {itemsDisplay}
    </div>
  );
};
