import { IDataProviderUpdate } from '@modules/dataProvider';
import { useMemo } from 'react';
import { DataSource } from '../DataSources/interfaces';
import { Errors } from './interfaces';

export const useConnectDataSourceValidation = (
  values: IDataProviderUpdate,
  showErrors: boolean,
  data?: DataSource[],
  key?: string
) => {
  const validation = useMemo(() => {
    const errors: Errors = {};
    const notUnique = key
      ? data?.find(
          (item) =>
            key === item.key && item.dataSourceName !== values.sourceName
        )
      : data?.find((item) => item.dataSourceName === values.sourceName);

    !values.sourceName && (errors.sourceName = '');
    notUnique && (errors.sourceName = '');
    !values.connectionType && (errors.connectionType = '');
    !values.sourceType && (errors.sourceType = '');
    !values.username && (errors.username = '');
    !values.password && (errors.password = '');
    !values.serverName && (errors.serverName = '');
    !values.port && (errors.port = '');
    !values.additionalProperties && (errors.additionalProperties = '');

    return {
      isValid: !Object.values(errors).length,
      errors: showErrors ? errors : undefined,
      notUnique: notUnique,
      errorOnlyByUnique:
        Object.values(errors).length === 1 &&
        notUnique &&
        errors.sourceName === '',
    };
  }, [values, showErrors]);

  return validation;
};
