import React, { Key, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Menu, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import './AggregatesDataGrid.scss';

import { IDataTableList } from '@modules/development';
import { RootState } from '@modules/store';
import { useTranslationPath } from '@modules/languageProvider';
import { SimpleTable } from '@shared';
import { selectDataTypes } from '@modules/references';
import classNames from 'classnames';

const { Item } = Menu;

interface IAggregatesDataGrid {
  data: IAggregatesDataGridData[];
  isSingleChoice: boolean;
  selectedRow: (state: RootState) => Key[];
  onClickOpen: (data: IDataTableList) => void;
  onClickOpenViewingOnly: (data: IDataTableList) => void;
  onClickDelete: (data: IDataTableList) => void;
  onSelectedRowKeys: (keys: Key[]) => void;
  onDoubleClick: (data: IDataTableList) => void;
}

export interface IAggregatesDataGridData {
  key: string;
  aggregateName: string;
  aggregateDescription?: string;
  aggregateFunction?: string;
  aggregateVariableType?: string;
  groupingElement?: string;
  aggregateCalcByIdDiagram?: string;
  aggregateReadByIdDiagram?: string;
}

export const AggregatesDataGrid: React.FC<IAggregatesDataGrid> = ({
  data,
  isSingleChoice,
  selectedRow,
  onClickOpen,
  onClickOpenViewingOnly,
  onClickDelete,
  onSelectedRowKeys,
  onDoubleClick,
}) => {
  const { t } = useTranslation();
  const translate = useTranslationPath('development.aggregates.dataGrid');
  const selectedRowTable = useSelector(selectedRow);
  const dataTypes = useSelector(selectDataTypes);

  const menu = (data: IDataTableList) => (
    <Menu>
      <Item
        key="menu-item-dropdown_0"
        onClick={() => onContextMenuItemClick(data, 'open')}
      >
        {t('development.contextMenu.open')}
      </Item>
      <Item
        key="menu-item-dropdown_1"
        onClick={() => onContextMenuItemClick(data, 'openOnlyView')}
      >
        {t('development.contextMenu.openOnlyView')}
      </Item>
      <Item
        key="menu-item-dropdown_2"
        onClick={() => onContextMenuItemClick(data, 'delete')}
      >
        {t('development.contextMenu.delete')}
      </Item>
    </Menu>
  );

  const onContextMenuItemClick: any = (
    data: IDataTableList,
    command: string
  ) => {
    switch (command) {
      case 'open':
        onClickOpen(data);
        break;
      case 'openOnlyView':
        onClickOpenViewingOnly(data);
        break;
      case 'delete':
        onClickDelete(data);
        onSelectedRowKeys([]);
        break;
    }
  };

  const renderCell = (value: any, data: any, className?: string) => {
    return (
      <>
        <Dropdown
          className="aggregates-data-grid__context"
          overlay={menu(data)}
          trigger={[`contextMenu`]}
        >
          <Tooltip title={value}>
            <div
              className={classNames(className, 'aggregates-data-grid__cell')}
              onClick={(e) => e.stopPropagation()}
            >
              {value}
            </div>
          </Tooltip>
        </Dropdown>
      </>
    );
  };

  const sorterCellString = (a: any, b: any, c: any) => {
    const safeA = a?.[c]?.toLowerCase() || 'Z';
    const safeB = b?.[c]?.toLowerCase() || 'Z';
    return safeA.localeCompare(safeB);
  };
  const sorterCellNumber = (a: any, b: any, c: any) => a[c] - b[c];

  const columnsData = useMemo(() => {
    return [
      {
        dataIndex: 'aggregateName',
        title: translate('aggregateName'),
        ellipsis: true,
        render: renderCell,
        sorter: (a: any, b: any) => sorterCellString(a, b, 'aggregateName'),
      },
      {
        dataIndex: 'aggregateDescription',
        title: translate('aggregateDescription'),
        ellipsis: true,
        render: renderCell,
        sorter: (a: any, b: any) =>
          sorterCellString(a, b, 'aggregateDescription'),
      },
      {
        dataIndex: 'aggregateFunction',
        title: translate('aggregateFunction'),
        ellipsis: true,
        render: renderCell,
        sorter: (a: any, b: any) => sorterCellString(a, b, 'aggregateFunction'),
      },
      {
        dataIndex: 'aggregateVariableType',
        title: translate('aggregateVariableType'),
        ellipsis: true,
        render: (value: any, data: any) => {
          const dataType = dataTypes.find(
            (dt) => dt.typeId === value.toString()
          );
          return renderCell(dataType?.displayName, data);
        },
        sorter: (a: any, b: any) =>
          sorterCellNumber(a, b, 'aggregateVariableType'),
      },
      {
        dataIndex: 'groupingElement',
        title: translate('groupingElement'),
        ellipsis: true,
        render: renderCell,
        sorter: (a: any, b: any) => sorterCellString(a, b, 'groupingElement'),
      },
      {
        dataIndex: 'diagramCalcName',
        title: translate('aggregateCalcByNameDiagram'),
        ellipsis: true,
        render: (value: any, data: any) => renderCell(value, data, 'blue'),
        sorter: (a: any, b: any) => sorterCellString(a, b, 'diagramCalcName'),
      },
      {
        dataIndex: 'diagramReadName',
        title: translate('aggregateReadByNameDiagram'),
        ellipsis: true,
        render: (value: any, data: any) => renderCell(value, data, 'blue'),
        sorter: (a: any, b: any) => sorterCellString(a, b, 'diagramReadName'),
      },
    ].map((item) => ({
      ...item,
      showSorterTooltip: { title: item.title },
    }));
  }, [translate]);

  return (
    <div className="aggregates-data-grid">
      <SimpleTable
        columnsData={columnsData}
        dataSource={data}
        selectedRowKeys={selectedRowTable}
        rowClassName={(record) => record.disabled && 'disabled-row'}
        setSelectedRowKeys={onSelectedRowKeys}
        onDoubleClick={onDoubleClick}
        isSingleChoice={isSingleChoice}
        hideSelectAll
      />
    </div>
  );
};
