/* eslint-disable require-jsdoc */
import React from 'react';
import ReactDOM from 'react-dom';

interface IContentEditable
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  html: string;
  onInnerDataChanged: (value: string) => void;
}

export class ContentEditable extends React.Component<IContentEditable> {
  private lastHtml: string = '';
  render() {
    return (
      <div
        onInput={this.emitChange.bind(this)}
        onBlur={this.emitChange.bind(this)}
        onDrop={this.emitChange.bind(this)}
        contentEditable
        dangerouslySetInnerHTML={{ __html: this.props.html }}
        {...this.props}
      ></div>
    );
  }

  shouldComponentUpdate(nextProps: IContentEditable): boolean {
    return nextProps.html !== (ReactDOM.findDOMNode(this) as Element).innerHTML;
  }

  emitChange() {
    const html = (ReactDOM.findDOMNode(this) as Element).innerHTML;
    if (this.props.onInnerDataChanged && html !== this.lastHtml) {
      this.props.onInnerDataChanged(html);
    }
    this.lastHtml = html;
  }
}
