import React, { VFC } from 'react';
import classNames from 'classnames';
import type { Message } from '../interfaces';

interface IConnectDataSourceMessage {
  message: Message;
}

export const ConnectDataSourceMessage: VFC<IConnectDataSourceMessage> = ({
  message,
}) => {
  return (
    message && (
      <div
        className={classNames(
          'connect-data-source-modal__status',
          message.status === 'error' &&
            'connect-data-source-modal__status--error',
          message.status === 'success' &&
            'connect-data-source-modal__status--success'
        )}
      >
        <span>{message.text}</span>
      </div>
    )
  );
};
