import React from 'react';
import { IModalProps } from '@modules/development';
import { Button, IConfirmModalProps, Modal } from '@modules/shared';
import { useTranslation } from 'react-i18next';

interface SuccessModalProps {
  successModalProps: IModalProps | null;
  setSuccessModalProps: React.Dispatch<
    React.SetStateAction<IModalProps | null>
  >;
  setModalProps: React.Dispatch<
    React.SetStateAction<IConfirmModalProps | IModalProps | null>
  >;
}

export const StreamsListSuccessModal: React.FC<SuccessModalProps> = ({
  successModalProps,
  setModalProps,
  setSuccessModalProps,
}) => {
  const { t } = useTranslation();

  const successModalBody = () => {
    const isExport = successModalProps?.type === 'successExport';
    const modalText = isExport
      ? t('modals.successExport.text', {
          count: successModalProps?.data?.numberOfExportedObjects,
          totalCount: successModalProps?.data?.totalNumberOfObjects,
        })
      : t('modals.successImport.text', {
          count: successModalProps?.data?.numberOfImportedObjects,
          totalCount: successModalProps?.data?.totalNumberOfObjects,
        });

    const type = isExport ? 'exportInfo' : 'importInfo';

    const data = isExport
      ? successModalProps?.data?.exportStatus
      : successModalProps?.data?.importStatus?.objectsInfo;

    return (
      <div
        className="modal__child"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {modalText}
        <Button
          style={{
            color: '#0048B2',
            textDecoration: 'underline',
            display: 'flex',
          }}
          kind="transparent"
          onClick={() => {
            setModalProps({
              type: type,
              data: data,
            });
            setSuccessModalProps(null);
          }}
        >
          {t('modals.successExport.link')}
        </Button>
      </div>
    );
  };

  const successModalFooter = () => {
    return (
      <Button
        kind="normal"
        onClick={() => setSuccessModalProps(null)}
        style={{ display: 'inline' }}
      >
        OK
      </Button>
    );
  };

  return (
    <Modal
      isOn={!!successModalProps}
      onClose={() => setSuccessModalProps(null)}
      modalProps={{
        ...successModalProps,
        icon: 'icon-done',
        footer: successModalFooter(),
      }}
    >
      {successModalBody()}
    </Modal>
  );
};
