import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './TabPanel.scss';
import { Icon } from '@modules/icon';

export interface ITabPanelItem {
  key: string;
  text: string;
  icon?: string;
  padding?: number;
  path?: string;
  translation?: string;
  type?: string;
}

export interface ITabPanel {
  items: ITabPanelItem[];
  level: number;
  type?: string;
  active: string;
  disabledKeys?: string[];
  className?: string;
  onClick: (e: any, key: string, type: string, path?: string) => void;
  onClickClose?: (key: string) => void;
  textAlignCenter?: boolean;
}

const TabPanel: React.FC<ITabPanel> = (props) => {
  const {
    items,
    level,
    type,
    active,
    disabledKeys,
    className,
    onClick,
    onClickClose,
    textAlignCenter,
  } = props;
  const { t } = useTranslation();

  return (
    <div
      className={classNames('tabs', className)}
      style={{ justifyContent: textAlignCenter ? 'center' : '' }}
    >
      {items.map((data: any, index: number) => (
        <div
          className={classNames(
            'tab-links',
            data.key === active && 'active',
            disabledKeys?.includes(data.key) && 'disabled',
            `level-${level}`
          )}
          key={index}
          data-index={data.key}
          onClick={(e) => onClick(e, data.key, data.type)}
        >
          <div
            className="tab-links__title"
            style={{ paddingRight: data.padding ? `${data.padding}px` : '' }}
          >
            {data.icon && <Icon name={data.icon} />}
            <p>{data.translation ? t(data.translation) : data.text}</p>
          </div>
          {type === 'development' && (
            <Icon
              className="tab-links__close"
              name="icon-close"
              onClick={onClickClose ? () => onClickClose(data.key) : undefined}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export { TabPanel };
