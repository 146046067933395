/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification } from './types/interface';

const NOTIFICATION_FEATURE_KEY = 'notification';

export interface INotificationState {
  notifications: { [key: string]: INotification };
}

const initialState: INotificationState = {
  notifications: {},
};

export const notificationSlice = createSlice({
  name: NOTIFICATION_FEATURE_KEY,
  initialState,
  reducers: {
    addNotification: (
      state,
      action: PayloadAction<{ key: string; notification: INotification }>
    ) => {
      const { key, notification } = action.payload;
      state.notifications = { ...state.notifications, [key]: notification };
    },
    deleteNotification: (state, action: PayloadAction<string>) => {
      delete state.notifications[action.payload];
    },
    clearNotifications: (state, action: PayloadAction) => {
      state.notifications = {};
    },
  },
});

export const notificationActions = {
  ...notificationSlice.actions,
};

export default notificationSlice.reducer;
