import { FC, Key, useEffect, useState } from 'react';
import { Button, Portal, SimpleTable, useSearch } from '@shared';
import { Icon } from '@modules/icon';
import { useTranslationPath } from '@modules/languageProvider';
import { EditComplexTypeModal } from './EditComplexTypeModal';
import {
  onChangeCustomDataTypesSearch,
  removeOpenModal,
} from '@modules/development/DevelopmentSlice';
import { getComplexTypes } from '@modules/complexType';
import './ComplexTypes.scss';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { selectRoles, rolesList } from '@modules/services';
import { ColumnsType } from 'antd/es/table';
import { selectCustomDataTypesFilteredData } from '../../reducers';

type UpdateType = {
  typeId: string;
  versionId: string;
};

type DataSourceRowType = UpdateType & {
  key: string;
  typeName: string;
  user: string;
};

interface IComplexTypes {
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
  addNewRequested?: boolean;
  hideSelectAll?: boolean;
  onCancelAdd?: () => void;
  onTypeUpdated?: () => void;
  search?: string;
}

export const ComplexTypes: FC<IComplexTypes> = ({
  selectedRowKeys,
  setSelectedRowKeys,
  addNewRequested,
  hideSelectAll,
  onCancelAdd,
  onTypeUpdated,
  search,
}) => {
  const dispatch = useAppDispatch();
  const translate = useTranslationPath('development.complexTypes');
  const translateActions = useTranslationPath('actions');

  const data = useAppSelector(selectCustomDataTypesFilteredData);
  const roles = useAppSelector(selectRoles);

  const [updateType, setUpdateType] = useState<UpdateType | null>(null);

  const onSearch = (search: string) => {
    dispatch(onChangeCustomDataTypesSearch({ search }));
  };

  const [renderSearchInput, getRefProps] = useSearch({
    search: String(search),
    setSearch: onSearch,
  });

  useEffect(() => {
    dispatch(getComplexTypes());
    return () => onSearch('');
  }, []);

  const customTypes = data.map((ct) => ({ ...ct, key: ct.versionId }));

  const typeColumns: ColumnsType<DataSourceRowType> = [
    {
      dataIndex: 'typeName',
      key: 'typeName',
      title: translate('typeNameColumn'),
      sorter: (a, b) => a.typeName.localeCompare(b.typeName),
      render: (typeName: string, { typeId, versionId }) => {
        return (
          <div className="data-types__cell-edit">
            <span>{typeName}</span>
            <Button
              kind="icon"
              tooltip={translateActions('edit')}
              onClick={() => setUpdateType({ typeId, versionId })}
              children={<Icon name="icon-edit" />}
            />
          </div>
        );
      },
    },
    {
      dataIndex: 'user',
      key: 'user',
      title: translate('userColumn'),
      sorter: (a, b) => a.user.localeCompare(b.user),
    },
  ];

  return (
    <div {...getRefProps()}>
      <Portal id="toolbarSearch">{renderSearchInput()}</Portal>
      <SimpleTable
        className="complex-types__table"
        columnsData={typeColumns}
        dataSource={customTypes}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        hideSelectAll={hideSelectAll || true}
        onDoubleClick={(data: any) => {
          roles.includes(rolesList.COMPLEX_TYPE_READ) &&
            setUpdateType({ typeId: data.typeId, versionId: data.versionId });
        }}
      />
      {(addNewRequested || Boolean(updateType)) && (
        <EditComplexTypeModal
          modalProps={{
            title: translate('editType'),
            type: 'editCustomDataType',
          }}
          complexTypeId={updateType}
          onClose={() => {
            dispatch(removeOpenModal({}));
            onCancelAdd && onCancelAdd();
            setUpdateType(null);
          }}
          closeOnSave
          onChangesSaved={onTypeUpdated}
        />
      )}
    </div>
  );
};
