import { usePrevious } from '@shared';

export const useDiagramHasChanged = (val: any) => {
  const diagramBody = {
    diagramKey: val?.key,
    diagramName: val?.diagramName,
    diagramDescription: val?.diagramDescription,
    diagramJson: {
      inputVariables: val?.diagramJson?.inputVariables,
      outputVariables: val?.diagramJson?.outputVariables,
      startNodes: val?.diagramJson?.startNodes,
      nodes: val?.diagramJson?.nodes,
      innerElements: val?.diagramJson?.innerElements,
    },
    diagramTesting: val?.meta?.diagramTesting,
  };
  const prevVal = usePrevious(diagramBody);
  // @ts-ignore
  if (!prevVal?.diagramKey || prevVal?.diagramKey !== val?.key) {
    return false;
  }
  return JSON.stringify(prevVal) !== JSON.stringify(diagramBody);
};
