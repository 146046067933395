import React from 'react';
import { useConnectionsFormSettings } from '@modules/connections/hooks';
import { rawExternalServiceFields } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/components/externalServiceCommonSettings/rawFormFields';
import { FieldGroup } from '@shared';
import './ExternalServiceCommonSettings.scss';

export const ExternalServiceCommonSettings: React.FC = () => {
  const { fieldsData } = useConnectionsFormSettings(rawExternalServiceFields);
  return (
    <FieldGroup
      data={fieldsData}
      className="connections__common-settings"
      labelWidth="250px"
    />
  );
};
