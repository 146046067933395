import { useContext, useMemo, VFC } from 'react';
import { Avatar, Badge } from 'antd';
import { DropdownMenu } from '@shared';
import { useAppSelector } from '@modules/store';
import { selectUserName } from '@modules/services';
import { LanguageContext, useTranslationPath } from '@modules/languageProvider';

// @ts-ignore
const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

const mockUser = {
  ru: { name: 'Иван Петров', role: 'Администратор' },
  en: { name: 'Rayan Evans', role: 'Administrator' },
};

interface IUser {
  className: string;
  onClick: () => void;
}

export const User: VFC<IUser> = ({ className, onClick }) => {
  const translation = useTranslationPath('layout');

  const { language } = useContext(LanguageContext);
  const { name, role } = mockUser[language];

  const userName = useAppSelector(selectUserName);

  const initials = useMemo(() => {
    const names = userName.split(' ');
    if (names.length === 1) return `${names[0][0]}`;
    return `${names[0][0]}${names[1][0]}`;
  }, [userName]);

  return (
    <DropdownMenu
      id="user"
      items={[{ title: translation('logOut'), danger: true, onClick }]}
    >
      <div className={className}>
        <div className="user">
          <div className="user__text">
            <p className="user__name">{userName ? userName : name}</p>
            {HIDE_FEATURES ? null : <p className="user__role">{role}</p>}
          </div>
          <Badge color={['#EA5455', '#28C76F'][1]} dot>
            <Avatar
              style={{ color: '#FFFFFF', backgroundColor: '#B9B3F8' }}
              size={38}
              children={initials}
            />
          </Badge>
        </div>
      </div>
    </DropdownMenu>
  );
};
