import { Key } from 'react';
import {
  IObjects,
  IDiagram,
  SelectedNodes,
  CopiedNodes,
} from '@modules/development';
import { IAction } from '@modules/store';
import { IDevelopmentState } from '../../DevelopmentSlice';

const reducer = {
  diagramsSeeds(state: IDevelopmentState, action: IAction) {
    const { diagrams } = action.payload;
    state.diagrams.data = diagrams;
  },

  diagramsUpdate(state: IDevelopmentState, action: IAction) {
    const { diagramKey, title } = action.payload;

    const existingDiagram = state.diagrams.data.find(
      (diagram: { key: string }) => diagram.key === diagramKey
    );
    if (existingDiagram) {
      existingDiagram.diagramName = title;
    }
  },

  removeDiagram(state: IDevelopmentState, action: IAction<{ key: Key }>) {
    const { key } = action.payload;
    state.diagrams.data = state.diagrams.data.filter(
      (item) => item.key !== key
    );
  },

  setCopyNodes(state: IDevelopmentState, action: IAction<CopiedNodes>) {
    state.diagrams.copiedNodes = action.payload;
  },

  setSelectedNodes(
    state: IDevelopmentState,
    action: IAction<{ selectedNodes: SelectedNodes }>
  ) {
    const { selectedNodes } = action.payload;
    state.diagrams.selectedNodes = selectedNodes;
  },

  onSelectedDiagramsRow(
    state: IDevelopmentState,
    action: IAction<{ keys: Key[] }>
  ) {
    const { keys } = action.payload;
    state.diagrams.selectedRowTable = keys;
  },

  onChangeDiagramsSearch(
    state: IDevelopmentState,
    action: IAction<{ search: string }>
  ) {
    const { search } = action.payload;
    state.diagrams.search = search;
  },
  onChangeDiagramsSort(
    state: IDevelopmentState,
    action: IAction<Pick<IObjects<IDiagram>, 'sort'>>
  ) {
    const { sort } = action.payload;
    state.diagrams.sort = sort;
  },
};

export default reducer;
