import React, { Dispatch, Key, SetStateAction, VFC } from 'react';
import { InputSearch, Toolbar } from '@shared';
import { ServiceData } from '../interfaces';
import { useAppSelector } from '@modules/store';
import { selectRoles, rolesList } from '@modules/services';

type ExtServAccessToolbarProps = {
  data: ServiceData[];
  keys: Key[];
  setKeys: Dispatch<SetStateAction<Key[]>>;
  translate: (attrName: any, options?: any) => any;
  getInputProps: any;
  onAdd: () => void;
  onDelete: () => void;
  onRefresh: () => void;
};

export const ExtServAccessToolbar: VFC<ExtServAccessToolbarProps> = ({
  data,
  keys,
  setKeys,
  translate,
  getInputProps,
  onAdd,
  onDelete,
  onRefresh,
}) => {
  const roles = useAppSelector(selectRoles);

  return (
    <Toolbar
      data={[
        { isDivider: true },
        {
          icon: 'icon-add',
          tooltip: translate('add'),
          onClick: onAdd,
          disabled: !roles.includes(rolesList.EXT_SERVICE_CREATE),
        },
        {
          icon: 'icon-delete',
          tooltip: translate('delete'),
          disabled:
            !keys.length || !roles.includes(rolesList.EXT_SERVICE_DELETE),
          onClick: onDelete,
        },
        {
          icon: 'icon-refresh',
          tooltip: translate('refresh'),
          onClick: onRefresh,
        },
      ]}
      isShowCheckboxAll
      CheckboxAllIndeterminate={
        keys.length !== data.length && keys.length !== 0
      }
      CheckboxAllChecked={keys.length === data.length}
      onCheckboxAllChange={() => {
        setKeys(
          keys.length === data.length ? [] : data.map((item) => item.key)
        );
      }}
      search={<InputSearch {...getInputProps()} width={200} />}
    />
  );
};
