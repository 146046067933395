import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  ITypeMapData,
  IDataVariables,
  NodeAddedVariables,
} from '@modules/development';
import {
  IComplexTypeWithAttributes,
  IComplexTypeAttributeExt,
  selectLastLoadedComplexTypeMap,
} from '@modules/complexType';
import { selectDataTypes } from '@modules/references';

export const useDiagramVariablesTypeMapData = (
  diagramVariables: IDataVariables[] | undefined,
  addedVariables?: NodeAddedVariables | undefined
) => {
  const complexTypeMap = useSelector(selectLastLoadedComplexTypeMap);
  const dataTypes = useSelector(selectDataTypes);

  const addedVariablesMap = useMemo<NodeAddedVariables>(() => {
    if (!addedVariables) return {};

    const newAddedVariables: NodeAddedVariables = {};

    Object.entries(addedVariables).forEach(([rootId, variables]) => {
      variables.forEach((variable) => {
        const pathMap = variable.variablePath?.split('.') as string[];

        if (pathMap) {
          const parentName =
            pathMap.length === 1
              ? complexTypeMap?.[rootId].displayName
              : pathMap[pathMap.length - 1];
          const parentId =
            parentName === complexTypeMap?.[rootId].displayName
              ? complexTypeMap?.[rootId].typeId
              : complexTypeMap?.[rootId].attributes.find(
                  (attr) => attr.attributeName === parentName
                )?.complexTypeVersionId;

          if (parentId) {
            newAddedVariables[parentId] = newAddedVariables[parentId]
              ? [...newAddedVariables[parentId], variable]
              : [variable];
          }
        } else {
          newAddedVariables[''] = newAddedVariables['']
            ? [...newAddedVariables[''], variable]
            : [variable];
        }
      });
    });

    return newAddedVariables;
  }, [addedVariables]);

  const getInnerAttributes = useCallback(
    (rootId: string) => {
      const innerVariables: ITypeMapData[] = (
        addedVariablesMap?.[rootId] || []
      ).map((v) => ({
        typeId: v.typeId,
        name: v.variableName,
        typeName:
          getVarType(v.typeId).complexType?.displayName ||
          getVarType(v.typeId).primitiveType?.displayName ||
          '',
        isComplex: v.isComplex,
        isArray: v.isArray,
      }));

      return innerVariables;
    },
    [addedVariables]
  );

  const getVarType = useCallback(
    (typeId) => {
      const dataType = dataTypes?.find((dt) => dt.typeId === typeId);
      const complexType: IComplexTypeWithAttributes | undefined =
        dataType?.complexFlag ? complexTypeMap?.[typeId] : undefined;
      return {
        primitiveType: dataType,
        complexType: complexType,
      };
    },
    [complexTypeMap, dataTypes]
  );

  const getAttributes = useCallback(
    (attributes: IComplexTypeAttributeExt[] | undefined) => {
      const res: ITypeMapData[] =
        attributes?.map((a) => {
          const currTypeId =
            (a.complexFlag ? a.complexTypeVersionId : a.primitiveTypeId) || '';

          const varType = getVarType(currTypeId);

          return {
            typeId: currTypeId,
            name: a.attributeName,
            typeName:
              varType.complexType?.displayName ||
              varType.primitiveType?.displayName ||
              '',
            isComplex: Boolean(a.complexFlag),
            isArray: Boolean(a.arrayFlag),
            attributes: getAttributes(
              varType.complexType && [...(varType.complexType.attributes || [])]
            ).concat(getInnerAttributes(currTypeId)),
          };
        }) || [];

      return res;
    },
    [getVarType, getInnerAttributes]
  );

  const mapTypes = useMemo<ITypeMapData[] | undefined>(() => {
    const res = (diagramVariables || []).map((variable) => {
      const varType = getVarType(variable.typeId);

      return {
        variableId: variable.variableId,
        name: variable.variableName,
        typeId: variable.typeId,
        typeName:
          varType.complexType?.displayName ||
          varType.primitiveType?.displayName ||
          '',
        isComplex: Boolean(varType.primitiveType?.complexFlag),
        isArray: variable.isArray,
        attributes: getAttributes(
          varType.complexType && [...(varType.complexType.attributes || [])]
        ).concat(getInnerAttributes(variable.typeId)),
      };
    });
    return [...res, ...getInnerAttributes('')];
  }, [diagramVariables, getAttributes, getVarType, getInnerAttributes]);

  return mapTypes;
};
