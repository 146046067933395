import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useTranslationPath = (path: string) => {
  const { t } = useTranslation();
  return useCallback(
    (attrName, options?: any) => t(`${path}.${attrName}`, options),
    [path, t]
  );
};
