import React, { useState, useEffect } from 'react';
import { Divider, Tag } from 'antd';
import './selectedFilters.scss';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@modules/shared';

// export interface ITableOptions {
//   page?: number;
//   limit?: number;
//   search?: string;
//   showDeleted?: boolean;
//   status?:
//     | 'DRAFT'
//     | 'READY'
//     | 'SCHEDULED'
//     | 'RUNNING'
//     | 'FAILED'
//     | 'DELETED'
//     | undefined;
//   createdAfter?: string | undefined;
//   createdBefore?: string | undefined;
//   updatedAfter?: string | undefined;
//   updatedBefore?: string | undefined;
//   executedAfter?: string | undefined;
//   executedBefore?: string | undefined;
//   // [key: string]: boolean | number | string | undefined | object | null;
// }

export interface ITableOptions {
  deployStatus: string | null | string[];
  changeDt: string | null | string[];
}

type filters = {
  text: string;
  value: string;
  color: string;
}[];

interface SelectedFiltersProps {
  filters: filters;
  tableParams: ITableOptions;
  onClose: any;
  onClearFilters: any;
  disabled?: boolean;
}

const closeButtonStyle = {
  width: '10px',
  fontSize: '14px',
  color: '#868FA0',
};

const nameFilterStyle = {
  borderRadius: '4px',
  color: '#0048B2',
};
const tagBackgrounStule = {
  borderRadius: '4px',
  color: 'black',
};

const SelectedFilters: React.FC<SelectedFiltersProps> = ({
  filters,
  tableParams,
  onClose,
  onClearFilters,
  disabled,
}) => {
  const { t } = useTranslation();
  const [filtersCount, setfiltersCount] = useState<number>(0);

  useEffect(() => {
    const values = Object.values(tableParams);
    setfiltersCount(values.filter((item) => item).length);
    // const keyArray = Object.keys(tableParams);
    // setfiltersCount(
    //   keyArray.filter(
    //     (item) =>
    //       item.indexOf('After') === -1 &&
    //       item !== 'sortBy' &&
    //       item !== 'orderBy' &&
    //       item !== 'limit'
    //   ).length
    // );
  }, [tableParams]);

  if (filtersCount < 1 || disabled) {
    return <></>;
  } else {
    return (
      <>
        <div style={{ marginTop: '4px' }}>
          {t('filters.filter') + filtersCount}
          <Divider
            className="toolbar__divider"
            type="vertical"
            key={'tooltip'}
          />
          <Button
            style={{ color: '#0048B2' }}
            kind="transparent"
            onClick={onClearFilters}
          >
            {t('filters.clear')}
          </Button>
        </div>

        <div>
          {tableParams.deployStatus ? (
            <Tag
              style={tagBackgrounStule}
              color="#E5EFFF"
              closeIcon={<CloseOutlined style={closeButtonStyle} />}
              closable
              onClose={() => {
                onClose('deployStatus');
                setfiltersCount(filtersCount - 1);
              }}
            >
              <span className="name-of-filters">{t('filters.status')}</span>
              {
                filters.find(
                  (status): boolean =>
                    status.value ===
                    (typeof tableParams.deployStatus === 'string'
                      ? tableParams.deployStatus
                      : '')
                )?.text
              }
            </Tag>
          ) : (
            ''
          )}
          {tableParams.changeDt ? (
            <Tag
              style={tagBackgrounStule}
              color="#E5EFFF"
              closable
              closeIcon={<CloseOutlined style={closeButtonStyle} />}
              onClose={() => {
                onClose('changeDt');
                setfiltersCount(filtersCount - 1);
              }}
            >
              <span style={nameFilterStyle}>{t('filters.changeDt')}</span>
              {tableParams.changeDt[0]}
              <RightOutlined
                style={{ marginLeft: '0px', color: 'rgb(134, 143, 160)' }}
              />
              {tableParams.changeDt[1]}
            </Tag>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
};

export default SelectedFilters;
