import React, { FC, Key, Dispatch, SetStateAction } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import './solutionListsNew.scss';
import {
  IToolbarItem,
  Toolbar,
  ConfirmModal,
  IConfirmModalProps,
} from '@shared';
import { useAppDispatch } from '@modules/store';

interface ISolutionListsNew {
  title: string;
  rowKeys: Key[];
  selectedRow: Key[];
  onSelectedRowChange: ActionCreatorWithPayload<any, string>;
  toolbarData: IToolbarItem[];
  modalProps: IConfirmModalProps | null;
  onDeleteClick: () => void;
  setModalProps: Dispatch<SetStateAction<IConfirmModalProps | null>>;
}

export const SolutionListsNew: FC<ISolutionListsNew> = ({
  children,
  title,
  rowKeys,
  selectedRow,
  onSelectedRowChange,
  toolbarData,
  modalProps,
  onDeleteClick,
  setModalProps,
}) => {
  const dispatch = useAppDispatch();

  const onToolbarCheckboxAllChange = () => {
    const keys = selectedRow.length === rowKeys.length ? [] : rowKeys;
    dispatch(onSelectedRowChange({ keys }));
  };

  return (
    <>
      <div className="solution-lists">
        <div className="solution-lists__top">
          <h1 className="solution-lists__title">{title}</h1>
        </div>
        <div className="solution-lists__row">
          <Toolbar
            data={toolbarData}
            isShowCheckboxAll
            CheckboxAllIndeterminate={
              selectedRow.length !== rowKeys.length && selectedRow.length !== 0
            }
            CheckboxAllChecked={selectedRow.length === rowKeys.length}
            onCheckboxAllChange={onToolbarCheckboxAllChange}
          />
        </div>
        <div className="solution-lists__content">{children}</div>
      </div>

      {modalProps?.type === 'confirmDelete' && (
        <ConfirmModal
          modalProps={modalProps}
          onOk={onDeleteClick}
          onClose={() => setModalProps(null)}
        />
      )}
    </>
  );
};
