import React, { useEffect } from 'react';
import './administration.scss';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { fetchServices, selectRoles, rolesList } from '@modules/services';
import { getDataTypes } from '@modules/references';
import { Navigation } from './Navigation';

interface IAdministration {}

export const Administration: React.FC<IAdministration> = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectRoles);

  useEffect(() => {
    roles.includes(rolesList.DICTIONARY_READ_ALL_DATA_TYPES) &&
      dispatch(getDataTypes());
    // dispatch(fetchServices());
  }, []);

  return (
    <div className="administration-page">
      <div className="content">
        <Navigation roles={roles} />
      </div>
    </div>
  );
};
