import { IDiagramNode } from '@modules/development';

export enum OperatorTypes {
  Equal = 'EQUAL',
  Greater = 'GREATER',
  Less = 'LESS',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  LessOrEqual = 'LESS_OR_EQUAL',
  Interval = 'INTERVAL',
}

export type BranchTableData =
  | Branch
  | {
      defaultValue: boolean;
      selectable: boolean;
      path: string;
    };

export type Branch = {
  operator: OperatorTypes;
  valueFrom: string;
  valueTo: string | null;
  valueFromIncludeFlag: boolean | null;
  valueToIncludeFlag: boolean | null;
  path: string;
};

export enum BranchTypes {
  BranchByElement = 'BRANCH_BY_ELEMENT',
  BranchByPercent = 'BRANCH_BY_PERCENT',
  BranchByCalculateCondition = 'BRANCH_BY_CALCULATE_CONDITION',
}

export interface IBranchProperties {
  branchingType: BranchTypes;
  defaultPath: string;
  condition: string;
  branches: Branch[];
}

export type BranchNode = IDiagramNode<IBranchProperties>;

export enum ModalTypes {
  exprEditor = 'exprEditor',
  chooseBlock = 'chooseBlock',
  addElem = 'addElem',
  confirmModal = 'confirmModal',
}

export type OpenedModal =
  | {
      type: ModalTypes.addElem;
      condition: string;
    }
  | {
      type: ModalTypes.exprEditor;
      title?: string;
      expression: string;
    }
  | {
      type: ModalTypes.chooseBlock;
      title?: string;
      path?: string;
      isDefaultPath: boolean;
      index: number;
    }
  | {
      type: ModalTypes.confirmModal;
      contentText: string;
    }
  | null;

export type InvalidData = {
  inputs: { [key: string]: string };
  branches: { [key: string]: { [key: string]: string } };
};
