import React, { Suspense } from 'react';
import Layout from '@modules/layout';
import { Loader } from '@shared';
import { ErrorBoundary } from '@modules/ErrorBoundary';
import { NotificationProvider } from '@modules/notification';
import { MainRouting } from '../../routing/components/MainRouting';

export const MainLayout = () => {
  return (
    <Layout>
      <div className="app">
        <Suspense fallback={<Loader />}>
          <ErrorBoundary>
            <NotificationProvider>
              <MainRouting />
            </NotificationProvider>
          </ErrorBoundary>
        </Suspense>
      </div>
    </Layout>
  );
};
