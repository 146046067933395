import { FC, useEffect, useMemo, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { IDiagramNodeBase, IModalProps } from '@modules/development';
import {
  Button,
  Input,
  InputWithButton,
  Modal,
  ModalBox,
  SimpleTable,
  TextArea,
  useModalVisible,
} from '@shared';
import { Icon } from '@modules/icon';
import { useTranslationPath } from '@modules/languageProvider';
import { Dropdown, Menu, Radio } from 'antd';
import './DecisionTableModal.scss';
// import { selectCurrentObjectReadonly } from '@pages/DevelopmentPage/DevelopmentPage.slice';
import { useDiagramBlockData } from '../common/hooks/useCurrentBlock';

const Item = Menu.Item;

interface IDecisionTableData extends IDiagramNodeBase<any> {}

export interface IDecisionTableModal {
  onClose: () => void;
  blockCode: string;
  closeOnSave?: boolean;
  modalProps: IModalProps | null;
}

export const DecisionTableModal: FC<IDecisionTableModal> = ({
  onClose,
  blockCode,
  // closeOnSave,
  modalProps,
}) => {
  // const dispatch = useDispatch();
  const translate = useTranslationPath(
    'development.diagram.diagram.modals.decisionMakingTable'
  );

  const isVisible = useModalVisible(modalProps?.type);
  // const isReadonly = useSelector(selectCurrentObjectReadonly);
  const diagramDecisionTableData =
    useDiagramBlockData<IDecisionTableData>(blockCode);
  const [decisionTableData, setDecisionTableData] = useState<
    IDecisionTableData | undefined
  >(diagramDecisionTableData);

  useEffect(() => {
    setDecisionTableData(diagramDecisionTableData);
  }, [diagramDecisionTableData]);

  const [tableRadio, setTableRadio] = useState<string>('decisionTable');

  const outpitTableColumns = useMemo(
    () => [
      {
        title: translate('name'),
        dataIndex: 'name',
        key: 'name',
        render: (value: string) => {
          return (
            <div className="decision-table__table-edit-cell">
              <span>{value}</span>
              <Button kind="transparent">
                <Icon name="icon-edit" />
              </Button>
            </div>
          );
        },
      },
      { title: translate('type'), dataIndex: 'type', key: 'type' },
      {
        title: translate('defaultValue'),
        dataIndex: 'defaultValue',
        key: 'defaultValue',
      },
    ],
    []
  );

  const crossTableMapping = useMemo(() => {
    if (tableRadio !== 'crossTable') {
      return;
    }

    const columns = [
      {
        title: () => (
          <InputWithButton
            className="decision-table__input-with-button--cross-table-title"
            value={undefined}
            onClick={() => {}}
            bordered={false}
            inputDisabled
            placeholder={translate('selectItem')}
          />
        ),
        children: [
          {
            key: 'mappingRow',
            fixed: 'left',
            render: () => (
              <InputWithButton
                className="decision-table__input-with-button--cross-table-row-title"
                value={undefined}
                onClick={() => {}}
                bordered={false}
                placeholder={translate('enterValue')}
              />
            ),
          },
        ],
      },
      {
        title: () => (
          <InputWithButton
            className="decision-table__input-with-button--cross-table-title"
            value={undefined}
            onClick={() => {}}
            bordered={false}
            inputDisabled
            placeholder={translate('selectItem')}
          />
        ),
        children: new Array(4).fill('').map((it, index) => ({
          key: index,
          title: () => (
            <InputWithButton
              className="decision-table__input-with-button--title"
              value={undefined}
              onClick={() => {}}
              bordered={false}
              placeholder={translate('enterValue')}
            />
          ),
          render: (value: string, rowData: any) => (
            <Input
              className="decision-table__input"
              value={`${rowData.key + 1} ${index + 1}`}
              onChange={() => {}}
              bordered={false}
              placeholder={translate('selectItem')}
            />
          ),
        })),
      },
    ];

    const rows = new Array(2).fill('').map((it, index) => ({
      key: index,
    }));

    return { columns, rows };
  }, [tableRadio]);

  const decisionTableMapping = useMemo(() => {
    if (tableRadio !== 'decisionTable') {
      return;
    }
    const menu = (data: any) => (
      <Menu>
        <Item key="menu-item-dropdown_0" onClick={() => console.log(data)}>
          {translate('contextMenu.insertConditionColumn')}
        </Item>
        <Item key="menu-item-dropdown_1" onClick={() => console.log(data)}>
          {translate('contextMenu.insertSolutionColumn')}
        </Item>
        <Menu.Divider />
        <Item
          key="menu-item-dropdown_2"
          danger
          onClick={() => console.log(data)}
        >
          {translate('contextMenu.deleteColumn')}
        </Item>
      </Menu>
    );
    const columns = new Array(6).fill('').map((it, index) => ({
      key: index,
      title: (value: string) => (
        <Dropdown overlay={menu(value)} trigger={[`contextMenu`]}>
          <InputWithButton
            className="decision-table__input-with-button--title"
            value={undefined}
            onClick={() => {}}
            bordered={false}
            inputDisabled
            placeholder={translate('selectItem')}
          />
        </Dropdown>
      ),
      dataIndex: 'conditions',
      render: (value: string) => (
        <Input
          className="decision-table__input"
          value={value}
          onChange={() => {}}
          bordered={false}
          placeholder={translate('enterValue')}
        />
      ),
    }));
    const rows = [{}];
    return { columns, rows };
  }, [tableRadio]);

  return (
    <Modal
      isOn={Boolean(isVisible)}
      modalProps={{
        title: modalProps?.title || '',
        icon: 'icon-decision_making_table',
        width: 74,
        type: modalProps?.type,
        footer: (
          <div className="decision-table__actions btn-group-bottom">
            <Button kind="normal" onClick={() => {}} width={100}>
              {translate('save')}
            </Button>
            <Button
              kind="normal"
              modifiers={['second']}
              onClick={onClose}
              width={100}
            >
              {translate('cancel')}
            </Button>
          </div>
        ),
      }}
      onClose={onClose}
    >
      <div className="decision-table">
        <div className="decision-table__content">
          <div className="top-content decision-table__top-content">
            <div className="decision-table__field decision-table__field--title">
              <Input
                label={translate('blockName')}
                value={decisionTableData?.nodeName}
                onChange={(e) =>
                  setDecisionTableData({
                    ...(decisionTableData as IDecisionTableData),
                    nodeName: e.target.value,
                  })
                }
              />
            </div>
            <div className="decision-table__field decision-table__field--code">
              <Input
                label={translate('blockCode')}
                value={blockCode}
                disabled
              />
            </div>
          </div>
          <div className="top-content decision-table__top-content">
            <div className="decision-table__field decision-table__field--description">
              <TextArea
                label={translate('blockDescription')}
                value={decisionTableData?.nodeDescription}
                autoSize={{ maxRows: 3 }}
                onChange={(e) =>
                  setDecisionTableData({
                    ...(decisionTableData as IDecisionTableData),
                    nodeDescription: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <Radio.Group
          onChange={(e) => setTableRadio(e.target.value)}
          value={tableRadio}
          className="decision-table__radios"
        >
          <Radio.Button
            value="decisionTable"
            className="decision-table__radio decision-table__radio--decision-table"
          >
            {translate('tableOfSolutions')}
          </Radio.Button>
          <Radio.Button
            value="crossTable"
            className="decision-table__radio decision-table__radio--cross-table"
            style={{ marginLeft: 12 }}
          >
            {translate('cross-table')}
          </Radio.Button>
        </Radio.Group>
        {tableRadio === 'crossTable' && (
          <div className="decision-table__tables">
            <ModalBox title={translate('outputVariable')} showHeader>
              <SimpleTable
                columnsData={outpitTableColumns}
                dataSource={[{}]}
                className="decision-table__output-table"
              />
            </ModalBox>
          </div>
        )}
        <div className="decision-table__tables">
          {decisionTableMapping && (
            <ModalBox
              showHeader
              actions={[
                {
                  icon: 'icon-add',
                  onClick: () => {
                    console.log('onClick add button');
                  },
                },
                {
                  icon: 'icon-delete',
                  onClick: () => {
                    console.log('onClick delete button');
                  },
                },
              ]}
            >
              <SimpleTable
                columnsData={decisionTableMapping.columns}
                dataSource={[{}, {}, {}, {}, {}]}
                className="decision-table__decision-table"
                scroll={{ x: true }}
                size="small"
              />
            </ModalBox>
          )}
          {crossTableMapping && (
            <ModalBox
              title={translate('crossTable')}
              showHeader
              actions={[
                {
                  icon: 'icon-add',
                  onClick: () => {
                    console.log('onClick add button');
                  },
                },
                {
                  icon: 'icon-delete',
                  onClick: () => {
                    console.log('onClick delete button');
                  },
                },
              ]}
            >
              <SimpleTable
                columnsData={crossTableMapping.columns}
                dataSource={crossTableMapping.rows}
                className="decision-table__cross-table"
                scroll={{ x: true }}
                size="small"
              />
            </ModalBox>
          )}
        </div>
      </div>
    </Modal>
  );
};
