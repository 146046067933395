import { useRef, VFC } from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import './checkboxEye.scss';
import { Icon } from '@modules/icon';
import { Button } from '../Button';

interface ICheckboxEye extends CheckboxProps {}

export const CheckboxEye: VFC<ICheckboxEye> = (props) => {
  const checkboxRef = useRef<any>(null);
  const checked = props.checked || props.defaultChecked;
  const handleClick = () => checkboxRef.current?.input.click();
  return (
    <Button className="checkbox-eye" kind="icon" onClick={handleClick}>
      <Checkbox {...props} ref={checkboxRef} />
      {checked ? <Icon name="icon-eye" /> : <Icon name="icon-eye_crossed" />}
    </Button>
  );
};
