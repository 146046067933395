import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '@modules/store';
import { notificationActions } from '../NotificationProviderSlice';
import * as notificationThunkActions from '../actions/notificationActions';

export const useNotificationAction = () => {
  const dispatch = useAppDispatch();

  return bindActionCreators(
    { ...notificationActions, ...notificationThunkActions },
    dispatch
  );
};
