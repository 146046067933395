import { Key } from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from '@modules/store';
import { dataProviderApi, IDataProviderUpdate } from '@modules/dataProvider';
import { administrationActions } from '../AdministrationSlice';
import { ADMINISTRATION_FEATURE_KEY } from '../constants';
import { deployDiagramApi } from '../components/DeployDiagram/api/deployDiagramApi';

export const fetchDataProvider = () => async (dispatch: AppDispatch) => {
  dispatch<any>(administrationActions.setDataProviderIsLoading(true));
  dispatch(getDataProvider())
    .unwrap()
    .then((data) =>
      data.forEach(({ sourceId }) => dispatch(getDataProviderTables(sourceId)))
    )
    .catch(() => null)
    .finally(() =>
      dispatch<any>(administrationActions.setDataProviderIsLoading(false))
    );
};

export const addDataProvider =
  (body: IDataProviderUpdate) => async (dispatch: AppDispatch) => {
    dispatch<any>(administrationActions.setDataProviderIsLoading(true));
    dataProviderApi
      .addDataProvider(body)
      .then(({ uuid }) => {
        if (uuid) {
          dispatch(getDataProviderById(uuid))
            .unwrap()
            .then((data) => dispatch(getDataProviderTables(data.sourceId)))
            .catch(() => null);
        }
      })
      .catch(() => null)
      .finally(() =>
        dispatch<any>(administrationActions.setDataProviderIsLoading(false))
      );
  };

export const updateDataProviderById =
  (sourceId: string, body: IDataProviderUpdate) =>
  async (dispatch: AppDispatch) => {
    dispatch<any>(administrationActions.setDataProviderIsLoading(true));
    dataProviderApi
      .updateDataProviderById(sourceId, body)
      .then(({ uuid }) => {
        if (uuid) {
          dispatch(getDataProviderById(uuid))
            .unwrap()
            .then((data) => dispatch(getDataProviderTables(data.sourceId)))
            .catch(() => null);
        }
      })
      .catch(() => null)
      .finally(() =>
        dispatch<any>(administrationActions.setDataProviderIsLoading(false))
      );
  };

export const deleteDataProviderByIds =
  (sourceIds: Array<string | Key>) => async (dispatch: AppDispatch) => {
    dispatch<any>(administrationActions.setDataProviderIsLoading(true));
    Promise.all(
      sourceIds.map((sourceId) =>
        dispatch(deleteDataProviderById(String(sourceId)))
      )
    )
      .catch(() => null)
      .finally(() =>
        dispatch<any>(administrationActions.setDataProviderIsLoading(false))
      );
  };

export const getDataProviderById = createAsyncThunk(
  `${ADMINISTRATION_FEATURE_KEY}/getDataProviderById`,
  async (sourceId: string) => {
    return await dataProviderApi.getDataProviderById(sourceId);
  }
);

export const deleteDataProviderById = createAsyncThunk(
  `${ADMINISTRATION_FEATURE_KEY}/deleteDataProviderById`,
  async (sourceId: string) => {
    const result = await dataProviderApi.deleteDataProviderById(sourceId);
    return result;
  }
);

export const getDataProvider = createAsyncThunk(
  `${ADMINISTRATION_FEATURE_KEY}/getDataProvider`,
  async () => {
    const result = await dataProviderApi.getDataProvider();
    return result;
  }
);

export const getDataProviderTables = createAsyncThunk(
  `${ADMINISTRATION_FEATURE_KEY}/getDataProviderTables`,
  async (sourceId: string) => {
    const tables = await dataProviderApi.getDataProviderTables(sourceId);
    return { sourceId, tables };
  }
);

export const getDeployDiagramList = createAsyncThunk(
  `${ADMINISTRATION_FEATURE_KEY}/getDeployDiagramList`,
  async () => {
    const result = await deployDiagramApi.getDeployDiagramList();
    return result;
  }
);
