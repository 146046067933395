import React, { VFC } from 'react';
import { Button } from '@shared';
import { useTranslation } from '@modules/languageProvider';
import classNames from 'classnames';

interface IModalFooter {
  className?: string;
  saveText?: string;
  saveDisabled?: boolean;
  cancelText?: string;
  onSave?: () => void;
  onClose: () => void;
}

export const ModalFooter: VFC<IModalFooter> = ({
  className,
  saveText,
  saveDisabled,
  cancelText,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('btn-group-bottom', className)}>
      {onSave && (
        <Button
          kind="normal"
          modifiers={['hover-box-shadow']}
          disabled={saveDisabled}
          onClick={onSave}
          children={saveText || t('modals.save')}
        />
      )}
      <Button
        kind="normal"
        modifiers={['second', 'hover-box-shadow']}
        onClick={onClose}
        children={cancelText || t('modals.cancel')}
      />
    </div>
  );
};
