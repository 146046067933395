import React, { Dispatch, FC, Key, SetStateAction } from 'react';
import { SimpleTable } from '@shared';
import { DataSource } from '../interfaces';
import { ColumnsType } from 'antd/es/table';
import { Sort } from '@modules/administration';
import { useAppSelector } from '@modules/store/hooks';
import { selectRoles, rolesList } from '@modules/services';

interface IDataSourcesTable {
  columns: ColumnsType<DataSource>;
  data: DataSource[];
  selectedRowKeys: Key[];
  loading: boolean;
  activeItem: string | null;
  expandedRowKeys: React.Key[] | undefined;
  setSelectedRowKeys: (keys: Key[]) => void;
  setActiveItem: Dispatch<SetStateAction<string | null>>;
  setSort: (sort: Sort) => void;
  onRowEditClick: (key: Key[]) => void;
}

export const DataSourcesTable: FC<IDataSourcesTable> = ({
  columns,
  data,
  selectedRowKeys,
  loading,
  activeItem,
  expandedRowKeys,
  setSelectedRowKeys,
  setActiveItem,
  setSort,
  onRowEditClick,
}) => {
  const [, tableName] = activeItem?.split(':') || [];

  const roles = useAppSelector(selectRoles);

  return (
    <SimpleTable
      columnsData={columns}
      dataSource={data}
      selectedRowKeys={selectedRowKeys}
      loading={loading}
      rowClassName={(record: any) =>
        activeItem && tableName && record.key === activeItem ? 'active-row' : ''
      }
      setSelectedRowKeys={setSelectedRowKeys}
      onDoubleClick={({ key }) => {
        roles.includes(rolesList.DATA_PROVIDER_READ_COLUMNS) &&
          setActiveItem(key);
        roles.includes(rolesList.DATA_PROVIDER_READ) && onRowEditClick([key]);
      }}
      onChange={(_, __, { field, order }: any) => {
        setSort({ field, order });
      }}
      expandable={{ expandedRowKeys }}
      hideSelectAll
    />
  );
};
