/* eslint-disable require-jsdoc */
import { Key } from 'react';
import { IAggregate } from '@modules/development';
import { IDataType } from '@modules/references';
import {
  Aggregate,
  AggregateComputeNode,
  GroupingElement,
  OutputVariable,
} from './interfaces';
import { GROUPING_ELEMENT_TEMPLATE, OUTPUT_VARIABLE_TEMPLATE } from './data';

export const CALC_AGGREGATE_TRANSLATION_PATH =
  'development.diagram.diagram.modals.calcAggregate';

export function getTranslationPath(item: string) {
  return `${CALC_AGGREGATE_TRANSLATION_PATH}.${item}`;
}

export function getAggregate(id: string, object: IAggregate[]) {
  return object.find((aggregate: IAggregate) => aggregate.aggregateId === id);
}

export function getAggregateName(id: string, object: IAggregate[]) {
  return getAggregate(id, object)?.aggregateName;
}

export function getAggregateTypeId(id: string, object: IAggregate[]) {
  return getAggregate(id, object)?.aggregateJson.aggregateVariableType;
}

export function getAggregateTypeName(
  id: string,
  object: IAggregate[],
  dataTypes: IDataType[]
) {
  return dataTypes.find((dt) => dt.typeId === getAggregateTypeId(id, object))
    ?.displayName;
}

export const changeNodeHelper = (calcAggregateNode: AggregateComputeNode) => ({
  setProperty: (property: string, value: any) => ({
    ...calcAggregateNode,
    properties: { ...calcAggregateNode.properties, [property]: value },
  }),
  setGroupingElements: (
    key: Key,
    property: keyof GroupingElement,
    value: string
  ) => ({
    ...calcAggregateNode,
    properties: {
      ...calcAggregateNode.properties,
      groupingElements: [
        ...calcAggregateNode.properties.groupingElements.map((item, index) => {
          if (`groupingElement-${index}` === key) {
            return { ...item, [property]: value };
          } else return item;
        }),
      ],
    },
  }),
  addGroupingElements: () => ({
    ...calcAggregateNode,
    properties: {
      ...calcAggregateNode.properties,
      groupingElements: [
        ...calcAggregateNode.properties.groupingElements,
        GROUPING_ELEMENT_TEMPLATE,
      ],
    },
  }),
  deleteGroupingElements: (rows: Key[]) => ({
    ...calcAggregateNode,
    properties: {
      ...calcAggregateNode.properties,
      groupingElements: calcAggregateNode.properties.groupingElements.filter(
        (_, index) => !rows.includes(`groupingElement-${index}`)
      ),
    },
  }),
  addAggregate: () => ({
    ...calcAggregateNode,
    properties: {
      ...calcAggregateNode.properties,
      outputVariablesMapping: [
        ...calcAggregateNode.properties.outputVariablesMapping,
        OUTPUT_VARIABLE_TEMPLATE,
      ],
    },
  }),
  deleteAggregate: (rows: Key[]) => ({
    ...calcAggregateNode,
    properties: {
      ...calcAggregateNode.properties,
      outputVariablesMapping:
        calcAggregateNode.properties.outputVariablesMapping.filter(
          (_, index) => !rows.includes(`aggregate-${index}`)
        ),
    },
  }),
  setVariable: (key: Key, value: Omit<OutputVariable, 'aggregate'>) => ({
    ...calcAggregateNode,
    properties: {
      ...calcAggregateNode.properties,
      outputVariablesMapping:
        calcAggregateNode.properties.outputVariablesMapping.map(
          (item, index) => {
            if (key === `aggregate-${index}`) {
              return {
                ...item,
                variableName: value.variableName,
                typeId: value.typeId,
                isArray: value.isArray,
                isComplex: value.isComplex,
                variablePath: value.variablePath,
                variableRootId: value.variableRootId,
              };
            } else return item;
          }
        ),
    },
  }),
  setAggregate: (
    key: Key,
    property: keyof Aggregate,
    value: string | boolean
  ) => ({
    ...calcAggregateNode,
    properties: {
      ...calcAggregateNode.properties,
      outputVariablesMapping:
        calcAggregateNode.properties.outputVariablesMapping.map(
          (item, index) => {
            if (key === `aggregate-${index}`) {
              if (property === 'isUsedInDiagram' && value === false) {
                return {
                  variableName: '',
                  isComplex: false,
                  typeId: '',
                  isArray: false,
                  variablePath: '',
                  variableRootId: '',
                  aggregate: {
                    ...item.aggregate,
                    [property]: value,
                  },
                };
              } else
                return {
                  ...item,
                  aggregate: { ...item.aggregate, [property]: value },
                };
            } else return item;
          }
        ),
    },
  }),
  getElementNames: (exception?: string[]) => [
    ...calcAggregateNode.properties.outputVariablesMapping
      .flatMap((item) => [item.aggregate.diagramAggregateElement])
      .filter((entry) => !exception?.includes(entry))
      .filter((entry) => /\S/.test(entry)),
  ],
  getAggregateElementNames: (exception?: string[]) =>
    calcAggregateNode.properties.groupingElements
      .map((item) => item.aggregateElement)
      .filter((entry) => !exception?.includes(entry)),
});
