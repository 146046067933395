import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import './ExpressionEditorModal.scss';
import { diagramApi } from '@modules/development';
import {
  Button,
  Modal,
  useModalVisible,
  TextArea,
  ConfirmModal,
} from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import { useDebounce } from '@shared/hooks/useDebounce';
import { removeOpenModal } from '@modules/development/DevelopmentSlice';
import { AddFunctionModal } from '../AddFunctionModal';
import { AddElementModal } from '../AddElementModal';
import { ValidationInfo } from '../common/components/ValidationInfo';
import { IExpressionEditorModal, IExprEditorReturnData } from './interfaces';
import { OpenedModal } from './interfaces';
import { TRANSLATE_PATH } from './services';
import { ConfirmInvalidBlockSaveModal } from '../ConfirmInvalidBlockSaveModal';

const ExpressionEditorModal: React.FC<IExpressionEditorModal> = ({
  modalProps,
  inputVariables,
  closeOnSave,
  requiredIdTypeExpr,
  addedVariables,
  onClose,
  onSave,
}) => {
  const dispatch = useDispatch();
  const translate = useTranslationPath(TRANSLATE_PATH);
  const isVisible = useModalVisible(modalProps?.type);

  const [expression, setExpression] = useState(modalProps?.expression || '');
  const [lastValidatedData, setLastValidatedData] =
    useState<IExprEditorReturnData>();
  const [openedModal, setOpenedModal] = useState<OpenedModal>(null);
  const [selectionStart, setSelectionStart] = useState<number | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

  const debouncedValue = useDebounce<string>(expression, 3000);

  const onHideModalClick = () => {
    setOpenedModal(null);
    dispatch(removeOpenModal({}));
  };

  const setSelection = (
    event:
      | React.MouseEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    setSelectionStart(event.currentTarget.selectionStart);
    setSelectionEnd(event.currentTarget.selectionEnd);
  };

  const checkExpression = useCallback(async () => {
    const response = await diagramApi.validateCalculateExpression(expression);
    setLastValidatedData({
      expression,
      statusValidate: response.statusValidate,
      idTypeExpression: response.idTypeExpression,
    });
  }, [expression]);

  const validatioResult = useMemo(() => {
    const validationRules = {
      // [translate('errorValidation')]:
      //   !lastValidatedData || lastValidatedData.expression !== expression,
      [translate('errorInvalid')]:
        lastValidatedData && !lastValidatedData.statusValidate,
      // [translate('errorTypes')]:
      //   Boolean(requiredIdTypeExpr) &&
      //   requiredIdTypeExpr !== lastValidatedData?.idTypeExpression,
    };

    const errorMessages: string[] = Object.entries(validationRules)
      .filter(([, isError]) => isError)
      .map(([errMsg]) => errMsg);

    return { isValid: !errorMessages.length, errorMessages };
  }, [lastValidatedData, requiredIdTypeExpr, translate, checkExpression]);

  const onSaveButtonClick = async () => {
    if (expression) {
      const response = await diagramApi.validateCalculateExpression(expression);

      if (!response.statusValidate) {
        return setOpenedModal({
          type: 'confirmSave',
          contentText: '',
        });
      }

      return setOpenedModal({
        type: 'notification',
        title: '',
        data: {
          expression,
          statusValidate: response.statusValidate,
          idTypeExpression: response.idTypeExpression,
        },
      });
    }

    return setOpenedModal({
      type: 'notification',
      title: '',
      data: { expression: '' },
    });
  };

  const onSaveData = (data = lastValidatedData) => {
    data && onSave(data);
    closeOnSave && onClose();
  };

  const onSaveAddFuncOrElem = (selection: any, value: any) => {
    const newExpr = [...expression.split('')];
    const { selectionStart: start, selectionEnd: end } = selection;

    newExpr.splice(start || 0, end - start || 0, value);
    setExpression(newExpr.join(''));
    setSelectionStart(null);
    setSelectionEnd(null);
  };

  useEffect(() => {
    checkExpression();
  }, [debouncedValue]);

  return (
    <>
      <Modal
        isOn={isVisible}
        modalProps={{
          type: modalProps?.type,
          title: modalProps?.title || '',
          icon: 'icon-expression_editor',
          width: '1024px',
          helpKey: 'expression-editor',
          footer: (
            <div className="btn-group-bottom">
              <ValidationInfo
                show={!validatioResult.isValid}
                errorMessages={validatioResult.errorMessages}
              />
              <Button
                kind="normal"
                modifiers={['hover-box-shadow']}
                onClick={onSaveButtonClick}
              >
                {translate('add')}
              </Button>
              <Button
                kind="normal"
                modifiers={['second', 'hover-box-shadow']}
                onClick={onClose}
              >
                {translate('cancel')}
              </Button>
            </div>
          ),
        }}
        onClose={onClose}
      >
        <div className="expression-editor">
          <div className="expression-editor__content">
            <div className="top-content expression-editor__top-content">
              <div className="expression-editor__btn-group-top">
                <Button
                  kind="normal"
                  modifiers={['second', 'hover-box-shadow']}
                  className="expression-editor__btn"
                  onClick={() =>
                    setOpenedModal({
                      type: 'exprEditorAddFunc',
                      title: translate('addFunc'),
                      data: { selection: { selectionStart, selectionEnd } },
                    })
                  }
                  width={190}
                >
                  <span>+</span> {translate('addFunc')}
                </Button>
                <Button
                  kind="normal"
                  modifiers={['second', 'hover-box-shadow']}
                  className="expression-editor__btn"
                  onClick={() =>
                    setOpenedModal({
                      type: 'exprEditorAddElem',
                      title: translate('addElem'),
                      data: { selection: { selectionStart, selectionEnd } },
                    })
                  }
                  width={240}
                >
                  <span>+</span> {translate('addElem')}
                </Button>
              </div>
            </div>
          </div>
          <div className="expression-editor__expression">
            <TextArea
              maxLength={3000}
              value={expression}
              onChange={(e) => {
                setExpression(e.target.value);
              }}
              onKeyUp={(event) => setSelection(event)}
              onClick={(event) => setSelection(event)}
            />
          </div>
        </div>
      </Modal>

      {openedModal?.type === 'exprEditorAddFunc' && (
        <AddFunctionModal
          modalProps={openedModal}
          onSave={(funcData) => {
            const { selection } = openedModal?.data;
            onSaveAddFuncOrElem(selection, funcData.functionName);
          }}
          onClose={onHideModalClick}
          closeOnSave
        />
      )}

      {openedModal?.type === 'exprEditorAddElem' && (
        <AddElementModal
          modalProps={{ type: openedModal.type }}
          inputVariables={inputVariables}
          addedVariables={addedVariables}
          onSave={({ chainName }) => {
            const { selection } = openedModal?.data;
            onSaveAddFuncOrElem(selection, chainName);
          }}
          onClose={onHideModalClick}
          closeOnSave
          disableComplex
          hideAddNewBtn
        />
      )}

      {openedModal?.type === 'confirmSave' && (
        <ConfirmInvalidBlockSaveModal
          modalProps={openedModal}
          onOk={onSaveData}
          onClose={() => setOpenedModal(null)}
          onCancel={onClose}
        />
      )}

      {openedModal?.type === 'notification' && (
        <ConfirmModal
          modalProps={{
            type: 'notification',
            contentText: 'Успешно',
            kind: 'info',
          }}
          onClose={() => onSaveData(openedModal.data)}
        />
      )}
    </>
  );
};

export { ExpressionEditorModal };
