import { Key, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import './DeployDiagram.scss';
import {
  Toolbar,
  Select,
  SimpleTable,
  IToolbarItem,
  getColumnCustomFilterProps,
  getColumnDateFilterProps,
  useSearch,
  Portal,
} from '@shared';
import { Badge, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@modules/store';
import { selectRoles, rolesList } from '@modules/services';
import { SelectedFilters } from './parts/selectedFilters';
import { DeploySettingsModal } from './parts/deploySettings';
import { ITableOptions } from './parts/selectedFilters/SelectedFilters';
import {
  IDiagramForDeploy,
  ISettingsModalProps,
  ISettingsValues,
} from './interfaces';
import {
  deployDiagrams,
  getDeployDiagramList,
  getEnvironments,
  selectDeployDiargamList,
  selectEnvironments,
  undeployDiagrams,
} from '@modules/administration/AdministrationSlice';
import { useDispatch } from 'react-redux';
import { useDeployDiagram } from './hooks';

export const DeployDiagram = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [filteredList, setFilteredList] = useState<IDiagramForDeploy[] | []>(
    []
  );
  const environments = useAppSelector(selectEnvironments);
  const diagramList = useAppSelector(selectDeployDiargamList);
  const [filterParams, setFiltedParams] = useState<ITableOptions>({
    deployStatus: null,
    changeDt: null,
  });
  const [isFiltersDisabled, setIsFiltersDisabled] = useState<boolean>(false);
  const [clearFilterFlag, setClearFilterFlag] = useState<boolean>(false);
  const [settingsModalProps, setSettingsModalProps] =
    useState<ISettingsModalProps>({
      isOpen: false,
      disabled: false,
      deployId: null,
    });

  const [search, setSearch] = useState<string>('');
  const [settingsValues, setSettingsValues] = useState<ISettingsValues>({
    parallelism: '',
    detailedLogging: false,
    taskManagerMemory: '',
    jobManagerMemory: '',
    arguments: '',
    parallelDeployFlag: null,
  });
  const [envId, setEnvId] = useState<string>('');

  const { filters, renderMenuBtn } = useDeployDiagram(setSettingsModalProps);

  // const openTabs = useAppSelector(selectOpenTabs);
  const roles = useAppSelector(selectRoles);

  const clearValues = () => {
    setSettingsValues({
      parallelism: '',
      detailedLogging: false,
      taskManagerMemory: '',
      jobManagerMemory: '',
      arguments: '',
      parallelDeployFlag: null,
    });
  };

  const handleFilter = (
    dataIndex: string | Array<string>,
    value: string | string[]
  ) => {
    if (dataIndex === 'deployStatus') {
      filterParams.deployStatus && setClearFilterFlag(true);
      setFiltedParams({
        ...filterParams,
        deployStatus: value,
      });
    } else {
      setFiltedParams({
        ...filterParams,
        changeDt: value,
      });
    }
  };

  useEffect(() => {
    if (!filterParams.deployStatus && !filterParams.changeDt) {
      setFilteredList(diagramList);
    }
    if (filterParams.deployStatus) {
      const innerList = (clearFilterFlag ? diagramList : filteredList).filter(
        (item: any) => item.deployStatus === filterParams.deployStatus
      );
      setFilteredList(innerList);
      clearFilterFlag && setClearFilterFlag(false);
    }
    if (filterParams.changeDt) {
      const firstDate = new Date(filterParams.changeDt[0]).getTime();
      const secondDate = new Date(filterParams.changeDt[1]).getTime();
      const innerList = (clearFilterFlag ? diagramList : filteredList).filter(
        (item: any) => {
          const date = item.deployDt.substring(0, 10).split('-');
          const ms = new Date(+date[2], +date[1] - 1, +date[0]).getTime();
          return firstDate < ms && ms < secondDate;
        }
      );
      setFilteredList(innerList);
      clearFilterFlag && setClearFilterFlag(false);
    }
  }, [filterParams, diagramList, clearFilterFlag]);

  const clearFilters = () => {
    setFiltedParams({ changeDt: null, deployStatus: null });
  };

  const onCheckboxClick = (val: boolean) => {
    if (val) {
      setFiltedParams({ changeDt: null, deployStatus: 'READY_FOR_DEPLOY' });
      setIsFiltersDisabled(true);
    }
    if (!val) {
      clearFilters();
      setIsFiltersDisabled(false);
    }
  };

  const parseDate = (date: string) => {
    return Date.parse(
      date?.replace(/(\d{2})\-(\d{2})\-(\d{4})/, '$3-$2-$1')
    ).toString();
  };

  const sorterDate = (a: IDiagramForDeploy, b: IDiagramForDeploy) =>
    parseDate(a.deployDt).localeCompare(parseDate(b.deployDt));

  const columns: ColumnsType<IDiagramForDeploy> = [
    {
      dataIndex: 'collapse',
      className: 'ant-table-collapse',
    },
    {
      title: t('deployDiagram.columns.title'),
      dataIndex: 'diagramName',
      key: 'diagramName',
      width: '27%',
      sorter: (a, b) => a.diagramName.localeCompare(b.diagramName),
    },
    {
      title: t('deployDiagram.columns.version'),
      dataIndex: 'versionName',
      key: 'versionName',
      width: '27%',
      sorter: (a, b) => a.versionName.localeCompare(b.versionName),
    },
    {
      title: t('deployDiagram.columns.status'),
      dataIndex: 'deployStatus',
      key: 'deployStatus',
      width: '21%',
      sorter: (a, b) => a.deployStatus?.localeCompare(b.deployStatus),
      render: (value) => {
        switch (value) {
          case 'STOPPED':
            return <Badge color="#0048B2" text={t('deployDiagram.stopped')} />;
          case 'ERROR':
            return <Badge color="#D30000" text={t('deployDiagram.error')} />;
          case 'DEPLOYED':
            return <Badge color="#00B247" text={t('deployDiagram.deployed')} />;
          case 'READY_FOR_DEPLOY':
            return <Badge color="#C97A20" text={t('deployDiagram.ready')} />;
          default:
            return value;
        }
      },
      ...getColumnCustomFilterProps(
        'deployStatus',
        filters,
        handleFilter,
        filterParams,
        isFiltersDisabled
      ),
    },
    {
      title: t('deployDiagram.columns.changeDate'),
      dataIndex: 'deployDt',
      key: 'deployDt',
      width: '20%',
      sorter: sorterDate,
      defaultSortOrder: 'descend',
      ...getColumnDateFilterProps(
        filterParams,
        'updatedAfter',
        'updatedBefore',
        handleFilter,
        isFiltersDisabled
      ),
    },
    {
      title: '',
      dataIndex: 'menu',
      render: renderMenuBtn,
    },
  ];

  // const openedDiagrams = useMemo(() => {
  //   if (openTabs) {
  //     return openTabs
  //       .filter((tab) => tab.type === 'diagram')
  //       .map((diagram) => {
  //         return <div>{diagram.text}</div>;
  //       });
  //   } else {
  //     return [];
  //   }
  // }, [openTabs]);

  // const deployDiagram = () => {
  //   if (openedDiagrams.length) {
  //     setIsModalOpen(true);
  //   } else {
  //     setSettingsModalProps({ isOpen: true, disabled: false, deployId: null });
  //   }
  // };

  const deployDiagram = () => {
    setSettingsModalProps({ isOpen: true, disabled: false, deployId: null });
  };

  const undeployDiagram = () => {
    dispatch(undeployDiagrams(selectedRowKeys));
  };

  // TODO после подключения апи перепроверить статус в условии
  const isDeployButtonDisabled = useMemo(() => {
    let isDisabled = false;
    if (!roles.includes(rolesList.DIAGRAM_DEPLOY) || !selectedRowKeys.length) {
      isDisabled = true;
    }
    // Убрал это условие чтобы можно было переотправлять упавшие / отработанные
    /* else {
      selectedRowKeys.forEach((key) => {
        if (
          diagramList.find((item) => item.key === key)?.deployStatus !==
          'READY_FOR_DEPLOY'
        ) {
          isDisabled = true;
        }
      });
    }*/
    return isDisabled;
  }, [selectedRowKeys, roles]);

  // TODO после подключения апи перепроверить статус в условии
  const isUndeployButtonDisabled = useMemo(() => {
    let isDisabled = false;
    if (!roles.includes(rolesList.DIAGRAM_DEPLOY) || !selectedRowKeys.length) {
      isDisabled = true;
    } else {
      selectedRowKeys.forEach((key) => {
        if (
          diagramList.find((item) => item.key === key)?.deployStatus !==
          'DEPLOYED'
        ) {
          isDisabled = true;
        }
      });
    }
    return isDisabled;
  }, [selectedRowKeys, roles]);

  const toolbarData: IToolbarItem[] = [
    { isDivider: true },
    {
      icon: 'icon-deploy',
      tooltip: t('actions.deploy'),
      onClick: deployDiagram,
      disabled: isDeployButtonDisabled,
    },
    {
      icon: 'icon-deploy_cancel',
      tooltip: t('actions.cancelDeploy'),
      onClick: undeployDiagram,
      disabled: isUndeployButtonDisabled,
    },
    {
      icon: 'icon-refresh',
      tooltip: t('actions.refresh'),
      onClick: () => {
        dispatch(getDeployDiagramList());
      },
    },
    { icon: 'icon-upload_to_file', tooltip: t('actions.exportDiagram') },
    { icon: 'icon-download_file', tooltip: t('actions.importDiagram') },
  ];

  // const modalBody = () => {
  //   return (
  //     <div className="modal__child">
  //       {t('deployDiagram.modals.modalText')}
  //       {openedDiagrams}
  //     </div>
  //   );
  // };

  // const modalFooter = () => {
  //   return (
  //     <Button
  //       kind="normal"
  //       onClick={() => setIsModalOpen(false)}
  //       style={{ display: 'inline' }}
  //     >
  //       Ok
  //     </Button>
  //   );
  // };

  useEffect(() => {
    dispatch(getDeployDiagramList());
    // dispatch(getEnvironments());
  }, []);

  const onSearch = (search: string) => {
    setSearch(search);
  };

  const [renderSearchInput, getRefProps] = useSearch({
    search: search,
    setSearch: onSearch,
  });

  const data = useMemo(() => {
    if (search) {
      return filteredList.filter((entry) =>
        entry.diagramName.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      return filteredList;
    }
  }, [search, filteredList]);

  const sendOnDeployDiargam = () => {
    setSettingsModalProps({ isOpen: false, disabled: false, deployId: null });
    dispatch(deployDiagrams(envId, settingsValues, selectedRowKeys));
    clearValues();
  };

  const settingsChange = (key: string, value: number | string | boolean) => {
    setSettingsValues((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="deploy-diagram">
      <div className="deploy-diagram__top">{t('deployDiagram.title')}</div>
      <div className="deploy-diagram__row">
        <Portal id="toolbarSearch">{renderSearchInput()}</Portal>
        <Toolbar
          data={toolbarData}
          isShowCheckboxAll
          CheckboxAllIndeterminate={
            selectedRowKeys.length !== diagramList.length &&
            selectedRowKeys.length !== 0
          }
          CheckboxAllChecked={selectedRowKeys.length === diagramList.length}
          onCheckboxAllChange={() => {
            setSelectedRowKeys(
              selectedRowKeys.length === diagramList.length
                ? []
                : diagramList.map((item) => item.key)
            );
          }}
          beforeActions={
            <Select
              options={environments.map((env) => ({
                value: env.environmentId,
                label: env.name,
              }))}
              style={{ width: 220 }}
              size="middle"
              onChange={(val) => setEnvId(val)}
            />
          }
        />
      </div>
      <div className="scenarios-table__rows filters-and-checkbox-box">
        <div className="scenarios-table__rows remote-scripts-checkbox">
          <Checkbox onChange={({ target }) => onCheckboxClick(target.checked)}>
            {t('deployDiagram.checkBox')}
          </Checkbox>
        </div>

        <div className="filters-and-checkbox-box__filters">
          <SelectedFilters
            filters={filters}
            onClose={(val: string) => {
              val === 'deployStatus' &&
                setFiltedParams({ ...filterParams, deployStatus: null });
              val === 'changeDt' &&
                setFiltedParams({ ...filterParams, changeDt: null });
              setClearFilterFlag(true);
            }}
            tableParams={filterParams}
            onClearFilters={clearFilters}
            disabled={isFiltersDisabled}
          />
        </div>
      </div>
      <div className="deploy-diagram__content" {...getRefProps()}>
        <SimpleTable
          columnsData={columns}
          dataSource={data}
          hideSelectAll
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </div>
      {/* <Modal
        isOn={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalProps={{
          title: t('deployDiagram.modals.hasOpenedDiagrams'),
          icon: 'icon-alert-blue',
          footer: modalFooter(),
        }}
      >
        {modalBody()}
      </Modal> */}

      <DeploySettingsModal
        values={settingsValues}
        onValuesChange={settingsChange}
        modalProps={settingsModalProps}
        setIsOpenModal={setSettingsModalProps}
        onDeploy={sendOnDeployDiargam}
        clearValues={clearValues}
      />
    </div>
  );
};
