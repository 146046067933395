import {
  ConnectionsResponseDTO,
  IAllConnectionsDTO,
  IConnectionSourceTableData,
  IDatabaseSystemDTO,
  IMessageBrokersDTO,
  IExtServices,
} from '@modules/connections/types/connectionsTypes';
import { DBMS, EXTERNAL_SERVICES, MESSAGE_BROKERS } from './constants';

export const mockAllConnections: ConnectionsResponseDTO<IAllConnectionsDTO>[] =
  [
    {
      connectionId: '1',
      name: 'kafka-01',
      type: MESSAGE_BROKERS,
      lastChangeDt: '2022-09-09 09:10:05',
      lastChangeByUser: 'User_1',
      connectionStatus: 'SUCCESS',
    },
    {
      connectionId: '2',
      name: 'si-ois-01',
      type: DBMS,
      lastChangeDt: '2020-10-09 09:10:06',
      lastChangeByUser: 'User_1',
      children: [
        {
          connectionId: '4',
          name: 'BLACK_LISTS',
        },
        {
          connectionId: '5',
          name: 'CLIENTS',
        },
        {
          connectionId: '6',
          name: 'CREDITS',
        },
      ],
      connectionStatus: 'SUCCESS',
    },
    {
      connectionId: '3',
      name: 'НБКИ',
      type: EXTERNAL_SERVICES,
      lastChangeDt: '2022-09-09 09:10:05',
      lastChangeByUser: 'User_1',
      connectionStatus: 'SUCCESS',
    },
  ];

export const mockDatabaseSystem: ConnectionsResponseDTO<IDatabaseSystemDTO>[] =
  [
    {
      connectionId: '1',
      name: 'kafka-01',
      lastChangeDt: '2022-09-09 09:10:05',
      lastChangeByUser: 'User_1',
      dataSourceType: 'Oracle',
      jdbcUrl: 'oracle:thin:@localhost:1521:orcl',
      username: 'DBA',
      children: [
        {
          connectionId: '4',
          name: 'BLACK_LISTS',
        },
        {
          connectionId: '5',
          name: 'CLIENTS',
        },
        {
          connectionId: '6',
          name: 'CREDITS',
        },
      ],
      connectionStatus: 'SUCCESS',
    },
  ];

export const mockDataMessageBrokers: ConnectionsResponseDTO<IMessageBrokersDTO>[] =
  [
    {
      connectionId: '1',
      name: 'kafka-01',
      lastChangeDt: '2020-10-09 09:10:06',
      lastChangeByUser: 'User_1',
      bootstrapServers:
        'host:port, host2:port2, host3:port3, host4:port4, host5:port5, host6:port6, host7:port7, host8:port8, host9:port9',
      connectionStatus: 'SUCCESS',
    },
  ];

export const mockDataOutServices: ConnectionsResponseDTO<IExtServices>[] = [
  {
    connectionId: '3',
    name: 'НБКИ',
    protocol: 'REST',
    url: 'http://external-service:9099123',
    lastChangeDt: '2022-09-09 09:10:05',
    lastChangeByUser: 'User_1',
    connectionStatus: 'SUCCESS',
  },
];

export const mockSourceData: IConnectionSourceTableData[] = [
  {
    key: 1,
    columnName: 'CLIENT_ID',
    dataType: 'Строка',
  },
  {
    key: 2,
    columnName: 'BLACK_LIST_NAME',
    dataType: 'Строка',
  },
];
