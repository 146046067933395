import React, { FC, Key, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Collapse } from 'antd';
import './ExternalServiceCallSettingsModal.scss';
import {
  ConfirmModal,
  Input,
  Modal,
  SimpleTable,
  TabPanel,
  TextArea,
  Toolbar,
  useAdministrationModalVisible,
} from '@shared';
import { Icon } from '@modules/icon';
import {
  servicesApi,
  ServiceUpdate,
  ServiceAdd,
  selectRoles,
  rolesList,
} from '@modules/services';
import { useAppSelector } from '@modules/store';
import { initialService, mainClass, tabs, tranlationPath } from './data';
import { ExtServiceGeneralSettings } from './parts/ExtServiceGeneralSettings';
import { ExtServiceFooter } from './parts/ExtServiceFooter';
import { selectDataTypes } from '@modules/references';
import {
  Variable,
  ModalProps,
  Service,
  Header,
  Message,
  OpenedModal,
} from './interfaces';
import {
  useExternalServiceCallSettingsValidation,
  useExtServiceHeadersTable,
  useExtServiceInputVariablesTable,
  useExtServiceOutputVariablesTable,
} from './hooks';
import { getBody } from './services';
import { ExtServiceCallSettingsMessage } from './parts/ExtServiceCallSettingsMessage';
import { ServiceData } from '../ExternalServiceAccess/interfaces';
import ExtServiceCallSettingsVersions from './parts/ExtServiceCallSettingsVersions';
import { useTranslationPath } from '@modules/languageProvider';
import { useAdministrationAction } from '../../hooks';

export interface IExternalServiceCallSettingsModal {
  modalProps: ModalProps;
  onSaveClose?: boolean;
  onSave: (body: ServiceAdd | ServiceUpdate, versionId?: Key) => void;
  onSaveAs: (body: ServiceAdd | ServiceUpdate) => void;
  onClose: () => void;
  data: ServiceData[];
  body?: ServiceAdd | ServiceUpdate;
  setSavedBody: React.Dispatch<ServiceAdd | ServiceUpdate | null>;
}

export const ExternalServiceCallSettingsModal: FC<IExternalServiceCallSettingsModal> =
  ({
    modalProps,
    onSaveClose,
    onSave,
    onSaveAs,
    onClose,
    data,
    body,
    setSavedBody,
  }) => {
    const translate = useTranslationPath(tranlationPath);
    const isVisible = useAdministrationModalVisible(modalProps.type);
    const { removeAdministrationOpenModal } = useAdministrationAction();

    const roles = useAppSelector(selectRoles);
    const dataTypes = useAppSelector(selectDataTypes);

    const [service, setService] = useState<Service>(initialService);
    const [inputVariables, setInputVariables] = useState<Variable[]>([]);
    const [outputVariables, setOutputVariables] = useState<Variable[]>([]);
    const [headers, setHeaders] = useState<Header[]>([]);
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [message, setMessage] = useState<Message>(null);
    const [notUniqueSourceNameMessage, setNotUniqueSourceNameMessage] =
      useState<Message>(null);
    const [activeThirdPanel, setActiveThirdPanel] = useState('0');
    const [startBody, setStartBody] = useState<ServiceAdd | ServiceUpdate>();
    const [openedModal, setOpenedModal] = useState<OpenedModal | null>(null);

    const valuesObject: ServiceAdd | ServiceUpdate = useMemo(() => {
      return getBody(service, headers, inputVariables, outputVariables);
    }, [service, headers, inputVariables, outputVariables]);

    const validation = useExternalServiceCallSettingsValidation(
      showErrors,
      translate,
      data,
      valuesObject,
      modalProps.versionId
    );

    const onServiceChange = (property: keyof Service, value: string) => {
      setService((current) => ({ ...current, [property]: value }));
    };

    const {
      inputVariablesRowKeys,
      setInputVariablesRowKeys,
      inputVariablesActions,
      inputVariablesColumns,
    } = useExtServiceInputVariablesTable(
      dataTypes,
      translate,
      setInputVariables,
      validation?.inputVarsErrors
    );

    const {
      outputVariablesRowKeys,
      setOutputVariablesRowKeys,
      outputVariablesActions,
      outputVariablesColumns,
    } = useExtServiceOutputVariablesTable(
      dataTypes,
      translate,
      setOutputVariables,
      validation?.outputVarsErrors
    );

    const {
      headersRowKeys,
      setHeadersRowKeys,
      headersActions,
      headersColumns,
    } = useExtServiceHeadersTable(translate, setHeaders);

    const onSaveAsClick = () => {
      setShowErrors(true);
      if (!validation.isValid) return false;

      let innerService = { ...service };
      if (!service.endpoint.startsWith('/')) {
        innerService = { ...innerService, endpoint: `/${service.endpoint}` };
      }

      const body = getBody(
        innerService,
        headers,
        inputVariables,
        outputVariables
      );
      onSaveAs(body);
    };

    const onSaveClick = () => {
      setShowErrors(true);
      if (!validation.isValid) return false;

      let innerService = { ...service };
      if (!service.endpoint.startsWith('/')) {
        innerService = { ...innerService, endpoint: `/${service.endpoint}` };
      }

      const body = getBody(
        innerService,
        headers,
        inputVariables,
        outputVariables
      );
      onSave(body, modalProps.versionId);
      onSaveClose && onClose();
    };

    const onCloseClick = () => {
      if (startBody) {
        if (JSON.stringify(startBody) !== JSON.stringify(valuesObject)) {
          return setOpenedModal({
            type: 'confirm',
            contentText: translate('confirmSaveUnsavedChanges'),
            onOk: onSaveClick,
          });
        }
      }

      onClose();
      removeAdministrationOpenModal({});
      setSavedBody(null);
    };

    // useEffect(() => {
    //   if (body) {
    //     console.log(body);

    //     const { variables, headers, ...service } = body;
    //     const batchFlag = service.batchFlag ? 'BATCH' : 'SINGLE';
    //     const inputVariables: Variable[] = [];
    //     const outputVariables: Variable[] = [];

    //     headers?.length &&
    //       setHeaders(
    //         headers.map((header) => ({
    //           key: uuidv4(),
    //           headerName: header.headerName,
    //           headerValue: header.headerValue,
    //         }))
    //       );

    //     variables?.length &&
    //       variables?.forEach((variable) => {
    //         const newVariable: Variable = {
    //           key: uuidv4(),
    //           variableName: variable.variableName,
    //           sourcePath: variable.sourcePath,
    //           isArray: variable.arrayFlag,
    //           isComplex: !!variable.complexTypeVersionId,
    //           variableTypeId: variable.complexTypeVersionId
    //             ? variable.complexTypeVersionId
    //             : String(variable.primitiveTypeId),
    //         };

    //         variable.parameterType === 'IN'
    //           ? inputVariables.push(newVariable)
    //           : outputVariables.push(newVariable);
    //       });

    //     setInputVariables(inputVariables);
    //     setOutputVariables(outputVariables);
    //     setService({ ...service, batchFlag });

    //     setStartBody(
    //       getBody(
    //         { ...service, batchFlag },
    //         (headers || []).map((header) => ({
    //           key: uuidv4(),
    //           headerName: header.headerName,
    //           headerValue: header.headerValue,
    //         })),
    //         inputVariables,
    //         outputVariables
    //       )
    //     );
    //   }
    // }, []);

    useEffect(() => {
      if (service.protocol === 'SOAP') {
        setService((current) => ({ ...current, fileFormat: 'XML' }));
      }
      if (service.batchFlag !== 'BATCH') {
        setService((current) => ({
          ...current,
          transactionsPerSecond: undefined,
        }));
      }
    }, [service.protocol, service.batchFlag]);

    useEffect(() => {
      const fetchService = async (serviceId: string) => {
        try {
          const response = await servicesApi.getServiceById(serviceId);
          const { variables, headers, ...service } = response;
          const batchFlag = service.batchFlag ? 'BATCH' : 'SINGLE';
          const inputVariables: Variable[] = [];
          const outputVariables: Variable[] = [];

          variables?.forEach((variable) => {
            const newVariable: Variable = {
              key: uuidv4(),
              variableId: variable.variableId,
              variableName: variable.variableName,
              sourcePath: variable.sourcePath,
              isArray: variable.arrayFlag,
              isComplex: !!variable.complexTypeVersionId,
              variableTypeId: variable.complexTypeVersionId
                ? variable.complexTypeVersionId
                : String(variable.primitiveTypeId),
            };

            variable.parameterType === 'IN'
              ? inputVariables.push(newVariable)
              : outputVariables.push(newVariable);
          });

          setInputVariables(inputVariables);
          setOutputVariables(outputVariables);
          setService({ ...service, batchFlag });
          setHeaders(
            (headers || []).map((header) => ({
              key: uuidv4(),
              headerId: header.headerId,
              headerName: header.headerName,
              headerValue: header.headerValue,
            }))
          );
          setStartBody(
            getBody(
              { ...service, batchFlag },
              (headers || []).map((header) => ({
                key: uuidv4(),
                headerId: header.headerId,
                headerName: header.headerName,
                headerValue: header.headerValue,
              })),
              inputVariables,
              outputVariables
            )
          );
        } catch (error) {
          console.warn(error);
        }
      };

      if (body) {
        const { variables, headers, ...service } = body;
        const batchFlag = service.batchFlag ? 'BATCH' : 'SINGLE';
        const inputVariables: Variable[] = [];
        const outputVariables: Variable[] = [];

        headers?.length &&
          setHeaders(
            headers.map((header) => ({
              key: uuidv4(),
              headerName: header.headerName,
              headerValue: header.headerValue,
            }))
          );

        variables?.length &&
          variables?.forEach((variable) => {
            const newVariable: Variable = {
              key: uuidv4(),
              variableName: variable.variableName,
              sourcePath: variable.sourcePath,
              isArray: variable.arrayFlag,
              isComplex: !!variable.complexTypeVersionId,
              variableTypeId: variable.complexTypeVersionId
                ? variable.complexTypeVersionId
                : String(variable.primitiveTypeId),
            };

            variable.parameterType === 'IN'
              ? inputVariables.push(newVariable)
              : outputVariables.push(newVariable);
          });

        setInputVariables(inputVariables);
        setOutputVariables(outputVariables);
        setService({ ...service, batchFlag });

        setStartBody(
          getBody(
            { ...service, batchFlag },
            (headers || []).map((header) => ({
              key: uuidv4(),
              headerName: header.headerName,
              headerValue: header.headerValue,
            })),
            inputVariables,
            outputVariables
          )
        );
      } else if (modalProps.versionId) {
        fetchService(String(modalProps.versionId));
      }

      setActiveThirdPanel('0');
    }, [body, modalProps.versionId]);

    useEffect(() => {
      if (!validation.isValid && showErrors) {
        !validation.errorOnlyByUnique &&
          setMessage({ text: translate('errorValues'), status: 'error' });

        validation.errorOnlyByUnique && setMessage(null);

        validation.notUnique &&
          setNotUniqueSourceNameMessage({
            text: translate('notUnique'),
            status: 'error',
          });

        !validation.notUnique && setNotUniqueSourceNameMessage(null);
      } else {
        setMessage(null);
        setNotUniqueSourceNameMessage(null);
      }
    }, [
      showErrors,
      validation.isValid,
      validation.notUnique,
      validation.errorOnlyByUnique,
    ]);

    const disableCollapsePanel = roles.includes(
      rolesList.EXT_SERVICE_READ_VARIABLES
    )
      ? undefined
      : 'disabled';

    return (
      <>
        <Modal
          isOn={isVisible}
          modalProps={{
            title: modalProps?.title || translate('title'),
            icon: modalProps?.icon || 'icon-calling_external_service',
            type: modalProps.type,
            width: modalProps?.width || '1080px',
            className: 'vertical-expanded',
            helpKey: 'external-service-settings',
            footer: (
              <ExtServiceFooter
                translate={translate}
                onSaveAsClick={onSaveAsClick}
                onSaveClick={onSaveClick}
                onCloseClick={onCloseClick}
                serviceId={modalProps.versionId}
              />
            ),
          }}
          page="administration"
          onClose={onCloseClick}
        >
          <div className={`${mainClass}__row`}>
            <TabPanel
              items={tabs}
              level={3}
              active={activeThirdPanel}
              onClick={(_, key) => {
                setActiveThirdPanel(key);
              }}
            />
          </div>

          <div className={mainClass}>
            <ExtServiceCallSettingsMessage message={message} />
            <ExtServiceCallSettingsMessage
              message={notUniqueSourceNameMessage}
            />
            {activeThirdPanel === '0' && (
              <>
                <div className={`${mainClass}__top`}>
                  <div className={`${mainClass}__name`}>
                    <Input
                      label={translate('serviceName')}
                      value={service.serviceName}
                      error={validation.errors?.serviceName}
                      onChange={(event) => {
                        onServiceChange('serviceName', event.target.value);
                      }}
                    />
                  </div>
                </div>
                <Collapse
                  className={`${mainClass}__accordion`}
                  expandIcon={({ isActive }) => (
                    <Icon
                      className={`${mainClass}__accordion-icon`}
                      name="icon-angle_down"
                      rotate={isActive ? 0 : 180}
                    />
                  )}
                  defaultActiveKey={`${mainClass}-accordion-1`}
                  ghost
                >
                  <Collapse.Panel
                    header={
                      <div className={`${mainClass}__accordion-header`}>
                        <span>{translate('generalSettings')}</span>
                      </div>
                    }
                    key={`${mainClass}-accordion-1`}
                  >
                    <ExtServiceGeneralSettings
                      service={service}
                      translate={translate}
                      onServiceChange={onServiceChange}
                      errors={validation.errors}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel
                    header={
                      <div className={`${mainClass}__accordion-header`}>
                        <span>{translate('inputVariables')}</span>
                        <div
                          className={`${mainClass}__accordion-toolbar`}
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Toolbar data={inputVariablesActions} />
                        </div>
                      </div>
                    }
                    key={`${mainClass}-accordion-2`}
                    collapsible={disableCollapsePanel}
                  >
                    <div className={`${mainClass}-input-variables`}>
                      <SimpleTable
                        columnsData={inputVariablesColumns}
                        dataSource={inputVariables}
                        selectedRowKeys={inputVariablesRowKeys}
                        setSelectedRowKeys={setInputVariablesRowKeys}
                      />
                    </div>
                  </Collapse.Panel>
                  <Collapse.Panel
                    header={
                      <div className={`${mainClass}__accordion-header`}>
                        <span>{translate('body')}</span>
                      </div>
                    }
                    key={`${mainClass}-accordion-3`}
                  >
                    <div className={`${mainClass}-request-generating`}>
                      <TextArea
                        className={`${mainClass}-request-generating__text-editor`}
                        value={service.body}
                        onChange={(event) => {
                          onServiceChange('body', event.target.value);
                        }}
                        disabled={!roles.includes(rolesList.EXT_SERVICE_UPDATE)}
                      />
                    </div>
                  </Collapse.Panel>
                  <Collapse.Panel
                    header={
                      <div className={`${mainClass}__accordion-header`}>
                        <span>{translate('outputVariables')}</span>
                        <div
                          className={`${mainClass}__accordion-toolbar`}
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Toolbar data={outputVariablesActions} />
                        </div>
                      </div>
                    }
                    key={`${mainClass}-accordion-4`}
                    collapsible={disableCollapsePanel}
                  >
                    <div className={`${mainClass}-output-variables`}>
                      <SimpleTable
                        columnsData={outputVariablesColumns}
                        dataSource={outputVariables}
                        selectedRowKeys={outputVariablesRowKeys}
                        setSelectedRowKeys={setOutputVariablesRowKeys}
                      />
                    </div>
                  </Collapse.Panel>
                  <Collapse.Panel
                    header={
                      <div className={`${mainClass}__accordion-header`}>
                        <span>{translate('headers')}</span>
                        <div
                          className={`${mainClass}__accordion-toolbar`}
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Toolbar data={headersActions} />
                        </div>
                      </div>
                    }
                    key={`${mainClass}-accordion-5`}
                  >
                    <div className={`${mainClass}-output-variables`}>
                      <SimpleTable
                        columnsData={headersColumns}
                        dataSource={headers}
                        selectedRowKeys={headersRowKeys}
                        setSelectedRowKeys={setHeadersRowKeys}
                      />
                    </div>
                  </Collapse.Panel>
                </Collapse>
              </>
            )}
            {activeThirdPanel === '1' && (
              <ExtServiceCallSettingsVersions
                serviceId={modalProps.serviceId}
                version={modalProps.versionId}
                body={valuesObject}
                setActiveThirdPanel={setActiveThirdPanel}
              />
            )}
          </div>
        </Modal>

        {openedModal?.type === 'confirm' && (
          <ConfirmModal
            modalProps={{
              type: openedModal.type,
              contentText: openedModal.contentText,
              okButtonText: translate('yes'),
              cancelButtonModifiers: ['second', 'hover-box-shadow'],
              cancelButtonText: translate('no'),
              closeButtonText: translate('cancel'),
            }}
            onOk={openedModal.onOk}
            onCancel={() => {
              setOpenedModal(null);
              onClose();
              removeAdministrationOpenModal({});
              setSavedBody(null);
            }}
            onClose={() => {
              setOpenedModal(null);
            }}
          />
        )}
      </>
    );
  };
