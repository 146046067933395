/* eslint-disable require-jsdoc */
import { apiBase } from '@modules/apiBase';
import {
  IComplexTypeExt,
  IComplexTypeAttributeExt,
  INewComplexTypeWithAttributes,
  IComplexTypeBase,
  IComplexTypeAttributeBase,
  IComplexTypeAttribute,
  IComplexTypeVersion,
  IComplexTypeWithAttributes,
} from '../types/interface';

const COMPLEX_TYPE_ENDPOINT = 'complextype';

class ComplexType {
  async getComplexTypes() {
    return await apiBase.get<IComplexTypeExt[]>(COMPLEX_TYPE_ENDPOINT);
  }
  async getComplexType(versionId: string) {
    return await apiBase.get<IComplexTypeWithAttributes>(
      `${COMPLEX_TYPE_ENDPOINT}/${versionId}`
    );
  }
  async addComplexType(body: INewComplexTypeWithAttributes) {
    return await apiBase.post(COMPLEX_TYPE_ENDPOINT, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
  async updateComplexType(typeId: string, body: IComplexTypeBase) {
    return await apiBase.put(`${COMPLEX_TYPE_ENDPOINT}/${typeId}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
  async deleteComplexType(typeId: string) {
    return await apiBase.delete(`${COMPLEX_TYPE_ENDPOINT}/${typeId}`);
  }
  async getComplexTypeAttributes(typeId: string) {
    return await apiBase.get<IComplexTypeAttributeExt[]>(
      `${COMPLEX_TYPE_ENDPOINT}/${typeId}/attributes`
    );
  }
  async updateComplexTypeAttribute(
    typeId: string,
    body: IComplexTypeAttribute
  ) {
    return await apiBase.put(
      `${COMPLEX_TYPE_ENDPOINT}/${typeId}/attributes`,
      body,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }
  async addComplexTypeAttribute(
    typeId: string,
    body: IComplexTypeAttributeBase
  ) {
    return await apiBase.post(
      `${COMPLEX_TYPE_ENDPOINT}/${typeId}/attributes`,
      body,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }
  async deleteComplexTypeAttributes(typeId: string, attributeId: string) {
    return await apiBase.delete(
      `${COMPLEX_TYPE_ENDPOINT}/${typeId}/attributes/${attributeId}`
    );
  }
  async getComplexTypeMap(typeId: string) {
    return await apiBase.get<any>(`${COMPLEX_TYPE_ENDPOINT}/${typeId}/map`);
  }
  async getComplexTypeVersions(typeId: string) {
    return await apiBase.get<IComplexTypeVersion[]>(
      `${COMPLEX_TYPE_ENDPOINT}/${typeId}/versions`
    );
  }
}

export const complexTypeApi = new ComplexType();
