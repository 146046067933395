import { FC, Key, useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './ChooseCustomCodeModal.scss';
import { Button } from '@shared/Button/Button';
import { SimpleTable } from '@shared/SimpleTable/SimpleTable';
import {
  useModalVisible,
  InputSearch,
  IModalProps,
  Modal,
  ModalBox,
} from '@shared';
import { Icon } from '@modules/icon';
import {
  addCustomCode,
  getCustomCodes,
  selectCustomCodes,
} from '@modules/development/DevelopmentSlice';
import { useTranslationPath } from '@modules/languageProvider';
import { ICustomCode } from '@modules/development';
import { CUSTOM_CODES_PATH } from '../../../../../routing/developmentConstants';

interface IChooseCustomCodeModal {
  onSave: (script: ICustomCode) => void;
  onClose: () => void;
  modalProps: IModalProps | null;
  closeOnSave?: boolean;
}

export const ChooseCustomCodeModal: FC<IChooseCustomCodeModal> = ({
  modalProps,
  onClose,
  onSave,
  closeOnSave,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const translate = useTranslationPath(
    'development.diagram.diagram.modals.chooseCustomCode'
  );

  const isVisibleChooseCustomCode = useModalVisible(modalProps?.type);
  const customCodes = useSelector(selectCustomCodes);
  const [selectedCodeIds, setSelectedCodeIds] = useState<Key[]>([]);
  const [search, setSearch] = useState<string>('');
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);

  const onAddCustomCode = async () => {
    const uuid = uuidv4();
    await dispatch(addCustomCode({ key: uuid }));
    history.push(`${CUSTOM_CODES_PATH}/${uuid}`);
  };

  const onEditCustomCode = (key: string) => {
    history.push(`${CUSTOM_CODES_PATH}/${key}`);
  };

  const columns = useMemo(() => {
    return [
      {
        title: translate('name'),
        dataIndex: 'title',
        key: 'title',
        width: '50%',
        render: (value: string, row: any) => {
          return row?.children?.length ? (
            <div className="choose-custom-code__table-parent-cell">
              <Icon name="icon-folder" />
              {value}
            </div>
          ) : (
            value
          );
        },
      },
      {
        title: translate('code'),
        dataIndex: 'code',
        key: 'code',
        width: '25%',
        ellipsis: true,
        render: (value: string) => (
          <Tooltip placement="topLeft" title={value}>
            <span className="choose-custom-code__table-markered-cell">
              {value}
            </span>
          </Tooltip>
        ),
      },
      {
        title: translate('version'),
        dataIndex: 'version',
        key: 'version',
        width: '25%',
        ellipsis: true,
        render: (value: string) => (
          <Tooltip placement="topLeft" title={value}>
            <span className="choose-custom-code__table-markered-cell">
              {value}
            </span>
          </Tooltip>
        ),
      },
    ];
  }, []);

  const dataSource = useMemo(() => {
    const result = customCodes.data.map((f) => ({
      ...f,
      code: f.scriptId,
      version: f.versionId,
    }));

    if (search) {
      const filteredResult = result.filter((f) =>
        f.title.toLowerCase().includes(search.toLowerCase())
      );
      setExpandedRowKeys([1]);
      return filteredResult;
    } else {
      setExpandedRowKeys([]);
    }

    return result;
  }, [selectedCodeIds, customCodes, search]);

  const actions = useMemo(() => {
    return [
      {
        icon: 'icon-add',
        tooltip: t('actions.add'),
        onClick: onAddCustomCode,
      },
      {
        icon: 'icon-edit',
        tooltip: t('actions.edit'),
        disabled: !selectedCodeIds.length,
        onClick: () => onEditCustomCode(String(selectedCodeIds[0])),
      },
    ];
  }, [t, customCodes, selectedCodeIds]);

  const saveData = useCallback(() => {
    const saveData = customCodes.data.find((f) => f.key === selectedCodeIds[0]);
    if (!saveData) return;
    onSave(saveData);
    closeOnSave && onClose();
  }, [selectedCodeIds, customCodes]);

  useEffect(() => {
    dispatch(getCustomCodes());
  }, []);

  return (
    <>
      <Modal
        isOn={isVisibleChooseCustomCode}
        onClose={onClose}
        modalProps={{
          title: modalProps?.title || translate('title'),
          type: modalProps?.type,
          icon: 'icon-custom_code',
          width: '640px',
        }}
      >
        <div className="choose-custom-code">
          <div className="choose-custom-code__search">
            <InputSearch
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              bordered
            />
          </div>
          <ModalBox
            className="choose-custom-code__table"
            actions={actions}
            showHeader
          >
            <SimpleTable
              columnsData={columns}
              dataSource={[
                {
                  title: 'CustomCode',
                  key: 1,
                  children: dataSource,
                  selectable: false,
                },
              ]}
              selectedRowKeys={selectedCodeIds}
              setSelectedRowKeys={setSelectedCodeIds}
              scroll={{ y: 280 }}
              isSingleChoice
              hideSelectAll
              expandable={{
                expandedRowKeys: expandedRowKeys.length
                  ? expandedRowKeys
                  : undefined,
              }}
            />
          </ModalBox>
          <div className="choose-custom-code__actions btn-group-bottom">
            <Button
              kind="normal"
              modifiers={['hover-box-shadow']}
              onClick={saveData}
            >
              {translate('ok')}
            </Button>
            <Button
              kind="normal"
              modifiers={['second', 'hover-box-shadow']}
              onClick={onClose}
            >
              {translate('cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
