import { TConnectionsFormSettings } from '@modules/connections/hooks';
import { ConnectionsDataSourceModalFields } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';

export const rawFieldsBrokerMessageOutBorder: TConnectionsFormSettings[] = [
  {
    type: 'select',
    key: ConnectionsDataSourceModalFields.brokerType,
    label: 'connections.sourceDataModal.formFields.typeBrokerMessage',
    required: true,
    props: {
      options: [
        {
          value: 'KAFKA',
          label: 'Kafka',
        },
      ],
    },
  },
];

export const rawFieldsBrokerMessageInBorder: TConnectionsFormSettings[] = [
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.bootstrapServers,
    label: 'connections.sourceDataModal.formFields.bootstrapServers',
    required: true,
    rules: [
      {
        pattern: /([a-z]:[0-9]{2,5})$/g,
        message: 'Введены некорректные символы',
      },
    ],
  },
  {
    type: 'file',
    key: ConnectionsDataSourceModalFields.truststoreFile,
    label: 'connections.sourceDataModal.formFields.truststoreFile',
  },
  {
    type: 'file',
    key: ConnectionsDataSourceModalFields.keystoreFile,
    label: 'connections.sourceDataModal.formFields.keystoreFile',
  },
];
