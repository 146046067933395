import { HomePage } from '@modules/homePage/homePage';
import { NotFound } from '@modules/shared';
import { lazy } from 'react';
import {
  ADMINISTRATION_PATH,
  APP_CORE_PATH,
  DEVELOPMENT_PATH,
  ENVIRONMENTS_PATH,
  NOT_FOUND_PATH,
  OPERATIONAL_REPORTING_PATH,
  CONNECTIONS_PATH,
} from './appConstants';

const DevelopmentPage = lazy(() => import('@modules/development'));
const OperationalReportingPage = lazy(
  () => import('@modules/operationalReporting')
);
const AdministrationPage = lazy(() => import('../../pages/AdministrationPage'));
const EnvironmentsPage = lazy(() => import('../../pages/EnvironmentsPage'));
const ConnectionsPage = lazy(() => import('@modules/connections'));

// @ts-ignore
const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

const APP_ROUTES = [
  {
    path: APP_CORE_PATH,
    component: HomePage,
    exact: true,
  },
  {
    path: DEVELOPMENT_PATH,
    component: DevelopmentPage,
  },
  {
    path: OPERATIONAL_REPORTING_PATH,
    component: OperationalReportingPage,
  },
  {
    path: ENVIRONMENTS_PATH,
    component: EnvironmentsPage,
  },
  {
    path: ADMINISTRATION_PATH,
    component: AdministrationPage,
  },
  {
    path: CONNECTIONS_PATH,
    component: ConnectionsPage,
  },
  {
    path: NOT_FOUND_PATH,
    component: NotFound,
  },
];

if (HIDE_FEATURES) delete APP_ROUTES[2];

export { APP_ROUTES };
