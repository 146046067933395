import { Key } from 'react';
import { IDataVariables, IDiagramNode } from '@modules/development';

export type ScoreValue = {
  scoreValue: number;
  value: number | string | null;
  minValue: number | string | null;
  maxValue: number | string | null;
  includeMinValue: boolean | null;
  includeMaxValue: boolean | null;
};

export type OutputVariable = IDataVariables & {
  defaultValue: number | string;
};

export type InputVariable = IDataVariables & {
  defaultValue: number;
  scoreValues: ScoreValue[];
};

export interface IScorecardProperties {
  outputVariable: OutputVariable;
  inputVariablesMapping: InputVariable[];
}

export type ScorecardNode = IDiagramNode<IScorecardProperties>;

export type MappingRow = {
  key: Key;
  variableKey: string;
  variableValueMapScore: ScoreValue;
};

export enum ModalTypes {
  scorecardAddElem = 'scorecardAddElem',
  scorecardInputVal = 'scorecardInputVal',
  confirmSave = 'confirmSave',
}

export type OpenedModal =
  | {
      type: ModalTypes.scorecardAddElem;
      title: string;
      isOutput: boolean;
      variableName: string;
      index: number;
    }
  | {
      type: ModalTypes.scorecardInputVal;
      title: string;
      value:
        | {
            value: number | string;
          }
        | {
            from: number | '*';
            to: number | '*';
            includeFrom: boolean;
            includeTo: boolean;
          }
        | undefined;
      typeId: string;
      key: Key;
    }
  | { type: ModalTypes.confirmSave; contentText: string }
  | null;

export type InvalidData = {
  inputs: { [key: string]: string };
  inputVariables: { [key: string]: { [key: string]: string } };
  outputVariable: { [key: string]: string };
  scoreValues: { [key: string]: { [key: string]: string } };
};
