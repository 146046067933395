import React, {
  useEffect,
  useState,
  Suspense,
  useCallback,
  KeyboardEvent,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Stream.scss';
import {
  ConfirmModal,
  Modal,
  SaveAsModal,
  TabPanel,
  Loader,
  Toolbar,
} from '@shared';
import { Icon } from '@modules/icon';
import { Navigation } from './Navigation';
import { CloseObjectButton } from '@modules/development';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { useDiagramToolbar } from './useStreamToolbar';
import {
  selectDiagrams,
  diagram,
  saveAsDiagram,
  selectCurrentDiagram,
  selectCurrentObjectReadonly,
  selectIsSavedObject,
  selectDevelopmentIsLoading,
} from '@modules/development/DevelopmentSlice';
import { useStreamData } from './useStreamData';
import { selectRoles, rolesList } from '@modules/services';
import { useUserGuide } from '@modules/userGuide';
import { tabs } from './data';
import { STREAMS_CORE_PATH } from '../../routing/streamsConstants';
import { useTranslationPath } from '@modules/languageProvider';

interface IDiagram {
  location: any;
}

const Stream: React.FC<IDiagram> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const translateActions = useTranslationPath('actions');
  const diagrams = useAppSelector(selectDiagrams);
  const currentDiagram = useAppSelector(diagram);
  const currentObjectAllInfo = useAppSelector(selectCurrentDiagram);
  const currentDiagramReadonly = useAppSelector(selectCurrentObjectReadonly);
  const isObjectSaved = useAppSelector(selectIsSavedObject);
  const loading = useAppSelector(selectDevelopmentIsLoading);

  const [activeThirdPanel, setActiveThirdPanel] = useState('');

  const [currentObject] = useStreamData(props?.location?.state);
  const [getLink] = useUserGuide();

  const [openedModal, setOpenedModal, toolbarRender, onSave] =
    useDiagramToolbar({
      currentObject,
    });
  const roles = useAppSelector(selectRoles);

  const onClickSaveAsModal = async (title: string) => {
    // // существует ли диаграмма с таким названием
    // // TODO тк список диаграмм берется только на странице списка диаграмм, то если этот список не был получен
    // // то могут быть повторения
    // // чтобы каждый раз на этой странице не делать запрос на список диаграмм
    // // то мб стоит запрошивать их в родительском компоненте
    // // на странице development или в диаграмме, но при монтировании компонента
    const checkTitle = diagrams.data.filter(
      (item) => item.diagramName === title
    );
    if (checkTitle[0]) {
      alert(t('development.diagram.errorNameAlreadyUsed'));
    } else {
      const body = {
        diagramName: title,
        diagramDescription: currentObjectAllInfo.diagramDescription,
        diagramId: currentObjectAllInfo.diagramId,
        versionId: currentObjectAllInfo.versionId,
        errorResponseFlag: currentObjectAllInfo.errorResponseFlag,
      };
      const id = await dispatch(saveAsDiagram(body));
      history.push(`${STREAMS_CORE_PATH}/${id}`);
      onClickHideModal();
    }
  };

  const onClickHideModal = () => {
    setOpenedModal(null);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.ctrlKey && !currentDiagramReadonly) {
        if (event.key === 's' || event.key === 'ы') {
          event.preventDefault();
          onSave();
        }
      }
    },
    [currentDiagramReadonly, isObjectSaved]
  );

  const innerTabs = useMemo(() => {
    if (currentObject?.versionName) {
      return tabs.filter((tab) => tab.key !== '5');
    } else return tabs;
  }, [tabs, roles, currentObject]);

  useEffect(() => {
    tabs.forEach((data) => {
      if (data.path === '/' + history.location.pathname.split('/')[4]) {
        setActiveThirdPanel(data.key);
      }
    });
  }, [history.location.pathname.split('/')[4]]);

  const commonToolbar = useMemo(() => {
    return [
      {
        icon: 'icon-save',
        disabled:
          currentDiagramReadonly || !roles.includes(rolesList.DIAGRAM_UPDATE),
        tooltip: translateActions('save'),
        type: 'save_button',
        onClick: onSave,
      },
      {
        icon: 'icon-save_as',
        tooltip: translateActions('saveAs'),
        type: 'save_as_button',
        disabled: !roles.includes(rolesList.DIAGRAM_CREATE),
      },
      {
        icon: 'icon-send_deploy',
        disabled:
          currentDiagramReadonly ||
          !roles.includes(rolesList.DIAGRAM_MARK_TO_DEPLOY),
        tooltip: translateActions('sendOnDeploy'),
        type: 'send_deploy_button',
      },
      {
        icon: 'icon-download',
        tooltip: translateActions('download'),
        type: 'download_button',
        disabled: !roles.includes(rolesList.DIAGRAM_READ),
      },
    ];
  }, [currentDiagramReadonly, roles, i18n.language, currentObject?.isSaved]);

  return (
    <div className="stream" onKeyDown={handleKeyDown} tabIndex={0}>
      {loading && <Loader />}
      <div className="stream__top">
        <h1 className="stream__title">
          <Icon className="stream__title-icon" name="icon-development" />
          <span>
            {currentDiagram.title}
            {!!currentDiagramReadonly &&
              ` (${t('development.diagram.diagram.common.readOnly')})`}
          </span>
        </h1>
        <div className="stream__actions">
          <div className="stream__actions-button">
            {getLink(
              `diagram.${history.location.pathname.split('/')[4]}`,
              <Icon name="icon-help" />
            )}
          </div>
          <CloseObjectButton
            objectKey={currentDiagram.key}
            objectType="diagram"
            objectVersion={currentDiagram.version}
          />
        </div>
      </div>
      <div className="stream__row stream__row-top">
        <TabPanel
          items={innerTabs}
          level={3}
          active={activeThirdPanel}
          textAlignCenter
          onClick={(_, key) => {
            history.push(
              `${STREAMS_CORE_PATH}/${currentDiagram.key}${tabs[+key].path}`
            );
          }}
        />
      </div>
      <div className="stream__row">{toolbarRender()}</div>
      <div className="stream__content">
        <Suspense fallback={<Loader />}>
          <Navigation />
        </Suspense>
      </div>

      {openedModal?.type === 'save-as' && (
        <Modal
          isOn={openedModal?.type === 'save-as'}
          modalProps={{
            title: t('modals.createNewObject'),
            icon: 'icon-expression_editor',
            width: '510px',
          }}
          onClose={onClickHideModal}
        >
          <SaveAsModal onSave={onClickSaveAsModal} onClose={onClickHideModal} />
        </Modal>
      )}

      {openedModal?.type === 'confirm' && (
        <ConfirmModal modalProps={openedModal} onClose={onClickHideModal} />
      )}
    </div>
  );
};

export { Stream };
