import React, { FC, createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './i18n';

export const LanguageContext = createContext<{
  language: 'ru' | 'en';
  setLanguage: React.Dispatch<React.SetStateAction<'ru' | 'en'>>;
}>({
  language: 'ru',
  setLanguage: () => {},
});

export const LanguageProvider: FC = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<'ru' | 'en'>('ru');
  const value = { language, setLanguage };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};
