/* eslint-disable require-jsdoc */
import React, { ErrorInfo, ReactNode } from 'react';
import { InlineError } from './components/InlineError';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log(`error: ${error}`);
    console.log(`errorInfo: ${JSON.stringify(errorInfo)}`);
    console.log(`componentStack: ${errorInfo.componentStack}`);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <InlineError message="Ошибка" />;
    }

    return this.props.children;
  }
}
