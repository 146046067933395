import { CSSProperties, FC, ReactChild, useMemo, useState } from 'react';
import { Layout as AntdLayout } from 'antd';
import './mainLayout.scss';
import { Icon } from '@modules/icon';

const { Sider } = AntdLayout;

interface ISlotsChildren {
  header?: ReactChild;
  sidePanel?: ReactChild;
  mainContent: ReactChild;
  footer?: ReactChild;
}

interface IMainLayout {
  backgroundColor?: string;
}

export const MainLayout: FC<IMainLayout> = ({ backgroundColor, children }) => {
  const [collapsed, setCollapsed] = useState(true);

  const style: CSSProperties = {};
  if (backgroundColor) style['backgroundColor'] = backgroundColor;

  const isSlots = useMemo(() => {
    const isObject =
      typeof children === 'object' &&
      typeof children !== 'function' &&
      children != undefined;
    return isObject && 'mainContent' in children;
  }, [children]);

  if (!isSlots)
    return (
      <div className="layout__wrapper" style={style}>
        {children as ReactChild}
      </div>
    );

  const { header, sidePanel, mainContent, footer } = children as ISlotsChildren;
  return (
    <div className="layout__wrapper" style={style}>
      <header className="layout__header">{header}</header>
      <main className="layout__main">
        <Sider
          className="layout__sider sider"
          collapsed={collapsed}
          trigger={null}
          width={240}
          collapsedWidth={56}
          collapsible
        >
          <div className="sider__content">{sidePanel}</div>
          <div className="sider__footer">
            <button
              className="sider__footer-button"
              onClick={() => setCollapsed(!collapsed)}
            >
              <Icon name="icon-side_hiding" size={22} />
            </button>
          </div>
          <button
            className="sider__right-trigger"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon
              name="icon-angle_right"
              size={12}
              rotate={collapsed ? 0 : 180}
            />
          </button>
        </Sider>
        <section className="layout__content-wrapper">{mainContent}</section>
        <footer className="layout__footer">{footer}</footer>
      </main>
    </div>
  );
};
