import React, { Key, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Portal, useSearch } from '@shared';
import {
  selectDiagrams,
  selectOpenTabs,
  setReadonly,
  removeOpenTab,
  removeOpenObject,
  getSelectedDiagramsRow,
  onSelectedDiagramsRow,
  getDiagrams,
  deleteDiagram,
  renameDiagram,
  onChangeDiagramsSearch,
  currentObjectReadonly,
  onChangeDiagramsSort,
  selectDiagramSort,
  selectDevelopmentIsLoading,
} from '../../../../DevelopmentSlice';
import './StreamTable.scss';
import { DataGridList } from '@modules/development/components/DataGridList';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { selectRoles, rolesList } from '@modules/services';
import { STREAMS_CORE_PATH } from '../../routing/streamsConstants';
import { rawColumns } from './rawColumns';
import { StreamsDataTable } from '@modules/development/modules/streams/types/streamsTypes';

interface IDiagrams {
  data: StreamsDataTable[];
}

const StreamTable: React.FC<IDiagrams> = ({ data }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const diagrams = useAppSelector(selectDiagrams);
  const openTabs = useAppSelector(selectOpenTabs);
  const readonly = useAppSelector(currentObjectReadonly);
  const loading = useAppSelector(selectDevelopmentIsLoading);
  const sort = useAppSelector(selectDiagramSort);
  const roles = useAppSelector(selectRoles);

  const onClickOpen = (data: StreamsDataTable) => {
    if (roles.includes(rolesList.DIAGRAM_READ)) {
      if (readonly) dispatch(setReadonly({ readonly: false }));
      history.push(`${STREAMS_CORE_PATH}/${data.key}`);
    }
  };

  const onClickOpenViewingOnly = (data: StreamsDataTable) => {
    // TODO добавить ограничение действий на странице диаграммы
    dispatch(setReadonly({ readonly: true }));
    history.push({
      pathname: `${STREAMS_CORE_PATH}/${data.key}`,
      state: { readonly: true },
    });
  };

  const onClickDelete = async (data: StreamsDataTable) => {
    const isOpen = openTabs.find((item) => item.key === data.key);
    if (isOpen) {
      // если диаграмма открыта, то удалить ее из списка открытых табов
      // и из списка открытых объектов
      await dispatch(removeOpenTab({ key: data.key }));
      await dispatch(removeOpenObject({ key: data.key }));
    }
    await dispatch(deleteDiagram(data.key));
    await dispatch(getDiagrams());
  };

  const updateDiagram = (row: StreamsDataTable) => {
    dispatch(renameDiagram({ id: row.key, title: row.title }));
    // dispatch(diagramsUpdate({ diagramKey: row.key, title: row.title }));
  };

  const onSelectedRowKeys = (keys: Key[]) => {
    dispatch(onSelectedDiagramsRow({ keys }));
  };

  const onSearch = (search: string) => {
    dispatch(onChangeDiagramsSearch({ search }));
  };

  const onSortChange = (sort: any) => {
    dispatch(onChangeDiagramsSort(sort));
  };

  useEffect(() => {
    dispatch(getDiagrams());
    return () => onSearch('');
  }, []);

  const [renderSearchInput, getRefProps] = useSearch({
    search: diagrams.search,
    setSearch: onSearch,
  });

  return (
    <div className="diagrams" {...getRefProps()}>
      <Portal id="toolbarSearch">{renderSearchInput()}</Portal>
      <DataGridList<StreamsDataTable>
        data={data}
        sort={sort}
        loading={loading}
        onSortChange={onSortChange}
        selectedRow={getSelectedDiagramsRow}
        changeData={updateDiagram}
        onClickOpen={onClickOpen}
        onClickOpenViewingOnly={onClickOpenViewingOnly}
        onClickDelete={onClickDelete}
        onSelectedRowKeys={onSelectedRowKeys}
        onDoubleClick={onClickOpen}
        columns={rawColumns}
      />
    </div>
  );
};

export { StreamTable };
