import React, { FC, Key, SetStateAction, useEffect, useState } from 'react';
import { InputSearch, SimpleTable, Toolbar, Tooltip, useSearch } from '@shared';
import { TableData } from './data';
import { ColumnsType } from 'antd/lib/table';
import { Checkbox } from 'antd';
import { useTranslationPath } from '@modules/languageProvider';
import { complexTypeApi, IComplexTypeVersion } from '@modules/complexType';
import {
  ComplexTypeViewModal,
  ComplexTypeViewModalProps,
} from '../complexTypeViewModal';

interface ICustomTypeVersionsProps {
  complexTypeId?: string | null;
  setActiveTab: (value: SetStateAction<string>) => void;
}

const CustomTypeVersions: FC<ICustomTypeVersionsProps> = ({
  complexTypeId,
  setActiveTab,
}) => {
  const translate = useTranslationPath('development.diagram.versions');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();
  const [selectedVersion, setSelectedVersion] = useState<TableData[]>();
  const [search, setSearch] = useState('');
  const [versions, setVersions] = useState<IComplexTypeVersion[]>([]);
  const [viewModal, setViewModal] = useState<ComplexTypeViewModalProps | null>(
    null
  );

  const [, getRefProps, getInputProps] = useSearch({ search, setSearch });

  const deleteUserVersion = async (Ids: Key[]) => {
    await Promise.all(
      Ids.map((id) => complexTypeApi.deleteComplexType(String(id)))
    );
    complexTypeId && fetchVersions(complexTypeId);
  };

  const fetchVersions = async (complexTypeId: string) => {
    const versions = await complexTypeApi.getComplexTypeVersions(complexTypeId);
    setVersions(versions);
  };

  const render = (value: any) => <Tooltip title={value}>{value}</Tooltip>;

  useEffect(() => {
    complexTypeId && fetchVersions(complexTypeId);
  }, [complexTypeId]);

  const actions = [
    {
      icon: 'icon-delete',
      tooltip: translate('toolbar.delete'),
      disabled:
        !selectedRowKeys?.length ||
        selectedVersion?.some((v) => v.versionType === 'LATEST'),
      onClick: async () => {
        if (selectedRowKeys?.length) deleteUserVersion(selectedRowKeys);
      },
    },
    {
      icon: 'icon-refresh',
      tooltip: translate('toolbar.refresh'),
      onClick: () => complexTypeId && fetchVersions(complexTypeId),
    },
    { isDivider: true },
    {
      icon: 'icon-version_actual',
      tooltip: translate('toolbar.selectVersion'),
      disabled: !selectedRowKeys?.length || selectedRowKeys?.length > 1,
      onClick: () => {
        console.log('in progress...');
      },
    },
    {
      icon: 'icon-version_open',
      tooltip: translate('toolbar.openVersion'),
      disabled: !selectedRowKeys?.length || selectedRowKeys?.length > 1,
      onClick: () => {
        if (selectedVersion?.[0]?.versionType === 'LATEST') {
          return setActiveTab('0');
        }

        setViewModal({
          type: 'complexTypeView',
          versionId: selectedVersion?.[0]?.versionId,
          versionName: selectedVersion?.[0]?.versionName,
        });
        setSelectedRowKeys(undefined);
      },
    },
  ];

  const columns: ColumnsType<TableData> = [
    {
      key: 'versionName',
      dataIndex: 'versionName',
      title: translate('objectVersion'),
      ellipsis: true,
      showSorterTooltip: { title: translate('objectVersion') },
      sorter: (a, b) => a.versionName.localeCompare(b.versionName),
      render,
    },
    {
      key: 'versionId',
      dataIndex: 'versionId',
      title: translate('versionId'),
      ellipsis: true,
      showSorterTooltip: { title: translate('versionId') },
      sorter: (a, b) => a.versionId.localeCompare(b.versionId),
      render,
    },
    {
      key: 'versionType',
      dataIndex: 'versionType',
      title: translate('versionType'),
      ellipsis: true,
      showSorterTooltip: { title: translate('versionType') },
      sorter: (a, b) => a.versionType.localeCompare(b.versionType),
      render,
    },
    {
      key: 'lastChangeDt',
      dataIndex: 'lastChangeDt',
      title: translate('lastSaveDate'),
      ellipsis: true,
      showSorterTooltip: { title: translate('lastSaveDate') },
      sorter: (a, b) => a.lastChangeDt.localeCompare(b.lastChangeDt),
      render,
    },
    {
      key: 'lastChangeByUser',
      dataIndex: 'lastChangeByUser',
      title: translate('theUserWhoSavedTheChanges'),
      ellipsis: true,
      showSorterTooltip: { title: translate('theUserWhoSavedTheChanges') },
      sorter: (a, b) => a.lastChangeByUser.localeCompare(b.lastChangeByUser),
      render,
    },
    {
      key: 'dependentObjectFlag',
      dataIndex: 'rootObjectId',
      title: translate('dependentObjectAttribute'),
      ellipsis: true,
      showSorterTooltip: { title: translate('dependentObjectAttribute') },
      align: 'center',
      sorter: (a, b) => Number(a.rootObjectId) - Number(b.rootObjectId),
      render: (value) => <Checkbox checked={!!value} />,
    },
    {
      key: 'rootObjectId',
      dataIndex: 'rootObjectId',
      title: translate('idOfTheRootDiagramForTheDependentObject'),
      ellipsis: true,
      showSorterTooltip: {
        title: translate('idOfTheRootDiagramForTheDependentObject'),
      },
      sorter: (a, b) => a.rootObjectId.localeCompare(b.rootObjectId),
      render,
    },
  ];

  const data: TableData[] = versions.map((v) => ({
    key: v.versionId,
    versionName: v.versionName,
    id: v.versionId,
    versionType: v.versionType,
    versionId: v.versionId,
    lastChangeDt: v.changeDt,
    lastChangeByUser: v.lastChangeByUser,
    innerObjecttSaveFlag: !!v.rootObjectVersionId,
    dependentObjectFlag: !!v.rootObjectVersionId,
    rootObjectId: v.rootObjectVersionId,
  }));

  return (
    <>
      <div className="diagram-versions" {...getRefProps()}>
        <div className="external-service-call-settings-modal__row">
          <Toolbar
            data={actions}
            search={<InputSearch {...getInputProps()} width={420} />}
          />
        </div>

        <div className="diagram-versions__table">
          <SimpleTable
            columnsData={columns}
            dataSource={data}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={(key, selectedRows: TableData[]) => {
              setSelectedRowKeys(key);
              setSelectedVersion(selectedRows);
            }}
          />
        </div>
      </div>

      <ComplexTypeViewModal
        modalProps={viewModal}
        onClose={() => setViewModal(null)}
      />
    </>
  );
};

export default CustomTypeVersions;
