import React, { FC, Key, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import './InputOutputDataScript.scss';
import { ModalBox, SimpleTable, Input, DataTypesSelect } from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import {
  addCustomCodeInputRow,
  addCustomCodeOutputRow,
  selectCurrentObjectReadonly,
  deleteCustomCodeInputRows,
  deleteCustomCodeOutputRows,
  selectCurrentObject,
  setCustomCodeInputIsArray,
  setCustomCodeInputName,
  setCustomCodeInputType,
  setCustomCodeOutputIsArray,
  setCustomCodeOutputName,
  setCustomCodeOutputType,
} from '@modules/development/DevelopmentSlice';
import { ICustomCode } from '@modules/development';
import { IDataType, selectDataTypes } from '@modules/references';
import { rolesList, selectRoles } from '@modules/services';
import { useAppSelector } from '@modules/store';

interface IInputOutputDataScript {}

const InputOutputDataScript: FC<IInputOutputDataScript> = () => {
  const dispatch = useDispatch();
  const customCode = useSelector(selectCurrentObject) as ICustomCode;
  const dataTypes = useSelector(selectDataTypes);
  const isReadOnly = useSelector(selectCurrentObjectReadonly);
  const translate = useTranslationPath(
    'development.customCode.inputOutputDataScript'
  );

  const [inputSelectedRowKeys, setInputSelectedRowKeys] = useState<Key[]>([]);
  const [outputSelectedRowKeys, setOutputSelectedRowKeys] = useState<Key[]>([]);
  const roles = useAppSelector(selectRoles);
  const inputTable = useMemo(() => {
    if (!customCode) return {};

    const handleNameChange = (name: string, key: number) => {
      dispatch(setCustomCodeInputName({ key, name }));
    };

    const handleIsArrayChange = (isArray: boolean, key: number) => {
      dispatch(setCustomCodeInputIsArray({ key, isArray }));
    };

    const handleTypeChange = (key: number, selectedDataType?: IDataType) => {
      if (selectedDataType) {
        const { typeId, complexFlag: isComplex } = selectedDataType;
        dispatch(setCustomCodeInputType({ key, typeId, isComplex }));
      }
    };

    const columns = [
      {
        title: translate('attribute'),
        dataIndex: 'attributeName',
        key: 'attributeName',
        width: '50%',
        render: (value: string, data: any) => (
          <Input
            value={value}
            onChange={(e) => handleNameChange(e.target.value, data.key)}
            disabled={isReadOnly || !roles.includes(rolesList.SCRIPT_UPDATE)}
          />
        ),
      },
      {
        title: translate('array'),
        dataIndex: 'attributeIsArray',
        key: 'attributeIsArray',
        width: '10%',
        render: (value: string, data: any) => (
          <Checkbox
            checked={!!value}
            onChange={(e) => handleIsArrayChange(e.target.checked, data.key)}
            disabled={isReadOnly || !roles.includes(rolesList.SCRIPT_UPDATE)}
          />
        ),
      },
      {
        title: translate('type'),
        dataIndex: 'attributeTypeName',
        key: 'attributeTypeName',
        width: '40%',
        render: (value: string, data: any) => (
          <DataTypesSelect
            value={value}
            onChange={(dataType) => handleTypeChange(data.key, dataType)}
            disabled={isReadOnly || !roles.includes(rolesList.SCRIPT_UPDATE)}
          />
        ),
      },
    ];

    // NEW VERSIONING
    const data = customCode.variables
      ?.filter((v) => v.variableType === 'IN')
      .map((item, index) => {
        return {
          key: `input-${index}`,
          attributeName: item.variableName,
          attributeIsArray: item.arrayFlag,
          attributeTypeName: dataTypes.find(
            (dt) =>
              dt.typeId === item.primitiveTypeId ||
              dt.typeId === item.complexTypeVersionId
          )?.displayName,
        };
      });

    const actions = [
      {
        icon: 'icon-add',
        onClick: () => dispatch(addCustomCodeInputRow({})),
        disabled: !roles.includes(rolesList.SCRIPT_UPDATE),
      },
      {
        icon: 'icon-delete',
        onClick: () => {
          dispatch(deleteCustomCodeInputRows({ keys: inputSelectedRowKeys }));
          setInputSelectedRowKeys([]);
        },
        disabled: !roles.includes(rolesList.SCRIPT_UPDATE),
      },
    ];

    const rows = inputSelectedRowKeys;

    const setRows = setInputSelectedRowKeys;

    return { columns, data, actions, rows, setRows };
  }, [translate, customCode, dataTypes, inputSelectedRowKeys]);

  const outputTable = useMemo(() => {
    if (!customCode) return {};

    const handleNameChange = (name: string, key: number) => {
      dispatch(setCustomCodeOutputName({ key, name }));
    };

    const handleIsArrayChange = (isArray: boolean, key: number) => {
      dispatch(setCustomCodeOutputIsArray({ key, isArray }));
    };

    const handleTypeChange = (key: number, selectedDataType?: IDataType) => {
      if (selectedDataType) {
        const { typeId, complexFlag: isComplex } = selectedDataType;
        dispatch(setCustomCodeOutputType({ key, typeId, isComplex }));
      }
    };

    const columns = [
      {
        title: translate('attribute'),
        dataIndex: 'attributeName',
        key: 'attributeName',
        width: '50%',
        render: (value: string, data: any) => (
          <Input
            value={value}
            onChange={(e) => handleNameChange(e.target.value, data.key)}
            disabled={isReadOnly || !roles.includes(rolesList.SCRIPT_UPDATE)}
          />
        ),
      },
      {
        title: translate('array'),
        dataIndex: 'attributeIsArray',
        key: 'attributeIsArray',
        width: '10%',
        render: (value: string, data: any) => (
          <Checkbox
            checked={!!value}
            onChange={(e) => handleIsArrayChange(e.target.checked, data.key)}
            disabled={isReadOnly || !roles.includes(rolesList.SCRIPT_UPDATE)}
          />
        ),
      },
      {
        title: translate('type'),
        dataIndex: 'attributeTypeName',
        key: 'attributeTypeName',
        width: '40%',
        render: (value: string, data: any) => (
          <DataTypesSelect
            value={value}
            onChange={(dataType) => handleTypeChange(data.key, dataType)}
            disabled={isReadOnly || !roles.includes(rolesList.SCRIPT_UPDATE)}
          />
        ),
      },
    ];

    // NEW VERSIONING
    const data = customCode.variables
      ?.filter((v) => v.variableType === 'OUT')
      .map((item, index) => {
        return {
          key: `output-${index}`,
          attributeName: item.variableName,
          attributeIsArray: item.arrayFlag,
          attributeTypeName: dataTypes.find(
            (dt) =>
              dt.typeId === item.primitiveTypeId ||
              dt.typeId === item.complexTypeVersionId
          )?.displayName,
        };
      });

    const actions = [
      {
        icon: 'icon-add',
        onClick: () => dispatch(addCustomCodeOutputRow({})),
        disabled: !roles.includes(rolesList.SCRIPT_UPDATE),
      },
      {
        icon: 'icon-delete',
        onClick: () => {
          dispatch(deleteCustomCodeOutputRows({ keys: outputSelectedRowKeys }));
          setOutputSelectedRowKeys([]);
        },
        disabled: !roles.includes(rolesList.SCRIPT_UPDATE),
      },
    ];

    const rows = outputSelectedRowKeys;

    const setRows = setOutputSelectedRowKeys;

    return { columns, data, actions, rows, setRows };
  }, [translate, customCode, dataTypes, outputSelectedRowKeys]);

  return (
    <div className="input-output-data-script">
      {customCode && (
        <div className="input-output-data-script__tables">
          <ModalBox
            title={translate('inputAttributes')}
            showHeader
            actions={inputTable.actions}
          >
            <SimpleTable
              columnsData={inputTable.columns}
              dataSource={inputTable.data}
              selectedRowKeys={inputTable.rows}
              setSelectedRowKeys={inputTable.setRows}
            />
          </ModalBox>
          <ModalBox
            title={translate('outputAttributes')}
            showHeader
            actions={outputTable.actions}
          >
            <SimpleTable
              columnsData={outputTable.columns}
              dataSource={outputTable.data}
              selectedRowKeys={outputTable.rows}
              setSelectedRowKeys={outputTable.setRows}
            />
          </ModalBox>
        </div>
      )}
    </div>
  );
};

export { InputOutputDataScript };
