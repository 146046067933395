import React, { VFC, useContext, useEffect } from 'react';
import { Menu, Dropdown, Radio } from 'antd';
import './Language.scss';
import { LanguageContext } from '@modules/languageProvider';

const Language: VFC = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  const languageCodes: any = {
    ru: 'Русский',
    en: 'English',
  };

  const onChangeLanguage = (e: any) => {
    setLanguage(e.target.value);
  };

  // @ts-ignore
  const englishOnly = window._env_.ENGLISH_ONLY === 'true';

  useEffect(() => {
    englishOnly && setLanguage('en');
  }, []);

  const languageMenu = (
    <Menu className="language__menu">
      <Radio.Group onChange={onChangeLanguage} value={language}>
        <Menu.Item>
          <Radio value={'ru'}>{languageCodes.ru}</Radio>
        </Menu.Item>
        <Menu.Item>
          <Radio value={'en'}>{languageCodes.en}</Radio>
        </Menu.Item>
      </Radio.Group>
    </Menu>
  );

  return englishOnly ? null : (
    <Dropdown overlay={languageMenu} className="language">
      <div className="language__inner">
        <img
          className="language__icon"
          src={`/images/icon-flag_${language + '.png'}`}
          width="30px"
        />
        <span>{languageCodes[language]}</span>
      </div>
    </Dropdown>
  );
};

export default Language;
