import React from 'react';
import { IToolbar, Toolbar } from '@shared';
import { Collapse } from 'antd';
const { Panel } = Collapse;
import { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel';
import classNames from 'classnames';
import { Icon } from '@modules/icon';
import './DataSourcePanel.scss';

interface IDataSourcePanel {
  title: string;
  panel: Omit<CollapsePanelProps, 'header'>;
  toolbar?: IToolbar;
}

export const DataSourcePanel: React.FC<IDataSourcePanel> = ({
  title,
  toolbar,
  panel,
  children,
}) => {
  const { className, ...restPanel } = panel;
  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <Icon name="icon-angle_down" rotate={isActive ? 0 : 180} />
      )}
      defaultActiveKey={`external-service-accordion-1`}
      ghost
      className="connections__collapse"
    >
      <Panel
        {...restPanel}
        className={classNames('connections__panel', className)}
        header={
          <div className="connections__accordion-header">
            <span>{title}</span>
            {toolbar && (
              <div
                className="connections__accordion-toolbar"
                onClick={(event) => event.stopPropagation()}
              >
                <Toolbar {...toolbar} />
              </div>
            )}
          </div>
        }
        forceRender
      >
        {children}
      </Panel>
    </Collapse>
  );
};
