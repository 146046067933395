import { ColumnsType } from 'antd/lib/table';
import { ConnectionsBrokerMessageDTO } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';

export const rawBrokerMessageColumns: ColumnsType<ConnectionsBrokerMessageDTO> =
  [
    {
      title: 'connections.sourceDataModal.brokerMessage.paramName',
      dataIndex: 'name',
      width: '33.3%',
      sorter: (a, b) => a?.name?.localeCompare(b.name),
    },
    {
      title: 'connections.sourceDataModal.brokerMessage.paramValue',
      dataIndex: 'value',
      width: '33.3%',
      sorter: (a, b) => a?.value?.localeCompare(b.value),
    },
    {
      title: 'connections.sourceDataModal.brokerMessage.confidentialSetting',
      dataIndex: 'isSecure',
      width: '33.3%',
      sorter: (a, b) =>
        a?.isSecure === b?.isSecure ? 0 : a?.isSecure ? -1 : 1,
    },
  ];
