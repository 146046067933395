import React, { useState, VFC } from 'react';
import './userVersionModal.scss';
import { Button, IModalProps, Input, Modal, TextArea } from '@shared';
import { Checkbox } from 'antd';
import { useTranslationPath } from '@modules/languageProvider';
import { deleteFalseValuesObject } from '@modules/utils';

const TRANSLATE_PATH = 'development.diagram.diagram.modals.userVersionModal';

export type UserVersionSaveData = {
  versionId?: string;
  versionName?: string;
  versionDescription?: string;
  globalFlag?: boolean;
};

type UserVersionModalProps = {
  modalProps: IModalProps;
  onSave: (saveData: UserVersionSaveData) => void;
  onClose: () => void;
  hideCheckbox?: boolean;
  closeOnSave?: boolean;
  data?: UserVersionSaveData;
  onUpdate?: (saveData: UserVersionSaveData) => void;
};

export const UserVersionModal: VFC<UserVersionModalProps> = ({
  modalProps,
  onSave,
  onClose,
  hideCheckbox,
  closeOnSave,
  data,
  onUpdate,
}) => {
  const translate = useTranslationPath(TRANSLATE_PATH);
  const [saveData, setSaveData] = useState<UserVersionSaveData>(data || {});

  const onChangeData = (newData: UserVersionSaveData) => {
    setSaveData(deleteFalseValuesObject(newData));
  };

  const onClickSave = () => {
    data && onUpdate ? onUpdate(saveData) : onSave(saveData);
    closeOnSave && onClickClose();
  };

  const onClickClose = () => {
    setSaveData({});
    onClose();
  };

  return (
    <Modal
      isOn={Boolean(modalProps)}
      modalProps={{
        title: translate('title'),
        type: modalProps?.type,
        width: '800px',
        footer: (
          <div className="btn-group-bottom">
            <Button
              kind="normal"
              modifiers={['hover-box-shadow']}
              onClick={onClickSave}
            >
              {translate('save')}
            </Button>
            <Button
              kind="normal"
              modifiers={['second', 'hover-box-shadow']}
              onClick={onClickClose}
            >
              {translate('cancel')}
            </Button>
          </div>
        ),
      }}
      onClose={onClose}
    >
      <div className="user-version">
        <div className="user-version__grid">
          {saveData.versionId && (
            <div className="user-version__item user-version__item--medium">
              <Input
                label={translate('versionId')}
                value={saveData.versionId}
                disabled
              />
            </div>
          )}
          <div className="user-version__item user-version__item--medium">
            <Input
              label={translate('versionName')}
              value={saveData.versionName}
              onChange={(e) =>
                onChangeData({ ...saveData, versionName: e.target.value })
              }
            />
          </div>
          <div className="user-version__item">
            <TextArea
              className="user-version__textarea"
              placeholder={translate('description')}
              label={translate('changesDescription')}
              value={saveData.versionDescription}
              onChange={(e) =>
                onChangeData({
                  ...saveData,
                  versionDescription: e.target.value,
                })
              }
              rows={5}
            />
          </div>
          {hideCheckbox ? null : (
            <div className="user-version__item">
              <Checkbox
                checked={saveData.globalFlag}
                onChange={(e) =>
                  !saveData.versionId &&
                  onChangeData({ ...saveData, globalFlag: e.target.checked })
                }
              >
                {translate('flagStoringVersionsNestedObjects')}
              </Checkbox>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
