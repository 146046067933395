import { Input, InputNumber, Select } from '@shared';
import classNames from 'classnames';
import React, { ReactNode, VFC } from 'react';
import { Service } from '../interfaces';
import { mainClass } from '../data';
import { useTranslationPath } from '@modules/languageProvider';

type ExtServiceGeneralItemProps = {
  label: keyof Service;
  size?: 'small' | 'large';
  value?: string | number;
  options?: {
    value: string;
    label?: string | undefined;
  }[];
  disabled?: boolean;
  onChange?: (key: keyof Service, value: string) => void;
  error?: string;
  addonBefore?: ReactNode;
  type?: string;
  number?: boolean;
};

export const ExtServiceGeneralItem: VFC<ExtServiceGeneralItemProps> = ({
  label,
  size,
  value,
  options,
  disabled,
  onChange,
  error,
  addonBefore,
  type,
  number,
}) => {
  const translate = useTranslationPath(
    'administrationTab.externalServiceCallSettingsModal'
  );
  const fieldClasses = classNames(
    `${mainClass}-general-settings__field`,
    size === 'small' && `${mainClass}-general-settings__field--small`,
    size === 'large' && `${mainClass}-general-settings__field--large`
  );

  const wrapperClasses = classNames(
    `${mainClass}-general-settings__item`,
    disabled && `${mainClass}-general-settings__item--disabled`
  );

  const InputNode = number ? (
    <InputNumber
      className={`${mainClass}-general-settings__field ${mainClass}-general-settings__field--number`}
      value={value}
      disabled={disabled}
      onChange={(value) => {
        if (value) onChange && onChange(label, value.toString());
        if (!value) onChange && onChange(label, '0');
      }}
      error={error}
      type={type}
    />
  ) : (
    <Input
      className={fieldClasses}
      value={value}
      disabled={disabled}
      onChange={(event) => onChange && onChange(label, event.target.value)}
      error={error}
      addonBefore={addonBefore}
      type={type}
    />
  );

  return (
    <div className={wrapperClasses}>
      <span className={`${mainClass}-general-settings__label`}>
        {translate(label)}
      </span>
      {options && typeof value !== 'number' ? (
        <Select
          className={fieldClasses}
          value={value}
          options={options}
          disabled={disabled}
          onChange={(value) => onChange && onChange(label, value)}
          error={error}
        />
      ) : (
        InputNode
      )}
    </div>
  );
};
