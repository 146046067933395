import {
  ConnectionsModalDataSourceState,
  OneConnectionDTO,
  ConnectionRdbmsResponse,
  ConnectionExtServicesResponse,
  ConnectionBrokerMessageResponse,
} from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import { AnyAction, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { showNotification } from '@modules/notification';
import {
  connectionsSliceName,
  EXTERNAL_SERVICES,
  MESSAGE_BROKERS,
} from '@modules/connections/constants';
import { mockVersionsData } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/components/externalServiceVersions/components/versionsTable/mockData';
import { TConnectionType } from '@modules/connections/types/connectionsTypes';
// import { mockModalDataSource } from '@modules/connections/components/connectionsModalDataSource/mockData';
import { v4 as uuidv4 } from 'uuid';
import { connectionsApi } from '@modules/connections/api';

export const fetchVersionsTable = createAsyncThunk(
  `${connectionsSliceName}/fetchVersionsTable`,
  async (serviceId: string, { rejectWithValue, dispatch }) => {
    try {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(mockVersionsData as any);
        }, 1000);
      });
    } catch (e: any) {
      dispatch(
        showNotification({
          type: 'error',
          message: 'error',
          duration: 3000,
        }) as unknown as AnyAction
      );
      return rejectWithValue(e);
    }
  }
);

export const fetchOneConnection = createAsyncThunk(
  `${connectionsSliceName}/fetchOneConnection`,
  async (
    {
      connectionId,
      type,
    }: {
      connectionId: string;
      type: TConnectionType;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      return {
        ...(await connectionsApi.getOneConnection(type, connectionId)),
        type: type,
      };
    } catch (e: any) {
      dispatch(
        showNotification({
          type: 'error',
          message: e?.message?.toString(),
          duration: 3000,
        }) as unknown as AnyAction
      );
      return rejectWithValue(e);
    }
  }
);

export const connectionsModalDataSourceInitialState: ConnectionsModalDataSourceState =
  {
    selectedVersions: [],
    versionsTable: [],
    versionsTableLoading: false,
    oneConnection: null,
  };

export const connectionsModalDataSourceReducers = {
  setSelectedVersions(
    state: ConnectionsModalDataSourceState,
    action: PayloadAction<React.Key[]>
  ) {
    state.selectedVersions = action.payload;
  },
};

export const connectionsModalDataSourceExtraReducers = {
  [fetchVersionsTable.fulfilled.type]: (
    state: ConnectionsModalDataSourceState,
    action: PayloadAction<any>
  ) => {
    state.versionsTable = action.payload.map((v: any) => ({
      key: v.versionId,
      version: v.versionName,
      id: v.versionId,
      type: v.versionType,
      dateLastChange: v.changeDt,
      userLastChange: v.lastChangeByUser,
      innerObjectSaveFlag: !!v.rootObjectVersionId,
      dependentObjectFlag: !!v.rootObjectVersionId,
      diagramId: v.rootObjectVersionId,
      data: {
        serviceId: v.serviceId,
        versionId: v.versionId,
        versionName: v.versionName,
        versionDescription: v.versionDescription,
      },
    }));
    state.versionsTableLoading = false;
  },
  [fetchVersionsTable.pending.type]: (
    state: ConnectionsModalDataSourceState
  ) => {
    state.versionsTableLoading = true;
  },
  [fetchOneConnection.fulfilled.type]: (
    state: ConnectionsModalDataSourceState,
    action: PayloadAction<
      OneConnectionDTO<
        ConnectionExtServicesResponse &
          ConnectionRdbmsResponse &
          ConnectionBrokerMessageResponse
      >
    >
  ) => {
    const { type = '', properties } = action.payload;
    console.log(action);

    if (type === EXTERNAL_SERVICES) {
      const { variables = [], headers = [] } = properties ?? {};

      state.oneConnection = {
        ...action.payload,
        properties: {
          ...properties,
          headers: headers.map((header: any) => ({ ...header, key: uuidv4() })),
          inputVariables: variables
            .filter(
              (variable: ConnectionExtServicesResponse['variables'][number]) =>
                variable.parameterType === 'IN'
            )
            .map(
              (
                variable: ConnectionExtServicesResponse['variables'][number]
              ) => ({
                key: uuidv4(),
                variableId: variable.variableId,
                variableName: variable.variableName,
                sourcePath: variable.sourcePath,
                isArray: variable.arrayFlag,
                isComplex: !!variable.complexTypeVersionId,
                variableTypeId: variable.complexTypeVersionId
                  ? variable.complexTypeVersionId
                  : String(variable.primitiveTypeId),
              })
            ),
          outputVariables: variables
            .filter(
              (variable: ConnectionExtServicesResponse['variables'][number]) =>
                variable.parameterType === 'OUT'
            )
            .map(
              (
                variable: ConnectionExtServicesResponse['variables'][number]
              ) => ({
                key: uuidv4(),
                variableId: variable.variableId,
                variableName: variable.variableName,
                sourcePath: variable.sourcePath,
                isArray: variable.arrayFlag,
                isComplex: !!variable.complexTypeVersionId,
                variableTypeId: variable.complexTypeVersionId
                  ? variable.complexTypeVersionId
                  : String(variable.primitiveTypeId),
              })
            ),
        },
      };
    } else if (type === MESSAGE_BROKERS) {
      const { parameters = [] } = properties ?? {};
      state.oneConnection = {
        ...action.payload,
        properties: {
          ...properties,
          parameters: parameters.map((param) => ({
            ...param,
            key: uuidv4(),
          })),
        },
      };
    } else {
      state.oneConnection = action.payload;
    }
  },
};
