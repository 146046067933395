import { IDiagram } from '../../../../types';
import { IAction } from '@modules/store';
import { findCurrentObject } from '../../../../utils';
import { WritableDraft } from 'immer/dist/internal';
import { IDevelopmentState } from '@modules/development/DevelopmentSlice';

const diagramTestingReportReducers = {
  resetDiagramTestReport: (
    state: WritableDraft<IDevelopmentState>,
    action: IAction<{ testId: string }>
  ) => {
    const { testId } = action.payload;

    const currentObject = findCurrentObject(
      state.openObjects,
      state.currentObject.key
    ) as IDiagram;

    if (currentObject && currentObject.testing) {
      currentObject.testing = {
        ...currentObject.testing,
        testReports: {
          ...currentObject.testing.testReports,
          [testId]: [],
        },
      };
    }
  },
};

export default diagramTestingReportReducers;
