import React, { VFC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { rolesList } from '@modules/services';
// import { CommunicationChannels } from '@modules/communicationChannels';
// import { Diagrams } from './modules/Diagrams/Diagrams';
import { CustomCodesList } from './modules/CustomCodesList/CustomCodesList';
import { CustomDataTypes } from './modules/CustomDataTypes/CustomDataTypes';
import { AggregatesList } from './modules/AggregatesList';
import {
  AGGREGATES_PATH,
  // COMMUNICATION_CHANNELS_PATH,
  CUSTOM_CODES_PATH,
  CUSTOM_DATA_TYPES_PATH,
  // DEVELOPMENT_CORE_PATH,
  // DIAGRAMS_PATH,
  NOT_FOUND_PATH,
  SCHEMAS_PATH,
} from './routing/developmentConstants';
import { Schemas } from './modules/Schemas/Schemas';

const Navigation: VFC<{ roles: string[] }> = ({ roles }) => (
  <Switch>
    {/* {roles.includes(rolesList.DIAGRAM_READ_ALL) && (
      <Route
        exact
        path={DEVELOPMENT_CORE_PATH}
        render={() => <Redirect to={DIAGRAMS_PATH} />}
      />
    )} */}
    {/* {roles.includes(rolesList.DIAGRAM_READ_ALL) && (
      <Route exact path={DIAGRAMS_PATH} component={Diagrams} />
    )} */}
    {roles.includes(rolesList.SCRIPT_READ_ALL) && (
      <Route path={CUSTOM_CODES_PATH} component={CustomCodesList} />
    )}
    {roles.includes(rolesList.COMPLEX_TYPE_READ_ALL) && (
      <Route exact path={CUSTOM_DATA_TYPES_PATH} component={CustomDataTypes} />
    )}
    {roles.includes(rolesList.COMPLEX_TYPE_READ_ALL) && (
      <Route exact path={SCHEMAS_PATH} component={Schemas} />
    )}
    {roles.includes(rolesList.AGGREGATE_READ_ALL_BY_GROUPING_ELEMENT) && (
      <Route path={AGGREGATES_PATH} component={AggregatesList} />
    )}
    {/* <Route
      path={COMMUNICATION_CHANNELS_PATH}
      component={CommunicationChannels}
    /> */}
    <Route path="*" render={() => <Redirect to={NOT_FOUND_PATH} />} />
  </Switch>
);

export { Navigation };
