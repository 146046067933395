export const ALL_CONNECTIONS = 'allConnections';
export const EXTERNAL_SERVICES = 'EXT_SERVICE';
export const DBMS = 'RDBMS';
export const MESSAGE_BROKERS = 'BROKER';
export const ORACLE_DB = 'ORACLE';
export const POSTGRES_DB = 'POSTGRES';
export const ANOTHER_DB = 'OTHER';
export const environmentPROD = 'PROD';
export const environmentTEST = 'TEST';
export const environmentDEV = 'DEV';
export const connectionsSliceName = 'connections';
