import React, { useMemo } from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';
import './RadioGroup.scss';

interface IOption {
  value: number | string;
  label: string;
}

interface IRadioGroup {
  value: number | string;
  options: IOption[];
  isVerticalDirection?: boolean;
  onChange: (e: RadioChangeEvent) => void;
}
const RadioGroup: React.FC<IRadioGroup> = ({
  value,
  options,
  isVerticalDirection,
  onChange,
}) => {
  const radioOptions = useMemo(
    () =>
      options.map((option) => (
        <Radio key={`radio-option_${option.value}`} value={option.value}>
          {option.label}
        </Radio>
      )),
    [options]
  );
  return (
    <div className="radio-group">
      <Radio.Group onChange={onChange} value={value}>
        {isVerticalDirection ? (
          <Space direction="vertical">{radioOptions}</Space>
        ) : (
          radioOptions
        )}
      </Radio.Group>
    </div>
  );
};

export { RadioGroup };
