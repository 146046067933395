import React, { useCallback, useEffect, createContext } from 'react';
import {
  useConnectionsActions,
  useConnectionsSelector,
  useConnectionsTable,
} from '@modules/connections/hooks';
import { ConnectionsTableData } from '@modules/connections/types/connectionsTypes';
import { fetchConnectionsTable } from '@modules/connections/ConnectionsSlice';
import { useAppDispatch } from '@modules/store';
import { Form, Table } from 'antd';
import { fetchOneConnection } from '@modules/connections/components/connectionsModalDataSource/ConnectionsModalDataSourceSlice';

export const ConnectionsTableFormContext = createContext<{
  saveName: () => void;
}>({} as any);

export const ConnectionsTable: React.FC = () => {
  const {
    activeConnectionType,
    selectedRows,
    activeConnectionRow,
    isLoading,
    tableData,
    activeEditRow,
  } = useConnectionsSelector();
  const dispatch = useAppDispatch();
  const {
    setSelectedRows,
    setActiveConnectionRow,
    setEditRow,
    setConnectionsTable,
    setVConnectionsDataSourceModal,
    setConnectionActiveId,
  } = useConnectionsActions();
  const { columns } = useConnectionsTable(activeConnectionType, tableData);
  const [form] = Form.useForm();

  const onDoubleClick = ({
    connectionId,
    selectable = true,
    type,
    key = '',
  }: ConnectionsTableData) => {
    setActiveConnectionRow(key);

    if (selectable) {
      const selectType = type ?? activeConnectionType;
      dispatch(fetchOneConnection({ connectionId, type: selectType }))
        .unwrap()
        .then(() => {
          setConnectionActiveId(connectionId);
          setVConnectionsDataSourceModal(true);
        });
    }
  };

  const rowClassName = useCallback(
    ({ connectionId }: ConnectionsTableData) =>
      connectionId === activeConnectionRow &&
      typeof activeConnectionRow === 'string'
        ? 'active-row'
        : '',
    [activeConnectionRow]
  );

  useEffect(() => {
    dispatch(fetchConnectionsTable(activeConnectionType));
  }, [activeConnectionType]);

  const saveName = async () => {
    try {
      await form.validateFields();
      const [[id, name]] = Object.entries(form.getFieldsValue());
      const updatedData = tableData.map((el) => ({
        ...el,
        name: (el.connectionId === id ? name : el.name) as string,
      }));
      setConnectionsTable(updatedData);
      setEditRow(null);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!activeEditRow) return;

    const editingName =
      tableData.find(({ connectionId }) => connectionId === activeEditRow)
        ?.name ?? '';

    form.setFieldsValue({
      [activeEditRow]: editingName,
    });
  }, [activeEditRow]);

  return (
    <>
      <Form form={form} component={false}>
        <ConnectionsTableFormContext.Provider value={{ saveName }}>
          <Table<ConnectionsTableData>
            className="connections__table"
            columns={columns}
            dataSource={tableData}
            rowSelection={{
              hideSelectAll: true,
              selectedRowKeys: selectedRows,
              onChange: (selectedRowKeys) => {
                setSelectedRows(selectedRowKeys);
              },
              getCheckboxProps: (record: ConnectionsTableData) => ({
                disabled: record.selectable === false,
                name: record.name,
              }),
            }}
            rowClassName={rowClassName}
            onRow={(record) => ({
              onClick: () => {}, // click row
              onDoubleClick: () => onDoubleClick(record as any),
              onContextMenu: () => {}, // right button click row
              onMouseEnter: () => {}, // mouse enter row
              onMouseLeave: () => {}, // mouse leave row
            })}
            pagination={false}
            loading={isLoading}
            rowKey="connectionId"
          />
        </ConnectionsTableFormContext.Provider>
      </Form>
    </>
  );
};
