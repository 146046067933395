import { createSelector } from '@reduxjs/toolkit';
import { RootState, useAppSelector } from '@modules/store';

export const useCommunicationChannelsSelector = () => {
  const selectData = (state: RootState) => state.сommunicationChannels.data;

  const data = useAppSelector(selectData);

  const selectedRowKeys = useAppSelector(
    (state: RootState) => state.сommunicationChannels.selectedRowKeys
  );

  const rowKeys = useAppSelector(
    createSelector(selectData, (data) => data.map((item) => item.key))
  );

  const search = useAppSelector(
    (state: RootState) => state.сommunicationChannels.search
  );

  return { data, selectedRowKeys, rowKeys, search };
};
