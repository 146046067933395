import React, { FC, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
// import { Badge } from 'antd';
import classNames from 'classnames';
import { useKeycloak } from '@react-keycloak/web';
import './Header.scss';
import { Settings } from '../settings/Settings';
import Language from '../language/Language';
import { Icon } from '@modules/icon';
import { useUserGuide } from '@modules/userGuide';
import { User } from '../user';
import { ConfirmModal } from '@modules/shared';
import { useTranslationPath } from '@modules/languageProvider';

interface IHeader {
  kind?: 'main' | 'second';
  customLogo?: ReactNode;
}

const Header: FC<IHeader> = ({ kind = 'main', customLogo }) => {
  const translation = useTranslationPath('layout');

  const [showModal, setShowModal] = useState<boolean>(false);

  const [getLink] = useUserGuide();
  const { keycloak } = useKeycloak();

  return (
    <>
      <div className={classNames('header', `header--${kind}`)}>
        {customLogo ? (
          customLogo
        ) : (
          <Link className="header__logo" to="/">
            <img className="header__logo-image" src="/images/logo.png" />
          </Link>
        )}
        <div className="header__btn-group">
          <div className="header__language group-item">
            <Language />
          </div>

          <div className="header__question group-item">
            <div className="question">
              {getLink('home', <Icon name="icon-question" size={22} />)}
            </div>
          </div>

          {keycloak.authenticated && (
            <>
              {/* <div className="header__notifications group-item">
                <div className="notifications">
                  <Badge size="small" count={4}>
                    <Icon name="icon-notifications" size={22} />
                  </Badge>
                </div>
              </div> */}

              <div className="header__settings group-item">
                <Settings />
              </div>

              <User
                className="header__user group-item"
                onClick={() => setShowModal(true)}
              />
            </>
          )}
        </div>
      </div>

      {showModal && (
        <ConfirmModal
          modalProps={{
            type: 'confirmModal',
            width: '400px',
            contentText: translation('logOutConfirmText'),
            okButtonText: translation('yes'),
            closeButtonText: translation('cancel'),
          }}
          onOk={keycloak.logout}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export { Header };
