import React, { Fragment, useState, VFC } from 'react';
import { IConfirmModalProps } from '@shared';
import { SolutionListsNew } from '@modules/development/components/solutionListsNew/SolutionListsNew';
import { useTranslation } from '@modules/languageProvider';
import {
  useCommunicationChannelsAction,
  useCommunicationChannelsSelector,
} from './hooks';
import {
  CommunicationChannelAddModal,
  CommunicationChannelsList,
} from './components';

type ModalProps = { type: 'addCommunication' } | IConfirmModalProps;

export const CommunicationChannels: VFC = () => {
  const { t } = useTranslation();
  const { rowKeys, selectedRowKeys } = useCommunicationChannelsSelector();
  const { setSelectedRowKeys, deleteItemsByKeys } =
    useCommunicationChannelsAction();

  const [modalProps, setModalProps] = useState<ModalProps | null>(null);

  return (
    <SolutionListsNew
      title="Каналы коммуникации"
      rowKeys={rowKeys}
      selectedRow={selectedRowKeys}
      onSelectedRowChange={setSelectedRowKeys}
      toolbarData={[
        {
          isDivider: true,
        },
        {
          icon: 'icon-add',
          onClick: () => {
            setModalProps({ type: 'addCommunication' });
          },
        },
        {
          icon: 'icon-refresh',
          onClick: () => console.log('refresh'),
        },
        {
          icon: 'icon-delete',
          disabled: !selectedRowKeys.length,
          onClick: () => {
            setModalProps({
              type: 'confirmDelete',
              width: '440px',
              okButtonText: t('modals.yes'),
              closeButtonText: t('modals.no'),
              contentText: 'Вы уверены, что хотите удалить выбранные объекты?',
              kind: 'info',
            });
          },
        },
        {
          icon: 'icon-selection_view',
          onClick: () => console.log('view'),
        },
      ]}
      modalProps={modalProps}
      onDeleteClick={() => deleteItemsByKeys(selectedRowKeys)}
      setModalProps={setModalProps}
    >
      <Fragment>
        <CommunicationChannelsList />

        {modalProps?.type === 'addCommunication' && (
          <CommunicationChannelAddModal
            modalProps={{ type: modalProps.type }}
            onClose={() => setModalProps(null)}
          />
        )}
      </Fragment>
    </SolutionListsNew>
  );
};
