import { IDataVariables, IDiagramNode } from '@modules/development';
import { Key } from 'react';

export type ReadVariable = IDataVariables & {
  nodeVariable: string;
};

export interface IReadProperties {
  dataProviderUuid: string;
  query: string;
  allowMultiResultResponse: boolean;
  outputVariablesMapping: ReadVariable[];
  selectedTableNames: string[];
}

export type ReadNode = IDiagramNode<IReadProperties>;

export type DataBaseTableData = {
  key: Key;
  tableName: string;
  columnName: string;
  selectable: boolean;
  children?: DataBaseTableData[];
};

export enum ModalTypes {
  addDataTables = 'add-data-tables',
  addElement = 'add-element',
  confirm = 'confirm-save',
}

export type OpenedModal =
  | {
      typeModal: ModalTypes.addDataTables;
    }
  | {
      typeModal: ModalTypes.addElement;
      variableName: string;
      key: string;
    }
  | {
      typeModal: ModalTypes.confirm;
      contentText: string;
    }
  | null;
