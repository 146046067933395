import { IAction } from '@modules/store';
import { IDevelopmentState } from '../../../../DevelopmentSlice';

const reducers = {
  setDiagramKey(state: IDevelopmentState, action: IAction) {
    const { key } = action.payload;
    if (key.length !== 0) {
      state.currentObject.key = key;
    }
  },
  setDiagramTitle(state: IDevelopmentState, action: IAction) {
    const { title } = action.payload;
    if (title.length) {
      state.currentObject.title = title;
    }
  },
  setReadonly(state: IDevelopmentState, action: IAction) {
    // TODO устанавливается подгружая параметр из url
    const { readonly } = action.payload;
    state.currentObject.readonly = readonly;
  },
};

export default reducers;
