import { Variable } from '@modules/administration/components/ExternalServiceCallSettingsModal/interfaces';
import React from 'react';
import {
  connectionStatus,
  TConnectionsDbType,
  TConnectionType,
} from '@modules/connections/types/connectionsTypes';
import {
  DBMS,
  EXTERNAL_SERVICES,
  MESSAGE_BROKERS,
} from '@modules/connections/constants';

export type ConnectionEntityOptionsApi =
  | 'brokers'
  | 'services'
  | 'dataprovider';

export enum ConnectionsDataSourceModalFields {
  'sourceName' = 'name',
  'sourceDescription' = 'description',
  'sourceType' = 'type',
  'typeDB' = 'sourceType',
  'environment' = 'environment',
  'jdbcUrl' = 'jdbcUrl',
  'driver' = 'driver',
  'server' = 'host',
  'port' = 'port',
  'userName' = 'username',
  'pass' = 'password',
  'furtherParams' = 'additionalProperties',
  'host' = 'host',
  'fileFormat' = 'fileFormat',
  'batchFlag' = 'batchFlag',
  'serviceType' = 'serviceType',
  'endPoint' = 'endpoint',
  'syncType' = 'syncType',
  'timeout' = 'timeout',
  'secondAttemptsCnt' = 'secondAttemptsCnt',
  'protocol' = 'protocol',
  'transactionsPerSecond' = 'transactionsPerSecond',
  'method' = 'method',
  'interval' = 'interval',
  'inputVariables' = 'inputVariables',
  'outputVariables' = 'outputVariables',
  'headers' = 'headers',
  'body' = 'body',
  'brokerType' = 'brokerType',
  'bootstrapServers' = 'bootstrapServers',
  'truststoreFile' = 'truststoreFile',
  'keystoreFile' = 'keystoreFile',
  'brokerMessageCommonParams' = 'brokerMessageCommonParams',
}

export interface IVariableFormField {
  [key: string]: {
    variableName: string;
    isArray: boolean;
    variableTypeId: {
      realValue: string;
      isComplex: boolean;
    };
  };
}

export interface IHeaderFormField {
  [key: string]: {
    headerName: string;
    headerValue: string;
  };
}

export interface IBrokerCommonParamsFormField {
  [key: string]: {
    name: string;
    value: string;
    isSecure: boolean;
  };
}

// TODO: status type
export interface ConnectionsDataSourceCommonFields {
  [ConnectionsDataSourceModalFields.sourceName]: string;
  [ConnectionsDataSourceModalFields.sourceDescription]: string;
  [ConnectionsDataSourceModalFields.sourceType]: TConnectionType;
  connectionStatus?: string;
}

export interface ConnectionsDataSourceDBFields {
  [ConnectionsDataSourceModalFields.typeDB]: TConnectionsDbType;
  [ConnectionsDataSourceModalFields.environment]: string;
  [ConnectionsDataSourceModalFields.server]: string;
  [ConnectionsDataSourceModalFields.port]: string;
  [ConnectionsDataSourceModalFields.userName]: string;
  [ConnectionsDataSourceModalFields.pass]: string;
  [ConnectionsDataSourceModalFields.furtherParams]: string;
  [ConnectionsDataSourceModalFields.jdbcUrl]: string;
  [ConnectionsDataSourceModalFields.driver]: string;
}

// eslint-disable-next-line require-jsdoc
export class ConnectionsDataSourceTestDBFields {
  [ConnectionsDataSourceModalFields.typeDB]?: TConnectionsDbType;
  [ConnectionsDataSourceModalFields.server]?: string;
  [ConnectionsDataSourceModalFields.port]?: string;
  [ConnectionsDataSourceModalFields.userName]?: string;
  [ConnectionsDataSourceModalFields.pass]?: string;
  [ConnectionsDataSourceModalFields.furtherParams]?: string;
}

export interface ConnectionsDataSourceExService {
  [ConnectionsDataSourceModalFields.host]: string;
  [ConnectionsDataSourceModalFields.fileFormat]: 'JSON' | 'XML';
  [ConnectionsDataSourceModalFields.batchFlag]: boolean;
  [ConnectionsDataSourceModalFields.serviceType]: 'HTTPS' | 'HTTP';
  [ConnectionsDataSourceModalFields.port]: number;
  [ConnectionsDataSourceModalFields.endPoint]: string;
  [ConnectionsDataSourceModalFields.syncType]: 'ASYNC' | 'SYNC';
  [ConnectionsDataSourceModalFields.timeout]: number;
  [ConnectionsDataSourceModalFields.secondAttemptsCnt]: number;
  [ConnectionsDataSourceModalFields.transactionsPerSecond]: number;
  [ConnectionsDataSourceModalFields.interval]: number;
  [ConnectionsDataSourceModalFields.protocol]: 'REST' | 'SOAP';
  [ConnectionsDataSourceModalFields.method]: 'GET' | 'POST' | 'PUT';
  [ConnectionsDataSourceModalFields.outputVariables]: IVariableFormField | any;
  [ConnectionsDataSourceModalFields.inputVariables]: IVariableFormField | any;
  [ConnectionsDataSourceModalFields.headers]: IHeaderFormField | any;
  [ConnectionsDataSourceModalFields.body]: string;
}

export interface ConnectionsDataSourceBrokerMessage {
  [ConnectionsDataSourceModalFields.brokerType]: string;
  [ConnectionsDataSourceModalFields.bootstrapServers]: string;
  [ConnectionsDataSourceModalFields.truststoreFile]: File | '';
  [ConnectionsDataSourceModalFields.keystoreFile]: File | '';
  [ConnectionsDataSourceModalFields.brokerMessageCommonParams]: IBrokerCommonParamsFormField;
}

export type ConnectionsVariablesDTO = Variable;

export interface ConnectionsBrokerMessageDTO {
  key: React.Key;
  value: string;
  name: string;
  isSecure: boolean;
  brokerParameterType?: 'PRODUCER';
}

export interface ConnectionsVersionsDTO {
  key: React.Key;
  version: string;
  id: string;
  type: string;
  dateLastChange: string;
  userLastChange: string;
  dependentObjectFlag: boolean;
  innerObjectSaveFlag: boolean;
  diagramId: string;
  data: {
    serviceId: string;
    versionId: string;
    versionName: string;
    versionDescription: string;
  };
}

// TODO connectionStatus - not undefined
export type OneConnectionDTO<
  T =
    | ConnectionRdbmsResponse
    | ConnectionExtServicesResponse
    | ConnectionBrokerMessageResponse
> = {
  connectionId: string;
  type: TConnectionType;
  name: string;
  description: string;
  connectionStatus?: string;
  properties: T;
};

export interface ConnectionRdbmsResponse {
  status?: connectionStatus;
  sourceType?: TConnectionsDbType;
  username?: string;
  password?: string;
  additionalProperties?: string;
  host?: string;
  port?: string;
  jdbcUrl?: string;
  driver?: string;
  connectionId?: string;
  connectionStatus?: string;
  lastChangeByUser?: string;
  name?: string;
  properties?: IDBMSConnection;
}

interface IDBMSConnection {
  additionalProperties?: string;
  driver?: string;
  host?: string;
  jdbcUrl?: string;
  password?: string;
  port?: string;
  sourceType?: string;
  username?: string;
}

export type ConnectionExtServiceParameterType = 'IN' | 'OUT' | 'IN_OUT';

export interface ConnectionExtServicesResponse {
  batchFlag: boolean;
  fileFormat: 'JSON' | 'XML';
  host: string;
  interval: number;
  method: 'GET' | 'POST' | 'PUT';
  protocol: 'REST' | 'SOAP';
  secondAttemptsCnt: number;
  serviceType: 'HTTPS' | 'HTTP';
  syncType: 'ASYNC' | 'SYNC';
  transactionsPerSecond: number;
  endpoint: string;
  port: number;
  timeout: number;
  body: string;
  headers:
    | { headerId?: string; headerName: string; headerValue: string }[]
    | any;
  variables:
    | {
        variableId: string;
        variableName: string;
        primitiveTypeId: string;
        complexTypeVersionId: string;
        parameterType: ConnectionExtServiceParameterType;
        arrayFlag: boolean;
        sourcePath?: string;
      }[]
    | any;
}

export interface ConnectionBrokerMessageResponse {
  brokerType: string;
  bootstrapServers: string;
  parameters: {
    name: string;
    value: string;
    isSecure: boolean;
    brokerParameterType?: 'PRODUCER';
  }[];
}

export interface ConnectionBrokerMessageProceed
  extends ConnectionBrokerMessageResponse {
  parameters: ConnectionsBrokerMessageDTO[];
}

export interface ConnectionExtServicesProceed
  extends ConnectionExtServicesResponse {
  outputVariables: Variable[];
  inputVariables: Variable[];
  headers: {
    key: React.Key;
    headerId: string;
    headerName: string;
    headerValue: string;
  }[];
}

export interface ConnectionsModalDataSourceState {
  selectedVersions: React.Key[];
  versionsTable: ConnectionsVersionsDTO[];
  versionsTableLoading: boolean;
  oneConnection: OneConnectionDTO<
    | ConnectionRdbmsResponse
    | ConnectionExtServicesProceed
    | ConnectionBrokerMessageProceed
  > | null;
}
