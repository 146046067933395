import { Key } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMMUNICATION_CHANNELS_FEATURE_KEY } from './constants';
import { CommunicationChannelsState } from './types/interface';

const initialState: CommunicationChannelsState = {
  data: [
    {
      key: '1',
      name: 'EMAIL',
      code: 'CAMP001',
      path: '/Diagrams/',
      changeDt: '2020-10-09 09:10:05',
      lastChangeByUser: 'User_1',
    },
    {
      key: '2',
      name: 'EMAIL',
      code: 'CAMP002',
      path: '/Diagrams/',
      changeDt: '2020-10-09 09:10:06',
      lastChangeByUser: 'User_2',
    },
    {
      key: '3',
      name: 'EMAIL',
      code: 'CAMP003',
      path: '/Diagrams/',
      changeDt: '2020-10-09 09:10:07',
      lastChangeByUser: 'User_3',
    },
  ],
  selectedRowKeys: [],
  search: '',
};

const communicationChannelsSlice = createSlice({
  name: COMMUNICATION_CHANNELS_FEATURE_KEY,
  initialState,
  reducers: {
    setSelectedRowKeys(state, { payload }: PayloadAction<Key[]>) {
      state.selectedRowKeys = payload;
    },
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    deleteItemsByKeys(state, { payload }: PayloadAction<Key[]>) {
      state.data = state.data.filter((item) => !payload.includes(item.key));
      state.selectedRowKeys = [];
    },
  },
});

const { reducer, actions } = communicationChannelsSlice;
export const сommunicationChannelsActions = { ...actions };
export { reducer as сommunicationChannelsReducer };
