import { apiBase } from '@modules/apiBase';

export interface IRequestExportSettings {
  objectsType: string;
  objectIds: any;
  isIncludeAllVersions: boolean;
  isIncludeDependencies: boolean;
}

const MIGRATION_ENDPOINT = 'migration';

export const exportApi = {
  generateExportObjects: async (settings: IRequestExportSettings) => {
    return await apiBase.post(
      `${MIGRATION_ENDPOINT}/generateExportObjects`,
      settings
    );
  },
  export: async (body: any) => {
    return await apiBase.post(`${MIGRATION_ENDPOINT}/export`, body);
  },
  downloadFile: async (fileName: string) => {
    return await apiBase.get(
      `${MIGRATION_ENDPOINT}/downloadExportFile/${fileName}`,
      { headers: { 'Content-Type': 'application/octet-stream' } }
    );
  },
  uploadImportFile: async (file: any) => {
    return await apiBase.post(`${MIGRATION_ENDPOINT}/uploadImportFile`, file);
  },
  import: async (body: any) => {
    return await apiBase.post(`${MIGRATION_ENDPOINT}/import`, body);
  },
};
