import React, { Key, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, Button, Badge } from 'antd';
import { SimpleTable } from '@shared';
import { IDataTableList } from '@modules/development';
import { RootState, useAppSelector } from '@modules/store';
import './DataGridList.scss';
import { Icon } from '@modules/icon';
import { sortHelper } from '@modules/utils';
import { selectRoles, rolesList } from '@modules/services';
import { ColumnsType } from 'antd/lib/table';
import { parseDate } from '@helpers';

const Item = Menu.Item;

type IDataGridList<T> = {
  data: T[];
  sort: any;
  loading?: boolean;
  onSortChange: (sort: any) => void;
  selectedRow: (state: RootState) => Key[];
  changeData?: (row: T) => void;
  onClickOpen: (data: T) => void;
  onClickOpenViewingOnly: (data: T) => void;
  onClickDelete: (data: T) => void;
  onSelectedRowKeys: (keys: Key[]) => void;
  onDoubleClick: (data: T) => void;
  columns?: ColumnsType<T>;
};

const DataGridList = <
  T extends Pick<IDataTableList, 'title' | 'dateOfChange'>
>({
  data,
  sort,
  loading,
  onSortChange,
  selectedRow,
  changeData,
  onClickOpen,
  onClickOpenViewingOnly,
  onClickDelete,
  onSelectedRowKeys,
  onDoubleClick,
  columns,
}: IDataGridList<T>) => {
  const { t } = useTranslation();
  const [editableTitle, setEditableTitle] = useState(false);
  const selectedRowTable = useAppSelector(selectedRow);
  const roles = useAppSelector(selectRoles);
  // const [columnsConfig, setColumnsConfig] = useState(columnsData_);

  const menu = (data: T) => (
    <Menu>
      <Item
        key="menu-item-dropdown_0"
        onClick={() => onContextMenuItemClick(data, 'open')}
      >
        {t('development.contextMenu.open')}
      </Item>
      <Item
        key="menu-item-dropdown_1"
        onClick={() => onContextMenuItemClick(data, 'openOnlyView')}
      >
        {t('development.contextMenu.openOnlyView')}
      </Item>
      <Item
        key="menu-item-dropdown_2"
        onClick={() => onContextMenuItemClick(data, 'delete')}
      >
        {t('development.contextMenu.delete')}
      </Item>
      <Item
        key="menu-item-dropdown_3"
        onClick={() => onContextMenuItemClick(data, 'rename')}
        disabled={!roles.includes(rolesList.DIAGRAM_RENAME)}
      >
        {t('development.contextMenu.rename')}
      </Item>
    </Menu>
  );

  const onClickRenameRow = () => {
    // при нажатии на клавишу переименовать startEditing: true, для названия
    // TODO то что сейчас сделано (если его запихнуть в useState) меняет параметр для колонки => состояние поменяется для всех строк, а не для одной
    columnsData[0].startEditing = true;
  };

  const onContextMenuItemClick: any = (data: T, command: string) => {
    switch (command) {
      case 'open':
        onClickOpen(data);
        break;
      case 'openOnlyView':
        onClickOpenViewingOnly(data);
        break;
      case 'delete':
        onClickDelete(data);
        onSelectedRowKeys([]);
        break;
      case 'rename':
        setEditableTitle(true);
        onClickRenameRow();
    }
  };

  // const sorterColumn = (a: IDataTableList, b: IDataTableList) =>
  //   a.title.localeCompare(b.title);
  // const render = (value: any, data: any) => {
  //   return (
  //     <Dropdown overlay={menu(data)} trigger={[`contextMenu`]}>
  //       <div>{value}</div>
  //     </Dropdown>
  //   );
  // };

  const sorterColumn = (a: T, b: T) => a.title.localeCompare(b.title);

  const sorterDate = (a: T, b: T) =>
    parseDate(a.dateOfChange).localeCompare(parseDate(b.dateOfChange));

  const render = (value: any, data: T) => {
    return (
      <Dropdown overlay={menu(data)} trigger={[`contextMenu`]}>
        <div onClick={(e) => e.stopPropagation()}>{value}</div>
      </Dropdown>
    );
  };

  const renderState = (value: any, data: any) => {
    let output;
    switch (value) {
      case 'IN_DEVELOPMENT':
        output = (
          <Badge
            color="#d9d9d9"
            text={t('development.solutionLists.table.inDevelopment')}
          />
        );
        break;
      case 'DEPLOYED':
        output = (
          <Badge
            color="#1890ff"
            text={t('development.solutionLists.table.deployed')}
          />
        );
        break;
      default:
        output = value;
    }

    return render(output, data);
  };
  const renderMenuBtn = (_: any, data: any) => {
    return (
      <Dropdown overlay={menu(data)} trigger={[`click`]}>
        <Button shape="circle" type="text">
          <Icon name="icon-more_horizontal" />
        </Button>
      </Dropdown>
    );
  };

  const columnsData = useMemo(() => {
    if (columns) {
      const customColumns: ColumnsType<T> = columns.map((column) => ({
        ...column,
        title: t(column.title as string),
        render,
      }));
      customColumns.push({
        title: '',
        dataIndex: 'menu',
        render: renderMenuBtn,
      });

      return customColumns;
    }

    return sortHelper(
      [
        {
          title: t('development.solutionLists.table.columns.title'),
          dataIndex: 'title',
          sorter: sorterColumn,
          render,
          editable: 'true',
          type: 'input',
          startEditing: false,
        },
        {
          title: t('development.solutionLists.table.columns.code'),
          dataIndex: 'code',
          sorter: sorterColumn,
          render,
        },
        {
          title: t('development.solutionLists.table.columns.location'),
          dataIndex: 'location',
          sorter: sorterColumn,
          render,
        },
        {
          title: t('development.solutionLists.table.columns.state'),
          dataIndex: 'state',
          sorter: sorterColumn,
          render: renderState,
        },
        {
          title: t('development.solutionLists.table.columns.dateOfChange'),
          dataIndex: 'dateOfChange',
          sorter: sorterDate,
          render,
        },
        {
          title: t('development.solutionLists.table.columns.user'),
          dataIndex: 'user',
          sorter: sorterColumn,
          render,
        },
        {
          title: t('development.solutionLists.table.columns.version'),
          dataIndex: 'version',
          sorter: sorterColumn,
          render,
        },
        {
          title: '',
          dataIndex: 'menu',
          render: renderMenuBtn,
        },
      ],
      sort
    );
  }, [columns, sort]);

  useEffect(() => {
    onSelectedRowKeys([]);
  }, [editableTitle]);

  return (
    <div className="data-grid-list">
      <SimpleTable
        selectedRowKeys={selectedRowTable}
        setSelectedRowKeys={onSelectedRowKeys}
        onDoubleClick={onDoubleClick}
        dataSource={data}
        columnsData={columnsData}
        changeData={changeData}
        onChange={(_, __, { field, order }: any) => {
          onSortChange({ sort: { field, order } });
        }}
        loading={loading}
        hideSelectAll
      />
    </div>
  );
};

export { DataGridList };
