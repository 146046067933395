import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputRef } from 'antd';
import { IModalProps } from '@modules/development';
import { Input, Modal, useModalVisible, ModalFooter } from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import { schemaApi, addSchema } from '@modules/schema';
import './SchemasTable.scss';
import { ISchema } from '@modules/development/types/schema';
import { AvroElement, IAvroData } from '../AvroElement/AvroElement';
import Title from 'antd/lib/typography/Title';

const DEFAULT_NAMESPACE = 'ru.glowbyte.streaming';

interface IEditCustomDataTypeModal {
  modalProps: IModalProps;
  schemaId?: string | null;
  editFlg: boolean;
  onClose: () => void;
  onChangesSaved?: () => void;
  closeOnSave?: boolean;
}

const defaultAvroSchema = {
  name: 'newSchema',
  namespace: 'newNameSpace',
  type: 'record',
};

export const SchemasModal: FC<IEditCustomDataTypeModal> = ({
  modalProps,
  schemaId,
  editFlg,
  onClose,
  onChangesSaved,
  closeOnSave,
}) => {
  const dispatch = useDispatch();
  const isEditModalVisible = useModalVisible(modalProps?.type || 'editSchema');
  const translate = useTranslationPath('development.complexTypes');

  const [schema, setSchema] = useState<ISchema>();
  const [schemaJson, setSchemaJson] = useState<IAvroData>();

  const nameRef = useRef<InputRef>(null);

  const fetchSchema = async (schemaId: string) => {
    setSchema(await schemaApi.getSchema(schemaId));
  };

  useEffect(() => {
    schemaId ? fetchSchema(schemaId) : setSchema(undefined);
  }, [schemaId]);

  const saveData = () => {
    console.log(schemaJson);
    dispatch(
      addSchema({
        name: schemaJson?.name,
        version: 1,
        schemaJson: { ...schemaJson, namespace: DEFAULT_NAMESPACE },
      })
    );
    onClose();
  };

  return (
    <Modal
      modalProps={{
        title: schema ? 'Схема: ' + schema.name : 'Новая схема',
        type: modalProps?.type || 'editSchema',
        width: 65,
        // helpKey: 'custom-data-types',
        footer: (
          <ModalFooter
            saveDisabled={!editFlg}
            onSave={saveData}
            onClose={onClose}
          />
        ),
      }}
      isOn={isEditModalVisible}
      onClose={onClose}
    >
      <div className="schema-container">
        <AvroElement
          data={schema?.schemaJson ? schema?.schemaJson : defaultAvroSchema}
          editFlg={editFlg}
          onChange={(e: any) => setSchemaJson(e)}
        />
      </div>
    </Modal>
  );
};

/*
      <div className="schema-container">
        <div>
          <Input
            addonBefore="Name"
            ref={nameRef}
            disabled={!editFlg}
            value={schema?.name}
          ></Input>
        </div>
        <div>
          <Input addonBefore="Url" disabled={true} value={schema?.url}></Input>
        </div>
        <div className="schema-json-wrapper">
          <Title level={3}>SchemaJson</Title>
          <AvroElement
            data={schema?.schemaJson ? schema?.schemaJson : defaultAvroSchema}
            editFlg={editFlg}
            onChange={(e: any) => setSchemaJson(e)}
          />
        </div>
      </div>
      */
