import React from 'react';
import { Checkbox, Divider } from 'antd';
import { Button } from '@shared';

import './Toolbar.scss';
import { Icon } from '@modules/icon';
import classNames from 'classnames';

export interface IToolbarItem {
  icon?: string;
  type?: string;
  disabled?: boolean;
  tooltip?: string;
  isDivider?: boolean;
  onClick?: (e?: any) => void;
}

export interface IToolbar {
  data: IToolbarItem[];
  className?: string;
  title?: string;
  isShowCheckboxAll?: boolean;
  CheckboxAllIndeterminate?: boolean;
  CheckboxAllChecked?: boolean;
  onCheckboxAllChange?: () => void;
  isDataSaved?: boolean;
  beforeActions?: React.ReactNode;
  search?: React.ReactNode;
}

const Toolbar: React.FC<IToolbar> = (props) => {
  const {
    data,
    className,
    title,
    isShowCheckboxAll,
    CheckboxAllIndeterminate,
    CheckboxAllChecked,
    onCheckboxAllChange,
    isDataSaved,
    beforeActions,
    search,
  } = props;

  return (
    <div className={classNames(className, 'toolbar')}>
      <div className="toolbar__actions">
        {title && <div className="toolbar__title">{title}</div>}
        {isShowCheckboxAll && onCheckboxAllChange && (
          <Checkbox
            className="toolbar__checkbox-all"
            indeterminate={CheckboxAllIndeterminate}
            checked={CheckboxAllChecked}
            onChange={onCheckboxAllChange}
          />
        )}

        {beforeActions && (
          <div className="toolbar__before-actions">{beforeActions}</div>
        )}

        {data.map((item, index) => {
          const { isDivider, type, tooltip, disabled, icon, onClick } = item;

          return isDivider ? (
            <Divider
              className="toolbar__divider"
              type="vertical"
              key={`tooltip_${index}`}
            />
          ) : (
            <Button
              key={`tooltip_${type ? type : index}`}
              className="toolbar__actions-button"
              kind="icon"
              tooltip={tooltip}
              disabled={disabled || (type === 'save_button' && isDataSaved)}
              onClick={onClick}
              children={
                icon && <Icon className="toolbar__actions-icon" name={icon} />
              }
            />
          );
        })}
        <div id="additional-buttons" />
      </div>
      <div id="toolbarSearch" className="toolbar__search">
        {/* Предпологается, что сюда будет вмонтирован поиск через портал */}
        {search && search}
      </div>
    </div>
  );
};

export { Toolbar };
