import React, { useState, VFC } from 'react';
import { ISelect, Select } from '@shared';
import { IDataType, selectDataTypes } from '@modules/references';
import { useAppSelector } from '@modules/store';

type DataTypesSelect = Omit<ISelect, 'onChange'> & {
  value?: string | undefined;
  onChange?: (dataType: IDataType | undefined) => void;
};

export const DataTypesSelect: VFC<DataTypesSelect> = ({
  value,
  onChange,
  ...props
}) => {
  const dataTypes = useAppSelector(selectDataTypes);
  const [search, setSearch] = useState('');
  const { onSelect } = props;
  const options = (dataTypes || [])
    .map((t) => ({ value: t.typeId, label: t.displayName }))
    .filter((t) => t.label.toLowerCase().includes(search.toLowerCase()));

  return (
    <Select
      size="large"
      {...props}
      value={value}
      options={options}
      onChange={(value) => {
        const foundType = dataTypes?.find((t) => t.typeId === value);
        onChange && onChange(foundType);
      }}
      onSelect={(value: any, record: any) => {
        onSelect?.(value, record);
      }}
      onSearch={(value) => setSearch(value)}
      filterOption={false}
      showSearch
    />
  );
};
