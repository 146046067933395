import { FC, useEffect, useState, VFC } from 'react';
import { Button, Input, Modal } from '@shared';
import './DeploySettingsModal.scss';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { IDeploySettingsModal, ISettingsValues } from '../../interfaces';
import { apiBase } from '@modules/apiBase';

const Field: FC<{ label: string }> = ({ label, children }) => {
  return (
    <div className="deploy-settings-modal__row">
      <div className="deploy-settings-modal__label">
        <span>{label}</span>
      </div>
      <div className="deploy-settings-modal__field">{children}</div>
    </div>
  );
};

export const DeploySettingsModal: VFC<IDeploySettingsModal> = ({
  values,
  onValuesChange,
  setIsOpenModal,
  modalProps,
  onDeploy,
  clearValues,
}) => {
  const { t } = useTranslation();
  const { isOpen, disabled } = modalProps;
  const [innerValues, setInnerValues] = useState<ISettingsValues>(values);

  const isNotValidValue = (value?: string | number) => {
    if (typeof value === 'number') return false;

    return value?.length && !/^[0-9]+$/.test(value);
  };

  useEffect(() => {
    !modalProps.disabled && setInnerValues(values);
  }, [values]);

  useEffect(() => {
    if (modalProps.disabled) {
      apiBase
        .get(`/deploy/${modalProps.deployId}/configuration`)
        .then((res) => setInnerValues(res as ISettingsValues))
        .catch((err) => console.log(err));
    }
  }, [modalProps]);

  // const selectAfterKafka = (
  //   <Select
  //     defaultValue="hour"
  //     className="select-after"
  //     options={[
  //       { value: 'hour', label: t('deployDiagram.deploySettingsModal.hour') },
  //       {
  //         value: 'minute',
  //         label: t('deployDiagram.deploySettingsModal.minute'),
  //       },
  //       {
  //         value: 'second',
  //         label: t('deployDiagram.deploySettingsModal.second'),
  //       },
  //       {
  //         value: 'byte',
  //         label: t('deployDiagram.deploySettingsModal.Byte'),
  //       },
  //       {
  //         value: 'piece',
  //         label: t('deployDiagram.deploySettingsModal.piece'),
  //       },
  //     ]}
  //   />
  // );

  // const selectAfterAggregate = (
  //   <Select
  //     defaultValue="hour"
  //     className="select-after"
  //     options={[
  //       { value: 'hour', label: t('deployDiagram.deploySettingsModal.hour') },
  //       {
  //         value: 'minute',
  //         label: t('deployDiagram.deploySettingsModal.minute'),
  //       },
  //       {
  //         value: 'second',
  //         label: t('deployDiagram.deploySettingsModal.second'),
  //       },
  //     ]}
  //   />
  // );

  return (
    <Modal
      isOn={isOpen}
      modalProps={{
        title: t('deployDiagram.deploySettingsModal.title'),
        icon: 'icon-dots',
        type: 'deploy-settings',
        width: '857px',
        footer: (
          <div className="btn-group-bottom">
            <Button
              kind="normal"
              modifiers={['hover-box-shadow']}
              disabled={disabled}
              onClick={onDeploy}
              children={t('deployDiagram.deploySettingsModal.deploy')}
            />
          </div>
        ),
      }}
      page="administration"
      onClose={() => {
        setIsOpenModal({ isOpen: false, disabled: false, deployId: null });
        clearValues();
      }}
    >
      <div className="connect-data-source-modal">
        <div className="deploy-settings-modal__grid">
          <Field label={t('deployDiagram.deploySettingsModal.threadCount')}>
            <Input
              className="deploy-settings-modal__grid-input"
              value={innerValues.parallelism}
              disabled={disabled}
              onChange={(event) => {
                if (isNotValidValue(event.target.value)) {
                  return;
                }
                onValuesChange('parallelism', event.target.value);
              }}
            />
          </Field>

          <Field label={t('deployDiagram.deploySettingsModal.singleMemory')}>
            <Input
              className="deploy-settings-modal__grid-input"
              value={innerValues.taskManagerMemory}
              disabled={disabled}
              addonAfter={t('deployDiagram.deploySettingsModal.Mbyte')}
              onChange={(event) => {
                if (isNotValidValue(event.target.value)) {
                  return;
                }
                onValuesChange('taskManagerMemory', event.target.value);
              }}
            />
          </Field>

          <Field label={t('deployDiagram.deploySettingsModal.manageMemory')}>
            <Input
              className="deploy-settings-modal__grid-input"
              value={innerValues.jobManagerMemory}
              disabled={disabled}
              addonAfter={t('deployDiagram.deploySettingsModal.Mbyte')}
              onChange={(event) => {
                if (isNotValidValue(event.target.value)) {
                  return;
                }
                onValuesChange('jobManagerMemory', event.target.value);
              }}
            />
          </Field>

          <Field label={t('deployDiagram.deploySettingsModal.logs')}>
            <Checkbox
              disabled={disabled}
              className="deploy-settings-modal__grid-checkBox"
              checked={innerValues.detailedLogging}
              onChange={(event) => {
                onValuesChange('detailedLogging', event.target.checked);
              }}
            />
          </Field>

          {/* <Field label={t('deployDiagram.deploySettingsModal.backupPeriod')}>
            <Input
              addonAfter={t('deployDiagram.deploySettingsModal.sec')}
              className="deploy-settings-modal__grid-input"
              disabled={disabled}
              value={innerValues.username}
              onChange={(event) => {
                onValuesChange('username', event.target.value);
              }}
            />
          </Field> */}

          <Field label={t('deployDiagram.deploySettingsModal.flink')}>
            <Input
              className="deploy-settings-modal__grid-input"
              value={innerValues.arguments}
              disabled={disabled}
              onChange={(event) => {
                onValuesChange('arguments', event.target.value);
              }}
            />
          </Field>

          {/* <Field label={t('deployDiagram.deploySettingsModal.kafka')}>
            <Input
              className="deploy-settings-modal__grid-input"
              addonAfter={selectAfterKafka}
              disabled={disabled}
              value={innerValues.additionalProperties}
              onChange={(event) => {
                onValuesChange('additionalProperties', event.target.value);
              }}
            />
          </Field>

          <Field label={t('deployDiagram.deploySettingsModal.aggWarm')}>
            <Input
              className="deploy-settings-modal__grid-input"
              addonAfter={selectAfterAggregate}
              disabled={disabled}
              value={innerValues.description}
              onChange={(event) => {
                onValuesChange('description', event.target.value);
              }}
            />
          </Field> */}
        </div>
      </div>
    </Modal>
  );
};
