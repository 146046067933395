import React, { useMemo } from 'react';
import { Table, TableProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import './SimpleTable.scss';
import { TableCell, TableRow } from '../TableCell';

interface ISimpleTable<T = any> extends TableProps<T> {
  className?: string;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: (key: React.Key[], selectedRows: any[]) => void;
  dataSource: any; // TODO добавить типы
  columnsData: any; // TODO добавить типы
  changeData?: (row: any) => void;
  summary?: (data: any) => React.ReactNode; // TODO добавить тип
  hideHeader?: boolean;
  onExpand?: (expanded: boolean, record: any) => void;
  size?: SizeType;
  isSingleChoice?: boolean;
  hideSelectAll?: boolean;
  loading?: boolean;
  onRow?: (record: any, rowIndex: any) => any;
  onDoubleClick?: (record: any) => void;
}

const SimpleTable: React.FC<ISimpleTable> = (props) => {
  const {
    className,
    selectedRowKeys,
    setSelectedRowKeys,
    dataSource,
    columnsData,
    changeData,
    summary,
    hideHeader,
    onExpand,
    rowClassName,
    size,
    scroll,
    isSingleChoice,
    hideSelectAll,
    loading,
    onDoubleClick,
    onRow,
    ...restProps
  } = props;

  const handleSave = (row: any) => {
    if (!changeData) return;
    changeData(row);
  };

  const getRowSelection = useMemo(() => {
    if (!setSelectedRowKeys) return;
    return {
      hideSelectAll: hideSelectAll || false,
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        if (isSingleChoice) {
          if (!selectedRowKeys.length) {
            setSelectedRowKeys(selectedRowKeys, selectedRows);
          } else return;
        }
        setSelectedRowKeys(selectedRowKeys, selectedRows);
      },
      onSelect: (record: any) => {
        if (!isSingleChoice) return;
        setSelectedRowKeys([record.key], [record]);
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.selectable === false,
        name: record.title,
      }),
      selectedRowKeys,
    };
  }, [selectedRowKeys, setSelectedRowKeys]);

  const components = {
    body: {
      row: TableRow,
      cell: TableCell,
    },
  };

  const columns = columnsData.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any, index: number) => ({
        record,
        index: index,
        editable: col.editable,
        startEditing: col.startEditing,
        selectCellAttr: col.selectCellAttr,
        type: col.type,
        dataIndex: col.dataIndex,
        title: col.title,
        required: col.required,
        onClickImage: col.onClickImage,
        disabled: col.disabled,
        defaultSortOrder: col.defaultSortOrder,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <div className="simple-table-wrapper">
      <Table
        {...restProps}
        className={className}
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={process.env.NODE_ENV === 'development' ? undefined : false}
        summary={summary}
        rowSelection={getRowSelection}
        showHeader={!hideHeader}
        size={size || 'large'}
        scroll={scroll}
        loading={loading || false}
        rowClassName={
          rowClassName
            ? rowClassName
            : (record: any) => record.disabled && 'disabled-row'
        }
        rowKey={(record, index: number = 0) => {
          return record.key || index.toString();
        }}
        onExpand={onExpand}
        onRow={
          onRow
            ? onRow
            : (record) => {
                return {
                  onClick: () => {}, // click row
                  onDoubleClick: onDoubleClick
                    ? () => onDoubleClick(record)
                    : undefined, // double click row
                  onContextMenu: () => {}, // right button click row
                  onMouseEnter: () => {}, // mouse enter row
                  onMouseLeave: () => {}, // mouse leave row
                };
              }
        }
      />
    </div>
  );
};

export { SimpleTable };
