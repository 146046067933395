import {
  setAggregateName,
  setAggregateDescription,
  setAggregateVariableType,
  setAggregateFunction,
  setAggregateGroupingElement,
  selectCurrentObject,
  selectCurrentObjectReadonly,
  selectError,
} from '@modules/development/DevelopmentSlice';
import { IAggregate } from '@modules/development';
import { useTranslationPath } from '@modules/languageProvider';
import { Input, Select } from '@shared';
import { VersionChangesInfo } from '@shared/VersionChangesInfo';
import { selectDataTypes } from '@modules/references';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rolesList, selectRoles } from '@modules/services';
import { useAppSelector } from '@modules/store';

export const Settings = () => {
  const translate = useTranslationPath('development.aggregates.aggregate');
  const dispatch = useDispatch();
  const currentAggregateReadonly = useSelector(selectCurrentObjectReadonly);
  const aggregate = useSelector(selectCurrentObject) as IAggregate;
  const showErrors = useSelector(selectError);
  const dataTypes = useSelector(selectDataTypes);
  const roles = useAppSelector(selectRoles);
  const onChangeName = (aggregateName: string) => {
    dispatch(setAggregateName({ aggregateName }));
  };

  const onChangeDescription = (aggregateDescription: string) => {
    dispatch(setAggregateDescription({ aggregateDescription }));
  };

  const onChangeVariableType = (aggregateVariableType: string) => {
    dispatch(setAggregateVariableType({ aggregateVariableType }));
  };

  const onChangeFunction = (aggregateFunction: string) => {
    dispatch(setAggregateFunction({ aggregateFunction }));
  };

  const onChangeGroupingElement = (groupingElement: string) => {
    dispatch(setAggregateGroupingElement({ groupingElement }));
  };

  const errors = useMemo(() => {
    if (!showErrors) return {};

    const name = (() => {
      if (!aggregate?.aggregateName) {
        return 'Поле является обязательным';
      }
      return undefined;
    })();
    const variableType = (() => {
      if (!aggregate?.aggregateJson?.aggregateVariableType) {
        return 'Поле является обязательным';
      }
      return undefined;
    })();
    const functions = functionError;
    const groupingElement = (() => {
      if (!aggregate?.aggregateJson?.groupingElement) {
        return 'Поле является обязательным';
      }
      return undefined;
    })();

    return { name, variableType, functions, groupingElement };
  }, [aggregate, showErrors]);

  const functionError = useMemo(() => {
    const aggregateVariableType =
      aggregate?.aggregateJson?.aggregateVariableType;
    const aggregateFunction = aggregate?.aggregateJson?.aggregateFunction;

    if (!aggregateFunction) return 'Поле является обязательным';
    if (
      [
        'AggAverage',
        'AggMax',
        'AggMin',
        'AggMode',
        'AggStd',
        'AggSum',
      ].includes(aggregateFunction) &&
      !['0', '1'].includes(aggregateVariableType)
    ) {
      return 'Для данной агрегирующей функции тип поля должен быть цифровой';
    }

    return undefined;
  }, [
    aggregate?.aggregateJson?.aggregateVariableType,
    aggregate?.aggregateJson?.aggregateFunction,
  ]);

  const functionsMap = useMemo(() => {
    return [
      { value: 'AggAverage' },
      { value: 'AggCount' },
      { value: 'AggCountDistinct' },
      { value: 'AggCountNonNull' },
      { value: 'AggFirstValue' },
      { value: 'AggLastValue' },
      { value: 'AggMax' },
      { value: 'AggMin' },
      { value: 'AggMode' },
      { value: 'AggStd' },
      { value: 'AggSum' },
    ];
  }, []);

  const dataTypesMap = useMemo(() => {
    return dataTypes
      ?.filter((dt) => !dt.complexFlag)
      .map((dt) => ({
        value: dt.typeId,
        label: dt.displayName,
      }));
  }, [dataTypes]);

  return (
    <div className="aggregate__content">
      <div className="aggregate-info">
        <div className="aggregate-info__top">
          <Input
            className="aggregate-info__name"
            label={translate('aggregateName')}
            value={aggregate?.aggregateName}
            disabled={
              currentAggregateReadonly ||
              !roles.includes(rolesList.AGGREGATE_UPDATE)
            }
            error={errors.name}
            onChange={(e) => onChangeName(e.target.value)}
            required
          />
          <Input
            className="aggregate-info__description"
            label={translate('aggregateDescription')}
            value={aggregate?.aggregateDescription}
            disabled={
              currentAggregateReadonly ||
              !roles.includes(rolesList.AGGREGATE_UPDATE)
            }
            onChange={(e) => onChangeDescription(e.target.value)}
          />
        </div>
        <div className="aggregate-info__row">
          <div className="aggregate-info__field">
            <span className="aggregate-info__field-label">
              {translate('aggregateVariableType')}
            </span>
            <Select
              className="aggregate-info__field-input"
              value={aggregate?.aggregateJson?.aggregateVariableType}
              options={dataTypesMap}
              disabled={
                currentAggregateReadonly ||
                !roles.includes(rolesList.AGGREGATE_UPDATE)
              }
              error={errors.variableType}
              onChange={onChangeVariableType}
              required
            />
          </div>
        </div>
        <div className="aggregate-info__row">
          <div className="aggregate-info__field">
            <span className="aggregate-info__field-label">
              {translate('aggregateFunction')}
            </span>
            <Select
              className="aggregate-info__field-input"
              value={aggregate?.aggregateJson?.aggregateFunction}
              options={functionsMap}
              disabled={
                currentAggregateReadonly ||
                !roles.includes(rolesList.AGGREGATE_UPDATE)
              }
              error={errors.functions}
              onChange={onChangeFunction}
              required
            />
          </div>
        </div>
        <div className="aggregate-info__row">
          <div className="aggregate-info__field">
            <span className="aggregate-info__field-label">
              {translate('groupingElement')}
            </span>
            <Input
              className="aggregate-info__field-input"
              value={aggregate?.aggregateJson?.groupingElement}
              disabled={
                currentAggregateReadonly ||
                !roles.includes(rolesList.AGGREGATE_UPDATE)
              }
              error={errors.groupingElement}
              onChange={(e) => onChangeGroupingElement(e.target.value)}
              required
            />
          </div>
        </div>
      </div>
      {aggregate.versionId ? (
        <VersionChangesInfo
          currentVersionTitle={translate('currentVersionTitle')}
          lastChangeDt={aggregate.changeDt}
          lastChangeUser={aggregate.lastChangeByUser}
          currentVersion={aggregate.versionId}
          createDt={aggregate.createDt}
          createUser={aggregate.createByUser}
        />
      ) : null}
    </div>
  );
};
