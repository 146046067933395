import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IComplexTypeAttributeExt, IComplexTypeMap } from './types/interface';
import { schemaApi } from './api/schemaApi';
import { IAction, RootState } from '@modules/store';
import { tryCatchDecorator } from '@modules/development/utils';
import { ISchema } from '@modules/development/types/schema';

const SCHEMA_FEATURE_KEY = 'schema';

export interface ISchemasState {
  lastLoadedMap?: IComplexTypeMap;
  complexTypesAttributes?: IComplexTypeAttributeExt[];
  schemas: ISchema[];
}

const initialState: ISchemasState = {
  schemas: [],
};

export const getSchemas = createAsyncThunk(
  `${SCHEMA_FEATURE_KEY}/getSchemas`,
  async (_, { dispatch }) => {
    // @ts-ignore
    return await tryCatchDecorator(dispatch, async () => {
      const response = await schemaApi.getSchemas();
      return response;
    });
  }
);

export const addSchema = createAsyncThunk(
  `${SCHEMA_FEATURE_KEY}/addSchema`,
  async (data: ISchema) => {
    return await schemaApi.addSchema(data);
  }
);

export const deleteSchema = createAsyncThunk(
  `${SCHEMA_FEATURE_KEY}/deleteSchema`,
  async (schemaId: string) => {
    return await schemaApi.deleteSchema(schemaId);
  }
);

export const schemaSlice = createSlice({
  name: SCHEMA_FEATURE_KEY,
  initialState,
  reducers: {
    setComplexTypeAttributes(state, action) {
      const { attributes } = action.payload;
      state.complexTypesAttributes = attributes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSchemas.fulfilled,
      (state, action: IAction<ISchema[]>) => {
        if (action.payload) state.schemas = action.payload;
      }
    );
  },
});

export const selectAllSchemas = (state: RootState) => state.schema.schemas;

export default schemaSlice.reducer;
