import { createSelector } from '@reduxjs/toolkit';
import { RootState, useAppSelector } from '@modules/store';

export const useNotificationSelector = () => {
  const notifications = useAppSelector(
    createSelector(
      (state: RootState) => state.notification.notifications,
      (notifications) => notifications
    )
  );

  const showClearButton = useAppSelector(
    createSelector(
      () => notifications,
      (notifications) => Object.keys(notifications).length > 1
    )
  );

  return { notifications, showClearButton };
};
