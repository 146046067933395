import React from 'react';
import './TechnicalMonitoring.scss';
import { ListMessage } from './ListMessage';
import { LogFilter } from './LogFilter';
import { useTranslation } from 'react-i18next';
import { Charts } from '@shared//Charts';

const TECH_MONITORING_TRANSLATION_PATH =
  'administrationTab.technicalMonitoring';
const getTranslationPath = (item: string) => {
  return `${TECH_MONITORING_TRANSLATION_PATH}.${item}`;
};

interface ITechnicalMonitoring {}
export const TechnicalMonitoring: React.FC<ITechnicalMonitoring> = () => {
  const { t } = useTranslation();

  return (
    <div className="technical-monitoring">
      <div className="technical-monitoring__top">
        <div className="technical-monitoring__title">
          {t(getTranslationPath('headerTitles.technicalMonitoring'))}
        </div>
      </div>
      <div className="technical-monitoring__content">
        <div className="technical-monitoring__left">
          <p className="technical-monitoring__subtitle">
            {t(getTranslationPath('headerTitles.logInfo'))}
          </p>
          <div className="technical-monitoring__zone technical-monitoring__charts">
            <Charts isButtonsShow />
          </div>
          <div className="technical-monitoring__zone technical-monitoring__list-msg">
            <ListMessage />
          </div>
        </div>
        <div className="technical-monitoring__log-filter">
          <LogFilter />
        </div>
      </div>
    </div>
  );
};
