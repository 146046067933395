import React from 'react';
import { SidePopup, useSearch } from '@shared';
import {
  useConnectionsSelector,
  useConnectionsDataSources,
  useConnectionsActions,
} from '@modules/connections/hooks';

export const ConnectionsDataSources: React.FC = () => {
  const { activeConnectionRow } = useConnectionsSelector();
  const { setActiveConnectionRow } = useConnectionsActions();
  const [sourceId, tableName] = `${activeConnectionRow}`.split(':') || [];
  const { columns, data, isLoading, fetchAttributes, search, setSearch } =
    useConnectionsDataSources({ sourceId, tableName });
  const [, getRefProps, getInputProps] = useSearch({ search, setSearch });

  return (
    <SidePopup
      title={tableName}
      getRefProps={getRefProps}
      getInputProps={getInputProps}
      close={() => setActiveConnectionRow('')}
      fetchData={fetchAttributes}
      columns={columns}
      data={data}
      isLoading={isLoading}
    />
  );
};
