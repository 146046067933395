import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@modules/store';
import { showNotification } from '@modules/notification';
import { setIsLoading } from '../DevelopmentSlice';

export const tryCatchDecorator = async (
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  callback: () => any,
  onError?: (e: any) => any,
  onFinally?: () => any
) => {
  dispatch(setIsLoading({ isLoading: true }));

  try {
    return await callback();
  } catch (e: any) {
    onError
      ? onError(e)
      : e?.message
      ? dispatch(showNotification({ type: 'error', message: e?.message }))
      : dispatch(showNotification({ type: 'error', message: e?.error }));
  } finally {
    onFinally && onFinally();
    dispatch(setIsLoading({ isLoading: false }));
  }
};
