/* eslint-disable max-len */
import React, { useState } from 'react';

interface InlineErrorProps {
  message: string | null;
}

export const InlineError: React.FC<InlineErrorProps> = ({ message }) => {
  const [showMessage] = useState(true); // setShowMessage

  if (!message || !showMessage) {
    return null;
  }

  return <div>{message}</div>;
};
