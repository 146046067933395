import { ITabPanelItem } from '@shared';
import {
  SCHEMAS,
  STREAM,
} from '@modules/development/modules/streams/routing/streamsConstants';

// @ts-ignore
// const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

export const tabs: ITabPanelItem[] = [
  {
    key: '0',
    text: '',
    translation: 'development.diagram.tabs.generalInformation',
    path: '/general-information',
  },
  // {
  //   key: '1',
  //   text: '',
  //   translation: 'development.streams.tabs.schemas',
  //   path: `/${SCHEMAS}`,
  // },
  {
    key: '1',
    text: '',
    translation: 'development.streams.tabs.streams',
    path: `/${STREAM}`,
  },
  {
    key: '2',
    text: 'Версии',
    translation: 'development.diagram.tabs.versions',
    path: '/versions',
  },
];
