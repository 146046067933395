import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { Button, ModalBox, TextArea } from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import { TRANSLATE_PATH } from '../services';
import { DataBaseTableData, ReadNode } from '../interfaces';
import { useDiagramVariablesTypeMapData } from '../../common/hooks/useDiagramVariablesTypeMapData';
import { ITypeMapData } from '@modules/development';
import { useQueryDataHelper } from './hooks';

// @ts-ignore
const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

interface IQuery {
  readingData: ReadNode;
  requestData: string;
  setReadingData: Dispatch<SetStateAction<ReadNode | undefined>>;
  setRequestData: React.Dispatch<React.SetStateAction<string>>;
  dataBaseData: DataBaseTableData[];
  disabled?: boolean;
}

export const Query: React.FC<IQuery> = ({
  readingData,
  requestData,
  setReadingData,
  setRequestData,
  dataBaseData,
  disabled,
}) => {
  const translate = useTranslationPath(TRANSLATE_PATH);
  const diagramVariablesTypes =
    useDiagramVariablesTypeMapData(
      readingData.inputVariables,
      readingData.nodeAddedVariables
    ) || [];

  const { addRowMap /* deleteRowMap */ } = useQueryDataHelper(
    readingData,
    setReadingData
  );

  const onInnerDataChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    // const setCaretPosition = (start: number) => {
    //   if (event.target.setSelectionRange) {
    //     event.target?.focus();
    //     event.target?.setSelectionRange(start, start);
    //   }
    // };

    const tempValue = event.target.value;
    const selectReqExp = new RegExp('(?<=select).+?(?=from|where|$)', 'gs');
    const dbElRegExp = new RegExp(`\\$\\{[\\w|\.]*}|[\\w|\.]*`, 'g');
    const selectedAttr = getSelectedTablesAttributesNames();
    const mappingAttr = readingData.properties.outputVariablesMapping.map(
      (item) => item.nodeVariable
    );

    const selectBlock = tempValue.match(selectReqExp);

    if (selectBlock) {
      const newDbEl = selectBlock[0].match(dbElRegExp)?.map((item) => item);
      const prevDbEl = requestData.match(dbElRegExp)?.map((item) => item);

      newDbEl?.forEach((attr) => {
        if (!prevDbEl?.includes(attr) && !mappingAttr?.includes(attr)) {
          if (selectedAttr.includes(attr)) {
            addRowMap(attr);
          }
        }
      });

      // prevDbEl?.forEach((attr) => {
      //   if (mappingAttr?.includes(attr) && !newDbEl?.includes(attr)) {
      //     const currentPos = event.target?.selectionStart;
      //     const attrLenght = attr.length;
      //     tempValue = tempValue.replace(attr.slice(0, -1), '');
      //     deleteRowMap([attr]);
      //     setTimeout(() => setCaretPosition(currentPos - attrLenght + 1), 0);
      //   }
      // });
    }
    setRequestData(tempValue);
  };

  const getSelectedTablesAttributesNames = useCallback(() => {
    const wrappedVarriables = (varriables: string[]) => {
      return varriables.map((varriable) => `\$\{${varriable}\}`);
    };
    const getChild = (child: ITypeMapData[], path?: string[], prefix = '') => {
      return child?.flatMap((item) => {
        let result: string[] = [];
        result = [...result, item.name];

        if (item.attributes?.length) {
          result = [
            ...result,
            ...getChild(item.attributes, [...(path || []), prefix + item.name]),
          ];
        }

        return [...(path || []), result].join('.').split(',');
      });
    };

    return [
      ...dataBaseData.map((item) => [
        item.columnName,
        ...(item.children?.map((child) => child.columnName) || []),
      ]),
      ...wrappedVarriables(getChild(diagramVariablesTypes, [])),
    ].flat();
  }, [diagramVariablesTypes, dataBaseData]);

  return (
    <ModalBox
      className="reading-data__query"
      title={translate('query')}
      icon="icon-query"
      showHeader
    >
      <div className="reading-data__query-top">
        <TextArea
          className="reading-data__query-txt"
          value={requestData}
          bordered={false}
          disabled={disabled}
          onChange={(event) => onInnerDataChanged(event)}
        />
      </div>

      {HIDE_FEATURES ? null : (
        <div className="reading-data__query-bottom">
          <Button
            kind="default"
            modifiers={['usual-text-style', 'hover-default']}
            width={160}
            disabled={disabled}
          >
            {translate('checkQuery')}
          </Button>
        </div>
      )}
    </ModalBox>
  );
};
