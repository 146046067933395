import { ColumnsType } from 'antd/lib/table';
import React, { useContext, useState } from 'react';
import {
  ConnectionBrokerMessageProceed,
  ConnectionsBrokerMessageDTO,
  ConnectionsDataSourceModalFields,
} from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import { useMemo } from 'react';
import {
  ConfigureTableSetting,
  useColumnSetup,
} from '@modules/connections/hooks';
import { useTranslationPath } from '@modules/languageProvider';
import { tranlationPath } from '@modules/administration/components/ExternalServiceCallSettingsModal/data';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox, Form } from 'antd';
import { Input, InputPassword } from '@shared';
import {
  KEYSTORE_LOCATION_NAME,
  TRUSTSTORE_LOCATION_NAME,
} from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsBrokerMessage/constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ConnectionsModalDataFromContext } from '@modules/connections/components';

export const useBrokerMessageTables = (
  rawColumns: ColumnsType<ConnectionsBrokerMessageDTO>,
  data: ConnectionBrokerMessageProceed['parameters']
) => {
  const translate = useTranslationPath(tranlationPath);
  const [tableData, setTableData] =
    useState<ConnectionsBrokerMessageDTO[]>(data);
  const [selected, setSelected] = useState<React.Key[]>([]);
  const { form, isEdit } = useContext(ConnectionsModalDataFromContext);

  const colSettings = useMemo<
    ConfigureTableSetting<ConnectionsBrokerMessageDTO>
  >(() => {
    return {
      value: {
        render(value, { key, name, isSecure, brokerParameterType }) {
          const disabled =
            name === TRUSTSTORE_LOCATION_NAME ||
            name === KEYSTORE_LOCATION_NAME;

          return isSecure ? (
            <Form.Item
              name={[
                ConnectionsDataSourceModalFields.brokerMessageCommonParams,
                `${key}`,
                'value',
              ]}
              initialValue={value}
              shouldUpdate
              rules={[
                { required: true, message: 'Поле должно быть заполнено' },
              ]}
            >
              <InputPassword
                disabled={disabled || (isEdit && !!brokerParameterType)}
                visibilityToggle={false}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name={[
                ConnectionsDataSourceModalFields.brokerMessageCommonParams,
                `${key}`,
                'value',
              ]}
              initialValue={value}
              rules={[
                { required: true, message: 'Поле должно быть заполнено' },
              ]}
              shouldUpdate
            >
              <Input disabled={disabled} />
            </Form.Item>
          );
        },
      },
      name: {
        render(value, { key, name }) {
          const disabled =
            name === TRUSTSTORE_LOCATION_NAME ||
            name === KEYSTORE_LOCATION_NAME;
          return (
            <Form.Item
              name={[
                ConnectionsDataSourceModalFields.brokerMessageCommonParams,
                `${key}`,
                'name',
              ]}
              initialValue={value}
              rules={[
                { required: true, message: 'Поле должно быть заполнено' },
              ]}
              shouldUpdate
            >
              <Input disabled={disabled} />
            </Form.Item>
          );
        },
      },
      isSecure: {
        render(value, { key, brokerParameterType }) {
          const secureChange = (e: CheckboxChangeEvent) => {
            const { checked } = e.target;
            const updatedBrokenMessage = tableData.map((el: any) => ({
              ...el,
              isSecure: key === el.key ? checked : el.isSecure,
            }));
            setTableData(updatedBrokenMessage);
          };

          return (
            <Form.Item
              name={[
                ConnectionsDataSourceModalFields.brokerMessageCommonParams,
                `${key}`,
                'isSecure',
              ]}
              initialValue={value}
              valuePropName="checked"
              shouldUpdate
            >
              <Checkbox
                onChange={secureChange}
                disabled={isEdit && !!brokerParameterType}
              />
            </Form.Item>
          );
        },
      },
    };
  }, [tableData]);

  const addRow = () => {
    setTableData([
      ...tableData,
      { key: uuidv4(), name: '', isSecure: false, value: '' },
    ]);
  };

  const deleteCommonParam = () => {
    const cutCommonParams = tableData.filter(
      ({ key }: { key: React.Key }) => !selected.includes(key)
    );
    setTableData(cutCommonParams);

    const leaveCommonParamsKeys = cutCommonParams.map(
      ({ key }: { key: React.Key }) => key
    );
    setSelected(leaveCommonParamsKeys);

    const findTrustStoreFile = tableData.find(
      (commonParam: any) =>
        commonParam.paramName === TRUSTSTORE_LOCATION_NAME &&
        selected.includes(commonParam.key)
    );

    if (findTrustStoreFile) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [ConnectionsDataSourceModalFields.truststoreFile]: '',
      });
    }
  };

  const toolbar = [
    {
      icon: 'icon-add',
      tooltip: translate('add'),
      onClick: (e: any) => {
        e?.preventDefault();
        addRow();
      },
    },
    { isDivider: true },
    {
      icon: 'icon-delete',
      tooltip: translate('delete'),
      onClick: (e: any) => {
        e?.preventDefault();
        deleteCommonParam();
      },
      disabled: !selected.length,
    },
  ];

  const columns = useColumnSetup({
    rawColumns,
    configureSettings: colSettings,
  });

  return {
    columns,
    toolbar,
    tableData,
    setTableData,
    selected,
    setSelected,
  };
};
