import { configureStore } from '@reduxjs/toolkit';

import developmentReducer from '@modules/development/DevelopmentSlice';
import { administrationReducer } from '@modules/administration';
import { notificationReducer } from '@modules/notification';
import { referencesReducer } from '@modules/references';
import { complexTypeReducer } from '@modules/complexType';
import { servicesReducer } from '@modules/services';
import { environmentsReducer } from '@modules/environments';
import { сommunicationChannelsReducer } from '@modules/communicationChannels';
import { connectionsReducer } from '@modules/connections';
import { apiResponseMiddleware } from './middlewares/apiResponseMiddleware';
import { schemaReducer } from '@modules/schema';

const reducer = {
  development: developmentReducer,
  administration: administrationReducer,
  references: referencesReducer,
  complexType: complexTypeReducer,
  services: servicesReducer,
  notification: notificationReducer,
  environments: environmentsReducer,
  сommunicationChannels: сommunicationChannelsReducer,
  connections: connectionsReducer,
  schema: schemaReducer,
};

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiResponseMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});
