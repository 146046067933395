import React, { VFC } from 'react';
import classNames from 'classnames';
import './timePicker.scss';
import { TimePicker as AntTimePicker, TimePickerProps } from 'antd';
import { Icon } from '@modules/icon';

export interface ITimePicker extends TimePickerProps {
  label?: string;
  required?: boolean;
}

export const TimePicker: VFC<ITimePicker> = ({
  className,
  label,
  required,
  size,
  ...restProps
}) => {
  return (
    <div className={classNames(className, 'timepicker-container')}>
      {label && <div className="timepicker-container__label">{label}</div>}
      <AntTimePicker
        showNow={false}
        {...restProps}
        className="timepicker"
        size={size || 'large'}
        suffixIcon={<Icon name="icon-timer" />}
        clearIcon={<Icon name="icon-highlight_off" />}
      />
      {required && <span className="timepicker-container__required">*</span>}
    </div>
  );
};
