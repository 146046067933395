import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslationPath } from '@modules/languageProvider';
import { IConfirmModalProps, IToolbarItem, Portal, Toolbar } from '@shared';
import { IDiagram } from '@modules/development';
import { useAppDispatch, useAppSelector } from '@modules/store';
import {
  getSelectedRowKeysDiagramData,
  saveDiagramJson,
  selectCurrentObject,
  selectCurrentObjectReadonly,
  selectDiagramParameters,
  // selectDiagrams,
  selectDiagramTests,
  selectDiagramTestsSelectedRowKeys,
  selectIsSavedObject,
  setDiagramReadyToDeploy,
  setSelectedRowKeysDiagramData,
  setSelectedRowKeysDiagramTesting,
  validateDiagram,
} from '@modules/development/DevelopmentSlice';
import { selectRoles, rolesList } from '@modules/services';
import { onSaveClick } from '../../../../utils';

type UseStreamToolbar = {
  currentObject: IDiagram | undefined;
};

type OpenedModal =
  | { type: 'save-as' }
  | (IConfirmModalProps & { type: 'confirm' })
  | null;

export const useDiagramToolbar = ({ currentObject }: UseStreamToolbar) => {
  const dispatch = useAppDispatch();
  const translate = useTranslationPath('development.diagram');
  const translateActions = useTranslationPath('actions');
  const history = useHistory();
  const currentObjectAllInfo = useSelector(selectCurrentObject) as IDiagram;
  const currentDiagramReadonly = useSelector(selectCurrentObjectReadonly);
  const isObjectSaved = useSelector(selectIsSavedObject);
  const variablesDiagramData = useSelector(selectDiagramParameters); // allDataRows schemas
  const selectedRowKeysDiagramData = useSelector(getSelectedRowKeysDiagramData); // selectedKeys in table schemas
  const diagramTestingData = useSelector(selectDiagramTests); // allDataRows Testing (in diagram)
  const selectedRowKeysDiagramTesting = useSelector(
    selectDiagramTestsSelectedRowKeys
  ); // selectedKeys in table Testing
  const roles = useAppSelector(selectRoles);

  const [openedModal, setOpenedModal] = useState<OpenedModal>(null);

  // const isDiagramNameNotUnique = useMemo(() => {
  //   if (currentObjectAllInfo) {
  //     const { diagramName, key } = currentObjectAllInfo;
  //     return diagrams.data.some(
  //       (d) => d.diagramName === diagramName && d.key !== key
  //     );
  //   }
  // }, [diagrams, currentObjectAllInfo]);

  const isDiagramGetStartedTests = useMemo(() => {
    if (currentObjectAllInfo) {
      const { testing } = currentObjectAllInfo;
      if (testing && testing?.data) {
        return testing.data.some((d) => d.status === 'IN_PROGRESS');
      }
    }
  }, [currentObjectAllInfo]);

  const isValidVariables = useMemo(() => {
    return (currentObjectAllInfo.inOutParameters || []).some(
      (item) => !item.parameterName || !item.typeId
    );
  }, [currentObjectAllInfo]);

  const isNonUniqueVariableNames = useMemo(() => {
    return (currentObjectAllInfo.inOutParameters || []).some((a, _, arr) =>
      arr.some(
        (b) =>
          a.parameterName === b.parameterName && a.parameterId !== b.parameterId
      )
    );
  }, [currentObjectAllInfo]);

  const onSave = () => {
    if (!currentObjectAllInfo || !currentObjectAllInfo.diagramName) {
      console.log('ERROR: stream.tsx ~ line 84 ~ onSaveClick ~ currentObject');
      return;
    }

    // if (isDiagramNameNotUnique) {
    //   return setOpenedModal({
    //     type: 'confirm',
    //     title: translate('errorSaveTitle'),
    //     contentText: translate('errorDiagramNameNotUnique'),
    //     kind: 'warning',
    //   });
    // }

    if (isDiagramGetStartedTests) {
      return setOpenedModal({
        type: 'confirm',
        title: translate('errorSaveTitle'),
        contentText: translate('errorGetStartedTests'),
        kind: 'warning',
      });
    }

    if (isValidVariables) {
      return setOpenedModal({
        type: 'confirm',
        title: translate('errorSaveTitle'),
        contentText: translate('errorVariables'),
        kind: 'warning',
      });
    }

    if (isNonUniqueVariableNames) {
      return setOpenedModal({
        type: 'confirm',
        title: translate('errorSaveTitle'),
        contentText: translate('errorNonUniqueVariableNames'),
        kind: 'warning',
      });
    }

    !isObjectSaved && onSaveClick(currentObjectAllInfo, dispatch);
  };

  const onSaveAsClick = () => {
    setOpenedModal({ type: 'save-as' });
  };

  const onValidateClick = async () => {
    if (!currentObject) return;

    const body = {
      diagramName: currentObject?.diagramName,
      diagramDescription: currentObject?.diagramDescription,
    };

    const { payload } = await dispatch(validateDiagram(body));

    // Todo поправить обработку ошибок
    if (payload.httpCode !== 200) {
      alert(payload?.message || translate('errorValidation'));
    } else {
      alert(translate('successValidation'));
    }
  };

  const onSendDeployClick = () => {
    !isObjectSaved && onSaveClick(currentObjectAllInfo, dispatch);
    if (currentObject?.key) {
      dispatch(setDiagramReadyToDeploy(currentObject.key));
    }
  };

  const onDownloadClick = () => {
    dispatch(saveDiagramJson(currentObjectAllInfo.versionId));
  };

  const diagramToolbar: IToolbarItem[] = [
    {
      icon: 'icon-save',
      disabled:
        currentDiagramReadonly || !roles.includes(rolesList.DIAGRAM_UPDATE),
      tooltip: translateActions('save'),
      type: 'save_button',
      onClick: onSave,
    },
    {
      icon: 'icon-save_as',
      tooltip: translateActions('saveAs'),
      type: 'save_as_button',
      onClick: onSaveAsClick,
      disabled: !roles.includes(rolesList.DIAGRAM_CREATE),
    },
    {
      icon: 'icon-send_deploy',
      disabled:
        currentDiagramReadonly ||
        !roles.includes(rolesList.DIAGRAM_MARK_TO_DEPLOY),
      tooltip: translateActions('sendOnDeploy'),
      type: 'send_deploy_button',
      onClick: onSendDeployClick,
    },
    {
      icon: 'icon-download',
      tooltip: translateActions('download'),
      type: 'download_button',
      onClick: onDownloadClick,
      disabled: !roles.includes(rolesList.DIAGRAM_READ),
    },
  ];

  const pathNameTab = history.location.pathname.split('/')[4];

  let allKeysTable: React.Key[] = []; // all row keys of table
  let selectedRowKeys: React.Key[] = []; // all selected rows of table

  if (pathNameTab === 'diagram-data') {
    const allRowKeysArr: React.Key[] = [];
    variablesDiagramData.forEach((e) => allRowKeysArr.push(e.parameterId));
    allKeysTable = allRowKeysArr;
    selectedRowKeys = selectedRowKeysDiagramData;
  }

  if (pathNameTab === 'testing') {
    const allRowKeysArr: React.Key[] = [];
    diagramTestingData.forEach((e) => {
      e?.testId && allRowKeysArr.push(e.testId);
    });

    allKeysTable = allRowKeysArr;
    selectedRowKeys = selectedRowKeysDiagramTesting;
  }

  const isShowCheckboxAll = useMemo(() => {
    if (pathNameTab === 'diagram-data' || pathNameTab === 'testing') {
      diagramToolbar.unshift({
        isDivider: true,
      });
      return true;
    }
    return false;
  }, [pathNameTab, diagramToolbar]);

  const onCheckboxAllChange = () => {
    if (pathNameTab === 'diagram-data') {
      dispatch(
        setSelectedRowKeysDiagramData(
          allKeysTable.length === selectedRowKeys.length ? [] : allKeysTable
        )
      );
    }
    if (pathNameTab === 'testing') {
      dispatch(
        setSelectedRowKeysDiagramTesting(
          allKeysTable.length === selectedRowKeys.length ? [] : allKeysTable
        )
      );
    }
  };

  const toolbarRender = () => {
    if (history.location.pathname.split('/')[4] === 'diagram') {
      return (
        <Portal id="reactflowToolbar">
          <Toolbar data={diagramToolbar} isDataSaved={isObjectSaved} />
        </Portal>
      );
    }
    return (
      <Toolbar
        data={diagramToolbar}
        isShowCheckboxAll={isShowCheckboxAll}
        onCheckboxAllChange={onCheckboxAllChange}
        CheckboxAllIndeterminate={
          selectedRowKeys.length !== allKeysTable.length &&
          selectedRowKeys.length !== 0
        }
        CheckboxAllChecked={
          selectedRowKeys.length === allKeysTable.length &&
          allKeysTable.length !== 0
        }
        isDataSaved={isObjectSaved}
      />
    );
  };

  return [openedModal, setOpenedModal, toolbarRender, onSave] as const;
};
