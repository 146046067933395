import React, { Key, useEffect, useState, VFC } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Checkbox } from 'antd';

import {
  InputSearch,
  Portal,
  SimpleTable,
  Toolbar,
  Tooltip,
  useSearch,
} from '@shared';
import {
  IAggregate,
  IAggregateVersion,
  aggregatesApi,
} from '@modules/development';
import {
  selectCurrentObject,
  setReadonly,
} from '@modules/development/DevelopmentSlice';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { useTranslationPath } from '@modules/languageProvider';
import { AGGREGATES_CORE_PATH } from '../../../../routing/aggregatesConstants';
import { useHistory } from 'react-router-dom';

type TableData = {
  key: Key;
  versionName: string;
  id: string;
  versionType: string;
  changeDt: string;
  lastChangeByUser: string;
  innerObjecttSaveFlag: boolean;
  dependentObjectFlag: boolean;
  rootObjectId: string;
};

const AggregateVersions: VFC = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslationPath('development.aggregates.versions');
  const history = useHistory();

  const { aggregateId, versionId } = useAppSelector(
    selectCurrentObject
  ) as IAggregate;
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();
  const [selectedVersion, setSelectedVersion] = useState<TableData[]>();
  const [search, setSearch] = useState('');
  const [versions, setVersions] = useState<IAggregateVersion[]>();

  const [, getRefProps, getInputProps, filterSearch] = useSearch({
    search,
    setSearch,
  });

  const getVersions = async (aggregateId: string) => {
    const versions = await aggregatesApi.getAggregateVesions(aggregateId);
    setVersions(versions);
  };

  const onCLickDeleteVersion = async (aggregateId: string, ids: Key[]) => {
    await Promise.all(ids.map((id) => aggregatesApi.deleteAggregate(id)));
    getVersions(aggregateId);
  };

  const onClickOpenVersion = (selectedVersion: TableData) => {
    if (selectedVersion.key === versionId) {
      return history.push(`${AGGREGATES_CORE_PATH}/${versionId}/settings`);
    }

    dispatch(setReadonly({ readonly: true }));
    history.push({
      pathname: `${AGGREGATES_CORE_PATH}/${selectedVersion.key}/settings`,
      state: { readonly: true, versionName: selectedVersion.versionName },
    });
  };

  const render = (value: any) => <Tooltip title={value}>{value}</Tooltip>;

  const actions = [
    { isDivider: true },
    {
      icon: 'icon-delete',
      tooltip: translate('toolbar.delete'),
      disabled:
        !selectedRowKeys?.length ||
        selectedVersion?.some((v) => v.versionType === 'LATEST'),
      onClick: () => {
        if (aggregateId && selectedRowKeys?.length) {
          onCLickDeleteVersion(aggregateId, selectedRowKeys);
        }
      },
    },
    {
      icon: 'icon-refresh',
      tooltip: translate('toolbar.refresh'),
      onClick: () => aggregateId && getVersions(aggregateId),
    },
    { isDivider: true },
    {
      icon: 'icon-version_actual',
      disabled: !selectedRowKeys?.length || selectedRowKeys?.length > 1,
      tooltip: translate('toolbar.selectVersion'),
    },
    // { icon: 'icon-version_save', tooltip: translate('toolbar.saveVersion') },
    {
      icon: 'icon-version_open',
      tooltip: translate('toolbar.openVersion'),
      disabled: !selectedRowKeys?.length || selectedRowKeys?.length > 1,
      onClick: () => {
        selectedVersion?.[0] && onClickOpenVersion(selectedVersion[0]);
      },
    },
  ];

  const columns: ColumnsType<TableData> = [
    {
      key: 'versionName',
      dataIndex: 'versionName',
      title: translate('objectVersion'),
      ellipsis: true,
      showSorterTooltip: { title: translate('objectVersion') },
      sorter: (a, b) => a.versionName.localeCompare(b.versionName),
      render,
    },
    {
      key: 'versionType',
      dataIndex: 'versionType',
      title: translate('versionType'),
      ellipsis: true,
      showSorterTooltip: { title: translate('versionType') },
      sorter: (a, b) => a.versionType.localeCompare(b.versionType),
      render,
    },
    {
      key: 'changeDt',
      dataIndex: 'changeDt',
      title: translate('lastSaveDate'),
      ellipsis: true,
      showSorterTooltip: { title: translate('lastSaveDate') },
      sorter: (a, b) => a.changeDt.localeCompare(b.changeDt),
      render,
    },
    {
      key: 'lastChangeByUser',
      dataIndex: 'lastChangeByUser',
      title: translate('theUserWhoSavedTheChanges'),
      ellipsis: true,
      showSorterTooltip: { title: translate('theUserWhoSavedTheChanges') },
      sorter: (a, b) => a.lastChangeByUser.localeCompare(b.lastChangeByUser),
      render,
    },
    {
      key: 'dependentObjectFlag',
      dataIndex: 'rootObjectId',
      title: translate('dependentObjectAttribute'),
      ellipsis: true,
      showSorterTooltip: { title: translate('dependentObjectAttribute') },
      align: 'center',
      sorter: (a, b) => Number(a.rootObjectId) - Number(b.rootObjectId),
      render: (value) => <Checkbox checked={!!value} />,
    },
    {
      key: 'rootObjectId',
      dataIndex: 'rootObjectId',
      title: translate('idOfTheRootDiagramForTheDependentObject'),
      ellipsis: true,
      showSorterTooltip: {
        title: translate('idOfTheRootDiagramForTheDependentObject'),
      },
      sorter: (a, b) => a.rootObjectId.localeCompare(b.rootObjectId),
      render,
    },
  ];

  const data: TableData[] = filterSearch<TableData>(
    (versions || []).map((v) => ({
      key: v.versionId,
      versionName: v.versionName || '',
      id: v.versionId,
      versionType: v.versionType,
      changeDt: v.changeDt,
      lastChangeByUser: v.lastChangeByUser,
      innerObjecttSaveFlag: !!v.rootObjectVersionId,
      dependentObjectFlag: !!v.rootObjectVersionId,
      rootObjectId: v.rootObjectVersionId,
    })),
    'versionName'
  );

  useEffect(() => {
    aggregateId && getVersions(aggregateId);
  }, [aggregateId]);

  return (
    <div className="diagram-versions" {...getRefProps()}>
      <Portal id="additional-buttons">
        <Toolbar className="diagram-data__toolbar" data={actions} />
      </Portal>
      <Portal id="toolbarSearch">
        <InputSearch {...getInputProps()} width={420} />
      </Portal>

      <div className="diagram-versions__table">
        <SimpleTable
          columnsData={columns}
          dataSource={data}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={(key, selectedRows: TableData[]) => {
            setSelectedRowKeys(key);
            setSelectedVersion(selectedRows);
          }}
        />
      </div>
    </div>
  );
};

export default AggregateVersions;
