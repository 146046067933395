import React from 'react';
import './Card.scss';
import { Icon } from '@modules/icon';

interface ICard {
  title: string;
  additionalHeaderElement?: React.ReactNode;
  icon?: string;
}

const Card: React.FC<ICard> = ({
  title,
  icon,
  additionalHeaderElement,
  children,
}) => {
  return (
    <div className="card">
      <div className="card__top">
        {icon && <Icon name={`icon-${icon}`} />}
        {title}
        <div className="card__additional-header-element">
          {additionalHeaderElement}
        </div>
      </div>
      <div className="card__content">{children}</div>
    </div>
  );
};

export { Card };
