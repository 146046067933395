import React, { useEffect, useState, VFC } from 'react';
import classNames from 'classnames';
import { Icon } from '@modules/icon';

type NotificationMessageProps = {
  text: string;
};

export const NotificationMessage: VFC<NotificationMessageProps> = ({
  text,
}) => {
  const [lineClamp, setLineClamp] = useState<boolean | null>(null);

  useEffect(() => {
    if (text.length > 50) {
      setLineClamp(true);
    }
  }, []);

  return (
    <div className="notification-message">
      <span
        className={classNames(
          'notification-message__text',
          lineClamp && 'notification-message__text--line-clamp'
        )}
      >
        {text}
      </span>
      {lineClamp !== null ? (
        <Icon
          className={classNames(
            'notification-message__icon',
            !lineClamp && 'notification-message__icon--top'
          )}
          name="icon-angle"
          size={12}
          onClick={() => setLineClamp(!lineClamp)}
        />
      ) : null}
    </div>
  );
};
