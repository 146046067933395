import React from 'react';
import classNames from 'classnames';
import { Icon } from '@modules/icon';
import { Button, InputSearch, SimpleTable } from '@shared';
import { useTranslation } from 'react-i18next';
import './SidePopup.scss';

interface ISidePopup {
  title: string;
  getRefProps: () => any;
  getInputProps: () => any;
  close: () => void;
  fetchData: () => any;
  columns: any[];
  data: any[];
  isLoading: boolean;
}

export const SidePopup: React.FC<ISidePopup> = ({
  title,
  getRefProps,
  getInputProps,
  close,
  fetchData,
  columns,
  data,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'data-sources-info',
        title && 'data-sources-info--active'
      )}
      {...getRefProps()}
    >
      <div className="data-sources-info__top">
        <span className="data-sources-info__title">{title}</span>
        <button className="data-sources-info__close" onClick={close}>
          <Icon name="icon-close" />
        </button>
      </div>
      <div className="data-sources-info__content">
        <div className="data-sources-info__actions">
          <InputSearch size="large" iconColor="5e6366" {...getInputProps()} />

          <Button kind="square-s-b" tooltip={t('actions.refresh')}>
            <Icon name="icon-refresh" onClick={fetchData} />
          </Button>
        </div>

        <div className="data-sources-info__divider"></div>

        <SimpleTable
          className="data-sources-info__table"
          columnsData={columns}
          dataSource={data}
          loading={isLoading}
        />
      </div>
    </div>
  );
};
