import { RadioGroup } from '@modules/shared';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerFilter } from './DatePicker';

interface ITestingItemInfo {}

export const DateFilters: React.FC<ITestingItemInfo> = () => {
  const { t } = useTranslation();

  const [filterType, setFilterType] = useState<number>(1);

  const options = [
    {
      value: 1,
      label: t('lockout.all'),
    },
    {
      value: 2,
      label: t('lockout.today'),
    },
    {
      value: 3,
      label: t('lockout.week'),
    },
    {
      value: 4,
      label: t('lockout.period'),
    },
  ];

  return (
    <div className="date-filters">
      <div className="date-filters__top">
        <span className="date-filters__title">{t('lockout.lockDt')}</span>
      </div>
      <div className="date-filters__content">
        <div className="date-filters__radio">
          <RadioGroup
            options={options}
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            isVerticalDirection
          />
        </div>
        <div className="date-filters__picker">
          {filterType === 4 && (
            <>
              <div className="date-filters__picker_title">
                {t('lockout.date')}
              </div>
              <DatePickerFilter handleFilter={(val: any) => console.log(val)} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
