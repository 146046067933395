import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NOT_FOUND_PATH } from '../constants/appConstants';
import { APP_ROUTES } from '../constants/appRoutes';
import { PrivateRoute } from './PrivateRoute';

export const MainRouting: React.FC = () => (
  <Switch>
    {APP_ROUTES.map((route) => (
      <PrivateRoute key={route.path} {...route} />
    ))}

    <Route path="*" render={() => <Redirect to={NOT_FOUND_PATH} />} />
  </Switch>
);
