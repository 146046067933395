import { useAppSelector } from '@modules/store';
import { useCallback } from 'react';
import { rolesList } from '../constants/roles';
import { selectRoles } from '../servicesSlice';

export const useRolesHelper = () => {
  const roles = useAppSelector(selectRoles);

  const hasRoles = useCallback(
    (checkedRoles?: string[]) => {
      if (!checkedRoles) return true;
      return checkedRoles.every((role) => roles.includes(role));
    },
    [roles]
  );

  return { roles, rolesList, hasRoles };
};
