import React, { lazy } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import {
  STREAMS_CORE_PATH,
  STREAM_PATH,
  GENERAL_INFORMATION_PATH,
  SCHEMAS_PATH,
  WORKING_AREA_PATH,
  TESTING_PATH,
  VERSIONS_PATH,
} from '../../routing/streamsConstants';

const GeneralInformation = lazy(
  () => import('../GeneralInformation/GeneralInformation')
);
const DiagramData = lazy(() => import('../schemas/Schemas'));
const Testing = lazy(() => import('../Testing/Testing'));
const WorkingAreaDiagram = lazy(
  () => import('../streamsDiagram/StreamsDiagram')
);
const StreamVersions = lazy(() => import('../StreamVersions/StreamVersions'));

const Navigation = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route
        exact
        path={STREAM_PATH}
        render={(props) => {
          return (
            <Redirect
              to={{
                pathname: `${STREAMS_CORE_PATH}/${props.match.params.diagram}/general-information`,
                state: history.location.state,
              }}
            />
          );
        }}
      />
      <Route
        exact
        path={GENERAL_INFORMATION_PATH}
        component={GeneralInformation}
      />
      <Route exact path={SCHEMAS_PATH} component={DiagramData} />
      <Route path={TESTING_PATH} component={Testing} />
      <Route path={WORKING_AREA_PATH} component={WorkingAreaDiagram} />
      <Route path={VERSIONS_PATH} component={StreamVersions} />
    </Switch>
  );
};

export { Navigation };
