import { CheckOutlined } from '@ant-design/icons';
import { Radio, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { FC, useEffect, useState } from 'react';
// import { IScriptsTableOptions } from "src/modules/Scripts/types/interface";

interface RadioGroupFilterProps {
  confirm: () => void;
  setSelectedKeys: (selectedKeys: any) => void;
  clearFilters?: () => void;
  dataIndex: string;
  handleFilter: any;
  filters?: any;
  // tableParams?: IScriptsTableOptions;
  tableParams?: any;
  disabled: boolean;
}

const RadioGroupFilter: FC<RadioGroupFilterProps> = ({
  filters,
  handleFilter,
  confirm,
  dataIndex,
  tableParams,
  disabled,
}) => {
  const [value, setValue] = useState<any>('');

  const onFilter = (e: any) => {
    handleFilter(dataIndex, e.target.value);
    setValue(e.target.value);
    confirm();
  };

  useEffect(() => {
    if (!tableParams?.status) {
      setValue('');
    }
  }, [tableParams, tableParams?.status]);

  return (
    <Space direction="vertical">
      <>
        <Radio.Group value={value} style={{}}>
          <Space direction="vertical">
            {filters.map((el: any) => {
              return (
                <div style={{ display: 'flex' }} key={'div ' + el.value}>
                  <Radio.Button
                    style={{ width: '100%', border: 'none', padding: '0px' }}
                    onClick={onFilter}
                    key={el.value}
                    value={el.value}
                    disabled={disabled}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          className="dot"
                          style={{ backgroundColor: el.color }}
                        ></div>
                        {el.text}
                      </div>
                      {el.value === value ? (
                        <CheckOutlined style={{ marginLeft: '10px' }} />
                      ) : null}
                    </div>
                  </Radio.Button>
                </div>
              );
            })}
          </Space>
        </Radio.Group>

        {/* <Button onClick={onFilter}>Применить</Button>
                <Button onClick={() => setValue('')}>Сбросить</Button> */}
      </>
    </Space>
  );
};

export const getColumnCustomFilterProps = (
  dataIndex: string,
  filters: any,
  handleFilter: any,
  tableParams: any,
  disabled: boolean
) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <RadioGroupFilter
          confirm={confirm}
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearFilters}
          dataIndex={dataIndex}
          filters={filters}
          handleFilter={handleFilter}
          tableParams={tableParams}
          disabled={disabled}
        />
      </div>
    ),

    onFilter: () => true,
  };
};
