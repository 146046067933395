import React, { useState } from 'react';
import './Script.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectCurrentObject,
  selectCurrentObjectReadonly,
  setCustomCodeTitle,
  setCustomCodeScriptText,
  selectCustomCodes,
} from '@modules/development/DevelopmentSlice';
import { ICustomCode } from '@modules/development';
import { Input, TextArea, VersionChangesInfo } from '@shared';
import { useAppSelector } from '@modules/store';
import { rolesList, selectRoles } from '@modules/services';

interface IScript {}

const Script: React.FC<IScript> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [titleError, setTitleError] = useState<string | undefined>(undefined);
  const customCodes = useSelector(selectCustomCodes);
  const customCode = useSelector(selectCurrentObject) as ICustomCode;
  const isReadOnly = useSelector(selectCurrentObjectReadonly);
  const roles = useAppSelector(selectRoles);
  const onChangeTitle = async (title: string) => {
    const response = await dispatch(setCustomCodeTitle({ title }));

    const isTitleOccupied = customCodes.data.find(
      (i) => i.key !== customCode.key && i.title === response.payload.title
    );

    if (isTitleOccupied) {
      return setTitleError(t('development.customCode.script.isTitleOccupied'));
    }
    setTitleError(undefined);
  };

  const onChangeScriptText = (scriptText: string) => {
    dispatch(setCustomCodeScriptText({ scriptText }));
  };

  return (
    <div className="script__wrapper">
      <div className="script">
        <div className="script__top-content">
          <Input
            className="script__field script__field--name"
            label={t('development.customCode.script.title')}
            value={customCode?.title}
            onChange={(e) => onChangeTitle(e.target.value)}
            disabled={isReadOnly || !roles.includes(rolesList.SCRIPT_UPDATE)}
            error={titleError}
          />

          <Input
            className="script__field script__field--code"
            label={t('development.customCode.script.code')}
            value={customCode?.code}
            disabled
          />
        </div>
        <div className="script__bottom-content">
          <TextArea
            label={t('development.customCode.script.codeInput')}
            className="script__field script__fild--description script__field--description"
            style={{ resize: 'none' }}
            value={customCode?.scriptJson?.scriptText}
            onChange={(e) => onChangeScriptText(e.target.value)}
            disabled={isReadOnly || !roles.includes(rolesList.SCRIPT_UPDATE)}
            maxLength={30000}
            rows={12}
          />
        </div>
      </div>
      {customCode.versionId && (
        <VersionChangesInfo
          currentVersionTitle={t(
            'development.customCode.script.currentVersionTitle'
          )}
          lastChangeDt={customCode.dateOfChange}
          lastChangeUser={customCode.user}
          currentVersion={customCode.versionId}
          createDt={customCode.dateOfCreate}
          createUser={customCode.createUser}
        />
      )}
    </div>
  );
};

export { Script };
