import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  diagramApi,
  DiagramNodeTypes,
  IDataVariables,
  IDiagram,
  IDiagramNodePosition,
  InOutParameter,
} from '@modules/development';
import {
  addNode,
  openObjects,
  selectDiagramInputParameters,
  selectDiagramParameters,
} from '@modules/development/DevelopmentSlice';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { Attribute } from '../components/DiagramBlocks/AddElementModal/interfaces';
import { nodeList } from '../components/SidebarDiagram/data';

export const useDiagramUtils = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const openAllObjects = useAppSelector(openObjects) as IDiagram[];
  const inputParameters = useAppSelector(selectDiagramInputParameters);
  const parameters = useAppSelector(selectDiagramParameters);

  const fetchDiagram = async (diagramId: string) => {
    try {
      const response = await diagramApi.getDiagram(diagramId);
      let diagram: IDiagram | undefined;
      if (!response) {
        diagram = openAllObjects.find((item) => item.key == diagramId);
        if (diagram) return diagram;
      } else {
        diagram = {
          ...response,
          testing: { data: [], loading: false },
          key: response.diagramId,
        };
        if (diagram) return diagram;
      }
    } catch (e: any) {
      console.warn(e);
    }
  };

  const createNode = (
    nodeTypeId: DiagramNodeTypes,
    position: IDiagramNodePosition
  ) => {
    const metaData = nodeList.find((item) => item.nodeType === nodeTypeId);
    const nodeName = metaData?.translation ? t(metaData.translation) : '';
    const metaInfo = { position };
    const properties = null;

    dispatch(addNode({ nodeTypeId, nodeName, metaInfo, properties }));
  };

  const fetchNode = async <T extends any>(nodeId: string) => {
    const node = await diagramApi.getNodeById<T>(nodeId);
    let blockInnerMeta = {};

    if (node.metaInfo.blockInnerMeta) {
      blockInnerMeta = JSON.parse(node.metaInfo.blockInnerMeta);
    }

    return Promise.resolve({ node, blockInnerMeta });
  };

  const fetchPossibleNodes = async (nodeId: string, versionId: string) => {
    const nodes = await diagramApi.getPossibleNodesById(nodeId, versionId);

    return Promise.resolve({ nodes });
  };

  const getStartNodeVariables = useCallback(
    (variables: InOutParameter[] = inputParameters) => {
      return variables.map((e) => ({
        variableName: e.parameterName,
        isComplex: e.complexFlag,
        typeId: e.typeId,
        isArray: e.arrayFlag,
        parameterId: e.parameterId,
      }));
    },
    [inputParameters]
  );

  const parseVariable = (variable: Attribute): IDataVariables => {
    const path = variable.chainName.split('.');
    const name = path.pop() as string;
    const variablePath = path.join('.');

    const parsedVariable: IDataVariables = {
      variableName: name,
      isComplex: variable.isComplex,
      typeId: variable.typeId,
      isArray: variable.isArray,
    };

    if (variable.id) {
      parsedVariable.variableId = variable.id;
    } else if (variablePath) {
      parsedVariable.variableRootId = variable.typesChain[0].typeId;
      parsedVariable.variablePath = variablePath;
    }

    return parsedVariable;
  };

  const getVariablePath = (variableName: string, variablePath?: string) => {
    return variablePath ? [variablePath, variableName].join('.') : variableName;
  };

  const checkUniqueVariableName = (variableName: string) => {
    return !parameters.some((p) => p.parameterName === variableName);
  };

  return {
    fetchDiagram,
    createNode,
    fetchNode,
    fetchPossibleNodes,
    getStartNodeVariables,
    parseVariable,
    getVariablePath,
    checkUniqueVariableName,
  };
};
