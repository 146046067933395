import {
  ConnectionsResponseDTO,
  ConnectionsState,
  ConnectionsTableData,
  TConnectionType,
} from '@modules/connections/types/connectionsTypes';
import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  AnyAction,
} from '@reduxjs/toolkit';
import React from 'react';
import {
  mockAllConnections,
  mockDatabaseSystem,
  mockDataMessageBrokers,
  mockDataOutServices,
} from '@modules/connections/mockData';
import { showNotification } from '@modules/notification';
import { connectionsSliceName } from '@modules/connections/constants';
import {
  connectionsModalDataSourceExtraReducers,
  connectionsModalDataSourceInitialState,
  connectionsModalDataSourceReducers,
} from '@modules/connections/components/connectionsModalDataSource/ConnectionsModalDataSourceSlice';
import { connectionsApi } from '@modules/connections/api';
import { ALL_CONNECTIONS } from '@modules/connections/constants';

export const fetchConnectionsTable = createAsyncThunk(
  `${connectionsSliceName}/fetchConnectionsTable`,
  async (connectionType: TConnectionType, { rejectWithValue, dispatch }) => {
    return connectionsApi
      .getAllConnections(
        connectionType === 'allConnections' ? '' : connectionType
      )
      .then((result) => {
        if (connectionType === 'allConnections') {
          return result;
        } else {
          result.forEach((row) => (row.type = connectionType));
          return result;
        }
      })
      .catch((e) => {
        dispatch(
          showNotification({
            type: 'error',
            message: e?.error?.toString(),
            duration: 3000,
          }) as unknown as AnyAction
        );
        throw e;
      });
  }
);

// TODO: Это не нужно вроде больше
export const deleteConnection = createAsyncThunk(
  `${connectionsSliceName}/deleteConnection`,
  async (
    {
      connectionType,
      id,
    }: {
      connectionType: TConnectionType;
      id: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      return connectionsApi.deleteConnection(connectionType, id);
    } catch (e: any) {
      dispatch(
        showNotification({
          type: 'error',
          message: e?.error?.toString(),
          duration: 3000,
        }) as unknown as AnyAction
      );
      return rejectWithValue(e);
    }
  }
);

export const deleteConnections = createAsyncThunk(
  `${connectionsSliceName}/deleteConnections`,
  async (
    connections: {
      connectionType: TConnectionType;
      id: string;
    }[],
    { rejectWithValue, dispatch }
  ) => {
    try {
      const requests = connections.map((options) =>
        connectionsApi.deleteConnection(options.connectionType, options.id)
      );
      return Promise.allSettled(requests);
    } catch (e: any) {
      dispatch(
        showNotification({
          type: 'error',
          message: e?.error?.toString(),
          duration: 3000,
        }) as unknown as AnyAction
      );
      return rejectWithValue(e);
    }
  }
);

const initialState: ConnectionsState = {
  ...connectionsModalDataSourceInitialState,
  activeConnectionType: ALL_CONNECTIONS,
  activeConnectionRow: '',
  selectedRows: [],
  tableData: [],
  isLoading: false,
  activeEditRow: null,
  visibleConnectionsDataSourceModal: false,
  connectionActiveId: null,
  disabledConnectionsDataSourceModal: false,
};

const connectionsSlice = createSlice({
  name: connectionsSliceName,
  initialState,
  reducers: {
    ...connectionsModalDataSourceReducers,
    setActiveConnectionType(state, action: PayloadAction<TConnectionType>) {
      state.activeConnectionType = action.payload;
    },
    setSelectedRows(state, action: PayloadAction<React.Key[]>) {
      state.selectedRows = action.payload;
    },
    setActiveConnectionRow(state, action: PayloadAction<string>) {
      state.activeConnectionRow = action.payload;
    },
    setConnectionsTable(state, action: PayloadAction<ConnectionsTableData[]>) {
      state.tableData = action.payload;
    },
    setEditRow(state, action: PayloadAction<string | null>) {
      state.tableData = state.tableData.map((tableRow) => ({
        ...tableRow,
        isEdit: tableRow.connectionId === action.payload,
      }));
      state.activeEditRow = action.payload;
    },
    setVConnectionsDataSourceModal(state, action: PayloadAction<boolean>) {
      state.visibleConnectionsDataSourceModal = action.payload;
    },
    setDisabledConnectionsDataSourceModal(
      state,
      action: PayloadAction<boolean>
    ) {
      state.disabledConnectionsDataSourceModal = action.payload;
    },
    setConnectionActiveId(state, action: PayloadAction<null | string>) {
      state.connectionActiveId = action.payload;
    },
  },
  extraReducers: {
    ...connectionsModalDataSourceExtraReducers,
    [fetchConnectionsTable.fulfilled.type]: (
      state,
      action: PayloadAction<ConnectionsResponseDTO[]>
    ) => {
      state.tableData = action.payload.map((el) => ({
        ...el,
        isEdit: false,
        ...(el.children && el.children.length
          ? {
              children: el.children.map((child: any) => ({
                ...child,
                icon: 'icon-db',
                selectable: false,
                key: `${child.connectionId}:${child.name}`,
              })),
            }
          : {}),
      }));
      state.isLoading = false;
    },
    [fetchConnectionsTable.pending.type]: (state) => {
      state.isLoading = true;
      state.tableData = [];
      state.selectedRows = [];
    },
    [fetchConnectionsTable.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = connectionsSlice;

export { actions as connectionsActions };
export { reducer as connectionsReducer };
