import React from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import './ConfirmModal.scss';
import {
  Button,
  ButtonModify,
  Modal,
  useModalVisible,
  useAdministrationModalVisible,
} from '@shared';
import { IModalProps } from '@modules/development';
import { useAppDispatch } from '@modules/store/hooks';
import { removeOpenModal } from '@modules/development/DevelopmentSlice';
import { useTranslationPath } from '@modules/languageProvider';
import { useAdministrationAction } from '@modules/administration';

export interface IConfirmModalProps extends IModalProps {
  contentText?: string | React.ReactNode;
  okButtonText?: string;
  okButtonDisabled?: boolean;
  cancelButtonText?: string;
  cancelButtonModifiers?: ButtonModify[];
  closeButtonText?: string;
  closeButtonModifiers?: ButtonModify[];
  buttonsOrder?: [number, number, number];
  kind?: 'error' | 'warning' | 'info';
  meta?: { [key: string]: any };
}

export interface IConfirmModal {
  modalProps: IConfirmModalProps;
  onOk?: () => void;
  onCancel?: () => void;
  onClose: () => void;
}

export const ConfirmModal: React.FC<IConfirmModal> = ({
  children,
  modalProps,
  onOk: onOkCallback,
  onCancel: onCancelCallback,
  onClose: onCloseCallback,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const translate = useTranslationPath('modals.confirmModal');
  const page = location.pathname.split('/')[1];
  const { removeAdministrationOpenModal } = useAdministrationAction();

  const isVisible =
    page === 'development'
      ? useModalVisible(modalProps.type)
      : useAdministrationModalVisible(modalProps.type);

  const { cancelButtonModifiers, closeButtonModifiers, buttonsOrder } =
    modalProps;

  const onOk = () => {
    onOkCallback && onOkCallback();
    onClose();
  };

  const onCancel = () => {
    onCancelCallback && onCancelCallback();
    onClose();
  };

  const onClose = () => {
    onCloseCallback();
    page === 'development'
      ? dispatch(removeOpenModal({}))
      : removeAdministrationOpenModal({});
  };

  const getContentText = () => {
    if (children) return children;

    if (typeof modalProps.contentText === 'string')
      return (
        <div dangerouslySetInnerHTML={{ __html: modalProps.contentText }} />
      );

    return modalProps.contentText;
  };

  const icon = useMemo(() => {
    switch (modalProps.kind) {
      case 'error':
        return 'icon-error-red';
      case 'info':
        return 'icon-alert-blue';
      case 'warning':
        return 'icon-alert-yellow';
      default:
        return undefined;
    }
  }, [modalProps.kind]);

  return (
    <Modal
      isOn={isVisible}
      modalProps={{
        title: modalProps?.title || translate('title'),
        icon: modalProps?.icon || icon,
        type: modalProps.type,
        width: modalProps?.width || '600px',
        footer: (
          <div className="btn-group-bottom">
            {onOkCallback && (
              <Button
                kind="normal"
                modifiers={['hover-box-shadow']}
                style={buttonsOrder && { order: buttonsOrder[0] }}
                disabled={modalProps.okButtonDisabled}
                onClick={onOk}
                children={modalProps?.okButtonText || translate('ok')}
              />
            )}

            {onCancelCallback && (
              <Button
                kind="normal"
                modifiers={cancelButtonModifiers || ['hover-box-shadow']}
                style={buttonsOrder && { order: buttonsOrder[1] }}
                onClick={onCancel}
                children={modalProps?.cancelButtonText || translate('cancel')}
              />
            )}

            <Button
              kind="normal"
              modifiers={closeButtonModifiers || ['second', 'hover-box-shadow']}
              style={buttonsOrder && { order: buttonsOrder[2] }}
              onClick={onClose}
              children={modalProps?.closeButtonText || translate('close')}
            />
          </div>
        ),
      }}
      page={page}
      onClose={onClose}
    >
      <div className="confirm-modal">{getContentText()}</div>
    </Modal>
  );
};
