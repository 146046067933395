import React, { useMemo } from 'react';
import { SimpleTable, Card } from '@shared';
import './ListMessage.scss';
import { useTranslationPath } from '@modules/languageProvider';

interface IListMessage {}

const ListMessage: React.FC<IListMessage> = () => {
  const translateColumn = useTranslationPath(
    'administrationTab.technicalMonitoring'
  );
  const tableData = useMemo(() => {
    return [
      {
        messageType: 'INFO',
        source: 'audit',
        message: '',
        time: '05.05.2021 19:36:11',
      },
      {
        messageType: 'INFO',
        source: 'audit',
        message: '',
        time: '05.05.2021 19:36:11',
      },
      {
        messageType: 'INFO',
        source: 'audit',
        message: '',
        time: '05.05.2021 19:36:11',
      },
      {
        messageType: 'INFO',
        source: 'audit',
        message: '',
        time: '05.05.2021 19:36:11',
      },
      {
        messageType: 'INFO',
        source: 'audit',
        message: '',
        time: '05.05.2021 19:36:11',
      },
      {
        messageType: 'INFO',
        source: 'audit',
        message: '',
        time: '05.05.2021 19:36:11',
      },
      {
        messageType: 'INFO',
        source: 'audit',
        message: '',
        time: '05.05.2021 19:36:11',
      },
    ];
  }, []);
  const columns = [
    {
      title: translateColumn('messageType'),
      dataIndex: 'messageType',
      width: '20%',
    },
    {
      title: translateColumn('source'),
      dataIndex: 'source',
      width: '20%',
    },
    {
      title: translateColumn('message'),
      dataIndex: 'message',
      // width: '30%',
    },
    {
      title: translateColumn('time'),
      dataIndex: 'time',
      width: '20%',
    },
  ];

  return (
    <div className="list-message">
      <Card title={translateColumn('messages')} icon="message">
        <SimpleTable
          className="diagram-data__simple-table"
          dataSource={tableData}
          columnsData={columns}
          hideSelectAll
          size="large"
        />
      </Card>
    </div>
  );
};

export { ListMessage };
