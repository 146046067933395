import React, { Key, useEffect, useState, VFC } from 'react';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import './communicationChannelAddModal.scss';
import {
  Button,
  CheckboxEye,
  DataTypesSelect,
  IModalProps,
  Input,
  InputWithButton,
  Modal,
  ModalBox,
  SimpleTable,
  Tooltip,
  useModalVisible,
} from '@shared';
import { useAppDispatch } from '@modules/store';
import { getDataTypes } from '@modules/references';
import { ChooseCustomCodeModal } from '@modules/development/modules/Diagrams/components/DiagramBlocks';
import { removeOpenModal } from '@modules/development/DevelopmentSlice';
import { CommunicationFieldSettingsModal } from '../communicationFieldSettingsModal';

type FieldsRecordType = {
  key: Key;
  fieldName?: string;
  variableName?: string;
  variableType?: string;
  fieldType?: string;
  required?: boolean;
  visible?: boolean;
  selectable?: boolean;
};

type VariablesRecordType = {
  key: Key;
  variableName?: string;
  variableType?: string;
};

type OpenedModal =
  | {
      type: 'chooseCustomCode';
    }
  | {
      type: 'fieldSettings';
    };

interface ICommunicationChannelAddModal {
  modalProps: IModalProps;
  onClose: () => void;
}

export const CommunicationChannelAddModal: VFC<ICommunicationChannelAddModal> =
  ({ modalProps, onClose }) => {
    const dispatch = useAppDispatch();
    const isVisible = useModalVisible(modalProps.type);

    const [openedModal, setOpenedModal] = useState<OpenedModal | null>(null);

    const fieldsColumns: ColumnsType<FieldsRecordType> = [
      {
        title: 'Название поля',
        dataIndex: 'fieldName',
        ellipsis: true,
        render: (value) => <Input size="small" value={value} />,
      },
      {
        title: 'Имя переменной',
        dataIndex: 'variableName',
        width: '126px',
        ellipsis: true,
        render: (value) => <Input size="small" value={value} />,
      },
      {
        title: 'Тип',
        dataIndex: 'variableType',
        width: '150px',
        render: (value) => <DataTypesSelect size="small" value={value} />,
      },
      {
        title: 'Тип поля',
        dataIndex: 'fieldType',
        width: '182px',
        render: (value) => (
          <InputWithButton
            value={value}
            size="small"
            bordered={false}
            readOnly
            onClick={() => {
              setOpenedModal({ type: 'fieldSettings' });
            }}
          />
        ),
      },
      {
        title: <span className="error">*</span>,
        dataIndex: 'required',
        align: 'center',
        width: '56px',
        render: (value, { visible }) =>
          typeof visible !== 'undefined' && (
            <Tooltip
              title="Установить обязательность поля"
              placement="bottomRight"
            >
              <Checkbox defaultChecked={value} />
            </Tooltip>
          ),
      },
      {
        dataIndex: 'visible',
        align: 'center',
        width: '56px',
        render: (value) =>
          typeof value !== 'undefined' && (
            <CheckboxEye defaultChecked={value} />
          ),
      },
    ];

    const fieldsData: FieldsRecordType[] = [
      {
        key: '1',
        fieldName: 'Тип рассылки',
        variableName: 'Название',
        variableType: '2',
        fieldType: 'Динамический список',
        required: true,
        visible: false,
        selectable: false,
      },
      {
        key: '2',
        fieldName: 'Часовой пояс клиента',
        variableName: 'Название переменной',
        variableType: '4',
        fieldType: 'Динамический список',
        required: false,
        visible: true,
        selectable: false,
      },
      {
        key: '3',
        fieldName: 'Начало коммуникации',
        variableName: 'Название переменной',
        variableType: '5',
        fieldType: 'Статистический список',
        required: false,
        visible: true,
        selectable: false,
      },
      {
        key: '4',
        fieldName: 'Окончание коммуникации',
        variableName: 'Название переменной',
        variableType: '5',
        fieldType: 'Ручной ввод',
        required: false,
        visible: true,
        selectable: false,
      },
      {
        key: '5',
        fieldName: 'Текст сообщения',
        variableName: 'Название переменной',
        variableType: '2',
        fieldType: 'Флаг',
        required: false,
        visible: true,
        selectable: false,
      },
      {
        key: '6',
        fieldName: '',
        variableName: '',
        variableType: '',
        fieldType: 'Флаг',
      },
    ];

    const variablesColumns: ColumnsType<VariablesRecordType> = [
      {
        title: 'Название',
        dataIndex: 'variableName',
        ellipsis: true,
        render: (value) => <Input size="small" value={value} />,
      },
      {
        title: 'Тип',
        dataIndex: 'variableType',
        width: '154px',
        render: (value) => <DataTypesSelect size="small" value={value} />,
      },
    ];

    const variablesData: VariablesRecordType[] = [
      {
        key: '1',
        variableName: 'email',
        variableType: '2',
      },
      {
        key: '2',
        variableName: 'name',
        variableType: '2',
      },
      {
        key: '3',
        variableName: 'balance',
        variableType: '1',
      },
      {
        key: '4',
        variableName: 'card_mask',
        variableType: '2',
      },
    ];

    useEffect(() => {
      dispatch(getDataTypes());
    }, []);

    return (
      <React.Fragment>
        <Modal
          isOn={isVisible}
          modalProps={{
            type: modalProps.type,
            title: 'Новый канал коммуникации',
            width: '1332px',
            footer: (
              <div className="btn-group-bottom">
                <Button
                  kind="normal"
                  modifiers={['hover-box-shadow']}
                  children="Сохранить как"
                />
                <Button
                  kind="normal"
                  modifiers={['hover-box-shadow']}
                  children="Сохранить"
                />
                <Button
                  kind="normal"
                  modifiers={['second', 'hover-box-shadow']}
                  onClick={onClose}
                  children="Отмена"
                />
              </div>
            ),
          }}
          onClose={onClose}
        >
          <div className="communication-channel-add-modal">
            <div className="communication-channel-add-modal__top">
              <Input label="Название канала" defaultValue="Импорт выборки" />

              <Input label="Код" />

              <InputWithButton
                label="Кастомный код"
                onClick={() => setOpenedModal({ type: 'chooseCustomCode' })}
              />
            </div>

            <div className="communication-channel-add-modal__tables">
              <ModalBox
                className="communication-channel-add-modal__fields"
                title="Поля блока коммуникации"
                actions={[
                  { icon: 'icon-add', onClick: () => {} },
                  { icon: 'icon-delete', onClick: () => {} },
                ]}
                showHeader
              >
                <SimpleTable
                  columnsData={fieldsColumns}
                  dataSource={fieldsData}
                  selectedRowKeys={[]}
                  setSelectedRowKeys={() => {}}
                />
              </ModalBox>

              <ModalBox
                className="communication-channel-add-modal__variables"
                title="Переменные коммуникации"
                actions={[
                  { icon: 'icon-add', onClick: () => {} },
                  { icon: 'icon-delete', onClick: () => {} },
                ]}
                showHeader
              >
                <SimpleTable
                  columnsData={variablesColumns}
                  dataSource={variablesData}
                  selectedRowKeys={[]}
                  setSelectedRowKeys={() => {}}
                />
              </ModalBox>
            </div>
          </div>
        </Modal>

        {openedModal?.type === 'chooseCustomCode' && (
          <ChooseCustomCodeModal
            modalProps={{ type: openedModal.type }}
            onSave={(script) => console.log(script)}
            onClose={() => {
              setOpenedModal(null);
              dispatch(removeOpenModal({}));
            }}
          />
        )}

        {openedModal?.type === 'fieldSettings' && (
          <CommunicationFieldSettingsModal
            modalProps={{ type: openedModal.type }}
            onClose={() => {
              setOpenedModal(null);
              dispatch(removeOpenModal({}));
            }}
          />
        )}
      </React.Fragment>
    );
  };
