import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import './SidePanel.scss';
import { Icon } from '@modules/icon';
import { useRolesHelper } from '@modules/services';
import { Navigation } from '../../types/interface';

interface ISidePanel {
  hideHeader?: boolean;
}

// @ts-ignore
const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

const routes: string[] = [];

export const SidePanel: FC<ISidePanel> = ({ hideHeader }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { rolesList, hasRoles } = useRolesHelper();

  const navigation: Navigation[] = [
    {
      text: 'Home',
      translation: 'sidePanel.home',
      path: '/',
      icon: 'icon-home',
    },
    {
      text: 'Разработка',
      translation: 'sidePanel.development',
      path: '/development',
      icon: 'icon-development',
      childrens: [
        // TODO не очень хороший вариант
        // @ts-ignore
        /*
        hasRoles([rolesList.DIAGRAM_READ_ALL]) && {
          text: 'Диаграммы',
          translation: 'sidePanel.diagrams',
          path: '/development/diagrams',
        },*/
        // @ts-ignore
        hasRoles([rolesList.SCRIPT_READ_ALL]) && {
          text: 'Кастомный код',
          translation: 'sidePanel.customCodes',
          path: '/development/custom-code-list',
        },
        // @ts-ignore
        /* hasRoles([rolesList.COMPLEX_TYPE_READ_ALL]) && {
          text: 'Пользовательские типы данных',
          translation: 'sidePanel.customDataTypes',
          path: '/development/custom-data-types',
        }, */
        // @ts-ignore
        /* hasRoles([rolesList.AGGREGATE_READ_ALL_BY_GROUPING_ELEMENT]) && {
          text: 'Агрегаты',
          translation: 'sidePanel.aggregates',
          path: '/development/aggregates',
        }, */
        /* {
          text: 'Каналы коммуникации',
          translation: 'sidePanel.communicationChannels',
          path: '/development/сommunication-сhannels',
        }, */
        {
          text: 'Потоки',
          translation: 'sidePanel.streams',
          path: '/development/streams',
        },
        {
          text: 'Схемы',
          translation: 'Схемы',
          path: '/development/schemas',
        },
      ],
    },
    /* {
      text: 'Операционный отчет',
      translation: 'sidePanel.operationalReporting',
      path: '/operational-reporting',
      icon: 'icon-operational_reporting',
      childrens: [
        {
          text: 'Статистика принятых решений',
          translation: 'sidePanel.statisticsOfDecisionsMade',
          path: '/operational-reporting/statistics-of-decisions-made',
        },
        {
          text: 'Количество заявок',
          translation: 'sidePanel.numberOfApplications',
          path: '/operational-reporting/number-of-applications',
        },
        {
          text: 'Количество сработавших правил',
          translation: 'sidePanel.numberOfTriggeredRules',
          path: '/operational-reporting/number-of-triggered-rules',
        },
        {
          text: 'Количество ошибок при  работе стратегии',
          translation: 'sidePanel.numberOfErrorsDuring',
          path: '/operational-reporting/number-of-errors-during',
        },
        {
          text: 'Время обработки заявки',
          translation: 'sidePanel.applicationProcessingTime',
          path: '/operational-reporting/application-processing-time',
        },
      ],
    }, */
    {
      text: 'Администрирование',
      translation: 'sidePanel.administration',
      path: '/administration',
      icon: 'icon-administration',
      childrens: [
        {
          text: 'Технический мониторинг',
          translation: 'sidePanel.technicalMonitoring',
          path: '/administration/tech-monitoring',
        },
        {
          text: 'Развертывание диаграмм',
          translation: 'sidePanel.deployingDiagrams',
          path: '/administration/deploy-diagram',
        },
        {
          text: 'Блокировки',
          translation: 'administrationTab.solutionLists.sidePanel.lockout',
          path: '/administration/lockout',
        },
        // @ts-ignore
        hasRoles([rolesList.EXT_SERVICE_READ_ALL]) && {
          text: 'Доступ к внешним сервисам',
          translation: 'sidePanel.externalServiceAccess',
          path: '/administration/external-service-access',
        },
        // @ts-ignore
        hasRoles([rolesList.DATA_PROVIDER_READ_ALL]) && {
          text: 'Источники данных',
          translation: 'sidePanel.dataSources',
          path: '/administration/data-sources',
        },
        /* {
          text: 'Подключение к серверам',
          translation: 'administrationTab.solutionLists.sidePanel.environments',
          path: '/administration/environments',
        }, */
      ],
    },
    {
      text: 'Подключения',
      translation: 'sidePanel.connections',
      path: '/connections',
      icon: 'icon-connect',
    },
  ];

  if (HIDE_FEATURES) {
    delete navigation[1].childrens?.[4];
    delete navigation[2];
    delete navigation[3].childrens?.[0];

    if (
      !hasRoles([
        (rolesList.DIAGRAM_READ_ALL,
        rolesList.SCRIPT_READ_ALL,
        rolesList.COMPLEX_TYPE_READ_ALL,
        rolesList.AGGREGATE_READ_ALL_BY_GROUPING_ELEMENT),
      ])
    ) {
      delete navigation[1];
    }
  }

  navigation.forEach((nav) => {
    if (nav.childrens)
      nav.childrens.forEach((child) => routes.push(child.path));
    routes.push(nav.path);
  });

  const renderListItem = (data: Navigation) => {
    if (data.childrens) {
      return (
        <Menu.SubMenu
          key={data.path}
          icon={
            data.icon && (
              <Icon className="side-panel__menu-icon" name={data.icon} />
            )
          }
          title={data.translation ? t(data.translation) : data.text}
        >
          {data.childrens.filter((c) => c).map((item) => renderListItem(item))}
        </Menu.SubMenu>
      );
    }

    return (
      <Menu.Item
        key={data.path}
        icon={
          data.icon && (
            <Icon className="side-panel__menu-icon" name={data.icon} />
          )
        }
      >
        <Link to={data.path}>
          {data.translation ? t(data.translation) : data.text}
        </Link>
      </Menu.Item>
    );
  };

  return (
    <div className="side-panel">
      {hideHeader ? null : (
        <div className="side-panel__header">
          <div className="side-panel__header-icon">
            <Icon name="icon-side_logo" size={20} />
          </div>
          <span className="side-panel__header-text">Balalaika</span>
        </div>
      )}
      <Menu
        className="side-panel__menu side-panel__main-nav"
        defaultSelectedKeys={['/']}
        selectedKeys={[
          (routes.includes(history.location.pathname) &&
            history.location.pathname) ||
            history.location.pathname.split('/').slice(0, 3).join('/'),
        ]}
        mode="inline"
      >
        {navigation.map((item) => renderListItem(item))}
      </Menu>
    </div>
  );
};
