import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Input, InputRef, Select, Form, Checkbox } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import './TableCell.scss';
import { Button, Tooltip } from '@shared';
import { Icon } from '@modules/icon';

const { Option } = Select;

interface ISelectCell {
  id: number;
  title: string;
}

interface ITableCell {
  index: number;
  title: React.ReactNode;
  editable: boolean;
  type: string;
  children: React.ReactNode;
  dataIndex: string;
  record: any;
  startEditing?: boolean;
  selectCellAttr?: ISelectCell[];
  required: boolean;
  className?: string;
  invalid?: any;
  disabled?: boolean;
  onClickImage: (e: any, item: any, index: number) => void;
  handleSave: (record: any, index?: number) => void;
}

const Context = React.createContext<FormInstance<any> | null>(null);

const TableCell: React.FC<ITableCell> = (props) => {
  const {
    index,
    title,
    editable,
    type,
    children,
    dataIndex,
    record,
    startEditing,
    selectCellAttr,
    required,
    invalid,
    disabled,
    onClickImage,
    handleSave,
    ...restProps
  } = props;
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(Context);

  useEffect(() => {
    if (editing && !startEditing) {
      inputRef.current?.focus();
    }
  }, [editing, startEditing]);

  const toggleEdit = () => {
    !startEditing && setEditing(!editing);
    inputRef.current?.blur();
    form?.setFieldsValue(record[dataIndex]);
  };

  const save = async () => {
    try {
      const values = await form?.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, index);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const renderItem = () => {
    switch (type) {
      case 'input':
        return (
          <Input
            id={`table-cell-input-${dataIndex}-${record.key}`}
            ref={inputRef}
            disabled={disabled}
            onPressEnter={save}
            onBlur={save}
          />
        );
      case 'select':
        return (
          <Select
            onChange={save}
            disabled={disabled}
            suffixIcon={<CaretDownOutlined />}
          >
            {selectCellAttr?.map((item) => (
              <Option key={`table-cell-option-${item.id}`} value={item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        );
      case 'checkbox':
        return <Checkbox onChange={save} />;
      case 'inputWithButton':
        return (
          <Input
            id={`table-cell-input-${dataIndex}-${record.key}`}
            ref={inputRef}
            disabled={disabled}
            onPressEnter={save}
            onBlur={save}
            addonAfter={
              <Button
                kind="icon"
                onClick={(e) => onClickImage(e, record, index)}
                disabled={disabled}
              >
                <Icon className="img-icon" name="icon-edit" />
              </Button>
            }
          />
        );
    }
  };

  let childNode = children;

  if (editable) {
    childNode =
      editing || startEditing ? (
        <Form.Item
          className={`table-cell__form-item_${type}`}
          style={{ margin: 0 }}
          name={dataIndex}
          valuePropName={type === 'checkbox' ? 'checked' : 'value'}
          initialValue={record[dataIndex]}
          rules={[
            {
              required: required,
              message: `${title} обязательно`, // TODO добавить проверку на уникальность названия
            },
          ]}
        >
          {renderItem()}
        </Form.Item>
      ) : (
        <div
          className="table-cell-value-wrap"
          // style={{ paddingRight: 24 }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }

  return (
    <Tooltip error={!!invalid} title={invalid}>
      <td
        {...restProps}
        className={classNames(restProps.className, invalid && 'cell-invalid')}
      >
        {childNode}
      </td>
    </Tooltip>
  );
};

interface RowProps {
  index: number;
}

const TableRow: React.FC<RowProps> = (props) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <Context.Provider value={form}>
        <tr {...props} />
      </Context.Provider>
    </Form>
  );
};

export { TableCell, TableRow };
