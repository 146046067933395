import React, {
  useState,
  useMemo,
  useEffect,
  createContext,
  useCallback,
} from 'react';
import { Button, Modal } from '@shared';
import { Form } from 'antd';
import { TConnectionType } from '@modules/connections/types/connectionsTypes';
import {
  ConnectionsDataSourceBrokerMessage,
  ConnectionsDataSourceCommonFields,
  ConnectionsDataSourceDBFields,
  ConnectionsDataSourceExService,
  ConnectionsDataSourceModalFields,
  ConnectionsDataSourceTestDBFields,
} from './types/connectionsModalDataSourceTypes';
import {
  DBMS,
  EXTERNAL_SERVICES,
  MESSAGE_BROKERS,
} from '@modules/connections/constants';
import {
  DataSourceFieldsBrokerMessage,
  DataSourceFieldsCommon,
  DataSourceFieldsDB,
  DataSourceFieldsExternalService,
} from './components';
import { getDataTypes } from '@modules/references';
import { useAppDispatch } from '@modules/store';
import { FormInstance } from 'antd/lib/form';
import { useTranslationPath } from '@modules/languageProvider';
import {
  ApproveModal,
  ApproveModalModalProps,
} from '@modules/connections/components';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import './ConnectionsModalDataSource.scss';
import { fetchConnectionsTable } from '@modules/connections/ConnectionsSlice';
import { useConnectionsSelector } from '@modules/connections/hooks';
import { showNotification } from '@modules/notification';
import { AnyAction } from '@reduxjs/toolkit';

export type DataSourceForm<T> = ConnectionsDataSourceCommonFields & Partial<T>;
export type ConnectionsFormInstance = DataSourceForm<
  ConnectionsDataSourceDBFields &
    ConnectionsDataSourceExService &
    ConnectionsDataSourceBrokerMessage
>;

export const ConnectionsModalDataFromContext = createContext<{
  form: FormInstance<ConnectionsFormInstance>;
  isEdit: boolean;
}>({} as any);

interface IConnectionsModal {
  initialFormValues: Partial<ConnectionsFormInstance>;
  saveConnection: (values: ConnectionsFormInstance) => Promise<any>;
  testConnection: (values: ConnectionsDataSourceTestDBFields) => Promise<any>;
  closeModal: () => void;
  isEdit: boolean;
  disabled?: boolean;
}

const validationStatusStyle = {
  SUCCESS: 'connections__test-status-success',
  TEST_FAILED: 'connections__test-status-failed',
  NOT_FILLED: 'connections__test-status-incomplete',
};

type validationStatus = keyof typeof validationStatusStyle;

export const ConnectionsModalDataSource: React.FC<IConnectionsModal> = ({
  initialFormValues,
  saveConnection,
  testConnection,
  isEdit,
  closeModal,
  disabled,
}) => {
  const t = useTranslationPath('connections.sourceDataModal');
  const dispatch = useAppDispatch();
  const { activeConnectionType } = useConnectionsSelector();
  const [form] = Form.useForm<ConnectionsFormInstance>();
  const { type = null } = initialFormValues;
  const [typeSource, setTypeSource] = useState<TConnectionType | null>(type);
  const [openedModal, setOpenedModal] = useState<ApproveModalModalProps | null>(
    null
  );
  const [validationStatus, setValidationStatus] = useState<{
    status?: validationStatus;
    message: string | null;
  }>();
  const isExternalService = typeSource === EXTERNAL_SERVICES;
  const isBrokerMessage = typeSource === MESSAGE_BROKERS;

  const saveConnectionDataSource = (values: ConnectionsFormInstance) => {
    // Перед сохранением надо получить статус
    getConnectionStatus().then((r) =>
      saveConnection({ ...values, connectionStatus: r?.status })
        .then(() => closeModal())
        .catch((e) =>
          dispatch(
            showNotification({
              type: 'error',
              message: e?.message?.toString(),
              duration: 3000,
            }) as unknown as AnyAction
          )
        )
        .finally(() => dispatch(fetchConnectionsTable(activeConnectionType)))
    );
  };

  const onValuesChange = (fields: ConnectionsFormInstance) => {
    if (fields[ConnectionsDataSourceModalFields.sourceType]) {
      if (isEdit && typeSource) {
        setOpenedModal({
          type: 'changeConnectionType',
          yesClick: () => {
            setTypeSource(fields[ConnectionsDataSourceModalFields.sourceType]);
            setOpenedModal(null);
          },
        });
      } else {
        setTypeSource(fields[ConnectionsDataSourceModalFields.sourceType]);
      }
    }
    // TODO: Перенести драйверы на бек и переписать логику

    if (fields[ConnectionsDataSourceModalFields.typeDB]) {
      const currentDBType = form.getFieldValue([
        ConnectionsDataSourceModalFields.typeDB,
      ]);
      currentDBType === 'ORACLE'
        ? form.setFieldValue(
            [ConnectionsDataSourceModalFields.driver],
            'oracle.jdbc.driver.OracleDriver'
          )
        : form.setFieldValue(
            [ConnectionsDataSourceModalFields.driver],
            'org.postgresql.Driver'
          );
    }
  };

  const checkOnEmptyValue = (errorFields: any[], restValues: any): boolean => {
    let isEmptyConfirmModal = false;

    errorFields.forEach(({ name }) => {
      if (name.length === 1) {
        if (!restValues[name[0]]) {
          isEmptyConfirmModal = true;
          return;
        }
      }
    });

    return isEmptyConfirmModal;
  };

  const onFinishFailed = (
    errorInfo: ValidateErrorEntity<ConnectionsFormInstance>
  ) => {
    console.log(errorInfo);
    const { values, errorFields } = errorInfo;
    const { name, type, ...restValues } = values;

    if (name && type) {
      const checkEmptiesValues = checkOnEmptyValue(errorFields, restValues);
      if (checkEmptiesValues) {
        setOpenedModal({
          type: 'saveOnEmpty',
          yesClick: () => saveConnectionDataSource(form.getFieldsValue()),
        });
      } else {
        setOpenedModal({
          type: 'saveOnValidation',
          yesClick: () => saveConnectionDataSource(form.getFieldsValue()),
        });
      }
    }
  };

  const onFinish = (values: ConnectionsFormInstance) => {
    console.log('values', values);
    saveConnectionDataSource(values);
  };

  const dynamicModalProps = useMemo(() => {
    if (isExternalService) {
      return {
        title: t('externalServiceTitle'),
        icon: 'icon-calling_external_service',
        width: '1080px',
      };
    }
    if (isBrokerMessage) {
      return {
        width: '841px',
      };
    }

    return {};
  }, [isExternalService, isBrokerMessage]);

  const onSave = async () => {
    try {
      await form.validateFields([
        ConnectionsDataSourceModalFields.sourceName,
        ConnectionsDataSourceModalFields.sourceType,
      ]);
      form.submit();
    } catch (e) {
      console.log(e);
    }
  };

  const onTest = async () => {
    getConnectionStatus().then((res) => {
      if (res) {
        setValidationStatus(res);
      }
    });
  };

  const getConnectionStatus = async (): Promise<
    | {
        status?: validationStatus;
        message: any;
      }
    | undefined
  > => {
    return form
      .validateFields()
      .then((val) => {
        if (typeSource === DBMS) {
          if (
            form.getFieldValue('sourceType') === 'ORACLE' ||
            form.getFieldValue('sourceType') === 'POSTGRES'
          ) {
            const fields = Object.getOwnPropertyNames(
              new ConnectionsDataSourceTestDBFields()
            );
            console.log(fields);
            return testConnection(form.getFieldsValue(fields));
          }
          return new Promise((resolve) =>
            resolve({
              result: 'SUCCESS',
            })
          );
        } else {
          return new Promise((resolve) =>
            resolve({
              result: 'SUCCESS',
            })
          );
        }
      })
      .catch((e) => {
        return {
          result: 'NOT_FILLED',
        };
      })
      .then((res: any) => {
        switch (res.result) {
          case 'FAILED':
            return {
              status: 'TEST_FAILED' as validationStatus,
              message: res.description,
            };
          case 'SUCCESS':
            return {
              status: 'SUCCESS' as validationStatus,
              message: t('testStatuses.success'),
            };
          case 'NOT_FILLED':
            return {
              status: 'NOT_FILLED' as validationStatus,
              message: t('testStatuses.notFilled'),
            };
        }
      });
  };

  const onCloseClick = () => {
    // Необходимо для сравнения.
    // При обычном JSON.stringify порядок ключей не совпадает.
    const stringifyOrder = (obj: any) => {
      const allKeys = new Set();
      JSON.stringify(obj, (key, value) => {
        if (value !== '') {
          allKeys.add(key);
        }
        return value;
      });
      return JSON.stringify(obj, Array.from(allKeys).sort() as any);
    };

    if (
      stringifyOrder(initialFormValues) !==
      stringifyOrder(form.getFieldsValue(true))
    ) {
      setOpenedModal({
        type: 'saveUnsavedChanges',
        yesClick: () => {
          closeModal();
          setOpenedModal(null);
        },
      });
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    dispatch(getDataTypes());
  }, []);

  const renderHeader = useCallback(() => {
    if (!validationStatus?.status) {
      return null;
    }
    return (
      <div
        className={`connections__test-status ${
          validationStatusStyle[validationStatus.status]
        }`}
      >
        {validationStatus.message}
      </div>
    );
  }, [validationStatus]);

  return (
    <>
      {openedModal?.type === 'saveUnsavedChanges' && (
        <ApproveModal
          modalProps={openedModal}
          onClose={() => setOpenedModal(null)}
          modalText={t('confirmExitUnsavedChanges')}
        />
      )}
      {openedModal?.type === 'changeConnectionType' && (
        <ApproveModal
          modalProps={openedModal}
          onClose={() => setOpenedModal(null)}
          modalText={t('confirmChangeType')}
        />
      )}
      {openedModal?.type === 'saveOnEmpty' && (
        <ApproveModal
          modalProps={openedModal}
          onClose={() => setOpenedModal(null)}
          modalText={t('saveOnEmpty')}
        />
      )}
      {openedModal?.type === 'saveOnValidation' && (
        <ApproveModal
          modalProps={openedModal}
          onClose={() => setOpenedModal(null)}
          modalText={t('saveOnValidation')}
        />
      )}
      <Modal
        isOn={true}
        modalProps={{
          title: t('dataSourceTitle'),
          icon: 'icon-link',
          header: renderHeader(),
          footer: (
            <div className="btn-group-bottom">
              {!disabled && (
                <Button
                  className="btn-left"
                  kind="normal"
                  modifiers={['hover-box-shadow']}
                  children={t('connectionTest')}
                  onClick={() => onTest()}
                />
              )}

              {!disabled && (
                <Button
                  kind="normal"
                  modifiers={['hover-box-shadow']}
                  children={t('save')}
                  onClick={() => onSave()}
                />
              )}

              <Button
                kind="normal"
                modifiers={['second', 'hover-box-shadow']}
                children={t('cancel')}
                onClick={onCloseClick}
              />
            </div>
          ),
          width: '581px',
          helpKey: 'connect-data-source',
          ...dynamicModalProps,
        }}
        onClose={closeModal}
      >
        <Form
          form={form}
          disabled={!!disabled}
          className="connections__data-source-modal"
          onValuesChange={onValuesChange}
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
          initialValues={initialFormValues}
        >
          <ConnectionsModalDataFromContext.Provider value={{ form, isEdit }}>
            {typeSource === DBMS ||
              (typeSource === null && <DataSourceFieldsCommon />)}
            {typeSource === DBMS && <DataSourceFieldsDB />}
            {isExternalService && <DataSourceFieldsExternalService />}
            {isBrokerMessage && <DataSourceFieldsBrokerMessage />}
          </ConnectionsModalDataFromContext.Provider>
        </Form>
      </Modal>
    </>
  );
};
