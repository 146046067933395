import { Key } from 'react';

export interface ISchemas {
  key: Key;
  addNewRequested?: boolean;
  selectedRowTable: Key[];
  search?: string;
}

export const SCHEMA_OBJTYPE = 'schema';
