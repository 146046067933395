import React, { useEffect, useState } from 'react';
import './SaveAsModal.scss';
import { Input, Button } from '@shared';
import { useTranslation } from 'react-i18next';

interface ISaveAsModal {
  onSave: (title: string) => void;
  onClose: () => void;
}

const SaveAsModal: React.FC<ISaveAsModal> = (props) => {
  const { onSave, onClose } = props;
  const { t } = useTranslation();
  const [title, onChangeTitle] = useState('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onSaveButtonClick = () => {
    setIsDisabled(true);
    onSave(title);
  };

  useEffect(() => {
    return () => {
      setIsDisabled(false);
      onChangeTitle('');
    };
  }, []);

  return (
    <div className="save-as">
      <div className="save-as__row">
        <span className="create-block__label">{t('modals.newObjectName')}</span>
        <Input
          className="create-block__field"
          value={title}
          onChange={(e) => onChangeTitle(e.target.value)}
        />
      </div>
      <div className="btn-group-bottom">
        <Button
          kind="normal"
          modifiers={['hover-box-shadow']}
          onClick={onSaveButtonClick}
          disabled={isDisabled}
        >
          {t('actions.save')}
        </Button>
        <Button
          kind="normal"
          modifiers={['second', 'hover-box-shadow']}
          onClick={onClose}
        >
          {t('actions.cancel')}
        </Button>
      </div>
    </div>
  );
};

export { SaveAsModal };
