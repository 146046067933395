import React, { useMemo } from 'react';
import { DataSourcePanel } from '@modules/connections/components/connectionsModalDataSource/components';
import { useTranslationPath } from '@modules/languageProvider';
import { tranlationPath } from '@modules/administration/components/ExternalServiceCallSettingsModal/data';
import { useDataSourceVariables } from '../../hooks';
import { Table } from 'antd';
import { useConnectionsSelector } from '@modules/connections/hooks';
import { ConnectionsDataSourceModalFields } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import { Variable } from '@modules/administration/components/ExternalServiceCallSettingsModal/interfaces';
import { rawColumnsInputVariables } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/components/externalServiceInputVariables/rawColumns';

export const ExternalServiceInputVariables: React.FC = () => {
  const translate = useTranslationPath(tranlationPath);
  const { oneConnection = {} } = useConnectionsSelector();
  const { properties = {} } = oneConnection ?? ({} as any);
  const { inputVariables = [] } = properties;

  const {
    columns,
    toolbar,
    tableData,
    selectedTableData,
    setSelectedTableData,
  } = useDataSourceVariables(
    inputVariables,
    ConnectionsDataSourceModalFields.inputVariables,
    rawColumnsInputVariables
  );

  console.log(tableData);

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: false,
      onChange: (selectedRowKeys: React.Key[]) => {
        setSelectedTableData(selectedRowKeys);
      },
      selectedRowKeys: selectedTableData,
      getCheckboxProps: (record: Variable) => ({
        disabled: record.isChildren,
        name: record.variableName,
      }),
    }),
    [selectedTableData]
  );

  return (
    <DataSourcePanel
      title={translate('inputVariables')}
      panel={{ key: 'external-service-accordion-2' }}
      toolbar={{ data: toolbar }}
    >
      <Table
        columns={columns}
        dataSource={tableData}
        rowSelection={rowSelection}
        className="connections__table"
      />
    </DataSourcePanel>
  );
};
