import React, { useEffect, useMemo, useState, VFC } from 'react';
import './complexTypeViewModal.scss';
import {
  DataTypesSelect,
  Input,
  Modal,
  ModalBox,
  ModalFooter,
  SimpleTable,
} from '@shared';
import { IModalProps } from '@modules/development';
import { useTranslationPath } from '@modules/languageProvider';
import {
  complexTypeApi,
  IComplexTypeWithAttributes,
} from '@modules/complexType';
import { ColumnsType } from 'antd/lib/table';
import { Checkbox } from 'antd';

export type ComplexTypeViewModalProps =
  | ({
      versionId?: string;
      versionName?: string;
    } & IModalProps)
  | null;

interface IComplexTypeView {
  modalProps: ComplexTypeViewModalProps;
  onClose: () => void;
}

export const ComplexTypeViewModal: VFC<IComplexTypeView> = ({
  modalProps,
  onClose,
}) => {
  const translate = useTranslationPath('development.complexTypes');

  const [complexType, setComplexType] = useState<IComplexTypeWithAttributes>();

  const fetchComplexType = async (versionId: string) => {
    setComplexType(await complexTypeApi.getComplexType(versionId));
  };

  const columns: ColumnsType = [
    {
      key: 'attributeName',
      dataIndex: 'attributeName',
      title: translate('attrName'),
      render: (value: string) => <Input value={value} disabled />,
    },
    {
      key: 'typeId',
      dataIndex: 'typeId',
      title: translate('attrType'),
      render: (value: string) => <DataTypesSelect value={value} disabled />,
    },
    {
      key: 'array',
      dataIndex: 'array',
      title: translate('array'),
      align: 'center',
      render: (value: boolean) => <Checkbox checked={value} disabled />,
    },
  ];

  const data = complexType?.attributes.map((a) => ({
    attributeName: a.attributeName,
    typeId: a.complexFlag ? a.complexTypeVersionId : a.primitiveTypeId,
    array: a.arrayFlag,
  }));

  const modalTitle = useMemo(() => {
    const versionName = modalProps?.versionName;
    const typeName = complexType?.typeName;
    return [versionName, typeName, translate('readOnly')].join(' ');
  }, [modalProps?.versionName, complexType?.typeName, translate]);

  useEffect(() => {
    modalProps?.versionId
      ? fetchComplexType(modalProps.versionId)
      : setComplexType(undefined);
  }, [modalProps?.versionId]);

  return (
    <Modal
      modalProps={{
        title: modalTitle,
        type: modalProps?.type,
        width: 65,
        footer: <ModalFooter onClose={onClose} />,
      }}
      isOn={Boolean(modalProps)}
      onClose={onClose}
    >
      <div className="complex-type-view">
        <Input
          label={translate('typeName')}
          className="complex-type-view__name"
          value={complexType?.typeName}
          disabled
        />
        <ModalBox
          className="complex-type-view__table-wrapper"
          title={translate('typeAttr')}
          showHeader
          children={<SimpleTable columnsData={columns} dataSource={data} />}
        />
      </div>
    </Modal>
  );
};
