import React, { FC, Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './DataSources.scss';
import { ConfirmModal, IConfirmModalProps, IToolbarItem } from '@shared';
import { useAppDispatch, useAppSelector } from '@modules/store';
import type { IDataProviderUpdate } from '@modules/dataProvider';
import { selectRoles, rolesList } from '@modules/services';
import { useAdministrationSelector } from '../../hooks';
import {
  addDataProvider,
  deleteDataProviderByIds,
  fetchDataProvider,
  updateDataProviderById,
} from '../../actions';
import { IConnectDataSourceModalProps } from '../ConnectDataSourceModal/interfaces';
import { ConnectDataSourceModal } from '../ConnectDataSourceModal';
import { useDataSourcesTable } from './hooks';
import { DataSourcesWrapper } from './parts/DataSourcesWrapper';

type OpenedModal =
  | (IConnectDataSourceModalProps & { type: 'connect-data-source' })
  | (IConfirmModalProps & { type: 'confirmSave' })
  | null;

interface IDataSources {}

export const DataSources: FC<IDataSources> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { dataProvider } = useAdministrationSelector();
  const [openedModal, setOpenedModal] = useState<OpenedModal>(null);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const roles = useAppSelector(selectRoles);

  const [
    columns,
    data,
    rowKeys,
    selectedRowKeys,
    setSelectedRowKeys,
    search,
    setSearch,
    setSort,
    expandedRowKeys,
  ] = useDataSourcesTable(dataProvider);

  const onEditClick = (keys: Key[]) => {
    const item = dataProvider.data.find((item) => item.sourceId === keys[0]);
    if (!item) return console.error('dataProvider not found');

    setOpenedModal({
      type: 'connect-data-source',
      data: {
        sourceName: item.sourceName,
        connectionType: item.connectionType,
        sourceType: item.sourceType,
        username: item.username,
        password: item.password,
        serverName: item.serverName,
        port: item.port,
        additionalProperties: item.additionalProperties,
      },
      sourceId: item.sourceId,
    });
  };

  const onDeleteClick = () => {
    setOpenedModal({
      type: 'confirmSave',
      contentText: t('administrationTab.dataSources.confirmSaveText'),
      okButtonText: t('administrationTab.dataSources.confirmSaveOk'),
      closeButtonText: t('administrationTab.dataSources.confirmSaveClose'),
      kind: 'info',
    });
  };

  const toolbarActions: IToolbarItem[] = [
    { isDivider: true },
    {
      icon: 'icon-add',
      tooltip: t('administrationTab.dataSources.add'),
      disabled:
        !!selectedRowKeys.length ||
        !roles.includes(rolesList.DATA_PROVIDER_CREATE),
      onClick: () => setOpenedModal({ type: 'connect-data-source' }),
    },
    {
      icon: 'icon-delete',
      tooltip: t('administrationTab.dataSources.delete'),
      disabled:
        !selectedRowKeys.length ||
        !roles.includes(rolesList.DATA_PROVIDER_DELETE),
      onClick: () => onDeleteClick(),
    },
    {
      icon: 'icon-edit',
      tooltip: t('actions.edit'),
      disabled:
        !selectedRowKeys.length ||
        selectedRowKeys.length > 1 ||
        !roles.includes(rolesList.DATA_PROVIDER_UPDATE),
      onClick: () => onEditClick(selectedRowKeys),
    },
    {
      icon: 'icon-refresh',
      tooltip: t('actions.refresh'),
      onClick: () => dispatch<any>(fetchDataProvider()),
    },
  ];

  const onModalClose = () => {
    setOpenedModal(null);
    setSelectedRowKeys([]);
    setActiveItem(null);
  };

  const onConnectDataSourceSave = (
    body: IDataProviderUpdate,
    sourceId?: string
  ) => {
    if (sourceId) {
      dispatch<any>(updateDataProviderById(sourceId, body));
    } else {
      dispatch<any>(addDataProvider(body));
    }
  };

  const onDelete = () => {
    dispatch<any>(deleteDataProviderByIds(selectedRowKeys));
  };

  useEffect(() => {
    dispatch<any>(fetchDataProvider());
  }, []);

  return (
    <React.Fragment>
      <DataSourcesWrapper
        actions={toolbarActions}
        search={search}
        activeItem={activeItem}
        columns={columns}
        data={data}
        rowKeys={rowKeys}
        selectedRowKeys={selectedRowKeys}
        loading={dataProvider.isLoading}
        expandedRowKeys={expandedRowKeys}
        setSearch={setSearch}
        setActiveItem={setActiveItem}
        setSelectedRowKeys={setSelectedRowKeys}
        setSort={setSort}
        onRowEditClick={onEditClick}
      />

      {openedModal?.type === 'confirmSave' && (
        <ConfirmModal
          modalProps={openedModal}
          onOk={onDelete}
          onClose={onModalClose}
        />
      )}

      {openedModal?.type === 'connect-data-source' && (
        <ConnectDataSourceModal
          modalProps={openedModal}
          onSave={onConnectDataSourceSave}
          onClose={onModalClose}
          closeOnSave
          data={data}
        />
      )}
    </React.Fragment>
  );
};
