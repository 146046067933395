/**
 * Фильтрация массива по ключу вложенных объектов
 * @param {Array} array Сам массив.
 * @param {string} key Ключ, по которуму будет фильтрация
 * @return {Array} Возвращается отфильтрованный массив.
 */
export function filterEqualByKey<T>(array: T[], key: keyof T) {
  return array.reduce((newArray: T[], item: T) => {
    return newArray.some((b) => b[key] === item[key])
      ? newArray
      : [...newArray, item];
  }, []);
}
