import React, { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import './CalcScorecardModal.scss';
import {
  Button,
  IModalProps,
  Input,
  Loader,
  Modal,
  ModalBox,
  ModalFooter,
  SimpleTable,
  TextArea,
  useModalVisible,
} from '@shared';
import { Icon } from '@modules/icon';
import { useTranslationPath } from '@modules/languageProvider';
import {
  clearMappingRows,
  removeOpenModal,
  updateNodes,
} from '@modules/development/DevelopmentSlice';
import { AddElementModal } from '../AddElementModal';
import { InputValueModal } from '../InputValueModal';
import {
  ScorecardNode,
  MappingRow,
  ModalTypes,
  OpenedModal,
  IScorecardProperties,
} from './interfaces';
import {
  useCalcScorecardModalValidation,
  useCalcScorecardModalTable,
  useUploadScoresMappingExcelApi,
} from './hooks';
import { CALC_SCORECARD_MODAL_TRANSLATE_PATH } from './services';
import { ConfirmInvalidBlockSaveModal } from '..';
import { Link } from 'react-router-dom';
import { BlockInnerMeta, NodeAddedVariables } from '@modules/development';
import { useDiagramUtils } from '../../../hooks';
import { onAddLocalVariable } from '../common/utils/onAddLocalVariable';
import { SCORECARD_PROPERTIES_TEMPLATE } from '../../../constants';
import { useRolesHelper } from '@modules/services';

// @ts-ignore
const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

interface ICalcScorecardModal {
  modalProps: IModalProps | null;
  onClose: () => void;
  blockCode: string;
  closeOnSave?: boolean;
}

export const CalcScorecardModal: React.FC<ICalcScorecardModal> = ({
  modalProps,
  onClose,
  blockCode,
  closeOnSave,
}) => {
  const dispatch = useDispatch();
  const translate = useTranslationPath(CALC_SCORECARD_MODAL_TRANSLATE_PATH);
  const translateActions = useTranslationPath('actions');
  const isVisible = useModalVisible(modalProps?.type);

  const [selectedInputVarKeys, setSelectedInputVarKeys] = useState<Key[]>([]);
  const [selectedMappingKeys, setSelectedMappingKeys] = useState<Key[]>([]);
  const [openedModal, setOpenedModal] = useState<OpenedModal>(null);
  const [mappingRows, setMappingRows] = useState<MappingRow[]>([]);
  const [innerMeta, setInnerMeta] = useState<BlockInnerMeta>();
  const [addedVariables, setAddedVariables] = useState<NodeAddedVariables>({});
  const [scorecardData, setScorecardData] = useState<ScorecardNode>();

  const [invalidData, getErrors] = useCalcScorecardModalValidation(
    scorecardData,
    mappingRows
  );
  const { fetchNode, parseVariable } = useDiagramUtils();
  const { rolesList, hasRoles } = useRolesHelper();
  const disabled = !hasRoles([rolesList.NODE_UPDATE]);
  const {
    paramsInputColumn,
    paramsOutputColumn,
    scoreMapColumn,
    paramsInputData,
    paramsOutputData,
  } = useCalcScorecardModalTable(
    scorecardData,
    setScorecardData,
    invalidData,
    setOpenedModal,
    mappingRows,
    setMappingRows,
    disabled
  );

  useEffect(() => {
    fetchNode<IScorecardProperties | null>(blockCode).then(
      ({ node, blockInnerMeta }) => {
        const newMappingRows: MappingRow[] = [];
        const properties = node.properties || SCORECARD_PROPERTIES_TEMPLATE;

        properties.inputVariablesMapping.forEach((v) => {
          v.scoreValues.forEach((val) => {
            newMappingRows.push({
              key: uuidv4(),
              variableKey: v.variableName,
              variableValueMapScore: val,
            });
          });
        });

        setScorecardData({ ...node, properties });
        setAddedVariables(node.nodeAddedVariables || {});
        setInnerMeta(blockInnerMeta);
        setMappingRows(newMappingRows);
      }
    );
  }, [blockCode]);

  const paramsActions = [
    {
      icon: 'icon-add',
      tooltip: translateActions('add'),
      disabled: disabled,
      onClick: () => {
        if (scorecardData) {
          setScorecardData({
            ...scorecardData,
            properties: {
              ...scorecardData.properties,
              inputVariablesMapping: [
                ...scorecardData.properties.inputVariablesMapping,
                {
                  variableName: '',
                  isComplex: false,
                  typeId: '',
                  isArray: false,
                  defaultValue: 0,
                  scoreValues: [],
                },
              ],
            },
          });
        }
      },
    },
    {
      icon: 'icon-delete',
      tooltip: translateActions('delete'),
      disabled: !selectedInputVarKeys.length || disabled,
      onClick: () => {
        if (scorecardData) {
          setScorecardData({
            ...scorecardData,
            properties: {
              ...scorecardData.properties,
              inputVariablesMapping:
                scorecardData.properties.inputVariablesMapping.filter(
                  (v, index) =>
                    !selectedInputVarKeys.includes(
                      v.variableName || index.toString()
                    )
                ),
            },
          });
          setMappingRows([
            ...mappingRows.filter(
              (row) => !selectedInputVarKeys.includes(row.variableKey)
            ),
          ]);
          setSelectedInputVarKeys([]);
        }
      },
    },
  ];

  const scoreMapActions = [
    {
      icon: 'icon-add',
      tooltip: translateActions('add'),
      disabled: disabled,
      onClick: () =>
        setMappingRows([
          ...mappingRows,
          {
            key: uuidv4(),
            variableKey: '',
            variableValueMapScore: {
              scoreValue: 0,
              value: '',
              minValue: null,
              maxValue: null,
              includeMinValue: null,
              includeMaxValue: null,
            },
          },
        ]),
    },
    {
      icon: 'icon-delete',
      tooltip: translateActions('delete'),
      disabled: !selectedMappingKeys.length || disabled,
      onClick: () => {
        setMappingRows(
          mappingRows?.filter((row) => !selectedMappingKeys.includes(row.key))
        );
        setSelectedMappingKeys([]);
      },
    },
  ];

  const uploadExcel = useUploadScoresMappingExcelApi((loadedMappingRows) =>
    setMappingRows((mappingRows || []).concat(loadedMappingRows))
  );

  const onClickSaveButton = () => {
    if (getErrors().length) {
      return setOpenedModal({
        type: ModalTypes.confirmSave,
        contentText: getErrors().join('</br>'),
      });
    }
    saveData();
  };

  const saveData = useCallback(() => {
    if (scorecardData) {
      dispatch(
        updateNodes([
          {
            ...scorecardData,
            properties: {
              ...scorecardData.properties,
              inputVariablesMapping:
                scorecardData.properties.inputVariablesMapping.map((v) => {
                  return {
                    ...v,
                    scoreValues: mappingRows
                      .filter(
                        (row) =>
                          v.variableName === row.variableKey &&
                          row.variableValueMapScore
                      )
                      .map((row) => row.variableValueMapScore),
                  };
                }),
            },
            metaInfo: {
              ...scorecardData.metaInfo,
              blockInnerMeta: JSON.stringify({
                ...innerMeta,
                isBlockValid: !getErrors().length,
                blockErrors: getErrors(),
              }),
            },
          },
        ])
      );

      closeOnSave && onClose();
    }
  }, [scorecardData, mappingRows]);

  const onCloseModal = () => {
    dispatch(clearMappingRows());
    onClose();
  };

  const disableUploadExcel = useMemo(() => {
    return !paramsInputData.some((data) => data.variableName);
  }, [paramsInputData]);

  return !scorecardData ? (
    <Loader />
  ) : (
    <>
      <Modal
        isOn={isVisible}
        modalProps={{
          title: modalProps?.title || '',
          type: modalProps?.type,
          icon: 'icon-calculation_scorecard',
          width: '920px',
          helpKey: 'calc-scorecard-node',
          footer: (
            <ModalFooter
              saveDisabled={!scorecardData?.nodeName || disabled}
              onSave={onClickSaveButton}
              onClose={onCloseModal}
            />
          ),
        }}
        onClose={onCloseModal}
      >
        <div className="calc-scorecard">
          <div className="calc-scorecard__content">
            <div className="top-content calc-scorecard__top-content">
              <div className="calc-scorecard__field calc-scorecard__field--title">
                <Input
                  className="form-field__input"
                  label={translate('blockName')}
                  value={scorecardData?.nodeName}
                  error={invalidData.inputs.nodeName}
                  disabled={disabled}
                  onChange={(e) =>
                    setScorecardData({
                      ...scorecardData,
                      nodeName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="calc-scorecard__field calc-scorecard__field--code">
                <Input
                  label={translate('blockCode')}
                  value={blockCode}
                  disabled
                />
              </div>
            </div>

            <div className="top-content calc-scorecard__top-content">
              <div className="calc-scorecard__field calc-scorecard__field--description">
                <TextArea
                  label={translate('blockDescription')}
                  className="form-field__input"
                  value={scorecardData?.nodeDescription}
                  autoSize={{ maxRows: 3 }}
                  disabled={disabled}
                  onChange={(e) =>
                    setScorecardData({
                      ...scorecardData,
                      nodeDescription: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="calc-scorecard__tables">
              <ModalBox
                title={translate('inputVariables')}
                actions={paramsActions}
                showHeader
              >
                <SimpleTable
                  className="calc-scorecard__table"
                  columnsData={paramsInputColumn}
                  dataSource={paramsInputData}
                  selectedRowKeys={selectedInputVarKeys}
                  setSelectedRowKeys={(keys) => setSelectedInputVarKeys(keys)}
                />
              </ModalBox>
            </div>

            <div className="calc-scorecard__tables">
              <ModalBox title={translate('outputVariable')} showHeader>
                <SimpleTable
                  className="calc-scorecard__table"
                  columnsData={paramsOutputColumn}
                  dataSource={paramsOutputData}
                />
              </ModalBox>
            </div>

            <div className="calc-scorecard__btns-group-left">
              <Button
                kind="normal"
                modifiers={['second', 'usual-text-style', 'hover-box-shadow']}
                onClick={uploadExcel}
                width={250}
                disabled={disableUploadExcel || disabled}
              >
                <Icon name="icon-download_in_excel" color="#0048B2" />
                {translate('uploadExcel')}
              </Button>

              <Button
                kind="normal"
                modifiers={['second', 'usual-text-style', 'hover-box-shadow']}
                width={250}
                disabled={disabled}
              >
                <Link to="/files/Скоркарта.xlsx" target="_blank" download>
                  <Icon name="icon-download_in_excel" color="#0048B2" />
                  {translate('downloadTemplate')}
                </Link>
              </Button>

              {HIDE_FEATURES ? null : (
                <Button
                  kind="normal"
                  modifiers={['second', 'usual-text-style', 'hover-box-shadow']}
                  width={250}
                  disabled={disabled}
                >
                  <Icon name="icon-download_second" color="#0048B2" />
                  {translate('uploadData')}
                </Button>
              )}
            </div>

            <div className="calc-scorecard__tables">
              <ModalBox
                title={translate('mappingVariableScore')}
                actions={scoreMapActions}
                showHeader
              >
                <SimpleTable
                  className="calc-scorecard__table"
                  columnsData={scoreMapColumn}
                  dataSource={mappingRows}
                  selectedRowKeys={selectedMappingKeys}
                  setSelectedRowKeys={setSelectedMappingKeys}
                />
              </ModalBox>
            </div>
          </div>
        </div>
      </Modal>

      {openedModal?.type === ModalTypes.scorecardAddElem && (
        <AddElementModal
          modalProps={{
            type: openedModal.type,
            title: openedModal.title,
          }}
          inputVariables={scorecardData.inputVariables}
          addedVariables={addedVariables}
          allowedTypes={
            openedModal.isOutput
              ? [
                  { typeId: '0', isArray: false },
                  { typeId: '1', isArray: false },
                ]
              : undefined
          }
          disabledKeys={scorecardData.properties.inputVariablesMapping
            .filter((inVar) => inVar.variableName !== openedModal.variableName)
            .map((inVar) => inVar.variableName)
            .concat(
              openedModal.isOutput
                ? scorecardData.properties.inputVariablesMapping.map(
                    (inVar) => inVar.variableName
                  )
                : scorecardData.properties.outputVariable.variableName
            )}
          selectedKeys={[openedModal.variableName]}
          onClose={() => {
            setOpenedModal(null);
            dispatch(removeOpenModal({}));
          }}
          onSave={(variable) => {
            setScorecardData({
              ...scorecardData,
              properties: {
                ...scorecardData.properties,
                ...(!openedModal.isOutput
                  ? {
                      inputVariablesMapping:
                        scorecardData.properties.inputVariablesMapping.map(
                          (v, index) => {
                            if (openedModal.index !== index) return v;
                            return {
                              defaultValue: v.defaultValue,
                              scoreValues: v.scoreValues,
                              ...parseVariable(variable),
                            };
                          }
                        ),
                    }
                  : {
                      outputVariable: {
                        defaultValue:
                          scorecardData.properties.outputVariable.defaultValue,
                        ...parseVariable(variable),
                      },
                    }),
              },
            });
          }}
          onAddLocalVariable={(data, rootId) => {
            setAddedVariables(onAddLocalVariable(data, rootId, addedVariables));
          }}
          hideAddNewBtn={!openedModal.isOutput}
          disableComplex
          disableArrays
          closeOnSave
        />
      )}

      {openedModal?.type === ModalTypes.scorecardInputVal && (
        <InputValueModal
          modalProps={{
            type: openedModal?.type,
            title: '',
          }}
          data={openedModal.value}
          disabledRange={openedModal.typeId === '2'}
          onSave={(value) => {
            if (!value) return;
            setMappingRows(
              mappingRows?.map((mappingRow) => {
                if (mappingRow.key !== openedModal.key) {
                  return mappingRow;
                }
                return 'value' in value
                  ? {
                      ...mappingRow,
                      variableValueMapScore: {
                        scoreValue: mappingRow.variableValueMapScore.scoreValue,
                        value: value.value,
                        minValue: null,
                        maxValue: null,
                        includeMinValue: null,
                        includeMaxValue: null,
                      },
                    }
                  : {
                      ...mappingRow,
                      variableValueMapScore: {
                        scoreValue: mappingRow.variableValueMapScore.scoreValue,
                        value: null,
                        minValue: value.from,
                        maxValue: value.to,
                        includeMinValue: value.includeFrom,
                        includeMaxValue: value.includeTo,
                      },
                    };
              })
            );
          }}
          onClose={() => {
            setOpenedModal(null);
            dispatch(removeOpenModal({}));
          }}
          closeOnSave
        />
      )}

      {openedModal?.type === ModalTypes.confirmSave && (
        <ConfirmInvalidBlockSaveModal
          modalProps={openedModal}
          onOk={saveData}
          onCancel={onClose}
          onClose={() => setOpenedModal(null)}
        />
      )}
    </>
  );
};
