import { Key } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { concat, filter } from 'lodash';
import { IEnvironmentDataItem, IEnvironmentsState } from './types/interface';
import { ENVIRONMENTS_FEATURE_KEY } from './constants';

const initialState: IEnvironmentsState = {
  data: [
    {
      key: 'DEV',
      name: 'DEV',
      integrationUrl: 'host_spr_dev',
      kafkaUrl: 'вмприыи',
      streamingPlatformUrl: 'ыпмыпиыи53',
      defaulEnvironment: true,
    },
    {
      key: 'TEST',
      name: 'TEST',
      integrationUrl: 'host_spr__test',
      kafkaUrl: 'вмприыи',
      streamingPlatformUrl: '15352енп2',
      defaulEnvironment: false,
    },
    {
      key: 'PROD',
      name: 'PROD',
      integrationUrl: 'host_spr_prod',
      kafkaUrl: 'вмприыи',
      streamingPlatformUrl: '4552525',
      defaulEnvironment: false,
    },
  ],
  selectedRowKeys: [],
  search: '',
  isLoading: false,
};

const environmentsSlice = createSlice({
  name: ENVIRONMENTS_FEATURE_KEY,
  initialState,
  reducers: {
    addEnvironment(state, { payload }: PayloadAction<IEnvironmentDataItem>) {
      state.data = concat(state.data, payload);
    },
    deleteEnvironments(state, { payload }: PayloadAction<Key[]>) {
      state.data = filter(state.data, (env) => !payload.includes(env.key));
      state.selectedRowKeys = [];
    },
    setSelectedRowKeys(state, { payload }: PayloadAction<Key[]>) {
      state.selectedRowKeys = payload;
    },
  },
});

const { reducer, actions } = environmentsSlice;
export const environmentsActions = { ...actions };
export { reducer as environmentsReducer };
