import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import AggregateVersions from './Tabs/Versions/AggregateVersions';
import { Settings } from './Tabs/Settings/Settings';
import { DiagramsWithAggregate } from './Tabs/DiagramsWithAggregate/DiagramsWithAggregate';
import {
  AGGREGATES_CORE_PATH,
  AGGREGATE_PATH,
  AGGREGATE_SETTINGS_PATH,
  AGGREGATE_VERSIONS_PATH,
  DIAGRAMS_WITH_AGGREGATE_PATH,
} from '../../routing/aggregatesConstants';

const AggregateNavigation = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route
        exact
        path={AGGREGATE_PATH}
        render={(props) => {
          return (
            <Redirect
              to={{
                pathname: `${AGGREGATES_CORE_PATH}/${props.match.params.aggregate}/settings`,
                state: history.location.state,
              }}
            />
          );
        }}
      />
      <Route exact path={AGGREGATE_SETTINGS_PATH} component={Settings} />
      <Route
        exact
        path={DIAGRAMS_WITH_AGGREGATE_PATH}
        component={DiagramsWithAggregate}
      />
      <Route
        exact
        path={AGGREGATE_VERSIONS_PATH}
        component={AggregateVersions}
      />
    </Switch>
  );
};

export { AggregateNavigation };
