import { ITabPanelItem } from '@shared';

export const tabs: ITabPanelItem[] = [
  {
    key: '0',
    text: 'Настройки',
    translation: 'development.aggregates.tabs.settings',
    path: '/settings',
  },
  {
    key: '1',
    text: 'Диаграммы, в которых вызывается агрегат',
    translation: 'development.aggregates.tabs.diagramsWithAggregate',
    path: '/diagrams-with-aggregate',
  },
  {
    key: '2',
    text: 'Версии',
    translation: 'development.aggregates.tabs.versions',
    path: '/versions',
  },
];
