import React from 'react';
import { DataSourcePanel } from '@modules/connections/components/connectionsModalDataSource/components';
import { useTranslationPath } from '@modules/languageProvider';
import { tranlationPath } from '@modules/administration/components/ExternalServiceCallSettingsModal/data';
import { TextArea } from '@shared';
import { Form } from 'antd';
import { ConnectionsDataSourceModalFields } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';

export const ExternalServiceTextEditor: React.FC = () => {
  const translate = useTranslationPath(tranlationPath);
  return (
    <DataSourcePanel
      title={translate('body')}
      panel={{ key: 'external-service-accordion-3' }}
    >
      <Form.Item name={ConnectionsDataSourceModalFields.body}>
        <TextArea className="connections__text-editor" />
      </Form.Item>
    </DataSourcePanel>
  );
};
