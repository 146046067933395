import React, { useEffect, useRef } from 'react';
import { Form } from 'antd';
import { ConnectionsTableData } from '@modules/connections/types/connectionsTypes';
import { Input } from '@shared';
import { useContext } from 'react';
import { ConnectionsTableFormContext } from '@modules/connections/components';
import './ConnectionsEditableCell.scss';

interface IConnectionsEditableCell {
  record: Pick<ConnectionsTableData, 'connectionId' | 'isEdit'>;
}

export const ConnectionsEditableCell: React.FC<IConnectionsEditableCell> = ({
  record,
  children,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { saveName } = useContext(ConnectionsTableFormContext);
  const { connectionId, isEdit } = record;

  useEffect(() => {
    inputRef.current && inputRef.current?.focus();
  }, [inputRef]);

  return isEdit ? (
    <Form.Item
      name={connectionId}
      className="connections__edit-cell"
      rules={[
        {
          required: true,
          message: 'Поле не может быть пустым',
        },
      ]}
    >
      <Input
        size="small"
        ref={inputRef}
        onBlur={saveName}
        onPressEnter={saveName}
      />
    </Form.Item>
  ) : (
    (children as JSX.Element)
  );
};
