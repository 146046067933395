import {
  ConnectionsTableData,
  TConnectionType,
} from '@modules/connections/types/connectionsTypes';
import {
  allConnectionsColumns,
  databasesColumns,
  outServicesColumns,
  messageBrokersColumns,
} from '@modules/connections/components/connectionsTable/rawColumns';
import React, { useMemo } from 'react';
import {
  useColumnSetup,
  ConfigureTableSetting,
} from '@modules/connections/hooks';
import { Icon } from '@modules/icon';
import {
  ConnectionsContextMenu,
  ConnectionsEditableCell,
  ConnectionsMoreWrap,
} from '@modules/connections/components';
import {
  ALL_CONNECTIONS,
  DBMS,
  EXTERNAL_SERVICES,
  MESSAGE_BROKERS,
} from '../constants';

const columnsOptions: Record<TConnectionType, any[]> = {
  [ALL_CONNECTIONS]: allConnectionsColumns,
  [DBMS]: databasesColumns,
  [MESSAGE_BROKERS]: messageBrokersColumns,
  [EXTERNAL_SERVICES]: outServicesColumns,
};

export const useConnectionsTable = (
  connectionType: TConnectionType,
  rawData: ConnectionsTableData[]
) => {
  const commonRender = (value: any, record: ConnectionsTableData) => {
    return (
      <ConnectionsContextMenu data={record}>{value}</ConnectionsContextMenu>
    );
  };

  const colSettings = useMemo<
    ConfigureTableSetting<ConnectionsTableData>
  >(() => {
    const commonColSettings: ConfigureTableSetting<ConnectionsTableData> = {
      name: {
        render: (value: string, record: ConnectionsTableData) => {
          const { isEdit, connectionId, icon } = record;
          if (icon) {
            return (
              <>
                {icon && <Icon name={icon} />} {value}
              </>
            );
          }

          if (isEdit) {
            return {
              props: {
                className: 'connections__edit-wrap',
              },
              children: (
                <ConnectionsEditableCell record={{ isEdit, connectionId }}>
                  {value}
                </ConnectionsEditableCell>
              ),
            };
          }

          return (
            <ConnectionsContextMenu data={record}>
              {value}
            </ConnectionsContextMenu>
          );
        },
      },
      lastChangeByUser: {
        render: (value: string, record: ConnectionsTableData) => {
          return (
            <ConnectionsContextMenu data={record}>
              <span className="connections__cell_blu">{value}</span>
            </ConnectionsContextMenu>
          );
        },
      },
      connectionStatus: {
        render: (value: string, record: ConnectionsTableData) => {
          let cellClass = '';
          // TODO: узнать коды из бэка
          switch (value) {
            case 'SUCCESS':
              cellClass = 'connections__cell_green';
              break;
            case 'TEST_FAILED':
              cellClass = 'connections__cell_yellow';
              break;
            case 'NOT_FILLED':
              cellClass = 'connections__cell_red';
              break;
          }
          return (
            <ConnectionsContextMenu data={record}>
              <span className={cellClass}>{value}</span>
            </ConnectionsContextMenu>
          );
        },
      },
    };
    const dynamicConnectionsTypeSettings: Partial<
      Record<TConnectionType, ConfigureTableSetting<ConnectionsTableData>>
    > = {
      [MESSAGE_BROKERS]: {
        bootstrapServers: {
          render: (value: string) => {
            return <ConnectionsMoreWrap text={value} initialHeight={17} />;
          },
        },
      },
    };

    return {
      ...commonColSettings,
      ...(dynamicConnectionsTypeSettings[connectionType] ?? {}),
    } as ConfigureTableSetting<ConnectionsTableData>;
  }, [connectionType, rawData]);

  const columns = useColumnSetup<ConnectionsTableData>({
    rawColumns: columnsOptions[connectionType] ?? [],
    configureSettings: colSettings,
    render: commonRender,
  });

  return {
    columns,
  };
};
