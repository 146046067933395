import { Middleware } from '@reduxjs/toolkit';
import {
  getAggregates,
  getCustomCode,
  getCustomCodes,
} from '@modules/development/DevelopmentSlice';
import {
  getCustomCodes as getCustomCodesHelper,
  getCustomCode as getCustomCodeHelper,
} from './customCode';
import {
  addComplexTypeAttributes as getAddComplexTypeAttributesHelper,
  refreshComplexTypes as getRefreshComplexTypesHelper,
} from './complexType';
import {
  addComplexType,
  addComplexTypeAttributes,
  manageChangedComplexType,
} from '@modules/complexType';
import { getAggregates as getAggregatesHelper } from './aggregates';

const apiResponseMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type == getCustomCodes.fulfilled) {
    getCustomCodesHelper(store, action);
  } else if (action.type == getCustomCode.fulfilled) {
    getCustomCodeHelper(store, action);
  } else if (action.type == getAggregates.fulfilled) {
    getAggregatesHelper(store, action);
  } else if (action.type == addComplexTypeAttributes.fulfilled) {
    getAddComplexTypeAttributesHelper(store, action);
  } else if (
    action.type == manageChangedComplexType.fulfilled ||
    action.type == manageChangedComplexType.rejected ||
    action.type == addComplexType.fulfilled
  ) {
    getRefreshComplexTypesHelper(store);
  }
  return next(action);
};

export { apiResponseMiddleware };
