import { Icon } from '@modules/icon';
import { Button } from '@modules/shared';
import { useUserGuide } from '@modules/userGuide';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './homePage.scss';

export const HomePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [getLink] = useUserGuide();
  return (
    <div className="home">
      <div className="home__content">
        <div className="home__content-left">
          <div className="home__content-icon">
            <Icon className="home-icon" size="32" name="icon-assignment" />
          </div>
          {t('homePage.text')}
        </div>
        <div className="home__content-right">
          <Button
            className="home-button"
            kind="default"
            onClick={() => history.push('development/schemas')}
          >
            {t('homePage.createSchemaButton')}
          </Button>
          <Button
            className="home-button"
            style={{ marginLeft: '24px' }}
            kind="default"
            onClick={() => history.push('development/streams')}
          >
            {t('homePage.createStreamButton')}
          </Button>
        </div>
      </div>
    </div>
  );
};
