/* eslint-disable @typescript-eslint/no-unused-vars */
import { findCurrentDiagram } from '../../../../utils';
import { IDevelopmentState } from '../../../../DevelopmentSlice';
import {
  DiagramLinks,
  DiagramNodes,
  IDiagramLink,
  IDiagramNode,
  IDiagramNodeBase,
} from '../../../../types';
import { IAction } from '@modules/store';
import { filterLinks } from '../../utils';

const reducer = {
  // Nodes
  addDiagramNodes(
    state: IDevelopmentState,
    action: IAction<{ nodes: { nodeId: string; node: IDiagramNodeBase }[] }>
  ) {
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram) {
      action.payload.nodes.forEach(({ nodeId, node }) => {
        currentDiagram.nodes = {
          ...currentDiagram.nodes,
          [nodeId]: { ...node, nodeId },
        };
      });
      currentDiagram.isSaved = false;
    }
  },
  updateDiagramNodes(
    state: IDevelopmentState,
    action: IAction<{ nodes: { id: string; data: IDiagramNode }[] }>
  ) {
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram) {
      action.payload.nodes.forEach(({ id, data }) => {
        currentDiagram.nodes = { ...currentDiagram.nodes, [id]: data };
      });
      currentDiagram.isSaved = false;
    }
  },
  deleteDiagramNodes(
    state: IDevelopmentState,
    action: IAction<{ ids: string[]; fromHistory?: boolean }>
  ) {
    const { ids, fromHistory } = action.payload;
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram && currentDiagram.nodes) {
      const newNodes: DiagramNodes = {};
      const deletedNodes: IDiagramNode[] = [];
      const deletedLinksIds: string[] = [];

      if (currentDiagram.links) {
        const newLinks: DiagramLinks = {};

        Object.entries(currentDiagram.links).forEach(([linkId, linkData]) => {
          if (
            ids.includes(linkData.nextNodeId) ||
            ids.includes(linkData.prevNodeId)
          ) {
            return deletedLinksIds.push(linkId);
          }

          newLinks[linkId] = { ...linkData };
        });

        currentDiagram.links = newLinks;
      }

      Object.entries(currentDiagram.nodes).forEach(([nodeId, nodeData]) => {
        if (ids.includes(nodeId)) {
          deletedNodes.push({
            ...nodeData,
            inputLinks: filterLinks(nodeData.inputLinks, deletedLinksIds),
            outputLinks: filterLinks(nodeData.outputLinks, deletedLinksIds),
          });
          return;
        }
        newNodes[nodeId] = {
          ...nodeData,
          inputLinks: filterLinks(nodeData.inputLinks, deletedLinksIds),
          outputLinks: filterLinks(nodeData.outputLinks, deletedLinksIds),
        };
      });

      !fromHistory &&
        currentDiagram.historyChanges.push({
          type: 'add',
          nodes: deletedNodes,
        });

      currentDiagram.nodes = newNodes;
      currentDiagram.isSaved = false;
    }
  },
  // Links
  addDiagramLink(state: IDevelopmentState, action: IAction<IDiagramLink>) {
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram && currentDiagram.nodes) {
      const newLink: DiagramLinks = { [action.payload.linkId]: action.payload };

      currentDiagram.links = { ...currentDiagram.links, ...newLink };
      currentDiagram.nodes[action.payload.prevNodeId].outputLinks = [
        ...(currentDiagram.nodes[action.payload.prevNodeId].outputLinks || []),
        action.payload.linkId,
      ];
      currentDiagram.nodes[action.payload.nextNodeId].inputLinks = [
        ...(currentDiagram.nodes[action.payload.nextNodeId].inputLinks || []),
        action.payload.linkId,
      ];
      currentDiagram.isSaved = false;
    }
  },

  deleteDiagramLink(state: IDevelopmentState, action: IAction<{ id: string }>) {
    const { id } = action.payload;
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram && currentDiagram.links && currentDiagram.nodes) {
      const newLinks: DiagramLinks = {};
      const newNodes: DiagramNodes = {};

      Object.entries(currentDiagram.links).forEach(([linkId, linkData]) => {
        if (linkId.includes(id)) return;
        newLinks[linkId] = { ...linkData };
      });

      Object.entries(currentDiagram.nodes).forEach(([nodeId, nodeData]) => {
        const newLinksIds = Object.keys(newLinks);

        const inputLinks = nodeData.inputLinks?.filter((inputLink) =>
          newLinksIds.includes(inputLink)
        );

        const outputLinks = nodeData.outputLinks?.filter((outputLink) =>
          newLinksIds.includes(outputLink)
        );

        newNodes[nodeId] = { ...nodeData, inputLinks, outputLinks };
      });

      currentDiagram.links = newLinks;
      currentDiagram.nodes = newNodes;
      currentDiagram.isSaved = false;
    }
  },

  // History
  nodeUpdateHistory(state: IDevelopmentState, action: IAction) {
    const currentDiagram = findCurrentDiagram(state);
    currentDiagram?.historyChanges.push({
      type: 'update',
      nodes: action.payload,
    });
  },

  nodeAddHistory(state: IDevelopmentState, action: IAction) {
    const currentDiagram = findCurrentDiagram(state);
    currentDiagram?.historyChanges.push({
      type: 'delete',
      nodes: action.payload,
    });
  },

  nodeAddLinkHistory(state: IDevelopmentState, action: IAction) {
    const currentDiagram = findCurrentDiagram(state);
    currentDiagram?.historyChanges.push({
      type: 'deleteLink',
      nodes: action.payload,
      link: action.payload,
    });
  },

  nodeDeleteLinkHistory(state: IDevelopmentState, action: IAction) {
    const currentDiagram = findCurrentDiagram(state);
    currentDiagram?.historyChanges.push({
      type: 'addLink',
      nodes: action.payload,
      link: action.payload,
    });
  },

  removeHistoryElement(state: IDevelopmentState, action: IAction) {
    const currentDiagram = findCurrentDiagram(state);
    const history = currentDiagram?.historyChanges;

    if (action.payload.ids && history) {
      const ids = action.payload.ids;
      history.pop();
      history.forEach((item: any) => {
        Object.entries(ids).forEach(([oldId, newId]) => {
          item.nodes.forEach((node: any) => {
            if (node.nodeId === oldId) {
              node.nodeId = newId;
            } else if (node === oldId) {
              node = newId;
            }
          });
        });
      });
    } else {
      history && history.pop();
    }
    if (currentDiagram && history) {
      currentDiagram.historyChanges = [...history];
    }
  },

  // Group
  addDiagramGroup(state: IDevelopmentState, action: IAction) {
    // const nodes = action.payload.data;
    // const coordinates = action.payload.coordinates;
    // const currentObject = findCurrentDiagram(state);
    // const uuid4 = action.payload.newId || uuidv4();
    // const parentNodes = nodes.filter((node: IDiagramNode) => node.isParent);
    // const childNodes = nodes.filter((node: IDiagramNode) => !node.isParent);
    // const parentNodesIds = parentNodes.map((parent: IDiagramNode) => parent.id);
    // if (parentNodesIds.length && currentObject && currentObject.diagramJson) {
    //   const newNodes: { [key: string]: IDiagramNodeBase } = {};
    //   Object.entries(currentObject.diagramJson.nodes).forEach(
    //     ([id, nodeData]) => {
    //       if (parentNodesIds.includes(id)) return;
    //       newNodes[id] = {
    //         ...nodeData,
    //       };
    //     }
    //   );
    //   currentObject.diagramJson.historyChanges = [
    //     ...(currentObject.diagramJson.historyChanges || []),
    //     currentObject.diagramJson.nodes,
    //   ];
    //   currentObject.diagramJson.nodes = newNodes;
    // }
    // const parentNode = {
    //   [uuid4 as string]: {
    //     nodeName: action.payload.title,
    //     nodeType: DiagramNodeTypes.Group,
    //     nodeDescription: '',
    //     id: uuid4,
    //     data: {},
    //     meta: {
    //       position: { x: coordinates.left, y: coordinates.top },
    //       type: 'group',
    //       links: [],
    //       shape: {
    //         x: coordinates.width + 100,
    //         y: coordinates.height + 100,
    //       },
    //     },
    //     properties: {},
    //     link: [],
    //   },
    // };
    // const newChildrens = childNodes.map((child: any) => {
    //   const parent = parentNodes.find(
    //     (parent: IDiagramNode) => parent.id === child.parentNode
    //   );
    //   if (parent) {
    //     return {
    //       [child.id as string]: {
    //         ...child,
    //         meta: {
    //           ...child.meta,
    //           extent: 'parent',
    //           parentNode: uuid4,
    //           position: {
    //             x:
    //               child.meta.position.x +
    //               parent.meta.position.x -
    //               coordinates.left,
    //             y:
    //               child.meta.position.y +
    //               parent.meta.position.y -
    //               coordinates.top,
    //           },
    //         },
    //         selected: false,
    //       },
    //     };
    //   } else {
    //     return {
    //       [child.id as string]: {
    //         ...child,
    //         meta: {
    //           ...child.meta,
    //           extent: 'parent',
    //           parentNode: uuid4,
    //           position: {
    //             x: child.meta.position.x - coordinates.left,
    //             y: child.meta.position.y - coordinates.top,
    //           },
    //         },
    //         selected: false,
    //       },
    //     };
    //   }
    // });
    // if (currentObject && currentObject.diagramJson) {
    //   // @ts-ignore
    //   currentObject.diagramJson.nodes = {
    //     ...currentObject?.diagramJson?.nodes,
    //     ...parentNode,
    //   };
    // }
    // newChildrens.forEach((children: any) => {
    //   if (currentObject && currentObject.diagramJson) {
    //     currentObject.diagramJson.nodes = {
    //       ...currentObject?.diagramJson?.nodes,
    //       ...children,
    //     };
    //   }
    // });
  },
  removeDiagramGroup(state: IDevelopmentState, action: IAction) {
    // const currentObject = findCurrentDiagram(state);
    // const parentNode = action.payload.find(
    //   (node: IDiagramNode) => node.isParent
    // );
    // const childNodes = action.payload.filter(
    //   (node: IDiagramNode) => !node.isParent
    // );
    // const ids = parentNode.id;
    // if (currentObject && currentObject.diagramJson) {
    //   const newNodes: { [key: string]: IDiagramNodeBase } = {};
    //   Object.entries(currentObject.diagramJson.nodes).forEach(
    //     ([id, nodeData]) => {
    //       if (ids.includes(id)) return;
    //       newNodes[id] = {
    //         ...nodeData,
    //       };
    //     }
    //   );
    //   currentObject.diagramJson.historyChanges = [
    //     ...(currentObject.diagramJson.historyChanges || []),
    //     currentObject.diagramJson.nodes,
    //   ];
    //   currentObject.diagramJson.nodes = newNodes;
    // }
    // const newChildrens = childNodes.map((child: any) => {
    //   return {
    //     [child.id as string]: {
    //       ...child,
    //       meta: {
    //         ...child.meta,
    //         position: {
    //           x: child.meta.position.x + parentNode.meta.position.x,
    //           y: child.meta.position.y + parentNode.meta.position.y,
    //         },
    //         extent: '',
    //         parentNode: '',
    //       },
    //       selected: false,
    //     },
    //   };
    // });
    // newChildrens.forEach((children: any) => {
    //   if (currentObject && currentObject.diagramJson) {
    //     currentObject.diagramJson.nodes = {
    //       ...currentObject?.diagramJson?.nodes,
    //       ...children,
    //     };
    //   }
    // });
  },
};

export default reducer;
