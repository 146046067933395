export const DEVELOPMENT_CORE_PATH = '/development';
export const DIAGRAMS_PATH = `${DEVELOPMENT_CORE_PATH}/diagrams`;
export const CUSTOM_CODES_PATH = `${DEVELOPMENT_CORE_PATH}/custom-code-list`;
export const CUSTOM_DATA_TYPES_PATH = `${DEVELOPMENT_CORE_PATH}/custom-data-types`;
export const AGGREGATES_PATH = `${DEVELOPMENT_CORE_PATH}/aggregates`;
export const STREAMS = 'streams';
export const STREAMS_PATH = `${DEVELOPMENT_CORE_PATH}/${STREAMS}`;
export const SCHEMAS_PATH = `${DEVELOPMENT_CORE_PATH}/schemas`;
// export const COMMUNICATION_CHANNELS_PATH = `${DEVELOPMENT_CORE_PATH}/communication-channels`;
export const NOT_FOUND_PATH = '/not-found';
