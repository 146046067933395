import { apiBase } from '@modules/apiBase';
import { IDataType, IFunction, IRuleType } from '../types/interface';

const REFERENCES_ENDPOINT = 'references';

export const referencesApi = {
  getDataTypes: async () => {
    const data = await apiBase.get<{ [key: string]: IDataType }>(
      `${REFERENCES_ENDPOINT}/datatype`
    );
    if (data) return Object.entries(data).map(([, dataType]) => dataType);
  },
  getFunctions: async () => {
    const data = await apiBase.get<{ [key: string]: IFunction }>(
      `${REFERENCES_ENDPOINT}/functions`
    );
    if (data) return Object.entries(data).map(([, func]) => func);
  },
  getRuleType: async () => {
    const data = await apiBase.get<{ [key: string]: IRuleType }>(
      `${REFERENCES_ENDPOINT}/ruletype`
    );
    if (data) return Object.entries(data).map(([, func]) => func);
  },
};
