import { ITest, ITestUpdate } from '../../../../types';
import { IAction } from '@modules/store';
import { IDevelopmentState } from '@modules/development/DevelopmentSlice';
import { Key } from 'react';
import { findCurrentDiagram } from '../../../../utils';

const diagramTestingReducers = {
  setDiagramTests: (
    state: IDevelopmentState,
    action: IAction<{ data: ITest[] }>
  ) => {
    const { data } = action.payload;
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram) {
      currentDiagram.testing = { ...currentDiagram.testing, data };
    }
  },
  addDiagramTest: (
    state: IDevelopmentState,
    action: IAction<{ testBody: ITestUpdate }>
  ) => {
    const { testBody } = action.payload;
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram) {
      currentDiagram.testing = {
        ...currentDiagram.testing,
        data: currentDiagram.testing?.data?.concat(testBody),
      };
    }
  },
  updateDiagramTest: (
    state: IDevelopmentState,
    action: IAction<{ testBody: Omit<ITestUpdate, 'diagramId'> }>
  ) => {
    const { testBody } = action.payload;
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram && testBody.testId) {
      if (currentDiagram) {
        currentDiagram.testing = {
          ...currentDiagram.testing,
          data: currentDiagram.testing?.data?.some(
            (test) => test.testId === testBody.testId
          )
            ? currentDiagram.testing?.data?.map((test) => {
                if (test.testId === testBody.testId) return testBody;
                return test;
              })
            : currentDiagram.testing?.data?.concat(testBody),
        };
      }
    }
  },
  setSelectedRowKeysDiagramTesting(state: any, action: IAction<Key[]>) {
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram) {
      currentDiagram.testing = {
        ...currentDiagram.testing,
        selectedRowKeys: action.payload,
      };
    }
  },
};

export default diagramTestingReducers;
