import { IAggregate } from '@modules/development';
import { IAction } from '@modules/store';
import { IDevelopmentState } from '@modules/development/DevelopmentSlice';

const reducers = {
  aggregatesSeeds(state: IDevelopmentState, action: IAction) {
    const { aggregates } = action.payload;
    state.aggregates.data = aggregates;
  },
  addAgregate(state: IDevelopmentState, action: IAction) {
    const { key } = action.payload;

    const newAggregate: IAggregate = {
      aggregateId: '',
      versionId: '',
      aggregateName: '',
      aggregateDescription: '',
      aggregateJson: {
        aggregateName: '',
        aggregateVariableType: '',
        aggregateFunction: '',
        groupingElement: '',
      },

      key: key,
      type: 'aggregate',
      draft: true,
      isSaved: false,
      isReadonly: false,
    };
    state.openObjects.push(newAggregate);
    state.currentObject = {
      title: newAggregate.aggregateName,
      key: key,
      readonly: false,
      objectInfo: JSON.stringify(newAggregate),
    };
    state.openTabs.push({
      key: key,
      version: key,
      text: '',
      type: 'aggregate',
      icon: 'icon-aggregate',
    });
  },
  removeAgregate(state: IDevelopmentState, action: IAction<{ key: string }>) {
    const { key } = action.payload;
    state.aggregates.data = state.aggregates.data.filter(
      (item: { key: string }) => item.key !== key
    );
  },
  onSelectedAggregatesRow(state: IDevelopmentState, action: IAction) {
    const { keys } = action.payload;
    state.aggregates.selectedRowTable = keys;
  },
  onChangeAggregatesSearch(state: IDevelopmentState, action: IAction) {
    const { search } = action.payload;
    state.aggregates.search = search;
  },
};

export default reducers;
