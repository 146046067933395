import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { customCodeSeeds } from '@modules/development/DevelopmentSlice';

const getCustomCodes = (
  store: MiddlewareAPI<Dispatch<AnyAction>, any>,
  action: any
) => {
  const customCodes = action.payload.map((customCode: any) => ({
    key: customCode.versionId,
    title: customCode.scriptName,
    description: customCode.description,
    code: customCode.scriptId,
    dateOfChange: customCode.changeDt,
    dateOfCreate: customCode.createDt,
    user: customCode.lastChangeByUser,
    createUser: customCode.createByUser,
    versionId: customCode.versionId,
    scriptId: customCode.scriptId,
    scriptJson: JSON.parse(customCode.scriptJson),
    variables: customCode.variables,
    location: '/CustomCode/',
    state: 'IN_DEVELOPMENT',
    type: 'customCode',
    draft: false,
  }));
  store.dispatch(customCodeSeeds({ customCodes }));
};

const getCustomCode = (
  store: MiddlewareAPI<Dispatch<AnyAction>, any>,
  action: any
) => {
  const customCode = {
    key: action.payload.versionId,
    title: action.payload.scriptName,
    // description: action.pyload.description,
    code: action.payload.scriptId,
    dateOfChange: action.payload.changeDt,
    dateOfCreate: action.payload.createDt,
    user: action.payload.lastChangeByUser,
    createUser: action.payload.createByUser,
    versionId: action.payload.versionId,
    versionName: action.payload.versionName,
    scriptId: action.payload.scriptId,
    scriptJson: JSON.parse(action.payload.scriptJson),
    variables: action.payload.variables,
    location: '/CustomCode/',
    state: 'IN_DEVELOPMENT',
    type: 'customCode',
    draft: false,
  };
  store.dispatch(customCodeSeeds({ customCodes: [customCode] }));
};

export { getCustomCodes, getCustomCode };
