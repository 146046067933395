import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addOpenTab,
  cancelAddSchema,
  onSelectedSchemaRows,
  selectOpenTabs,
  updateOpenTab,
} from '@modules/development/DevelopmentSlice';
import { selectAddNewRequested, selectSchemasSelectedRows } from './reducers';
import { SchemasTable } from './components/ComplexTypes/SchemasTable';
import { SCHEMA_OBJTYPE } from '@modules/development';

export const Schemas: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openedTabs = useSelector(selectOpenTabs);
  const addNewRequested = useSelector(selectAddNewRequested);
  const selectedRowKeys = useSelector(selectSchemasSelectedRows);

  useEffect(() => {
    const key = SCHEMA_OBJTYPE;
    const tab = {
      key,
      text: 'Схемы',
      type: SCHEMA_OBJTYPE,
    };
    dispatch(
      openedTabs.every((ot) => ot.key !== key)
        ? addOpenTab({ tab })
        : updateOpenTab({ key, tab })
    );
  }, [t]);

  return (
    <>
      <SchemasTable
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={(keys) => {
          dispatch(onSelectedSchemaRows({ keys }));
        }}
        addNewRequested={addNewRequested}
        onCancelAdd={() => dispatch(cancelAddSchema())}
        // search={search}
      />
    </>
  );
};
