import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IDiagram } from '@modules/development';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { getDataTypes } from '@modules/references';
import { getServices, useRolesHelper } from '@modules/services';
import {
  addOpenObject,
  addOpenTab,
  changeSaveStatusObject,
  diagram as selectDiagram,
  openDiagram,
  openDiagramToView,
  openObjects,
  selectCurrentDiagram,
  selectCurrentObjectReadonly,
  selectIsSavedObject,
  selectOpenTabs,
  setDiagramKey,
  setDiagramTitle,
  updateOpenTab,
} from '@modules/development/DevelopmentSlice';
import { useTranslationPath } from '@modules/languageProvider';
import { STREAMS_CORE_PATH } from '../../routing/streamsConstants';
import { useDiagramHasChanged } from '../../hooks/useHasChanged';

type UseDiagramDataProps = {
  diagram?: IDiagram;
  readonly?: boolean;
  versionName?: string;
};

export const useStreamData = (props: UseDiagramDataProps) => {
  const dispatch = useAppDispatch();
  const translate = useTranslationPath('development.diagram');
  const history = useHistory();

  const currentDiagram = useAppSelector(selectDiagram);
  const openTabs = useAppSelector(selectOpenTabs);
  const openAllObjects = useAppSelector(openObjects) as IDiagram[];
  const currentObjectAllInfo = useAppSelector(selectCurrentDiagram);
  const currentDiagramReadonly = useAppSelector(selectCurrentObjectReadonly);
  const isObjectSaved = useAppSelector(selectIsSavedObject);

  const [currentObject, setCurrentObject] = useState<IDiagram>();

  const diagramKey = history.location.pathname.split('/')[3];
  const pathNameTab = history.location.pathname.split('/')[4];

  const hasChangedDataDiagram = useDiagramHasChanged(currentObjectAllInfo);
  const { rolesList, hasRoles } = useRolesHelper();

  const getDiagramRequest = async (key: string) => {
    let response;
    if (props?.diagram) {
      response = props?.diagram;
    } else if (props?.readonly) {
      response = await dispatch(openDiagramToView(key, props?.versionName));
    } else {
      response = await dispatch(openDiagram(key));
    }

    let responseDiagram: IDiagram;
    if (!response) {
      responseDiagram = openAllObjects.filter((item) => item.key == key)[0];
      if (responseDiagram) {
        setCurrentObject(responseDiagram);
      } else {
        history.push('/not-found');
        return;
      }
    } else {
      responseDiagram = {
        ...response,
        key: key,
        testing: { data: [], loading: false },
      };
      setCurrentObject(responseDiagram);
    }

    const title = [props?.versionName || '', responseDiagram.diagramName].join(
      ' '
    );

    // если в табах нет этого объекта, то он добавляется в табы и в список открытых объектов
    if (!openTabs.find((item) => item.key === responseDiagram.key)) {
      if (history.location.hash.length) {
        dispatch(
          updateOpenTab({
            key: history.location.hash.slice(1),
            tab: {
              key: responseDiagram.key,
              text: title,
              type: 'diagram',
              icon: 'icon-diagram',
            },
          })
        );
        history.push(
          `${STREAMS_CORE_PATH}/${responseDiagram.diagramId}/${
            history.location.pathname.split('/')[4]
          }`
        );
      } else {
        dispatch(
          addOpenTab({
            tab: {
              key: responseDiagram.key,
              version: responseDiagram.version,
              text: title,
              type: 'diagram',
              icon: 'icon-diagram',
              state: {
                diagram: props?.diagram,
                readonly: props?.readonly,
                versionName: props?.versionName,
              },
            },
          })
        );
      }

      dispatch(addOpenObject({ object: { ...responseDiagram, key } }));
    }
    dispatch(setDiagramKey({ key }));
    dispatch(setDiagramTitle({ title: title || ' ' }));
  };

  useEffect(() => {
    // dispatch(getDiagrams());
    hasRoles([rolesList.DICTIONARY_READ_ALL_DATA_TYPES]) &&
      dispatch(getDataTypes());
    // dispatch(getServices());

    return () => {
      setCurrentObject(undefined);
    };
  }, []);

  useEffect(() => {
    // эта проверка нужна из-за того, что после закрытия диаграммы успевает сработать useEffect
    // => проверяется указан ли uid диаграммы, если нет, то считается, что это не страница диаграммы
    if (diagramKey) getDiagramRequest(diagramKey);
  }, [diagramKey]);

  useEffect(() => {
    const isView = currentDiagramReadonly;
    const readOnly = isView ? ` (${translate('readOnly')})` : '';
    const text = currentDiagram.title + readOnly;
    const openTab = { key: currentDiagram.key, tab: { text } };
    text !== 'undefined' && dispatch(updateOpenTab(openTab));
  }, [currentDiagramReadonly, currentDiagram]);

  useEffect(() => {
    if (hasChangedDataDiagram && isObjectSaved) {
      dispatch(changeSaveStatusObject({ saveStatus: false }));
    }
  }, [hasChangedDataDiagram, isObjectSaved]);

  useEffect(() => {
    pathNameTab &&
      dispatch(updateOpenTab({ key: diagramKey, tab: { path: pathNameTab } }));
  }, [history.location.pathname]);

  return [currentObject] as const;
};
