import React from 'react';
import { useConnectionsFormSettings } from '@modules/connections/hooks';
import { rawCommonFormFields } from './rawFormFields';
import { FieldGroup } from '@shared';

type TDataSourceFieldsCommon = {
  className?: string;
};
export const DataSourceFieldsCommon: React.FC<TDataSourceFieldsCommon> = ({
  className = '',
}) => {
  const { fieldsData } = useConnectionsFormSettings(rawCommonFormFields);
  return (
    <FieldGroup className={className} data={fieldsData} labelWidth="200px" />
  );
};
