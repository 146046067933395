import React, { FC } from 'react';
import { Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps } from 'antd';
import './Tooltip.scss';
import AlertIcon from '@modules/icon/assets/icon-alert.svg';

type TooltipProps = AntdTooltipProps & { error?: boolean; white?: boolean };

export const Tooltip: FC<TooltipProps> = ({
  error,
  white,
  title,
  ...restProps
}) => {
  const renderTitle = () => {
    if (!title) return undefined;
    if (error) {
      return (
        <div className="tooltip-error__inner">
          <AlertIcon className="tooltip-error__inner-icon icon" />
          {title}
        </div>
      );
    }
    return title;
  };

  return (
    <AntdTooltip
      overlayClassName={
        error ? 'tooltip-error' : white ? 'tooltip-white' : undefined
      }
      title={renderTitle()}
      {...restProps}
    />
  );
};
