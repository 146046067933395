import React, { FC, useMemo } from 'react';
import { ConfirmModal, IConfirmModal } from '@shared';
import { useTranslationPath } from '@modules/languageProvider';

export type ConfirmSaveModalProps = {
  type: 'confirmSave';
  contentText: string | string[];
};

export const ConfirmInvalidBlockSaveModal: FC<Required<IConfirmModal>> = ({
  modalProps,
  onOk,
  onCancel,
  onClose,
}) => {
  const translate = useTranslationPath(
    'development.diagram.diagram.modals.confirmInvalidBlockSaveModal'
  );

  const contentText = useMemo(() => {
    const main = `${translate('confirmInvalidSave')}</br></br>`;

    if (Array.isArray(modalProps.contentText)) {
      return `${main}${modalProps.contentText.join('</br>')}`;
    }

    return `${main}${modalProps.contentText}`;
  }, [modalProps.contentText, translate]);

  return (
    <ConfirmModal
      modalProps={{
        type: modalProps.type,
        title: translate('title'),
        contentText,
        okButtonText: translate('save'),
        cancelButtonText: translate('cancel'),
        cancelButtonModifiers: ['second', 'hover-box-shadow'],
        closeButtonText: translate('backEdit'),
        closeButtonModifiers: ['hover-box-shadow'],
        buttonsOrder: [2, 3, 1],
        kind: 'warning',
        width: '740px',
      }}
      onOk={onOk}
      onCancel={onCancel}
      onClose={onClose}
    />
  );
};
