import {
  ConfigureTableSetting,
  useColumnSetup,
} from '@modules/connections/hooks';
import React, { useMemo, Key, useState } from 'react';
import {
  ConnectionExtServicesProceed,
  ConnectionsDataSourceModalFields,
  ConnectionsVariablesDTO,
} from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import { useTranslationPath } from '@modules/languageProvider';
import { tranlationPath } from '@modules/administration/components/ExternalServiceCallSettingsModal/data';
import { Form } from 'antd';
import { DataTypesSelect, Input } from '@shared';
import { Checkbox } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import {
  Header,
  Variable,
} from '@modules/administration/components/ExternalServiceCallSettingsModal/interfaces';
import { complexTypeApi, IComplexTypeAttributeExt } from '@modules/complexType';
import { tryCatchDecorator } from '@modules/development/utils';
import { AppDispatch, useAppDispatch } from '@modules/store';
import { rawColumnsHeaders } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/components/externalServiceHeaders/rawColumns';
import { ColumnsType } from 'antd/lib/table';

const fetchAttributesClosure = (dispatch: AppDispatch) => {
  const fetchAttributes = async (
    typeId: string,
    key: Key,
    name: string
  ): Promise<Variable[]> => {
    const response: IComplexTypeAttributeExt[] = await tryCatchDecorator(
      dispatch,
      async () => {
        return await complexTypeApi.getComplexTypeAttributes(typeId);
      }
    );

    return await Promise.all(
      response.map(async (item) => {
        let data: Variable = {
          key: uuidv4(),
          variableId: item.attributeId,
          variableName: `${name}.${item.attributeName}`,
          isArray: item.arrayFlag,
          isComplex: item.complexFlag,
          variableTypeId: undefined,
          sourcePath: '',
          isChildren: true,
        };

        if (item.complexFlag && item.complexTypeVersionId) {
          const children = await fetchAttributes(
            item.complexTypeVersionId,
            key,
            `${name}.${item.attributeName}`
          );

          data = { ...data, children };
        }

        return data;
      })
    );
  };

  return fetchAttributes;
};

export const useDataSourceVariables = (
  variablesData:
    | ConnectionExtServicesProceed['outputVariables']
    | ConnectionExtServicesProceed['inputVariables'],
  formField:
    | ConnectionsDataSourceModalFields.inputVariables
    | ConnectionsDataSourceModalFields.outputVariables,
  rawColumns: ColumnsType<ConnectionsVariablesDTO>
) => {
  const translate = useTranslationPath(tranlationPath);
  const [tableData, setTableData] = useState(variablesData);
  const [selectedTableData, setSelectedTableData] = useState<React.Key[]>([]);
  const dispatch = useAppDispatch();

  const fetchAttributes = fetchAttributesClosure(dispatch);

  const variableTypeChange = async (
    key: Key,
    name: string,
    isComplex?: boolean,
    variableTypeId?: string
  ) => {
    let children: Variable[];

    if (isComplex && typeof variableTypeId === 'string') {
      children = await fetchAttributes(variableTypeId, key, name);
    }

    const refreshedInputVariables = tableData.map((variable) => {
      if (key === variable.key) {
        return {
          ...variable,
          isComplex,
          variableTypeId,
          children,
        };
      }

      return {
        ...variable,
        children: variable.children?.map((child) => {
          if (key === child.key) {
            return {
              ...child,
              isComplex,
              variableTypeId,
              children,
            };
          }
          return child;
        }),
      };
    });

    setTableData(refreshedInputVariables);
  };

  const colSettings = useMemo<
    ConfigureTableSetting<ConnectionsVariablesDTO>
  >(() => {
    return {
      variableName: {
        render(value, { key, isChildren }) {
          return (
            <>
              {isChildren ? (
                <Input readOnly={isChildren} value={value} />
              ) : (
                <Form.Item
                  name={[formField, key, 'variableName']}
                  rules={[
                    { required: true, message: 'Поле должно быть заполнено' },
                  ]}
                >
                  <Input readOnly={isChildren} />
                </Form.Item>
              )}
            </>
          );
        },
      },
      isArray: {
        render(value, { key, isChildren }) {
          const disableCheck = isChildren ? { checked: value } : {};
          return (
            <>
              {isChildren ? (
                <Checkbox {...disableCheck} />
              ) : (
                <Form.Item
                  name={[formField, key, 'isArray']}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              )}
            </>
          );
        },
      },
      variableTypeId: {
        render(value, { key, variableName, isChildren }) {
          return (
            !isChildren && (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.Type !== curValues.Type
                }
              >
                {({ setFieldsValue, getFieldsValue }) => {
                  const selectField = getFieldsValue([formField]);
                  return (
                    <Form.Item
                      name={[formField, key, 'variableTypeId']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                      initialValue={value}
                    >
                      <div>
                        <DataTypesSelect
                          value={value}
                          onChange={(dataType) => {
                            setFieldsValue({
                              ...getFieldsValue(),
                              [formField]: {
                                ...selectField,
                                [key]: {
                                  ...selectField[key],
                                  variableTypeId: {
                                    realValue: dataType?.typeId,
                                    isComplex: dataType?.complexFlag,
                                  },
                                },
                              },
                            });
                            variableTypeChange(
                              key,
                              variableName,
                              dataType?.complexFlag,
                              dataType?.typeId
                            );
                          }}
                        />
                      </div>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            )
          );
        },
      },
      sourcePath: {
        render(value, { key, isChildren }) {
          return (
            <Form.Item
              name={[formField, key, 'sourcePath']}
              rules={[
                { required: true, message: 'Поле должно быть заполнено' },
              ]}
            >
              <Input readOnly={isChildren} />
            </Form.Item>
          );
        },
      },
    };
  }, [tableData]);

  const addInputVariable = () => {
    setTableData([
      ...tableData,
      {
        key: uuidv4(),
        variableName: '',
        isArray: false,
        variableTypeId: '',
        sourcePath: '',
      },
    ]);
  };

  const deleteInputVariable = () => {
    const cutInputVariables = tableData.filter(
      ({ key }) => !selectedTableData.includes(key)
    );
    setTableData(cutInputVariables);
  };

  const toolbar = [
    {
      icon: 'icon-add',
      tooltip: translate('add'),
      onClick: (e: any) => {
        e?.preventDefault();
        addInputVariable();
      },
    },
    { isDivider: true },
    {
      icon: 'icon-delete',
      tooltip: translate('delete'),
      disabled: !selectedTableData.length,
      onClick: (e: any) => {
        e?.preventDefault();
        deleteInputVariable();
      },
    },
  ];

  const columns = useColumnSetup({
    rawColumns,
    configureSettings: colSettings,
    translate,
  });

  return {
    columns,
    toolbar,
    tableData,
    selectedTableData,
    setSelectedTableData,
  };
};

export const useDataSourceHeaders = (
  headersData: ConnectionExtServicesProceed['headers']
) => {
  const translate = useTranslationPath(tranlationPath);
  const [tableData, setTableData] =
    useState<ConnectionExtServicesProceed['headers']>(headersData);
  const [selectedHeaders, setSelectedHeaders] = useState<React.Key[]>([]);

  const onHeadersAdd = () => {
    setTableData([
      ...tableData,
      { key: uuidv4(), headerName: '', headerValue: '', headerId: '' },
    ]);
  };

  const onHeadersDelete = (keys: Key[]) => {
    const cutHeaders = tableData.filter(({ key }: any) => !keys.includes(key));
    setTableData(cutHeaders);
  };

  const toolbar = [
    {
      icon: 'icon-add',
      tooltip: translate('add'),
      onClick: (e: any) => {
        e?.preventDefault();
        onHeadersAdd();
      },
    },
    { isDivider: true },
    {
      icon: 'icon-delete',
      tooltip: translate('delete'),
      onClick: (e: any) => {
        e.preventDefault(e);
        onHeadersDelete(selectedHeaders);
      },
    },
  ];

  const colSettings = useMemo<ConfigureTableSetting<Header>>(() => {
    return {
      headerName: {
        render(value, { key }) {
          return (
            <Form.Item
              name={[
                ConnectionsDataSourceModalFields.headers,
                key,
                'headerName',
              ]}
              rules={[
                { required: true, message: 'Поле должно быть заполнено' },
              ]}
            >
              <Input />
            </Form.Item>
          );
        },
      },
      headerValue: {
        render(value, { key }) {
          return (
            <Form.Item
              name={[
                ConnectionsDataSourceModalFields.headers,
                key,
                'headerValue',
              ]}
              rules={[
                { required: true, message: 'Поле должно быть заполнено' },
              ]}
            >
              <Input />
            </Form.Item>
          );
        },
      },
    };
  }, [tableData]);

  const columns = useColumnSetup({
    rawColumns: rawColumnsHeaders,
    configureSettings: colSettings,
    translate,
  });

  return { columns, toolbar, tableData, selectedHeaders, setSelectedHeaders };
};
