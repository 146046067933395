import React, { FC, forwardRef, Ref } from 'react';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';
import { PasswordProps } from 'antd/lib/input';
import classnames from 'classnames';
import './InputPassword.scss';
import { Tooltip } from '@shared';

export interface IInputPassword extends AntdInputProps, PasswordProps {
  label?: string;
  error?: string;
  ref?: any;
}

export const InputPassword: FC<IInputPassword> = forwardRef(
  (props, ref?: Ref<any>) => {
    const { required, className, size, label, error } = props;

    return (
      <div
        className={classnames(
          className,
          'input-ext__container',
          typeof error === 'string' && 'input-ext__container--error'
        )}
      >
        <div className="input-ext__top">
          {label && <span className="input-ext__label">{label}</span>}
          {required && <span className="input-ext__required">*</span>}
        </div>
        <Tooltip error={!!error} title={error}>
          <AntdInput.Password
            {...props}
            ref={ref}
            required={required}
            size={size || 'large'}
            className="input-ext"
          />
        </Tooltip>
      </div>
    );
  }
);
