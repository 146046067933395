import React, { ReactNode, VFC } from 'react';
import classNames from 'classnames';
import './fieldGroup.scss';

interface IFieldGroup {
  className?: string;
  data: FieldGroupItem[];
  labelWidth: string;
}

export type FieldGroupItem = {
  key: string;
  label: string;
  required?: boolean;
  field: ReactNode;
};

export const FieldGroup: VFC<IFieldGroup> = ({
  className,
  data,
  labelWidth,
}) => {
  return (
    <div className={classNames(className, 'field-group')}>
      {data.map((item) => (
        <div key={item.key} className="field-group__row">
          <div className="field-group__label" style={{ width: labelWidth }}>
            <span>{item.label}</span> {item.required && <span>*</span>}
          </div>
          <div className="field-group__field">{item.field}</div>
        </div>
      ))}
    </div>
  );
};
