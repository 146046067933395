import { ColumnsType } from 'antd/lib/table';
import { StreamsDataTable } from '@modules/development/modules/streams/types/streamsTypes';
import { parseDate } from '@helpers';

export const rawColumns: ColumnsType<StreamsDataTable> = [
  {
    title: 'development.streams.streamsList.columns.title',
    dataIndex: 'title',
    sorter: (a, b) => a?.title.localeCompare(b?.title),
  },
  {
    title: 'development.streams.streamsList.columns.idStream',
    dataIndex: 'code',
    sorter: (a, b) => a?.code.localeCompare(b?.code),
  },
  {
    title: 'development.solutionLists.table.columns.location',
    dataIndex: 'location',
    sorter: (a, b) => a?.location.localeCompare(b?.location),
  },
  {
    title: 'development.solutionLists.table.columns.dateOfChange',
    dataIndex: 'dateOfChange',
    sorter: (a, b) =>
      parseDate(a.dateOfChange).localeCompare(parseDate(b.dateOfChange)),
  },
  {
    title: 'development.solutionLists.table.columns.user',
    dataIndex: 'user',
    sorter: (a, b) => a?.user.localeCompare(b?.user),
  },
];
