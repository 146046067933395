import React, { Fragment, useState, VFC } from 'react';
import './environments.scss';
import { SimpleTable, Toolbar } from '@shared';
import { useTranslation } from '@modules/languageProvider';
import { useEnvironmentsTable } from './hooks';
import { getTranslation } from './utils';
import { CreateEnvironmentModal } from './components/createEnvironmentModal';

type OpenedModal = {
  type: 'createEnvironment';
};

export const Environments: VFC = () => {
  const { t } = useTranslation();

  const [openedModal, setOpenedModal] = useState<OpenedModal | null>(null);

  const onAddClick = () => {
    setOpenedModal({ type: 'createEnvironment' });
  };

  const {
    actions,
    columns,
    data,
    selectedRowKeys,
    rowKeys,
    setSelectedRowKeys,
  } = useEnvironmentsTable(onAddClick);

  return (
    <Fragment>
      <div className="environments">
        <div className="environments__top">
          <p className="environments__title">{t(getTranslation('title'))}</p>
        </div>

        <div className="environments__row">
          <Toolbar
            data={actions}
            onCheckboxAllChange={() => {
              setSelectedRowKeys(
                selectedRowKeys.length === rowKeys.length ? [] : rowKeys
              );
            }}
            CheckboxAllChecked={selectedRowKeys.length === rowKeys.length}
            CheckboxAllIndeterminate={
              selectedRowKeys.length !== rowKeys.length &&
              selectedRowKeys.length !== 0
            }
            isShowCheckboxAll
          />
        </div>

        <div className="environments__content">
          <div className="environments__table">
            <SimpleTable
              columnsData={columns}
              dataSource={data}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              hideSelectAll
            />
          </div>
        </div>
      </div>

      {openedModal?.type === 'createEnvironment' && (
        <CreateEnvironmentModal
          modalProps={{ type: openedModal.type }}
          onClose={() => setOpenedModal(null)}
        />
      )}
    </Fragment>
  );
};
