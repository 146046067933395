import { FC, Key, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { ITypeMapData } from '@modules/development';
import { ArrayFlag, SimpleTable } from '@shared';
import { selectDiagramParameters } from '@modules/development/DevelopmentSlice';
import { useDiagramVariablesTypeMapData } from '../../../common/hooks/useDiagramVariablesTypeMapData';
import { getSelectedRowsByKeys } from '../../../common/utils/getSelectedRowsByKeys';
import { getComplexTypesMap } from '@modules/complexType';
import { IAddElementBase, ISelectedRow, TableRow } from '../../interfaces';

interface IElementTable extends IAddElementBase {
  search?: string;
  multiselect?: boolean;
  onSelect?: (selected: ISelectedRow[]) => void;
}

export const ElementsTable: FC<IElementTable> = ({
  selectedKeys,
  search,
  disableComplex,
  disablePrimitive,
  disableArrays,
  disabledKeys,
  hidePrimitive,
  allowedTypes,
  multiselect,
  selectedNames,
  inputVariables,
  addedVariables,
  onRow,
  onSelect,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const diagramVariables = useSelector(selectDiagramParameters);
  const diagramVariablesTypes = useDiagramVariablesTypeMapData(
    inputVariables,
    addedVariables
  );

  const checkIsSelectable = useCallback(
    (typeId, isComplex, isArray, name) => {
      if (disabledKeys?.includes(name)) return false;
      if (disableComplex && isComplex) return false;
      if (disablePrimitive && !isComplex) return false;
      if (disableArrays && isArray) return false;
      if (
        allowedTypes &&
        !allowedTypes.some(
          (at) => at.typeId === typeId && at.isArray === isArray
        )
      )
        return false;
      return true;
    },
    [disableComplex, disablePrimitive, disableArrays, allowedTypes]
  );

  const tableColumns = useMemo(
    () => [
      {
        title: t('development.diagram.diagram.modals.addElem.attr'),
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        render: (value: string, rowData: { isArray: boolean }) => {
          return (
            <span className="add-element__array_flag_field">
              <Tooltip
                title={value}
                placement="bottomLeft"
                children={<span>{value}</span>}
              />
              <span>{rowData.isArray && <ArrayFlag />}</span>
            </span>
          );
        },
      },
      {
        title: t('development.diagram.diagram.modals.addElem.type'),
        dataIndex: 'type',
        key: 'type',
      },
    ],
    [t]
  );

  const getChildren: (
    attributes: ITypeMapData[] | undefined,
    parentName: string | number,
    parentData: ITypeMapData[]
  ) => TableRow[] | undefined = useCallback(
    (
      attributes: ITypeMapData[] | undefined,
      parentName: string | number,
      parentData: ITypeMapData[]
    ) => {
      const res = attributes
        ?.filter((it) => {
          return (
            !search || it.name.toLowerCase().includes(search.toLowerCase())
          );
        })
        ?.map((it) => {
          const currName = `${parentName}.${it.name}`;
          return {
            key: currName,
            dataIndex: currName,
            name: it.name,
            type: it.typeName,
            isArray: it.isArray,
            isComplex: it.isComplex,
            typeId: it.typeId,
            chainData: parentData.concat(it),
            variableId: it.variableId,
            selectable: checkIsSelectable(
              it.typeId,
              it.isComplex,
              it.isArray,
              currName
            ),
            disabled:
              !it.attributes?.length &&
              !checkIsSelectable(it.typeId, it.isComplex, it.isArray, currName),
            children: getChildren(
              it.attributes,
              currName,
              parentData.concat(it)
            ),
          };
        });

      if (res?.length) {
        return res;
      }
    },
    [checkIsSelectable, search, diagramVariablesTypes]
  );

  const tableVariables = useMemo(() => {
    return diagramVariablesTypes
      ?.filter((item) => item.name && item.typeName)
      ?.map((it) => {
        return {
          key: it.name,
          dataIndex: it.name,
          name: it.name,
          type: it.typeName,
          isArray: it.isArray,
          isComplex: it.isComplex,
          typeId: it.typeId,
          chainData: [it],
          variableId: it.variableId,
          selectable: checkIsSelectable(
            it.typeId,
            it.isComplex,
            it.isArray,
            it.name
          ),
          disabled:
            !it.attributes?.length &&
            !checkIsSelectable(it.typeId, it.isComplex, it.isArray, it.name),
          children: getChildren(it.attributes, it.name, [it]),
        };
      })
      .filter((it) => {
        return (
          (!search ||
            it.children?.length ||
            it.name.toLowerCase().includes(search.toLowerCase())) &&
          ((hidePrimitive && it.isComplex) || !hidePrimitive)
        );
      });
  }, [getChildren, search, checkIsSelectable, diagramVariablesTypes]);

  const selectKeys = useCallback(
    (keys: Key[]) => {
      const newKeys = keys.filter(
        (key) => !multiselect || !selectedKeys?.includes(key)
      );
      const selectedRows: ISelectedRow[] = getSelectedRowsByKeys<TableRow>(
        newKeys,
        tableVariables || []
      )
        .filter((v) => Boolean(v.selectable))
        .map((v) => ({
          type: v.type,
          isArray: v.isArray,
          isComplex: v.isComplex,
          name: v.name,
          chainData: v.chainData,
          typeId: v.typeId,
          key: v.key,
          variableId: v.variableId,
        }));
      onSelect && onSelect(selectedRows);
    },
    [multiselect, selectedKeys, tableVariables]
  );

  useEffect(() => {
    const typeIds = (diagramVariables || [])
      .map((v) => v.typeId)
      .filter((typeId) => typeId.length > 1);
    dispatch(getComplexTypesMap(typeIds));
  }, [diagramVariables]);

  useEffect(() => {
    if (tableVariables) {
      tableVariables.forEach((item) => {
        if (item.children) {
          return item.children.forEach((children) => {
            if (selectedNames?.includes(children.name))
              selectKeys([children.key]);
          });
        }
        if (selectedNames?.includes(item.name)) selectKeys([item.key]);
      });
    }
  }, []);

  return (
    <SimpleTable
      className="add-element__table"
      columnsData={tableColumns}
      dataSource={tableVariables}
      rowClassName={(record: any) => (record.disabled ? 'disabled-row' : '')}
      scroll={{ y: 448 }}
      selectedRowKeys={selectedKeys}
      setSelectedRowKeys={selectKeys}
      onRow={onRow}
      hideSelectAll
    />
  );
};
