/* eslint-disable require-jsdoc */
import React, { useMemo } from 'react';
import {
  useConnectionsActions,
  useConnectionsSelector,
} from '@modules/connections/hooks';
import {
  ConnectionsFormInstance,
  ConnectionsModalDataSource,
} from '@modules/connections/components';
import {
  ConnectionBrokerMessageProceed,
  ConnectionBrokerMessageResponse,
  ConnectionExtServiceParameterType,
  ConnectionExtServicesProceed,
  ConnectionExtServicesResponse,
  ConnectionRdbmsResponse,
  ConnectionsDataSourceModalFields,
  ConnectionsDataSourceTestDBFields,
  IBrokerCommonParamsFormField,
  IHeaderFormField,
  OneConnectionDTO,
} from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import {
  DBMS,
  EXTERNAL_SERVICES,
  MESSAGE_BROKERS,
} from '@modules/connections/constants';
import { IVariableFormField } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import { connectionsApi } from '@modules/connections/api';
import { TConnectionType } from '@modules/connections/types/connectionsTypes';
import { useDispatch } from 'react-redux';

import { AnyAction } from '@reduxjs/toolkit';
const { createConnectionEntity, updateConnectionEntity, testRdbConnection } =
  connectionsApi;

interface ConnectionsFormInstancePrepared extends ConnectionsFormInstance {
  variables?: ConnectionExtServicesResponse['variables'];
  headers?: ConnectionExtServicesResponse['headers'];
  parameters?: ConnectionBrokerMessageResponse['parameters'];
}

export const ConnectionsModalDataSourceWrapper: React.FC = () => {
  const {
    visibleConnectionsDataSourceModal,
    connectionActiveId,
    oneConnection,
    disabledConnectionsDataSourceModal,
  } = useConnectionsSelector();
  const dispatch = useDispatch();
  const { setVConnectionsDataSourceModal, setConnectionActiveId } =
    useConnectionsActions();
  const isEdit = !!connectionActiveId;

  const prepareVariablesToBack = (
    variables: IVariableFormField,
    parameterType: ConnectionExtServiceParameterType
  ): ConnectionsFormInstancePrepared['variables'] => {
    return Object.entries(variables).map(([, value]) => ({
      variableName: value.variableName,
      parameterType,
      arrayFlag: value.isArray,
      complexTypeVersionId: value?.variableTypeId?.isComplex
        ? value?.variableTypeId?.realValue
        : undefined,
      primitiveTypeId: !value?.variableTypeId?.isComplex
        ? value?.variableTypeId?.realValue
        : undefined,
    }));
  };

  const prepareVariablesToForm = (
    variables:
      | ConnectionExtServicesProceed['inputVariables']
      | ConnectionExtServicesProceed['outputVariables']
  ): IVariableFormField => {
    return variables.reduce(
      (
        previousValue,
        { key, variableName, variableTypeId, isArray, isComplex, sourcePath }
      ) => {
        return {
          ...previousValue,
          [key as string]: {
            variableName,
            variableTypeId: {
              realValue: variableTypeId,
              isComplex,
            },
            isArray,
            ...(sourcePath ? { sourcePath } : {}),
          },
        };
      },
      {}
    );
  };

  const preparedHeadersToBack = (
    headers: ConnectionsFormInstance['headers']
  ): ConnectionsFormInstancePrepared['headers'] => {
    return Object.values<IHeaderFormField>(headers).map(
      ({ headerName, headerValue }) => ({ headerName, headerValue })
    );
  };

  const preparedParametersToBack = (
    parameters: ConnectionsFormInstance['brokerMessageCommonParams'] = {}
  ): ConnectionsFormInstancePrepared['parameters'] => {
    return Object.values(parameters).map(({ name, value, isSecure }) => ({
      name,
      value,
      isSecure,
      brokerParameterType: 'PRODUCER',
    }));
  };

  const apiConstructor = (
    type: TConnectionType,
    id: string | null,
    data: OneConnectionDTO | Omit<OneConnectionDTO, 'connectionId'>
  ) => {
    if (isEdit) {
      if (id === null) {
        throw new Error('No connection selected');
      }
      return updateConnectionEntity(type, id, {
        ...data,
        connectionId: id,
      } as OneConnectionDTO);
    }
    return createConnectionEntity(type, data);
  };

  const testConnection = async (
    connectionValues: ConnectionsDataSourceTestDBFields
  ) => {
    return testRdbConnection(connectionValues);
  };

  const saveConnection = async (connectionValues: ConnectionsFormInstance) => {
    const { type, name, description, connectionStatus, ...properties } =
      connectionValues;
    const preparedProps: Omit<
      ConnectionsFormInstancePrepared,
      'type' | 'name' | 'description' | 'connectionStatus'
    > = { ...properties };

    if (type === DBMS) {
      return apiConstructor(type, connectionActiveId, {
        type,
        name,
        description,
        connectionStatus,
        properties: preparedProps,
      });
    }

    if (type === EXTERNAL_SERVICES) {
      let inputVariables: any[] = [];
      let outputVariables: any[] = [];
      const {
        inputVariables: formInputVariables = {},
        outputVariables: formOutputVariables = {},
      } = properties;

      if (formInputVariables) {
        inputVariables = prepareVariablesToBack(formInputVariables, 'IN');
      }
      if (formOutputVariables) {
        outputVariables = prepareVariablesToBack(formOutputVariables, 'OUT');
      }

      preparedProps.variables = [...inputVariables, ...outputVariables];

      if (preparedProps.headers) {
        preparedProps.headers = preparedHeadersToBack(preparedProps.headers);
      }
      return apiConstructor(type, connectionActiveId, {
        type,
        name,
        description,
        connectionStatus,
        properties: preparedProps,
      });
    }

    if (type === MESSAGE_BROKERS) {
      const { brokerMessageCommonParams = {} } = properties;
      preparedProps.parameters = preparedParametersToBack(
        brokerMessageCommonParams
      );
      return apiConstructor(type, connectionActiveId, {
        type,
        name,
        description,
        connectionStatus,
        properties: preparedProps,
      });
    }
  };

  const initialFormValues = useMemo<Partial<ConnectionsFormInstance>>(() => {
    if (oneConnection && !!connectionActiveId) {
      // Перенести драйверы на бек и поменять логику
      const driver =
        oneConnection.type === 'RDBMS' && oneConnection.name === 'postgres'
          ? 'org.postgresql.Driver'
          : 'oracle.jdbc.driver.OracleDriver';
      const { properties, type, ...restOneConnection } = oneConnection;
      const {
        status,
        variables,
        inputVariables: rawInputVariables,
        outputVariables: rawOutputVariables,
        headers: rawHeaders,
        parameters: rawParameters,
        ...restProperties
      } = (properties as ConnectionRdbmsResponse &
        ConnectionExtServicesProceed &
        ConnectionBrokerMessageProceed) ?? {};
      const formFields = {
        ...restOneConnection,
        ...restProperties,
        type,
        driver,
      };

      if (type === EXTERNAL_SERVICES) {
        const inputVariables = prepareVariablesToForm(rawInputVariables);
        const outputVariables = prepareVariablesToForm(rawOutputVariables);
        const headers = rawHeaders.reduce(
          (previousValue, { key, headerName, headerValue }) => {
            return {
              ...previousValue,
              [key]: {
                headerName,
                headerValue,
              },
            };
          },
          {} as IHeaderFormField
        );
        return {
          ...formFields,
          [ConnectionsDataSourceModalFields.inputVariables]: inputVariables,
          [ConnectionsDataSourceModalFields.outputVariables]: outputVariables,
          [ConnectionsDataSourceModalFields.headers]: headers,
        };
      }
      if (type === MESSAGE_BROKERS) {
        const brokerMessageCommonParams = rawParameters.reduce(
          (prevValue, { key, value, name, isSecure }) => {
            return {
              ...prevValue,
              [key]: {
                name,
                value,
                isSecure,
              },
            };
          },
          {} as IBrokerCommonParamsFormField
        );
        return {
          ...formFields,
          [ConnectionsDataSourceModalFields.brokerMessageCommonParams]:
            brokerMessageCommonParams,
        };
      }

      return formFields;
    }

    return {};
  }, [oneConnection, connectionActiveId]);

  const closeModal = () => {
    setConnectionActiveId(null);
    setVConnectionsDataSourceModal(false);
  };

  if (!visibleConnectionsDataSourceModal) return null;

  return (
    <ConnectionsModalDataSource
      saveConnection={saveConnection}
      testConnection={testConnection}
      isEdit={isEdit}
      initialFormValues={initialFormValues}
      disabled={disabledConnectionsDataSourceModal}
      closeModal={closeModal}
    />
  );
};
