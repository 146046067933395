import { VFC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { TechnicalMonitoring } from '@modules/administration/components/TechnicalMonitoring/TechnicalMonitoring';
import { DeployDiagram } from '@modules/administration/components/DeployDiagram/DeployDiagram';
import { ExternalServiceAccess } from '@modules/administration/components/ExternalServiceAccess/ExternalServiceAccess';
import { DataSources } from '@modules/administration/components/DataSources/DataSources';
import {
  ADMINISTRATION_CORE_PATH,
  DATA_SOURCES_PATH,
  DEPLOY_DIAGRAM_PATH,
  EXTERNAL_SERVICE_ACCESS_PATH,
  LOCKOUT_PATH,
  NOT_FOUND_PATH,
  TECH_MONITORING_PATH,
} from './routing/administrationConstants';
import { rolesList } from '@modules/services';
import { Lockout } from './components/Lockout/Lockout';

// @ts-ignore
const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

const Navigation: VFC<{ roles: string[] }> = ({ roles }) => (
  <Switch>
    <Route
      exact
      path={ADMINISTRATION_CORE_PATH}
      render={() => <Redirect to={TECH_MONITORING_PATH} />}
    />
    {HIDE_FEATURES ? null : (
      <Route
        exact
        path={TECH_MONITORING_PATH}
        component={TechnicalMonitoring}
      />
    )}
    <Route exact path={DEPLOY_DIAGRAM_PATH} component={DeployDiagram} />
    <Route exact path={LOCKOUT_PATH} component={Lockout} />
    {roles.includes(rolesList.EXT_SERVICE_READ_ALL) && (
      <Route
        exact
        path={EXTERNAL_SERVICE_ACCESS_PATH}
        component={ExternalServiceAccess}
      />
    )}
    {roles.includes(rolesList.DATA_PROVIDER_READ_ALL) && (
      <Route exact path={DATA_SOURCES_PATH} component={DataSources} />
    )}
    <Route path="*" render={() => <Redirect to={NOT_FOUND_PATH} />} />
  </Switch>
);

export { Navigation };
