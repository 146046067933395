import React, { useState } from 'react';
import { Badge } from 'antd';
import { InputSearch, Portal, SimpleTable } from '@shared';
import { Icon } from '@modules/icon';
import { useTranslationPath } from '@modules/languageProvider';
import './DiagramsWithScript.scss';
import { useTranslation } from 'react-i18next';

interface IDiagramsWithScript {}

const DiagramsWithScript: React.FC<IDiagramsWithScript> = () => {
  const [search, setSearch] = useState<string>('');
  const { t } = useTranslation();
  const translate = useTranslationPath(
    'development.customCode.diagramsWithScripts'
  );

  const renderState = (value: any) => {
    switch (value) {
      case 'В разработке':
        return <Badge color="#d9d9d9" text={translate('inDeveloping')} />;
        break;
      case 'Развернуто':
        return <Badge color="#0048B2" text={translate('deployed')} />;
        break;
      default:
        return value;
    }
  };

  const sorterColumn = (a: any, b: any) => a.title.localeCompare(b.title);

  const columns = [
    {
      title: translate('title'),
      dataIndex: 'title',
      key: 'title',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      sorter: sorterColumn,
    },
    {
      title: translate('code'),
      dataIndex: 'code',
      key: 'code',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      sorter: sorterColumn,
      render: (value: string) => {
        return <div className="code">{value}</div>;
      },
    },
    {
      title: translate('location'),
      dataIndex: 'location',
      key: 'location',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      sorter: sorterColumn,
    },
    {
      title: translate('status'),
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      sorter: sorterColumn,
      render: renderState,
    },
    {
      title: translate('version'),
      dataIndex: 'version',
      key: 'version',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      sorter: sorterColumn,
      render: (value: string) => {
        return <div className="version">{value}</div>;
      },
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '5%',
      render: () => <Icon name="icon-more_horizontal" onClick={() => null} />,
    },
  ];

  const dataTest = [
    {
      title: t('mocks.calcOffer'),
      diagramCode: 'CODE_1',
      location: 'Diagrams',
      status: t('statuses.process'),
      version: '1.0',
      key: '1',
    },
    {
      title: t('mocks.calcOffer'),
      diagramCode: 'CODE_2',
      location: 'Diagrams',
      status: t('statuses.deployed'),
      version: '2.0',
      key: '2',
    },
  ];

  return (
    <div className="diagrams-with-scripts">
      <div className="diagrams-with-scripts__top-content">
        {
          <Portal
            children={
              <div className="toolbar-actions">
                <div className="toolbar__search">
                  <InputSearch
                    bordered
                    background="#fff"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            }
            id="additional-buttons"
          />
        }
        <SimpleTable
          className="diagrams-with-scripts____simple-table"
          columnsData={columns}
          dataSource={dataTest}
          size="large"
        />
      </div>
    </div>
  );
};

export { DiagramsWithScript };
