import { apiBase, IResponse } from '@modules/apiBase';
import {
  IServiceShort,
  ServiceFull,
  IServiceVariables,
  ServiceAdd,
  ServiceUpdate,
  ServiceVersions,
  ServiceCreateUserVersion,
  ServiceUpdateUserVersion,
} from '../types/interface';

const SERVICES_ENDPOINT = 'services';

export const servicesApi = {
  addService: async (body: ServiceAdd) => {
    return await apiBase.post<IResponse>(SERVICES_ENDPOINT, body);
  },
  getServiceById: async (serviceId: string) => {
    return await apiBase.get<ServiceFull>(`${SERVICES_ENDPOINT}/${serviceId}`);
  },
  updateServiceById: async (serviceId: string, body: ServiceUpdate) => {
    return await apiBase.put<IResponse>(
      `${SERVICES_ENDPOINT}/${serviceId}`,
      body
    );
  },
  deleteServiceById: async (serviceId: string) => {
    return await apiBase.delete<IResponse>(`${SERVICES_ENDPOINT}/${serviceId}`);
  },
  getServices: async () => {
    return await apiBase.get<IServiceShort[]>(SERVICES_ENDPOINT);
  },
  getServiceVariables: async (serviceId: string) => {
    return await apiBase.get<IServiceVariables>(
      `${SERVICES_ENDPOINT}/${serviceId}/variables`
    );
  },
  getServiceVersions: async (serviceId: string) => {
    return await apiBase.get<ServiceVersions>(
      `${SERVICES_ENDPOINT}/${serviceId}/versions`
    );
  },
  addServiceUserVersion: async (
    serviceId: string,
    body: ServiceCreateUserVersion
  ) => {
    return await apiBase.post<IResponse>(
      `${SERVICES_ENDPOINT}/${serviceId}/create/userVersion`,
      body
    );
  },
  updateServiceUserVersion: async (
    versionId: string,
    body: ServiceUpdateUserVersion
  ) => {
    return await apiBase.put<IResponse>(
      `${SERVICES_ENDPOINT}/${versionId}/updateUserVersion`,
      body
    );
  },
};
