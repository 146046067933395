import { FC, useCallback, useEffect, useState } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { useTranslationPath } from '@modules/languageProvider';
import { Input } from '../Input';
import './IntervalValue.scss';

export interface IRangeValue {
  from: number | string;
  to: number | string;
  includeFrom: boolean;
  includeTo: boolean;
}

interface IIntervalValue {
  value: IRangeValue;
  disabled?: boolean;
  onChange: (value: IRangeValue) => void;
}

export const IntervalValue: FC<IIntervalValue> = ({
  value,
  disabled,
  onChange,
}) => {
  const translate = useTranslationPath(
    'development.diagram.diagram.common.intervalValue'
  );

  const [innerValue, setInnerValue] = useState<IRangeValue>({
    from: value.from,
    to: value.to,
    includeFrom: value?.includeFrom || false,
    includeTo: value?.includeTo || false,
  });

  // регулярка на отрицательные и положительные целые и дробные /^-?(0|[1-9]\d*)(\.\d+)?$/
  const onInputChange = useCallback(
    (value: string, where: 'to' | 'from') => {
      if (/[а-яА-ЯёЁa-zA-Z]+$/.test(value)) return;
      if (value === '*') {
        onChange({ ...innerValue, [where]: '*' });
      } else if (parseFloat(value)?.toString() === value) {
        onChange({ ...innerValue, [where]: parseFloat(value) });
      } else if (
        value.toString().startsWith('-') ||
        value.toString().includes('.')
      ) {
        onChange({ ...innerValue, [where]: value });
      } else {
        onChange({ ...innerValue, [where]: '' });
      }
    },
    [innerValue, onChange]
  );

  useEffect(() => {
    setInnerValue({
      from: value?.from,
      to: value?.to,
      includeFrom: value?.includeFrom || false,
      includeTo: value?.includeTo || false,
    });
  }, [value]);

  return (
    <div className="interval-value">
      <Tooltip placement="top" title={translate('including')}>
        <Checkbox
          checked={innerValue?.includeFrom}
          disabled={disabled}
          onChange={(e) => {
            onChange({ ...innerValue, includeFrom: e.target.checked });
          }}
        />
      </Tooltip>

      <Tooltip title={translate('from')} placement="top">
        <Input
          className="interval-value__input-from"
          value={value?.from}
          disabled={disabled}
          onChange={(e) => onInputChange(e.target.value, 'from')}
        />
      </Tooltip>

      <Tooltip title={translate('to')} placement="top">
        <Input
          className="interval-value__input-to"
          value={value?.to}
          disabled={disabled}
          onChange={(e) => onInputChange(e.target.value, 'to')}
        />
      </Tooltip>

      <Tooltip placement="top" title={translate('including')}>
        <Checkbox
          checked={innerValue?.includeTo}
          disabled={disabled}
          onChange={(e) => {
            onChange({ ...innerValue, includeTo: e.target.checked });
          }}
        />
      </Tooltip>
    </div>
  );
};
