import React, { FC } from 'react';

interface IDataSourcesTop {
  title: string;
}

export const DataSourcesTop: FC<IDataSourcesTop> = ({ title }) => {
  return (
    <div className="data-sources__top">
      <p className="data-sources__title">{title}</p>
    </div>
  );
};
