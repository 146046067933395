import { STREAMS_CORE_PATH, STREAM_PATH } from './streamsConstants';
import { Stream } from '@modules/development/modules/streams/components/stream/Stream';
import { StreamsList } from '../components/streamsList';
import { rolesList } from '@modules/services';

export const STREAMS_ROUTES = [
  {
    path: STREAMS_CORE_PATH,
    component: StreamsList,
    role: rolesList.DIAGRAM_READ_ALL,
    exact: true,
  },
  {
    path: STREAM_PATH,
    component: Stream,
  },
];
