import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { aggregatesSeeds } from '@modules/development/DevelopmentSlice';

export const getAggregates = (
  store: MiddlewareAPI<Dispatch<AnyAction>, any>,
  action: any
) => {
  const aggregates = action.payload.map((aggregate: any) => {
    return {
      aggregateId: aggregate.aggregateId,
      aggregateName: aggregate.aggregateName,
      aggregateDescription: aggregate.aggregateDescription,
      aggregateJson: JSON.parse(aggregate.aggregateJson),
      aggregateRelations: aggregate.aggregateRelations,
      createDt: aggregate.createDt,
      createByUser: aggregate.createByUser,
      changeDt: aggregate.changeDt,
      lastChangeByUser: aggregate.lastChangeByUser,
      key: aggregate.versionId,
      // new отсутствуют в описании api:
      versionId: aggregate.versionId,
      type: 'aggregate',
      draft: false,
    };
  });
  store.dispatch(aggregatesSeeds({ aggregates }));
};
