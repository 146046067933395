import React, { InputHTMLAttributes } from 'react';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import { Button, Input } from '@shared';
import { Icon } from '@modules/icon';

interface IInputAddData extends InputHTMLAttributes<HTMLInputElement> {
  onClick: () => void;
  label?: string;
  error?: string;
  tooltip?: string;
  tooltipButton?: string;
}

export const InputAddData: React.FC<IInputAddData> = ({
  className,
  value,
  onClick,
  label,
  error,
  tooltip,
  tooltipButton,
  disabled,
}) => {
  let button = (
    <Button
      className="input-ext__add-data__button"
      kind="transparent"
      modifiers={['min-width', 'transparent']}
      disabled={disabled}
      onClick={onClick}
      children={<Icon name="icon-edit" />}
    />
  );

  if (tooltipButton) {
    button = (
      <Tooltip title={tooltipButton} placement="bottom" children={button} />
    );
  }

  const template = (
    <div className={classnames(className, 'input-ext__container')}>
      {label && (
        <div className="input-ext__top">
          <span className="input-ext__label">{label}</span>
        </div>
      )}
      <div className={classnames(className, 'input-ext__add-data')}>
        <Input
          className="input-ext__add-data__input"
          value={value}
          error={error}
          disabled={disabled}
          readOnly
        />
        {button}
      </div>
    </div>
  );

  if (tooltip && tooltip.length > 35)
    return (
      <Tooltip
        title={<pre style={{ whiteSpace: 'pre-wrap' }}>{tooltip}</pre>}
        children={template}
      />
    );

  return template;
};
