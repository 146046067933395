import React, { ChangeEvent, forwardRef, Ref, useRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input as AntdInput, InputRef } from 'antd';
import './InputSearch.scss';
import { Input, Button, IInput } from '@shared';
import { Icon } from '@modules/icon';

type IInputSearchBorderColor = '#fafafc' | '#fff';

interface IInputSearch extends IInput {
  background?: IInputSearchBorderColor;
  iconColor?: string;
}

export const InputSearch: React.FC<IInputSearch> = forwardRef(
  (
    { className, value, placeholder, background, width, size, onChange },
    ref?: Ref<InputRef>
  ) => {
    const searchRef = useRef(ref || null);
    const { t } = useTranslation();

    return (
      <div
        className={classnames(
          className,
          'input-ext__search',
          size === 'large' && 'input-ext__search--lg'
        )}
        style={{ width: width && 420 }}
      >
        <Input
          ref={searchRef}
          className="input-ext__search__input"
          placeholder={placeholder ? placeholder : `${t('actions.search')}...`}
          value={value}
          onChange={onChange}
          style={{ background }}
          size={size || 'middle'}
        />
        {value && value.toString().length !== 0 ? (
          <Button
            className="input-ext__search__button input-ext__search__button--clear"
            kind="transparent"
            modifiers={['min-width', 'transparent']}
            onClick={() =>
              onChange &&
              onChange({
                target: { value: '' },
                currentTarget: { value: '' },
              } as ChangeEvent<HTMLInputElement>)
            }
            children={<Icon name="icon-highlight_off" />}
          />
        ) : (
          <Button
            className="input-ext__search__button"
            kind="normal"
            modifiers={['min-width', 'transparent']}
            children={<Icon name="icon-search" />}
          />
        )}
      </div>
    );
  }
);
