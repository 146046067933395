import React, { FC, VFC } from 'react';
import { Input, InputPassword, Select } from '@shared';
import { IDataProviderUpdate } from '@modules/dataProvider';
import { Errors } from '../interfaces';
import { rolesList, selectRoles } from '@modules/services';
import { useAppSelector } from '@modules/store';

const Field: FC<{ label: string }> = ({ label, children }) => {
  return (
    <div className="connect-data-source-modal__row">
      <div className="connect-data-source-modal__label">
        <span>{label}</span>
      </div>
      <div className="connect-data-source-modal__field">{children}</div>
    </div>
  );
};

interface IConnectDataSourceGrid {
  translate: (attrName: any, options?: any) => any;
  values: IDataProviderUpdate;
  errors: Errors | undefined;
  onValuesChange: (key: string, value: string) => void;
}

export const ConnectDataSourceGrid: VFC<IConnectDataSourceGrid> = ({
  translate,
  values,
  errors,
  onValuesChange,
}) => {
  const roles = useAppSelector(selectRoles);

  return (
    <div className="connect-data-source-modal__grid">
      <Field label={translate('sourceName')}>
        <Input
          value={values.sourceName}
          error={errors?.sourceName}
          onChange={(event) => {
            onValuesChange('sourceName', event.target.value);
          }}
          disabled={!roles.includes(rolesList.DATA_PROVIDER_UPDATE)}
        />
      </Field>

      <Field label={translate('description')}>
        <Input
          value={values.description}
          onChange={(event) => {
            onValuesChange('description', event.target.value);
          }}
          disabled={!roles.includes(rolesList.DATA_PROVIDER_UPDATE)}
        />
      </Field>

      <Field label={translate('serverName')}>
        <Input
          value={values.serverName}
          error={errors?.serverName}
          onChange={(event) => {
            onValuesChange('serverName', event.target.value);
          }}
          disabled={!roles.includes(rolesList.DATA_PROVIDER_UPDATE)}
        />
      </Field>

      <Field label={translate('sourceType')}>
        <Select
          value={values.sourceType}
          options={[
            { value: 'ORACLE', label: 'Oracle' },
            { value: 'POSTGRES', label: 'Postgres' },
          ]}
          error={errors?.sourceType}
          onChange={(value) => {
            onValuesChange('sourceType', value);
          }}
          disabled={!roles.includes(rolesList.DATA_PROVIDER_UPDATE)}
        />
      </Field>

      <Field label={translate('connectionType')}>
        <Select
          value={values.connectionType}
          options={[{ value: 'jdbc', label: 'jdbc' }]}
          error={errors?.connectionType}
          onChange={(value) => {
            onValuesChange('connectionType', value);
          }}
          disabled={!roles.includes(rolesList.DATA_PROVIDER_UPDATE)}
        />
      </Field>

      <Field label={translate('username')}>
        <Input
          value={values.username}
          error={errors?.username}
          onChange={(event) => {
            onValuesChange('username', event.target.value);
          }}
          disabled={!roles.includes(rolesList.DATA_PROVIDER_UPDATE)}
        />
      </Field>

      <Field label={translate('password')}>
        <InputPassword
          value={values.password}
          error={errors?.password}
          onChange={(event) => {
            onValuesChange('password', event.target.value);
          }}
          disabled={!roles.includes(rolesList.DATA_PROVIDER_UPDATE)}
        />
      </Field>

      <Field label={translate('port')}>
        <Input
          value={values.port}
          error={errors?.port}
          onChange={(event) => {
            onValuesChange('port', event.target.value);
          }}
          disabled={!roles.includes(rolesList.DATA_PROVIDER_UPDATE)}
        />
      </Field>

      <Field label={translate('additionalProperties')}>
        <Input
          value={values.additionalProperties}
          error={errors?.additionalProperties}
          onChange={(event) => {
            onValuesChange('additionalProperties', event.target.value);
          }}
          disabled={!roles.includes(rolesList.DATA_PROVIDER_UPDATE)}
        />
      </Field>
    </div>
  );
};
