export const data0 = [
  {
    type: 'FATAT',
    value: 10008,
  },
  {
    type: 'FATAT',
    value: 11008,
  },
  {
    type: 'ERROR',
    value: 11008,
  },
  {
    type: 'WAEN',
    value: 12408,
  },
  {
    type: 'INFO',
    value: 8008,
  },
  {
    type: 'DEBUG',
    value: 9008,
  },
  {
    type: 'TRACE',
    value: 14008,
  },
  {
    type: 'NONE',
    value: 15008,
  },
];
export const data1 = [
  {
    type: 'FATAT',
    value: 10008,
  },
  {
    type: 'FATAT',
    value: 1108,
  },
  {
    type: 'ERROR',
    value: 11008,
  },
  {
    type: 'WAEN',
    value: 1208,
  },
  {
    type: 'INFO',
    value: 8008,
  },
  {
    type: 'DEBUG',
    value: 9008,
  },
  {
    type: 'TRACE',
    value: 10008,
  },
  {
    type: 'NONE',
    value: 15008,
  },
];
export const data2 = [
  {
    type: 'FATAT',
    value: 1008,
  },
  {
    type: 'FATAT',
    value: 11008,
  },
  {
    type: 'ERROR',
    value: 1008,
  },
  {
    type: 'WAEN',
    value: 12408,
  },
  {
    type: 'INFO',
    value: 808,
  },
  {
    type: 'DEBUG',
    value: 9008,
  },
  {
    type: 'TRACE',
    value: 1408,
  },
  {
    type: 'NONE',
    value: 15008,
  },
];
