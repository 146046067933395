import React from 'react';
import { Input } from '@shared';
import type { UploadProps } from 'antd';
import { Upload } from 'antd';
import { Icon } from '@modules/icon';
import { RcFile } from 'antd/lib/upload/interface';
import './ConnectionsFileUpload.scss';

interface IConnectionsFileUpload {
  onChange?: (file: RcFile | '') => void;
  name?: string;
}

export const ConnectionsFileUpload: React.FC<IConnectionsFileUpload> = ({
  onChange,
  name = '',
}) => {
  const beforeUpload: UploadProps['beforeUpload'] = async (file) => {
    onChange?.(file);
    return false;
  };

  const onRemove = () => {
    onChange?.('');
  };

  const clearAll = () => {
    onChange?.('');
  };

  return (
    <Input
      readOnly
      value={name}
      className="connections__upload"
      suffix={
        <>
          <Upload
            accept=".jks"
            onRemove={onRemove}
            beforeUpload={beforeUpload}
            showUploadList={false}
            maxCount={1}
          >
            <Icon name="icon-upload_to_file" />
          </Upload>
          {name && <Icon name="icon-close" onClick={clearAll} />}
        </>
      }
    />
  );
};
