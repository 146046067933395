import { FC, Key, useCallback, useMemo, useState } from 'react';
import { Button, InputSearch, ModalBox } from '@shared';
import { IAddElement, ISelectedRow } from '../../interfaces';
import { ElementsTable } from '../ElementsTable/ElementsTable';
import './AddElement.scss';
import { useTranslationPath } from '@modules/languageProvider';

interface IAddElementExt extends IAddElement {
  onOpenAddNew?: () => void;
}

export const AddElement: FC<IAddElementExt> = ({
  onSave,
  onClose,
  closeOnSave,
  onOpenAddNew,
  hideAddNewBtn,
  allowedTypes,
  disableComplex,
  disablePrimitive,
  disableArrays,
  disabledKeys,
  hidePrimitive,
  selectedNames,
  selectedKeys,
  inputVariables,
  addedVariables,
}) => {
  const translate = useTranslationPath(
    'development.diagram.diagram.modals.addElem'
  );
  const translateActions = useTranslationPath('actions');

  const [search, setSearch] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>(
    selectedKeys ? selectedKeys : []
  );
  const [selectedRowData, setSelectedRowData] = useState<ISelectedRow>();

  const onAdd = useCallback(() => {
    if (selectedRowData) {
      onSave({
        chainName: selectedRowData.key,
        typesChain: selectedRowData.chainData,
        isArray: selectedRowData.isArray,
        isComplex: selectedRowData.isComplex,
        name: selectedRowData.name,
        typeId: selectedRowData.typeId,
        id: selectedRowData.variableId,
      });
    }
    closeOnSave && onClose();
  }, [selectedRowData, onSave, closeOnSave]);

  const panelData = useMemo(() => {
    if (hideAddNewBtn) return null;
    return [
      {
        icon: 'icon-add',
        tooltip: translateActions('add'),
        onClick: () => onOpenAddNew && onOpenAddNew(),
      },
    ];
  }, [hideAddNewBtn]);

  return (
    <div className="add-element">
      <div className="add-element__search">
        <InputSearch
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          background="#fafafc"
          bordered
        />
      </div>
      <ModalBox
        className="add-element__table"
        actions={panelData}
        showHeader={hideAddNewBtn ? false : true}
      >
        <ElementsTable
          inputVariables={inputVariables}
          addedVariables={addedVariables}
          selectedKeys={selectedRowKeys}
          search={search}
          disableComplex={disableComplex}
          disablePrimitive={disablePrimitive}
          disableArrays={disableArrays}
          disabledKeys={disabledKeys}
          hidePrimitive={hidePrimitive}
          allowedTypes={allowedTypes}
          selectedNames={selectedNames}
          onSelect={(selected) => {
            const newSelected = selected.find(
              (s) => !selectedRowKeys.includes(s.key)
            );
            setSelectedRowKeys(newSelected ? [newSelected.key] : []);
            setSelectedRowData(newSelected);
          }}
        />
      </ModalBox>
      <div className="add-element__btns btn-group-bottom">
        <Button
          kind="normal"
          modifiers={['hover-box-shadow']}
          disabled={!selectedRowKeys.length}
          onClick={onAdd}
        >
          {translate('add')}
        </Button>
        <Button
          kind="normal"
          modifiers={['second', 'hover-box-shadow']}
          onClick={onClose}
        >
          {translate('cancel')}
        </Button>
      </div>
    </div>
  );
};
