import { apiBase, IResponse } from '@modules/apiBase';
import { ITest, ITestCase, ITestUpdate, TestCaseInfo } from '../types';

const TEST_ENDPOINT = 'test';

type CreateTestProps = { testId: string; testName: string; timeout: number };

export const testApi = {
  getTestById: async (testId: string) => {
    return await apiBase.get<ITest>(`${TEST_ENDPOINT}/${testId}`);
  },
  changeTestById: async (
    testId: string,
    testBody: Omit<ITestUpdate, 'testId'>
  ) => {
    return await apiBase.put<IResponse>(
      `${TEST_ENDPOINT}/${testId}`,
      testBody,
      { headers: { 'Content-Type': 'application/json' } }
    );
  },
  deleteTestById: async (testId: string) => {
    return await apiBase.delete<IResponse>(`${TEST_ENDPOINT}/${testId}`);
  },
  getTestsByDiagramId: async (diagramId: string) => {
    return await apiBase.get<ITest[]>(
      `${TEST_ENDPOINT}?diagramId=${diagramId}`
    );
  },
  createTest: async (diagramId: string, locale: 'ru' | 'en') => {
    return await apiBase.post<CreateTestProps>(`${TEST_ENDPOINT}/next`, {
      locale,
      diagramId,
    });
  },
  uploadTestFile: async (testId: string, testBody: FormData) => {
    return await apiBase.post(
      `${TEST_ENDPOINT}/upload/file/${testId}`,
      testBody
    );
  },
  startTests: async (diagramId: string, testBody: any[]) => {
    return await apiBase.post<IResponse>(
      `${TEST_ENDPOINT}/start/${diagramId}`,
      testBody
    );
  },
  getTestResultsById: async (testId: string) => {
    return await apiBase.get<TestCaseInfo[]>(
      `${TEST_ENDPOINT}/${testId}/test_results`
    );
  },
  getTestStatusById: async (testId: string) => {
    apiBase.get<ITest | IResponse>(`${TEST_ENDPOINT}/${testId}/status`);
  },
  getTestCaseById: async (testCaseId: string) => {
    return await apiBase.get<ITestCase>(`${TEST_ENDPOINT}/case/${testCaseId}`);
  },
  generateTestFile: async (diagramId: string) => {
    const file = await apiBase.get<File>(
      `${TEST_ENDPOINT}/generate/${diagramId}`,
      { responseType: 'blob' }
    );
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([file]));
    link.setAttribute('download', 'test-file.xls');
    document.body.appendChild(link);
    link.click();
  },
};
