import { FC, Key, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  SimpleTable,
  useModalVisible,
  IModalProps,
  InputSearch,
} from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import { getFunctions, selectFunctions, IFunction } from '@modules/references';
import './AddFunctionModal.scss';
import { filterEqualByKey } from '@modules/utils';
import { TableColumnsType } from 'antd';
import { selectRoles, rolesList } from '@modules/services';
import { useAppSelector } from '@modules/store';

interface IAddFunctionModal {
  onSave: (func: IFunction) => void;
  onClose: () => void;
  modalProps: IModalProps | null;
  closeOnSave?: boolean;
}

export const AddFunctionModal: FC<IAddFunctionModal> = ({
  onSave,
  onClose,
  modalProps,
  closeOnSave = false,
}) => {
  const translate = useTranslationPath(
    'development.diagram.diagram.modals.addFunc'
  );
  const dispatch = useDispatch();
  const isVisible = useModalVisible(modalProps?.type);

  const [search, setSearch] = useState<string>('');
  const roles = useAppSelector(selectRoles);
  useEffect(() => {
    roles.includes(rolesList.DICTIONARY_READ_ALL_FUNCTIONS) &&
      dispatch(getFunctions());
  }, []);
  const functions = useSelector(selectFunctions);

  const [selectedFuncIds, setSelectedFuncIds] = useState<Key[]>([]);
  const tableColumns = useMemo<TableColumnsType<any>>(
    () => [
      {
        title: translate('function'),
        dataIndex: 'functionName',
        key: 'functionName',
        width: '30%',
        sorter: (a, b) => a.functionName?.localeCompare(b.functionName),
      },
      {
        title: translate('resultType'),
        dataIndex: 'resultType',
        key: 'resultType',
        width: '20%',
        sorter: (a, b) => a.resultType?.localeCompare(b.resultType),
      },
      {
        title: translate('funcDescr'),
        dataIndex: 'description',
        key: 'description',
        width: '50%',
        sorter: (a, b) => a.description?.localeCompare(b.description),
      },
    ],
    [translate]
  );

  const dataSource = useMemo(() => {
    let tempData = functions;

    if (search) {
      const byName = tempData.filter((f) =>
        f.functionName.toLowerCase().includes(search.toLowerCase())
      );

      const byDesc = tempData.filter((f) =>
        f.description.toLowerCase().includes(search.toLowerCase())
      );

      tempData = filterEqualByKey(byName.concat(byDesc), 'functionName');
    }

    return tempData.map((f) => ({
      ...f,
      key: f.functionId,
      dataIndex: f.functionId,
    }));
  }, [functions, search]);

  const saveData = useCallback(() => {
    onSave(
      functions.find((f) => f.functionId === selectedFuncIds[0]) as IFunction
    );
    closeOnSave && onClose();
  }, [selectedFuncIds, functions]);

  return (
    <Modal
      isOn={isVisible}
      onClose={onClose}
      modalProps={{
        type: modalProps?.type,
        title: modalProps?.title || '',
        icon: 'icon-dots',
        width: 60,
      }}
    >
      <div className="add-function">
        <div className="add-function__search">
          {/* <p className="add-function__info-func">{translate('infoFunc')}</p> */}
          <InputSearch
            className="add-function__search-field"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={translate('funcNameSearch')}
            background="#fafafc"
            bordered
          />
        </div>
        <div className="add-function__table modal__box">
          <SimpleTable
            columnsData={tableColumns}
            dataSource={dataSource}
            selectedRowKeys={selectedFuncIds}
            setSelectedRowKeys={(ids: Key[]) =>
              setSelectedFuncIds(
                ids.filter((id) => !selectedFuncIds.includes(id))
              )
            }
            scroll={{ y: 300 }}
            hideSelectAll
          />
        </div>
        <div className="add-function__btns btn-group-bottom">
          <Button
            kind="normal"
            onClick={saveData}
            disabled={!selectedFuncIds.length}
          >
            {translate('add')}
          </Button>
          <Button kind="normal" modifiers={['second']} onClick={onClose}>
            {translate('cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
