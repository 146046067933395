import React, { useContext } from 'react';
import { Menu, Dropdown, Radio } from 'antd';
import './Settings.scss';
import { Icon } from '@modules/icon';
import { useTranslationPath } from '@modules/languageProvider';
import { ThemeContext } from '@modules/themeProvider';

const Settings = () => {
  const translate = useTranslationPath('settings');
  const { theme, setTheme } = useContext(ThemeContext);

  const onChangeTheme = (value: 'default' | 'dark') => setTheme(value);

  const menu = (
    <Menu className="settings__menu">
      <Menu.SubMenu title={translate('theme')}>
        <Radio.Group
          onChange={(e) => onChangeTheme(e.target.value)}
          value={theme}
        >
          <Menu.Item>
            <Radio value="default">{translate('light')}</Radio>
          </Menu.Item>
          <Menu.Item>
            <Radio value="dark">{translate('dark')}</Radio>
          </Menu.Item>
        </Radio.Group>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <div className="settings">
          <Icon name="icon-settings" size={22} />
        </div>
      </Dropdown>
    </>
  );
};

export { Settings };
