import { IDiagramNodeLink, IDiagramNodePosition } from '@modules/development';

export const getNodeHandles = (
  aPos: IDiagramNodePosition,
  bPos?: IDiagramNodePosition
): IDiagramNodeLink => {
  if (!bPos) {
    return {
      source: 'sourceA',
      target: 'targetA',
    };
  }

  const { x: aX, y: aY } = aPos;
  const { x: bX, y: bY } = bPos;
  const BLOCK_WIDTH = 380;
  let source;
  let target;

  if (aX > bX && aY > bY) {
    if (aX - bX > BLOCK_WIDTH) {
      source = 'sourceC';
      target = 'targetC';
    } else {
      source = 'sourceD';
      target = 'targetD';
    }
  } else if (aX < bX && aY > bY) {
    if (aX - bX > -BLOCK_WIDTH) {
      source = 'sourceD';
      target = 'targetD';
    } else {
      source = 'sourceA';
      target = 'targetA';
    }
  } else if (aX < bX && aY < bY) {
    if (aX - bX > -BLOCK_WIDTH) {
      source = 'sourceB';
      target = 'targetB';
    } else {
      source = 'sourceA';
      target = 'targetA';
    }
  } else if (aX > bX && aY < bY) {
    if (aX - bX > BLOCK_WIDTH) {
      source = 'sourceC';
      target = 'targetC';
    } else {
      source = 'sourceB';
      target = 'targetB';
    }
  } else {
    source = 'sourceA';
    target = 'targetA';
  }

  return { source, target };
};
