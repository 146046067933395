import React from 'react';
import {
  ConnectionsActionsBar,
  ConnectionsTable,
  ConnectionsDataSources,
  ConnectionsModalDataSourceWrapper,
} from '@modules/connections/components';
import './Connections.scss';

export const Connections: React.FC = () => {
  return (
    <div className="connections">
      <ConnectionsActionsBar />
      <ConnectionsTable />
      <ConnectionsDataSources />
      <ConnectionsModalDataSourceWrapper />
    </div>
  );
};
