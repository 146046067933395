import React, { useEffect, useRef, useState } from 'react';
import { Input as AntdInput, InputRef } from 'antd';
import classnames from 'classnames';
import { Input, Button, IInput, Tooltip } from '@shared';
import { Icon } from '@modules/icon';

interface IInputWithButton extends IInput {
  icon?: string;
  error?: string;
  inputDisabled?: boolean;
  tooltip?: string;
}

export const InputWithButton: React.FC<IInputWithButton> = ({
  className,
  value,
  icon,
  label,
  error,
  size,
  bordered,
  inputDisabled,
  disabled,
  readOnly,
  placeholder,
  tooltip,
  onChange,
  onClick,
  onBlur,
}) => {
  const inputRef = useRef<InputRef>(null);
  const [shouldShowInputTooltip, setShouldShowInputTooltip] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        const scrollWidth = inputRef.current.input?.scrollWidth || 0;
        const offsetWidth = inputRef.current.input?.offsetWidth || 0;
        if (scrollWidth > offsetWidth) {
          return setShouldShowInputTooltip(true);
        }
        setShouldShowInputTooltip(false);
      }
    }, 100);
  }, [value, inputRef.current]);

  let buttonTemplate = (
    <Button
      className="input-ext__button"
      kind="normal"
      modifiers={['min-width', 'transparent']}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon name={icon ?? 'icon-edit'} />
    </Button>
  );

  if (tooltip) {
    buttonTemplate = (
      <Tooltip title={tooltip} placement="bottom" children={buttonTemplate} />
    );
  }

  let inputTemplate = (
    <Input
      ref={inputRef}
      placeholder={placeholder}
      size={size || 'large'}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      label={label}
      error={error}
      className="input-ext__with-button"
      suffix={buttonTemplate}
      disabled={disabled || inputDisabled}
      bordered={bordered}
      readOnly={readOnly}
    />
  );

  if (shouldShowInputTooltip) {
    inputTemplate = (
      <Tooltip title={value} placement="bottomLeft" children={inputTemplate} />
    );
  }

  return (
    <div className={classnames(className, 'input-ext__container')}>
      {inputTemplate}
    </div>
  );
};
