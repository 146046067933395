import { Key } from 'react';
import { IDevelopmentState } from '@modules/development/DevelopmentSlice';
import { IAction, RootState } from '@modules/store';
import {
  getSchemas as getSchemasList,
  selectAllSchemas,
  deleteSchema as doDeleteSchema,
} from '@modules/schema';

const reducers = {
  onSelectedSchemaRows(
    state: IDevelopmentState,
    action: IAction<{ keys: Key[] }>
  ) {
    state.schemas.selectedRowTable = action.payload.keys;
  },
  cancelAddSchema(state: IDevelopmentState) {
    state.schemas.addNewRequested = false;
  },
  addSchema(state: IDevelopmentState) {
    state.schemas.addNewRequested = true;
  },
};

export const selectSchemasSelectedRows = (state: RootState) => {
  return state.development.schemas.selectedRowTable;
};

export const selectAddNewRequested = (state: RootState) => {
  return state.development.schemas.addNewRequested;
};

export const selectSchemas = selectAllSchemas;

export const getSchemas = getSchemasList;
export const deleteSchema = doDeleteSchema;

export default reducers;
