import React, { FC } from 'react';
import { Menu, Dropdown } from 'antd';
import './DropdownMenu.scss';

const { Item, Divider } = Menu;

interface IDropdownMenu {
  id: string;
  items: DropdownMenuItem[];
}

type DropdownMenuItem = {
  title: string;
  danger?: boolean;
  isDivider?: boolean;
  onClick?: (...data: any[]) => void;
};

export const DropdownMenu: FC<IDropdownMenu> = ({ id, items, children }) => {
  const menu = () => (
    <Menu className="dropdown-menu">
      {items.map(({ title, danger, onClick, isDivider }, index) =>
        isDivider ? (
          <Divider key={`${id}-${index}`} />
        ) : (
          <Item
            key={`${id}-${index}`}
            className="dropdown-menu__item"
            danger={danger}
            onClick={() => onClick && onClick()}
          >
            {title}
          </Item>
        )
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu()} trigger={[`hover`]}>
      {children}
    </Dropdown>
  );
};
