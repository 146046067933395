import { Key } from 'react';

export const getSelectedRowsByKeys = <T extends { key: Key; children?: T[] }>(
  keys: Key[],
  rows: T[]
) => {
  let res: T[] = [];
  rows?.forEach((v) => {
    if (keys.includes(v.key)) {
      res = res.concat(v);
    }
    if (v.children) {
      res = res.concat(getSelectedRowsByKeys<T>(keys, v.children));
    }
  });
  return res;
};
