import React from 'react';
import classNames from 'classnames';
import { Button } from '@shared';
import { Icon } from '@modules/icon';
import { IModalBoxAction } from './interfaces';

interface IModalBox {
  children: React.ReactNode;
  className?: string;
  showHeader?: boolean;
  icon?: string;
  title?: React.ReactNode;
  actions?: IModalBoxAction[] | null;
}

export const ModalBox: React.FC<IModalBox> = ({
  children,
  className,
  showHeader,
  icon,
  title,
  actions,
}) => {
  return (
    <div className={classNames('modal__box', className)}>
      {showHeader && (
        <div className="modal__box-header">
          {icon && <Icon className="modal__box-icon" name={icon} />}
          <span className="modal__box-title">{title}</span>
          {actions && (
            <div className="modal__box-toolbar">
              {actions
                .map(({ icon, tooltip, disabled, onClick }, index) => (
                  <Button
                    key={`${title}-box-button-${index}`}
                    kind="icon"
                    tooltip={tooltip}
                    disabled={disabled}
                    onClick={onClick}
                    children={<Icon name={icon} />}
                  />
                ))
                // @ts-ignore
                .reduce((prev, current, index) => [
                  prev,
                  <div
                    key={`box-divider-${index}`}
                    className="modal__box-divider"
                  />,
                  current,
                ])}
            </div>
          )}
        </div>
      )}

      <div className="modal__box-content">{children}</div>
    </div>
  );
};

export default ModalBox;
