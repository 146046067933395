import { useEffect, useState } from 'react';
import { openModals } from '@modules/development/DevelopmentSlice';
import { useAppSelector } from '@modules/store';
import { useAdministrationSelector } from '@modules/administration';

export const useModalVisible = (type?: string) => {
  const openModal = useAppSelector(openModals);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    if (openModal[openModal.length - 1] === type && !isVisible)
      setIsVisible(true);
    else if (openModal[openModal.length - 1] !== type && isVisible) {
      setIsVisible(false);
    }
  }, [openModal]);
  return isVisible;
};

export const useAdministrationModalVisible = (type?: string) => {
  const { administrationOpenModals: openModal } = useAdministrationSelector();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    if (openModal[openModal.length - 1] === type && !isVisible)
      setIsVisible(true);
    else if (openModal[openModal.length - 1] !== type && isVisible) {
      setIsVisible(false);
    }
  }, [openModal]);
  return isVisible;
};
