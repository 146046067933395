import { IDiagramNode, IDataVariables } from '../../../../../types';

export enum QueryTypes {
  insert = 'INSERT',
  update = 'UPDATE',
}

export type NodeVariable = {
  columnName: string;
  dataType: string;
};

export interface ISaveDataVariable extends IDataVariables {
  nodeVariable: NodeVariable;
  key?: string;
  tableType?: TableTypes;
}

export interface ISaveDataProperies {
  dataProviderUuid: string;
  tableName: string;
  queryType: QueryTypes;
  inputVariablesUpdateMapping: ISaveDataVariable[];
  inputVariablesConditionMapping: ISaveDataVariable[];
}

export type SaveDataNode = IDiagramNode<ISaveDataProperies>;

export type OpenedModal =
  | {
      typeModal: 'add-attribute';
      key?: string;
      tableType: TableTypes;
    }
  | { typeModal: 'add-data-tables' }
  | {
      typeModal: 'add-element';
      key: string;
      variableName: string;
      tableType: TableTypes;
    }
  | { typeModal: 'confirmSave'; contentText: string }
  | null;

export enum TableTypes {
  update = 'update',
  condition = 'condition',
}

export type InvalidData = {
  inputs: { [key: string]: string };
  mappingVariables: { [key: string]: { [key: string]: string } };
  conditionVariables: { [key: string]: { [key: string]: string } };
};
