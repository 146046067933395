import React from 'react';
import { Collapse } from 'antd';
import classnames from 'classnames';
import './Accordion.scss';
import { Icon } from '@modules/icon';

const { Panel } = Collapse;

interface IAccordion {
  title: string;
  icon?: string;
  additionalHeaderElement?: React.ReactNode;
  className?: string;
}
const Accordion: React.FC<IAccordion> = ({
  title,
  icon,
  additionalHeaderElement,
  className,
  children,
}) => {
  return (
    <div className={classnames('accordion', className)}>
      <Collapse defaultActiveKey={['1']} ghost expandIconPosition="right">
        <Panel
          header={
            <div className="accordion__header">
              {icon && <Icon name={`icon-${icon}`} />}
              {title}
              {additionalHeaderElement && additionalHeaderElement}
            </div>
          }
          key="1"
        >
          {children}
        </Panel>
      </Collapse>
    </div>
  );
};

export { Accordion };
