import { VFC } from 'react';
import classNames from 'classnames';
import type { Message } from '../interfaces';

interface IExtServiceCallSettingsMessage {
  message: Message;
}

export const ExtServiceCallSettingsMessage: VFC<IExtServiceCallSettingsMessage> =
  ({ message }) => {
    return (
      message && (
        <div
          className={classNames(
            'external-service-call-settings-modal__status',
            message.status === 'error' &&
              'external-service-call-settings-modal__status--error',
            message.status === 'success' &&
              'external-service-call-settings-modal__status--success'
          )}
        >
          <span>{message.text}</span>
        </div>
      )
    );
  };
