import { ColumnsType } from 'antd/lib/table';
import { IToolbarItem } from '@shared';
import { useTranslation } from '@modules/languageProvider';
import { IEnvironmentDataItem } from '../types/interface';
import { getTranslation } from '../utils';
import { useEnvironmentsSelector } from './useEnvironmentsSelector';
import { useEnvironmentsAction } from './useEnvironmentsAction';
import { Checkbox } from 'antd';

export const useEnvironmentsTable = (onAddClick: () => void) => {
  const { t } = useTranslation();

  const { data, selectedRowKeys, rowKeys } = useEnvironmentsSelector();
  const { setSelectedRowKeys } = useEnvironmentsAction();

  const actions: IToolbarItem[] = [
    { isDivider: true },
    {
      icon: 'icon-add',
      tooltip: t('actions.add'),
      onClick: () => onAddClick(),
    },
    { icon: 'icon-delete', tooltip: t('actions.delete') },
    { icon: 'icon-refresh', tooltip: t('actions.refresh') },
  ];

  const columns: ColumnsType<IEnvironmentDataItem> = [
    {
      title: t(getTranslation('table.name')),
      dataIndex: 'name',
      width: '15%',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t(getTranslation('table.integrationUrl')),
      dataIndex: 'integrationUrl',
      width: '25%',
      sorter: (a, b) => a.integrationUrl.localeCompare(b.integrationUrl),
    },
    {
      title: t(getTranslation('table.kafkaUrl')),
      dataIndex: 'kafkaUrl',
      width: '15%',
      sorter: (a, b) => a.kafkaUrl.localeCompare(b.kafkaUrl),
    },
    {
      title: t(getTranslation('table.streamingPlatformUrl')),
      dataIndex: 'streamingPlatformUrl',
      width: '25%',
      sorter: (a, b) =>
        a.streamingPlatformUrl.localeCompare(b.streamingPlatformUrl),
    },
    {
      title: t(getTranslation('table.defaulEnvironment')),
      dataIndex: 'defaulEnvironment',
      width: '20%',
      align: 'center',
      render: (value: boolean) => (
        <Checkbox
          checked={value}
          onChange={(e) => console.log(e.target.checked)}
        />
      ),
    },
  ];

  return {
    actions,
    columns,
    data,
    selectedRowKeys,
    rowKeys,
    setSelectedRowKeys,
  };
};
