import React from 'react';
import { ISelect, Select } from '@shared';
import {
  ALL_CONNECTIONS,
  DBMS,
  MESSAGE_BROKERS,
  EXTERNAL_SERVICES,
} from '@modules/connections/constants';
import {
  useConnectionsActions,
  useConnectionsSelector,
} from '@modules/connections/hooks';
import './ConnectionsSelectType.scss';
import { TConnectionType } from '@modules/connections/types/connectionsTypes';

const connectionTypesOptions: ISelect['options'] = [
  {
    value: ALL_CONNECTIONS,
    translation: 'connections.types.allConnections',
  },
  {
    value: DBMS,
    translation: 'connections.types.DBMS',
  },
  {
    value: EXTERNAL_SERVICES,
    translation: 'connections.types.outputServices',
  },
  {
    value: MESSAGE_BROKERS,
    translation: 'connections.types.messageBrokers',
  },
];

export const ConnectionsSelectType: React.FC = () => {
  const { activeConnectionType } = useConnectionsSelector();
  const { setActiveConnectionType } = useConnectionsActions();

  const selectType = (value: TConnectionType) => {
    setActiveConnectionType(value);
  };

  return (
    <Select<TConnectionType>
      value={activeConnectionType}
      options={connectionTypesOptions}
      onChange={selectType}
    />
  );
};
