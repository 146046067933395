import React, { FC, Key, ReactNode } from 'react';
import { IToolbarItem, Toolbar } from '@shared';

interface IDataSourcesToolbar {
  actions: IToolbarItem[];
  search: ReactNode;
  rowKeys: Key[];
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
}

export const DataSourcesToolbar: FC<IDataSourcesToolbar> = ({
  actions,
  search,
  rowKeys,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  return (
    <div className="data-sources__row">
      <Toolbar
        search={search}
        data={actions}
        onCheckboxAllChange={() => {
          setSelectedRowKeys(
            selectedRowKeys.length === rowKeys.length ? [] : rowKeys
          );
        }}
        CheckboxAllChecked={selectedRowKeys.length === rowKeys.length}
        CheckboxAllIndeterminate={
          selectedRowKeys.length !== rowKeys.length &&
          selectedRowKeys.length !== 0
        }
        isShowCheckboxAll
      />
    </div>
  );
};
