import React, { Key, useEffect, useState, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  ConfirmModal,
  IConfirmModalProps,
  InputSearch,
  Portal,
  SimpleTable,
  Toolbar,
  Tooltip,
  useSearch,
} from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import {
  customCodeApi,
  CustomCodeCreateUserVersion,
  CustomCodeUpdateUserVersion,
  ICustomCode,
  ICustomCodeVersion,
} from '@modules/development';
import {
  deleteCustomCode,
  selectCurrentObject,
  setReadonly,
} from '@modules/development/DevelopmentSlice';
import { CUSTOM_CODES_CORE_PATH } from '@modules/development/modules/CustomCodesList/routing/сustomCodesConstants';
import {
  UserVersionModal,
  UserVersionSaveData,
} from '@modules/development/modules/Diagrams/components/DiagramBlocks';
import { useAppDispatch, useAppSelector } from '@modules/store';

export type TableData = {
  key: Key;
  versionName: string;
  id: string;
  versionType: string;
  changeDt: string;
  lastChangeByUser: string;
  innerObjecttSaveFlag: boolean;
  dependentObjectFlag: boolean;
  rootObjectId: string;
  data: { versionId: string } & CustomCodeUpdateUserVersion;
};

type OpenedModal =
  | { type: 'createUserVersion'; data?: CustomCodeUpdateUserVersion }
  | ({ type: 'deleteUserVersion'; onOk: () => void } & IConfirmModalProps);

const CustomCodeVersions: VFC = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslationPath('development.customCode.versions');
  const history = useHistory();

  const customCode = useAppSelector(selectCurrentObject) as ICustomCode;

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();
  const [selectedVersion, setSelectedVersion] = useState<TableData[]>();
  const [search, setSearch] = useState<string>('');
  const [versions, setVersions] = useState<ICustomCodeVersion[]>();
  const [openedModal, setOpenedModal] = useState<OpenedModal | null>(null);

  const [, getRefProps, getInputProps, filterSearch] = useSearch({
    search,
    setSearch,
  });

  const onDummyClick = async () => {
    console.log('in progress...');
  };

  const fetchVersions = async (scriptId: string) => {
    const versions = await customCodeApi.getCustomCodeVersions(scriptId);
    setVersions(versions);
  };

  const onDeleteVersionClick = async (ids: Key[]) => {
    await Promise.all(ids.map((id) => dispatch(deleteCustomCode(id))));
    customCode.scriptId && fetchVersions(customCode.scriptId);
  };

  const onSaveVersionClick = async (saveData: UserVersionSaveData) => {
    const body: CustomCodeCreateUserVersion = {
      versionName: saveData.versionName,
      versionDescription: saveData.versionDescription,
      description: customCode.description,
      scriptJson: JSON.stringify(customCode.scriptJson),
      scriptName: customCode.title,
      variables: customCode.variables,
    };

    await customCodeApi.createCustomCodeVersion(customCode.scriptId, body);
    fetchVersions(customCode.scriptId);
  };

  const onUpdateUserVersionClick = async (saveData: UserVersionSaveData) => {
    if (saveData.versionName && saveData.versionId) {
      const body: CustomCodeUpdateUserVersion = {
        versionName: saveData.versionName,
        versionDescription: saveData.versionDescription,
      };

      await customCodeApi.editCustomCodeVersion(saveData.versionId, body);
      fetchVersions(customCode.scriptId);
    }
  };

  const onOpenVersionClick = async (selectedVersion: TableData) => {
    dispatch(setReadonly({ readonly: true }));
    history.push({
      pathname: `${CUSTOM_CODES_CORE_PATH}/${selectedVersion.key}`,
      state: {
        readonly: true,
        id: selectedVersion.key,
        versionName: selectedVersion.versionName,
      },
    });
  };

  const render = (value: any) => <Tooltip title={value}>{value}</Tooltip>;

  const actions = [
    { isDivider: true },
    {
      icon: 'icon-delete',
      tooltip: translate('toolbar.delete'),
      disabled:
        !selectedRowKeys?.length ||
        selectedVersion?.some((v) => v.versionType === 'LATEST'),
      onClick: () =>
        selectedRowKeys?.length &&
        setOpenedModal({
          type: 'deleteUserVersion',
          onOk: () => selectedVersion && onDeleteVersionClick(selectedRowKeys),
        }),
    },
    {
      icon: 'icon-refresh',
      tooltip: translate('toolbar.refresh'),
      disabled: !customCode.scriptId,
      onClick: () => fetchVersions(customCode.scriptId),
    },
    { isDivider: true },
    {
      icon: 'icon-version_actual',
      tooltip: translate('toolbar.selectVersion'),
      disabled: !selectedRowKeys?.length || selectedRowKeys?.length > 1,
      onClick: () => onDummyClick(),
    },
    {
      icon: 'icon-version_save',
      tooltip: translate('toolbar.saveVersion'),
      disabled: !customCode.scriptId,
      onClick: () => setOpenedModal({ type: 'createUserVersion' }),
    },
    {
      icon: 'icon-version_open',
      tooltip: translate('toolbar.openVersion'),
      disabled: !selectedRowKeys?.length || selectedRowKeys?.length > 1,
      onClick: () => {
        if (selectedVersion?.[0]) onOpenVersionClick(selectedVersion[0]);
      },
    },
  ];

  useEffect(() => {
    customCode.scriptId && fetchVersions(customCode.scriptId);
  }, [customCode.scriptId]);

  const columns: ColumnsType<TableData> = [
    {
      key: 'versionName',
      dataIndex: 'versionName',
      title: translate('objectVersion'),
      ellipsis: true,
      showSorterTooltip: { title: translate('objectVersion') },
      sorter: (a, b) => a.versionName.localeCompare(b.versionName),
      render,
    },
    {
      key: 'versionType',
      dataIndex: 'versionType',
      title: translate('versionType'),
      ellipsis: true,
      showSorterTooltip: { title: translate('versionType') },
      sorter: (a, b) => a.versionType.localeCompare(b.versionType),
      render,
    },
    {
      key: 'changeDt',
      dataIndex: 'changeDt',
      title: translate('lastSaveDate'),
      ellipsis: true,
      showSorterTooltip: { title: translate('lastSaveDate') },
      sorter: (a, b) => a.changeDt.localeCompare(b.changeDt),
      render,
    },
    {
      key: 'lastChangeByUser',
      dataIndex: 'lastChangeByUser',
      title: translate('theUserWhoSavedTheChanges'),
      ellipsis: true,
      showSorterTooltip: { title: translate('theUserWhoSavedTheChanges') },
      sorter: (a, b) => a.lastChangeByUser.localeCompare(b.lastChangeByUser),
      render,
    },
    {
      key: 'dependentObjectFlag',
      dataIndex: 'rootObjectId',
      title: translate('dependentObjectAttribute'),
      ellipsis: true,
      showSorterTooltip: { title: translate('dependentObjectAttribute') },
      align: 'center',
      sorter: (a, b) =>
        Number(a.dependentObjectFlag) - Number(b.dependentObjectFlag),
      render: (value) => <Checkbox checked={!!value} />,
    },
    {
      key: 'rootObjectId',
      dataIndex: 'rootObjectId',
      title: translate('idOfTheRootDiagramForTheDependentObject'),
      ellipsis: true,
      showSorterTooltip: {
        title: translate('idOfTheRootDiagramForTheDependentObject'),
      },
      sorter: (a, b) => a.rootObjectId.localeCompare(b.rootObjectId),
      render,
    },
  ];

  const data: TableData[] = filterSearch<TableData>(
    (versions || []).map((v) => ({
      key: v.versionId,
      versionName: v.versionName || '',
      id: v.versionId,
      versionType: v.versionType,
      changeDt: v.changeDt,
      lastChangeByUser: v.lastChangeByUser,
      innerObjecttSaveFlag: !!v.rootObjectVersionId,
      dependentObjectFlag: !!v.rootObjectVersionId,
      rootObjectId: v.rootObjectVersionId,
      data: {
        versionId: v.versionId,
        versionName: v.versionName,
        versionDescription: v.versionDescription,
      },
    })),
    'versionName'
  );

  return (
    <>
      <div className="diagram-versions" {...getRefProps()}>
        <Portal id="additional-buttons">
          <Toolbar className="diagram-data__toolbar" data={actions} />
        </Portal>
        <Portal id="toolbarSearch">
          <InputSearch {...getInputProps()} width={420} />
        </Portal>

        <div className="diagram-versions__table">
          <SimpleTable
            columnsData={columns}
            dataSource={data}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={(key, selectedRows: TableData[]) => {
              setSelectedRowKeys(key);
              setSelectedVersion(selectedRows);
            }}
            onDoubleClick={({ data, versionType }: TableData) => {
              if (versionType === 'USER_LOCAL') {
                setOpenedModal({ type: 'createUserVersion', data });
              }
            }}
          />
        </div>
      </div>

      {openedModal?.type === 'createUserVersion' && (
        <UserVersionModal
          modalProps={{ type: openedModal.type }}
          onSave={onSaveVersionClick}
          onClose={() => setOpenedModal(null)}
          hideCheckbox
          closeOnSave
          data={openedModal.data}
          onUpdate={onUpdateUserVersionClick}
        />
      )}

      {openedModal?.type === 'deleteUserVersion' && (
        <ConfirmModal
          modalProps={{
            type: openedModal.type,
            contentText: translate('confirmDeleteUserVersion'),
          }}
          onOk={openedModal.onOk}
          onClose={() => setOpenedModal(null)}
        />
      )}
    </>
  );
};

export default CustomCodeVersions;
