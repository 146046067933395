import { IDataTableList } from '@modules/development';
import { IAction } from '@modules/store';
import { IDevelopmentState } from '../../DevelopmentSlice';

const reducer = {
  customCodeSeeds(state: IDevelopmentState, action: IAction) {
    const { customCodes } = action.payload;
    state.customCode.data = customCodes;
  },
  customCodeUpdate(state: IDevelopmentState, action: IAction) {
    const { customCodeKey, title } = action.payload;
    const existingCustomCode = state.customCode.data.find(
      (code: IDataTableList) => code.key === customCodeKey
    );
    if (existingCustomCode) {
      existingCustomCode.title = title;
    }
  },
  addCustomCode(state: IDevelopmentState, action: IAction) {
    const { key } = action.payload;

    // TODO устанавливается на сервере?
    const date = new Date();
    const options: any = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timezone: 'UTC',
    };
    const newCustomCode = {
      key: key,
      title: '',
      draft: true,
      isSaved: true,
      isReadonly: false,
      code: key,
      location: '/CustomCode/',
      state: 'В разработке',
      dateOfChange: `${date.toLocaleString(
        'ru',
        options
      )} ${date.toLocaleTimeString()}`, // '01.05.2021 13:41:56'
      user: 'User_1',
      createUser: 'User_1',
      version: '',
      description: '',
      type: 'customCode',
      versionId: '',
      scriptId: '',
      variables: [],
      scriptJson: {
        name: '',
        scriptText: '',
        // inputVariables: [],
        // outputVariables: [],
      },
    };
    state.openObjects.push(newCustomCode);
    state.openTabs.push({
      key: key,
      version: key,
      text: '',
      type: 'customCode',
      icon: 'icon-custom_code',
    });
  },
  removeCustomCode(state: IDevelopmentState, action: IAction) {
    const { key } = action.payload;
    state.customCode.data = state.customCode.data.filter(
      (item: IDataTableList) => item.key !== key
    );
  },
  onSelectedCustomCodeRow(state: IDevelopmentState, action: IAction) {
    const { keys } = action.payload;
    state.customCode.selectedRowTable = keys;
  },
  onChangeCustomCodeSearch(state: IDevelopmentState, action: IAction) {
    const { search } = action.payload;
    state.customCode.search = search;
  },
  onChangeCustomCodeSort(state: IDevelopmentState, action: IAction) {
    const { sort } = action.payload;
    state.customCode.sort = sort;
  },
};

export default reducer;
