import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal as ModalAntd } from 'antd';
import classNames from 'classnames';
import './Modal.scss';
import { TabPanel } from '@shared';
import { Icon } from '@modules/icon';
import { addOpenModal, openModals } from '../../development/DevelopmentSlice';
// import { addAdministrationOpenModal } from '@modules/administration/AdministrationSlice';
import { IModalProps, IModalTab } from './interfaces';
import { useUserGuide } from '@modules/userGuide';
import {
  useAdministrationSelector,
  useAdministrationAction,
} from '@modules/administration';

interface IModal {
  isOn: boolean;
  modalProps: IModalProps;
  children: React.ReactNode;
  modalTabs?: IModalTab[];
  page?: 'development' | 'administration' | string;
  onClose: () => void;
  onTabSelect?: (key: string) => void;
}

export const Modal: React.FC<IModal> = ({
  isOn,
  modalProps,
  children,
  modalTabs,
  page = 'development',
  onClose,
  onTabSelect,
}) => {
  const dispatch = useDispatch();
  const openModal = useSelector(openModals);
  const { administrationOpenModals } = useAdministrationSelector();
  const { addAdministrationOpenModal } = useAdministrationAction();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [isScrolled, setIsScrolled] = useState(true);
  const [getLink] = useUserGuide();

  const {
    helpKey,
    isCloseIconHiding,
    isHeaderBorderHiding,
    activeTab,
    title,
    footer,
    header,
    icon,
    width,
    disabledTabs,
  } = modalProps;

  const modalWidth = typeof width === 'number' ? `${width}%` : width;

  const renderModalFooter = useCallback(() => {
    if (!footer) return null;
    return footer;
  }, [modalTabs, activeTab, footer]);

  const renderModalHeader = useCallback(() => {
    if (!header) return null;
    return header;
  }, [modalTabs, activeTab, header]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const {
      currentTarget: { scrollHeight, scrollTop, clientHeight },
    } = event;

    if (scrollHeight - scrollTop === clientHeight) setIsScrolled(true);
    else setIsScrolled(false);
  };

  useEffect(() => {
    if (!isOn) return;
    const event = { currentTarget: modalRef.current };
    handleScroll(event as React.UIEvent<HTMLDivElement>);
  }, [isOn]);

  useEffect(() => {
    if (
      page === 'development' &&
      openModal[openModal.length - 1] !== modalProps.type &&
      modalProps.type
    ) {
      dispatch(addOpenModal({ modalInfo: modalProps.type }));
    } else if (
      page === 'administration' &&
      administrationOpenModals[administrationOpenModals.length - 1] !==
        modalProps.type &&
      modalProps.type
    ) {
      addAdministrationOpenModal({ modalInfo: modalProps.type });
    }
  }, []);

  return (
    <ModalAntd
      className={classNames(footer && !isScrolled && 'not-scrolled')}
      visible={isOn}
      title={
        <div>
          <div
            className={classNames(
              'modal__header',
              !isHeaderBorderHiding && 'modal__header_border'
            )}
          >
            {icon && <Icon className="modal__img" name={icon} />}
            {title && <h2 className="modal__title">{title}</h2>}
            {modalTabs && (
              <TabPanel
                level={3}
                active={activeTab || ''}
                disabledKeys={disabledTabs}
                onClick={(event, selectedValue) =>
                  onTabSelect && onTabSelect(selectedValue)
                }
                className="tabs-with-container"
                items={modalTabs.map((tab) => ({
                  ...tab,
                }))}
              />
            )}
            <div className="modal__actions">
              {helpKey && (
                <div className="img__close">
                  {getLink(helpKey, <Icon name="icon-help" />)}
                </div>
              )}
              {!isCloseIconHiding && (
                <Icon
                  className="img__close"
                  name="icon-close"
                  onClick={onClose}
                />
              )}
            </div>
          </div>
          {renderModalHeader()}{' '}
        </div>
      }
      footer={renderModalFooter()}
      width={modalWidth}
      centered
    >
      <div
        className={`modal ${modalProps.className && modalProps.className}`}
        onScroll={handleScroll}
        ref={modalRef}
      >
        <div className="modal__overlay"></div>
        <div className="modal__container">
          <div className="modal__body">{children}</div>
        </div>
      </div>
    </ModalAntd>
  );
};
