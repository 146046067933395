import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ITypeMapData, IModalProps } from '@modules/development';
import { Modal, useModalVisible } from '@shared';
import { AddElement } from './parts/AddElement/AddElement';
import { AddNewElement } from './parts/AddNewElement/AddNewElement';
import { IAddElement } from './interfaces';
import { removeOpenModal } from '@modules/development/DevelopmentSlice';
import { addComplexTypeAttributes } from '@modules/complexType';
import { selectRoles, rolesList } from '@modules/services';
import { useAppSelector } from '@modules/store';

interface IAddElementModal extends IAddElement {
  modalProps: IModalProps | null;
}

export const AddElementModal: FC<IAddElementModal> = ({
  modalProps,
  inputVariables,
  onClose,
  onAddLocalVariable,
  ...addElemProps
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isVisibleAddElem = useModalVisible(modalProps?.type);
  const isVisibleAddNew = useModalVisible('addNewElem');
  const isVisibleAddRelated = useModalVisible('addNewElemRelatedTo');

  const [openAddNew, setOpenAddNew] = useState<boolean>(false);
  const [openAddRelatedTo, setOpenAddRelatedTo] = useState<boolean>(false);
  const roles = useAppSelector(selectRoles);
  const [relatedTo, setRelatedTo] = useState<{
    name: string;
    rootId: string;
    data?: ITypeMapData;
  }>();

  const closeAddNew = useCallback(() => {
    dispatch(removeOpenModal({}));
    setOpenAddNew(false);
    setRelatedTo(undefined);
  }, [setOpenAddNew, setRelatedTo]);

  const closeAddRelatedTo = useCallback(() => {
    dispatch(removeOpenModal({}));
    setOpenAddRelatedTo(false);
  }, [setOpenAddRelatedTo]);

  return (
    <>
      <Modal
        isOn={isVisibleAddElem}
        onClose={onClose}
        modalProps={{
          title:
            modalProps?.title ||
            t('development.diagram.diagram.modals.addElem.title'),
          type: modalProps?.type,
          icon: modalProps?.icon || 'icon-dots',
          width: modalProps?.width || '462px',
          helpKey: 'add-element',
        }}
      >
        <AddElement
          {...addElemProps}
          inputVariables={inputVariables}
          onOpenAddNew={() => setOpenAddNew(true)}
          onClose={onClose}
        />
      </Modal>
      {openAddNew && (
        <Modal
          isOn={openAddNew && isVisibleAddNew}
          onClose={closeAddNew}
          modalProps={{
            title: t('development.diagram.diagram.modals.addNewElem.title'),
            type: 'addNewElem',
            icon: 'icon-dots',
            width: '1080px',
          }}
        >
          <AddNewElement
            onEditRelatedToClick={() => setOpenAddRelatedTo(true)}
            onClose={closeAddNew}
            relatedTo={relatedTo}
            onSave={(data, inner) => {
              if (inner) {
                onAddLocalVariable &&
                  onAddLocalVariable(data, relatedTo?.rootId || '');
              } else {
                roles.includes(rolesList.COMPLEX_TYPE_READ_MAP) &&
                  dispatch(
                    addComplexTypeAttributes({
                      parentTypeId: relatedTo?.data?.typeId as string,
                      data,
                    })
                  );
              }
            }}
            closeOnSave
          />
        </Modal>
      )}
      {openAddRelatedTo && (
        <Modal
          isOn={openAddRelatedTo && isVisibleAddRelated}
          modalProps={{
            type: 'addNewElemRelatedTo',
            title: t(
              'development.diagram.diagram.modals.addNewElem.chooseRelatedTo'
            ),
            width: '462px',
            icon: 'icon-dots',
          }}
          onClose={closeAddRelatedTo}
        >
          <AddElement
            inputVariables={inputVariables}
            disablePrimitive
            hidePrimitive
            closeOnSave
            hideAddNewBtn
            onSave={({ chainName, typesChain }) => {
              // todo проверить приходил ли versionId

              setRelatedTo({
                name: chainName,
                data: typesChain[typesChain.length - 1],
                rootId: typesChain[0].typeId,
              });
            }}
            onClose={closeAddRelatedTo}
          />
        </Modal>
      )}
    </>
  );
};
