import React, { forwardRef } from 'react';
import {
  InputNumber as AntdInput,
  InputNumberProps as AntdInputProps,
} from 'antd';
import classnames from 'classnames';
import './Input.scss';
import { Tooltip } from '@modules/shared';

export interface IInputNumber extends AntdInputProps {
  label?: string;
  error?: string;
}

export const InputNumber: React.FC<IInputNumber> = forwardRef((props) => {
  const { required, className, size, label, error } = props;

  return (
    <div
      className={classnames(
        className,
        'input-ext__container',
        typeof error === 'string' && 'input-ext__container--error'
      )}
    >
      <div className="input-ext__top">
        {label && <span className="input-ext__label">{label}</span>}
        {required && <span className="input-ext__required">*</span>}
      </div>

      <Tooltip error={!!error} title={error}>
        <AntdInput
          {...props}
          required={required}
          size={size || 'large'}
          className="input-ext"
        />
      </Tooltip>
    </div>
  );
});
