import React, { FC, useContext, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import enUS from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import LanguageProvider, { LanguageContext } from '@modules/languageProvider';
import ThemeProvider from '@modules/themeProvider';

const locales = { ru: ruRU, en: enUS };

export const DecisionAppConfigProvider: FC = ({ children }) => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    dayjs.locale('ru');
    dayjs.extend(localizedFormat);
  }, []);

  return (
    <ThemeProvider>
      <LanguageProvider>
        <ConfigProvider locale={locales[language]}>{children}</ConfigProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
};
