import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import './DiagramsWithAggregate.scss';
import { InputSearch, Portal, SimpleTable, Toolbar } from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import { useAppSelector } from '@modules/store';
import { selectCurrentObject } from '@modules/development/DevelopmentSlice';
import {
  aggregatesApi,
  IAggregate,
  AggregateRelations,
  AggregateRelation,
} from '@modules/development';
import { tryCatchDecorator } from '@modules/development/utils';
import { useDispatch } from 'react-redux';

interface IDiagramsWithAggregate {}

const DiagramsWithAggregate: React.FC<IDiagramsWithAggregate> = () => {
  const translate = useTranslationPath('development.aggregates.aggregate');

  const { versionId } = useAppSelector(selectCurrentObject) as IAggregate;

  const [search, setSearch] = useState<string>('');
  const [relations, setRealations] = useState<AggregateRelations>();
  const dispatch = useDispatch();

  const fetchRelations = async (versionId: string) => {
    const relations = await tryCatchDecorator(dispatch, async () => {
      return await aggregatesApi.getRelations(versionId);
    });

    setRealations(relations);
  };

  const renderAggregateType = (value: 'READ' | 'CALC') => {
    switch (value) {
      case 'READ':
        return translate('diagramReadId');
      case 'CALC':
        return translate('diagramCalcId');
    }
  };

  const sorterColumn = (a: any, b: any) => a.title.localeCompare(b.title);

  const actions = [
    {
      isDivider: true,
    },
    {
      icon: 'icon-refresh',
      disabled: !versionId,
      onClick: () => fetchRelations(versionId),
    },
  ];

  const columns: ColumnsType<AggregateRelation> = [
    {
      title: translate('type'),
      dataIndex: 'AggregateType',
      key: 'AggregateType',
      width: '20%',
      ellipsis: { showTitle: false },
      sorter: sorterColumn,
      render: renderAggregateType,
    },
    {
      title: translate('diagramName'),
      dataIndex: 'diagramName',
      key: 'diagramName',
      width: '15%',
      ellipsis: { showTitle: false },
      sorter: sorterColumn,
      render: (value: string) => {
        return <div className="code">{value}</div>;
      },
    },
    {
      title: translate('version'),
      dataIndex: 'versionName',
      key: 'versionName',
      width: '20%',
      ellipsis: { showTitle: false },
      sorter: sorterColumn,
      render: (value: string) => {
        return <div className="version">{value}</div>;
      },
    },
    {
      title: translate('versionType'),
      dataIndex: 'diagramVersionId',
      key: 'diagramVersionId',
      width: '20%',
      ellipsis: { showTitle: false },
      sorter: sorterColumn,
    },
  ];

  const data: AggregateRelation[] = relations || [];

  useEffect(() => {
    versionId && fetchRelations(versionId);
  }, [versionId]);

  return (
    <div className="diagrams-with-aggregate">
      <div className="diagrams-with-aggregate__top-content">
        <Portal id="additional-buttons">
          <Toolbar className="diagram-data__toolbar" data={actions} />
        </Portal>
        {
          <Portal
            children={
              <div className="toolbar-actions">
                <div className="toolbar__search">
                  <InputSearch
                    bordered
                    background="#fff"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            }
            id="toolbarSearch"
          />
        }
        <SimpleTable
          className="diagrams-with-aggregate____simple-table"
          columnsData={columns}
          dataSource={data}
          size="large"
        />
      </div>
    </div>
  );
};

export { DiagramsWithAggregate };
