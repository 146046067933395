import { Key } from 'react';
import { ITabPanelItem } from '@shared';

export type TableData = {
  key: Key;
  versionName: string;
  id: string;
  versionType: string;
  lastChangeDt: string;
  lastChangeByUser: string;
  innerObjecttSaveFlag: boolean;
  dependentObjectFlag: boolean;
  rootObjectId: string;
  versionId: string;
};

export const tabs: ITabPanelItem[] = [
  {
    key: '0',
    text: 'Элементы типа',
    translation: 'development.complexTypes.tabs.elements',
    path: '/elements',
  },
  {
    key: '1',
    text: 'Версии',
    translation: 'development.complexTypes.tabs.versions',
    path: '/versions',
  },
];
