import { Key } from 'react';
import { Edge, Node, XYPosition } from 'react-flow-renderer';
import { IObject, IObjects, ITesting } from './';

export interface IDiagramSave {
  diagramName: string;
  diagramDescription: string;
}

export type DiagramCreate = {
  diagramName: string;
  diagramDescription?: string;
  diagramId: string;
  versionId: string;
  errorResponseFlag: boolean;
};

export interface IDiagramBase {
  versionId: string;
  diagramId: string;
  createByUser: string;
  lastChangeByUser: string;
  createDt: string;
  changeDt: string;
  diagramName: string;
  diagramDescription: string;
  inOutParameters?: InOutParameter[];
}

export interface historyElem {
  type: string;
  nodes?: any;
  link?: IDiagramLinkBase | string;
}

export interface IDiagram extends IObject, IDiagramBase {
  versionName?: string;
  nodes?: DiagramNodes;
  links?: DiagramLinks;
  errorResponseFlag: boolean;
  testing: ITesting;
  meta?: { diagramDataSelectedRowKeys?: Key[] };
  historyChanges: historyElem[];
}

export type DiagramNodes = { [key: string]: IDiagramNode };
export type DiagramLinks = { [key: string]: IDiagramLink };

export interface IDiagramNodePosition {
  x: number;
  y: number;
}

export interface IDiagramNodeLinks {
  [key: string]: IDiagramNodeLink;
}

export interface IDiagramNodeLink {
  source: string;
  target: string;
}

// // // // // // //

export interface IDiagramNodeBase<T = any> {
  nodeTypeId: DiagramNodeTypes;
  nodeName: string;
  nodeDescription?: string;
  properties: T;
  metaInfo: IDiagramNodeMetaInfo;
}

export interface IDiagramNodeCreate<T = any> extends IDiagramNodeBase<T> {
  diagramId: string;
  diagramVersionId: string;
}

export interface IDiagramNode<T = any> extends IDiagramNodeBase<T> {
  nodeId: string;
  validFlag?: boolean;
  inputLinks?: string[];
  outputLinks?: string[];
  inputVariables?: IDataVariables[];
  nodeAddedVariables?: NodeAddedVariables;
}

export interface IDiagramNodeUpdate<T = any> extends IDiagramNode<T> {
  diagramId: string;
  diagramVersionId: string;
}

export interface IDiagramLinkBase {
  prevNodeId: string;
  nextNodeId: string;
  metaInfo: IDiagramNodeLink;
}

export interface IDiagramLinkCreate extends IDiagramLinkBase {
  diagramId: string;
  diagramVersionId: string;
}

export interface IDiagramLink extends IDiagramLinkBase {
  linkId: string;
}

// // // // // // //

export interface IDiagramNodeMetaInfo {
  position: IDiagramNodePosition;
  blockInnerMeta?: string;
  validationMessages?: string[];
  shape?: IDiagramNodePosition;
  parentNode?: string;
  extent?: string;
  // type: string;
}

export interface IDataVariablesBase {
  variableId?: string;
  variableName: string;
  isComplex: boolean;
  typeId: string;
  isArray: boolean;
}

export interface IDataVariables extends IDataVariablesBase {
  variablePath?: string;
  variableRootId?: string;
}

export interface IDiagramData {
  inputVariables: IDiagramVariable[]; // входные параметры
  outputVariables: IDiagramVariable[]; // выходные параметры
  startNodes: string[]; // идентификаторы узлов начала диграммы
  nodes: { [key: string]: IDiagramNode };
  //
  historyChanges: { [key: string]: IDiagramNode }[];
}

export enum ParameterTypes {
  in = 'IN',
  out = 'OUT',
  inOut = 'IN_OUT',
}

export interface InOutParameter {
  parameterId: string;
  parameterVersionId: string;
  parameterName: string;
  typeId: string;
  arrayFlag: boolean;
  complexFlag: boolean;
  parameterType: ParameterTypes;
  defaultValue?: string;
  metaInfo: {
    orderNum: number;
    isExecuteStatus?: boolean;
  };
}

export interface IDiagramVariable extends IDataVariablesBase {
  defaultValue: string;
  //
  meta?: {
    key: string;
    orderNum: number;
    in: boolean;
    out: boolean;
  };
}

export enum DiagramNodeTypes {
  Sources = 1,
  Transformations,
  Receivers,
  SourcesDBMS = 107,
  ReceiversDBMS = 108,
  BrokerMessage = 0,
  SourcesKafka = 102,
  ReceiversKafka = 103,
  Filter = 105,
  Join = 106,
  Mapping = 104,
  Enrichment = 109,
  Aggregation = 110,
  Groovy = 111,
  PostgresCDC = 113,
  // TODO: Добавить реальный айди к Элай
  PostgresCDCApply = 112,
}

export interface ICreatedNewNode {
  type: string;
  nodeName: string;
  nodeType: DiagramNodeTypes;
  code: string;
}

export interface IGroupDiagram {
  id: string;
  title?: string;
  code?: string;
  translation?: string;
}

export interface IGroupDiagrams {
  id: string;
  title: string;
  data: IGroupDiagram[];
}

export interface ITypeMapData {
  variableId?: string;
  typeId: string;
  name: string;
  typeName: string;
  isComplex: boolean;
  isArray: boolean;
  attributes?: ITypeMapData[];
  versionId?: string;
}

export type CopiedNodes = { diagramId: string; nodes: IDiagramNode[] };
export type SelectedNodes = DiagramNode[];

export interface IDiagramObjects extends IObjects<IDiagram> {
  copiedNodes: CopiedNodes;
  selectedNodes: SelectedNodes;
  lastPosition: XYPosition;
}

export type DiagramNodeData = {
  title: string;
  translation: string;
  icon: string;
  type: string;
  nodeType: DiagramNodeTypes;
  connectedHandles: string[];
  isValid: boolean;
  errors: string[];
  shape: IDiagramNodePosition;
  nodeData: IDiagramNode;
  nodeColor?: string;
  parentTitle?: string;
};

export type DiagramNode = Node<DiagramNodeData>;

export type DiagramLinkData = {
  nodesDraggable: boolean;
};

export type DiagramLink = Edge<DiagramLinkData>;

export type NodeAddedVariables = { [key: string]: IDataVariables[] };

export type BlockInnerMeta = {
  isBlockValid?: boolean;
  blockErrors?: string[];
};

export interface IDiagramVersion {
  diagramId: string;
  versionId: string;
  createByUser: string;
  lastChangeByUser: string;
  createDt: string;
  changeDt: string;
  diagramName: string;
  diagramDescription: string;
  versionType: 'TEMP' | 'DEPLOYED' | 'LATEST' | 'USER_LOCAL' | 'USER_GLOBAL';
  versionName: string | null;
  versionDescription: string;
  rootObjectVersionId: string;
}

export type DiagramVersions = IDiagramVersion[];

export type DiagramUpdateUserVersion = {
  versionName: string;
  versionDescription?: string;
};

export type DiagramCreateUserVersion = DiagramUpdateUserVersion & {
  diagramId: string;
  versionId: string;
  diagramName: string;
  globalFlag: boolean;
  errorResponseFlag: boolean;
};

export type PossibleNodes = {
  nodeId: string;
  nodeName: string;
  nodeType: DiagramNodeTypes;
}[];
