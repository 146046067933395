import React from 'react';
import { Button, IModalProps, Modal } from '@shared';
import { useTranslationPath } from '@modules/languageProvider';
import './ApproveModal.scss';

export interface ApproveModalModalProps extends IModalProps {
  yesClick: () => void;
}

interface IConnectionsApproveModal {
  modalProps: ApproveModalModalProps;
  onClose: () => void;
  modalText: string;
}

export const ApproveModal: React.FC<IConnectionsApproveModal> = ({
  modalProps,
  onClose,
  modalText,
}) => {
  const { yesClick, ...restModalProps } = modalProps;
  const t = useTranslationPath('connections.sourceDataModal');

  return (
    <Modal
      isOn={!!modalProps}
      modalProps={{
        ...restModalProps,
        title: t('confirmTitle'),
        footer: (
          <div className="btn-group-bottom">
            <Button
              kind="normal"
              modifiers={['hover-box-shadow']}
              children={t('yes')}
              onClick={() => {
                yesClick();
              }}
            />

            <Button
              kind="normal"
              modifiers={['second', 'hover-box-shadow']}
              children={t('no')}
              onClick={() => onClose()}
            />
          </div>
        ),
      }}
      onClose={onClose}
    >
      <div className="connections__confirm-text">{modalText}</div>
    </Modal>
  );
};
