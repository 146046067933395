import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { DataNode } from 'antd/lib/tree';

interface ITreeList {
  data: DataNode[];
}

const TreeList: React.FC<ITreeList> = (props) => {
  const { data } = props;
  return (
    <Tree
      showIcon
      defaultExpandAll
      height={671}
      switcherIcon={<DownOutlined />}
      treeData={data}
    />
  );
};

export { TreeList };
