import { Key } from 'react';
import {
  IAggregate,
  AggregateRelations,
  IAggregateVersion,
} from '@modules/development';
import { apiBase } from '@modules/apiBase';

const AGGREGATES_ENDPOINT = 'aggregates';

export const aggregatesApi = {
  getAggregate: async (id: Key) => {
    return await apiBase.get<IAggregate>(`${AGGREGATES_ENDPOINT}/${id}`);
  },
  getAggregates: async () => {
    return await apiBase.get(AGGREGATES_ENDPOINT);
  },
  createAggregate: async (body: any) => {
    return await apiBase.post<any>(AGGREGATES_ENDPOINT, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
  deleteAggregate: async (id: Key) => {
    return await apiBase.delete(`${AGGREGATES_ENDPOINT}/${id}`);
  },
  editAggregate: async (payload: any) => {
    const { id, body } = payload;
    return await apiBase.put(`${AGGREGATES_ENDPOINT}/${id}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
  getRelations: async (versionId: Key) => {
    return await apiBase.get<AggregateRelations>(
      `${AGGREGATES_ENDPOINT}/${versionId}/relations`
    );
  },
  getGroupingElements: async () => {
    return await apiBase.get<any>(`${AGGREGATES_ENDPOINT}/groupingElements`);
  },
  getAggregateVesions: async (id: Key) => {
    return await apiBase.get<IAggregateVersion[]>(
      `${AGGREGATES_ENDPOINT}/${id}/versions`
    );
  },
};
