import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import './CreateBlockModal.scss';
import { Select, Modal, Button, Input } from '@shared';
import { nodeList } from '../../SidebarDiagram/data';
import { DiagramNodeTypes, ICreatedNewNode } from '@modules/development';
import { TRANSLATE_PATH } from './services';

interface ICreateBlockModal {
  isOn?: boolean;
  title: string;
  onSave: (newBlock: ICreatedNewNode) => void;
  onClose: () => void;
}

// @ts-ignore
const HIDE_FEATURES: boolean = window._env_.HIDE_FEATURES;

export const CreateBlockModal: FC<ICreateBlockModal> = ({
  isOn,
  title,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation();

  const [newBlock, setNewBlock] = useState<ICreatedNewNode>({
    type: '',
    nodeType: 0,
    nodeName: '',
    code: uuidv4(),
  });

  const handleSave = () => onSave(newBlock);
  const isValid = () => !!newBlock.nodeName && !!newBlock.nodeType;

  const nodeTypes = useMemo(
    () =>
      nodeList.map((node) => ({
        value: node.type,
        label: t(node.translation),
      })),
    []
  );

  useEffect(() => {
    if (!isOn) {
      setNewBlock({
        type: '',
        nodeType: 0,
        nodeName: '',
        code: uuidv4(),
      });
    }
  }, [isOn]);

  return (
    <Modal
      isOn={Boolean(isOn)}
      onClose={onClose}
      modalProps={{ title, width: '475px' }}
    >
      <div className="create-block">
        <div className="create-block__row">
          <span className="create-block__label">
            {t(`${TRANSLATE_PATH}.blockType`)}
          </span>
          <Select
            className="create-block__field"
            value={newBlock.type}
            options={nodeTypes}
            onChange={(value: string) =>
              setNewBlock({
                ...newBlock,
                type: value,
                nodeType: nodeList.find((n) => n.type === value)?.nodeType || 0,
              })
            }
          />
        </div>
        <div className="create-block__row">
          <span className="create-block__label">
            {t(`${TRANSLATE_PATH}.blockName`)}
          </span>
          <Input
            className="create-block__field"
            value={newBlock.nodeName}
            onChange={(e) => {
              setNewBlock({
                ...newBlock,
                nodeName: e.target.value,
              });
            }}
          />
        </div>
        {/* <div className="create-block__row">
          <span className="create-block__label">
            {t(`${TRANSLATE_PATH}.blockCode`)}
          </span>
          <Input
            className="create-block__field"
            value={newBlock.code}
            disabled
          />
        </div> */}
        <div className="create-block__btns btn-group-bottom">
          <Button
            kind="normal"
            modifiers={['hover-box-shadow']}
            disabled={!isValid()}
            onClick={handleSave}
            children={t(`${TRANSLATE_PATH}.ok`)}
          />
          <Button
            kind="normal"
            modifiers={['second', 'hover-box-shadow']}
            onClick={onClose}
            children={t(`${TRANSLATE_PATH}.cancel`)}
          />
        </div>
      </div>
    </Modal>
  );
};
