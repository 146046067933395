import React, { ReactNode } from 'react';
import { Checkbox } from 'antd';
import classnames from 'classnames';
import './CheckoxList.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useTranslation } from 'react-i18next';

type ListMode = 'all' | 'multiple' | 'none';
interface ICheckoxList {
  data: any;
  mode: ListMode;
  itemChecked: string[];
  checkedIdName?: string;
  className?: string;
  disabled?: boolean;
  itemRender: (data: any, index: number) => ReactNode;
  onItemChecked: (e: CheckboxChangeEvent, id: string) => void;
}
interface ICheckboxItemTemplate {
  checked: boolean;
  id: string;
  indeterminate?: boolean;
  disabled?: boolean;
  onChange: (e: CheckboxChangeEvent, id: string) => void;
}

const CheckboxItemTemplate: React.FC<ICheckboxItemTemplate> = ({
  checked,
  id,
  indeterminate,
  disabled,
  children,
  onChange,
}) => (
  <div className="checkbox-item-template">
    <div className="checkbox-item-template__checkbox">
      <Checkbox
        className="checkbox"
        indeterminate={indeterminate}
        checked={checked}
        onChange={(e) => onChange(e, id)}
        disabled={disabled}
      />
    </div>
    {children}
  </div>
);

const CheckoxList: React.FC<ICheckoxList> = ({
  data,
  mode,
  itemChecked,
  checkedIdName,
  className,
  disabled,
  itemRender,
  onItemChecked,
}) => (
  <React.Fragment>
    <div className={classnames('checkbox-list', className)}>
      {mode === 'all' && (
        <CheckboxItemTemplate
          key={`checkbox-item-template_-1`}
          id={'-1'}
          onChange={onItemChecked}
          checked={itemChecked.length === data.length && data.length !== 0}
          indeterminate={
            !!itemChecked.length &&
            itemChecked.length !== data.length &&
            data.length !== 0
          }
        >
          {'Выбрать все'}
        </CheckboxItemTemplate>
      )}
      {data?.map((item: any, index: number) => {
        return (
          <CheckboxItemTemplate
            key={`checkbox-item-template_${
              item[checkedIdName ?? 'id'] || `${index}`
            }`}
            id={item[checkedIdName ?? 'id'] || `${index}`}
            disabled={disabled}
            onChange={onItemChecked}
            checked={itemChecked.includes(
              item[checkedIdName ?? 'id'] || `${index}`
            )}
          >
            {itemRender(item, index)}
          </CheckboxItemTemplate>
        );
      })}
    </div>
  </React.Fragment>
);

interface ICheckoxDataBase {
  label: string;
  value: string | number;
}
interface ICheckoxListBase {
  data: ICheckoxDataBase[];
  value: CheckboxValueType[] | undefined;
  isSelectAll?: boolean;
  indeterminate?: boolean;
  checkAll?: boolean;
  disabled?: boolean;
  onCheckAllChange?: (e: any) => void;
  onChange: (value: CheckboxValueType[]) => void;
}
const CheckoxListBase: React.FC<ICheckoxListBase> = ({
  data,
  isSelectAll,
  indeterminate,
  onChange,
  onCheckAllChange,
  checkAll,
  value,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="checkbox-list-base">
      {isSelectAll && (
        <Checkbox
          disabled={disabled}
          className="checkbox-list-base__checked-all"
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {t('actions.selectAll')}
        </Checkbox>
      )}
      <Checkbox.Group
        disabled={disabled}
        options={data}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export { CheckoxList, CheckoxListBase };
