import { Icon } from '@modules/icon';
import { Dropdown, Menu, Button as ButtonAnt } from 'antd';
import { useTranslation } from 'react-i18next';
import { IDiagramForDeploy, ISettingsModalProps } from './interfaces';

const Item = Menu.Item;

export const useDeployDiagram = (
  setSettingsModalProps: React.Dispatch<
    React.SetStateAction<ISettingsModalProps>
  >
) => {
  const { t } = useTranslation();

  const filters = [
    {
      text: t('deployDiagram.ready'),
      value: 'READY_FOR_DEPLOY',
      color: '#C97A20',
    },
    {
      text: t('deployDiagram.deployed'),
      value: 'DEPLOYED',
      color: '#00B247',
    },
    {
      text: t('deployDiagram.stopped'),
      value: 'STOPPED',
      color: '#0048B2',
    },
    {
      text: t('deployDiagram.error'),
      value: 'ERROR',
      color: '#D30000',
    },
  ];

  const menu = (data: IDiagramForDeploy) => (
    <Menu>
      <Item
        key="menu-item-dropdown_0"
        onClick={() => onContextMenuItemClick(data, 'open')}
      >
        {t('deployDiagram.showDeploySettings')}
      </Item>
    </Menu>
  );

  const onContextMenuItemClick: any = (
    data: IDiagramForDeploy,
    command: string
  ) => {
    switch (command) {
      case 'open':
        setSettingsModalProps({
          isOpen: true,
          disabled: true,
          deployId: data.deployId,
        });
        break;
    }
  };

  const renderMenuBtn = (_: any, data: IDiagramForDeploy) => {
    if (!data.deployStatus) return <></>;
    return (
      <Dropdown
        disabled={data.deployStatus !== 'DEPLOYED'}
        overlay={menu(data)}
        trigger={[`click`]}
      >
        <ButtonAnt shape="circle" type="text">
          <Icon
            color={data.deployStatus !== 'DEPLOYED' ? '#D9D9D9' : undefined}
            name="icon-more_horizontal"
          />
        </ButtonAnt>
      </Dropdown>
    );
  };

  return { filters, renderMenuBtn };
};
