import React, { FC, Suspense } from 'react';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import './App.scss';
import '@openfonts/roboto_cyrillic';
import './index.less';
import { keycloak } from '@modules/keycloakClient';
import { store } from '@modules/store';
import { DecisionAppConfigProvider } from '@modules/decisionAppConfigProvider';
import { Loader } from '@shared';
import { AppRouting } from './routing/AppRouting';
import { userStorage } from '@modules/userStorage';

const App: FC = () => {
  const setTokens = (tokens: any) => {
    userStorage.set('token', tokens.token);
  };

  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <ReactKeycloakProvider authClient={keycloak} onTokens={setTokens}>
          <DecisionAppConfigProvider>
            <AppRouting />
          </DecisionAppConfigProvider>
        </ReactKeycloakProvider>
      </Provider>
    </Suspense>
  );
};

export default App;
