import React, { FC } from 'react';
import { Collapse } from 'antd';
import { CollapsibleType } from 'antd/lib/collapse/CollapsePanel';
import classNames from 'classnames';
import './accordionNew.scss';
import { Icon } from '@modules/icon';
import { IToolbarItem, Toolbar } from '../Toolbar';

interface IAccordionNew {
  className?: string;
  accordionKey: string;
  title: string;
  actions?: IToolbarItem[];
  collapsible?: CollapsibleType;
  defaultOpen?: boolean;
}

export const AccordionNew: FC<IAccordionNew> = ({
  className,
  accordionKey,
  title,
  actions,
  collapsible,
  defaultOpen,
  children,
}) => {
  return (
    <Collapse
      className={classNames('accordion-new', className)}
      expandIcon={({ isActive }) => (
        <Icon
          className="accordion-new__icon"
          name="icon-angle_down"
          rotate={isActive ? 0 : 180}
        />
      )}
      defaultActiveKey={defaultOpen ? accordionKey : undefined}
      ghost
    >
      <Collapse.Panel
        header={
          <div className="accordion-new__header">
            <span>{title}</span>
            {actions && (
              <div
                className="accordion-new__toolbar"
                onClick={(event) => event.stopPropagation()}
                children={<Toolbar data={actions} />}
              />
            )}
          </div>
        }
        key={accordionKey}
        collapsible={collapsible}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};
