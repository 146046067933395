import { InOutParameter } from '../types';

export const checkVariables = (parameters: InOutParameter[]) => {
  const errorMessages: string[] = [];
  const regExp = new RegExp('^[a-zA-Z][a-zA-Z0-9_\\.]*$');

  const notUniqueVariableName = parameters.some((paramA) =>
    parameters.some(
      (paramB) =>
        paramA.parameterId !== paramB.parameterId &&
        paramA.parameterName === paramB.parameterName
    )
  );

  const invalidVariableName = parameters.some(
    (param) => !param.parameterName.match(regExp)
  );

  const invalidTypeId = parameters.some((param) => !param.typeId);

  if (notUniqueVariableName)
    errorMessages.push('development.diagram.diagramData.errorAttributeTitle');

  if (invalidVariableName)
    errorMessages.push('development.diagram.diagramData.errorValidName');

  if (invalidTypeId)
    errorMessages.push('development.diagram.diagramData.errorTypeId');

  return errorMessages;
};
