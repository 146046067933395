import React, { useRef, useState, useEffect, memo } from 'react';
import classNames from 'classnames';

interface IIcon {
  name: string;
  className?: string;
  size?: number | string;
  color?: string;
  rotate?: number;
  onClick?: (...args: any[]) => void;
}

export const Icon: React.FC<IIcon> = memo(function IconComponent({
  name,
  className,
  size,
  color,
  rotate,
  onClick,
}) {
  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const style = Object.assign(
    rotate ? { transform: `rotate(${rotate}deg)` } : {},
    color ? { color } : {},
    {}
  );

  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        const { default: namedImport } = await import(`./assets/${name}.svg`);
        ImportedIconRef.current = namedImport;
      } catch (err) {
        console.error(String(err));
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon }: any = ImportedIconRef;
    return (
      <ImportedIcon
        className={classNames('icon', className)}
        width={size || 24}
        height={size || 24}
        onClick={onClick}
        style={style}
      />
    );
  }

  return null;
});
