import React, { useState } from 'react';
import { TabPanel } from '@shared';
import { tabs } from '@modules/administration/components/ExternalServiceCallSettingsModal/data';
import './DataSourceFieldsExternalService.scss';
import { ExternalServiceSetup } from './components';

export const DataSourceFieldsExternalService: React.FC = () => {
  const [activeExternalServiceTab, setExternalServiceTab] = useState('0');
  return (
    <>
      <TabPanel
        items={tabs.slice(0, 1)}
        className="connections__external-service-tabs"
        level={3}
        active={activeExternalServiceTab}
        onClick={(_, key) => {
          setExternalServiceTab(key);
        }}
      />
      {activeExternalServiceTab === '0' && <ExternalServiceSetup />}
    </>
  );
};
