import React, { useCallback, useMemo, useState, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { IDiagram } from '../../types';
import { onSaveClick } from '../../utils';
import {
  currentObjectKey,
  removeOpenObject,
  removeOpenTab,
  openObjects,
  removeDiagram,
  removeCustomCode,
  closeDiagram,
} from '../../DevelopmentSlice';
import { useTranslation } from 'react-i18next';
import { ConfirmModal, IConfirmModalProps } from '@shared';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { Icon } from '@modules/icon';

interface ICloseObjectButton {
  objectKey: string;
  objectType: string;
  objectVersion?: string;
}

export const CloseObjectButton: VFC<ICloseObjectButton> = ({
  objectKey,
  objectType,
  objectVersion,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const currentObjKey = useAppSelector(currentObjectKey);
  const openObjectsData = useAppSelector(openObjects);

  const [modalProps, setModalProps] = useState<IConfirmModalProps | null>(null);

  const location = useMemo(
    () => history.location.pathname.split('/'),
    [history]
  );

  const currentObjectData = useMemo(() => {
    if (!modalProps) return undefined;
    const objectKey = modalProps?.meta?.objectKey;
    return openObjectsData.find(({ key }) => key === objectKey);
  }, [modalProps]);

  const onClickClose = useCallback(() => {
    // если закрывается таб диаграммы которая в данный момент на странице
    // то редирект на список диаграмм
    const currentObjectData = openObjectsData.find(
      (item) => item.key === objectKey
    );

    if (currentObjectData && !currentObjectData?.isSaved) {
      return setModalProps({
        type: 'confirm-modal',
        contentText: t(
          `development.${
            objectType === 'aggregate' ? 'aggregates' : objectType
          }.confirmationClose`
        ),
        okButtonText: t('modals.yes'),
        cancelButtonText: t('modals.no'),
        cancelButtonModifiers: ['hover-box-shadow', 'second'],
        closeButtonText: t('modals.cancel'),
        kind: 'info',
        meta: { objectKey, objectVersion },
      });
    }

    onClickCancelConfirmModal(objectKey, objectVersion);
  }, [objectKey, objectType, objectVersion, openObjectsData]);

  const onClickOkConfirmModal = async () => {
    onSaveClick(currentObjectData as IDiagram, dispatch);
    onClickCancelConfirmModal();
  };

  const onClickCancelConfirmModal = (
    objectKey?: string,
    objectVersion?: string
  ) => {
    const key = objectKey ?? modalProps?.meta?.objectKey;
    const ver = objectVersion ?? modalProps?.meta?.objectVersion;

    if (currentObjKey === key) history.push(location.slice(0, 3).join('/'));

    if (ver) dispatch(closeDiagram(ver));

    if (currentObjectData?.draft) {
      currentObjectData?.type === 'diagram'
        ? dispatch(removeDiagram({ key }))
        : dispatch(removeCustomCode({ key }));
    }

    dispatch(removeOpenTab({ key }));
    dispatch(removeOpenObject({ key }));
  };

  const onClickCloseConfirmModal = () => {
    setModalProps(null);
  };

  return (
    <>
      <button
        className="diagram__actions-button"
        onClick={onClickClose}
        children={<Icon name="icon-close" />}
      />

      {modalProps && modalProps.type === 'confirm-modal' && (
        <ConfirmModal
          modalProps={modalProps}
          onOk={onClickOkConfirmModal}
          onCancel={onClickCancelConfirmModal}
          onClose={onClickCloseConfirmModal}
        />
      )}
    </>
  );
};
