import React, { useEffect, useState, VFC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox, Collapse } from 'antd';
import '../ExternalServiceCallSettingsModal.scss';
import {
  Button,
  DataTypesSelect,
  IModalProps,
  Input,
  Modal,
  SimpleTable,
  TextArea,
} from '@shared';
import { Icon } from '@modules/icon';
import { servicesApi, rolesList, selectRoles } from '@modules/services';
import { useAppSelector } from '@modules/store';
import { initialService, mainClass, tranlationPath } from '../data';
import { Variable, Service, Header } from '../interfaces';
import { useTranslationPath } from '@modules/languageProvider';
import { ExtServiceGeneralItem } from './ExtServiceGeneralItem';
import { ColumnsType } from 'antd/lib/table';

export interface IExtServiceCallSettingsView {
  modalProps: IModalProps;
  versionId: string;
  versionName: string;
  onClose: () => void;
}

export const ExtServiceCallSettingsView: VFC<IExtServiceCallSettingsView> = ({
  modalProps,
  versionId,
  versionName,
  onClose,
}) => {
  const translate = useTranslationPath(tranlationPath);

  const roles = useAppSelector(selectRoles);

  const [service, setService] = useState<Service>(initialService);
  const [inputVariables, setInputVariables] = useState<Variable[]>([]);
  const [outputVariables, setOutputVariables] = useState<Variable[]>([]);
  const [headers, setHeaders] = useState<Header[]>([]);

  const inputVariablesColumns: ColumnsType<Variable> = [
    {
      dataIndex: 'collapse',
      className: 'ant-table-collapse',
    },
    {
      dataIndex: 'variableName',
      title: translate('variableName'),
      width: '50%',
      render: (value) => <Input value={value} disabled />,
    },
    {
      dataIndex: 'isArray',
      title: translate('isArray'),
      align: 'center',
      render: (value) => <Checkbox checked={value} disabled />,
    },
    {
      dataIndex: 'variableTypeId',
      title: translate('variableTypeId'),
      width: '50%',
      render: (value) => <DataTypesSelect value={value} disabled />,
    },
  ];

  const outputVariablesColumns: ColumnsType<Variable> = [
    {
      dataIndex: 'collapse',
      className: 'ant-table-collapse',
    },
    {
      dataIndex: 'variableName',
      title: translate('variableName'),
      width: '33.3333%',
      render: (value) => <Input value={value} disabled />,
    },
    {
      dataIndex: 'isArray',
      title: translate('isArray'),
      align: 'center',
      render: (value) => <Checkbox checked={value} disabled />,
    },
    {
      dataIndex: 'variableTypeId',
      title: translate('variableTypeId'),
      width: '33.3333%',
      render: (value) => <DataTypesSelect value={value} disabled />,
    },
    {
      dataIndex: 'sourcePath',
      title: translate('sourcePath'),
      width: '33.3333%',
      render: (value) => <Input value={value} disabled />,
    },
  ];

  const headersColumns: ColumnsType<Header> = [
    {
      dataIndex: 'headerName',
      title: translate('headerName'),
      width: '50%',
      render: (value) => <Input value={value} disabled />,
    },
    {
      dataIndex: 'headerValue',
      title: translate('headerValue'),
      width: '50%',
      render: (value) => <Input value={value} disabled />,
    },
  ];

  const onCloseClick = () => {
    onClose();
  };

  useEffect(() => {
    const fetchService = async (serviceId: string) => {
      try {
        const response = await servicesApi.getServiceById(serviceId);
        const { variables, headers, ...service } = response;
        const batchFlag = service.batchFlag ? 'BATCH' : 'SINGLE';
        const inputVariables: Variable[] = [];
        const outputVariables: Variable[] = [];

        variables?.forEach((variable) => {
          const newVariable: Variable = {
            key: uuidv4(),
            variableId: variable.variableId,
            variableName: variable.variableName,
            sourcePath: variable.sourcePath,
            isArray: variable.arrayFlag,
            isComplex: !!variable.complexTypeVersionId,
            variableTypeId: variable.complexTypeVersionId
              ? variable.complexTypeVersionId
              : String(variable.primitiveTypeId),
          };

          variable.parameterType === 'IN'
            ? inputVariables.push(newVariable)
            : outputVariables.push(newVariable);
        });

        setInputVariables(inputVariables);
        setOutputVariables(outputVariables);
        setService({ ...service, batchFlag });
        setHeaders(
          (headers || []).map((header) => ({
            key: uuidv4(),
            headerId: header.headerId,
            headerName: header.headerName,
            headerValue: header.headerValue,
          }))
        );
      } catch (error) {
        console.warn(error);
      }
    };

    if (versionId) fetchService(String(versionId));
  }, [versionId]);

  const disableCollapsePanel = roles.includes(
    rolesList.EXT_SERVICE_READ_VARIABLES
  )
    ? undefined
    : 'disabled';

  return (
    <>
      <Modal
        isOn={Boolean(modalProps)}
        modalProps={{
          title: `${versionName} ${service.serviceName} ${translate(
            'readOnly'
          )}`,
          icon: modalProps?.icon || 'icon-calling_external_service',
          type: modalProps.type,
          width: modalProps?.width || '1080px',
          className: 'vertical-expanded',
          helpKey: 'external-service-settings',
          footer: (
            <div className="btn-group-bottom">
              <Button
                kind="normal"
                modifiers={['second', 'hover-box-shadow']}
                onClick={onCloseClick}
                children={translate('cancel')}
              />
            </div>
          ),
        }}
        onClose={onClose}
      >
        <div className={mainClass}>
          <div className={`${mainClass}__top`}>
            <div className={`${mainClass}__name`}>
              <Input
                label={translate('serviceName')}
                value={service.serviceName}
                disabled
              />
            </div>
          </div>
          <Collapse
            className={`${mainClass}__accordion`}
            expandIcon={({ isActive }) => (
              <Icon
                className={`${mainClass}__accordion-icon`}
                name="icon-angle_down"
                rotate={isActive ? 0 : 180}
              />
            )}
            defaultActiveKey={`${mainClass}-accordion-1`}
            ghost
          >
            <Collapse.Panel
              header={
                <div className={`${mainClass}__accordion-header`}>
                  <span>{translate('generalSettings')}</span>
                </div>
              }
              key={`${mainClass}-accordion-1`}
            >
              <div className={`${mainClass}-general-settings`}>
                <ExtServiceGeneralItem
                  label="host"
                  size="large"
                  value={service.host}
                  disabled
                />

                <ExtServiceGeneralItem
                  label="fileFormat"
                  value={service.fileFormat}
                  disabled
                />

                <ExtServiceGeneralItem
                  label="serviceType"
                  value={service.serviceType}
                  disabled
                />

                <ExtServiceGeneralItem
                  label="batchFlag"
                  value={service.batchFlag}
                  disabled
                />

                <ExtServiceGeneralItem
                  label="endpoint"
                  size="large"
                  value={service.endpoint}
                  addonBefore="/"
                  disabled
                />

                <ExtServiceGeneralItem
                  label="port"
                  size="small"
                  value={service.port}
                  number
                  disabled
                />

                <ExtServiceGeneralItem
                  label="syncType"
                  value={service.syncType}
                  disabled
                />

                <ExtServiceGeneralItem
                  label="timeout"
                  size="small"
                  value={service.timeout}
                  number
                  disabled
                />

                <ExtServiceGeneralItem
                  label="secondAttemptsCnt"
                  size="small"
                  value={service.secondAttemptsCnt}
                  number
                  disabled
                />

                <ExtServiceGeneralItem
                  label="protocol"
                  value={service.protocol}
                  disabled
                />

                <ExtServiceGeneralItem
                  label="transactionsPerSecond"
                  value={service.transactionsPerSecond}
                  disabled
                  number
                />

                <ExtServiceGeneralItem
                  label="method"
                  size="large"
                  value={service.method}
                  disabled
                />

                <ExtServiceGeneralItem
                  label="interval"
                  size="small"
                  value={service.interval}
                  number
                  disabled
                />
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={
                <div className={`${mainClass}__accordion-header`}>
                  <span>{translate('inputVariables')}</span>
                </div>
              }
              key={`${mainClass}-accordion-2`}
              collapsible={disableCollapsePanel}
            >
              <div className={`${mainClass}-input-variables`}>
                <SimpleTable
                  columnsData={inputVariablesColumns}
                  dataSource={inputVariables}
                />
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={
                <div className={`${mainClass}__accordion-header`}>
                  <span>{translate('body')}</span>
                </div>
              }
              key={`${mainClass}-accordion-3`}
            >
              <div className={`${mainClass}-request-generating`}>
                <TextArea
                  className={`${mainClass}-request-generating__text-editor`}
                  value={service.body}
                  disabled
                />
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={
                <div className={`${mainClass}__accordion-header`}>
                  <span>{translate('outputVariables')}</span>
                </div>
              }
              key={`${mainClass}-accordion-4`}
              collapsible={disableCollapsePanel}
            >
              <div className={`${mainClass}-output-variables`}>
                <SimpleTable
                  columnsData={outputVariablesColumns}
                  dataSource={outputVariables}
                />
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={
                <div className={`${mainClass}__accordion-header`}>
                  <span>{translate('headers')}</span>
                </div>
              }
              key={`${mainClass}-accordion-5`}
            >
              <div className={`${mainClass}-output-variables`}>
                <SimpleTable
                  columnsData={headersColumns}
                  dataSource={headers}
                />
              </div>
            </Collapse.Panel>
          </Collapse>
        </div>
      </Modal>
    </>
  );
};
