import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxProps } from 'antd';
import './Checkbox.scss';

interface ICheckbox extends CheckboxProps {
  label: string;
  required: boolean;
}

export const Checkbox: React.FC<ICheckbox> = (props) => {
  const { label, required } = props;
  return (
    <div className="balalaika__checkbox">
      <div className="balalaika__checkbox-top">
        {label && <span>{label}</span>}
        {required && <span className="balalaika__checkbox-required">*</span>}
      </div>
      <AntdCheckbox {...props} />
    </div>
  );
};
