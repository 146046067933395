import { apiBase } from '@modules/apiBase';
import { ISchema } from '../types/schema';

const SCHEMA_ENDPOINT = 'diagrams/schemas';

export const schemasApi = {
  getSchemas: async () => {
    return await apiBase.get<ISchema[]>(SCHEMA_ENDPOINT);
  },
};
