import {
  ConnectionsTableDataDTO,
  IAllConnectionsDTO,
  IDatabaseSystemDTO,
  IExtServices,
  IMessageBrokersDTO,
} from '@modules/connections/types/connectionsTypes';
import { ColumnsType } from 'antd/lib/table';

export const allConnectionsColumns: ColumnsType<
  ConnectionsTableDataDTO<IAllConnectionsDTO>
> = [
  {
    title: 'Название',
    dataIndex: 'name',
    width: '25%',
    sorter: (a, b) => a?.name?.localeCompare(b.name),
  },
  {
    title: 'Тип источника',
    dataIndex: 'type',
    width: '20%',
    sorter: (a, b) => a?.type?.localeCompare(b?.type),
  },
  {
    title: 'Дата изменения',
    dataIndex: 'lastChangeDt',
    width: '20%',
    sorter: (a, b) => a?.lastChangeDt?.localeCompare(b.lastChangeDt),
  },
  {
    title: 'Пользователь, внесший изменения',
    dataIndex: 'lastChangeByUser',
    width: '25%',
    sorter: (a, b) => a?.lastChangeByUser?.localeCompare(b.lastChangeByUser),
  },
  {
    title: 'Признак проверки',
    dataIndex: 'connectionStatus',
    width: '10%',
    sorter: (a, b) => a?.lastChangeByUser?.localeCompare(b.lastChangeByUser),
  },
];

export const databasesColumns: ColumnsType<
  ConnectionsTableDataDTO<IDatabaseSystemDTO>
> = [
  {
    title: 'Название',
    dataIndex: 'name',
    sorter: (a, b) => a?.name?.localeCompare(b.name),
  },
  {
    title: 'Тип СУБД',
    dataIndex: 'dataSourceType',
    sorter: (a, b) => a?.dataSourceType?.localeCompare(b.dataSourceType),
  },
  {
    title: 'JDBC URL',
    dataIndex: 'jdbcUrl',
    sorter: (a, b) => a?.jdbcUrl?.localeCompare(b.jdbcUrl),
  },
  {
    title: 'Имя пользователя',
    dataIndex: 'username',
    sorter: (a, b) => a?.username?.localeCompare(b.username),
  },
  {
    title: 'Дата изменения',
    dataIndex: 'lastChangeDt',
    sorter: (a, b) => a?.lastChangeDt?.localeCompare(b.lastChangeDt),
  },
  {
    title: 'Пользователь, внесший изменения',
    dataIndex: 'lastChangeByUser',
    sorter: (a, b) => a?.lastChangeByUser?.localeCompare(b.lastChangeByUser),
  },
  {
    title: 'Признак проверки',
    dataIndex: 'connectionStatus',
    width: '10%',
    sorter: (a, b) => a?.lastChangeByUser?.localeCompare(b.lastChangeByUser),
  },
];

export const outServicesColumns: ColumnsType<
  ConnectionsTableDataDTO<IExtServices>
> = [
  {
    title: 'Название',
    dataIndex: 'name',
    sorter: (a, b) => a?.name?.localeCompare(b.name),
  },
  {
    title: 'Протокол доступа',
    dataIndex: 'protocol',
    sorter: (a, b) => a?.protocol?.localeCompare(b.protocol),
  },
  {
    title: 'Расположение',
    dataIndex: 'url',
    sorter: (a, b) => a?.url?.localeCompare(b.url),
  },
  {
    title: 'Дата изменения',
    dataIndex: 'lastChangeDt',
    sorter: (a, b) => a?.lastChangeDt?.localeCompare(b.lastChangeDt),
  },
  {
    title: 'Пользователь, внесший изменения',
    dataIndex: 'lastChangeByUser',
    sorter: (a, b) => a?.lastChangeByUser?.localeCompare(b.lastChangeByUser),
  },
  {
    title: 'Признак проверки',
    dataIndex: 'connectionStatus',
    width: '10%',
    sorter: (a, b) => a?.lastChangeByUser?.localeCompare(b.lastChangeByUser),
  },
];

export const messageBrokersColumns: ColumnsType<
  ConnectionsTableDataDTO<IMessageBrokersDTO>
> = [
  {
    title: 'Название',
    dataIndex: 'name',
    width: '20%',
    sorter: (a, b) => a?.name?.localeCompare(b.name),
  },
  {
    title: 'Bootstrap Servers',
    dataIndex: 'bootstrapServers',
    width: '30%',
    sorter: (a, b) => a?.bootstrapServers?.localeCompare(b.bootstrapServers),
  },
  {
    title: 'Дата изменения',
    dataIndex: 'lastChangeDt',
    width: '25%',
    sorter: (a, b) => a?.lastChangeDt?.localeCompare(b.lastChangeDt),
  },
  {
    title: 'Пользователь, внесший изменения',
    dataIndex: 'lastChangeByUser',
    width: '25%',
    sorter: (a, b) => a?.lastChangeByUser?.localeCompare(b.lastChangeByUser),
  },
  {
    title: 'Признак проверки',
    dataIndex: 'connectionStatus',
    width: '10%',
    sorter: (a, b) => a?.lastChangeByUser?.localeCompare(b.lastChangeByUser),
  },
];
