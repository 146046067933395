import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Script } from './Tabs/Script/Script';
import { DiagramsWithScript } from './Tabs/DiagramsWithScript/DiagramsWithScript';
import { InputOutputDataScript } from './Tabs/InputOutputDataScript';
import CustomCodeVersions from './Tabs/Versions/CustomCodeVersions';
import {
  CUSTOM_CODES_CORE_PATH,
  CUSTOM_CODE_PATH,
  DATA_SCRIPT_PATH,
  DIAGRAMS_WITH_SCRIPT_PATH,
  SCRIPT_PATH,
  VERSIONS_PATH,
} from '../../routing/сustomCodesConstants';

const CustomCodeNavigation = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route
        exact
        path={CUSTOM_CODE_PATH}
        render={(props) => {
          return (
            <Redirect
              to={{
                pathname: `${CUSTOM_CODES_CORE_PATH}/${props.match.params.code}/script`,
                state: history.location.state,
              }}
            />
          );
        }}
      />
      <Route exact path={SCRIPT_PATH} component={Script} />
      <Route exact path={DATA_SCRIPT_PATH} component={InputOutputDataScript} />
      <Route
        exact
        path={DIAGRAMS_WITH_SCRIPT_PATH}
        component={DiagramsWithScript}
      />
      <Route exact path={VERSIONS_PATH} component={CustomCodeVersions} />
    </Switch>
  );
};

export { CustomCodeNavigation };
