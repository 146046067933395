import { Key } from 'react';
import { IDataVariables, IDiagramNode } from '@modules/development';
import { useCalcAggregateValidate } from './hooks';

export enum RetentionTypes {
  system = 'process',
  event = 'event',
}
export enum WatermarkTypes {
  permanentOrderly = 'monotonous',
  permanentDisorderly = 'bounded_unordered',
  impermanentOrderly = 'periodic',
  impermanentDisorderly = 'periodic_bounded_unordered',
}

export enum RetentionTimeUnits {
  milliseconds = 'ms',
  seconds = 's',
  minutes = 'm',
  hours = 'h',
  days = 'd',
}

export type Aggregate = {
  aggregateId: string;
  versionId: string;
  aggregateElementTypeId: string;
  diagramAggregateElement: string;
  isUsedInDiagram: boolean;
};

export type GroupingElement = {
  diagramElement: string;
  aggregateElement: string;
};

export type OutputVariable = IDataVariables & {
  aggregate: Aggregate;
};

export interface IAggregateComputeProperties {
  retentionTimeField?: string;
  retentionType: RetentionTypes;
  retentionTimeValue: number;
  retentionTimeUnit: RetentionTimeUnits;
  watermarkType?: WatermarkTypes;
  duration?: number;
  coefficient?: number;
  groupingElements: GroupingElement[];
  outputVariablesMapping: OutputVariable[];
}

export type AggregateComputeNode = IDiagramNode<IAggregateComputeProperties>;

export enum ModalTypes {
  addElement = 'addElement',
  chooseGroupingElements = 'chooseGroupingElements',
  chooseAggregate = 'chooseAggregate',
  confirmSave = 'confirmSave',
}

export enum AddModalTypes {
  diagramElement = 'diagramElement',
  retentionTimeField = 'retentionTimeField',
  diagramAggregateElement = 'diagramAggregateElement',
  diagramAggregateVariable = 'diagramAggregateVariable',
}

export type ModalData =
  | {
      type: ModalTypes.addElement;
      variableName: string;
      addType: AddModalTypes;
      key?: Key;
      allowedTypes?: { typeId?: string; isArray?: boolean }[];
    }
  | {
      type: ModalTypes.chooseGroupingElements;
      name: string;
      key: Key;
    }
  | {
      type: ModalTypes.chooseAggregate;
      aggregateId: string;
      key: Key;
    }
  | {
      type: ModalTypes.confirmSave;
      contentText: string;
    }
  | null;

export type InvalidData = {
  inputs: { [key: string]: string };
  outputVariablesMapping: { [key: string]: { [key: string]: string } };
};

export type Validation = ReturnType<typeof useCalcAggregateValidate>;
