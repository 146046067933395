export const sortHelper = (array: any, sort: any) => {
  return array.map((item: any) => {
    if (sort.field === item.dataIndex) {
      return {
        ...item,
        defaultSortOrder: sort.order,
      };
    }
    return item;
  });
};
