import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConnectionsSelectType,
  ConnectionsToolbar,
} from '@modules/connections/components';
import './ConnectionsActionsBar.scss';

export const ConnectionsActionsBar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="connections-bar">
      <div className="connections__top">
        <h1 className="connections__title">{t('connections.title')}</h1>
        <ConnectionsSelectType />
      </div>
      <ConnectionsToolbar />
    </div>
  );
};
