import React, { useEffect, useMemo, useState } from 'react';
import { InputSearch, IToolbarItem, Toolbar, useSearch } from '@shared';
import { useTranslation } from 'react-i18next';
import {
  useConnectionsActions,
  useConnectionsSelector,
} from '@modules/connections/hooks';
import { useAppDispatch } from '@modules/store';
import {
  fetchConnectionsTable,
  deleteConnection,
  deleteConnections,
} from '@modules/connections/ConnectionsSlice';
import {
  ApproveModal,
  ApproveModalModalProps,
} from '@modules/connections/components';

export const ConnectionsToolbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const {
    setSelectedRows,
    setConnectionsTable,
    setVConnectionsDataSourceModal,
    setConnectionActiveId,
  } = useConnectionsActions();
  const { tableData, selectedRows, isLoading, activeConnectionType } =
    useConnectionsSelector();
  const [search, setSearch] = useState<null | ''>(null);
  const areAllRowsChecked =
    !!tableData.length && tableData.length === selectedRows.length;
  const [openedModal, setOpenedModal] = useState<ApproveModalModalProps | null>(
    null
  );

  const [, getRefProps, getInputProps, filterSearch] = useSearch({
    search: search as string,
    setSearch: setSearch as (value: string) => void,
  });

  const cacheTable = useMemo(() => tableData, [isLoading]);

  useEffect(() => {
    if (search !== null) {
      const filterData = search ? filterSearch(cacheTable, 'name') : cacheTable;
      setConnectionsTable(filterData);
    }
  }, [search]);

  const actions: IToolbarItem[] = useMemo(
    () => [
      { isDivider: true },
      {
        icon: 'icon-add',
        tooltip: t('actions.add'),
        onClick: () => setVConnectionsDataSourceModal(true),
      },
      {
        icon: 'icon-delete',
        tooltip: t('actions.delete'),
        disabled: !selectedRows.length,
        onClick: () => {
          setOpenedModal({
            type: 'deleteConnections',
            yesClick: () => {
              const deletedTableData = tableData.filter((row) =>
                selectedRows.includes(row.connectionId)
              );

              dispatch(
                deleteConnections(
                  deletedTableData.map((row) => ({
                    connectionType: row.type!,
                    id: row.connectionId,
                  }))
                )
              ).finally(() =>
                dispatch(fetchConnectionsTable(activeConnectionType))
              );

              /*
              const updatedTableData = tableData.filter(
                (connection) => !selectedRows.includes(connection.connectionId)
              );
              setSelectedRows([]);
              setConnectionsTable(updatedTableData);*/
              setOpenedModal(null);
            },
          });
        },
      },
      {
        icon: 'icon-edit',
        tooltip: t('actions.edit'),
        disabled: selectedRows.length !== 1,
        onClick: () => {
          setVConnectionsDataSourceModal(true);
          setConnectionActiveId(selectedRows[0] as string);
        },
      },
      {
        icon: 'icon-refresh',
        tooltip: t('actions.refresh'),
        onClick: () => dispatch(fetchConnectionsTable(activeConnectionType)),
      },
    ],
    [i18n.language, selectedRows, activeConnectionType]
  );

  const onCheckboxAllChange = () => {
    if (areAllRowsChecked) {
      setSelectedRows([]);
    } else {
      const checkedIds = tableData.map(({ connectionId }) => connectionId);
      setSelectedRows(checkedIds);
    }
  };

  return (
    <div className="connections__row" {...getRefProps()}>
      <Toolbar
        data={actions}
        onCheckboxAllChange={onCheckboxAllChange}
        CheckboxAllChecked={areAllRowsChecked}
        CheckboxAllIndeterminate={
          areAllRowsChecked ? false : !!selectedRows.length
        }
        isShowCheckboxAll
        search={<InputSearch {...getInputProps()} />}
      />
      {openedModal?.type === 'deleteConnections' && (
        <ApproveModal
          modalProps={openedModal}
          onClose={() => setOpenedModal(null)}
          modalText={t('connections.deleteModalText')}
        />
      )}
    </div>
  );
};
