import React from 'react';
import {
  DataSourceFieldsCommon,
  DataSourcePanel,
} from '@modules/connections/components/connectionsModalDataSource/components';
import { tranlationPath } from '@modules/administration/components/ExternalServiceCallSettingsModal/data';
import { useTranslationPath } from '@modules/languageProvider';
import { ExternalServiceCommonSettings } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/components';
import { ExternalServiceInputVariables } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/components/externalServiceInputVariables';
import { ExternalServiceOutputVariables } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/components/externalServiceOutputVariables';
import { ExternalServiceHeaders } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/components/externalServiceHeaders';
import { ExternalServiceTextEditor } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsExternalService/components/externalServiceTextEditor';
import './ExternalServiceSetup.scss';

export const ExternalServiceSetup: React.FC = () => {
  const translate = useTranslationPath(tranlationPath);

  return (
    <>
      <DataSourceFieldsCommon className="external-service__common" />
      <DataSourcePanel
        title={translate('generalSettings')}
        panel={{ key: 'external-service-accordion-1' }}
      >
        <ExternalServiceCommonSettings />
      </DataSourcePanel>
      <ExternalServiceInputVariables />
      <ExternalServiceOutputVariables />
      <ExternalServiceHeaders />
      <ExternalServiceTextEditor />
    </>
  );
};
