import { ITabPanelItem } from '@shared';
import { Service } from './interfaces';

export const tranlationPath =
  'administrationTab.externalServiceCallSettingsModal';

export const mainClass = 'external-service-call-settings-modal';

export const initialService: Service = {
  batchFlag: 'BATCH',
  serviceName: '',
  host: '',
  serviceType: 'HTTP',
  endpoint: '',
  port: 0,
  syncType: 'ASYNC',
  protocol: 'REST',
  secondAttemptsCnt: 1,
  interval: 0,
  transactionsPerSecond: 0,
  timeout: 0,
};

export const tabs: ITabPanelItem[] = [
  {
    key: '0',
    text: 'Настройки',
    translation: 'administrationTab.externalServiceCallSettingsModal.settings',
    path: '/settings',
  },
  {
    key: '1',
    text: 'Версии',
    translation: 'administrationTab.externalServiceCallSettingsModal.versions',
    path: '/versions',
  },
];
