import React, { useContext, useEffect, useState } from 'react';
import { DataSourceFieldsCommon } from '@modules/connections/components/connectionsModalDataSource/components';
import {
  useConnectionsFormSettings,
  useConnectionsSelector,
} from '@modules/connections/hooks';
import {
  rawFieldsBrokerMessageInBorder,
  rawFieldsBrokerMessageOutBorder,
} from './rawFormFields';
import { FieldGroup, Tooltip } from '@shared';
import { Icon } from '@modules/icon';
import { BrokerMessageTable } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsBrokerMessage/components/brokerMessageTable';
import { RcFile } from 'antd/lib/upload/interface';
import { v4 as uuidv4 } from 'uuid';
import {
  KEYSTORE_LOCATION_NAME,
  TRUSTSTORE_LOCATION_NAME,
} from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsBrokerMessage/constants';
import { ConnectionsModalDataFromContext } from '@modules/connections/components';
import {
  ConnectionsBrokerMessageDTO,
  ConnectionsDataSourceModalFields,
} from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';
import { useBrokerMessageTables } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsBrokerMessage/hooks';
import { rawBrokerMessageColumns } from '@modules/connections/components/connectionsModalDataSource/components/dataSourceFieldsBrokerMessage/components/brokerMessageTable/rawColumns';

type FileName = typeof TRUSTSTORE_LOCATION_NAME | typeof KEYSTORE_LOCATION_NAME;

export const DataSourceFieldsBrokerMessage: React.FC = () => {
  const [trustStoreName, setTrustStoreName] = useState('');
  const [keyStoreName, setKeyStoreName] = useState('');
  const { oneConnection = {} } = useConnectionsSelector();
  const { properties = {} } = oneConnection ?? ({} as any);
  const { parameters = [] } = properties;

  const { fieldsData: fieldsOutBorder } = useConnectionsFormSettings(
    rawFieldsBrokerMessageOutBorder
  );
  const { form } = useContext(ConnectionsModalDataFromContext);
  const { columns, toolbar, selected, setSelected, tableData, setTableData } =
    useBrokerMessageTables(rawBrokerMessageColumns, parameters);

  const updateExistFile = (
    name: string,
    existParam: ConnectionsBrokerMessageDTO
  ) => {
    const updatedFileName = tableData.map((el: any) => ({
      ...el,
      ...(existParam.name === el.name
        ? {
            value: name,
            isSecure: false,
          }
        : {}),
    }));

    setTableData(updatedFileName);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [ConnectionsDataSourceModalFields.brokerMessageCommonParams]: {
        ...form.getFieldsValue([
          ConnectionsDataSourceModalFields.brokerMessageCommonParams,
        ]),
        [existParam.key]: {
          value: name,
          name: existParam.name,
          isSecure: false,
        },
      },
    });
  };

  const removeFileFromCommonParams = (fileName: FileName) => {
    const deletedKey =
      tableData.find((param) => param.name === fileName)?.key ?? '';
    const updatedCommonParams = tableData.filter(
      ({ key }) => key !== deletedKey
    );
    setTableData(updatedCommonParams);

    return deletedKey;
  };

  const fileChangeHandler = (file: RcFile | '', fileName: FileName) => {
    if (file) {
      const { name = '' } = file;
      const findExistParamName = tableData.find((el) => el.name === fileName);

      if (findExistParamName) {
        updateExistFile(name, findExistParamName);
      } else {
        setTableData([
          ...tableData,
          {
            key: uuidv4(),
            isSecure: false,
            name: fileName,
            value: name,
          },
        ]);
      }
    } else {
      const deletedKey = removeFileFromCommonParams(fileName);

      if (selected.includes(deletedKey)) {
        const updatedSelectedKeys = selected.filter(
          (key) => key !== deletedKey
        );
        setSelected(updatedSelectedKeys);
      }
    }
  };

  const truststoreFileChange = (file: RcFile | '') => {
    setTrustStoreName(file ? file.name : '');
    fileChangeHandler(file, TRUSTSTORE_LOCATION_NAME);
  };

  const keystoreFileChange = (file: RcFile | '') => {
    setKeyStoreName(file ? file.name : '');
    fileChangeHandler(file, KEYSTORE_LOCATION_NAME);
  };

  const { fieldsData: fieldsInBorder } = useConnectionsFormSettings(
    rawFieldsBrokerMessageInBorder,
    {
      [ConnectionsDataSourceModalFields.bootstrapServers]: {
        suffix: (
          <Tooltip title="Further information" trigger="click" placement="top">
            <Icon name="icon-help" />
          </Tooltip>
        ),
      },
      [ConnectionsDataSourceModalFields.truststoreFile]: {
        onChange: truststoreFileChange,
        name: trustStoreName,
      },
      [ConnectionsDataSourceModalFields.keystoreFile]: {
        onChange: keystoreFileChange,
        name: keyStoreName,
      },
    }
  );

  const isTrustFileTouched = form.getFieldsValue([
    ConnectionsDataSourceModalFields.truststoreFile,
  ]);
  const isKeyFileTouched = form.getFieldsValue([
    ConnectionsDataSourceModalFields.keystoreFile,
  ]);

  useEffect(() => {
    if (!isTrustFileTouched[ConnectionsDataSourceModalFields.truststoreFile]) {
      setTrustStoreName('');
    }
  }, [isTrustFileTouched]);

  useEffect(() => {
    if (!isTrustFileTouched[ConnectionsDataSourceModalFields.keystoreFile]) {
      setKeyStoreName('');
    }
  }, [isKeyFileTouched]);

  return (
    <>
      <DataSourceFieldsCommon />
      <FieldGroup data={fieldsOutBorder} labelWidth="200px" />
      <FieldGroup
        className="connections__data-source-modal_border"
        data={fieldsInBorder}
        labelWidth="200px"
      />
      <BrokerMessageTable
        columns={columns}
        selected={selected}
        setSelected={setSelected}
        tableData={tableData}
        toolbar={toolbar}
      />
    </>
  );
};
