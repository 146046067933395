import { DatePicker } from 'antd';
import { FC, useState } from 'react';

const { RangePicker } = DatePicker;

interface DatePickerFilterProps {
  // statusFilter: any;
  // confirm: () => void;
  // setSelectedKeys: (selectedKeys: any) => void;
  // clearFilters?: () => void;
  // dataIndexAfter: string;
  // dataIndexBefore: string;
  handleFilter: any;
}

export const DatePickerFilter: FC<DatePickerFilterProps> = ({
  // statusFilter,
  // confirm,
  // setSelectedKeys,
  // clearFilters,
  // dataIndexAfter,
  // dataIndexBefore,
  handleFilter,
}) => {
  // const [hackValue, setHackValue] = useState<any>();
  const [value, setValue] = useState<any>(null);
  // const [open, setOpen] = useState<boolean>(true);

  const onFilter = (val1: any, val2: any) => {
    if (val1 && val2) {
      handleFilter(
        // [dataIndexAfter, dataIndexBefore],
        [val1?.format('YYYY-MM-DD'), val2?.format('YYYY-MM-DD')]
      );
    }
    // confirm();
  };

  const onRangePickerChange = (val: any) => {
    setValue(val);
    if (val) {
      onFilter(val[0], val[1]);
    }
  };

  //   useEffect(() => {
  //     if (
  //       value !== null &&
  //       Object.keys(statusFilter).indexOf(dataIndexAfter) === -1 &&
  //       clearFilters
  //     ) {
  //       setValue([null, null]);
  //     }
  //   }, [Object.keys(statusFilter).indexOf(dataIndexAfter) === -1]);

  return (
    <RangePicker value={value} onChange={(val) => onRangePickerChange(val)} />
  );
};
