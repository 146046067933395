import {
  FieldGroupItem,
  Input,
  InputNumber,
  InputPassword,
  Select,
} from '@shared';
import { ConnectionsFileUpload } from '@modules/connections/components';
import { Form } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
const { Item } = Form;

type TComponent = 'input' | 'select' | 'password' | 'input-number' | 'file';
export type TConnectionsFormSettings = Omit<FieldGroupItem, 'field'> & {
  type: TComponent;
  rules?: any[];
  hidden?: boolean;
  relate?: TConnectionsFormSettings[][number]['props'][number]['value'][];
  props?: any;
};

const FormFieldBuilder: React.FC<{
  fieldSetting: TConnectionsFormSettings;
}> = ({ fieldSetting }) => {
  const { type, props = {}, required, key, label, rules = [] } = fieldSetting;
  let Component: React.ElementType | null = null;
  const { value = '' } = props;

  switch (type) {
    case 'input': {
      Component = Input;
      break;
    }
    case 'select': {
      Component = Select;
      break;
    }
    case 'password': {
      Component = InputPassword;
      break;
    }
    case 'input-number': {
      Component = InputNumber;
      break;
    }
    case 'file': {
      Component = ConnectionsFileUpload;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Item
      name={key}
      rules={[
        {
          required,
          message: `Пожалуйста, введите ${label.toLowerCase()}`,
        },
        ...rules,
      ]}
      initialValue={value}
    >
      {Component ? <Component {...props} /> : null}
    </Item>
  );
};

export const useConnectionsFormSettings = (
  settings: TConnectionsFormSettings[],
  furtherProps: Record<TConnectionsFormSettings[][number]['key'], any> = {}
) => {
  const { t } = useTranslation();

  const fieldsData = useMemo<FieldGroupItem[]>(() => {
    return settings
      .filter(({ hidden }) => !hidden)
      .map((field) => {
        const { key, required, label: rawLabel, props: rawProps = {} } = field;
        const label = t(rawLabel);
        const props = {
          ...rawProps,
          ...(furtherProps[key] ?? {}),
        };

        return {
          key,
          required,
          label,
          field: <FormFieldBuilder fieldSetting={{ ...field, label, props }} />,
        };
      });
  }, [settings, furtherProps]);

  return {
    fieldsData,
  };
};
