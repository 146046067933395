import { TConnectionsFormSettings } from '@modules/connections/hooks';
import {
  ANOTHER_DB,
  POSTGRES_DB,
  ORACLE_DB,
} from '@modules/connections/constants';
import { ConnectionsDataSourceModalFields } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';

export const rawDBFormCommonFields: TConnectionsFormSettings[] = [
  {
    type: 'select',
    key: ConnectionsDataSourceModalFields.typeDB,
    label: 'connections.sourceDataModal.formFields.typeDB',
    required: true,
    props: {
      options: [
        {
          value: ORACLE_DB,
          translation: 'connections.sourceDataModal.dbTypes.oracle',
        },
        {
          value: POSTGRES_DB,
          translation: 'connections.sourceDataModal.dbTypes.postgres',
        },
        {
          value: ANOTHER_DB,
          translation: 'connections.sourceDataModal.dbTypes.another',
        },
      ],
    },
  },
];

export const rawDBFormDynamicFields: TConnectionsFormSettings[] = [
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.server,
    label: 'connections.sourceDataModal.formFields.server',
    hidden: true,
    relate: [ORACLE_DB, POSTGRES_DB],
    required: true,
  },
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.port,
    label: 'connections.sourceDataModal.formFields.port',
    hidden: true,
    relate: [ORACLE_DB, POSTGRES_DB],
    required: true,
  },
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.jdbcUrl,
    label: 'connections.sourceDataModal.formFields.jdbcUrl',
    hidden: true,
    relate: [ANOTHER_DB],
    required: true,
  },
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.driver,
    label: 'connections.sourceDataModal.formFields.driver',
    hidden: true,
    relate: [ANOTHER_DB],
    required: true,
  },
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.userName,
    label: 'connections.sourceDataModal.formFields.userName',
    hidden: true,
    relate: [ORACLE_DB, POSTGRES_DB, ANOTHER_DB],
    required: true,
  },
  {
    type: 'password',
    key: ConnectionsDataSourceModalFields.pass,
    label: 'connections.sourceDataModal.formFields.pass',
    hidden: true,
    relate: [ORACLE_DB, POSTGRES_DB, ANOTHER_DB],
    required: true,
  },
  {
    type: 'input',
    key: ConnectionsDataSourceModalFields.furtherParams,
    label: 'connections.sourceDataModal.formFields.furtherParams',
    hidden: true,
    relate: [ORACLE_DB, POSTGRES_DB],
  },
];
