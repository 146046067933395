import React, { VFC } from 'react';
import { ColumnsType } from 'antd/lib/table';
import './communicationChannelsList.scss';
import { Button, SimpleTable } from '@shared';
import { Icon } from '@modules/icon';
import { CommunicationChannelsRecordType } from '../../types/interface';
import {
  useCommunicationChannelsSelector,
  useCommunicationChannelsAction,
} from '../../hooks';

interface ICommunicationChannelsList {}

export const CommunicationChannelsList: VFC<ICommunicationChannelsList> =
  () => {
    const { data, selectedRowKeys } = useCommunicationChannelsSelector();
    const { setSelectedRowKeys } = useCommunicationChannelsAction();

    const columns: ColumnsType<CommunicationChannelsRecordType> = [
      {
        title: 'Название',
        dataIndex: 'name',
      },
      {
        title: 'Код',
        dataIndex: 'code',
      },
      {
        title: 'Расположение',
        dataIndex: 'path',
      },
      {
        title: 'Дата изменения',
        dataIndex: 'changeDt',
      },
      {
        title: 'Пользователь, внесший изменения',
        dataIndex: 'lastChangeByUser',
      },
      {
        dataIndex: 'actions',
        width: '56px',
        render: () => (
          <Button kind="icon">
            <Icon name="icon-more_horizontal" />
          </Button>
        ),
      },
    ];

    return (
      <div className="communication-channels-list">
        <SimpleTable
          columnsData={columns}
          dataSource={data}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          hideSelectAll
        />
      </div>
    );
  };
