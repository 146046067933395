import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { IDataVariables, ITypeMapData } from '@modules/development';
import { IComplexTypeAttributeBase } from '@modules/complexType';
import { Button, DataTypesSelect, Input, ModalBox, SimpleTable } from '@shared';
import { Icon } from '@modules/icon';
import { getDataTypes } from '@modules/references';
import { useDiagramVariablesTypeMapData } from '../../../common/hooks/useDiagramVariablesTypeMapData';
import './AddNewElement.scss';
import { ValidationInfo } from '../../../common/components/ValidationInfo';
import { selectDiagramParameters } from '@modules/development/DevelopmentSlice';
import { getComplexTypesMap } from '@modules/complexType';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { useDiagramUtils } from '@modules/development/modules/Diagrams/hooks';
import { useTranslationPath } from '@modules/languageProvider';

interface IAddElementModal {
  inputVariables?: IDataVariables[];
  addedVariables?: { [key: string]: IDataVariables[] };
  onSave?: (data: IComplexTypeAttributeBase, inner: boolean) => void;
  onClose: () => void;
  onEditRelatedToClick?: () => void;
  relatedTo?: {
    name: string;
    data?: ITypeMapData;
  };
  closeOnSave?: boolean;
}

const TRANSLATE_PATH = 'development.diagram.diagram.modals.addNewElem';

export const AddNewElement: FC<IAddElementModal> = ({
  inputVariables,
  addedVariables,
  onSave,
  onClose,
  onEditRelatedToClick,
  relatedTo,
  closeOnSave,
}) => {
  const { t } = useTranslation();
  const translate = useTranslationPath(TRANSLATE_PATH);
  const dispatch = useAppDispatch();

  const diagramVariables = useAppSelector(selectDiagramParameters);
  useEffect(() => {
    dispatch(getComplexTypesMap(diagramVariables?.map((v) => v.typeId) || []));
  }, [diagramVariables]);
  const diagramVariablesTypes = useDiagramVariablesTypeMapData(
    inputVariables,
    addedVariables
  );

  const { checkUniqueVariableName } = useDiagramUtils();

  const [innerType, setInnerType] = useState<boolean>(true);
  const [currData, setCurrData] = useState<IComplexTypeAttributeBase>({
    attributeName: '',
    complexFlag: false,
    arrayFlag: false,
    primitiveTypeId: null,
    complexTypeVersionId: null,
    description: '',
  });
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(getDataTypes());
  }, []);

  const columns: ColumnsType<IComplexTypeAttributeBase> = [
    {
      title: translate('elemName'),
      dataIndex: 'attributeName',
      key: 'attributeName',
      ellipsis: true,
      width: '20%',
      render: (value) => (
        <Input
          value={value}
          onChange={(e) => {
            setCurrData({ ...currData, attributeName: e.target.value });
          }}
        />
      ),
    },
    {
      title: translate('elemArray'),
      dataIndex: 'arrayFlag',
      key: 'arrayFlag',
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (value) => (
        <Checkbox
          checked={value}
          onChange={(e) => {
            setCurrData({ ...currData, arrayFlag: e.target.checked });
          }}
        />
      ),
    },
    {
      title: translate('elemType'),
      dataIndex: 'primitiveTypeId',
      key: 'primitiveTypeId',
      ellipsis: true,
      width: '20%',
      render: (primitiveTypeId, { complexTypeVersionId }) => (
        <DataTypesSelect
          value={primitiveTypeId || complexTypeVersionId}
          onChange={(dataType) => {
            if (dataType) {
              setCurrData({
                ...currData,
                primitiveTypeId: !dataType.complexFlag ? dataType.typeId : null,
                complexFlag: dataType.complexFlag,
                complexTypeVersionId: dataType.complexFlag
                  ? dataType.typeId
                  : null,
              });
            }
          }}
        />
      ),
    },
    {
      title: translate('relatedToObj'),
      dataIndex: 'relation',
      key: 'relation',
      ellipsis: true,
      width: '20%',
      render: () => (
        <div className="add-new-element__table-edit-cell">
          <span>{relatedTo?.name}</span>
          <Button
            kind="transparent"
            onClick={() => {
              onEditRelatedToClick && onEditRelatedToClick();
            }}
          >
            <Icon name="icon-edit" />
          </Button>
        </div>
      ),
    },
    {
      title: translate('onlyInsideDiagram'),
      dataIndex: 'innerType',
      key: 'innerType',
      ellipsis: true,
      width: '30%',
      render: () => (
        <div className="add-new-element__table-checkbox-cell">
          <Checkbox
            checked={!relatedTo || innerType}
            onChange={(e) => {
              setInnerType(e.target.checked);
            }}
            disabled={!relatedTo}
          />
        </div>
      ),
    },
  ];

  const validatationResult = useMemo(() => {
    const errorMessages: string[] = [];
    const regExp = new RegExp('^[a-zA-Z][a-zA-Z0-9_\\.]*$');
    const attributes = relatedTo?.data?.attributes;

    if (!currData.attributeName) {
      errorMessages.push(translate('errorAttrName'));
    }

    if (!currData.attributeName.match(regExp)) {
      errorMessages.push(translate('errorValidName'));
    }

    if (!currData.primitiveTypeId && !currData.complexTypeVersionId) {
      errorMessages.push(translate('errorTypeId'));
    }

    if (relatedTo && relatedTo?.data) {
      if (attributes?.some((a) => a.name === currData.attributeName)) {
        errorMessages.push(translate('errorUniqueName'));
      }
    }

    if (!relatedTo && !checkUniqueVariableName(currData.attributeName)) {
      errorMessages.push(translate('errorUniqueName'));
    }

    return {
      isValid: !errorMessages.length,
      errorMessages,
    };
  }, [currData, diagramVariablesTypes, relatedTo]);

  const saveData = useCallback(() => {
    const { isValid } = validatationResult;
    if (!isValid) {
      setShowValidationErrors(true);
      return;
    }
    onSave && onSave(currData, innerType);
    closeOnSave && onClose();
  }, [currData, onSave, onClose, closeOnSave, validatationResult, innerType]);

  return (
    <div className="add-new-element">
      <ModalBox>
        <SimpleTable
          columnsData={columns}
          dataSource={[currData]}
          className="add-new-element__table"
        />
      </ModalBox>

      <div className="add-new-element__actions btn-group-bottom">
        <ValidationInfo
          show={Boolean(
            validatationResult.errorMessages.length && showValidationErrors
          )}
          errorMessages={validatationResult.errorMessages}
        />
        <Button
          kind="normal"
          modifiers={['hover-box-shadow']}
          onClick={saveData}
          children={t('development.diagram.diagram.modals.addNewElem.save')}
        />
        <Button
          kind="normal"
          modifiers={['second', 'hover-box-shadow']}
          onClick={onClose}
          children={t('development.diagram.diagram.modals.addNewElem.cancel')}
        />
      </div>
    </div>
  );
};
