import React, { useMemo } from 'react';
import { Table } from 'antd';
import { DataSourcePanel } from '@modules/connections/components/connectionsModalDataSource/components';
import { useTranslation } from 'react-i18next';
import { IToolbar } from '@shared';
import './BrokerMessageTable.scss';
import { ColumnsType } from 'antd/lib/table';
import {
  ConnectionBrokerMessageProceed,
  ConnectionsBrokerMessageDTO,
} from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';

interface IBrokerMessageTable {
  setSelected: React.Dispatch<React.SetStateAction<React.Key[]>>;
  columns: ColumnsType<ConnectionsBrokerMessageDTO>;
  tableData: ConnectionBrokerMessageProceed['parameters'];
  selected: React.Key[];
  toolbar: IToolbar['data'];
}

export const BrokerMessageTable: React.FC<IBrokerMessageTable> = ({
  setSelected,
  columns,
  selected,
  toolbar,
  tableData,
}) => {
  const { t } = useTranslation();

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: false,
      onChange: (selectedRowKeys: React.Key[]) => {
        setSelected(selectedRowKeys);
      },
      selectedRowKeys: selected,
    }),
    [selected]
  );

  return (
    <DataSourcePanel
      title={t('connections.sourceDataModal.brokerMessage.commonParams')}
      panel={{
        key: 'external-service-accordion-1',
        className: 'broken-message__table',
      }}
      toolbar={{ data: toolbar }}
    >
      <Table
        columns={columns}
        dataSource={tableData}
        rowSelection={rowSelection}
        className="balalaika__table"
      />
    </DataSourcePanel>
  );
};
