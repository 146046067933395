import React, {
  Dispatch,
  Key,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  VFC,
} from 'react';
import { InputSearch, SimpleTable, Toolbar, Tooltip, useSearch } from '@shared';
import { ColumnsType } from 'antd/lib/table';
import { Checkbox } from 'antd';
import { useTranslationPath } from '@modules/languageProvider';
import {
  ServiceAdd,
  servicesApi,
  ServiceUpdate,
  ServiceUpdateUserVersion,
  ServiceVersions,
} from '@modules/services';
import {
  UserVersionModal,
  UserVersionSaveData,
} from '@modules/development/modules/Diagrams/components/DiagramBlocks';
import { ExtServiceCallSettingsView } from './ExtServiceCallSettingsView';

const TRANSLATE_PATH =
  'administrationTab.externalServiceCallSettingsModal.versionsTab';

export type OpenedModal =
  | {
      type: 'createUserVersion';
      data?: ServiceUpdateUserVersion;
    }
  | {
      type: 'extServiceView';
      versionId: string;
      versionName: string;
    }
  | null;

export type TableData = {
  key: Key;
  version: string;
  id: string;
  type: string;
  dateLastChange: string;
  userLastChange: string;
  innerObjecttSaveFlag: boolean;
  dependentObjectFlag: boolean;
  diagramId: string;
  data: ServiceUpdateUserVersion;
};

interface IExternalServiceCallSettingsVersions {
  serviceId?: string;
  version?: Key;
  body: ServiceAdd | ServiceUpdate;
  setActiveThirdPanel: Dispatch<SetStateAction<string>>;
}

const ExternalServiceCallSettingsVersions: VFC<IExternalServiceCallSettingsVersions> =
  ({ serviceId, version, body, setActiveThirdPanel }) => {
    const translate = useTranslationPath(TRANSLATE_PATH);

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();
    const [selectedVersion, setSelectedVersion] = useState<TableData[]>();
    const [search, setSearch] = useState('');
    const [versions, setVersions] = useState<ServiceVersions>([]);
    const [openedModal, setOpenedModal] = useState<OpenedModal>(null);

    const [, getRefProps, getInputProps] = useSearch({ search, setSearch });

    const fetchVersions = async (serviceId: string) => {
      setVersions(await servicesApi.getServiceVersions(serviceId));
    };

    const onMockClick = () => {
      console.log('in progress...');
    };

    const onUserVersionSave = async (saveData: UserVersionSaveData) => {
      if (serviceId) {
        const data = { ...body, ...saveData };
        await servicesApi.addServiceUserVersion(serviceId, data);
        fetchVersions(serviceId);
      }
    };

    const deleteUserVersion = useCallback(async () => {
      if (!selectedRowKeys?.length) return;
      await Promise.all(
        selectedRowKeys.map((id) => servicesApi.deleteServiceById(String(id)))
      );
      serviceId && fetchVersions(serviceId);
    }, [serviceId, selectedRowKeys]);

    const updateUserVersion = async (saveData: UserVersionSaveData) => {
      const { versionId, ...data } = saveData;
      if (serviceId && versionId) {
        await servicesApi.updateServiceUserVersion(versionId, data);
        fetchVersions(serviceId);
      }
    };

    const onVersionOpen = async (versionId: string, versionName: string) => {
      if (version === versionId) setActiveThirdPanel('0');
      if (serviceId) {
        setOpenedModal({ type: 'extServiceView', versionId, versionName });
      }
    };

    const render = (value: any) => <Tooltip title={value}>{value}</Tooltip>;

    const actions = [
      {
        icon: 'icon-delete',
        tooltip: translate('toolbar.delete'),
        disabled:
          !selectedRowKeys?.length ||
          selectedVersion?.some((v) => v.type === 'LATEST'),
        onClick: () => deleteUserVersion(),
      },
      {
        icon: 'icon-refresh',
        tooltip: translate('toolbar.refresh'),
        onClick: () => serviceId && fetchVersions(serviceId),
      },
      { isDivider: true },
      {
        icon: 'icon-version_actual',
        tooltip: translate('toolbar.selectVersion'),
        disabled: !selectedRowKeys?.length || selectedRowKeys?.length > 1,
        onClick: () => onMockClick(),
      },
      {
        icon: 'icon-version_save',
        tooltip: translate('toolbar.saveVersion'),
        onClick: () => setOpenedModal({ type: 'createUserVersion' }),
      },
      {
        icon: 'icon-version_open',
        tooltip: translate('toolbar.openVersion'),
        disabled: !selectedRowKeys?.length || selectedRowKeys?.length > 1,
        onClick: () => {
          if (selectedVersion?.[0]) {
            onVersionOpen(
              String(selectedVersion[0].key),
              selectedVersion[0].version
            );
          }
        },
      },
    ];

    const data: TableData[] =
      versions.map((v) => ({
        key: v.versionId,
        version: v.versionName,
        id: v.versionId,
        type: v.versionType,
        dateLastChange: v.changeDt,
        userLastChange: v.lastChangeByUser,
        innerObjecttSaveFlag: !!v.rootObjectVersionId,
        dependentObjectFlag: !!v.rootObjectVersionId,
        diagramId: v.rootObjectVersionId,
        data: {
          serviceId: v.serviceId,
          versionId: v.versionId,
          versionName: v.versionName,
          versionDescription: v.versionDescription,
        },
      })) || [];

    const columns: ColumnsType<TableData> = [
      {
        key: 'version',
        dataIndex: 'version',
        title: translate('objectVersion'),
        ellipsis: true,
        showSorterTooltip: { title: translate('objectVersion') },
        sorter: (a, b) => a.version.localeCompare(b.version),
        render,
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: translate('versionType'),
        ellipsis: true,
        showSorterTooltip: { title: translate('versionType') },
        sorter: (a, b) => a.type.localeCompare(b.type),
        render,
      },
      {
        key: 'dateLastChange',
        dataIndex: 'dateLastChange',
        title: translate('lastSaveDate'),
        ellipsis: true,
        showSorterTooltip: { title: translate('lastSaveDate') },
        sorter: (a, b) => a.dateLastChange.localeCompare(b.dateLastChange),
        render,
      },
      {
        key: 'userLastChange',
        dataIndex: 'userLastChange',
        title: translate('theUserWhoSavedTheChanges'),
        ellipsis: true,
        showSorterTooltip: { title: translate('theUserWhoSavedTheChanges') },
        sorter: (a, b) => a.userLastChange.localeCompare(b.userLastChange),
        render,
      },
      {
        key: 'dependentObjectFlag',
        dataIndex: 'dependentObjectFlag',
        title: translate('dependentObjectAttribute'),
        ellipsis: true,
        showSorterTooltip: { title: translate('dependentObjectAttribute') },
        align: 'center',
        sorter: (a, b) =>
          Number(a.dependentObjectFlag) - Number(b.dependentObjectFlag),
        render: (value) => <Checkbox checked={value} />,
      },
      {
        key: 'diagramId',
        dataIndex: 'diagramId',
        title: translate('idOfTheRootDiagramForTheDependentObject'),
        ellipsis: true,
        showSorterTooltip: {
          title: translate('idOfTheRootDiagramForTheDependentObject'),
        },
        sorter: (a, b) => a.diagramId.localeCompare(b.diagramId),
        render,
      },
    ];

    useEffect(() => {
      serviceId && fetchVersions(serviceId);
    }, [serviceId]);

    return (
      <>
        <div className="diagram-versions" {...getRefProps()}>
          <div className="external-service-call-settings-modal__row">
            <Toolbar
              data={actions}
              search={<InputSearch {...getInputProps()} width={420} />}
            />
          </div>

          <div className="diagram-versions__table">
            <SimpleTable
              columnsData={columns}
              dataSource={data}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={(key, selectedRows: TableData[]) => {
                setSelectedRowKeys(key);
                setSelectedVersion(selectedRows);
              }}
              onDoubleClick={({ data, type }: TableData) => {
                if (type === 'USER_LOCAL') {
                  setOpenedModal({ type: 'createUserVersion', data });
                }
              }}
            />
          </div>
        </div>
        {openedModal?.type === 'createUserVersion' && (
          <UserVersionModal
            modalProps={{ type: openedModal.type }}
            onSave={onUserVersionSave}
            onClose={() => setOpenedModal(null)}
            hideCheckbox
            closeOnSave
            data={openedModal.data}
            onUpdate={updateUserVersion}
          />
        )}

        {openedModal?.type === 'extServiceView' && (
          <ExtServiceCallSettingsView
            modalProps={{ type: openedModal.type }}
            versionId={openedModal.versionId}
            versionName={openedModal.versionName}
            onClose={() => setOpenedModal(null)}
          />
        )}
      </>
    );
  };

export default ExternalServiceCallSettingsVersions;
