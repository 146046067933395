import { Key } from 'react';

export interface ICustomDataTypes {
  key: Key;
  addNewRequested?: boolean;
  selectedRowTable: Key[];
  search?: string;
}

export const CUSTOMDATATYPES_OBJTYPE = 'customDataType';
