import { v4 as uuidv4 } from 'uuid';
import { AppThunk } from '@modules/store';
import { INotification } from '../types/interface';
import { notificationActions } from '../NotificationProviderSlice';

export const showNotification =
  (notification: INotification): AppThunk =>
  async (dispach) => {
    const key = uuidv4();

    dispach(
      notificationActions.addNotification({
        key,
        notification: {
          type: notification.type || 'info',
          message: notification.message,
          translate: notification.translate,
          translateOptions: notification.translateOptions,
          duration: notification.duration,
        },
      })
    );

    if (notification.duration) {
      setTimeout(() => {
        dispach(notificationActions.deleteNotification(key));
      }, notification.duration);
    }
  };
