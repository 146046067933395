import { ITabPanelItem } from '@shared';

export const TRANSLATE_PATH =
  'development.diagram.diagram.modals.communication';

export const MAIN_CLASS = 'communication-modal';

export const tabItems: ITabPanelItem[] = [
  {
    text: 'Основные настройки',
    key: 'general-settings',
    translation:
      'development.diagram.diagram.modals.communication.generalSettings',
  },
  {
    text: 'Переменные коммуникации',
    key: 'communication-variables',
    translation:
      'development.diagram.diagram.modals.communication.communicationVariables',
  },
  {
    text: 'Выходные переменные',
    key: 'output-variables',
    translation:
      'development.diagram.diagram.modals.communication.outputVariables',
  },
];

export const tabKeys = tabItems.map((t) => t.key);

export const channels = ['EMAIL', 'PUSH', 'SMS'];

export const channelOptions = channels.map((value) => ({ value }));
