import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Toolbar } from '@shared//Toolbar/Toolbar';
import { Navigation } from '@modules/development/Navigation';
import {
  addCustomCode,
  deleteCustomCode,
  getCustomCodes,
  getSelectedCustomCodeRow,
  onChangeCustomCodeSearch,
  onSelectedCustomCodeRow,
  removeOpenObject,
  removeOpenTab,
  selectOpenTabs,
  onSelectedCustomDataTypesRows,
  addCustomDataType,
  onChangeCustomDataTypesSearch,
  getAggregates,
  onSelectedAggregatesRow,
  selectAggregatesSelectedRows,
  selectAggregatesFilteredDataKeys,
  onChangeAggregatesSearch,
  addAgregate,
  deleteAggregate,
  selectAggregatesData,
  selecCustomCodeFilteredDataKeys,
  addSchema,
  onSelectedSchemaRows,
} from '../../DevelopmentSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  selectCustomDataTypesSelectedRows,
  deletCustomDataType,
  getCustomDataTypes,
  selectCustomDataTypesFilteredDataKeys,
} from '../../modules/CustomDataTypes/reducers';
import './SolutionLists.scss';
import { ConfirmModal, IConfirmModalProps } from '@shared';
import { useAppSelector } from '@modules/store/hooks';
import { selectRoles, rolesList } from '@modules/services';
import {
  AGGREGATES_PATH,
  CUSTOM_CODES_PATH,
} from '../../routing/developmentConstants';
import {
  deleteSchema,
  selectSchemas,
  selectSchemasSelectedRows,
} from '@modules/development/modules/Schemas/reducers';
import { getSchemas } from '@modules/schema/schemaSlice';

interface ISolutionLists {}

const SolutionLists: React.FC<ISolutionLists> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const aggregates = useSelector(selectAggregatesData);
  const [modalProps, setModalProps] = useState<IConfirmModalProps | null>(null);
  const roles = useAppSelector(selectRoles);
  const openTabs = useSelector(selectOpenTabs);
  const pathname = history.location.pathname.split('/')[2];

  // TODO нужно исправить этот ужас
  let selectedRowTable: React.Key[];
  let tableRowKeys: any[];
  // Ну раз "этот ужас" всё равно будет исправляться, то я еще своего добавлю))0)
  // И я
  if (pathname == 'custom-code-list') {
    selectedRowTable = useSelector(getSelectedCustomCodeRow);
    tableRowKeys = useSelector(selecCustomCodeFilteredDataKeys);
  } else if (pathname == 'aggregates') {
    selectedRowTable = useSelector(selectAggregatesSelectedRows);
    tableRowKeys = useSelector(selectAggregatesFilteredDataKeys);
  } else if (pathname == 'schemas') {
    selectedRowTable = useSelector(selectSchemasSelectedRows);
    tableRowKeys = []; // useSelector(selectAggregatesFilteredDataKeys);
  } else {
    selectedRowTable = useSelector(selectCustomDataTypesSelectedRows);
    tableRowKeys = useSelector(selectCustomDataTypesFilteredDataKeys);
  }

  const objectMap = useMemo(() => {
    return {
      'custom-code-list': {
        title: 'development.solutionLists.sidePanel.customCodes',
        onSelectedRow: onSelectedCustomCodeRow,
        onChangeSearch: onChangeCustomCodeSearch,
        add: addCustomCode,
        delete: deleteCustomCode,
        refresh: getCustomCodes,
      },
      'custom-data-types': {
        title: 'development.solutionLists.sidePanel.customDataTypes',
        onSelectedRow: onSelectedCustomDataTypesRows,
        onChangeSearch: onChangeCustomDataTypesSearch,
        add: addCustomDataType,
        delete: deletCustomDataType,
        refresh: getCustomDataTypes,
      },
      aggregates: {
        title: 'development.solutionLists.sidePanel.aggregates',
        onSelectedRow: onSelectedAggregatesRow,
        onChangeSearch: onChangeAggregatesSearch,
        add: addAgregate,
        delete: deleteAggregate,
        refresh: getAggregates,
      },
      schemas: {
        title: 'Схемы',
        onSelectedRow: onSelectedSchemaRows,
        onChangeSearch: onChangeCustomCodeSearch,
        add: addSchema,
        delete: deleteSchema,
        refresh: getSchemas,
      },
    } as {
      [key: string]: {
        title: string;
        onSelectedRow: ActionCreatorWithPayload<any, string>;
        onChangeSearch: ActionCreatorWithPayload<any, string>;
        add: ActionCreatorWithPayload<any, string>;
        delete: any;
        refresh: any;
      };
    };
  }, []);

  useEffect(() => {
    setTitle(objectMap[pathname]?.title || '');
  }, [pathname]);

  const onToolbarCheckboxAllChange = () => {
    dispatch(
      objectMap[pathname].onSelectedRow({
        keys:
          selectedRowTable.length === tableRowKeys.length ? [] : tableRowKeys,
      })
    );
  };

  const onAddClick = () => {
    const uuid = uuidv4();
    dispatch(objectMap[pathname].add({ key: uuid }));
    if (pathname == 'custom-code-list') {
      setTimeout(
        () =>
          history.push({
            pathname: `${CUSTOM_CODES_PATH}/${uuid}`,
            state: { draft: true },
          }),
        200
      );
    } else if (pathname == 'aggregates') {
      setTimeout(() => history.push(`${AGGREGATES_PATH}/${uuid}`), 200);
    }
  };

  const addButtonDisabled = useMemo(() => {
    if (pathname == 'custom-code-list') {
      return !roles.includes(rolesList.SCRIPT_CREATE);
    } else if (pathname == 'aggregates') {
      return !roles.includes(rolesList.AGGREGATE_CREATE);
    } else if (pathname == 'custom-data-types') {
      return !roles.includes(rolesList.COMPLEX_TYPE_CREATE);
    } else {
      return false;
    }
  }, [roles, pathname]);

  const deleteButtonDisabled = useMemo(() => {
    if (pathname == 'custom-code-list') {
      return !roles.includes(rolesList.SCRIPT_DELETE);
    } else if (pathname == 'aggregates') {
      return !roles.includes(rolesList.AGGREGATE_DELETE);
    } else if (pathname == 'custom-data-types') {
      return !roles.includes(rolesList.COMPLEX_TYPE_DELETE);
    } else {
      return false;
    }
  }, [roles, pathname]);

  const getAggregatesText = async (data: any[]) => {
    try {
      return data
        .map((id) => {
          const item = aggregates?.find((item) => item.aggregateId === id);
          if (item?.aggregateRelations) {
            return {
              aggregateName: item.aggregateName,
              aggregateRelations: item.aggregateRelations,
            };
          }
        })
        .filter(Boolean)
        .map((item) => {
          return item?.aggregateRelations.map((relation) => {
            if (!relation.diagramName) return '';
            return `Агрегат "${item.aggregateName}" используется в диаграмме "${relation.diagramName}"`;
          });
        })
        .flat()
        .join('</br>');
    } catch (error) {
      return String(error);
    }
  };

  const onDeleteClick = async () => {
    console.log('qq');
    if (!modalProps) {
      let contentText = t('development.solutionLists.confirmDeleteText', {
        name: t(objectMap[pathname].title).toLowerCase(),
      });
      let additionalContextText = '';

      if (pathname == 'aggregates') {
        additionalContextText = await getAggregatesText(selectedRowTable);
      }

      contentText = additionalContextText
        ? contentText + '</br></br>' + additionalContextText
        : contentText;

      return setModalProps({
        type: 'confirm-modal',
        width: additionalContextText ? '740px' : '440px',
        okButtonText: t('modals.yes'),
        closeButtonText: t('modals.no'),
        contentText: contentText,
        kind: 'info',
      });
    }

    selectedRowTable.forEach(async (key) => {
      const isOpen = openTabs.find((item) => item.key === key);
      if (isOpen) {
        // если объект открыт, то удалить его из списка открытых табов
        // и из списка открытых объектов
        dispatch(removeOpenTab({ key }));
        dispatch(removeOpenObject({ key }));
      }
      // в зависимости от открытой вкладки удалить кастомный код или диаграмму
      dispatch(objectMap[pathname].delete(key));
      dispatch(objectMap[pathname].onSelectedRow({ keys: [] }));
    });

    // TODO этот ужас надо исправить
    setTimeout(() => dispatch(objectMap[pathname].refresh()), 200);
  };

  const onRefreshClick = () => {
    dispatch(objectMap[pathname].refresh());
  };

  const onSelectionViewClick = () => {};

  const diagramsToolbar = [
    {
      isDivider: true,
    },
    {
      icon: 'icon-add',
      tooltip: t('actions.add'),
      onClick: onAddClick,
      disabled: addButtonDisabled,
    },
    {
      icon: 'icon-delete',
      tooltip: t('actions.delete'),
      onClick: onDeleteClick,
      disabled: deleteButtonDisabled,
    },
    {
      icon: 'icon-refresh',
      tooltip: t('actions.refresh'),
      onClick: onRefreshClick,
    },
    ...(pathname !== 'custom-data-types'
      ? [
          {
            icon: 'icon-selection_view',
            onClick: onSelectionViewClick,
          },
        ]
      : []),
  ];

  return (
    <>
      <div className="solution-lists">
        <div className="solution-lists__top">
          <h1 className="solution-lists__title">{t(title)}</h1>
        </div>
        <div className="solution-lists__row">
          <Toolbar
            data={diagramsToolbar}
            isShowCheckboxAll
            CheckboxAllIndeterminate={
              selectedRowTable.length !== tableRowKeys.length &&
              selectedRowTable.length !== 0
            }
            CheckboxAllChecked={selectedRowTable.length === tableRowKeys.length}
            onCheckboxAllChange={onToolbarCheckboxAllChange}
          />
        </div>
        <div className="solution-lists__content">
          <Navigation roles={roles} />
        </div>
      </div>

      {modalProps && (
        <ConfirmModal
          modalProps={modalProps}
          onOk={onDeleteClick}
          onClose={() => setModalProps(null)}
        />
      )}
    </>
  );
};

export { SolutionLists };
