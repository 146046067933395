import { FC } from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import './ArrayFlag.scss';

export const ArrayFlag: FC = () => {
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="topLeft"
      title={t('development.diagram.diagram.common.arrayFlag')}
    >
      <span className="array-flag">[ ]</span>
    </Tooltip>
  );
};
