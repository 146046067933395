import React, { Key, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './AggregatesList.scss';
import {
  selectCurrentObjectReadonly,
  deleteAggregate,
  getAggregates,
  onChangeAggregatesSearch,
  onSelectedAggregatesRow,
  removeOpenObject,
  removeOpenTab,
  selectAggregates,
  selectAggregatesSelectedRows,
  selectAggregatesFilteredData,
  selectOpenTabs,
  setReadonly,
} from '@modules/development/DevelopmentSlice';
import { AggregatesDataGrid } from './components/AggregatesDataGrid';
import { Portal, useSearch } from '@shared';
import { getDataTypes } from '@modules/references';
import { selectRoles, rolesList } from '@modules/services';
import { useAppSelector } from '@modules/store';
import { AGGREGATES_CORE_PATH } from './routing/aggregatesConstants';
import { aggregatesApi, IDataTableList } from '@modules/development';
import { tryCatchDecorator } from '@modules/development/utils';

interface IAggregatesList {
  isSingleChoice?: boolean;
  allowedGroupingElements?: string[];
}

export const AggregatesList: React.FC<IAggregatesList> = ({
  isSingleChoice,
  allowedGroupingElements,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const aggregates = useSelector(selectAggregates);
  const filteredAggregates = useSelector(selectAggregatesFilteredData);
  const openTabs = useSelector(selectOpenTabs);
  const readonly = useSelector(selectCurrentObjectReadonly);
  const roles = useAppSelector(selectRoles);

  const onClickOpen = (data: IDataTableList) => {
    if (!roles.includes(rolesList.AGGREGATE_READ)) {
      return;
    } else {
      if (readonly) dispatch(setReadonly({ readonly: false }));
      history.push(`${AGGREGATES_CORE_PATH}/${data.key}`);
    }
  };

  const onClickOpenViewingOnly = (data: IDataTableList) => {
    dispatch(setReadonly({ readonly: true }));
    history.push({
      pathname: `${AGGREGATES_CORE_PATH}/${data.key}`,
      state: { readonly: true },
    });
  };

  const onClickDelete = async (data: IDataTableList) => {
    const relations = await tryCatchDecorator(dispatch, async () => {
      return await aggregatesApi.getRelations(data.key);
    });
    if (!relations) return;

    const confinm = confirm(
      `Агрегат используется в следующих диаграммах: ${relations
        ?.map((r: any) => r.diagramName)
        .join(', ')}`
    );

    if (!confinm) return;

    const isOpen = openTabs.find((item) => item.key === data.key);
    if (isOpen) {
      // если диаграмма открыта, то удалить ее из списка открытых табов
      // и из списка открытых объектов
      await dispatch(removeOpenTab({ key: data.key }));
      await dispatch(removeOpenObject({ key: data.key }));
    }
    await dispatch(deleteAggregate(data.key));
    await dispatch(getAggregates());
  };

  const onSearch = (search: string) => {
    dispatch(onChangeAggregatesSearch({ search }));
  };

  const processedData = useMemo(() => {
    return filteredAggregates
      .map((d) => ({
        key: d.key,
        aggregateName: d.aggregateName,
        aggregateDescription: d.aggregateDescription,
        aggregateFunction: d.aggregateJson.aggregateFunction,
        aggregateVariableType: d.aggregateJson.aggregateVariableType,
        groupingElement: d.aggregateJson.groupingElement,
        versionId: d.versionId,
        diagramCalcName: d.aggregateRelations
          ?.filter((r) => r.AggregateType === 'CALC')
          .map((r) => r.diagramName)
          .join(', '),
        diagramReadName: d.aggregateRelations
          ?.filter((r) => r.AggregateType === 'READ')
          .map((r) => r.diagramName)
          .join(', '),
        disabled:
          allowedGroupingElements &&
          !allowedGroupingElements.includes(d.aggregateJson.groupingElement),
      }))
      .sort((a, b) => {
        return !a?.disabled === !b?.disabled ? 0 : !a?.disabled ? -1 : 1;
      });
  }, [aggregates]);

  const onSelectedRowKeys = (keys: Key[]) => {
    dispatch(onSelectedAggregatesRow({ keys }));
  };

  useEffect(() => {
    dispatch(getAggregates());
    roles.includes(rolesList.DICTIONARY_READ_ALL_DATA_TYPES) &&
      dispatch(getDataTypes());
    return () => onSearch('');
  }, []);

  const [renderSearchInput, getRefProps] = useSearch({
    search: aggregates.search,
    setSearch: onSearch,
  });

  return (
    <div className="aggregates-list" {...getRefProps()}>
      <Portal id="toolbarSearch">{renderSearchInput()}</Portal>
      <AggregatesDataGrid
        data={processedData}
        isSingleChoice={isSingleChoice || false}
        onClickOpen={onClickOpen}
        onClickOpenViewingOnly={onClickOpenViewingOnly}
        onClickDelete={onClickDelete}
        selectedRow={selectAggregatesSelectedRows}
        onSelectedRowKeys={onSelectedRowKeys}
        onDoubleClick={onClickOpen}
      />
    </div>
  );
};
