/* eslint-disable require-jsdoc */
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@shared';
import { LanguageContext, useTranslationPath } from '@modules/languageProvider';
import { Config } from '../types/interface';

class UserGuideConfig {
  public config: any;

  constructor() {
    this.config;
    this.fetchConfig();
  }

  private async fetchConfig() {
    const response = await fetch('/userGuideConfig.json');
    this.config = await response.json();
  }
}

const userGuideConfig = new UserGuideConfig();

export const useUserGuide = () => {
  const translate = useTranslationPath('userGuide.sections');
  const { language } = useContext(LanguageContext);
  const [config, setConfig] = useState<Config>({});

  const path = useMemo(() => `/user-guide_${language}.pdf`, [language]);

  const fetchConfig = useCallback(async () => {
    setConfig(userGuideConfig.config);
  }, [language]);

  const getLink = useCallback(
    (section: string, children: ReactNode) => {
      return (
        <Tooltip title={translate(config?.[section]?.title)}>
          <Link to={`${path}${config?.[section]?.anchor}`} target="_blank">
            {children}
          </Link>
        </Tooltip>
      );
    },
    [path, config, translate]
  );

  useEffect(() => {
    fetchConfig();
  }, [language]);

  return [getLink] as const;
};
