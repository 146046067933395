import { Key } from 'react';
import {
  // DiagramNodeTypes,
  // IDiagramNodeBase,
  InOutParameter,
} from '../../../../types';
import { IAction } from '@modules/store';
import { findCurrentDiagram } from '../../../../utils';
import { IDevelopmentState } from '@modules/development/DevelopmentSlice';

const diagramDataReducers = {
  addDiagramDataRow(
    state: IDevelopmentState,
    action: IAction<{ rowData: InOutParameter }>
  ) {
    const { rowData } = action.payload;
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram) {
      currentDiagram.inOutParameters = [
        ...(currentDiagram.inOutParameters || []),
        rowData,
      ];
    }
  },

  deleteDiagramDataRow(
    state: IDevelopmentState,
    action: IAction<{ keys: Key[] }>
  ) {
    const { keys } = action.payload;
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram) {
      currentDiagram.inOutParameters = [
        ...(currentDiagram.inOutParameters || []).filter(
          (parameter) => !keys.includes(parameter.parameterId)
        ),
      ];
    }

    // В блоке ветвления нет инфы о ключе атрибута, только название
    // const values = keys.map((key) => {
    //   return [
    //     ...(currentObject?.diagramJson as IDiagramData).inputVariables,
    //     ...(currentObject?.diagramJson as IDiagramData).outputVariables,
    //   ].find((v: any) => v?.meta?.key === key)?.variableName;
    // });
    // const resetBranchingNode = (
    //   node: IDiagramNodeBase<any>,
    //   key: string
    // ) => ({
    //   ...node,
    //   link: [],
    //   properties: {
    //     ...(currentObject?.diagramJson as IDiagramData).nodes[key].properties,
    //     condition: null,
    //     branches: null,
    //     defaultPath: null,
    //   },
    //   meta: {
    //     ...(currentObject?.diagramJson as IDiagramData).nodes[key].metaInfo,
    //     links: {},
    //   },
    // });
    // const resetRulesetNode = (node: IDiagramNodeBase, key: string) => ({
    //   ...node,
    //   properties: {
    //     ...(currentObject?.diagramJson as IDiagramData).nodes[key].properties,
    //     rules: (currentObject?.diagramJson as IDiagramData).nodes[
    //       key
    //     ].properties?.rules?.map((rule: any) => {
    //       if (!values.includes(rule.ruleObject)) {
    //         return rule;
    //       }
    //       return {
    //         applyRule: rule.applyRule,
    //         ruleName: rule.ruleName,
    //         ruleCode: rule.ruleCode,
    //         ruleTypeId: rule.ruleTypeId,
    //         ruleObject: null,
    //         ruleDescription: rule.ruleDescription,
    //         ruleExpression: rule.ruleExpression,
    //       };
    //     }),
    //   },
    // });
    // Object.entries(currentObject?.diagramJson?.nodes || {}).forEach(
    //   ([key, node]) => {
    //     if (node.nodeType === DiagramNodeTypes.Branching) {
    //       if (values.includes(node.properties?.condition)) {
    //         currentObject.diagramJson = {
    //           ...(currentObject?.diagramJson as IDiagramData),
    //           nodes: {
    //             ...(currentObject?.diagramJson as IDiagramData).nodes,
    //             [key]: resetBranchingNode(node, key),
    //           },
    //         };
    //       }
    //     }
    //     // else if (node.nodeType === DiagramBlockType.Ruleset) {
    //     //   currentObject.diagramJson = {
    //     //     ...(currentObject?.diagramJson as IDiagramData),
    //     //     nodes: {
    //     //       ...(currentObject?.diagramJson as IDiagramData).nodes,
    //     //       [key]: resetRulesetNode(node, key),
    //     //     },
    //     //   };
    //     // }
    //   }
    // );
  },

  updateDiagramData(
    state: IDevelopmentState,
    action: IAction<{ rowData: InOutParameter }>
  ) {
    // TODO при изменении названия атрибута
    // проверять его на уникальность в рамках диаграммы
    const { rowData } = action.payload;
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram) {
      const inOutParameters = (currentDiagram.inOutParameters || []).map((p) =>
        rowData.parameterId !== p.parameterId ? p : rowData
      );

      currentDiagram.inOutParameters = inOutParameters;
      currentDiagram.isSaved = false;
    }
  },

  setSelectedRowKeysDiagramData(
    state: IDevelopmentState,
    action: IAction<Key[]>
  ) {
    const currentObject = findCurrentDiagram(state);

    if (currentObject) {
      currentObject.meta = {
        ...currentObject.meta,
        diagramDataSelectedRowKeys: action.payload,
      };
    }
  },

  updateDiagramErrorResponseFlag(
    state: IDevelopmentState,
    action: IAction<boolean>
  ) {
    const currentDiagram = findCurrentDiagram(state);

    if (currentDiagram) {
      currentDiagram.errorResponseFlag = action.payload;
      currentDiagram.isSaved = false;
    }
  },
};

export default diagramDataReducers;
