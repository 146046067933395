import React, { Key, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { DataSource } from './interfaces';
import { Icon } from '@modules/icon';
import {
  IDataProviderExt,
  IDataProviderAttr,
  IDataProviderTables,
  IDataProviderObjects,
} from '@modules/dataProvider';
import { useAppSelector } from '@modules/store';
import { sortHelper } from '@modules/utils';
import { selectRoles, rolesList } from '@modules/services';
import { dataProviderApi } from '@modules/dataProvider';
import { useAdministrationAction } from '../../hooks';
import { Sort } from '../../types/interface';

export const useDataSourcesTable = (dataProvider: IDataProviderObjects) => {
  const { t } = useTranslation();
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[] | undefined>();
  const roles = useAppSelector(selectRoles);
  const {
    setDataProviderSearch,
    setDataProviderSelectedRowKeys,
    setDataProviderSort,
  } = useAdministrationAction();

  const columns = useMemo<ColumnsType<DataSource>>(() => {
    return sortHelper(
      [
        {
          dataIndex: 'dataSourceName',
          key: 'dataSourceName',
          title: t('administrationTab.dataSources.dataSourceName'),
          sorter: (a, b) => a.dataSourceName.localeCompare(b.dataSourceName),
          render: (value, record) => (
            <React.Fragment>
              {record.icon && <Icon name={record.icon} />} {value}
            </React.Fragment>
          ),
        },
      ] as ColumnsType<DataSource>,
      dataProvider.sort
    );
  }, [t, dataProvider.sort]);

  const data = useMemo<DataSource[]>(() => {
    if (!dataProvider.data) return [];

    const result = dataProvider.data.map((dataProvider: IDataProviderExt) => ({
      dataSourceName: dataProvider.sourceName,
      key: dataProvider.sourceId,
      children: dataProvider?.children?.map(
        (dataProviderTable: IDataProviderTables) => ({
          dataSourceName: dataProviderTable.tableName,
          icon: 'icon-db',
          key: `${dataProvider.sourceId}:${dataProviderTable.tableName}`,
          selectable: false,
        })
      ),
    }));

    if (dataProvider.search) {
      const filteredResult = result
        .map((item) => ({
          ...item,
          children: item.children?.filter(
            (child) =>
              !dataProvider.search ||
              child.dataSourceName
                .toLowerCase()
                .includes(dataProvider.search.toLowerCase())
          ),
        }))
        .filter((item) => item.children?.length);

      const keys = filteredResult
        .map(({ children, key }) => children?.length && (key as Key))
        .filter(Boolean) as Key[];

      setExpandedRowKeys(keys.length ? keys : undefined);

      return filteredResult;
    } else {
      setExpandedRowKeys(undefined);
    }

    if (!roles.includes(rolesList.DATA_PROVIDER_READ_TABLES)) {
      return result.map((item) => ({
        ...item,
        children: undefined,
      }));
    } else {
      return result;
    }
  }, [dataProvider.data, dataProvider.search]);

  const rowKeys = useMemo(
    () =>
      data
        .map((d) => [d.key, ...(d?.children?.map((cd) => cd.key) || [])])
        .flat(),
    [data]
  );

  return [
    columns,
    data,
    rowKeys,
    dataProvider.selectedRowKeys,
    (keys: Key[]) => setDataProviderSelectedRowKeys(keys),
    dataProvider.search,
    (search: string) => setDataProviderSearch(search),
    (sort: Sort) => setDataProviderSort(sort),
    expandedRowKeys,
  ] as const;
};

export const useDataSourcesInfoTable = ({
  sourceId,
  tableName,
}: {
  sourceId: string;
  tableName: string;
}) => {
  const { t } = useTranslation();

  const [attributes, setAttributes] = useState<IDataProviderAttr[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const fetchAttributes = (sourceId: string, tableName: string) => {
    setIsLoading(true);

    dataProviderApi
      .getDataProviderAttr(sourceId, tableName)
      .then((data) => setAttributes(data))
      .catch(() => null)
      .finally(() => setIsLoading(false));
  };

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        dataIndex: 'attributeName',
        key: 'attributeName',
        title: t('administrationTab.dataSources.attributeName'),
        width: '70%',
        sorter: (a, b) => a.attributeName.localeCompare(b.attributeName),
      },
      {
        dataIndex: 'type',
        key: 'type',
        title: t('administrationTab.dataSources.type'),
        width: '30%',
        sorter: (a, b) => a.type.localeCompare(b.type),
      },
    ];
  }, [t]);

  const data = useMemo(() => {
    return attributes
      .map((item) => ({
        attributeName: item.columnName,
        type: item.dataType,
      }))
      .filter(
        (item) =>
          !search ||
          item.attributeName.toLowerCase().includes(search.toLowerCase())
      );
  }, [t, attributes, search]);

  useEffect(() => {
    sourceId && tableName && fetchAttributes(sourceId, tableName);

    return () => {
      setAttributes([]);
    };
  }, [sourceId, tableName]);

  return [
    columns,
    data,
    isLoading,
    fetchAttributes,
    search,
    setSearch,
  ] as const;
};
