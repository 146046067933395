import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'querystring';
import './CustomCode.scss';
import { CustomCodeNavigation } from './CustomCodeNavigation';
import {
  CloseObjectButton,
  ICustomCode as ICustomCodeData,
} from '@modules/development';
import { Modal, SaveAsModal, Toolbar, TabPanel } from '@shared';
import { Icon } from '@modules/icon';
import { getDataTypes } from '@modules/references';
import {
  selectCustomCodes,
  selectOpenTabs,
  addOpenTab,
  addOpenCustomCodeObject,
  openObjects,
  setCustomCodeKey,
  setCurrentCustomCodeTitle,
  selectCurrentObjectReadonly,
  diagram,
  selectCurrentObject,
  editCustomCode,
  updateOpenTab,
  validateCustomCode,
  createCustomCode,
  getCustomCodes,
  getCustomCode,
  removeOpenObject,
} from '../../../../DevelopmentSlice';
import { selectRoles, rolesList } from '@modules/services';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { useUserGuide } from '@modules/userGuide';
import { CUSTOM_CODES_CORE_PATH } from '../../routing/сustomCodesConstants';
import { tabs as baseTabs } from './data';

interface ICustomCode {}

const CustomCode: React.FC<ICustomCode> = () => {
  const [activeThirdPanel, setActiveThirdPanel] = useState('');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<any>();
  const currentCustomCode = useSelector(diagram);
  const customCodes = useSelector(selectCustomCodes);
  const customCode = useSelector(selectCurrentObject) as ICustomCodeData;
  const openAllObjects = useSelector(openObjects);
  const openTabs = useSelector(selectOpenTabs);
  const isReadOnly = useSelector(selectCurrentObjectReadonly);
  const roles = useAppSelector(selectRoles);
  const [getLink] = useUserGuide();
  const [title, setTitle] = useState('');

  const tab = location.pathname.split('/')[4];

  const getCustomCodeRequest = async (key: string, versionName?: string) => {
    await dispatch(getCustomCode({ id: key, versionName }));
  };

  const onSaveClick = async (title?: string) => {
    if (title) {
      const checkTitle = customCodes.data.some((i) => i.title === title);
      if (checkTitle) return alert('название уже занято');
    }

    const body = {
      scriptJson: JSON.stringify(customCode.scriptJson),
      scriptName: title || customCode.title,
      variables: customCode.variables,
    };

    const tab = {
      key: customCode.code,
      tab: { text: title || customCode.title },
    };

    if (customCode.draft || title) {
      if (!roles.includes(rolesList.SCRIPT_CREATE)) {
        return;
      } else {
        const response = await dispatch<any>(createCustomCode(body));
        await dispatch(getCustomCodes());
        dispatch(removeOpenObject({ key: customCode.key }));
        setShowModal(false);
        return history.push(
          `${CUSTOM_CODES_CORE_PATH}/${response.payload.uuid}?create=${customCode.key}`
        );
      }
    } else {
      if (!roles.includes(rolesList.SCRIPT_UPDATE)) {
        return;
      } else {
        await dispatch(editCustomCode({ id: customCode.versionId, body }));
        await dispatch(getCustomCodes());
      }
    }

    dispatch(updateOpenTab(tab));
  };

  const onSaveAsClick = () => setShowModal(true);

  const onValidateClick = async () => {
    const body = {
      variables: customCode.variables,
      scriptJson: JSON.stringify(customCode.scriptJson),
      scriptId: customCode.scriptId,
    };

    dispatch(validateCustomCode(body));
  };

  const onRefreshClick = async () => {
    await dispatch(getCustomCodes());
  };

  const tabs = useMemo(() => {
    if (isReadOnly) {
      return baseTabs.filter((tab) => tab.key !== '3');
    } else return baseTabs;
  }, [baseTabs, isReadOnly]);

  const isTitleOccupied = useMemo(
    () =>
      customCodes.data.some((i) => {
        i.key !== customCode.key && i.title === customCode.title;
      }),

    [customCodes, customCode]
  );

  const customCodeForCompare = useMemo(() => {
    return { ...customCode };
  }, [customCodes]);

  const isChanged = useMemo(() => {
    const isChanged =
      JSON.stringify(customCode) !== JSON.stringify(customCodeForCompare);
    return isChanged;
  }, [customCode, customCodes]);

  const isValid = useMemo(() => {
    if (customCode) {
      // if (!customCode.scriptJson?.inputVariables?.length) return false;
      // if (!customCode.scriptJson?.outputVariables?.length) return false;

      // const isInputVariablesValid = !customCode.scriptJson.inputVariables.some(
      //   (item) => !item.scriptVariable || !item.scriptVariableTypeId
      // );

      // const isOutputVariablesValid =
      //   !customCode.scriptJson.outputVariables.some(
      //     (item) => !item.scriptVariable || !item.scriptVariableTypeId
      //   );

      if (isTitleOccupied) return false;
      if (!customCode.title) return false;
      if (!customCode.scriptJson.name) return false;
      // if (!customCode.scriptJson.scriptText) return false;
      // if (!isInputVariablesValid) return false;
      // if (!isOutputVariablesValid) return false;
    }
    return true;
  }, [customCode]);

  const actions = useMemo(() => {
    const actions = [
      {
        icon: 'icon-save',
        disabled: isReadOnly || !isChanged || !isValid,
        tooltip: t('actions.save'),
        onClick: () => onSaveClick(),
      },
      {
        icon: 'icon-save_as',
        disabled:
          !isValid ||
          isTitleOccupied ||
          !roles.includes(rolesList.SCRIPT_CREATE),
        tooltip: t('actions.saveAs'),
        onClick: onSaveAsClick,
      },
      {
        icon: 'icon-validate',
        disabled: isReadOnly || !roles.includes(rolesList.SCRIPT_VALIDATE),
        tooltip: t('actions.validate'),
        onClick: onValidateClick,
      },
    ];

    if (tab === 'diagrams-with-script') {
      actions.push({
        icon: 'icon-refresh',
        disabled: isReadOnly || false,
        tooltip: t('actions.refresh'),
        onClick: onRefreshClick,
      });
    }

    return actions;
  }, [isReadOnly, isValid, customCode, tab, isChanged, t]);

  useEffect(() => {
    const parsedUrl = queryString.parse(location.search.slice(1));
    const customCodeKey = history.location.pathname.split('/')[3];

    if (!customCodes.data.length && !location.state?.draft) {
      getCustomCodeRequest(
        customCodeKey,
        (location as any)?.state?.versionName
      );
    } else {
      const currentCustomCode = [...customCodes.data, ...openAllObjects].filter(
        (item) => item.key == customCodeKey
      )[0] as ICustomCodeData;

      // todo пришлось совершить колхоз
      if (!currentCustomCode && customCodeKey)
        getCustomCodeRequest(
          customCodeKey,
          (location as any)?.state?.versionName
        );

      if (currentCustomCode) {
        const title = [
          currentCustomCode.versionName,
          currentCustomCode.title,
          isReadOnly && ` (${t('development.customCode.common.readOnly')})`,
        ]
          .filter(Boolean)
          .join(' ');

        setTitle(title);

        if (!openTabs.find((item) => item.key === customCodeKey)) {
          if (parsedUrl.create) {
            dispatch(
              updateOpenTab({
                key: parsedUrl.create,
                tab: {
                  key: currentCustomCode.key,
                  text: title,
                  type: 'customCode',
                  icon: 'icon-custom_code',
                },
              })
            );

            history.push(
              `${CUSTOM_CODES_CORE_PATH}/${currentCustomCode.key}/script`
            );
          } else {
            dispatch(
              addOpenTab({
                tab: {
                  key: customCodeKey,
                  text: title,
                  type: 'customCode',
                  icon: 'icon-custom_code',
                },
              })
            );
          }
          dispatch(addOpenCustomCodeObject({ key: customCodeKey }));
        }
        dispatch(setCustomCodeKey({ key: customCodeKey }));
        dispatch(setCurrentCustomCodeTitle({ title: title }));
      }
    }
  }, [history.location.pathname.split('/')[3], customCodes]);

  useEffect(() => {
    tabs.forEach((data) => {
      if (data.path === '/' + history.location.pathname.split('/')[4]) {
        setActiveThirdPanel(data.key);
      }
    });
  }, [tabs, history.location.pathname.split('/')[4]]);

  useEffect(() => {
    const key = history.location.pathname.split('/')[3];
    const path = history.location.pathname.split('/')[4];
    dispatch(updateOpenTab({ key, tab: { path } }));
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(getCustomCodes());
    roles.includes(rolesList.DICTIONARY_READ_ALL_DATA_TYPES) &&
      dispatch(getDataTypes());
  }, []);

  useEffect(() => {
    const translatePath = 'development.customCode.common.readOnly';
    const version = customCode.versionName ? `${customCode.versionName} ` : '';
    const readOnly = isReadOnly ? ` (${t(translatePath)})` : '';
    const text = version + customCode.title + readOnly;
    const openTab = { key: customCode.key, tab: { text } };
    setTitle(text);

    text !== 'undefined' && dispatch(updateOpenTab(openTab));
  }, [customCode, isReadOnly]);

  return (
    <>
      <div className="diagram">
        <div className="diagram__top">
          <h1 className="diagram__title">
            {/* <Icon className="diagram__title-icon" name="icon-custom_code" /> */}
            <span>{title}</span>
          </h1>
          <div className="diagram__actions">
            <div className="diagram__actions-button">
              {getLink('custom-code', <Icon name="icon-help" />)}
            </div>
            <CloseObjectButton
              objectKey={currentCustomCode.key}
              objectType="customCode"
            />
          </div>
        </div>
        <div className="diagram__row">
          <TabPanel
            items={tabs}
            level={3}
            active={activeThirdPanel}
            onClick={(_, key) => {
              history.push(
                `/development/custom-code-list/${currentCustomCode.key}${
                  tabs[+key].path
                }`
              );
            }}
          />
        </div>
        <div className="diagram__row">
          <Toolbar data={actions} />
        </div>
        <div className="diagram__content">
          <CustomCodeNavigation />
        </div>
      </div>
      <Modal
        isOn={showModal}
        modalProps={{
          title: 'Создание нового объекта',
          icon: 'icon-expression_editor',
          width: '510px',
        }}
        onClose={() => setShowModal(false)}
      >
        <SaveAsModal onSave={onSaveClick} onClose={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

export { CustomCode };
