import { ColumnsType } from 'antd/lib/table';
import { Header } from '@modules/administration/components/ExternalServiceCallSettingsModal/interfaces';

export const rawColumnsHeaders: ColumnsType<Header> = [
  {
    dataIndex: 'headerName',
    title: 'headerName',
    width: '50%',
  },
  {
    dataIndex: 'headerValue',
    title: 'headerValue',
    width: '50%',
  },
];
