import { IAggregate } from '@modules/development';
import { IAction } from '@modules/store';
import { IDevelopmentState } from '@modules/development/DevelopmentSlice';

const reducers = {
  setAggregateName(state: IDevelopmentState, action: IAction) {
    const { aggregateName } = action.payload;
    const currentObject = state.openObjects.find(
      (item) => item.key === state.currentObject.key
    ) as IAggregate;

    if (currentObject) {
      currentObject.aggregateName = aggregateName;
      currentObject.aggregateJson.aggregateName = aggregateName;
      currentObject.isSaved = false;
    }
  },
  setAggregateDescription(state: IDevelopmentState, action: IAction) {
    const { aggregateDescription } = action.payload;
    const currentObject = state.openObjects.find(
      (item) => item.key === state.currentObject.key
    ) as IAggregate;

    if (currentObject) {
      currentObject.aggregateDescription = aggregateDescription;
      currentObject.aggregateJson.aggregateDescription = aggregateDescription;
      currentObject.isSaved = false;
    }
  },
  setAggregateVariableType(state: IDevelopmentState, action: IAction) {
    const { aggregateVariableType } = action.payload;
    const currentObject = state.openObjects.find(
      (item) => item.key === state.currentObject.key
    ) as IAggregate;

    if (currentObject) {
      currentObject.aggregateJson.aggregateVariableType = aggregateVariableType;
      currentObject.isSaved = false;
    }
  },
  setAggregateFunction(state: IDevelopmentState, action: IAction) {
    const { aggregateFunction } = action.payload;
    const currentObject = state.openObjects.find(
      (item) => item.key === state.currentObject.key
    ) as IAggregate;

    if (currentObject) {
      currentObject.aggregateJson.aggregateFunction = aggregateFunction;
      currentObject.isSaved = false;
    }
  },
  setAggregateGroupingElement(state: IDevelopmentState, action: IAction) {
    const { groupingElement } = action.payload;
    const currentObject = state.openObjects.find(
      (item) => item.key === state.currentObject.key
    ) as IAggregate;

    if (currentObject) {
      currentObject.aggregateJson.groupingElement = groupingElement;
      currentObject.isSaved = false;
    }
  },
  setShowError(state: IDevelopmentState, action: IAction) {
    return {
      ...state,
      aggregates: { ...state.aggregates, error: action.payload },
    };
  },
};

export default reducers;
