import React, { useState, useContext, useEffect, useMemo } from 'react';
import {
  TConnectionsFormSettings,
  useConnectionsFormSettings,
} from '@modules/connections/hooks';
import { rawDBFormCommonFields, rawDBFormDynamicFields } from './rawFormFields';
import { FieldGroup, Tooltip } from '@shared';
import { TConnectionsDbType } from '@modules/connections/types/connectionsTypes';
import { Icon } from '@modules/icon';
import { DataSourceFieldsCommon } from '@modules/connections/components/connectionsModalDataSource/components';
import { ConnectionsModalDataFromContext } from '@modules/connections/components';
import { ConnectionsDataSourceModalFields } from '@modules/connections/components/connectionsModalDataSource/types/connectionsModalDataSourceTypes';

export const DataSourceFieldsDB: React.FC = () => {
  const { form, isEdit } = useContext(ConnectionsModalDataFromContext);

  const dbFormDynamicFieldsIfEdit = useMemo(() => {
    return rawDBFormDynamicFields;
  }, [isEdit]);

  const [dbFormCommonFields, setDbFormFields] = useState(rawDBFormCommonFields);
  const [dbFormDynamicFields, setDbFormDynamicFields] = useState(
    dbFormDynamicFieldsIfEdit
  );

  const changeHiddenFlg = (
    array: TConnectionsFormSettings[],
    value: TConnectionsDbType
  ) => {
    return array.map((field) => ({
      ...field,
      ...(field.relate ? { hidden: !field.relate.includes(value) } : {}),
    }));
  };

  const selectDbType = (value: TConnectionsDbType) => {
    const freshCommonFields = changeHiddenFlg(dbFormCommonFields, value);
    const freshDynamicFields = changeHiddenFlg(dbFormDynamicFields, value);

    setDbFormFields(freshCommonFields);
    setDbFormDynamicFields(freshDynamicFields);
  };

  const { fieldsData: fieldsDbCommon } = useConnectionsFormSettings(
    dbFormCommonFields,
    {
      [ConnectionsDataSourceModalFields.typeDB]: {
        onChange: selectDbType,
      },
    }
  );
  const { fieldsData: fieldsDbDynamic } = useConnectionsFormSettings(
    dbFormDynamicFields,
    {
      [ConnectionsDataSourceModalFields.furtherParams]: {
        suffix: (
          <Tooltip title="Further information" trigger="click" placement="top">
            <Icon name="icon-help" />
          </Tooltip>
        ),
      },
      [ConnectionsDataSourceModalFields.jdbcUrl]: {
        suffix: (
          <Tooltip title="Further information" trigger="click" placement="top">
            <Icon name="icon-help" />
          </Tooltip>
        ),
      },
      [ConnectionsDataSourceModalFields.pass]: {
        visibilityToggle: !isEdit,
      },
    }
  );

  useEffect(() => {
    const typeDb = form.getFieldsValue([
      ConnectionsDataSourceModalFields.typeDB,
    ])?.[ConnectionsDataSourceModalFields.typeDB];

    if (typeDb) selectDbType(typeDb);

    return () => {
      form.resetFields([ConnectionsDataSourceModalFields.typeDB]);
    };
  }, []);

  return (
    <>
      <DataSourceFieldsCommon />
      <FieldGroup data={fieldsDbCommon} labelWidth="200px" />
      {!!fieldsDbDynamic.length && (
        <FieldGroup
          className="connections__data-source-modal_border"
          data={fieldsDbDynamic}
          labelWidth="200px"
        />
      )}
    </>
  );
};
