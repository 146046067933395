import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { MainLayout } from '../components/mainLayout';
import { PrivateRoute } from './components/PrivateRoute';

export const AppRouting = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/" component={MainLayout} />
      </Switch>
    </Router>
  );
};
