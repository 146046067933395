import { Button } from '@shared';
import { selectRoles, rolesList } from '@modules/services';
import React, { Key, useMemo, VFC } from 'react';
import { useAppSelector } from '@modules/store/hooks';

type ExtServiceFooterProps = {
  translate: (attrName: any, options?: any) => any;
  onSaveAsClick: () => void;
  onSaveClick: () => void;
  onCloseClick: () => void;
  serviceId: Key | undefined;
};

export const ExtServiceFooter: VFC<ExtServiceFooterProps> = ({
  translate,
  onSaveAsClick,
  onSaveClick,
  onCloseClick,
  serviceId,
}) => {
  const roles = useAppSelector(selectRoles);
  const saveButtonDisabled = useMemo(() => {
    if (serviceId) {
      return !roles.includes(rolesList.EXT_SERVICE_UPDATE);
    } else {
      return !roles.includes(rolesList.EXT_SERVICE_CREATE);
    }
  }, [serviceId, roles]);

  return (
    <div className="btn-group-bottom">
      <Button
        kind="normal"
        modifiers={['hover-box-shadow']}
        onClick={onSaveAsClick}
        children={translate('saveAs')}
        disabled={!roles.includes(rolesList.EXT_SERVICE_CREATE)}
      />
      <Button
        kind="normal"
        modifiers={['hover-box-shadow']}
        onClick={onSaveClick}
        children={translate('save')}
        disabled={saveButtonDisabled}
      />
      <Button
        kind="normal"
        modifiers={['second', 'hover-box-shadow']}
        onClick={onCloseClick}
        children={translate('cancel')}
      />
    </div>
  );
};
